import styles from "./CheckboxDarkGreen.module.css";

export const CheckboxDarkGreen = ({
  id,
  title,
  value,
  onChange,
}: {
  id?: string;
  title?: string;
  value?: boolean;
  onChange?: (value: boolean) => void;
}): JSX.Element => {
  return (
    <input
      className={"form-check-input " + styles.checkbox}
      type="checkbox"
      id={id}
      title={title}
      checked={value}
      onChange={() => onChange && onChange(!value)}
    />
  );
};
