import {
  Firestore,
  collection,
  where,
  query,
  Timestamp,
  doc,
  onSnapshot,
} from "@firebase/firestore";
import { Observable } from "rxjs";

import { Result } from "../typings";
import { OutputHub } from "../domain/entities/Hub";
import { HandlerGetHubRealtime } from "../domain/usecases/interfaces/HandlerGetHubRealtime";

export class HandlerGetHubRealtimeFirebase implements HandlerGetHubRealtime {
  constructor(private firestore: Firestore) {}

  getAllEnabledHubs(): Observable<Result<OutputHub[]>> {
    return new Observable((sub) => {
      const queryEnabledHubs = query(
        collection(this.firestore, "hubs"),
        where("habilitado", "==", true)
      );

      onSnapshot(
        queryEnabledHubs,
        (querySnapshot) => {
          const hubs: OutputHub[] = querySnapshot.docs.map((docSnapshot) => {
            const hub = docSnapshot.data() as OutputHub;
            hub.id = docSnapshot.id;
            hub.created_in = (hub.created_in as unknown as Timestamp).toDate();
            return hub;
          });

          sub.next({
            ok: true,
            value: hubs,
          });
        },
        () =>
          sub.next({
            ok: true,
            value: [],
          })
      );
    });
  }

  getHub(id: string): Observable<Result<OutputHub>> {
    return new Observable((sub) => {
      const docHub = doc(this.firestore, "hubs", id);

      onSnapshot(
        docHub,
        (docSnapshot) => {
          const hub = docSnapshot.data() as OutputHub;
          hub.id = docSnapshot.id;
          hub.created_in = (hub.created_in as unknown as Timestamp).toDate();

          sub.next({
            ok: true,
            value: hub,
          });
        },
        (error) =>
          sub.next({
            ok: false,
            error,
          })
      );
    });
  }

  getHubsGestor(id_gerente: string): Observable<Result<OutputHub[]>> {
    return new Observable((sub) => {
      const queryHubsGestor = query(
        collection(this.firestore, "hubs"),
        where("gestores", "array-contains", id_gerente)
      );

      onSnapshot(
        queryHubsGestor,
        (querySnapshot) => {
          const hubs: OutputHub[] = querySnapshot.docs.map((docSnapshot) => {
            const hub = docSnapshot.data() as OutputHub;
            hub.id = docSnapshot.id;
            hub.created_in = (hub.created_in as unknown as Timestamp).toDate();
            return hub;
          });

          sub.next({
            ok: true,
            value: hubs,
          });
        },
        () =>
          sub.next({
            ok: true,
            value: [],
          })
      );
    });
  }
}
