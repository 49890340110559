import { Button, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";

import { ProgramaConexao } from "../../../../../domain/entities/ProgramaConexao";
import { HandlerProgramaConexaoUpdate } from "../../../../../domain/usecases/interfaces/HandlerProgramaConexaoUpdate";
import { InputText } from "../../../../generalComponents/InputText";
import { InputTextarea } from "../../../../generalComponents/InputTextarea";
import {
  InputDuration,
  InputDurationValue,
} from "../../../../generalComponents/InputDuration";

export function ModalEditProgramaDeConexao({
  initialValue,
  show,
  onShowChange,
  handlerProgramaConexaoUpdate,
  onUpdate,
}: {
  initialValue: InputEditProgramaDeConexao;
  show: boolean;
  onShowChange: (show: boolean) => void;
  handlerProgramaConexaoUpdate: HandlerProgramaConexaoUpdate;
  onUpdate: () => void;
}) {
  const [programaToEdit, setProgramaToEdit] =
    useState<InputEditProgramaDeConexao>(initialValue);
  const [error, setError] = useState<
    Error | Partial<Record<keyof InputEditProgramaDeConexao, Error>>
  >();
  const [carregando, setCarregando] = useState<boolean>(false);

  useEffect(() => {
    setProgramaToEdit({
      ...initialValue,
    });
  }, [initialValue]);

  useEffect(() => {
    const invalidResult =
      ProgramaConexao.isInvalidProgramaConexao(programaToEdit);
    if (invalidResult) {
      setError(invalidResult);
    } else {
      setError(undefined);
    }
  }, [programaToEdit]);

  return (
    <Modal
      show={show}
      onHide={() => onShowChange(false)}
      centered
      scrollable={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <FontAwesomeIcon icon={faPencil} className="me-2" />
          Editar informações do usuário
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputText
          label="Título do programa*"
          title="Título do programa"
          value={programaToEdit.nome}
          onChange={(nome) => {
            setProgramaToEdit({
              ...programaToEdit,
              nome,
            });
          }}
          id="iptTitulo"
          min={{
            value: ProgramaConexao.MIN_SIZE_NOME,
            errorMessage: ProgramaConexao.MIN_SIZE_NOME_ERROR,
          }}
          max={{
            value: ProgramaConexao.MAX_SIZE_NOME,
            errorMessage: ProgramaConexao.MAX_SIZE_NOME_ERROR,
          }}
        />

        <InputTextarea
          label="Sobre*"
          subLabel="Deixe as pessoas saberem sobre o que é o programa de conexão"
          title="Sobre o programa"
          value={programaToEdit.about}
          onChange={(about) => {
            setProgramaToEdit({
              ...programaToEdit,
              about,
            });
          }}
          id="iptSobre"
          min={{
            value: ProgramaConexao.MIN_SIZE_ABOUT,
            errorMessage: ProgramaConexao.MIN_SIZE_ABOUT_ERROR,
          }}
          max={{
            value: ProgramaConexao.MAX_SIZE_ABOUT,
            errorMessage: ProgramaConexao.MAX_SIZE_ABOUT_ERROR,
          }}
        />

        <InputDuration
          label="Duração*"
          value={programaToEdit.duration}
          onChange={(duration) => {
            setProgramaToEdit({
              ...programaToEdit,
              duration,
            });
          }}
          validate={(duration) =>
            ProgramaConexao.isInvalidDates(duration.start, duration.end)
          }
          sizeDates="large"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="success"
          disabled={!!error || carregando}
          onClick={async () => {
            setCarregando(true);

            const updateResult = await handlerProgramaConexaoUpdate.update({
              id: programaToEdit.id,
              nome: programaToEdit.nome,
              about: programaToEdit.about,
              end_date: programaToEdit.duration.end,
              start_date: programaToEdit.duration.start,
            });

            setCarregando(false);

            if (updateResult.ok) {
              onUpdate();
            } else {
              console.warn(updateResult.error);
            }
          }}
        >
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export type InputEditProgramaDeConexao = {
  id: string;
  nome: string;
  about: string;
  duration: InputDurationValue;
};
