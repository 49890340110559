import { useNavigate } from "react-router";
import { useContext } from "react";

import { ContainerResponsive } from "../../../../generalComponents/ContainerResponsive";
import { PainelElegibilidade } from "../../components/PainelElegibilidade/PainelElegibilidade";
import { HandlersDatabaseContext } from "../../../../../globalStore/HandlersProviders/HandlersDatabaseContext";

export function PainelElegibilidadeHub() {
  const navigate = useNavigate();

  const { handlerAceiteSet } = useContext(HandlersDatabaseContext);

  const paginacaoElegibilidade = {
    start: 1,
    end: 6,
  };
  const nextUrl = "/cadastro/hub/2";

  return (
    <section data-testid="painel-elegibilidade-hub" className="h-100">
      <ContainerResponsive
        backUrlMobile="/"
        textoMarcaDagua={"Hub de Inovação"}
        paginacao={paginacaoElegibilidade}
        showLogo={true}
      >
        <PainelElegibilidade
          requisitos={[
            "Atuamos com inovação e empreendedorismo;",
            "Temos como objetivo fomentar ecossistemas de empreendedorismo e inovação;",
            "Já participo ou pretendo participar do ecossistema de empreendedorismo.;",
          ]}
          titulo={"Hub de Inovação"}
          onNext={() => {
            if (handlerAceiteSet) handlerAceiteSet.set("hub", new Date());
            navigate(nextUrl);
          }}
          onPrevious={() => {
            navigate("/");
          }}
        />
      </ContainerResponsive>
    </section>
  );
}
