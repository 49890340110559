export function getUrlFromFile(file: Blob): Promise<string> {

  return new Promise((resolve, reject) => {

    const fileReader = new FileReader();

    fileReader.onload = (e) => {
      if (e.target) {
        const { result } = e.target;
        if (result) {
          resolve(result as string)
          return
        }
      }
      reject(new Error("Erro ao ler arquivo"))
    };
    fileReader.readAsDataURL(file);
  })
}