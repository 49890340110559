import { useState, useContext } from "react";

import HTMLHead from "../../generalComponents/HTMLHead";
import { Result } from "../../../typings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ImgFromPathNew } from "../../generalComponents/ImgFromPathNew";
import {
  faExclamationCircle,
  faUser,
  faEye,
  faPencil,
} from "@fortawesome/free-solid-svg-icons";
import { CidadeOutput } from "../../../domain/entities/cidade";
import { Modal } from "react-bootstrap";
import { HandlerSaveLogoStartup } from "../../../domain/usecases/interfaces/handlerSaveLogoStartup";
import { ModalEditStartupHeader } from "../startup/ModalEditStartupHeader";
import { HandlersDatabaseContext } from "../../../globalStore/HandlersProviders/HandlersDatabaseContext";
import { InputUpdateStartupHeader } from "../../../domain/entities/Startup";

type CardHeaderContentProps = {
  html_title: string;
  html_description: string;
  infoResult: Result<{
    id: string;
    headline: string;
    isHabilitado: boolean;
  }>;

  handlerSaveLogoStartup?: HandlerSaveLogoStartup;
  isGestor: boolean;
  seeAsPublic: boolean;
  path_logo?: string;
  nome: string;
  cidade: CidadeOutput;
  onChangeSeeAsPublic: (asPublic: boolean) => void;
};

export default function CardHeaderContent({
  html_title,
  html_description,
  infoResult,
  handlerSaveLogoStartup,
  isGestor,
  seeAsPublic,
  path_logo,
  nome,
  cidade,
  onChangeSeeAsPublic,
}: CardHeaderContentProps) {
  const { handlerUpdateStartup } = useContext(HandlersDatabaseContext);
  const [showAlertNaoHabilitado, setShowAlertNaoHabilitado] = useState(true);
  const [img, setImg] = useState(path_logo || "");
  const [showModalEditStartup, setShowModalEditStartup] =
    useState<boolean>(false);

  const [nomeStartup, setNomeStartup] = useState<string>(nome);
  const [cidadeStartup, setCidadeStartup] = useState<CidadeOutput>(cidade);

  const updateInfosStartup = (startup?: InputUpdateStartupHeader) => {
    if (startup) {
      if (startup.cidade && startup.cidade !== cidadeStartup) {
        setCidadeStartup(startup.cidade);
      }

      if (startup.nome && startup.nome !== nomeStartup) {
        setNomeStartup(startup.nome);
      }
    }
  };

  return (
    <>
      <HTMLHead title={html_title} description={html_description} />

      {infoResult.ok ? (
        <>
          <>
            <section className="d-flex mx-2">
              <div
                style={{
                  width: "15vw",
                  marginLeft: "3vw",
                  marginTop: "1.5vw",
                  display: "inline-block",
                  verticalAlign: "top",
                  minWidth: 75,
                }}
              >
                {
                  <ImgFromPathNew
                    handlerUpdate={
                      handlerSaveLogoStartup &&
                      isGestor &&
                      !seeAsPublic &&
                      infoResult.value.isHabilitado
                        ? {
                            modalButtonText: "Salvar avatar",
                            modalHeaderText: "Editar avatar",
                            update: async (file) => {
                              const saveResult =
                                await handlerSaveLogoStartup?.save({
                                  id_startup: infoResult.value.id,
                                  file,
                                });

                              if (!saveResult?.ok) return saveResult;

                              setImg(saveResult.value.path);

                              return {
                                ok: true,
                                value: {
                                  path: saveResult.value.path,
                                },
                              };
                            },
                          }
                        : undefined
                    }
                    alt={nomeStartup}
                    path={img}
                    className="rounded-circle border border-2 border-white bg-white"
                    elementErro={
                      <div className="rounded-circle bg-secondary-subtle text-white d-flex justify-content-center align-items-center opacity-75 w-100 h-100">
                        <FontAwesomeIcon icon={faUser} className="w-50 h-50" />
                      </div>
                    }
                  />
                }
              </div>
              <div
                className="ms-2 w-100"
                style={{
                  zIndex: 1,
                }}
              >
                <div
                  className="d-flex flex-column justify-content-between p-1"
                  style={{
                    height: "8vw",
                    minHeight: "100px",
                  }}
                >
                  {isGestor && (
                    <>
                      {infoResult.value.isHabilitado ? (
                        <div className="d-flex justify-content-end">
                          <button
                            className="btn btn-sm btn-light d-flex align-items-center rounded-pill"
                            style={{
                              fontSize: "min(2vw, 16px)",
                            }}
                            onClick={() => onChangeSeeAsPublic(!seeAsPublic)}
                          >
                            <FontAwesomeIcon icon={faEye} className="m-1" />
                            {seeAsPublic
                              ? "ver como administrador"
                              : "ver perfil como usuário"}
                          </button>
                        </div>
                      ) : (
                        <>
                          <div
                            className="d-flex align-items-center bg-white rounded border border-danger border-3  border-opacity-50 p-xl-0"
                            style={{
                              fontSize: "min(2vw, 16px)",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faExclamationCircle}
                              className="text-danger m-1"
                            />
                            Seu perfil está sob análise, ficará visível para os
                            demais usuários após aprovado
                          </div>

                          <Modal
                            show={showAlertNaoHabilitado}
                            onHide={() => setShowAlertNaoHabilitado(false)}
                            centered
                            scrollable={true}
                          >
                            <Modal.Body>
                              <div className="d-flex gap-3">
                                <FontAwesomeIcon
                                  icon={faExclamationCircle}
                                  className="text-danger m-1"
                                  size="4x"
                                />
                                <div>
                                  <h6 className="fw-bolder">
                                    Perfil em análise!
                                  </h6>
                                  <p>
                                    Uma vez aprovado, seu perfil ficará visível
                                    para outras pessoas e você poderá se
                                    conectar com outros usuários do SCIENCIN.
                                    Por enquanto você pode editar e preencher
                                    suas informações
                                  </p>
                                </div>
                              </div>
                              <div className="text-center">
                                <button
                                  className="btn btn-danger px-5"
                                  onClick={() =>
                                    setShowAlertNaoHabilitado(false)
                                  }
                                >
                                  OK
                                </button>
                              </div>
                            </Modal.Body>
                          </Modal>
                        </>
                      )}
                    </>
                  )}
                </div>

                <h3 className="fs-4 fw-bold d-flex align-items-end">
                  <span className="flex-fill">{nomeStartup}</span>
                  {isGestor &&
                  handlerUpdateStartup &&
                  !seeAsPublic &&
                  infoResult.value.isHabilitado ? (
                    <>
                      <button
                        type="button"
                        className="btn bg-body-secondary text-secondary rounded-circle m-1"
                        onClick={() => setShowModalEditStartup(true)}
                      >
                        <FontAwesomeIcon icon={faPencil} />
                      </button>

                      {isGestor && handlerUpdateStartup && (
                        <ModalEditStartupHeader
                          initialValue={{
                            id: infoResult.value.id,
                            cidade: cidadeStartup,
                            nome: nomeStartup,
                          }}
                          show={showModalEditStartup}
                          onShowChange={(show, startupToEdit) => {
                            setShowModalEditStartup(show);
                            updateInfosStartup(startupToEdit);
                          }}
                          handlerUpdateStartup={handlerUpdateStartup}
                        />
                      )}
                    </>
                  ) : (
                    <button type="button" className="invisible btn m-1">
                      <FontAwesomeIcon icon={faPencil} />
                    </button>
                  )}
                </h3>

                <h5>{infoResult.value.headline}</h5>

                <h6 className="fs-6 m-0">
                  {`${cidadeStartup.cidade}, ${cidadeStartup.estado} - ${cidadeStartup.pais}`}
                </h6>

                <div className="d-flex align-items-center gap-2">
                  {/* {perfisHabilitados.length > 0 && (
                    <div
                      style={{
                        fontSize: "14px",
                        color: "#525200",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {perfisHabilitados.map((perfil, i) => (
                        <span key={i}>
                          {i > 0 && " | "}
                          {perfil}
                        </span>
                      ))}
                    </div>
                  )} */}

                  {/* {(user.url_linkedin || user.url_lattes) && (
                    <>
                      {user.url_lattes && (
                        <a href={user.url_lattes} target="_blank">
                          <img
                            src={logoLattes}
                            alt={"lattes de " + user.nome}
                            className="p-1"
                            style={{
                              minWidth: "2rem",
                              maxWidth: "2rem",
                            }}
                          />
                        </a>
                      )}

                      {user.url_linkedin && (
                        <a href={user.url_linkedin} target="_blank">
                          <img
                            src={logoLinkedin}
                            alt={"linkedin de " + user.nome}
                            style={{
                              minWidth: "2.1rem",
                              maxWidth: "2.1rem",
                            }}
                          />
                        </a>
                      )}
                    </>
                  )} */}
                </div>

                {/* <MutualConnection
                  auth_id_conexoes_user={user.auth_id_conexoes ?? []}
                  auth_id_conexoes_current_user={
                    currentUser.auth_id_conexoes ?? []
                  }
                  hideMutual={user.auth_user_id == currentUser.auth_user_id}
                /> */}

                {/* {((!isCurrentUser && conexaoComCurrentUserResult) ||
                  seeAsPublic) && (
                  <div className="mb-4">
                    {!isCurrentUser && conexaoComCurrentUserResult ? (
                      <>
                        {conexaoComCurrentUserResult.ok ? (
                          <>
                            {conexaoComCurrentUserResult.value.autor
                              .auth_user_id == currentUser.auth_user_id ? (
                              conexaoComCurrentUserResult.value.status ==
                              "iniciado" ? (
                                <div
                                  className="border rounded-3 p-1 d-inline-block"
                                  style={{
                                    border: "#999900 1px solid",
                                    borderRadius: 16,
                                    color: "#999900",
                                    backgroundColor: "#fff",
                                  }}
                                >
                                  <FontAwesomeIcon
                                    fontSize={20}
                                    title="Pendente"
                                    icon={faClock}
                                    className="me-1"
                                  />
                                  Pendente
                                </div>
                              ) : conexaoComCurrentUserResult.value.status ==
                                "aprovado" ? (
                                <div className="border rounded-3 p-1">
                                  <FontAwesomeIcon
                                    fontSize={20}
                                    title="conectados"
                                    icon={faCheck}
                                  />
                                </div>
                              ) : (
                                <></>
                              )
                            ) : conexaoComCurrentUserResult.value.status ==
                              "iniciado" ? (
                              <Button
                                onClick={onAceitarConexao}
                                icon={faUserPlus}
                                classes="btn btn-success"
                              >
                                Aceitar
                              </Button>
                            ) : conexaoComCurrentUserResult.value.status ==
                              "aprovado" ? (
                              <div className="border rounded-3 p-1">
                                <FontAwesomeIcon
                                  fontSize={20}
                                  title="conectados"
                                  icon={faCheck}
                                />
                              </div>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <Button
                            onClick={() =>
                              onAdicionarConexao(currentUser, user)
                            }
                            icon={faUserPlus}
                            classes="btn fw-bolder"
                            style={{
                              border: "#999900 1px solid",
                              borderRadius: 16,
                              color: "#fff",
                              backgroundColor: "#999900",
                            }}
                          >
                            Conectar
                          </Button>
                        )}
                      </>
                    ) : (
                      <Button
                        icon={faUserPlus}
                        classes="btn fw-bolder"
                        style={{
                          border: "#999900 1px solid",
                          borderRadius: 16,
                          color: "#fff",
                          backgroundColor: "#999900",
                        }}
                      >
                        Conectar
                      </Button>
                    )}
                  </div>
                )} */}

                {/* {isCurrentUser && !seeAsPublic && (
                  <LinkedinPerfilButton dataIssue={new Date()} user={user} /> // TODO: GET DATE CREATED
                )} */}
              </div>
            </section>
          </>
        </>
      ) : (
        <div
          className="ms-2 flex-fill"
          style={{
            paddingTop: "9vw",
            display: "inline-block",
          }}
        >
          <div className="border rounded-4 card p-2 text-muted">
            não encontrado
          </div>
        </div>
      )}
    </>
  );
}
