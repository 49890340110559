// import markerSDK from "@marker.io/browser";
// import { useEffect } from "react";

import { CurrentUserProvider } from "./globalStore/CurrentUserContextProvider";
import { ResizeContextProvider } from "./hooks/useResize";
import RoutesDefinition from "./RoutesDefinition";
import { HandlersFirebaseAndOSMProvider } from "./globalStore/HandlersProviders/HandlersFirebaseAndOSMProvider";

function App() {
  // useEffect(() => {
  //   if (process.env.NODE_ENV === "production") {
  //     markerSDK.loadWidget({
  //       destination: "621c0ed1a9e2ad4b1424d546", // <- Your unique destination ID
  //     });
  //   }
  // }, []);
  return (
    <HandlersFirebaseAndOSMProvider>
      <ResizeContextProvider>
        <CurrentUserProvider>
          <RoutesDefinition />
        </CurrentUserProvider>
      </ResizeContextProvider>
    </HandlersFirebaseAndOSMProvider>
  );
}

export default App;
