import { ModeloNegocioOutput } from "../../../../../domain/entities/modeloNegocio";
import { BadgeInfo } from "./BadgeInfo";

export const InfoModeloNegocio = ({
  modeloNegocio,
  className = "",
}: {
  modeloNegocio: ModeloNegocioOutput[];
  className?: string;
}) => {
  return (
    <div className={className}>
      <h5 className="fw-lighter">Modelo de negócio</h5>

      {modeloNegocio.map((modelo, i) => (
        <BadgeInfo key={i} children={modelo.nome} />
      ))}
    </div>
  );
};
