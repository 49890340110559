import { Firestore, setDoc, doc } from "firebase/firestore";
import { FirebaseError } from "firebase/app";

import { Result } from "../typings";
import {
  FirestoreError,
  FirestoreErrorsFactory,
} from "../domain/erros/FirestoreErrors";
import { HandlerSaveTelefone } from "../domain/usecases/interfaces/handlerSaveTelefone";
import {
  InputTelefone,
  OutputTelefone,
  Telefone,
} from "../domain/entities/telefone";

export class HandlerSaveTelefoneFirebase implements HandlerSaveTelefone {
  constructor(private firestore: Firestore) {}
  async saveTelefone(
    input: InputTelefone
  ): Promise<Result<OutputTelefone, Error>> {
    const docPhoneNumber = doc(this.firestore, "phone_number", input.auth_id);

    const telefoneResult = Telefone.create(input);

    if (!telefoneResult.ok) return telefoneResult;

    return new Promise<Result<InputTelefone, Error>>((resolve) => {
      setDoc(docPhoneNumber, input)
        .then(() =>
          resolve({
            ok: true,
            value: input,
          })
        )
        .catch((error: FirebaseError) => {
          const instanciaErro = FirestoreErrorsFactory.createErrorsValidation(
            <FirestoreError>error.code
          );

          resolve({
            ok: false,
            error: instanciaErro,
          });
        });
    });
  }
}
