import Select, { StylesConfig } from "react-select";
import { useContext, useEffect, useState } from "react";
import makeAnimated from "react-select/animated";

import { ResizeContext } from "../../hooks/useResize";
import { HandlersDatabaseContext } from "../../globalStore/HandlersProviders/HandlersDatabaseContext";
import { OutputHub } from "../../domain/entities/Hub";

const animatedComponents = makeAnimated();

const customStylesSelectHub: StylesConfig<
  Pick<OutputHub, "id" | "nome">,
  false
> = {
  control: () => ({
    border: "none",
    borderBottom: "3px #666600 solid",
    display: "flex",
    background: " #fff",
    borderBottomLeftRadius: "8px",
    textAlign: "left",
  }),
};

export function InputHub({
  label,
  value,
  onChange,
  validate,
  className,
}: {
  label: string;
  value?: Pick<OutputHub, "id" | "nome">;
  onChange: (hub?: Pick<OutputHub, "id" | "nome">) => void;
  validate: (hub?: Pick<OutputHub, "id" | "nome">) => Error | undefined;
  className?: string;
}) {
  const { handlerGetHub } = useContext(HandlersDatabaseContext);
  const { responsive } = useContext(ResizeContext);

  const [hubs, setHubs] = useState<OutputHub[]>([]);
  const [isHubsLoading, setIsHubsLoading] = useState(true);

  const [error, setError] = useState<string>();
  const [isChanged, setIsChanged] = useState<boolean>(false);

  useEffect(() => {
    const error = validate(value);
    if (error) {
      setError(error.message);
    } else {
      setError(undefined);
    }
  }, [validate, value]);

  useEffect(() => {
    if (handlerGetHub) {
      setIsHubsLoading(true);
      handlerGetHub
        .getAllEnabledHubs()
        .then((handlerGetModeloNegocioResult) => {
          if (handlerGetModeloNegocioResult.ok)
            setHubs(handlerGetModeloNegocioResult.value);

          setIsHubsLoading(false);
        });
    }
  }, [handlerGetHub]);

  return (
    <div className={className}>
      <label className="form-label fw-bolder m-0" htmlFor="selectHub">
        {label}
      </label>
      <Select<Pick<OutputHub, "id" | "nome">>
        isClearable={true}
        options={hubs}
        isLoading={isHubsLoading}
        getOptionLabel={(o) => o.nome}
        getOptionValue={(o) => o.id}
        components={animatedComponents}
        placeholder=""
        onChange={(hub) => {
          onChange(hub ?? undefined);
        }}
        onBlur={() => {
          setIsChanged(true);
        }}
        className={error && isChanged ? " is-invalid" : ""}
        noOptionsMessage={() => "sem opção"}
        inputId="selectHub"
        styles={responsive.sm ? customStylesSelectHub : undefined}
        value={value}
      />
      <div className="invalid-feedback flex-fill">{error}</div>
    </div>
  );
}
