import { ConteudoPainelLogin } from "./ConteudoPainelLogin";
import { ContainerResponsive } from "../../../generalComponents/ContainerResponsive";

export function PainelLogin() {
  return (
    <section className="h-100">
      <ContainerResponsive
        showLogo={false}
        children={<ConteudoPainelLogin />}
      />
    </section>
  );
}
