import { Startup } from "./Startup";
import { CidadeOutput } from "./cidade";

export class ConviteStartupEmployee {
  private constructor(
    readonly id: string,
    readonly startup: Pick<Startup, "id" | "nome" | "path_logo">,
    readonly status: StatusConvite,
    readonly alvo: TypeAlvoConvite,
    readonly funcao: ConviteStartupEmployeeFuncao,
    readonly disabled?: boolean
  ) {}

  public static idFormat(convite: {
    startup: {
      id: string;
    };
    alvo: {
      id: string;
    };
    funcao: ConviteStartupEmployeeFuncao;
  }): string {
    return convite.startup.id + "|" + convite.alvo.id + "|" + convite.funcao;
  }
}

type StatusConvite = "pending" | "accepted" | "rejected";
export type TypeAlvoConvite = {
  id: string;
  nome: string;
  headline?: string;
  cidade: CidadeOutput;
  avatar_path?: string;
};
export type ConviteStartupEmployeeFuncao = "admin" | "employee";
