import { useCallback, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTableCells } from "@fortawesome/free-solid-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import { HandlersStorageContext } from "../../../../../globalStore/HandlersProviders/HandlersStorageContext";

export const InfoArquivos = ({
  arquivos,
}: {
  arquivos: InputInfoArquivo[];
}) => {
  const { handlerGetURLFromPath } = useContext(HandlersStorageContext);

  const openPath = useCallback(
    ({ path, type }: InputInfoArquivo) => {
      if (type == "video") {
        window.open(path);
      } else {
        handlerGetURLFromPath?.getURL(path).then((getURLResult) => {
          if (getURLResult.ok) {
            window.open(getURLResult.value);
          }
        });
      }
    },
    [handlerGetURLFromPath]
  );

  return (
    <>
      {arquivos.length > 0 && (
        <div className="mb-3">
          <h5 className="fw-lighter">Arquivos</h5>

          {arquivos.map(({ type, path }) => {
            return (
              <button
                className="btn btn-light btn-lg text-green-strong me-2"
                role="button"
                key={type + path}
                onClick={() => openPath({ path, type })}
              >
                <FontAwesomeIcon
                  icon={
                    type == "pdf"
                      ? (faFilePdf as IconProp)
                      : type == "excel"
                      ? faTableCells
                      : faYoutube
                  }
                  className="fs-1"
                />
              </button>
            );
          })}
        </div>
      )}
    </>
  );
};

export type InputInfoArquivo = {
  type: "pdf" | "excel" | "video";
  path: string;
};
