import { useContext, useEffect, useState, useMemo, ReactNode } from "react";
import { Navigate, useNavigate } from "react-router-dom";

import { FooterUsuarioFromContext } from "./FooterUsuarioFromContext";
import {
  OutputUsuarioScyggzEntity,
  TipoPerfil,
} from "../../../domain/entities/usuarioScyggz";
import { WrapperPerfil } from "./components/perfis/WrapperPerfil";
import { UsuarioInfoFirebaseProvider } from "../../../globalStore/UsuarioInfoFirebaseProvider";
import { UsuarioInfoContext } from "../../../globalStore/UsuarioInfoContext";
import { AbasPerfisFromUserContext } from "./components/AbasPerfisFromUserContext";
import { CardHeaderUsuarioFromContext } from "./CardHeaderUsuarioFromContext";
import { perfisToArray } from "../../../hooks/perfisToArray";
import { SideInfosUsuario } from "./SideInfosUsuario";
import UserNotFinded from "./UserNotFinded";
import { HandlerConexaoFirebaseProvider } from "../../../globalStore/providerHandlerConexao/HandlerConexaoFirebaseProvider";
import { ConexaoEntreUsuariosProvider } from "../../../globalStore/conexaoEntreUsuarios/ConexaoEntreUsuariosProvider";
import { SubPageOgarnizacoes } from "./SubPageOgarnizacoes";
import { HandlersDatabaseContext } from "../../../globalStore/HandlersProviders/HandlersDatabaseContext";

type UserAndPerfilToShowProps = {
  id_user: string | undefined;
  id_perfil: string | undefined;
  currentUser: OutputUsuarioScyggzEntity;
};

export default function UserAndPerfilToShow({
  id_user,
  id_perfil,
  currentUser,
}: UserAndPerfilToShowProps) {
  return (
    <div className="container py-3 d-flex flex-column flex-sm-row gap-3">
      {id_user ? (
        <div className="col-12 col-sm-9 d-flex flex-column gap-3">
          {id_user == currentUser.auth_user_id ? (
            <UsuarioInfoContext.Provider
              value={{
                usuarioInfoResult: {
                  ok: true,
                  value: currentUser,
                },
              }}
            >
              <UserWithContext id_perfil={id_perfil} id_user={id_user} />
            </UsuarioInfoContext.Provider>
          ) : (
            <UsuarioInfoFirebaseProvider
              usuario={{
                auth_user_id: id_user,
              }}
            >
              <HandlerConexaoFirebaseProvider>
                <ConexaoEntreUsuariosProvider
                  userA={currentUser}
                  userB={{
                    auth_user_id: id_user,
                  }}
                >
                  <UserWithContext id_perfil={id_perfil} id_user={id_user} />
                </ConexaoEntreUsuariosProvider>
              </HandlerConexaoFirebaseProvider>
            </UsuarioInfoFirebaseProvider>
          )}
        </div>
      ) : (
        <>{<Navigate to={"/user/" + currentUser.auth_user_id} />}</>
      )}

      <div className="col-12 col-sm-3 p-0 d-flex flex-column gap-3">
        <SideInfosUsuario />
      </div>
    </div>
  );
}

type UserWithContextProps = {
  id_perfil: string | undefined;
  id_user: string | undefined;
};

export function UserWithContext({ id_perfil, id_user }: UserWithContextProps) {
  const { usuarioInfoResult } = useContext(UsuarioInfoContext);

  const navigate = useNavigate();

  const [idPerfilToShow, setIdPerfilToShow] = useState<TipoPerfil>(
    id_perfil as TipoPerfil
  );

  const { handlerGetStartup } = useContext(HandlersDatabaseContext);

  const [userHasOrganizacoes, setUserHasOrganizacoes] =
    useState<boolean>(false);
  const [showOrganizacoes, setShowOrganizacoes] = useState<boolean>(
    usuarioInfoResult?.ok
      ? perfisToArray({
          perfis: usuarioInfoResult.value.perfis,
        }).length == 0
      : false
  );

  useEffect(() => {
    if (usuarioInfoResult?.ok) {
      handlerGetStartup
        ?.isUserHasStartup(usuarioInfoResult.value.auth_user_id)
        .then((result) => {
          if (result.ok) {
            setUserHasOrganizacoes(result.value);
          }
        });

      const perfisArray = perfisToArray({
        perfis: usuarioInfoResult.value.perfis,
      });
      const perfilToShow = perfisArray.find(
        (perfil) => perfil.tipo === id_perfil
      );

      if (perfilToShow) {
        setIdPerfilToShow(perfilToShow.tipo);
      } else {
        const firstPerfilArray = perfisArray.at(0);

        if (firstPerfilArray?.tipo) setIdPerfilToShow(firstPerfilArray?.tipo);

        window.history.replaceState(
          null,
          "",
          "/user/" +
            usuarioInfoResult.value.auth_user_id +
            "/" +
            firstPerfilArray?.tipo
        );
      }
    }
  }, [id_perfil, navigate, usuarioInfoResult, handlerGetStartup]);

  const organizacoesComponent = useMemo<ReactNode>(() => {
    return id_user && <SubPageOgarnizacoes userId={id_user} />;
  }, [id_user]);

  return (
    <>
      {usuarioInfoResult === undefined ? (
        <>carregando</>
      ) : (
        <>
          {usuarioInfoResult.ok ? (
            <>
              <CardHeaderUsuarioFromContext />

              <div>
                <h3 className="d-flex align-items-end">
                  <div className="ps-4">
                    <AbasPerfisFromUserContext
                      perfilAtivo={idPerfilToShow}
                      onClickOrganizacoes={(show) => {
                        if (show !== showOrganizacoes) {
                          setShowOrganizacoes(show);
                        }
                      }}
                      showOrganizacoes={showOrganizacoes}
                      userHasOrganizacoes={userHasOrganizacoes}
                    />
                  </div>
                </h3>

                <div className="bg-white shadow-sm rounded-4 p-4 bt-0">
                  {showOrganizacoes && userHasOrganizacoes ? (
                    <div>
                      <div className="position-relative bg-white shadow-sm rounded-4 p-4 bt-0">
                        {organizacoesComponent}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <WrapperPerfil
                        tipo_perfil={idPerfilToShow}
                        user_id={usuarioInfoResult.value.auth_user_id}
                      />
                      <FooterUsuarioFromContext />
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : (
            <UserNotFinded />
          )}
        </>
      )}
    </>
  );
}
