import { createContext } from "react";

import { OutputEmpresa } from "../../domain/entities/Empresa";
import { OutputHub } from "../../domain/entities/Hub";
import { OutputNit } from "../../domain/entities/Nit";
import { OutputStartup } from "../../domain/entities/Startup";
import { OutputVentureCapital } from "../../domain/entities/VentureCapital";
import { Result } from "../../typings";

export const UserOrganizacoesContext = createContext<{
  organizacoesResult: TypeUserOrganizacoesContext;
}>({
  organizacoesResult: undefined,
});

export type TypeUserOrganizacoesContext =
  | {
      startups: Result<OutputStartup[]> | undefined;
      hubs: Result<OutputHub[]> | undefined;
      nits: Result<OutputNit[]> | undefined;
      empresas: Result<OutputEmpresa[]> | undefined;
      vcs: Result<OutputVentureCapital[]> | undefined;
    }
  | undefined;
