import { ReactNode, useContext, useEffect, useState } from "react";

import { OutputEmpresa } from "../../domain/entities/Empresa";
import { HandlerGetEmpresaFirebase } from "../../gateway/handlerGetEmpresaFirebase";
import { FirebaseContext } from "../FirebaseContext";
import {
  PerfilEmpresaContext,
  TypePerfilEmpresaContext,
} from "./PerfilEmpresaContext";

export function PerfilEmpresaFirebaseProvider({
  empresa,
  children,
}: {
  empresa: Pick<OutputEmpresa, "id">;
  children: ReactNode;
}) {
  const [perfilEmpresaResult, setPerfilEmpresaResultResult] =
    useState<TypePerfilEmpresaContext>();

  const { firestore } = useContext(FirebaseContext);

  useEffect(() => {
    new HandlerGetEmpresaFirebase(firestore)
      .getEmpresa(empresa.id)
      .then((result) => {
        setPerfilEmpresaResultResult(
          result.ok
            ? {
                ok: true,
                value: result.value,
              }
            : result
        );
      });
  }, [empresa, firestore]);

  return (
    <PerfilEmpresaContext.Provider
      value={{
        perfilEmpresaResult,
      }}
      children={children}
    />
  );
}
