import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";

import { AreaAplicacaoTecnologia } from "../../../../domain/entities/areaAplicacaoTecnologia";
import { GrupoDePesquisaOutput } from "../../../../domain/entities/grupoDePesquisa";
import { Premiacao } from "../../../../domain/entities/premiacao";
import { SobreInput } from "../../../../domain/entities/sobre";
import { TecnologiaDoCientista } from "../../../../domain/entities/tecnologiaDoCientista";
import {
  InputHandlerUpdateCientista,
  InputHandlerUpdateCientistaSemId,
} from "../../../../domain/usecases/interfaces/handlerUpdateCientista";
import { Result } from "../../../../typings";
import { InfoAreaDeConhecimento } from "../components/generalComponents/InfoAreadeConhecimento";
import { InfoGrupoDePesquisa } from "../components/generalComponents/InfoGrupoDePesquisa";
import { InfoInstituicao } from "../components/generalComponents/InfoInstituicao";
import { InfoPremiacoes } from "../components/generalComponents/InfoPremiacoes";
import { InfoSobre } from "../components/generalComponents/InfoSobre";
import { InfoTecnologias } from "../components/generalComponents/InfoTecnologias";
import { ModalEditCientista } from "./ModalEditCientista";

export const PerfilInfoCientista = ({
  sobre,
  areas_de_conhecimento,
  unidades,
  grupos_de_pesquisa,
  premiacoes,
  tecnologias,
  refreshCientista,
  updateCientista,
}: {
  sobre: SobreInput;
  areas_de_conhecimento: Pick<AreaAplicacaoTecnologia, "id" | "nome">[];
  unidades: {
    id: string;
    nome: string;
    instituicao: { id: string; nome: string };
  }[];
} & Partial<{
  grupos_de_pesquisa: Pick<
    GrupoDePesquisaOutput,
    "id" | "nome" | "tipo" | "aprovado" | "autor"
  >[];
  premiacoes: Pick<Premiacao, "nome" | "descricao">[];
  tecnologias: Pick<
    TecnologiaDoCientista,
    | "nome"
    | "resumo"
    | "areas_de_conhecimento"
    | "areas_de_aplicacao"
    | "mecanismos_de_solucao"
  >[];
  refreshCientista: () => void;
  updateCientista: (
    input: InputHandlerUpdateCientistaSemId
  ) => Promise<Result<InputHandlerUpdateCientista>>;
}>) => {
  const [showModalEdit, setShowModalEdit] = useState<boolean>(false);

  return (
    <section className="position-relative">
      {updateCientista && (
        <>
          <button
            type="button"
            className="btn bg-body-secondary position-absolute top-0 start-100 translate-middle rounded-circle text-secondary"
            onClick={() => setShowModalEdit(true)}
          >
            <FontAwesomeIcon icon={faPencil} />
          </button>

          <ModalEditCientista
            initialValue={{
              sobre,
              areas_de_conhecimento,
              unidades,
              grupos_de_pesquisa,
              premiacoes,
              tecnologias,
            }}
            show={showModalEdit}
            onShowChange={(show) => {
              setShowModalEdit(show);
            }}
            handlerUpdateCientista={updateCientista}
            onUpdate={function (): void {
              if (refreshCientista) refreshCientista();
            }}
          />
        </>
      )}

      <InfoSobre sobre={sobre.conteudo} />
      <div className="row rounded-4 m-0 mb-4">
        {unidades.length > 0 && (
          <InfoInstituicao classes="col-12 col-md-6 p-0" unidades={unidades} />
        )}

        {areas_de_conhecimento.length > 0 && (
          <InfoAreaDeConhecimento
            classes="col-12 col-md-6 p-0"
            areasConhecimento={areas_de_conhecimento}
          />
        )}

        {(grupos_de_pesquisa?.length ?? 0) > 0 && (
          <InfoGrupoDePesquisa
            classes="p-0 pt-4"
            gruposDePesquisa={grupos_de_pesquisa}
          />
        )}
      </div>

      {(premiacoes?.length ?? 0) > 0 && (
        <InfoPremiacoes classes="mb-4" premiacoes={premiacoes} />
      )}

      {(tecnologias?.length ?? 0) > 0 && (
        <InfoTecnologias tecnologias={tecnologias} />
      )}
    </section>
  );
};
