import { useContext } from "react";
import { ProgressBar } from "react-bootstrap";

import { PerfilInvestidorContext } from "../../../../globalStore/perfilInvestidor/PerfilInvestidorContext";
import PerfilNotFinded from "../PerfilNotFinded";
import { PerfilInfoInvestidorAnjo } from "./PerfilInfoInvestidorAnjo";
import { CurrentUserContext } from "../../../../globalStore/CurrentUserContext";

export const PerfilInfoInvestidorAnjoFromContext = () => {
  const { perfilInvestidorResult, refreshInvestidor, updateInvestidor } =
    useContext(PerfilInvestidorContext);
  const { currentUserAuth, seeAsPublic } = useContext(CurrentUserContext);

  return (
    <>
      {perfilInvestidorResult == undefined ? (
        <ProgressBar animated variant="info" now={100} className="w-100" />
      ) : (
        <>
          {perfilInvestidorResult.ok ? (
            <PerfilInfoInvestidorAnjo
              tese_de_investimento={
                perfilInvestidorResult.value.tese_de_investimento
              }
              interesse_startup_estagio={
                perfilInvestidorResult.value.interesse_startup_estagio
              }
              interesse_startup_modelo_negocio={
                perfilInvestidorResult.value.interesse_startup_modelo_negocio
              }
              interesse_startup_segmento_atuacao={
                perfilInvestidorResult.value.interesse_startup_segmento_atuacao
              }
              interesse_startup_mecanismo_solucao={
                perfilInvestidorResult.value.interesse_startup_mecanismo_solucao
              }
              premiacoes_e_certificados={
                perfilInvestidorResult.value.premiacoes_e_certificados
              }
              associacoes_anjo={perfilInvestidorResult.value.associacoes_anjo}
              interesse_startup_localizacoes={
                perfilInvestidorResult.value.interesse_startup_localizacoes
              }
              startups_investidas={
                perfilInvestidorResult.value.startups_investidas
              }
              areas_de_especializacao={
                perfilInvestidorResult.value.areas_de_especializacao
              }
              refreshInvestidor={refreshInvestidor}
              updateInvestidor={
                currentUserAuth.user_auth?.auth_id ==
                  perfilInvestidorResult.value.user_id && !seeAsPublic
                  ? updateInvestidor
                  : undefined
              }
            />
          ) : (
            <PerfilNotFinded perfil="investidor" />
          )}
        </>
      )}
    </>
  );
};
