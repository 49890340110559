import { Firestore, addDoc, collection } from "@firebase/firestore";

import { Result } from "../typings";
import { HandlerCreateAssociacaoAnjo } from "../domain/usecases/interfaces/HandlerCreateAssociacaoAnjo";
import {
  AssociacaoAnjo,
  AssociacaoAnjoInput,
  AssociacaoAnjoOutput,
} from "../domain/entities/associacaoAnjo";

export class HandlerCreateAssociacaoAnjoFirebase
  implements HandlerCreateAssociacaoAnjo
{
  constructor(private firestore: Firestore) {}

  async createAssociacaoAnjo(
    inputAssociacaoAnjo: AssociacaoAnjoInput
  ): Promise<Result<AssociacaoAnjoOutput, Error>> {
    const createAssosiacaoAnjoResult =
      AssociacaoAnjo.create(inputAssociacaoAnjo);

    if (!createAssosiacaoAnjoResult.ok) {
      return createAssosiacaoAnjoResult;
    }

    return new Promise((resolve) => {
      addDoc(
        collection(this.firestore, "associacoes_anjo"),
        inputAssociacaoAnjo
      )
        .then((docAssociacao) =>
          resolve({
            ok: true,
            value: {
              id: docAssociacao.id,
              nome: inputAssociacaoAnjo.nome,
              validado: false,
              gestores: inputAssociacaoAnjo.gestores,
            },
          })
        )
        .catch((error) =>
          resolve({
            ok: false,
            error,
          })
        );
    });
  }
}
