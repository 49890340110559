import { useContext } from "react";

import HTMLHead from "../../generalComponents/HTMLHead";
import ButtonWhite from "../../generalComponents/Button";
import imageScyggz from "../../../assets/images/splash01b.gif";
import logoScyggz from "../../../assets/images/logo-scyggz.png";
import logoParceiros from "../../../assets/images/agits.png";
import { ResizeContext } from "../../../hooks/useResize";

export function ConteudoHomeExternal() {
  const { responsive } = useContext(ResizeContext);

  return (
    <>
      <HTMLHead title="Home" description="Essa é a home" />
      <section
        data-testid="homeExternal"
        className="px-3"
        style={{ backgroundColor: "white" }}
      >
        <div className="container mb-5">
          <div className="d-flex justify-content-center justify-content-md-between align-items-center mb-5 flex-wrap gap-3 ">
            <img src={logoScyggz} width={200} alt="" />
            <nav>
              <ButtonWhite
                link="/login"
                classes={`${responsive.sm ? "mb-2" : ""} buttonWhite`}
                children={"Login"}
              />
              <ButtonWhite
                classes="buttonGreen"
                children={"Cadastre-se"}
                link="/cadastro"
              />
            </nav>
          </div>

          <div className="row">
            <p className="col-md-7 col-12 mb-2 fw-bold">
              Conecte nessa rede de profissionais da Academia (Universidade) e o
              Mercado (Empresas e Investidores) com o objetivo de transformar
              invenção em inovação.
            </p>

            <div className="col-lg-7 order-1 order-lg-0 mt-4">
              <h2 className="my-3">Escolha seu perfil abaixo:</h2>

              <div className="row">
                <ButtonWhite
                  classes="buttonWhiteHome fw-bold mx-0 "
                  className="col-md-4"
                  link="/cadastro/cientista"
                >
                  Cientista
                </ButtonWhite>

                <ButtonWhite
                  classes="buttonWhiteHome fw-bold mx-0"
                  link="/cadastro/investidor"
                  className="col-md-4"
                >
                  Investidor Anjo
                </ButtonWhite>

                <ButtonWhite
                  classes="buttonWhiteHome fw-bold mx-0"
                  link="/cadastro/mentor"
                  className="col-md-4"
                >
                  Mentor
                </ButtonWhite>
              </div>

              <h2 className="mb-3 mt-5">Cadastre sua organização:</h2>

              <div className="row">
                <ButtonWhite
                  classes="buttonWhiteHome fw-bold mx-0"
                  link="/cadastro/startup"
                  className="col-md-4"
                >
                  Startup
                </ButtonWhite>

                <div className="col-md-4">
                  <ButtonWhite
                    classes="buttonWhiteHome fw-bold mx-0"
                    link="/cadastro/hub"
                    className="col-md-4"
                    disabled
                  >
                    Hub de Inovação
                  </ButtonWhite>
                </div>

                <div className="col-md-4">
                  <ButtonWhite
                    classes="buttonWhiteHome fw-bold mx-0"
                    link="/cadastro/nit"
                    className="col-md-4"
                    disabled
                  >
                    NIT
                  </ButtonWhite>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <ButtonWhite
                    classes="buttonWhiteHome fw-bold mx-0"
                    link="/cadastro/empresa"
                    className="col-md-4"
                    disabled
                  >
                    Empresa
                  </ButtonWhite>
                </div>

                <div className="col-md-4">
                  <ButtonWhite
                    classes="buttonWhiteHome fw-bold mx-0"
                    link="/cadastro/venture_capital"
                    className="col-md-4"
                    disabled
                  >
                    Venture Capital
                  </ButtonWhite>
                </div>
              </div>
            </div>

            <div
              className={`${
                responsive.sm ? "p-3" : "p-5"
              } col-lg-5 text-center`}
            >
              <img
                className="img-fluid pull-right"
                src={imageScyggz}
                style={{
                  width: "100%",
                  borderRadius: "16px",
                  maxWidth: "450px",
                }}
                alt="Logo Scyggz"
              />
            </div>
            <div
              className={`${responsive.sm ? "text-center mt-4" : ""} order-2`}
            >
              <h4
                className="fw-bold"
                style={{ fontSize: "20px", color: "#525200" }}
              >
                Parceiros:
              </h4>
              <img src={logoParceiros} width={100} alt="logoParceiros" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
