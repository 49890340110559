import { useMemo } from "react";
import { useParams } from "react-router-dom";

export function useAbaProgramaToShow(abaParamKey: string, isGestor: boolean) {
  const params = useParams();

  const abaProgramaSelected = useMemo<AbaPrograma>(() => {
    const paramValue = params[abaParamKey];

    if (paramValue && AllAbasPrograma.includes(paramValue as AbaPrograma)) {
      return paramValue as AbaPrograma;
    } else {
      return "mural";
    }
  }, [abaParamKey, params]);

  const abasPermitidas = useMemo<{ tipo: AbaPrograma; label: string }[]>(() => {
    const abas: { tipo: AbaPrograma; label: string }[] = Object.entries(
      AllLabelAbasPrograma
    ).map(([tipo, label]) => ({
      tipo: tipo as AbaPrograma,
      label,
    }));

    if (!isGestor) {
      return abas.filter(({ tipo }) => tipo != "estatisticas");
    }

    return abas;
  }, [isGestor]);

  const abaToShow = useMemo<AbaPrograma | undefined>(() => {
    if (abasPermitidas.some(({ tipo }) => tipo == abaProgramaSelected)) {
      return abaProgramaSelected;
    }

    return abasPermitidas.at(0)?.tipo;
  }, [abaProgramaSelected, abasPermitidas]);

  return { abaToShow, abasPermitidas };
}

export const AllAbasPrograma = ["mural", "membros", "estatisticas"] as const;
export type AbaPrograma = (typeof AllAbasPrograma)[number];
export const AllLabelAbasPrograma: Record<AbaPrograma, string> = {
  mural: "Mural",
  membros: "Membros",
  estatisticas: "Estatísticas",
};
