import { useContext } from "react";

import { ConexaoEntreUsuariosContext } from "../../../globalStore/conexaoEntreUsuarios/ConexaoEntreUsuariosContext";
import { HandlerConexaoContext } from "../../../globalStore/providerHandlerConexao/HandlerConexaoContext";
import { CurrentUserContext } from "../../../globalStore/CurrentUserContext";
import { UsuarioInfoContext } from "../../../globalStore/UsuarioInfoContext";
import { HeaderUsuario } from "./HeaderUsuario";
import HTMLHead from "../../generalComponents/HTMLHead";
import UserNotFinded from "./UserNotFinded";

export default function HeaderUsuarioFromContext() {
  const { usuarioInfoResult } = useContext(UsuarioInfoContext);
  const { conexaoEntreUsuariosResult, refreshConexaoEntreUsuarios } =
    useContext(ConexaoEntreUsuariosContext);
  const { currentUserScyggz } = useContext(CurrentUserContext);
  const { handlerUpdateAceiteConexao, handlerCreateConexao } = useContext(
    HandlerConexaoContext
  );

  return (
    <>
      {usuarioInfoResult ? (
        <>
          {usuarioInfoResult.ok && currentUserScyggz.user && (
            <>
              <HTMLHead
                title={usuarioInfoResult.value.nome}
                description={
                  "Informações usuário " + usuarioInfoResult.value.nome
                }
              />

              <HeaderUsuario
                user={usuarioInfoResult.value}
                currentUser={currentUserScyggz.user}
                onAdicionarConexao={function (autor, alvo): void {
                  if (handlerCreateConexao) {
                    handlerCreateConexao.create({ autor, alvo }).then(() => {
                      refreshConexaoEntreUsuarios();
                    });
                  } else {
                    console.warn(
                      "Missing providers",
                      "handlerCreateConexao",
                      handlerCreateConexao
                    );
                  }
                }}
                onRemoverConexao={function (): void {
                  if (
                    handlerUpdateAceiteConexao &&
                    conexaoEntreUsuariosResult?.ok
                  ) {
                    refreshConexaoEntreUsuarios();
                    handlerUpdateAceiteConexao
                      ?.aceitarConexao(conexaoEntreUsuariosResult.value, false)
                      .then(() => refreshConexaoEntreUsuarios());
                  } else {
                    console.warn(
                      "Missing providers",
                      "handlerUpdateAceiteConexao",
                      handlerUpdateAceiteConexao,
                      "conexaoEntreUsuariosResult",
                      conexaoEntreUsuariosResult
                    );
                  }
                }}
                conexaoComCurrentUserResult={conexaoEntreUsuariosResult}
                onAceitarConexao={function (): void {
                  console.warn("onAceitarConexao");

                  if (
                    handlerUpdateAceiteConexao &&
                    conexaoEntreUsuariosResult?.ok
                  ) {
                    // refreshConexaoEntreUsuarios();
                    handlerUpdateAceiteConexao
                      .aceitarConexao(conexaoEntreUsuariosResult.value, true)
                      .then(() => refreshConexaoEntreUsuarios());
                  } else {
                    console.warn(
                      "Missing providers",
                      "handlerUpdateAceiteConexao",
                      handlerUpdateAceiteConexao,
                      "conexaoEntreUsuariosResult",
                      conexaoEntreUsuariosResult
                    );
                  }
                }}
              />
            </>
          )}
        </>
      ) : (
        <div
          className="ms-2 flex-fill"
          style={{
            paddingTop: "9vw",
            display: "inline-block",
          }}
        >
          <UserNotFinded />
        </div>
      )}
    </>
  );
}
