import { useEffect, useState } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { TecnologiaDoCientista } from "../../../domain/entities/tecnologiaDoCientista";
import { InputText } from "../InputText";
import { InputAreasAplicacaoTecnologia } from "../InputAreasAplicacaoTecnologia";
import { InputAreasConhecimento } from "../InputAreasConhecimento";
import { InputMecanismosSolucao } from "../InputMecanismosSolucao";
import { InputTextarea } from "../InputTextarea";

export function InputAddTecnologia({
  onAdd,
  onCancel,
  onChange,
}: {
  onAdd: (tecnologia: TecnologiaDoCientista) => void;
  onCancel: () => void;
  onChange: () => void;
}) {
  const [tecnologia, setTecnologia] = useState<TecnologiaDoCientista>({
    nome: "",
    areas_de_aplicacao: [],
    areas_de_conhecimento: [],
    mecanismos_de_solucao: [],
    resumo: "",
  });
  const [errors, setErrors] =
    useState<Partial<Record<keyof TecnologiaDoCientista, string>>>();

  useEffect(() => {
    const tecnologiaResult = TecnologiaDoCientista.validate(tecnologia);

    if (tecnologiaResult.ok) {
      setErrors(undefined);
    } else {
      const error = tecnologiaResult.error;

      setErrors({
        nome: error.nome?.message,
        areas_de_aplicacao: error.areas_de_aplicacao?.message,
        areas_de_conhecimento: error.areas_de_conhecimento?.message,
        mecanismos_de_solucao: error.mecanismos_de_solucao?.message,
        resumo: error.resumo?.message,
      });
    }
  }, [tecnologia]);

  return (
    <div className="shadow p-2 m-3 rounded bg-body-tertiary border">
      {/* <h6 className="text-success">
        <FontAwesomeIcon icon={faPlus} className="me-2" />
        Adicionando
      </h6> */}

      <InputText
        label="Nome da tecnologia:"
        value={tecnologia.nome}
        onChange={(nome) => {
          setTecnologia({ ...tecnologia, nome });
          onChange();
        }}
        id="iptNomeTecnologia"
        title="Nome da tecnologia"
        min={{
          value: TecnologiaDoCientista.NOME_MINIMO_LENGTH,
          errorMessage: TecnologiaDoCientista.NOME_MINIMO_ERROR,
        }}
        max={{
          value: TecnologiaDoCientista.NOME_MAXIMO_LENGTH,
          errorMessage: TecnologiaDoCientista.NOME_MAXIMO_ERROR,
        }}
      />

      <InputAreasAplicacaoTecnologia
        label="Áreas de aplicação da tecnologia*"
        value={tecnologia.areas_de_aplicacao}
        onChange={(areas_de_aplicacao) => {
          setTecnologia({ ...tecnologia, areas_de_aplicacao });
          onChange();
        }}
        validate={TecnologiaDoCientista.validateAreasAplicacaoTecnologia}
        className="mb-3"
      />

      <InputAreasConhecimento
        label="Áreas de conhecimento da tecnologia*"
        value={tecnologia.areas_de_conhecimento}
        onChange={(areas_de_conhecimento) => {
          setTecnologia({ ...tecnologia, areas_de_conhecimento });
          onChange();
        }}
        validate={TecnologiaDoCientista.validateAreasConhecimento}
        className="mb-3"
      />

      <InputMecanismosSolucao
        label="Mecanismo de solução tecnológica*"
        value={tecnologia.mecanismos_de_solucao}
        onChange={(mecanismos_de_solucao) => {
          setTecnologia({ ...tecnologia, mecanismos_de_solucao });
          onChange();
        }}
        validate={TecnologiaDoCientista.validateMecanismosTecnologia}
        className="mb-3"
      />

      <InputTextarea
        label="Resumo da tecnologia*"
        value={tecnologia.resumo}
        onChange={(resumo) => {
          setTecnologia({ ...tecnologia, resumo });
          onChange();
        }}
        id="iptResumoTecnologia"
        title="Resumo da tecnologia"
        min={{
          value: TecnologiaDoCientista.RESUMO_MINIMO_LENGTH,
          errorMessage: TecnologiaDoCientista.RESUMO_MINIMO_ERROR,
        }}
        max={{
          value: TecnologiaDoCientista.RESUMO_MAXIMO_LENGTH,
          errorMessage: TecnologiaDoCientista.RESUMO_MAXIMO_ERROR,
        }}
      />

      <button
        type="button"
        className={
          "btn fw-bolder me-2" +
          (!errors ? " btn-outline-success" : " btn-outline-secondary")
        }
        onClick={() => onAdd(tecnologia)}
        disabled={!!errors}
      >
        Salvar
      </button>
      <button
        type="button"
        className="btn btn-outline-warning fw-bolder"
        onClick={onCancel}
      >
        Cancelar
      </button>
    </div>
  );
}
