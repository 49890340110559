import { EstagioStartup } from "../../../../../domain/entities/EstagioStartup"
import { BadgeInfo } from "./BadgeInfo"
import { TextH2Info } from "./TextH2Info"

export const InfoEstagioCicloDeVida = ({
  estagio
}: {
  estagio: EstagioStartup[]
}) => {

  return (
    <div className="mb-3">
      <TextH2Info children="Estágio do ciclo de vida" />
      {estagio.map((area, i) => (
        <BadgeInfo key={i} children={area} />
      ))}
    </div>
  )
}