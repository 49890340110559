import { Result, Writeable } from "../typings";
import {
  CacheStartup,
  HandlerCacheStartup,
} from "../domain/usecases/interfaces/HandleCacheStartup";

export class HandlerCacheStartupStorage implements HandlerCacheStartup {
  private readonly keyStartupPrefix = "startupCadastroInScyggz";

  clear(): Promise<Result<null>> {
    if (!localStorage) {
      return Promise.resolve({
        ok: false,
        error: new Error(
          "Não fez cache da Startup pois o navegador é incompativel"
        ),
      });
    }

    try {
      localStorage.removeItem(this.keyStartupPrefix);

      return Promise.resolve({
        ok: true,
        value: null,
      });
    } catch (error) {
      return Promise.resolve({
        ok: false,
        error: error as Error,
      });
    }
  }

  async save(startup: CacheStartup): Promise<Result<null, Error>> {
    if (!localStorage) {
      return Promise.resolve({
        ok: false,
        error: new Error(
          "Não fez cache da startup pois o navegador é incompativel"
        ),
      });
    }

    try {
      const getResult = await this.get();

      if (!getResult.ok) {
        return getResult;
      }

      localStorage.setItem(
        this.keyStartupPrefix,
        JSON.stringify({ ...getResult.value, ...startup })
      );

      return Promise.resolve({
        ok: true,
        value: null,
      });
    } catch (error) {
      return Promise.resolve({
        ok: false,
        error: error as Error,
      });
    }
  }

  get(): Promise<Result<CacheStartup, Error>> {
    const startupStorage = localStorage.getItem(this.keyStartupPrefix);

    if (!startupStorage) {
      return Promise.resolve({
        ok: true,
        value: {},
      });
    }

    const startupAny = JSON.parse(startupStorage);

    if (!startupAny) {
      return Promise.resolve({
        ok: true,
        value: {},
      });
    }

    const startup = this.convertAnyToStartup(startupAny);

    return Promise.resolve({
      ok: true,
      value: startup,
    });
  }

  private convertAnyToStartup(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    startupAny: any
  ): Writeable<CacheStartup> {
    const startup: Writeable<CacheStartup> = {};

    if (startupAny.id) startup.id = startupAny.id;

    if (startupAny.ano_fundacao) startup.ano_fundacao = startupAny.ano_fundacao;

    if (startupAny.premiacoes) startup.premiacoes = startupAny.premiacoes;

    if (startupAny.cidade) startup.cidade = startupAny.cidade;

    if (startupAny.nome) startup.nome = startupAny.nome;

    if (startupAny.path_logo) startup.path_logo = startupAny.path_logo;

    if (startupAny.sobre) startup.sobre = startupAny.sobre;

    if (startupAny.telefone) startup.telefone = startupAny.telefone;

    if (startupAny.website) startup.website = startupAny.website;

    if (startupAny.email) startup.email = startupAny.email;

    if (startupAny.linkedin) startup.linkedin = startupAny.linkedin;

    if (startupAny.modelo_negocio)
      startup.modelo_negocio = startupAny.modelo_negocio;

    if (startupAny.segmento_atuacao)
      startup.segmento_atuacao = startupAny.segmento_atuacao;

    if (startupAny.mercado_potencial)
      startup.mercado_potencial = startupAny.mercado_potencial;

    if (startupAny.faturamento_medio_mensal)
      startup.faturamento_medio_mensal = startupAny.faturamento_medio_mensal;

    if (startupAny.estagio) startup.estagio = startupAny.estagio;

    if (startupAny.last_funding_round)
      startup.last_funding_round = startupAny.last_funding_round;

    if (startupAny.interesse_cientista_mecanismo_solucao_tecnologica)
      startup.interesse_cientista_mecanismo_solucao_tecnologica =
        startupAny.interesse_cientista_mecanismo_solucao_tecnologica;

    if (startupAny.qtde_funcionarios)
      startup.qtde_funcionarios = startupAny.qtde_funcionarios;

    if (startupAny.hub) startup.hub = startupAny.hub;

    if (startupAny.path_pitch_pdf)
      startup.path_pitch_pdf = startupAny.path_pitch_pdf;

    if (startupAny.path_pitch_planilha)
      startup.path_pitch_planilha = startupAny.path_pitch_planilha;

    if (startupAny.url_video) startup.url_video = startupAny.url_video;

    if (startupAny.pitchPlanilhaUrl)
      startup.pitchPlanilhaUrl = startupAny.pitchPlanilhaUrl;

    if (startupAny.pitchPdfUrl) startup.pitchPdfUrl = startupAny.pitchPdfUrl;

    if (startupAny.interesse_cientista_area_conhecimento)
      startup.interesse_cientista_area_conhecimento =
        startupAny.interesse_cientista_area_conhecimento;

    if (startupAny.interesse_cientista_instituicao)
      startup.interesse_cientista_instituicao =
        startupAny.interesse_cientista_instituicao;

    if (startupAny.interesse_cientista_area_aplicacao_tecnologia)
      startup.interesse_cientista_area_aplicacao_tecnologia =
        startupAny.interesse_cientista_area_aplicacao_tecnologia;

    return startup;
  }
}
