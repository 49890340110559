import { faClock, faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";

import { Result } from "../../../../../typings";
import { validateHorasDisponibilidadeMensal } from "../../../../../domain/entities/HorasMensal";

export const InfoDisponibilidadeMensal = ({
  disponibilidade = 0,
  canUpdate,
}: {
  disponibilidade?: number;
  canUpdate?: (disponibilidade: number) => Promise<Result<null>>;
}) => {
  const [valueDisponibilidade, setValueDisponibilidade] =
    useState<number>(disponibilidade);
  const [showModalEdit, setShowModalEdit] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>();
  const [carregando, setCarregando] = useState<boolean>(false);

  useEffect(() => {
    setValueDisponibilidade(disponibilidade);
  }, [disponibilidade]);

  useEffect(() => {
    if (canUpdate) {
      const validateUpdateResult =
        validateHorasDisponibilidadeMensal(valueDisponibilidade);

      if (validateUpdateResult.ok) {
        setErrorMsg(undefined);
      } else {
        setErrorMsg(validateUpdateResult.error.message);
      }
    }
  }, [canUpdate, valueDisponibilidade]);

  return (
    <div className={"d-flex align-items-center flex-wrap"}>
      <h5 className="fw-bolder">Disponibilidade mensal (horas)</h5>
      <FontAwesomeIcon icon={faClock} color={"#8c8c8c"} width={20} />
      <b>{disponibilidade}h mensais</b>
      {canUpdate && (
        <button
          type="button"
          className="btn btn-primary rounded-circle ms-2"
          onClick={() => setShowModalEdit(true)}
        >
          <FontAwesomeIcon icon={faPencil} />
        </button>
      )}
      <Modal
        show={showModalEdit}
        onHide={() => setShowModalEdit(false)}
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Modal.Title>Editar disponibilidade</Modal.Title>

          <div className="input-group mt-3">
            <input
              type="number"
              className={"form-control" + (errorMsg ? " is-invalid" : "")}
              placeholder="Disponibilidade..."
              value={valueDisponibilidade}
              required
              onChange={({ target: { value } }) => {
                setValueDisponibilidade(parseInt(value));
              }}
            />
            <span className="input-group-text">horas mensais</span>
            <div className="invalid-feedback">{errorMsg}</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            disabled={!!errorMsg || carregando}
            onClick={async () => {
              if (canUpdate) {
                setCarregando(true);

                const handlerUpdateResult = await canUpdate(
                  valueDisponibilidade
                );

                setCarregando(false);

                if (!handlerUpdateResult.ok) {
                  console.warn(handlerUpdateResult.error);
                  return;
                }
              }

              setShowModalEdit(false);
            }}
          >
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
