import { Firestore, doc, setDoc } from "@firebase/firestore";
import { FirebaseError } from "firebase/app";

import { Result } from "../typings";
import {
  FirestoreErrorsFactory,
  FirestoreError,
} from "../domain/erros/FirestoreErrors";
import {
  HandlerUpdateMentor,
  InputHandlerUpdateMentor,
} from "../domain/usecases/interfaces/handlerUpdateMentor";
import { Mentor } from "../domain/entities/Mentor";

export class HandlerUpdateMentorFirebase implements HandlerUpdateMentor {
  constructor(private firestore: Firestore) {}

  async updateMentor(
    inputMentor: InputHandlerUpdateMentor & { user_id: string }
  ): Promise<
    Result<
      InputHandlerUpdateMentor,
      Error | Partial<Record<keyof Mentor, Error>>
    >
  > {
    const validateMentorResult = Mentor.validateUpdate(inputMentor);

    if (!validateMentorResult.ok) {
      return validateMentorResult;
    }

    return new Promise((resolve) => {
      const docUser = doc(
        this.firestore,
        "usuarios",
        inputMentor.user_id,
        "perfis/mentor"
      );

      setDoc(docUser, inputMentor, {
        merge: true,
      })
        .then(() => {
          resolve({
            ok: true,
            value: inputMentor,
          });
        })
        .catch((error: FirebaseError) => {
          const instanciaErro = FirestoreErrorsFactory.createErrorsValidation(
            <FirestoreError>error.code
          );
          if (instanciaErro) {
            resolve({
              ok: false,
              error: instanciaErro,
            });
            return;
          }

          console.error("Erro na atualização: ", error.code, error.message);

          resolve({
            ok: false,
            error,
          });
        });
    });
  }
}
