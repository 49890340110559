import { ref, uploadBytes, FirebaseStorage } from "firebase/storage";
import { doc, setDoc, Firestore } from "firebase/firestore";

import { Result } from "../typings";
import { HandlerSaveLogoVentureCapital } from "../domain/usecases/interfaces/handlerSaveLogoVentureCapital";
import {
  InputLogoVentureCapital,
  LogoVentureCapital,
  OutputLogoVentureCapital,
} from "../domain/entities/LogoVentureCapital";
import { OutputVentureCapital } from "../domain/entities/VentureCapital";

export class HandlerSaveLogoVentureCapitalFirebase
  implements HandlerSaveLogoVentureCapital
{
  constructor(
    private firebaseStorage: FirebaseStorage,
    private firestore: Firestore
  ) {}

  async save(
    inputLogoVentureCapital: InputLogoVentureCapital
  ): Promise<Result<OutputLogoVentureCapital>> {
    const logoVentureCapitalResult = await LogoVentureCapital.create(
      inputLogoVentureCapital
    );

    if (!logoVentureCapitalResult.ok) return logoVentureCapitalResult;

    const storageRef = ref(
      this.firebaseStorage,
      logoVentureCapitalResult.value.path
    );

    return new Promise((resolve) => {
      uploadBytes(storageRef, logoVentureCapitalResult.value.file)
        .then(async () => {
          const docVentureCapital = doc(
            this.firestore,
            "venture_capital",
            logoVentureCapitalResult.value.id_venture_capital
          );

          const logo: Pick<OutputVentureCapital, "path_logo"> = {
            path_logo: storageRef.fullPath,
          };

          await setDoc(docVentureCapital, logo, { merge: true });

          resolve(logoVentureCapitalResult);
        })
        .catch((error) =>
          resolve({
            ok: false,
            error,
          })
        );
    });
  }
}
