import { ReactNode } from "react";

export function TextoMarcaDaguaMobile({ children }: { children?: ReactNode }) {
  return (
    <span
      style={{
        fontFamily: "Raleway",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "40px",
        lineHeight: "47px",
        color: "rgba(153, 153, 0, 0.02)",
        textShadow: "0px 0px 1px rgba(0, 0, 0, 0.25)",
        letterSpacing: "-0.015em",
      }}
    >
      {children}
    </span>
  );
}