import { Hub } from "../../../../../domain/entities/Hub";
import { CardLogoOrganizacaoCidadeOutput } from "./CardLogoOrganizacaoCidadeOutput";
import { TitleInfo } from "./TitleInfo";

export const InfoHubsDeInvovacao = ({
  hubs,
  classes,
}: {
  hubs?: Pick<Hub, "nome" | "path_logo" | "cidade">[];
  classes?: string;
}) => {
  return (
    <>
      {hubs && hubs.length > 0 &&
        <section className={classes}>
          <TitleInfo classes="mb-3" children="Hubs de Inovação" />
          <div className="ms-2 d-flex gap-3 flex-wrap">
            {hubs?.map((hub, i) => (
              <CardLogoOrganizacaoCidadeOutput
                key={i}
                src={hub.path_logo}
                alt={hub.nome}
                local={hub.cidade}
                nome={hub.nome}
              />
            ))}
          </div>
        </section>
      }
    </>
  );
};