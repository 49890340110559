import { useContext } from "react";
import { ProgressBar } from "react-bootstrap";

import { PerfilNitContext } from "../../../globalStore/perfilNit/PerfilNitContext";
import PerfilInfoNit from "./PerfilInfoNit";

export const PerfilInfoNitFromContext = () => {
  const { perfilNitResult } = useContext(PerfilNitContext);

  if (!perfilNitResult)
    return (
      <ProgressBar animated variant="info" now={100} className="w-100 m-1" />
    );

  return perfilNitResult.ok ? (
    <PerfilInfoNit
      sobre={perfilNitResult.value.sobre}
      cidade={perfilNitResult.value.cidade}
      nome={perfilNitResult.value.nome}
      logo_path={perfilNitResult.value.logo_path}
    />
  ) : (
    <>não encontrado</>
  );
};
