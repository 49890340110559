import { doc, onSnapshot, Timestamp } from "firebase/firestore";
import { BehaviorSubject, Observable } from "rxjs";

import { FirebaseService } from "../../infra/firebase/firebaseService";
import {
  OutputUsuarioScyggzEntity,
  TipoPerfil,
  TIPOS_PERFIL,
} from "../entities/usuarioScyggz";

const firebaseService = FirebaseService.newFirebaseService();

export function getUserInfoObserver(
  id_user: string
): Observable<UserInfoRealtime> {
  const firestore = firebaseService.getFirestore();

  const info$ = new BehaviorSubject<UserInfoRealtime>({
    status: "carregando",
    user: null,
  });

  onSnapshot(doc(firestore, "usuarios", id_user), (docUserSnapshot) => {
    if (docUserSnapshot.exists()) {
      const docUserSnapshotData = docUserSnapshot.data();

      const aceite_termo_de_servico: Timestamp =
        docUserSnapshotData.aceite_termo_de_servico;
      const aceite_politica_de_privacidade: Timestamp =
        docUserSnapshotData.aceite_politica_de_privacidade;

      const usuarioScyggz: OutputUsuarioScyggzEntity = {
        ...docUserSnapshotData,
        auth_user_id: docUserSnapshot.id,
        nome: docUserSnapshotData.nome,
        cidade: docUserSnapshotData.cidade,
        telefone: docUserSnapshotData.telefone,
        aceite_termo_de_servico: aceite_termo_de_servico.toDate(),
        aceite_politica_de_privacidade: aceite_politica_de_privacidade.toDate(),
        avatar_path: docUserSnapshotData.avatar_path,
        url_linkedin: docUserSnapshotData.url_linkedin,
        headline: docUserSnapshotData.headline,
        perfis: Object.keys(docUserSnapshotData.perfis ?? {})
          .filter((perfil) => TIPOS_PERFIL.includes(perfil as TipoPerfil))
          .reduce<{ [id_perfil in TipoPerfil]?: boolean }>((prev, current) => {
            prev[current as TipoPerfil] = !!docUserSnapshotData.perfis[current];
            return prev;
          }, {}),
        url_lattes: docUserSnapshotData.url_lattes,
      };

      info$.next({
        status: "encontrado",
        user: usuarioScyggz,
      });
    } else {
      info$.next({
        status: "nao_encontrado",
        user: null,
      });
    }
  });

  return info$;
}

export type UserInfoRealtime = {
  status: StatusUserInfoRealtime;
  user: OutputUsuarioScyggzEntity | null;
};

type StatusUserInfoRealtime = "carregando" | "encontrado" | "nao_encontrado";
