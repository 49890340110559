import { doc, onSnapshot } from "firebase/firestore";
import { BehaviorSubject, Observable } from "rxjs";

import { FirebaseService } from "../../infra/firebase/firebaseService";
import { OutputUrlLinkedin } from "../entities/urlLinkedin";

const firebaseService = FirebaseService.newFirebaseService();

export function getUrlLinkedinObserver(
  id_user: string
): Observable<UserUrlLinkedinRealtime> {
  const firestore = firebaseService.getFirestore();

  const info$ = new BehaviorSubject<UserUrlLinkedinRealtime>({
    status: "carregando",
    urlLinkedin: null,
  });

  onSnapshot(doc(firestore, "url_linkedin", id_user), (docUserSnapshot) => {
    if (docUserSnapshot.exists()) {
      const docUserSnapshotData = docUserSnapshot.data();

      info$.next({
        status: "encontrado",
        urlLinkedin: {
          auth_id: docUserSnapshot.id,
          urlLinkedin: docUserSnapshotData.urlLinkedin ?? null,
        },
      });
    } else {
      info$.next({
        status: "nao_encontrado",
        urlLinkedin: null,
      });
    }
  });

  return info$;
}

export type UserUrlLinkedinRealtime = {
  status: StatusUserUrlLinkedinRealtime;
  urlLinkedin: OutputUrlLinkedin | null;
};

type StatusUserUrlLinkedinRealtime =
  | "carregando"
  | "encontrado"
  | "nao_encontrado";