import { SegmentoAtuacaoOutput } from "../../../../../domain/entities/segmentoAtuacao";
import { BadgeInfo } from "./BadgeInfo";

export const InfoSegmentoAtuacao = ({
  segmentoAtuacao,
}: {
  segmentoAtuacao: SegmentoAtuacaoOutput[];
}) => {
  return (
    <>
      {segmentoAtuacao.length > 0 && (
        <div className="mb-3">
          <h5 className="fw-lighter">Segmentos de atuação</h5>

          {segmentoAtuacao.map((segmento, i) => (
            <BadgeInfo key={i} children={segmento.nome} />
          ))}
        </div>
      )}
    </>
  );
};
