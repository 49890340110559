import { Result } from "../../typings";
import { Cidade, CidadeOutput } from "./cidade";
import { Telefone } from "./telefone";
import { UrlLattes } from "./UrlLattes";
import { UrlLinkedin } from "./urlLinkedin";
import { UsuarioScyggz } from "./usuarioScyggz";

export class UsuarioScyggzValidator {
  static readonly MIN_SIZE_NOME = 3;

  static readonly MIN_SIZE_PASSWORD = 6;
  static readonly MIN_SIZE_PASSWORD_ERROR =
    "Senha muito pequena, mínimo " + this.MIN_SIZE_PASSWORD + " caracteres";

  static readonly MAX_SIZE_HEADLINE = 155;

  static validateUpdate(
    attributes: Partial<UsuarioScyggz>
  ): Result<null, Partial<Record<keyof UsuarioScyggz, Error>>> {
    const errors: Partial<Record<keyof UsuarioScyggz, Error>> = {};
    const keys: (keyof UsuarioScyggz)[] = Object.keys(
      attributes
    ) as (keyof UsuarioScyggz)[];

    if (keys.includes("auth_user_id")) {
      const error = UsuarioScyggzValidator.isInvalidUserId(
        attributes.auth_user_id
      );
      if (error) {
        errors.auth_user_id = error;
      }
    }

    if (keys.includes("nome")) {
      const error = UsuarioScyggzValidator.isInvalidNome(attributes.nome);
      if (error) {
        errors.nome = error;
      }
    }

    if (keys.includes("cidade")) {
      const error = UsuarioScyggzValidator.isInvalidCidade(attributes.cidade);
      if (error) {
        errors.cidade = error;
      }
    }

    if (keys.includes("telefone")) {
      const errorResult = Telefone.create({
        auth_id: "...",
        numero: attributes.telefone ?? "",
      });
      if (!errorResult.ok) {
        errors.telefone = errorResult.error;
      }
    }

    if (keys.includes("url_linkedin") && attributes.url_linkedin) {
      const errorResult = UrlLinkedin.create({
        auth_id: "...",
        urlLinkedin: attributes.url_linkedin,
      });
      if (!errorResult.ok) {
        errors.url_linkedin = errorResult.error;
      }
    }

    if (keys.includes("headline")) {
      const error = UsuarioScyggzValidator.isInvalidHeadline(
        attributes.headline
      );
      if (error) {
        errors.headline = error;
      }
    }

    if (keys.includes("url_lattes") && attributes.url_lattes != undefined) {
      const errorResult = UrlLattes.create({
        auth_id: "...",
        urlLattes: attributes.url_lattes,
      });
      if (!errorResult.ok) {
        errors.url_lattes = errorResult.error;
      }
    }

    if (Object.keys(errors).length > 0) {
      return {
        ok: false,
        error: errors,
      };
    }

    return {
      ok: true,
      value: null,
    };
  }

  static validateCreate(
    attributes: UsuarioScyggz
  ): Result<null, Partial<Record<keyof UsuarioScyggz, Error>>> {
    const errors: Partial<Record<keyof UsuarioScyggz, Error>> = {};

    let error = UsuarioScyggzValidator.isInvalidUserId(attributes.auth_user_id);
    if (error) {
      errors.auth_user_id = error;
    }

    error = UsuarioScyggzValidator.isInvalidNome(attributes.nome);
    if (error) {
      errors.nome = error;
    }

    const errorCidade = UsuarioScyggzValidator.isInvalidCidade(
      attributes.cidade
    );
    if (errorCidade) {
      errors.cidade = errorCidade;
    }

    const errorTelefoneResult = Telefone.create({
      auth_id: "...",
      numero: attributes.telefone,
    });
    if (!errorTelefoneResult.ok) {
      errors.telefone = errorTelefoneResult.error;
    }

    if (attributes.url_linkedin != undefined) {
      const error = this.isInvalidLinkedin(attributes.url_linkedin);
      if (error) {
        errors.url_linkedin = error;
      }
    }

    if (attributes.headline != undefined) {
      const error = UsuarioScyggzValidator.isInvalidHeadline(
        attributes.headline
      );
      if (error) {
        errors.headline = error;
      }
    }

    if (attributes.url_lattes != undefined) {
      const error = this.isInvalidLattes(attributes.url_lattes);
      if (error) {
        errors.url_lattes = error;
      }
    }

    if (Object.keys(errors).length > 0) {
      return {
        ok: false,
        error: errors,
      };
    }

    return {
      ok: true,
      value: null,
    };
  }

  static isInvalidUserId(user_id?: string): Error | undefined {
    if (!user_id || user_id.trim().length < 1) {
      return new Error("User id inválido");
    }
  }

  public static isInvalidNome(nome?: string): Error | undefined {
    const isInvalid: boolean = (nome ?? "").trim().length < this.MIN_SIZE_NOME;
    if (isInvalid) {
      return new Error("Nome muito curto");
    }
  }

  public static isInvalidHeadline(nome?: string): Error | undefined {
    const isInvalid: boolean =
      (nome ?? "").trim().length > this.MAX_SIZE_HEADLINE;
    if (isInvalid) {
      return new Error("Muito longo");
    }
  }

  public static isInvalidCidade(
    cidade: CidadeOutput | undefined
  ): Error | undefined {
    if (!cidade) return new Error("Cidade obrigatória");

    const isInvalidResult = Cidade.create(cidade);
    if (!isInvalidResult.ok) {
      return new Error("Cidade inválida");
    }
  }

  public static isInvalidPassword(
    password: string | undefined
  ): Error | undefined {
    if (!password) return new Error("Senha obrigatória");

    if (password.length < this.MIN_SIZE_PASSWORD) {
      return new Error(this.MIN_SIZE_PASSWORD_ERROR);
    }
  }

  public static isInvalidLattes(lattes: string | undefined): Error | undefined {
    if (!lattes) return undefined;

    const errorResult = UrlLattes.create({
      auth_id: "...",
      urlLattes: lattes,
    });
    if (!errorResult.ok) {
      return errorResult.error;
    }
  }

  public static isInvalidLinkedin(
    linkedin: string | undefined
  ): Error | undefined {
    if (!linkedin) return undefined;

    const errorResult = UrlLinkedin.create({
      auth_id: "...",
      urlLinkedin: linkedin,
    });
    if (!errorResult.ok) {
      return errorResult.error;
    }
  }
}
