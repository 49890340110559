import { CidadeOutput } from "../../../../../domain/entities/cidade";


export const CardLogoOrganizacaoCidadeOutput = ({
  nome,
  src,
  alt,
  local
}: {
  nome: string;
  src?: string;
  alt?: string;
  local?: CidadeOutput;
}) => {

  return (
    <div
      className="border rounded-4 border-dark d-flex align-items-center justify-content-center gap-3"
      style={{ height: "131px", width: "257px", padding: "15px" }}
    >

      <img className="border rounded-circle " style={{ height: "73px", width: "73px", objectFit: "cover" }} src={src} alt={alt} />
      <div className="d-flex flex-column align-items-start">
        <h4 style={{ fontSize: "18px", fontWeight: "700" }}>{nome}</h4>
        <span style={{ fontSize: "11px", fontWeight: "500" }}>{`${local?.cidade}, ${local?.estado} - ${local?.pais}`}</span>
      </div>
    </div>
  )
}