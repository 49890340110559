import { useCallback, useState } from "react";

import { HandlerCache } from "../../domain/usecases/interfaces/HandlerCache";
import {
  MAXIMO_HORAS_DISPONIBILIDADE_MENSAL,
  MINIMO_HORAS_DISPONIBILIDADE_MENSAL,
  validateHorasDisponibilidadeMensal,
} from "../../domain/entities/HorasMensal";

export function InputHorasMensal<
  T,
  X extends keyof T,
  Y extends Record<X, number>
>({
  label,
  value,
  onChange,
  cacheOnChange,
}: {
  label: string;
  value: number;
  onChange: (horas: number) => void;
  cacheOnChange?: {
    handler: HandlerCache<T> & { save: (toSave: Y) => unknown };
    field: X;
  };
}) {
  const [error, setError] = useState<string>();

  const handlerChange = useCallback(
    (horas: number) => {
      onChange(horas);

      if (cacheOnChange) {
        const toCache: Y = {} as Y;
        toCache[cacheOnChange.field] = horas as never;
        cacheOnChange.handler.save(toCache);
      }

      const validateResult = validateHorasDisponibilidadeMensal(horas);
      if (validateResult.ok) {
        setError(undefined);
      } else {
        setError(validateResult.error.message);
      }
    },
    [cacheOnChange, onChange]
  );

  return (
    <div className={"mb-3"}>
      <label className="form-label fw-bolder" htmlFor="iptHorasMensal">
        {label}
      </label>

      <input
        className={"form-control" + (error ? " is-invalid" : "")}
        type="number"
        min={MINIMO_HORAS_DISPONIBILIDADE_MENSAL}
        max={MAXIMO_HORAS_DISPONIBILIDADE_MENSAL}
        onChange={({ target: { value } }) => {
          handlerChange(value ? parseInt(value) : 0);
        }}
        id="iptHorasMensal"
        value={value}
      />

      <div className="invalid-feedback">{error}</div>
    </div>
  );
}
