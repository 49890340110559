import { Result } from "../../typings";
import { Cidade, CidadeOutput } from "./cidade";
import { EstagioStartup } from "./EstagioStartup";
import { FundingRound } from "./FundingRound";
import { ValorFinanceiro } from "./ValorFinanceiro";
import { Premiacao } from "./premiacao";
import { MecanismoDeSolucaoTecnologica } from "./mecanismoDeSolucaoTecnologica";
import { ModeloNegocio } from "./modeloNegocio";
import { SegmentoAtuacao } from "./segmentoAtuacao";
import { AreaConhecimento } from "./areaConhecimento";
import { AreaAplicacaoTecnologia } from "./areaAplicacaoTecnologia";
import { UsuarioScyggz } from "./usuarioScyggz";
import { InstituicaoDeCienciaETecnologia } from "./instituicaoDeCienciaETecnologia";
import { Hub } from "./Hub";

export class Startup {
  public id = "";

  static readonly SOBRE_MINIMO_LENGTH = 100;
  static readonly SOBRE_MINIMO_MESSAGE =
    "Mínimo de " + this.SOBRE_MINIMO_LENGTH + " caracteres";
  static readonly SOBRE_MAXIMO_LENGTH = 500;
  static readonly SOBRE_MAXIMO_MESSAGE =
    "Máximo de " + this.SOBRE_MAXIMO_LENGTH + " caracteres";
  static readonly QTDE_FUNCIONARIOS_MINIMO = 1;
  static readonly QTDE_FUNCIONARIOS_MINIMO_MESSAGE =
    "Deve ter no mínimo " + this.QTDE_FUNCIONARIOS_MINIMO;

  private constructor(private input: InputStartup) {}

  public static create(input: InputStartup): Result<Startup> {
    let errors: Error[] = [];

    // TODO: validate input startup entity

    const validateResult = this.validateUpdate({ ...input, id: "" });

    if (validateResult) errors = Object.values(validateResult);

    if (errors.length > 0) {
      return {
        ok: false,
        error: errors[0],
      };
    }

    return {
      ok: true,
      value: new Startup(input),
    };
  }

  public get nome(): string {
    return this.input.nome;
  }
  public get sobre(): string {
    return this.input.sobre;
  }

  public get cidade(): CidadeOutput {
    return this.input.cidade;
  }

  public get path_logo(): string | undefined {
    return this.input.path_logo;
  }

  public get ano_fundacao(): number {
    return this.input.ano_fundacao;
  }

  public get telefone(): string | undefined {
    return this.input.telefone;
  }

  public get website(): string | undefined {
    return this.input.website;
  }

  public get email(): string | undefined {
    return this.input.email;
  }

  public get linkedin(): string | undefined {
    return this.input.linkedin;
  }

  public get premiacoes() {
    return this.input.premiacoes;
  }

  public get modelo_negocio() {
    return this.input.modelo_negocio;
  }

  public get segmento_atuacao() {
    return this.input.segmento_atuacao;
  }

  public get mercado_potencial() {
    return this.input.mercado_potencial;
  }

  public get faturamento_medio_mensal() {
    return this.input.faturamento_medio_mensal;
  }

  public get estagio() {
    return this.input.estagio;
  }

  public get last_funding_round() {
    return this.input.last_funding_round;
  }

  public get qtde_funcionarios() {
    return this.input.qtde_funcionarios;
  }

  public get interesse_cientista_mecanismo_solucao_tecnologica() {
    return this.input.interesse_cientista_mecanismo_solucao_tecnologica;
  }

  public get hub() {
    return this.input.hub;
  }

  public get mecanismo_solucao() {
    return this.input.mecanismo_solucao;
  }

  public get path_pitch_pdf() {
    return this.input.path_pitch_pdf;
  }

  public get url_video() {
    return this.input.url_video;
  }

  public get path_pitch_planilha() {
    return this.input.path_pitch_planilha;
  }

  public get gestores() {
    return this.input.gestores;
  }

  public get interesse_cientista_area_conhecimento() {
    return this.input.interesse_cientista_area_conhecimento;
  }

  public get interesse_cientista_instituicao() {
    return this.input.interesse_cientista_instituicao;
  }

  public get interesse_cientista_area_aplicacao_tecnologia() {
    return this.input.interesse_cientista_area_aplicacao_tecnologia;
  }

  public static validateSegmentoAtuacao(
    segmento_atuacao: SegmentoAtuacao[]
  ): Error | undefined {
    const isInteresseStartupSegmentoAtuacaoValid: boolean =
      segmento_atuacao.length > 0;
    if (!isInteresseStartupSegmentoAtuacaoValid) {
      return new Error("Precisa especificar segmento de atuação da startup");
    }
  }

  public static validateModelosNegocio(
    modelo_negocio: ModeloNegocio[]
  ): Error | undefined {
    const isModelosNegocioValid: boolean = modelo_negocio.length > 0;
    if (!isModelosNegocioValid) {
      return new Error("Precisa especificar modelo de negócio");
    }
  }

  public static validateMecanismoSolucao(): Error | undefined {
    return undefined;
  }

  public static validatePremiacoes(): Error | undefined {
    return undefined;
  }

  public static validateHub(): Error | undefined {
    return undefined;
  }

  public static validateEstagio(estagio?: EstagioStartup): Error | undefined {
    if (!estagio) return new Error("Precisa especificar estágio");
  }

  public static validateSobre(sobre: string): Error | undefined {
    const lenght = sobre.trim().length;

    if (lenght < this.SOBRE_MINIMO_LENGTH) {
      return new Error(this.SOBRE_MINIMO_MESSAGE);
    }

    if (length > this.SOBRE_MAXIMO_LENGTH) {
      return new Error(this.SOBRE_MAXIMO_MESSAGE);
    }
  }

  public static validateQtdeFuncionarios(qtde: number): Error | undefined {
    if (qtde < this.QTDE_FUNCIONARIOS_MINIMO) {
      return new Error(this.QTDE_FUNCIONARIOS_MINIMO_MESSAGE);
    }
  }

  public static validateCidade(
    cidade: CidadeOutput | undefined
  ): Error | undefined {
    if (!cidade) return new Error("Cidade obrigatória");

    const isInvalidResult = Cidade.create(cidade);
    if (!isInvalidResult.ok) {
      return new Error("Cidade inválida");
    }
  }

  public static validateUpdate(
    input: InputUpdateStartup
  ): Partial<Record<keyof InputUpdateStartup, Error>> | undefined {
    const errors: Partial<Record<keyof InputUpdateStartup, Error>> = {};

    const keys: (keyof InputUpdateStartup)[] = Object.keys(
      input
    ) as (keyof InputUpdateStartup)[];

    if (keys.includes("sobre")) {
      const error = this.validateSobre(input.sobre ?? "");
      if (error) {
        errors.sobre = error;
      }
    }

    if (keys.includes("premiacoes")) {
      const error = this.validatePremiacoes();
      if (error) {
        errors.premiacoes = error;
      }
    }

    if (keys.includes("cidade")) {
      const error = this.validateCidade(input.cidade);
      if (error) {
        errors.cidade = error;
      }
    }

    if (keys.includes("modelo_negocio")) {
      const error = this.validateModelosNegocio(input.modelo_negocio ?? []);
      if (error) {
        errors.modelo_negocio = error;
      }
    }

    if (keys.includes("segmento_atuacao")) {
      const error = this.validateSegmentoAtuacao(input.segmento_atuacao ?? []);
      if (error) {
        errors.segmento_atuacao = error;
      }
    }

    if (keys.includes("mecanismo_solucao")) {
      const error = this.validateMecanismoSolucao();
      if (error) {
        errors.mecanismo_solucao = error;
      }
    }

    if (keys.includes("qtde_funcionarios")) {
      const error = this.validateQtdeFuncionarios(input.qtde_funcionarios ?? 0);
      if (error) {
        errors.qtde_funcionarios = error;
      }
    }

    if (keys.includes("hub")) {
      const error = this.validateHub();
      if (error) {
        errors.hub = error;
      }
    }

    if (Object.keys(errors).length > 0) return errors;
  }
}

export type InputStartup = {
  nome: string;
  cidade: CidadeOutput;
  ano_fundacao: number;
  email: string;
  estagio: EstagioStartup;
  modelo_negocio: Pick<ModeloNegocio, "id" | "nome">[];
  segmento_atuacao: Pick<SegmentoAtuacao, "id" | "nome">[];
  gestores: string[];
  qtde_funcionarios: number;
  sobre: string;
  created_in: Date;
  habilitado: boolean;
  recusado: boolean;
} & Partial<{
  last_funding_round: {
    tipo: FundingRound;
    valor_captado: Pick<ValorFinanceiro, "moeda" | "valor">;
  };
  faturamento_medio_mensal: Pick<ValorFinanceiro, "moeda" | "valor">;
  path_logo: string;
  mecanismo_solucao: Pick<MecanismoDeSolucaoTecnologica, "id" | "nome">[];
  mercado_potencial: Pick<ValorFinanceiro, "moeda" | "valor">;
  path_pitch_pdf: string;
  path_pitch_planilha: string;
  url_video: string; // TODO: VALIDATE URL
  telefone: string;
  website: string; // TODO: VALIDATE URL
  linkedin: string; // TODO: VALIDATE URL
  hub: {
    status: StatusStartupHub;
    hub: Pick<Hub, "id" | "nome">;
  };
  investidores: Pick<UsuarioScyggz, "auth_user_id" | "nome">[];
  premiacoes: Pick<Premiacao, "nome" | "descricao">[];
  interesse_cientista_area_conhecimento: Pick<
    AreaConhecimento,
    "id" | "nome"
  >[];
  interesse_cientista_area_aplicacao_tecnologia: Pick<
    AreaAplicacaoTecnologia,
    "id" | "nome"
  >[];
  interesse_cientista_instituicao: Pick<
    InstituicaoDeCienciaETecnologia,
    "id" | "nome" | "unidades"
  >[];
  interesse_cientista_mecanismo_solucao_tecnologica: Pick<
    MecanismoDeSolucaoTecnologica,
    "id" | "nome"
  >[];
}>;

export type OutputStartup = InputStartup & {
  id: string;
};

export type StartupSearchAlgolia = InputStartup & {
  objectID: string;
};

export type InputUpdateStartup = {
  id: string;
} & Partial<
  Pick<
    Startup,
    | "sobre"
    | "premiacoes"
    | "estagio"
    | "cidade"
    | "hub"
    | "modelo_negocio"
    | "segmento_atuacao"
    | "mecanismo_solucao"
    | "qtde_funcionarios"
    | "interesse_cientista_area_conhecimento"
    | "interesse_cientista_area_aplicacao_tecnologia"
    | "interesse_cientista_instituicao"
    | "interesse_cientista_mecanismo_solucao_tecnologica"
  >
>;

export type InputUpdateStartupHeader = {
  id: string;
} & Partial<Pick<Startup, "cidade" | "nome">>;

export const STATUS_STARTUP_HUB = ["graduada", "incubada"] as const;

export type StatusStartupHub = (typeof STATUS_STARTUP_HUB)[number];
