import { LocalizacaoOutput } from "../../../../../domain/entities/localizacao";
import { BadgeInfo } from "./BadgeInfo";

export const InfoLocalidade = ({
  localidades,
}: {
  localidades?: LocalizacaoOutput[];
}) => {
  return (
    <div className="mb-3">
      <h5 className="text-green-strong fw-bolder">Localidade</h5>

      {localidades?.map((local, i) => {
        switch (local.tipo) {
          case "cidade":
            return (
              <BadgeInfo
                key={i}
                children={local.cidade + " - " + local.estado}
              />
            );
          case "estado":
            return <BadgeInfo key={i} children={local.estado} />;
          case "pais":
            return <BadgeInfo key={i} children={local.pais} />;
        }
      })}
    </div>
  );
};
