import { createContext } from "react";

import { HandlerGetURLFromPath } from "../../domain/usecases/interfaces/HandlerGetURLFromPath";
import { HandlerSaveAvatar } from "../../domain/usecases/interfaces/handlerSaveAvatar";
import { HandlerProgramaConexaoSaveLogo } from "../../domain/usecases/interfaces/HandlerProgramaConexaoSaveLogo";

export const HandlersStorageContext = createContext<TypeHandlersStorageContext>(
  {}
);

export type TypeHandlersStorageContext = {
  handlerGetURLFromPath?: HandlerGetURLFromPath;
  handlerSaveAvatar?: HandlerSaveAvatar;
  handlerProgramaConexaoSaveLogo?: HandlerProgramaConexaoSaveLogo;
};
