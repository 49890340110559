import { useContext, useEffect, useState } from "react";
import { BrowserRouter, PathRouteProps, Route, Routes } from "react-router-dom";

import { UserStatus, userStatusHandler } from "./entities/userStatusHandler";
import { ConexoesStatusCountRealtimeProvider } from "./globalStore/conexoesStatusCount/ConexoesStatusCountRealtimeProvider";
import { CurrentUserContext } from "./globalStore/CurrentUserContext";
import { ResizeContext } from "./hooks/useResize";
import { Footer } from "./presentation/pages/components/footer/Footer";
import HeaderToRender from "./presentation/pages/components/header/HeaderToRender";
import { getRotasAll } from "./routes/getRotasAll";
import { getRotasNotVisitante } from "./routes/getRotasNotVisitante";
import { getRotasVisitante } from "./routes/getRotasVisitante";
import { rotasCarregandoAuth } from "./routes/rotasCarregandoAuth";

const rotas: (PathRouteProps & {
  userStatus: UserStatus[];
  path: string;
  element: JSX.Element;
  showHeader: boolean;
  showFooter: boolean;
})[] = [];

rotas.push(...rotasCarregandoAuth);
rotas.push(...getRotasVisitante());
rotas.push(...getRotasNotVisitante());
rotas.push(...getRotasAll());

function RoutesDefinition() {
  const { responsive } = useContext(ResizeContext);
  const [userStatus, setUserStatus] = useState<UserStatus>("carregando_auth");

  const { currentUserAuth, currentUserScyggz, currentUserPhoneNumber } =
    useContext(CurrentUserContext);

  useEffect(() => {
    setUserStatus(
      userStatusHandler({
        userAuthRealtime: currentUserAuth,
        userInfoRealtime: currentUserScyggz,
        userPhoneNumberRealtime: currentUserPhoneNumber,
        currentUserStatus: userStatus,
      })
    );
  }, [currentUserAuth, currentUserScyggz, currentUserPhoneNumber, userStatus]);

  //useEffect(() => {
    //console.log("USERSTATUS=>", userStatus);
  //}, [userStatus]);

  return (
    <ConexoesStatusCountRealtimeProvider
      user={{ auth_user_id: currentUserScyggz.user?.auth_user_id ?? "" }}
    >
      <BrowserRouter>
        <Routes>{getRotasForUserStatus(userStatus, responsive.sm)}</Routes>
      </BrowserRouter>
    </ConexoesStatusCountRealtimeProvider>
  );
}

export function getRotasForUserStatus(
  userStatus: UserStatus,
  isMobile = false
): JSX.Element {
  const rotasToShow = rotas.filter(
    (rota) =>
      rota.userStatus.length == 0 || rota.userStatus.includes(userStatus)
  );

  return (
    <>
      {rotasToShow.map((rota, index) => (
        <Route
          key={index}
          path={rota.path}
          element={
            <section className="d-flex flex-column vh-100">
              {rota.showHeader ? <HeaderToRender /> : null}
              <main
                className="flex-fill overflow-auto"
                style={{ backgroundColor: "#e2e2cc" }}
              >
                {rota.element}
              </main>
              {rota.showFooter && !isMobile ? <Footer /> : null}
            </section>
          }
        />
      ))}
    </>
  );
}

export default RoutesDefinition;
