import { Result } from "../../typings";

export class LogoVentureCapital {
  private constructor(public id_venture_capital: string, public file: Blob) {}

  public static create({
    id_venture_capital,
    file,
  }: InputLogoVentureCapital): Result<LogoVentureCapital> {
    const errors: Error[] = [];

    id_venture_capital = id_venture_capital.trim();
    const isAuthValid: boolean = id_venture_capital.length > 0;
    if (!isAuthValid) {
      errors.push(new Error("Id venture capital inválido"));
    }

    // TODO: VALIDATE LOGO VENTURE CAPITALÏ

    if (errors.length > 0) {
      return {
        ok: false,
        error: errors[0],
      };
    }

    return {
      ok: true,
      value: new LogoVentureCapital(id_venture_capital, file),
    };
  }

  public get path(): string {
    return (
      "venture_capital/" +
      this.id_venture_capital +
      "/logo/" +
      new Date().getTime().toString()
    );
  }
}

export type InputLogoVentureCapital = {
  id_venture_capital: string;
  file: Blob;
};

export type OutputLogoVentureCapital = {
  path: string;
};
