import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { CurrentUserContext } from "../../../../../globalStore/CurrentUserContext";
import { HandlerSaveTelefone } from "../../../../../domain/usecases/interfaces/handlerSaveTelefone";
import { HandlerCreateUsuarioScyggz } from "../../../../../domain/usecases/interfaces/handlerCreateUsuarioScyggz";
import { getNomeFromStorage } from "../../../../../hooks/handlerNameFromStorage";
import { useIsMounted } from "../../../../../hooks/useIsMounted";
import { ContainerResponsive } from "../../../../generalComponents/ContainerResponsive";
import { ConteudoCadastroInfosPessoaisCientista } from "./ConteudoCadastroInfosPessoaisCientista";
import { OnEnterPrevent } from "../../../../generalComponents/OnEnterPrevent";
import { HandlersDatabaseContext } from "../../../../../globalStore/HandlersProviders/HandlersDatabaseContext";

export function PainelCadastroUsuarioCientista({
  handlerSaveTelefone,
  handlerCreateUsuarioScyggz,
}: {
  handlerSaveTelefone: HandlerSaveTelefone;
  handlerCreateUsuarioScyggz: HandlerCreateUsuarioScyggz;
}) {
  const navigate = useNavigate();
  const isMounted = useIsMounted();

  const { handlerAceiteGet } = useContext(HandlersDatabaseContext);

  const [dataAceite, setDataAceite] = useState<Date>(new Date());
  const { currentUserAuth } = useContext(CurrentUserContext);

  useEffect(() => {
    if (handlerAceiteGet)
      handlerAceiteGet.get("cientista").then((handlerAceiteResult) => {
        if (handlerAceiteResult.ok && handlerAceiteResult.value) {
          if (isMounted) setDataAceite(handlerAceiteResult.value);
        }
      });
  }, [handlerAceiteGet, isMounted]);

  const paginacaoCientista = {
    start: 3,
    end: 5,
  };

  return (
    <ContainerResponsive
      backUrlMobile="/cadastro/cientista/2"
      paginacao={paginacaoCientista}
      textoMarcaDagua="Cientista"
      showLogo={true}
    >
      <OnEnterPrevent>
        <ConteudoCadastroInfosPessoaisCientista
          inputPainelCadastroInfosPessoais={{
            auth_id: currentUserAuth?.user_auth?.auth_id ?? "",
            name:
              currentUserAuth?.user_auth?.displayName ??
              getNomeFromStorage() ??
              "",
            aceite_termo_de_servico: dataAceite,
            aceite_politica_de_privacidade: dataAceite,
          }}
          handlerSaveTelefone={handlerSaveTelefone}
          handlerCreateUsuarioScyggz={handlerCreateUsuarioScyggz}
          onCadastro={function () {
            navigate("/cadastro/cientista/4");
          }}
        />
      </OnEnterPrevent>
    </ContainerResponsive>
  );
}
