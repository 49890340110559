import { BadgeInfo } from "./BadgeInfo";
import { TextH2Info } from "./TextH2Info";

export const AreasInfo = ({
  title,
  areas,
}: {
  title: string;
  areas: AreasInfo[]
}) => {

  return (
    <div className="mb-3">
      <TextH2Info children={title} />
      {areas.map((area, i) => (
        <BadgeInfo key={i} children={area.nome} />
      ))}
    </div>
  )
}

type AreasInfo = {
  id: string
  nome: string
}