import { createContext } from "react";

import { Conexao } from "../../domain/entities/Conexao";
import { Result } from "../../typings";

export const ConexoesPendentesEnviadasContext = createContext<{
  conexoesPendentesEnviadasResult: TypeConexoesPendentesEnviadasContext;
}>({
  conexoesPendentesEnviadasResult: undefined,
});

export type TypeConexoesPendentesEnviadasContext =
  | Result<Readonly<Conexao>[]>
  | undefined;
