import { faCamera, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";

import { ImageCrop } from "./imageCrop";
import { getUrlFromFile } from "../../hooks/getUrlFromFile";

const imageMimeType = /image\/(png|jpg|jpeg)/i;

export function InputImage({
  alt,
  title,
  initialValue,
  value,
  onChange,
  isSubmitting = false,
  className = "",
}: {
  alt: string;
  title: string;
  initialValue?: string;
  value?: { imageBase: Blob; cropImage: Blob };
  onChange: (change: { imageBase: Blob; cropImage: Blob }) => void;
  isSubmitting?: boolean;
  className?: string;
}) {
  const iptNewFileFotoEl = useRef<HTMLInputElement>(null);

  const [urlImageBase, setUrlImageBase] = useState<string>();

  useEffect(() => {
    if (value?.imageBase) {
      getUrlFromFile(value.imageBase).then((urlImageBase) =>
        setUrlImageBase(urlImageBase)
      );
    } else {
      setUrlImageBase(undefined);
    }
  }, [value?.imageBase]);

  return (
    <fieldset
      disabled={isSubmitting}
      className={
        className +
        " text-center d-inline" +
        (isSubmitting ? " opacity-50" : "")
      }
    >
      <div
        style={{
          height: "250px",
          width: "250px",
        }}
        className="position-relative d-flex justify-content-center align-items-center"
      >
        {urlImageBase ? (
          <ImageCrop
            alt={alt}
            fileFotoDataURL={urlImageBase}
            onChangeBlob={(cropImage) => {
              if (value)
                onChange({
                  ...value,
                  cropImage,
                });
            }}
          />
        ) : initialValue ? (
          <div className="border rounded-circle">
            <img
              alt={alt}
              src={initialValue}
              className="rounded-circle ratio ratio-1x1"
            />
          </div>
        ) : (
          <div
            style={{
              height: "150px",
              width: "150px",
              backgroundColor: "#e2e2e2",
            }}
            className="border rounded-circle d-flex justify-content-center align-items-center"
            onClick={(event) => {
              iptNewFileFotoEl.current?.click();
              event.stopPropagation();
            }}
          >
            <FontAwesomeIcon
              title="Adicionar foto"
              icon={faCamera}
              style={{ fontSize: "50px", color: "#525200" }}
              className="h1"
            />
          </div>
        )}
        <span
          style={{
            backgroundColor: "#999900",
            minWidth: 40,
            minHeight: 40,
          }}
          title="add foto"
          className="btn position-absolute rounded-circle p-0 bottom-0 end-0 p-2 fs-3 text-white d-flex justify-content-center align-items-center"
          onClick={(event) => {
            iptNewFileFotoEl.current?.click();
            event.stopPropagation();
          }}
        >
          <FontAwesomeIcon icon={faPlus} />
        </span>
      </div>
      <input
        ref={iptNewFileFotoEl}
        className="d-none"
        type="file"
        accept=".png, .jpg, .jpeg"
        id="iptAvatar"
        title={title}
        onChange={(e) => {
          const { files } = e.target;

          if (files)
            for (let i = 0; i < files.length; i++) {
              const file = files[i];

              if (!file.type.match(imageMimeType)) {
                continue;
              }
              onChange({
                imageBase: file,
                cropImage: file,
              });
              break;
            }
        }}
      />
      <label className="mt-1 mb-4" htmlFor="iptAvatar">
        Adicione uma foto.
      </label>
    </fieldset>
  );
}
