import HTMLHead from "../../generalComponents/HTMLHead";
import { ListAllProgramaDeConexao } from "./components/ListAllProgramaDeConexao";
import { ListConviteProgramaDeConexao } from "./components/ListConviteProgramaDeConexao";
import { SelectProgramaDeConexao } from "./components/SelectProgramaDeConexao";

export function ProgramaDeConexaoPage() {
  return (
    <>
      <HTMLHead title="Programas de conexão" description={""} />

      <div className="p-2 row g-2">
        <div className="col-md-4">
          <SelectProgramaDeConexao />
        </div>

        <div className="col-md-8">
          <ListConviteProgramaDeConexao />

          <ListAllProgramaDeConexao />
        </div>
      </div>
    </>
  );
}
