import { ref, uploadBytes, FirebaseStorage } from "firebase/storage";
import { doc, setDoc, Firestore } from "firebase/firestore";

import { Result } from "../typings";
import { HandlerSaveLogoStartup } from "../domain/usecases/interfaces/handlerSaveLogoStartup";
import {
  InputLogoStartup,
  LogoStartup,
  OutputLogoStartup,
} from "../domain/entities/LogoStartup";
import { OutputStartup } from "../domain/entities/Startup";

export class HandlerSaveLogoStartupFirebase implements HandlerSaveLogoStartup {
  constructor(
    private firebaseStorage: FirebaseStorage,
    private firestore: Firestore
  ) {}

  async save(
    inputLogoStartup: InputLogoStartup
  ): Promise<Result<OutputLogoStartup>> {
    const logoStartupResult = LogoStartup.create(inputLogoStartup);

    if (!logoStartupResult.ok) return logoStartupResult;

    const storageRef = ref(this.firebaseStorage, logoStartupResult.value.path);

    return new Promise((resolve) => {
      uploadBytes(storageRef, logoStartupResult.value.file)
        .then(async () => {
          const docStartup = doc(
            this.firestore,
            "startup",
            logoStartupResult.value.id_startup
          );

          const logo: Pick<OutputStartup, "path_logo"> = {
            path_logo: storageRef.fullPath,
          };

          await setDoc(docStartup, logo, { merge: true });

          resolve({
            ok: true,
            value: {
              path: storageRef.fullPath,
            },
          });
        })
        .catch((error) =>
          resolve({
            ok: false,
            error,
          })
        );
    });
  }
}
