import bgCard from "../../../../assets/images/logoCardUser.jpeg";
import { FotoHeaderInternal } from "../header/fotoHeaderInternal/FotoHeaderInternal";
import { CidadeOutput } from "../../../../domain/entities/cidade";

export function HeaderPerfil({
  nome,
  foto_path,
  headline,
  cidade,
}: {
  nome: string;
  foto_path?: string;
  headline?: string;
  cidade?: CidadeOutput;
}) {
  return (
    <section className="h-100 position-relative">
      <div
        style={{
          position: "absolute",
          height: "172px",
          width: "100%",
          backgroundImage: `url(${bgCard})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          borderTopRightRadius: "19px",
          borderTopLeftRadius: "19px",
        }}
      ></div>

      <div className="mx-4">
        <div
          style={{ marginTop: "43px" }}
          className="d-flex justify-content-center gap-3 flex-wrap"
        >
          <div className="d-flex">
            <FotoHeaderInternal
              nome={nome}
              avatar={foto_path}
              size="xl"
              hideUndefined={true}
            />
          </div>

          <div className="d-flex flex-column mt-4 justify-content-end">
            <h4
              style={{
                fontSize: "22px",
                fontWeight: "bold",
                marginBottom: "0",
              }}
            >
              {nome}
            </h4>

            <span style={{ fontSize: "20px", fontWeight: "500" }}>
              {headline}
            </span>

            {cidade && (
              <span style={{ fontSize: "17px" }}>
                {`${cidade.cidade}, ${cidade.estado} - ${cidade.pais}`}
              </span>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
