import React from "react";

export function OnEnterPrevent({
  children,
}: {
  children: JSX.Element | React.ReactNode;
}) {
  return (
    <div
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          event.preventDefault();
        }
      }}
    >
      {children}
    </div>
  );
}
