import { Result } from "../../typings";

export class UrlLattes {
  public auth_id: string;
  public urlLattes: string;

  private constructor(input: InputUrlLattes) {
    this.auth_id = input.auth_id;
    this.urlLattes = input.urlLattes;
  }

  static isValidUrlLattes(inputUrl: string) {
    try {
      const url = new URL(inputUrl);

      if (!url.host.includes("lattes.cnpq.br") || url.pathname.length < 2) {
        return false;
      }

      return true;
    } catch (err) {
      return false;
    }
  }

  public static create({
    auth_id,
    urlLattes,
  }: InputUrlLattes): Result<UrlLattes> {
    const isAuthValid: boolean = auth_id.length > 0;
    if (!isAuthValid) {
      return {
        ok: false,
        error: new Error("Auth id do avatar inválido"),
      };
    }

    if (!UrlLattes.isValidUrlLattes(urlLattes)) {
      return {
        ok: false,
        error: new Error("URL Lattes inválida"),
      };
    }

    return {
      ok: true,
      value: new UrlLattes({ auth_id, urlLattes }),
    };
  }
}

export type InputUrlLattes = {
  auth_id: string;
  urlLattes: string;
};

export type OutputUrlLattes = InputUrlLattes;
