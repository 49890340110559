import {
  CacheVentureCapital,
  HandlerCacheVentureCapital,
} from "../domain/usecases/interfaces/HandlerCacheVentureCapital";
import { Result, Writeable } from "../typings";

export class HandlerCacheVentureCapitalStorage
  implements HandlerCacheVentureCapital
{
  private readonly keyVentureCapitalPrefix = "VentureCapitalCadastroInScyggz";

  clear(): Promise<Result<null>> {
    if (!localStorage) {
      return Promise.resolve({
        ok: false,
        error: new Error(
          "Não fez cache do VentureCapital pois o navegador é incompativel"
        ),
      });
    }

    try {
      localStorage.removeItem(this.keyVentureCapitalPrefix);

      return Promise.resolve({
        ok: true,
        value: null,
      });
    } catch (error) {
      return Promise.resolve({
        ok: false,
        error: error as Error,
      });
    }
  }

  async save(
    ventureCapital: CacheVentureCapital
  ): Promise<Result<null, Error>> {
    if (!localStorage) {
      return Promise.resolve({
        ok: false,
        error: new Error(
          "Não fez cache do VentureCapital pois o navegador é incompativel"
        ),
      });
    }

    try {
      const getResult = await this.get();

      if (!getResult.ok) {
        return getResult;
      }

      localStorage.setItem(
        this.keyVentureCapitalPrefix,
        JSON.stringify({ ...getResult.value, ...ventureCapital })
      );

      return Promise.resolve({
        ok: true,
        value: null,
      });
    } catch (error) {
      return Promise.resolve({
        ok: false,
        error: error as Error,
      });
    }
  }

  get(): Promise<Result<CacheVentureCapital, Error>> {
    const ventureCapitalStorage = localStorage.getItem(
      this.keyVentureCapitalPrefix
    );

    if (!ventureCapitalStorage) {
      return Promise.resolve({
        ok: true,
        value: {},
      });
    }

    const ventureCapitalAny = JSON.parse(ventureCapitalStorage);

    if (!ventureCapitalAny) {
      return Promise.resolve({
        ok: true,
        value: {},
      });
    }

    const ventureCapital = this.convertAnyToVentureCapital(ventureCapitalAny);

    return Promise.resolve({
      ok: true,
      value: ventureCapital,
    });
  }

  private convertAnyToVentureCapital(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ventureCapitalAny: any
  ): Writeable<CacheVentureCapital> {
    const ventureCapital: Writeable<CacheVentureCapital> = {};

    if (ventureCapitalAny.id) ventureCapital.id = ventureCapitalAny.id;

    if (ventureCapitalAny.areas_de_investimento)
      ventureCapital.areas_de_investimento =
        ventureCapitalAny.areas_de_investimento;

    if (ventureCapitalAny.email) ventureCapital.email = ventureCapitalAny.email;

    if (ventureCapitalAny.interesse_startup_estagio)
      ventureCapital.interesse_startup_estagio =
        ventureCapitalAny.interesse_startup_estagio;

    if (ventureCapitalAny.interesse_startup_estagio)
      ventureCapital.interesse_startup_estagio =
        ventureCapitalAny.interesse_startup_estagio;

    if (ventureCapitalAny.interesse_startup_localizacoes)
      ventureCapital.interesse_startup_localizacoes =
        ventureCapitalAny.interesse_startup_localizacoes;

    if (ventureCapitalAny.interesse_startup_mecanismo_solucao)
      ventureCapital.interesse_startup_mecanismo_solucao =
        ventureCapitalAny.interesse_startup_mecanismo_solucao;

    if (ventureCapitalAny.interesse_startup_modelo_negocio)
      ventureCapital.interesse_startup_modelo_negocio =
        ventureCapitalAny.interesse_startup_modelo_negocio;

    if (ventureCapitalAny.interesse_startup_segmento_atuacao)
      ventureCapital.interesse_startup_segmento_atuacao =
        ventureCapitalAny.interesse_startup_segmento_atuacao;

    if (ventureCapitalAny.habilitado)
      ventureCapital.habilitado = ventureCapitalAny.habilitado;

    if (ventureCapitalAny.nome) ventureCapital.nome = ventureCapitalAny.nome;

    if (ventureCapitalAny.path_logo)
      ventureCapital.path_logo = ventureCapitalAny.path_logo;

    if (ventureCapitalAny.premiacoes)
      ventureCapital.premiacoes = ventureCapitalAny.premiacoes;

    if (ventureCapitalAny.tese_de_investimento)
      ventureCapital.tese_de_investimento =
        ventureCapitalAny.tese_de_investimento;

    if (ventureCapitalAny.telefone)
      ventureCapital.telefone = ventureCapitalAny.telefone;

    if (ventureCapitalAny.volume_recursos_sob_gestao)
      ventureCapital.volume_recursos_sob_gestao =
        ventureCapitalAny.volume_recursos_sob_gestao;

    if (ventureCapitalAny.startups_investidas)
      ventureCapital.startups_investidas =
        ventureCapitalAny.startups_investidas;

    if (ventureCapitalAny.website)
      ventureCapital.website = ventureCapitalAny.website;

    if (ventureCapitalAny.logoFile)
      ventureCapital.logoFile = ventureCapitalAny.logoFile;

    return ventureCapital;
  }
}
