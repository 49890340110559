import { TipoPerfil } from "../domain/entities/usuarioScyggz";
import {
  BuscaPerfisPorTextoType,
  buscaPerfisPorTexto,
} from "../domain/usecases/buscaPerfisPorTexto";
import { HandlerPerfisGetEnabled } from "../domain/usecases/interfaces/HandlerPerfisGetEnabled";

export class HandlerPerfisAlgolia implements HandlerPerfisGetEnabled {
  getEnabled(perfis: TipoPerfil[]): Promise<BuscaPerfisPorTextoType> {
    return new Promise((resolve) => {
      buscaPerfisPorTexto({
        texto: "",
        totalPerPage: 1000,
        filter: {
          perfil: perfis,
        },
      }).then((result) => resolve(result));
    });
  }
}
