import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Avatar from "../../../../../assets/images/avatarLight.jpeg";

import { ImgFromPath } from "../../../../generalComponents/ImgFromPath";
import styles from "./FotoHeaderInternal.module.css";

export function FotoHeaderInternal({
  nome,
  avatar,
  size,
  hideUndefined,
}: {
  nome: string;
  avatar?: string;
  size: "sm" | "md" | "lg" | "xl";
  hideUndefined?: boolean;
}) {
  let sizeClass = "";

  switch (size) {
    case "sm":
      sizeClass = styles.sizeSM;
      break;
    case "md":
      sizeClass = styles.sizeMD;
      break;
    case "lg":
      sizeClass = styles.sizeLG;
      break;
    case "xl":
      sizeClass = styles.sizeXl;
      break;
  }

  const userIconUndefined = (
    <FontAwesomeIcon
      icon={faUser}
      className={styles.sizeSM + " rounded-circle ratio ratio-1x1"}
    />
  );

  return avatar ? (
    <ImgFromPath
      path={avatar}
      alt={nome}
      className={
        sizeClass +
        " rounded-circle ratio ratio-1x1 border border-2 border-white"
      }
      elementErro={hideUndefined ? <></> : userIconUndefined}
    />
  ) : (
    <img
      className={`rounded-circle align-self-center ${sizeClass}`}
      style={{ border: "2px solid #fff" }}
      src={Avatar}
      alt={nome}
      title={nome}
    />
  );
}
