import { updateProfile, Auth as AuthService } from "firebase/auth";

import {
  HandlerCreateAuthWithName,
  InputHandlerCreateAuthWithName,
  OutputHandlerCreateAuthWithName,
} from "../domain/usecases/interfaces/handlerCreateAuthWithName";
import { Result } from "../typings";
import { HandlerCreateAuthFirebase } from "./handlerCreateAuthFirebase";

export class HandlerCreateAuthWithNameFirebase
  implements HandlerCreateAuthWithName
{
  constructor(private authService: AuthService) {}

  async createAuthUserWithName(
    input: InputHandlerCreateAuthWithName
  ): Promise<Result<OutputHandlerCreateAuthWithName>> {
    const handlerCreateAuthFirebase = new HandlerCreateAuthFirebase();
    const createAuthUserResult = await handlerCreateAuthFirebase.createAuthUser(
      input
    );

    if (!createAuthUserResult.ok) {
      return createAuthUserResult;
    }

    const auth = this.authService.currentUser;

    if (auth) {
      try {
        await updateProfile(auth, {
          displayName: input.name,
        });
        return {
          ok: true,
          value: {
            auth_id: auth.uid,
            email: auth.email ?? input.email,
            name: auth.displayName ?? input.name,
          },
        };
      } catch (error) {
        return {
          ok: false,
          error: new Error(String(error)),
        };
      }
    }

    return {
      ok: false,
      error: new Error("Erro cadastro"),
    };
  }
}
