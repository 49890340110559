import { ReactNode, useCallback, useContext, useEffect, useState } from "react";

import { UsuarioScyggz } from "../../domain/entities/usuarioScyggz";
import {
  ConexaoEntreUsuariosContext,
  TypeConexaoEntreUsuariosContext,
} from "./ConexaoEntreUsuariosContext";
import { HandlersDatabaseContext } from "../HandlersProviders/HandlersDatabaseContext";

export function ConexaoEntreUsuariosProvider({
  userA,
  userB,
  children,
}: {
  userA: Pick<UsuarioScyggz, "auth_user_id">;
  userB: Pick<UsuarioScyggz, "auth_user_id">;
  children: ReactNode;
}) {
  const [conexaoEntreUsuariosResult, setConexaoEntreUsuariosResult] =
    useState<TypeConexaoEntreUsuariosContext>();

  const { handlerConexaoEntreUsuariosGet } = useContext(
    HandlersDatabaseContext
  );

  const refreshConexaoEntreUsuarios = useCallback(() => {
    if (handlerConexaoEntreUsuariosGet)
      handlerConexaoEntreUsuariosGet
        .getConexaoEntreUsuarios(userA, userB)
        .then((result) => {
          setConexaoEntreUsuariosResult(result);
        });
  }, [handlerConexaoEntreUsuariosGet, userA, userB]);

  useEffect(() => {
    refreshConexaoEntreUsuarios();
  }, [refreshConexaoEntreUsuarios]);

  return (
    <ConexaoEntreUsuariosContext.Provider
      value={{
        conexaoEntreUsuariosResult,
        refreshConexaoEntreUsuarios,
      }}
      children={children}
    />
  );
}
