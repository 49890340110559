import { Result } from "../../typings";
import { CidadeOutputWithTipo } from "./cidade";
import { Hub } from "./Hub";
import { InstituicaoDeCienciaETecnologia } from "./instituicaoDeCienciaETecnologia";

export class Nit {
  private constructor(private input: InputNit) {}
  public static create(input: InputNit): Result<Nit> {
    const errors: Error[] = [];

    let { nome } = input;

    nome = nome.trim();
    const isNomeValid: boolean = nome.length > 0;
    if (!isNomeValid) {
      errors.push(new Error("Nome inválido"));
    }

    //TODO: VALIDATE: sobre, gestores, cidade, email

    if (errors.length > 0) {
      return {
        ok: false,
        error: errors[0],
      };
    }

    return {
      ok: true,
      value: new Nit(input),
    };
  }

  public get sobre(): string {
    return this.input.sobre;
  }

  public get cidade(): CidadeOutputWithTipo {
    return this.input.cidade;
  }

  public get instituicoes():
    | Pick<InstituicaoDeCienciaETecnologia, "id" | "nome">[]
    | undefined {
    return this.input.instituicoes;
  }

  public get hubs():
    | Pick<Hub, "id" | "nome" | "cidade" | "path_logo">[]
    | undefined {
    return this.input.hubs;
  }
}

export type InputNit = {
  nome: string;
  sobre: string;
  cidade: CidadeOutputWithTipo;
  gestores: string[];
  email: string; // TODO: VALIDATE EMAIL
  telefone: string;
  created_in: Date;
  habilitado: boolean;
} & Partial<{
  instituicoes: Pick<InstituicaoDeCienciaETecnologia, "id" | "nome">[];
  logo_path: string;
  linkedin: string; // TODO: VALIDATE URL
  website: string; // TODO: VALIDATE URL
  hubs: Pick<Hub, "id" | "nome" | "cidade" | "path_logo">[];
}>;

export type OutputNit = InputNit & {
  id: string;
};
