export const TextH2Bold = ({
  children,
  classes,
}: {
  children: string
  classes?: string
}) => {
  return (
    <h2 className={`${classes}`} style={{ fontSize: "22px", fontWeight: "bold", marginBottom: "0" }} >
      {children}
    </h2>
  )
}