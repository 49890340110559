import algoliasearch from "algoliasearch";

import { StartupSearchAlgolia } from "../entities/Startup";
import { Result } from "../../typings";

export async function buscaStartupsPorTexto({
  texto,
  totalPerPage = 24,
  page = 0,
}: {
  texto: string;
  totalPerPage?: number;
  page?: number;
}): Promise<BuscaStartupsPorTextoType> {
  const client = algoliasearch(
    "40O9V7GSHN",
    "4455a01c9297d5d24ed81e29bafb3ba6"
  );
  const index = client.initIndex("Startups");

  return new Promise((resolve) => {
    index
      .search<StartupToSearch>(texto, {
        filters: "habilitado:true",
        hitsPerPage: totalPerPage,
        page,
      })
      .then((searchResponse) => {
        if (searchResponse.nbPages > 0 && searchResponse.hits.length == 0) {
          return resolve(
            buscaStartupsPorTexto({
              texto,
              page: searchResponse.nbPages - 1,
            })
          );
        }
        resolve({
          ok: true,
          value: {
            startups: searchResponse.hits,
            currentPage: page,
            totalPages: searchResponse.nbPages,
          },
        });
      })
      .catch((error) => {
        resolve({
          ok: false,
          error,
        });
      });
  });
}

export type StartupToSearch = Omit<StartupSearchAlgolia, "created_in">;

export type BuscaStartupsPorTextoType = Result<{
  currentPage: number;
  totalPages: number;
  startups: StartupToSearch[];
}>;
