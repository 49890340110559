import { Button, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";

import {
  InvestidorAnjo,
  OutputInvestidorAnjo,
} from "../../../../domain/entities/InvestidorAnjo";
import { InvestidorAnjoValidator } from "../../../../domain/entities/InvestidorAnjoValidator";
import {
  InputHandlerUpdateInvestidorSemId,
  TypeReturnHandlerUpdateInvestidor,
} from "../../../../domain/usecases/interfaces/handlerUpdateInvestidor";
import { InputTeseInvestimento } from "../../../generalComponents/InputTeseInvestimento";
import { InputAreasEspecializacao } from "../../../generalComponents/InputAreasEspecializacao";
import { InputAssociacoesAnjo } from "../../../generalComponents/InputAssociacoesAnjo";
import { InputEstagiosStartup } from "../../../generalComponents/InputEstagiosStartup";
import { InputLocais } from "../../../generalComponents/InputLocais";
import { InputModelosNegocio } from "../../../generalComponents/InputModelosNegocio";
import { InputSegmentosAtuacao } from "../../../generalComponents/InputSegmentosAtuacao";
import { InputMecanismosSolucao } from "../../../generalComponents/InputMecanismosSolucao";
import { InputPremiacoes } from "../../../generalComponents/InputPremiacoes/InputPremiacoes";

export function ModalEditInvestidor({
  initialValue,
  show,
  onShowChange,
  handlerUpdateInvestidor,
  onUpdate,
}: {
  initialValue: InputHandlerUpdateInvestidorSemId;
  show: boolean;
  onShowChange: (show: boolean) => void;
  handlerUpdateInvestidor: (
    input: InputHandlerUpdateInvestidorSemId
  ) => TypeReturnHandlerUpdateInvestidor;
  onUpdate: () => void;
}) {
  const [investidorToEdit, setInvestidorToEdit] =
    useState<InputHandlerUpdateInvestidorSemId>({});

  const [error, setError] = useState<
    Error | Partial<Record<keyof OutputInvestidorAnjo, Error>>
  >();
  const [carregando, setCarregando] = useState<boolean>(false);
  const [isEditingPremiacoes, setIsEditingPremiacoes] =
    useState<boolean>(false);

  useEffect(() => {
    setInvestidorToEdit(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const investidorValidateResult =
      InvestidorAnjo.validateUpdate(investidorToEdit);
    if (investidorValidateResult.ok) {
      setError(undefined);
    } else {
      setError(investidorValidateResult.error);
    }
  }, [investidorToEdit]);

  return (
    <Modal
      show={show}
      onHide={() => onShowChange(false)}
      centered
      scrollable={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>Editar investidor</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputTeseInvestimento
          label="Tese de Investimento"
          value={investidorToEdit?.tese_de_investimento?.conteudo ?? ""}
          onChange={(tese_de_investimento) => {
            setInvestidorToEdit({
              ...investidorToEdit,
              tese_de_investimento: {
                conteudo: tese_de_investimento,
              },
            });
          }}
          className="mb-2"
        />

        <InputAreasEspecializacao
          label="Áreas de especialização"
          value={investidorToEdit.areas_de_especializacao ?? []}
          onChange={(areas_de_especializacao) => {
            setInvestidorToEdit({
              ...investidorToEdit,
              areas_de_especializacao,
            });
          }}
          validate={InvestidorAnjoValidator.isInvalidAreasEspecializacao}
          className="mb-3"
        />

        <InputAssociacoesAnjo
          label="Associações Anjo"
          value={investidorToEdit.associacoes_anjo ?? []}
          onChange={(associacoes_anjo) => {
            setInvestidorToEdit({
              ...investidorToEdit,
              associacoes_anjo,
            });
          }}
          validate={() => undefined}
          className="mb-3"
        />

        <InputEstagiosStartup
          label="Estágio da startup de interesse*"
          value={investidorToEdit.interesse_startup_estagio ?? []}
          onChange={(interesse_startup_estagio) => {
            setInvestidorToEdit({
              ...investidorToEdit,
              interesse_startup_estagio,
            });
          }}
          validate={InvestidorAnjoValidator.isInvalidInteresseStartupEstagio}
          className="mb-3"
        />

        <InputLocais
          label="Em que localidades as Startups devem estar"
          value={investidorToEdit.interesse_startup_localizacoes ?? []}
          onChange={(interesse_startup_localizacoes) => {
            setInvestidorToEdit({
              ...investidorToEdit,
              interesse_startup_localizacoes,
            });
          }}
          validate={InvestidorAnjoValidator.isInvalidLocais}
          className="mb-3"
        />

        <InputModelosNegocio
          label="Modelos de negócio*"
          value={investidorToEdit.interesse_startup_modelo_negocio ?? []}
          onChange={(interesse_startup_modelo_negocio) => {
            setInvestidorToEdit({
              ...investidorToEdit,
              interesse_startup_modelo_negocio,
            });
          }}
          validate={
            InvestidorAnjoValidator.isInvalidInteresseStartupModeloNegocio
          }
          className="mb-3"
        />

        <InputSegmentosAtuacao
          label="Que atuam nos seguintes segmentos*"
          value={investidorToEdit.interesse_startup_segmento_atuacao ?? []}
          onChange={(interesse_startup_segmento_atuacao) => {
            setInvestidorToEdit({
              ...investidorToEdit,
              interesse_startup_segmento_atuacao,
            });
          }}
          validate={
            InvestidorAnjoValidator.isInvalidInteresseStartupSegmentoAtuacao
          }
          className="mb-3"
        />

        <InputMecanismosSolucao
          label="Que utilizam os seguintes mecanismos de solução tecnológica*"
          value={investidorToEdit.interesse_startup_mecanismo_solucao ?? []}
          onChange={(interesse_startup_mecanismo_solucao) => {
            setInvestidorToEdit({
              ...investidorToEdit,
              interesse_startup_mecanismo_solucao,
            });
          }}
          validate={
            InvestidorAnjoValidator.isInvalidInteresseStartupMecanismoSolucao
          }
          className="mb-3"
        />

        <InputPremiacoes
          label="Premiações"
          value={investidorToEdit.premiacoes_e_certificados ?? []}
          onChange={(premiacoes_e_certificados) => {
            setInvestidorToEdit({
              ...investidorToEdit,
              premiacoes_e_certificados,
            });
          }}
          showTip={false}
          isEditing={setIsEditingPremiacoes}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="success"
          disabled={!!error || carregando || isEditingPremiacoes}
          onClick={async () => {
            setCarregando(true);

            const handlerUpdateResult = await handlerUpdateInvestidor(
              investidorToEdit
            );

            setCarregando(false);

            if (!handlerUpdateResult.ok) {
              console.warn(handlerUpdateResult.error);

              setError(handlerUpdateResult.error);

              return;
            } else {
              onUpdate();
            }

            onShowChange(false);
          }}
        >
          Salvar investidor
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
