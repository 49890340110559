import { useNavigate } from "react-router-dom";

import { HandlerCacheVentureCapital } from "../../../../../domain/usecases/interfaces/HandlerCacheVentureCapital";
import { HandlerGetAreaInvestimento } from "../../../../../domain/usecases/interfaces/handlerGetAreaInvestimento";
import { ContainerResponsive } from "../../../../generalComponents/ContainerResponsive";
import { ConteudoSobreVentureCapital } from "./ConteudoSobreVentureCapital";

export const PainelSobreVentureCapital = ({
  handlerCacheVentureCapital,
  handlerGetAreaInvestimento,
}: {
  handlerCacheVentureCapital: HandlerCacheVentureCapital;
  handlerGetAreaInvestimento: HandlerGetAreaInvestimento;
}) => {
  const navigate = useNavigate();

  const paginacao = {
    start: 5,
    end: 7,
  };

  return (
    <section
      data-testid="painel-sobre-venturecapital"
      style={{ height: "100%" }}
    >
      <ContainerResponsive
        backUrlMobile="/cadastro/venture_capital/4"
        paginacao={paginacao}
        textoMarcaDagua="Venture Capital"
        showLogo={true}
      >
        <ConteudoSobreVentureCapital
          handlerCacheVentureCapital={handlerCacheVentureCapital}
          handlerGetAreaInvestimento={handlerGetAreaInvestimento}
          onBack={() => {
            navigate("/cadastro/venture_capital/4");
          }}
          onNext={() => {
            navigate("/cadastro/venture_capital/6");
          }}
        />
      </ContainerResponsive>
    </section>
  );
};
