import { createContext } from "react";

import { TypePessoaRelevante } from "../../domain/usecases/interfaces/HandlerGetPessoasRelevantes";
import { Result } from "../../typings";

export const PessoasRelevantesContext = createContext<{
  pessoasRelevantesResult: Result<TypePessoaRelevante[]> | undefined;
  refresh: (() => void) | undefined;
}>({
  pessoasRelevantesResult: undefined,
  refresh: undefined,
});
