import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { ConteudoCadastroInfosPessoais } from "../../components/ConteudoCadastroInfosPessoais";
import { HandlerSaveUrlLinkedin } from "../../../../../domain/usecases/interfaces/handlerSaveUrlLinkedin";
import { HandlerSaveTelefone } from "../../../../../domain/usecases/interfaces/handlerSaveTelefone";
import { HandlerCreateUsuarioScyggz } from "../../../../../domain/usecases/interfaces/handlerCreateUsuarioScyggz";
import { getNomeFromStorage } from "../../../../../hooks/handlerNameFromStorage";
import { useIsMounted } from "../../../../../hooks/useIsMounted";
import { ContainerResponsive } from "../../../../generalComponents/ContainerResponsive";
import { CurrentUserContext } from "../../../../../globalStore/CurrentUserContext";
import { HandlersDatabaseContext } from "../../../../../globalStore/HandlersProviders/HandlersDatabaseContext";

export function PainelCadastroUsuarioInvestidor({
  handlerSaveUrlLinkedin,
  handlerSaveTelefone,
  handlerCreateUsuarioScyggz,
}: {
  handlerSaveUrlLinkedin: HandlerSaveUrlLinkedin;
  handlerSaveTelefone: HandlerSaveTelefone;
  handlerCreateUsuarioScyggz: HandlerCreateUsuarioScyggz;
}) {
  const navigate = useNavigate();
  const isMounted = useIsMounted();

  const { handlerAceiteGet } = useContext(HandlersDatabaseContext);

  const [dataAceite, setDataAceite] = useState<Date>(new Date());
  const { currentUserAuth, currentUserScyggz } = useContext(CurrentUserContext);

  useEffect(() => {
    if (handlerAceiteGet)
      handlerAceiteGet.get("investidor").then((handlerAceiteResult) => {
        if (handlerAceiteResult.ok) {
          if (handlerAceiteResult.value) {
            if (isMounted) setDataAceite(handlerAceiteResult.value);
          }
        }
      });
  }, [handlerAceiteGet, isMounted]);

  const paginacao = {
    start: 3,
    end: 6,
  };

  return (
    <ContainerResponsive
      paginacao={paginacao}
      backUrlMobile="/cadastro/investidor/2"
      textoMarcaDagua="Investidor Anjo"
      showLogo={true}
    >
      <ConteudoCadastroInfosPessoais
        inputPainelCadastroInfosPessoais={{
          auth_id: currentUserAuth.user_auth?.auth_id ?? "",
          nome:
            currentUserScyggz.user?.nome ??
            currentUserAuth.user_auth?.displayName ??
            getNomeFromStorage() ??
            "",
          aceite_termo_de_servico: dataAceite,
          aceite_politica_de_privacidade: dataAceite,
          phoneNumber: currentUserScyggz.user?.telefone
            ? {
                numero: currentUserScyggz.user.telefone,
                invalid: false,
              }
            : undefined,
          cidade: currentUserScyggz.user?.cidade,
          urlLinkedin: currentUserScyggz.user?.url_linkedin,
          headline: undefined,
        }}
        handlerSaveUrlLinkedin={handlerSaveUrlLinkedin}
        handlerSaveTelefone={handlerSaveTelefone}
        handlerCreateUsuarioScyggz={handlerCreateUsuarioScyggz}
        onCadastro={function () {
          navigate("/cadastro/investidor/4");
        }}
      />
    </ContainerResponsive>
  );
}
