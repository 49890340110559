import { NavLink } from "react-router-dom";

import { AbaPrograma } from "../../../../../hooks/useAbaPrograma";

export function AbasPrograma({
  urls,
  abaToShow,
  abasPermitidas,
}: {
  urls: Partial<Record<AbaPrograma, string>>;
  abaToShow?: AbaPrograma;
  abasPermitidas: {
    tipo: AbaPrograma;
    label: string;
  }[];
}) {
  return (
    <>
      {abasPermitidas.map((aba) => (
        <NavLink
          key={aba.tipo}
          className={
            "fs-5 fw-bold px-2 px-sm-3 px-md-4 py-2 rounded-top me-2 " +
            (abaToShow == aba.tipo
              ? "bg-white color-spinoff"
              : "bg-body-secondary text-secondary")
          }
          to={urls[aba.tipo] ?? ""}
          children={aba.label}
        />
      ))}
    </>
  );
}
