import { useMemo } from "react";
import { faRotateLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { InputImage } from "../../../../generalComponents/InputImage";
import {
  InvitationRule,
  ProgramaConexao,
} from "../../../../../domain/entities/ProgramaConexao";
import { InputText } from "../../../../generalComponents/InputText";
import { InputTextarea } from "../../../../generalComponents/InputTextarea";
import {
  InputDuration,
  InputDurationValue,
} from "../../../../generalComponents/InputDuration";
import { InputRadios } from "../../../../generalComponents/InputRadios";
import { TipoCadastro } from "../../../../../domain/entities/TipoCadastro";
import { SelectStakeholdersGrupos } from "../../../../generalComponents/SelectStakeholdersGrupos";

export function Etapa2NewPrograma({
  value: programaToSave,
  onChange: setProgramaToSave,
  onBack,
  onNext,
}: {
  value: ProgramaConexaoEtapa2;
  onChange: (value: ProgramaConexaoEtapa2) => void;
  onBack: () => void;
  onNext: () => void;
}) {
  const erroProgramaToSave = useMemo<boolean>(() => {
    return !!ProgramaConexao.isInvalidProgramaConexao({
      logo_path:
        programaToSave.logo?.cropImage || programaToSave.logo?.cropImage
          ? "logo valido"
          : "",
      stakeholder_type_group_a: programaToSave.stakeholder_type_group_a,
      stakeholder_type_group_b: programaToSave.stakeholder_type_group_b,
      invitation_rule: programaToSave.invitation_rule,
      start_date: programaToSave.duration.start,
      end_date: programaToSave.duration.end,
      nome: programaToSave.nome,
      about: programaToSave.about,
    });
  }, [
    programaToSave.about,
    programaToSave.duration.end,
    programaToSave.duration.start,
    programaToSave.invitation_rule,
    programaToSave.logo,
    programaToSave.nome,
    programaToSave.stakeholder_type_group_a,
    programaToSave.stakeholder_type_group_b,
  ]);

  return (
    <div className="card shadow-sm">
      <div className="card-header border-0 bg-white p-0">
        <button
          type="button"
          className="btn btn-light bg-white border-0 text-muted"
          onClick={onBack}
        >
          <FontAwesomeIcon icon={faRotateLeft} className="me-2" />
          Back
        </button>
      </div>

      <div className="card-body">
        <h5 className="pb-2 fw-bolder">
          Preencha informações do programa de conexão
        </h5>

        <InputImage
          value={programaToSave.logo}
          alt="Logo programa de conexão"
          onChange={(logo) => {
            setProgramaToSave({
              ...programaToSave,
              logo,
            });
          }}
          title="logo programa de conexão"
          className="mb-3"
        />

        <InputText
          label="Título do programa*"
          title="Título do programa"
          value={programaToSave.nome}
          onChange={(nome) => {
            setProgramaToSave({
              ...programaToSave,
              nome,
            });
          }}
          id="iptTitulo"
          min={{
            value: ProgramaConexao.MIN_SIZE_NOME,
            errorMessage: ProgramaConexao.MIN_SIZE_NOME_ERROR,
          }}
          max={{
            value: ProgramaConexao.MAX_SIZE_NOME,
            errorMessage: ProgramaConexao.MAX_SIZE_NOME_ERROR,
          }}
        />

        <InputTextarea
          label="Sobre*"
          subLabel="Deixe as pessoas saberem sobre o que é o programa de conexão"
          title="Sobre o programa"
          value={programaToSave.about}
          onChange={(about) => {
            setProgramaToSave({
              ...programaToSave,
              about,
            });
          }}
          id="iptSobre"
          min={{
            value: ProgramaConexao.MIN_SIZE_ABOUT,
            errorMessage: ProgramaConexao.MIN_SIZE_ABOUT_ERROR,
          }}
          max={{
            value: ProgramaConexao.MAX_SIZE_ABOUT,
            errorMessage: ProgramaConexao.MAX_SIZE_ABOUT_ERROR,
          }}
        />

        <InputDuration
          label="Duração*"
          value={programaToSave.duration}
          onChange={(duration) => {
            setProgramaToSave({
              ...programaToSave,
              duration,
            });
          }}
          validate={(duration) =>
            ProgramaConexao.isInvalidDates(duration.start, duration.end)
          }
        />

        <SelectStakeholdersGrupos
          label="Selecione os perfis"
          subLabel="Selecione os perfis que você deseja conectar entre si"
          value={programaToSave}
          onChange={(stakeholders) => {
            setProgramaToSave({
              ...programaToSave,
              ...stakeholders,
            });
          }}
          validate={(stakeholders) => {
            return ProgramaConexao.isInvalidStakeHoldersGroups(
              stakeholders.stakeholder_type_group_a,
              stakeholders.stakeholder_type_group_b
            );
          }}
        />

        <InputRadios
          value={programaToSave.invitation_rule}
          label="Defina regras"
          subLabel="Quem pode enviar convites para se conectar com os outros"
          title="Defina regras"
          onChange={(invitation_rule) => {
            setProgramaToSave({
              ...programaToSave,
              invitation_rule: invitation_rule as InvitationRule,
            });
          }}
          id="rdbRegras"
          options={[
            {
              label: "Ambos os grupos",
              value: "from_both",
            },
            {
              label: "Somente grupo A",
              value: "from_a",
            },
            {
              label: "Somente grupo B",
              value: "from_b",
            },
          ]}
        />
      </div>

      <div className="card-footer border-0 pt-0 bg-white text-center">
        <button
          type="button"
          className="btn px-4 text-white background-spinoff"
          onClick={onNext}
          disabled={erroProgramaToSave}
        >
          Continue
        </button>
      </div>
    </div>
  );
}

export type ProgramaConexaoEtapa2 = {
  logo?: {
    imageBase: Blob;
    cropImage: Blob;
  };
  nome: string;
  about: string;
  duration: InputDurationValue;
  invitation_rule: InvitationRule;
  stakeholder_type_group_a: TipoCadastro[];
  stakeholder_type_group_b: TipoCadastro[];
};
