import { ReactNode, useCallback, useContext, useEffect, useState } from "react";

import { TypePessoaRelevante } from "../../domain/usecases/interfaces/HandlerGetPessoasRelevantes";
import { HandlerGetPessoasRelevantesFirebase } from "../../gateway/firebase/HandlerGetPessoasRelevantesFirebase";
import { Result } from "../../typings";
import { FirebaseContext } from "../FirebaseContext";
import { PessoasRelevantesContext } from "./PessoasRelevantesContext";

export function PessoasRelevantesFirebaseProvider({
  getOnInit,
  children,
  currentUserScyggz,
  showedUser,
}: {
  getOnInit?: boolean;
  showedUser: {
    auth_user_id: string;
  };
  currentUserScyggz: {
    auth_user_id: string;
  };
  children: ReactNode;
}) {
  const [pessoasRelevantesResult, setPessoasRelevantesResult] =
    useState<Result<TypePessoaRelevante[]>>();

  const { firestore } = useContext(FirebaseContext);

  const refresh = useCallback(() => {
    new HandlerGetPessoasRelevantesFirebase(firestore)
      .get({
        currentUser: currentUserScyggz.auth_user_id,
        showedUser: showedUser.auth_user_id,
      })
      .then((result) => {
        setPessoasRelevantesResult(result);
      });
  }, [currentUserScyggz.auth_user_id, firestore, showedUser.auth_user_id]);

  useEffect(() => {
    if (getOnInit) refresh();
  }, [firestore, getOnInit, refresh]);

  return (
    <PessoasRelevantesContext.Provider
      value={{
        pessoasRelevantesResult,
        refresh,
      }}
      children={children}
    />
  );
}
