import { Result } from "../../typings";
import { ProgramaConexao } from "./ProgramaConexao";
import { TipoCadastro } from "./TipoCadastro";
import { CidadeOutput } from "./cidade";

export class ConviteProgramaConexao {
  private constructor(
    readonly id: string,
    readonly programa_conexao: Pick<
      ProgramaConexao,
      | "id"
      | "nome"
      | "logo_path"
      | "stakeholder_type_group_a"
      | "stakeholder_type_group_b"
      | "message_to_a"
      | "message_to_b"
    >,
    readonly status: StatusConvite,
    readonly to_group: ("a" | "b")[],
    readonly alvo: TypeAlvoConvite,
    readonly disabled?: boolean
  ) {}

  public static idFormat(convite: {
    programa_conexao: {
      id: string;
    };
    alvo: {
      id: string;
    };
  }): string {
    return convite.programa_conexao.id + "|" + convite.alvo.id;
  }

  public static create(
    input: Readonly<ConviteProgramaConexao>
  ): Result<
    ConviteProgramaConexao,
    Partial<Record<keyof ConviteProgramaConexao, Error>>
  > {
    const isInvalid = this.isInvalidConviteProgramaConexao(input);

    if (isInvalid) {
      return {
        ok: false,
        error: isInvalid,
      };
    }

    return {
      ok: true,
      value: new ConviteProgramaConexao(
        input.id,
        input.programa_conexao,
        input.status,
        input.to_group,
        input.alvo
      ),
    };
  }

  public static isInvalidConviteProgramaConexao(
    conviteProgramaConexao: Partial<ConviteProgramaConexao>
  ): Partial<Record<keyof ConviteProgramaConexao, Error>> | undefined {
    const errors: Partial<Record<keyof ConviteProgramaConexao, Error>> = {
      programa_conexao: conviteProgramaConexao.programa_conexao
        ? this.isInvalidProgramaConexao(conviteProgramaConexao.programa_conexao)
        : undefined,

      alvo: conviteProgramaConexao.alvo
        ? this.isInvalidAlvo(conviteProgramaConexao.alvo)
        : undefined,

      to_group: conviteProgramaConexao.to_group
        ? this.isInvalidGroup(conviteProgramaConexao.to_group)
        : undefined,
    };

    const hasError: boolean = Object.values(errors).some((error) => !!error);

    if (hasError) return errors;
  }

  public static isInvalidId(id: string): Error | undefined {
    if (id.length < 1) {
      return new Error("id obrigatório");
    }
  }

  public static isInvalidProgramaConexao(
    programa: Pick<
      ProgramaConexao,
      | "id"
      | "nome"
      | "logo_path"
      | "stakeholder_type_group_a"
      | "stakeholder_type_group_b"
    >
  ): Error | undefined {
    if (ProgramaConexao.isInvalidProgramaConexao(programa)) {
      return new Error("Programa de conexão inválido");
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public static isInvalidAlvo(alvo: TypeAlvoConvite): Error | undefined {
    // TODO: isInvalidAlvo IN CONVITE
    return undefined;
  }

  public static isInvalidGroup(grupos: string[]): Error | undefined {
    if (grupos.length <= 0) {
      return new Error("Grupo obrigatório");
    }
  }
}

type StatusConvite = undefined | "accepted" | "rejected";
export type TypeAlvoConvite = {
  id: string;
  nome: string;
  headline?: string;
  cidade: CidadeOutput;
  avatar_path?: string;
  typesStakeholder: TipoCadastro[];
};
