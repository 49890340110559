import { createContext } from "react";

import { Result } from "../../typings";

export const ConexoesAprovadasCountContext = createContext<{
  conexoesAprovadasCountResult: TypeConexoesAprovadasCountContext;
}>({
  conexoesAprovadasCountResult: undefined,
});

export type TypeConexoesAprovadasCountContext = Result<number> | undefined;
