import { Cidade, CidadeOutputWithTipo } from "./cidade";
import { Estado, EstadoOutputWithTipo } from "./estado";
import { Pais, PaisOutputWithTipo } from "./pais";

export type Localizacao = Cidade | Estado | Pais;

export type LocalizacaoOutput =
  | CidadeOutputWithTipo
  | EstadoOutputWithTipo
  | PaisOutputWithTipo;

export function isLocalizacaoValida(local: LocalizacaoOutput): boolean {
  if (local.tipo == "cidade") {
    const createResult = Cidade.create(local);

    return createResult.ok;
  } else if (local.tipo == "estado") {
    const estado = Estado.create(local);

    return !!estado;
  } else if (local.tipo == "pais") {
    const pais = Pais.create(local);

    return !!pais;
  }

  return false;
}
