import { createContext } from "react";
import { Firestore } from "firebase/firestore";
import { FirebaseStorage } from "firebase/storage";
import { Auth } from "firebase/auth";

import { FirebaseService } from "../infra/firebase/firebaseService";

export const FirebaseContext = createContext<{
  firestore: Firestore;
  firebaseStorage: FirebaseStorage;
  auth: Auth;
}>({
  firestore: FirebaseService.newFirebaseService().getFirestore(),
  firebaseStorage: FirebaseService.newFirebaseService().getStorage(),
  auth: FirebaseService.newFirebaseService().getAuth(),
});
