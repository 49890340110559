import axios from "axios";

import { LocationFinder, LocationFinderOutput } from "../locationFinder";
import {
  Cidade,
  CidadeOutput,
  CidadeOutputWithTipo,
} from "../../entities/cidade";
import {
  Estado,
  EstadoOutput,
  EstadoOutputWithTipo,
} from "../../entities/estado";
import { Pais, PaisOutput, PaisOutputWithTipo } from "../../entities/pais";

export const locationFinderOSM: LocationFinder = async (
  locationName: string
) => {
  const locationsResult = await axios.get<LocationEntryOSM[]>(
    "https://nominatim.openstreetmap.org/search?q=" +
      locationName +
      "&format=json&addressdetails=1"
  );

  return locationsResult.data
    .map<LocationFinderOutput | null>((locationOSM) => {
      const paisResult = handleOSMPais(locationOSM);
      if (!paisResult) return null;

      const estadoResult = handleOSMEstado(locationOSM, paisResult);
      if (!estadoResult) return paisResult;

      const cidadeResult = handleOSMCidade(locationOSM, estadoResult);
      if (!cidadeResult) return estadoResult;

      return cidadeResult;
    })
    .filter<LocationFinderOutput>(
      (locationOutput): locationOutput is LocationFinderOutput =>
        locationOutput !== null
    )
    .filter(
      (locationOutput, index, locationsOutput) =>
        index ===
        locationsOutput.findIndex((entryLocationsOutput) =>
          isEqualLocation(locationOutput, entryLocationsOutput)
        )
    );
};

function isEqualLocation(
  l1: LocationFinderOutput,
  l2: LocationFinderOutput
): boolean {
  if (l1.tipo != l2.tipo) {
    return false;
  }

  switch (l1.tipo) {
    case "pais":
      return isEqualPais(l1, l2);
    case "estado":
      return isEqualEstado(l1, l2 as EstadoOutput);
    case "cidade":
      return isEqualCidade(l1, l2 as CidadeOutput);
    default:
      return false;
  }
}

function isEqualPais(p1: PaisOutput, p2: PaisOutput): boolean {
  return p1.paisCode == p2.paisCode;
}

function isEqualEstado(e1: EstadoOutput, e2: EstadoOutput): boolean {
  return e1.estado == e2.estado && isEqualPais(e1, e2);
}

function isEqualCidade(c1: CidadeOutput, c2: CidadeOutput): boolean {
  return c1.cidade == c2.cidade && isEqualEstado(c1, c2);
}

function handleOSMPais(
  locationOSM: LocationEntryOSM
): PaisOutputWithTipo | null {
  const paisResult = Pais.create({
    pais: locationOSM?.address?.country ?? "",
    paisCode: locationOSM?.address?.country_code ?? "",
  });
  if (paisResult)
    return {
      tipo: "pais",
      pais: paisResult.pais,
      paisCode: paisResult.paisCode,
    };
  else return null;
}

function handleOSMEstado(
  locationOSM: LocationEntryOSM,
  paisValidado: PaisOutputWithTipo
): EstadoOutputWithTipo | null {
  const estadoResult = Estado.create({
    estado: locationOSM?.address?.state ?? "",
    pais: paisValidado.pais,
    paisCode: paisValidado.paisCode,
  });
  if (estadoResult)
    return {
      tipo: "estado",
      estado: estadoResult.estado,
      pais: estadoResult.pais.pais,
      paisCode: estadoResult.pais.paisCode,
    };
  else return null;
}

function handleOSMCidade(
  locationOSM: LocationEntryOSM,
  estadoValidado: EstadoOutputWithTipo
): CidadeOutputWithTipo | null {
  const nomeCidade =
    locationOSM?.address?.city ??
    locationOSM?.address?.town ??
    locationOSM?.address?.village ??
    locationOSM?.address?.municipality ??
    "";

  const cidadeResult = Cidade.create({
    cidade: nomeCidade,
    estado: estadoValidado.estado,
    pais: estadoValidado.pais,
    paisCode: estadoValidado.paisCode,
  });
  if (cidadeResult.ok)
    return {
      tipo: "cidade",
      cidade: cidadeResult.value.cidade,
      estado: cidadeResult.value.estado.estado,
      pais: cidadeResult.value.estado.pais.pais,
      paisCode: cidadeResult.value.estado.pais.paisCode,
    };
  else return null;
}

type LocationEntryOSM = {
  address?: {
    city?: string;
    town?: string;
    village?: string;
    municipality?: string;
    state?: string;
    country?: string;
    country_code?: string;
  };
};
