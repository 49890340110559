/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useContext, useMemo, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { Spinner } from "react-bootstrap";

import { ButtonDarkGreen } from "../../../../generalComponents/buttonDarkGreen/ButtonDarkGreen";
import { Cidade, CidadeOutput } from "../../../../../domain/entities/cidade";
import { Result } from "../../../../../typings";
import { Telefone } from "../../../../../domain/entities/telefone";
import { HandlerCreateUsuarioScyggz } from "../../../../../domain/usecases/interfaces/handlerCreateUsuarioScyggz";
import { HandlerSaveTelefone } from "../../../../../domain/usecases/interfaces/handlerSaveTelefone";
import { CientistaOutputEntity } from "../../../../../domain/entities/Cientista";
import { ResizeContext } from "../../../../../hooks/useResize";
import { CurrentUserContext } from "../../../../../globalStore/CurrentUserContext";
import { InputCidade } from "../../../../generalComponents/InputCidade";
import { UsuarioScyggzValidator } from "../../../../../domain/entities/UsuarioScyggzValidator";
import {
  InputTelefone,
  TypeInputTelefone,
} from "../../../../generalComponents/InputTelefone";
import { InputLinkedin } from "../../../../generalComponents/InputLinkedin";
import { InputLattes } from "../../../../generalComponents/InputLattes";

export function ConteudoCadastroInfosPessoaisCientista({
  inputPainelCadastroInfosPessoais,
  handlerSaveTelefone,
  handlerCreateUsuarioScyggz,
  onCadastro,
}: {
  inputPainelCadastroInfosPessoais: InputPainelCadastroInfosPessoaisCientista;
  handlerSaveTelefone: HandlerSaveTelefone;
  handlerCreateUsuarioScyggz: HandlerCreateUsuarioScyggz;
  onCadastro: () => void;
}) {
  const { responsive } = useContext(ResizeContext);
  const { currentUserScyggz } = useContext(CurrentUserContext);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [values, setValues] = useState<FormType>({
    phoneNumber: currentUserScyggz.user?.telefone
      ? {
          numero: currentUserScyggz.user.telefone,
          invalid: false,
        }
      : {
          numero: "",
          invalid: true,
        },
    cidade: currentUserScyggz.user?.cidade,
    url_lattes: currentUserScyggz.user?.url_lattes,
    url_linkedin: currentUserScyggz.user?.url_linkedin,
  });

  const parteDoNome = useMemo<string>(
    () =>
      inputPainelCadastroInfosPessoais.name.split(" ").at(0) ??
      inputPainelCadastroInfosPessoais.name,
    [inputPainelCadastroInfosPessoais.name]
  );
  const isValid = useMemo<boolean>(() => {
    if (values.phoneNumber.invalid) {
      return false;
    }

    const errorCidade = UsuarioScyggzValidator.isInvalidCidade(values.cidade);
    if (errorCidade) {
      return false;
    }

    const errorLattes = UsuarioScyggzValidator.isInvalidLattes(
      values.url_lattes
    );
    if (errorLattes) {
      return false;
    }

    const errorLinkedin = UsuarioScyggzValidator.isInvalidLinkedin(
      values.url_linkedin
    );
    if (errorLinkedin) {
      return false;
    }

    return true;
  }, [values]);

  const onSubmit = useCallback(async () => {
    setIsSubmitting(true);

    const cidadeResult = Cidade.create({
      cidade: values.cidade?.cidade ?? "",
      estado: values.cidade?.estado ?? "",
      pais: values.cidade?.pais ?? "",
      paisCode: values.cidade?.paisCode ?? "",
    });
    if (!cidadeResult.ok) {
      console.warn("erro cidade", cidadeResult.error);

      setIsSubmitting(false);
      return;
    }

    const telefoneResult = Telefone.create({
      auth_id: inputPainelCadastroInfosPessoais.auth_id,
      numero: values.phoneNumber.numero,
    });
    if (!telefoneResult.ok) {
      console.warn("erro telefone", telefoneResult.error);

      setIsSubmitting(false);
      return;
    }

    const createUsuarioScyggzResult =
      await handlerCreateUsuarioScyggz.createUsuarioScyggz({
        auth_user_id: inputPainelCadastroInfosPessoais.auth_id,
        nome: inputPainelCadastroInfosPessoais.name,
        cidade: {
          cidade: cidadeResult.value.cidade,
          estado: cidadeResult.value.estado.estado,
          pais: cidadeResult.value.estado.pais.pais,
          paisCode: cidadeResult.value.estado.pais.paisCode,
        },
        url_lattes: values.url_lattes,
        url_linkedin: values.url_linkedin,
        telefone: telefoneResult.value.numero,
        aceite_termo_de_servico:
          inputPainelCadastroInfosPessoais.aceite_termo_de_servico,
        aceite_politica_de_privacidade:
          inputPainelCadastroInfosPessoais.aceite_politica_de_privacidade,
      });

    if (!createUsuarioScyggzResult.ok) {
      console.warn("Erro criação usuário", createUsuarioScyggzResult.error);

      setIsSubmitting(false);
      return;
    }

    const allPromises: Promise<Result<any>>[] = [];

    allPromises.push(
      handlerSaveTelefone.saveTelefone({
        auth_id: inputPainelCadastroInfosPessoais.auth_id,
        numero: telefoneResult.value.numero,
      })
    );

    await Promise.all(allPromises)
      .then((results) => {
        if (
          !results.every((result) => {
            return result.ok;
          })
        ) {
          console.warn("Erro", results);
        } else {
          onCadastro();
        }
      })
      .catch((erro) => console.warn("Erro", erro));

    setIsSubmitting(false);
  }, [
    handlerCreateUsuarioScyggz,
    handlerSaveTelefone,
    inputPainelCadastroInfosPessoais.aceite_politica_de_privacidade,
    inputPainelCadastroInfosPessoais.aceite_termo_de_servico,
    inputPainelCadastroInfosPessoais.auth_id,
    inputPainelCadastroInfosPessoais.name,
    onCadastro,
    values.cidade?.cidade,
    values.cidade?.estado,
    values.cidade?.pais,
    values.cidade?.paisCode,
    values.phoneNumber.numero,
    values.url_lattes,
    values.url_linkedin,
  ]);

  return (
    <>
      <div className="card-body">
        <h3 className="card-title mb-4">
          {parteDoNome}
          <span className="h5">, insira os seguintes dados pessoais.</span>
        </h3>

        <div className="row">
          <div className="col-12">
            <div className={`${responsive.sm ?? "mb-2"}`}>
              <InputTelefone
                label={!responsive.sm ? "Celular*" : ""}
                value={values.phoneNumber}
                onChange={(phoneNumber) => {
                  setValues({
                    ...values,
                    phoneNumber,
                  });
                }}
                validate={(numero) => {
                  const preenchido: boolean = (numero?.length ?? 0) > 0;

                  if (!preenchido) {
                    return new Error("obrigatório");
                  }
                }}
                className="mb-3"
              />
            </div>

            <div className="mb-3">
              <InputCidade
                label="Qual a sua cidade?*"
                value={values.cidade}
                onChange={(cidade) => {
                  setValues({
                    ...values,
                    cidade,
                  });
                }}
                validate={UsuarioScyggzValidator.isInvalidCidade}
              />
            </div>
          </div>
        </div>

        <div className="mb-3">
          <InputLattes
            label="CV Lattes (link)"
            value={values.url_lattes}
            onChange={(url_lattes) => {
              setValues({
                ...values,
                url_lattes,
              });
            }}
          />
        </div>

        <div className="mb-3">
          <InputLinkedin
            label="Linkedin"
            value={values.url_linkedin}
            onChange={(url_linkedin) => {
              setValues({
                ...values,
                url_linkedin,
              });
            }}
          />
        </div>

        <div className="text-end pt-4">
          <ButtonDarkGreen
            disabled={!isValid || isSubmitting}
            onClick={onSubmit}
            className="pe-5 text-uppercase"
          >
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className={"me-2" + (isSubmitting ? "" : " invisible")}
            />
            Avançar
          </ButtonDarkGreen>
        </div>
      </div>
    </>
  );
}

export type InputPainelCadastroInfosPessoaisCientista = {
  auth_id: string;
  name: string;
  aceite_termo_de_servico: Date;
  aceite_politica_de_privacidade: Date;
};

type FormType = {
  phoneNumber: TypeInputTelefone;
} & Partial<{
  cidade: CidadeOutput;
  url_lattes: string;
  url_linkedin: string;
}>;

export type OutputPainelCadastroInfosCientista = Pick<
  CientistaOutputEntity,
  "user_id" | "nome"
>;
