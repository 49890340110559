import { ReactNode, useContext, useEffect, useState } from "react";

import { UsuarioScyggz } from "../../domain/entities/usuarioScyggz";
import {
  ConexoesAprovadasContext,
  TypeConexoesAprovadasContext,
} from "./ConexoesAprovadasContext";
import { HandlersDatabaseContext } from "../HandlersProviders/HandlersDatabaseContext";

export function ConexoesAprovadasProvider({
  user,
  children,
}: {
  user: Pick<UsuarioScyggz, "auth_user_id">;
  children: ReactNode;
}) {
  const [conexoesAprovadasResult, setConexoesAprovadasResult] =
    useState<TypeConexoesAprovadasContext>();

  const { handlerConexaoAprovadasGet } = useContext(HandlersDatabaseContext);

  useEffect(() => {
    if (handlerConexaoAprovadasGet)
      handlerConexaoAprovadasGet.getConexoesAprovadas(user).then((result) => {
        setConexoesAprovadasResult(result);
      });
  }, [handlerConexaoAprovadasGet, user]);

  return (
    <ConexoesAprovadasContext.Provider
      value={{
        conexoesAprovadasResult,
      }}
      children={children}
    />
  );
}
