import bgCard from "../../../assets/images/logoCardUser.jpeg";
import { ReactNode } from "react";

export function CardHeader({ children }: { children: ReactNode }) {
  return (
    <div className="card shadow-sm rounded-4 pb-5">
      <div
        style={{
          position: "absolute",
          height: "8vw",
          minHeight: "100px",
          width: "100%",
          top: 0,
          backgroundImage: `url(${bgCard})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          borderTopRightRadius: "16px",
          borderTopLeftRadius: "16px",
        }}
      />

      {children}
    </div>
  );
}
