import {
  Firestore,
  collection,
  getDocs,
  query,
  where,
} from "@firebase/firestore";

import { Result } from "../typings";
import {
  HandlerGetTipoGrupoPesquisa,
  OutputHandlerGetTipoGruposPesquisa,
} from "../domain/usecases/interfaces/handlerGetTipoGrupoPesquisa";

export class HandlerGetTipoGrupoPesquisaFirebase
  implements HandlerGetTipoGrupoPesquisa
{
  constructor(private firestore: Firestore) {}

  getAllTipoGrupoPesquisa(): Promise<
    Result<OutputHandlerGetTipoGruposPesquisa[]>
  > {
    return new Promise((resolve) => {
      getDocs(collection(this.firestore, "tipos_grupo_pesquisa"))
        .then((querySnapshot) => {
          const tipos: OutputHandlerGetTipoGruposPesquisa[] =
            querySnapshot.docs.map((docSnapshot) => {
              const tipo =
                docSnapshot.data() as OutputHandlerGetTipoGruposPesquisa;
              tipo.id = docSnapshot.id;
              tipo.grupos = tipo.grupos ?? [];
              tipo.aprovado = !!tipo.aprovado;
              return tipo;
            });

          resolve({
            ok: true,
            value: tipos,
          });
        })
        .catch((error) =>
          resolve({
            ok: false,
            error,
          })
        );
    });
  }

  getEnabledTipoGrupoPesquisa(): Promise<
    Result<OutputHandlerGetTipoGruposPesquisa[]>
  > {
    return new Promise((resolve) => {
      getDocs(
        query(
          collection(this.firestore, "tipos_grupo_pesquisa"),
          where("aprovado", "==", true)
        )
      )
        .then((querySnapshot) => {
          const tipos: OutputHandlerGetTipoGruposPesquisa[] =
            querySnapshot.docs.map((docSnapshot) => {
              const tipo =
                docSnapshot.data() as OutputHandlerGetTipoGruposPesquisa;
              tipo.id = docSnapshot.id;
              tipo.grupos = tipo.grupos ?? [];
              tipo.aprovado = !!tipo.aprovado;
              return tipo;
            });

          resolve({
            ok: true,
            value: tipos,
          });
        })
        .catch((error) =>
          resolve({
            ok: false,
            error,
          })
        );
    });
  }
}
