import { ref, uploadBytes, FirebaseStorage } from "firebase/storage";
import { doc, setDoc, Firestore } from "firebase/firestore";

import { Result } from "../typings";
import { HandlerSavePitchPdfStartup } from "../domain/usecases/interfaces/handlerSavePitchPdfStartup";
import {
  InputPitchPdfStartup,
  OutputPitchPdfStartup,
  PitchPdfStartup,
} from "../domain/entities/PitchPdfStartup";
import { OutputStartup } from "../domain/entities/Startup";

export class HandlerSavePitchPdfStartupFirebase
  implements HandlerSavePitchPdfStartup
{
  constructor(
    private firebaseStorage: FirebaseStorage,
    private firestore: Firestore
  ) {}

  async save(
    inputPitchPdfStartup: InputPitchPdfStartup
  ): Promise<Result<OutputPitchPdfStartup>> {
    const pitchPdfStartupResult = PitchPdfStartup.create(inputPitchPdfStartup);

    if (!pitchPdfStartupResult.ok) return pitchPdfStartupResult;

    const storageRef = ref(
      this.firebaseStorage,
      pitchPdfStartupResult.value.path
    );

    return new Promise((resolve) => {
      uploadBytes(storageRef, pitchPdfStartupResult.value.file)
        .then(async () => {
          const docStartup = doc(
            this.firestore,
            "startup",
            pitchPdfStartupResult.value.id_startup
          );

          const pdf: Pick<OutputStartup, "path_pitch_pdf"> = {
            path_pitch_pdf: storageRef.fullPath,
          };

          await setDoc(docStartup, pdf, { merge: true });

          resolve(pitchPdfStartupResult);
        })
        .catch((error) =>
          resolve({
            ok: false,
            error,
          })
        );
    });
  }
}
