import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CacheVentureCapital,
  HandlerCacheVentureCapital,
} from "../../../../../domain/usecases/interfaces/HandlerCacheVentureCapital";

import { HandlerGetURLFromPath } from "../../../../../domain/usecases/interfaces/HandlerGetURLFromPath";
import { HandlerSaveLogoVentureCapital } from "../../../../../domain/usecases/interfaces/handlerSaveLogoVentureCapital";
import { CurrentUserContext } from "../../../../../globalStore/CurrentUserContext";
import { ContainerResponsive } from "../../../../generalComponents/ContainerResponsive";
import { ConteudoLogoVentureCapital } from "./ConteudoLogoVentureCapital";

export const PainelLogoVentureCapital = ({
  handlerSaveLogoVentureCapital,
  handlerGetURLFromPath,
  handlerCacheVentureCapital,
}: {
  handlerSaveLogoVentureCapital: HandlerSaveLogoVentureCapital;
  handlerGetURLFromPath: HandlerGetURLFromPath;
  handlerCacheVentureCapital: HandlerCacheVentureCapital;
}) => {
  const navigate = useNavigate();
  const { currentUserScyggz } = useContext(CurrentUserContext);

  const [cacheVentureCapital, setCacheVentureCapital] =
    useState<CacheVentureCapital>({});

  useEffect(() => {
    handlerCacheVentureCapital.get().then((cacheVentureCapitalResult) => {
      if (cacheVentureCapitalResult.ok) {
        setCacheVentureCapital(cacheVentureCapitalResult.value);
      }
    });
  }, [handlerCacheVentureCapital]);

  const paginacao = {
    start: 7,
    end: 7,
  };

  return (
    <section
      data-testid="painel-cadastro-logo-ventureCapital"
      style={{ height: "100%" }}
    >
      <ContainerResponsive
        backUrlMobile="/cadastro/venture_capital/6"
        paginacao={paginacao}
        textoMarcaDagua="Venture Capital"
        showLogo={true}
      >
        <ConteudoLogoVentureCapital
          ventureCapital={{
            id: cacheVentureCapital.id ?? "",
            path_logo: cacheVentureCapital.path_logo,
            nome: cacheVentureCapital.nome ?? "",
          }}
          user={{
            nome: currentUserScyggz.user?.nome ?? "",
          }}
          handlerSaveLogoVentureCapital={handlerSaveLogoVentureCapital}
          handlerGetURLFromPath={handlerGetURLFromPath}
          onNext={() => {
            navigate("/");
            handlerCacheVentureCapital.clear();
          }}
          handlerCacheVentureCapital={handlerCacheVentureCapital}
        />
      </ContainerResponsive>
    </section>
  );
};
