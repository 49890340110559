import { Result } from "../../typings";

export class LogoHub {
  private constructor(public id_Hub: string, public file: Blob) {}

  public static create({ id_Hub, file }: InputLogoHub): Result<LogoHub> {
    const errors: Error[] = [];

    id_Hub = id_Hub.trim();
    const isAuthValid: boolean = id_Hub.length > 0;
    if (!isAuthValid) {
      errors.push(new Error("Id Hub inválido"));
    }

    // TODO: VALIDATE LOGO HUB

    if (errors.length > 0) {
      return {
        ok: false,
        error: errors[0],
      };
    }

    return {
      ok: true,
      value: new LogoHub(id_Hub, file),
    };
  }

  public get path(): string {
    return "hub/" + this.id_Hub + "/logo/" + new Date().getTime().toString();
  }
}

export type InputLogoHub = {
  id_Hub: string;
  file: Blob;
};

export type OutputLogoHub = {
  path: string;
};
