import { Timestamp } from "@firebase/firestore";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function fieldToDate(value: any): Date {
  if (!value) {
    return new Date();
  }

  if (value instanceof Timestamp) {
    return value.toDate();
  }

  return new Date(value);
}
