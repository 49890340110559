/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement } from "react";
import Styles from "./DividerInfo.module.css"
export const DividerInfo = ({
  array,
  index,
  children,
}: {
  array: any;
  index: number;
  children: ReactElement
}) => {

  return (
    <li className={`${index !== array.length - 1 && Styles.divisor} d-flex gap-2`}>
      {children}
    </li>
  )
} 