import { ReactNode, useContext, useEffect, useState } from "react";
import { Subscription } from "rxjs";

import { UsuarioScyggz } from "../../domain/entities/usuarioScyggz";
import {
  ConexoesStatusCountContext,
  TypeConexoesStatusCountContext,
} from "./ConexoesStatusCountContext";
import { HandlersDatabaseContext } from "../HandlersProviders/HandlersDatabaseContext";

export function ConexoesStatusCountRealtimeProvider({
  user,
  children,
}: {
  user: Pick<UsuarioScyggz, "auth_user_id">;
  children: ReactNode;
}) {
  const { handlerConexaoStatusCountRealtime } = useContext(
    HandlersDatabaseContext
  );

  const [conexoesStatusCountResult, setConexoesStatusCountResult] =
    useState<TypeConexoesStatusCountContext>();

  useEffect(() => {
    let sub: Subscription | undefined;

    if (user.auth_user_id && handlerConexaoStatusCountRealtime)
      sub = handlerConexaoStatusCountRealtime
        .getConexoesStatusCountRealtime(user)
        .subscribe((result) => {
          setConexoesStatusCountResult(result);
        });

    return () => {
      if (sub) sub.unsubscribe();
    };
  }, [handlerConexaoStatusCountRealtime, user]);

  return (
    <ConexoesStatusCountContext.Provider
      value={{
        conexoesStatusCountResult,
      }}
      children={children}
    />
  );
}
