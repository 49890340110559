import HTMLHead from "../../../generalComponents/HTMLHead";
import { SelectProgramaDeConexao } from "../components/SelectProgramaDeConexao";
import { ListMyPrograms } from "./components/ListMyPrograms";

export function MyProgramasDeConexaoPage() {
  return (
    <>
      <HTMLHead title="Meus programas de conexão" description={""} />

      <div className="p-2 row g-2">
        <div className="col-md-4">
          <SelectProgramaDeConexao />
        </div>

        <div className="col-md-8">
          <ListMyPrograms />
        </div>
      </div>
    </>
  );
}
