import { FirebaseError } from "firebase/app";

export class EmailJaCadastradoError implements AuthErrorValidation {
  name = "auth/email-already-in-use";
  message = "Email já cadastrado";
  campos = [<CamposValidation>"email"];
}
export class EmailInvalidoError implements AuthErrorValidation {
  name = "auth/invalid-email";
  message = "Email inválido";
  campos = [<CamposValidation>"email"];
}
export class SenhaInvalidaError implements AuthErrorValidation {
  name = "auth/invalid-password";
  message = "Senha inválida";
  campos = [<CamposValidation>"password"];
}
export class UsuarioNaoCadastradoError implements AuthErrorValidation {
  name = "auth/user-not-found";
  message = "Usuário não cadastrado";
  campos = [<CamposValidation>"email"];
}
export class SenhaIncorretaError implements AuthErrorValidation {
  name = "auth/wrong-password";
  message = "Senha incorreta";
  campos = [<CamposValidation>"password"];
}
export class MuitasRequisicoesError implements AuthErrorValidation {
  name = "auth/too-many-requests";
  message = "Muitas requisições";
  campos = [<CamposValidation>"email"];
}
export class ErroDeRedeError implements AuthErrorValidation {
  name = "auth/network-request-failed";
  message = "Erro de rede";
  campos = [];
}
export class ErroInvalidActionCode implements AuthErrorValidation {
  constructor(public name: string) {}
  message = "Link inválido";
  campos = [];
}

export class AuthErrorsFactory {
  static createAuthErrorsValidation(
    error_code: AuthError
  ): Error | AuthErrorValidation {
    switch (error_code) {
      case "auth/email-already-in-use":
        return new EmailJaCadastradoError();
      case "auth/invalid-email":
        return new EmailInvalidoError();
      case "auth/invalid-password":
        return new SenhaInvalidaError();
      case "auth/user-not-found":
        return new UsuarioNaoCadastradoError();
      case "auth/wrong-password":
        return new SenhaIncorretaError();
      case "auth/too-many-requests":
        return new MuitasRequisicoesError();
      case "auth/network-request-failed":
        return new ErroDeRedeError();
      case "auth/invalid-action-code":
        return new ErroInvalidActionCode(error_code);
    }
    return new Error("Erro: " + error_code);
  }

  static formatErrorInstance(
    error: Error
  ): Error | FirebaseError | AuthErrorValidation {
    if (error instanceof FirebaseError) {
      if (ALL_AUTH_ERRORS.includes(error.code)) {
        return this.createAuthErrorsValidation(error.code);
      }
    }

    return error;
  }
}

type CamposValidation = "email" | "password";
export interface AuthErrorValidation extends Error {
  campos: CamposValidation[];
}

export const ALL_AUTH_ERRORS = [
  "auth/email-already-in-use",
  "auth/invalid-email",
  "auth/invalid-password",
  "auth/user-not-found",
  "auth/wrong-password",
  "auth/network-request-failed",
  "auth/too-many-requests",
  "auth/invalid-action-code",
];

export type AuthError = typeof ALL_AUTH_ERRORS[number];
