import ptbr from "date-fns/locale/pt-BR";
import en from "date-fns/locale/en-US";
import { preferredLocale, getUserLocales } from "preferred-locale";

export const localeString = preferredLocale("en-US", getUserLocales());

export const locales: { [id: string]: Locale } = {
  "pt-BR": ptbr,
  "en-US": en,
};

export const locale: Locale = locales[localeString] ?? en;

export const dateFormat: string = locale.formatLong?.date({
  width: "short",
});
