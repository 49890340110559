import { useNavigate } from "react-router-dom";
import { HandlerCacheEmpresa } from "../../../../../domain/usecases/interfaces/HandlerCacheEmpresa";
import { HandlerCreateEmpresa } from "../../../../../domain/usecases/interfaces/handlerCreateEmpresa";
import { HandlerGetInteresseInovacao } from "../../../../../domain/usecases/interfaces/handlerGetInteresseInovacao";
import { HandlerGetSetorAtuacao } from "../../../../../domain/usecases/interfaces/handlerGetSetorAtuacao";
import { ContainerResponsive } from "../../../../generalComponents/ContainerResponsive";
import { ConteudoSobreEmpresa } from "./ConteudoSobreEmpresa";

export const PainelSobreEmpresa = ({
  handlerCacheEmpresa,
  handlerGetSetorAtuacao,
  handlerGetInteresseInovacao,
  handlerCreateEmpresa,
}: {
  handlerCacheEmpresa: HandlerCacheEmpresa;
  handlerGetSetorAtuacao: HandlerGetSetorAtuacao;
  handlerGetInteresseInovacao: HandlerGetInteresseInovacao;
  handlerCreateEmpresa: HandlerCreateEmpresa;
}) => {
  const navigate = useNavigate();

  const paginacaoEmpresa = {
    start: 5,
    end: 8,
  };

  return (
    <section
      data-testid="painel-cadastro-foto-empresa"
      style={{ height: "100%" }}
    >
      <ContainerResponsive
        backUrlMobile="/cadastro/empresa/4"
        paginacao={paginacaoEmpresa}
        textoMarcaDagua="Empresa"
        showLogo={true}
      >
        <ConteudoSobreEmpresa
          handlerCreateEmpresa={handlerCreateEmpresa}
          handlerCacheEmpresa={handlerCacheEmpresa}
          onNext={() => {
            navigate("/cadastro/empresa/6");
          }}
          onBack={() => {
            navigate("/cadastro/empresa/4");
          }}
          handlerGetSetorAtuacao={handlerGetSetorAtuacao}
          handlerGetInteresseInovacao={handlerGetInteresseInovacao}
        />
      </ContainerResponsive>
    </section>
  );
};
