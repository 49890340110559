import { Result } from "../../typings";

export const MINIMO_HORAS_DISPONIBILIDADE_MENSAL = 0;
export const MAXIMO_HORAS_DISPONIBILIDADE_MENSAL = 720;

export function validateHorasDisponibilidadeMensal(
  horas_disponibilidade_mensal?: number
): Result<null> {
  horas_disponibilidade_mensal = horas_disponibilidade_mensal ?? 0;

  if (horas_disponibilidade_mensal < MINIMO_HORAS_DISPONIBILIDADE_MENSAL) {
    return {
      ok: false,
      error: new Error("Horas não pode ser negativa"),
    };
  }
  if (horas_disponibilidade_mensal > MAXIMO_HORAS_DISPONIBILIDADE_MENSAL) {
    return {
      ok: false,
      error: new Error(
        "Máximo de " + MAXIMO_HORAS_DISPONIBILIDADE_MENSAL + " horas"
      ),
    };
  }

  return {
    ok: true,
    value: null,
  };
}
