import { ReactNode, useCallback, useContext, useEffect, useState } from "react";

import { Mentor } from "../../domain/entities/Mentor";
import { InputHandlerUpdateMentorSemId } from "../../domain/usecases/interfaces/handlerUpdateMentor";
import { HandlerGetMentorFirebase } from "../../gateway/getPerfil/HandlerGetMentorFirebase";
import { HandlerUpdateMentorFirebase } from "../../gateway/HandlerUpdateMentorFirebase";
import { FirebaseContext } from "../FirebaseContext";
import {
  PerfilMentorContext,
  TypePerfilMentorContext,
} from "./PerfilMentorContext";

export function PerfilMentorFirebaseProvider({
  mentor,
  children,
}: {
  mentor: Pick<Mentor, "user_id">;
  children: ReactNode;
}) {
  const [perfilMentorResult, setPerfilMentorResult] =
    useState<TypePerfilMentorContext>();

  const { firestore } = useContext(FirebaseContext);

  const refreshMentor = useCallback(() => {
    new HandlerGetMentorFirebase(firestore)
      .get(mentor.user_id)
      .then((result) => {
        setPerfilMentorResult(result);
      });
  }, [mentor, firestore]);

  const updateMentor = useCallback(
    (inputMentor: InputHandlerUpdateMentorSemId) => {
      return new HandlerUpdateMentorFirebase(firestore).updateMentor({
        ...inputMentor,
        user_id: mentor.user_id,
      });
    },
    [mentor, firestore]
  );

  useEffect(() => {
    refreshMentor();
  }, [mentor.user_id, firestore, refreshMentor]);

  return (
    <PerfilMentorContext.Provider
      value={{
        perfilMentorResult,
        refreshMentor,
        updateMentor,
      }}
      children={children}
    />
  );
}
