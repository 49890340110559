export class ArquivoNaoEncontradoError implements Error {
  name = "storage/object-not-found";
  message = "Arquivo não encontrado";
}

export class StorageErrorsFactory {
  static createErrorsValidation(error_code: StorageError, path: string): Error {
    switch (error_code) {
      case "storage/object-not-found": {
        const e = new ArquivoNaoEncontradoError();
        e.message = e.message + ` (${path})`;
        return e;
      }
    }
    return new Error("Erro" + ` (${path})`);
  }
}

export type StorageError = "storage/object-not-found";
