import { useParams } from "react-router-dom";

import { PerfilEmpresaFirebaseProvider } from "../../../globalStore/perfilEmpresa/PerfilEmpresaFirebaseProvider";
import { PerfilInfoEmpresaFromContext } from "./PerfilInfoEmpresaFromContext";

export function PainelEmpresa() {
  const { id_empresa } = useParams();

  if (!id_empresa) {
    return <>...</>;
  }

  return (
    <PerfilEmpresaFirebaseProvider
      empresa={{
        id: id_empresa,
      }}
    >
      <PerfilInfoEmpresaFromContext />
    </PerfilEmpresaFirebaseProvider>
  );
}
