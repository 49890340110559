import { AssociacaoAnjo } from "../../../../../domain/entities/associacaoAnjo";
import { CardLogoInfoEmpresa } from "./CardLogoEmpresaInfo";
import { TitleInfo } from "./TitleInfo";

export const InfoAssociocoesAnjo = ({
  associacoes,
  classes,
}: {
  associacoes?: Pick<AssociacaoAnjo, "nome" | "path_logo" | "cidade">[];
  classes: string;
}) => {
  return (
    <>
      {associacoes && associacoes.length > 0 && (
        <section className={classes}>
          <TitleInfo classes="mb-3" children="Associações Anjo" />
          <div className="ms-2 d-flex gap-3 flex-wrap">
            {associacoes?.map((associacao, i) => (
              <CardLogoInfoEmpresa
                key={i}
                src={associacao.path_logo}
                alt={associacao.nome}
                local={associacao.cidade}
                nome={associacao.nome}
              />
            ))}
          </div>
        </section>
      )}
    </>
  );
};
