import {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

import HTMLHead from "../../../generalComponents/HTMLHead";
import { Etapa1NewPrograma } from "./components/Etapa1NewPrograma";
import {
  Etapa2NewPrograma,
  ProgramaConexaoEtapa2,
} from "./components/Etapa2NewPrograma";
import {
  Etapa5NewPrograma,
  ProgramaConexaoEtapa5,
} from "./components/Etapa5NewPrograma";
import {
  Etapa3NewPrograma,
  ProgramaConexaoEtapa3,
} from "./components/Etapa3NewPrograma";
import {
  Etapa4NewPrograma,
  ProgramaConexaoEtapa4,
} from "./components/Etapa4NewPrograma";
import { HandlersStorageContext } from "../../../../globalStore/HandlersProviders/HandlersStorageContext";
import { HandlersDatabaseContext } from "../../../../globalStore/HandlersProviders/HandlersDatabaseContext";
import { CurrentUserContext } from "../../../../globalStore/CurrentUserContext";
import { ConviteProgramaConexao } from "../../../../domain/entities/ConviteProgramaConexao";
import { Result } from "../../../../typings";

export function NewProgramaDeConexaoPage() {
  const { currentUserAuth } = useContext(CurrentUserContext);
  const { handlerProgramaConexaoCreate, handlerConviteProgramaConexaoCreate } =
    useContext(HandlersDatabaseContext);
  const { handlerProgramaConexaoSaveLogo } = useContext(HandlersStorageContext);

  const navigate = useNavigate();

  const [programaToSave, setProgramaToSave] = useState<ProgramaConexaoEtapa2>({
    stakeholder_type_group_a: [],
    stakeholder_type_group_b: [],
    invitation_rule: "from_both",
    duration: {},
    nome: "",
    about: "",
  });
  const [groupA, setGroupA] = useState<ProgramaConexaoEtapa3>({
    gruposA: [],
  });
  const [groupB, setGroupB] = useState<ProgramaConexaoEtapa4>({
    gruposB: [],
  });
  const [messages, setMessages] = useState<ProgramaConexaoEtapa5>({
    message_to_a: "",
    message_to_b: "",
  });
  const [indexEtapaToShow, setIndexEtapaToShow] = useState<number>(0);

  const salvarPrograma = useCallback(async (): Promise<
    Result<{ id_programa: string }, Error | Error[]>
  > => {
    if (
      handlerProgramaConexaoCreate &&
      handlerProgramaConexaoSaveLogo &&
      handlerConviteProgramaConexaoCreate
    ) {
      const createResult = await handlerProgramaConexaoCreate.create({
        logo_path: programaToSave.nome,
        start_date: programaToSave.duration.start ?? new Date(),
        end_date: programaToSave.duration.end ?? new Date(),
        about: programaToSave.about,
        invitation_rule: programaToSave.invitation_rule,
        nome: programaToSave.nome,
        stakeholder_type_group_a: programaToSave.stakeholder_type_group_a,
        stakeholder_type_group_b: programaToSave.stakeholder_type_group_b,
        gestores: currentUserAuth.user_auth?.auth_id
          ? [currentUserAuth.user_auth.auth_id]
          : [],
        message_to_a: messages.message_to_a,
        message_to_b: messages.message_to_b,
      });

      if (!createResult.ok) {
        return createResult;
      }

      const logo =
        programaToSave.logo?.cropImage ?? programaToSave.logo?.imageBase;

      if (logo) {
        const logoResult = await handlerProgramaConexaoSaveLogo.save({
          id_programa: createResult.value.id,
          file: logo,
        });

        if (!logoResult.ok) {
          return logoResult;
        }

        const stakeholdersA: ConviteProgramaConexao[] = groupA.gruposA.map(
          (stakeholderA) => ({
            id: "",
            alvo: {
              id: stakeholderA.id,
              cidade: stakeholderA.cidade,
              nome: stakeholderA.nome,
              typesStakeholder: stakeholderA.typesStakeholder,
              avatar_path: stakeholderA.avatar_path,
              headline: stakeholderA.headline,
            },
            programa_conexao: {
              id: createResult.value.id,
              logo_path: logoResult.value.logo_path,
              message_to_a: messages.message_to_a,
              message_to_b: messages.message_to_b,
              nome: programaToSave.nome,
              stakeholder_type_group_a: programaToSave.stakeholder_type_group_a,
              stakeholder_type_group_b: programaToSave.stakeholder_type_group_b,
            },
            status: undefined,
            to_group: ["a"],
          })
        );
        const stakeholdersB: ConviteProgramaConexao[] = groupB.gruposB.map(
          (stakeholder) => ({
            id: "",
            alvo: {
              id: stakeholder.id,
              cidade: stakeholder.cidade,
              nome: stakeholder.nome,
              typesStakeholder: stakeholder.typesStakeholder,
              avatar_path: stakeholder.avatar_path,
              headline: stakeholder.headline,
            },
            programa_conexao: {
              id: createResult.value.id,
              logo_path: logoResult.value.logo_path,
              message_to_a: messages.message_to_a,
              message_to_b: messages.message_to_b,
              nome: programaToSave.nome,
              stakeholder_type_group_a: programaToSave.stakeholder_type_group_a,
              stakeholder_type_group_b: programaToSave.stakeholder_type_group_b,
            },
            status: undefined,
            to_group: ["b"],
          })
        );

        const convitesResult = await handlerConviteProgramaConexaoCreate.create(
          [...stakeholdersA, ...stakeholdersB]
        );

        if (convitesResult.ok) {
          return {
            ok: true,
            value: {
              id_programa: createResult.value.id,
            },
          };
        } else {
          return convitesResult;
        }
      }
    }

    return {
      ok: false,
      error: new Error("handlers required"),
    };
  }, [
    currentUserAuth.user_auth?.auth_id,
    groupA.gruposA,
    groupB.gruposB,
    handlerConviteProgramaConexaoCreate,
    handlerProgramaConexaoCreate,
    handlerProgramaConexaoSaveLogo,
    messages.message_to_a,
    messages.message_to_b,
    programaToSave.about,
    programaToSave.duration.end,
    programaToSave.duration.start,
    programaToSave.invitation_rule,
    programaToSave.logo?.cropImage,
    programaToSave.logo?.imageBase,
    programaToSave.nome,
    programaToSave.stakeholder_type_group_a,
    programaToSave.stakeholder_type_group_b,
  ]);

  const previousEtapa = useCallback(() => {
    setIndexEtapaToShow((indexEtapaToShow) => indexEtapaToShow - 1);
  }, []);
  const nextEtapa = useCallback(() => {
    setIndexEtapaToShow((indexEtapaToShow) => indexEtapaToShow + 1);
  }, []);
  const etapas = useMemo<ReactNode[]>(() => {
    return [
      <Etapa1NewPrograma
        onBack={() => {
          navigate("/programa_conexao");
        }}
        onNext={nextEtapa}
      />,
      <Etapa2NewPrograma
        value={programaToSave}
        onChange={setProgramaToSave}
        onBack={previousEtapa}
        onNext={nextEtapa}
      />,
      <Etapa3NewPrograma
        typeStakeholdersGroupA={programaToSave.stakeholder_type_group_a}
        value={groupA}
        onChange={setGroupA}
        onBack={previousEtapa}
        onNext={nextEtapa}
      />,
      <Etapa4NewPrograma
        typeStakeholdersGroupB={programaToSave.stakeholder_type_group_b}
        value={groupB}
        onChange={setGroupB}
        onBack={previousEtapa}
        onNext={nextEtapa}
      />,
      <Etapa5NewPrograma
        stakeholdersGroupA={groupA.gruposA}
        stakeholdersGroupB={groupB.gruposB}
        value={messages}
        onChange={setMessages}
        onBack={previousEtapa}
        onNext={async () => {
          const salvarProgramaResult = await salvarPrograma();

          if (salvarProgramaResult.ok) {
            navigate(
              "/programa_conexao/" + salvarProgramaResult.value.id_programa
            );
          } else {
            console.warn(salvarProgramaResult.error);
          }
        }}
      />,
    ];
  }, [
    groupA,
    groupB,
    messages,
    navigate,
    nextEtapa,
    previousEtapa,
    programaToSave,
    salvarPrograma,
  ]);
  const etapaToShow = useMemo<ReactNode>(() => {
    return etapas.at(indexEtapaToShow);
  }, [etapas, indexEtapaToShow]);

  useEffect(() => {
    setGroupA((groupA) => ({
      gruposA: [
        ...groupA.gruposA.filter((stakeholder) => {
          return stakeholder.typesStakeholder.some((typeOfStakeholder) =>
            programaToSave.stakeholder_type_group_a.includes(typeOfStakeholder)
          );
        }),
      ],
    }));
  }, [programaToSave.stakeholder_type_group_a]);

  useEffect(() => {
    setGroupB((groupB) => ({
      gruposB: [
        ...groupB.gruposB.filter((stakeholder) => {
          return stakeholder.typesStakeholder.some((typeOfStakeholder) =>
            programaToSave.stakeholder_type_group_b.includes(typeOfStakeholder)
          );
        }),
      ],
    }));
  }, [programaToSave.stakeholder_type_group_b]);

  return (
    <>
      <HTMLHead title="Novo programa de conexão" description={""} />

      <div className="p-2">{etapaToShow}</div>
    </>
  );
}
