import { ProgressBar } from "react-bootstrap";

import { UseUserPerfilType } from "../../../../hooks/useUserPerfil";
import { HeaderUserPage } from "../HeaderUserPage";
import { AbasPerfis } from "./AbasPerfis";
import { PerfilInfoCientista } from "../cientista/PerfilInfoCientista";
import { PerfilInfoMentor } from "../mentor/PerfilInfoMentor";
import { PerfilInfoInvestidorAnjo } from "../investidor/PerfilInfoInvestidorAnjo";
import { FooterUsuarioFromContext } from "../FooterUsuarioFromContext";
import bgCard from "../../../../assets/images/logoCardUser.jpeg";

export function UserContentPage({
  baseUrl,
  userPerfil,
  current_user_auth_id_conexoes,
  isCurrentUser,
  className,
}: {
  baseUrl: string;
  userPerfil?: UseUserPerfilType;
  current_user_auth_id_conexoes?: string[];
  isCurrentUser: boolean;
  className?: string;
}) {
  return userPerfil ? (
    userPerfil.ok ? (
      <div className={className}>
        <div className="card shadow-sm rounded-4 pb-5 mb-3">
          <div
            style={{
              position: "absolute",
              height: "8vw",
              minHeight: "100px",
              width: "100%",
              top: 0,
              backgroundImage: `url(${bgCard})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              borderTopRightRadius: "16px",
              borderTopLeftRadius: "16px",
            }}
          />

          <HeaderUserPage
            user={userPerfil.value.user}
            isCurrentUser={isCurrentUser}
            current_user_auth_id_conexoes={current_user_auth_id_conexoes}
          />
        </div>

        <div className="ps-4">
          <AbasPerfis
            isCurrentUser={isCurrentUser}
            user={userPerfil.value.user}
            perfilAtivo={
              userPerfil.value.perfil?.ok
                ? userPerfil.value.perfil?.value.tipo
                : undefined
            }
            formatURL={({ user_id, perfil }) =>
              baseUrl + "/" + user_id + "/" + perfil
            }
            onClickOrganizacoes={() => {
              console.log("");
            }}
            showOrganizacoes={false}
            userHasOrganizacoes={false}
          />
        </div>

        {userPerfil.value.perfil ? (
          userPerfil.value.perfil.ok ? (
            <>
              <div className="bg-white shadow-sm rounded-4 p-4 bt-0">
                {userPerfil.value.perfil.value.tipo == "cientista" ? (
                  <PerfilInfoCientista
                    areas_de_conhecimento={
                      userPerfil.value.perfil.value.areas_de_conhecimento
                    }
                    sobre={userPerfil.value.perfil.value.sobre}
                    unidades={userPerfil.value.perfil.value.unidades}
                    grupos_de_pesquisa={
                      userPerfil.value.perfil.value.grupos_de_pesquisa
                    }
                    premiacoes={userPerfil.value.perfil.value.premiacoes}
                    tecnologias={userPerfil.value.perfil.value.tecnologias}
                  />
                ) : userPerfil.value.perfil.value.tipo == "mentor" ? (
                  <PerfilInfoMentor
                    interesse_startup_estagio={
                      userPerfil.value.perfil.value.interesse_startup_estagio
                    }
                    interesse_startup_mecanismo_solucao={
                      userPerfil.value.perfil.value
                        .interesse_startup_mecanismo_solucao
                    }
                    interesse_startup_modelo_negocio={
                      userPerfil.value.perfil.value
                        .interesse_startup_modelo_negocio
                    }
                    interesse_startup_segmento_atuacao={
                      userPerfil.value.perfil.value
                        .interesse_startup_segmento_atuacao
                    }
                    sobre_o_mentor={
                      userPerfil.value.perfil.value.sobre_o_mentor
                    }
                    horas_disponibilidade_mensal={
                      userPerfil.value.perfil.value.horas_disponibilidade_mensal
                    }
                    interesse_startup_localizacoes={
                      userPerfil.value.perfil.value
                        .interesse_startup_localizacoes
                    }
                    premiacoes={userPerfil.value.perfil.value.premiacoes}
                  />
                ) : userPerfil.value.perfil.value.tipo == "investidor" ? (
                  <PerfilInfoInvestidorAnjo
                    premiacoes_e_certificados={
                      userPerfil.value.perfil.value.premiacoes_e_certificados
                    }
                    areas_de_especializacao={
                      userPerfil.value.perfil.value.areas_de_especializacao
                    }
                    associacoes_anjo={
                      userPerfil.value.perfil.value.associacoes_anjo
                    }
                    interesse_startup_estagio={
                      userPerfil.value.perfil.value.interesse_startup_estagio
                    }
                    interesse_startup_localizacoes={
                      userPerfil.value.perfil.value
                        .interesse_startup_localizacoes
                    }
                    interesse_startup_mecanismo_solucao={
                      userPerfil.value.perfil.value
                        .interesse_startup_mecanismo_solucao
                    }
                    interesse_startup_modelo_negocio={
                      userPerfil.value.perfil.value
                        .interesse_startup_modelo_negocio
                    }
                    interesse_startup_segmento_atuacao={
                      userPerfil.value.perfil.value
                        .interesse_startup_segmento_atuacao
                    }
                    startups_investidas={
                      userPerfil.value.perfil.value.startups_investidas
                    }
                    tese_de_investimento={
                      userPerfil.value.perfil.value.tese_de_investimento
                    }
                  />
                ) : (
                  <></>
                )}

                <FooterUsuarioFromContext />
              </div>
            </>
          ) : (
            <div className="alert alert-danger" role="alert">
              erro na leitura do perfil
            </div>
          )
        ) : (
          <ProgressBar
            animated
            variant="info"
            now={100}
            className="w-100 m-1"
          />
        )}
      </div>
    ) : (
      <>
        <div className="alert alert-danger" role="alert">
          erro na leitura do usuário
        </div>
      </>
    )
  ) : (
    <>
      <ProgressBar animated variant="info" now={100} className="w-100 m-1" />
    </>
  );
}
