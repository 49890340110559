import { ParticipanteConexao } from "../../../../domain/entities/Conexao";
import { TipoPerfil } from "../../../../domain/entities/usuarioScyggz";
import { CardPerfil } from "../../busca/components/CardPerfil";

export function ListConexoes({
  conexoes,
}: {
  conexoes: {
    id: string;
    user: ParticipanteConexao;
  }[];
}) {
  return (
    <section className="row p-1 gy-3">
      {conexoes.map((conexao) => (
        <div
          className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2"
          key={conexao.id}
        >
          <CardPerfil
            user_id={conexao.user.auth_user_id}
            nome={conexao.user.nome}
            path_avatar={conexao.user.avatar_path?.path}
            tiposPerfil={Object.entries(conexao.user.perfis ?? {})
              .filter(([, enabled]) => enabled)
              .map(([tipo]) => tipo as TipoPerfil)}
          />
        </div>
      ))}
    </section>
  );
}
