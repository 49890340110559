import Select, { StylesConfig } from "react-select";
import { useContext, useEffect, useState } from "react";
import makeAnimated from "react-select/animated";

import { ResizeContext } from "../../hooks/useResize";
import { SegmentoAtuacao } from "../../domain/entities/segmentoAtuacao";
import { HandlersDatabaseContext } from "../../globalStore/HandlersProviders/HandlersDatabaseContext";

const animatedComponents = makeAnimated();

export function InputSegmentosAtuacao({
  label,
  value,
  onChange,
  validate,
  className,
}: {
  label: string;
  value: SegmentoAtuacao[];
  onChange: (segmentosAtuacao: SegmentoAtuacao[]) => void;
  validate: (segmentosAtuacao: SegmentoAtuacao[]) => Error | undefined;
  className?: string;
}) {
  const { handlerGetSegmentoAtuacao } = useContext(HandlersDatabaseContext);

  const [allSegmentoAtuacao, setAllSegmentoAtuacao] = useState<
    SegmentoAtuacao[]
  >([]);
  const [isAllSegmentoAtuacaoLoading, setIsAllSegmentoAtuacaoLoading] =
    useState<boolean>(false);

  const { responsive } = useContext(ResizeContext);

  const [error, setError] = useState<string>();
  const [isChanged, setIsChanged] = useState<boolean>(false);

  useEffect(() => {
    const errorValidate = validate(value);

    if (errorValidate) {
      setError(errorValidate.message);
    } else {
      setError(undefined);
    }
  }, [validate, value]);

  useEffect(() => {
    if (handlerGetSegmentoAtuacao) {
      setIsAllSegmentoAtuacaoLoading(true);
      handlerGetSegmentoAtuacao
        .getAllSegmentoAtuacao()
        .then((handlerGetSegmentoAtuacaoResult) => {
          if (handlerGetSegmentoAtuacaoResult.ok)
            setAllSegmentoAtuacao(handlerGetSegmentoAtuacaoResult.value);

          setIsAllSegmentoAtuacaoLoading(false);
        });
    }
  }, [handlerGetSegmentoAtuacao]);

  const customStylesSelect: StylesConfig<SegmentoAtuacao, true> = {
    control: () => ({
      border: "none",
      borderBottom: "3px #666600 solid",
      display: "flex",
      background: " #fff",
      borderBottomLeftRadius: "8px",
      textAlign: "left",
    }),
  };

  return (
    <div className={className}>
      <label className="form-label fw-bolder m-0" htmlFor="selSegmentosAtuacao">
        {label}
      </label>
      <Select<SegmentoAtuacao, true>
        isLoading={isAllSegmentoAtuacaoLoading}
        options={allSegmentoAtuacao}
        styles={responsive.sm ? customStylesSelect : undefined}
        getOptionLabel={(o) => o.nome}
        getOptionValue={(o) => o.id}
        components={animatedComponents}
        isMulti
        value={value}
        placeholder=""
        onChange={(segmentosAtuacao) => {
          onChange([...segmentosAtuacao]);
          setIsChanged(true);
        }}
        onBlur={() => setIsChanged(true)}
        className={error && isChanged ? "is-invalid " : ""}
        noOptionsMessage={() => "sem opção"}
        inputId="selSegmentosAtuacao"
      />

      <div className="invalid-feedback flex-fill">{error}</div>
    </div>
  );
}
