import { useNavigate } from "react-router";

import { ConteudoCadastro } from "../../components/ConteudoCadastro";
import { ContainerResponsive } from "../../../../generalComponents/ContainerResponsive";

export function PainelCadastroAuthEmpresa() {
  const navigate = useNavigate();

  const paginacaoElegibilidadeEmpresa = {
    start: 2,
    end: 8,
  };

  return (
    <section data-testid="painel-cadastro-auth-empresa" className="h-100">
      <ContainerResponsive
        backUrlMobile="/cadastro/empresa/1"
        paginacao={paginacaoElegibilidadeEmpresa}
        showLogo={true}
      >
        <ConteudoCadastro
          onRedirect={() => {
            navigate("/cadastro/empresa/3");
          }}
          onCadastro={() => {
            navigate("/cadastro/empresa/3");
          }}
        />
      </ContainerResponsive>
    </section>
  );
}
