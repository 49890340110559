import {
  CacheCientista,
  HandlerCacheCientista,
} from "../domain/usecases/interfaces/HandlerCacheCientista";
import { Result, Writeable } from "../typings";

export class HandlerCacheCientistaStorage implements HandlerCacheCientista {
  private readonly keyCientistaPrefix = "CientistaCadastroInScyggz";

  clear(): Promise<Result<null>> {
    if (!localStorage) {
      return Promise.resolve({
        ok: false,
        error: new Error(
          "Não fez cache do Cientista pois o navegador é incompativel"
        ),
      });
    }

    try {
      localStorage.removeItem(this.keyCientistaPrefix);

      return Promise.resolve({
        ok: true,
        value: null,
      });
    } catch (error) {
      return Promise.resolve({
        ok: false,
        error: error as Error,
      });
    }
  }

  async save(cientista: CacheCientista): Promise<Result<null, Error>> {
    if (!localStorage) {
      return Promise.resolve({
        ok: false,
        error: new Error(
          "Não fez cache do Cientista pois o navegador é incompativel"
        ),
      });
    }

    try {
      const getResult = await this.get();

      if (!getResult.ok) {
        return getResult;
      }

      localStorage.setItem(
        this.keyCientistaPrefix,
        JSON.stringify({ ...getResult.value, ...cientista })
      );

      return Promise.resolve({
        ok: true,
        value: null,
      });
    } catch (error) {
      return Promise.resolve({
        ok: false,
        error: error as Error,
      });
    }
  }

  get(): Promise<Result<CacheCientista, Error>> {
    const cientistaStorage = localStorage.getItem(this.keyCientistaPrefix);

    if (!cientistaStorage) {
      return Promise.resolve({
        ok: true,
        value: {},
      });
    }

    const cientista = JSON.parse(cientistaStorage) as
      | CacheCientista
      | undefined;

    if (!cientista) {
      return Promise.resolve({
        ok: true,
        value: {},
      });
    }

    const Cientista = this.convertToCientista(cientista);

    return Promise.resolve({
      ok: true,
      value: Cientista,
    });
  }

  private convertToCientista(
    inputCientista: CacheCientista | undefined
  ): Writeable<CacheCientista> {
    const cientista: Writeable<CacheCientista> = {};

    if (!inputCientista) return cientista;

    if (inputCientista.user_id) cientista.user_id = inputCientista.user_id;

    if (inputCientista.unidades) cientista.unidades = inputCientista.unidades;

    if (inputCientista.areas_de_conhecimento)
      cientista.areas_de_conhecimento = inputCientista.areas_de_conhecimento;

    if (inputCientista.sobre) cientista.sobre = inputCientista.sobre;

    if (inputCientista.premiacoes)
      cientista.premiacoes = inputCientista.premiacoes;

    if (inputCientista.grupos_de_pesquisa)
      cientista.grupos_de_pesquisa = inputCientista.grupos_de_pesquisa;

    if (inputCientista.tecnologias)
      cientista.tecnologias = inputCientista.tecnologias;

    if (inputCientista.nome) cientista.nome = inputCientista.nome;

    return cientista;
  }
}
