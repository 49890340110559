import { useContext, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

import { ImgFromPathNew } from "../../../../generalComponents/ImgFromPathNew";
import { HandlersDatabaseContext } from "../../../../../globalStore/HandlersProviders/HandlersDatabaseContext";
import { InputTextarea } from "../../../../generalComponents/InputTextarea";
import { ProgramaConexaoComment } from "../../../../../domain/entities/ProgramaConexaoComment";

export function PostMuralComment({
  id_programa,
  user_id,
  currentUserName,
  currentUserPath,
  onPost,
  className = "",
}: {
  id_programa: string;
  user_id: string;
  currentUserName: string;
  currentUserPath?: string;
  onPost: () => void;
  className?: string;
}) {
  const { handlerComentarioProgramaConexaoCreateInMural } = useContext(
    HandlersDatabaseContext
  );

  const [message, setMessage] = useState<string>("");
  const [showModalPost, setShowModalPost] = useState<boolean>(false);
  const [isPosting, setIsPosting] = useState<boolean>(false);

  const error = useMemo<Error | undefined>(
    () => ProgramaConexaoComment.isInvalidMessage(message),
    [message]
  );

  return (
    <>
      <div className={"card shadow-sm px-2 p-md-3 " + className}>
        <div className="card-body p-2 p-md-3 row align-items-center">
          <div className="col-2 col-sm-1">
            <ImgFromPathNew
              alt={currentUserName}
              path={currentUserPath}
              className="rounded-circle border border-2 border-white bg-white"
              elementErro={
                <div className="rounded-circle bg-secondary-subtle text-white d-flex justify-content-center align-items-center opacity-75 w-100 h-100">
                  <FontAwesomeIcon icon={faUser} className="w-50 h-50" />
                </div>
              }
            />
          </div>
          <div
            className="col-10 col-sm-11 text-bg-light rounded-5 p-4 cursorPointer"
            onClick={async () => {
              setShowModalPost(true);
            }}
          >
            clique aqui para escrever algo...
          </div>
        </div>
      </div>

      {/* // TODO: COMPONENTE MODAL TO COMMENT */}

      <Modal
        show={showModalPost}
        onHide={() => {
          setShowModalPost(false);
        }}
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Escreva uma postagem</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <InputTextarea
            label=""
            value={message}
            onChange={setMessage}
            id="iptMessage"
            title="Escreva uma postagem"
            min={{
              value: ProgramaConexaoComment.MIN_SIZE_MESSAGE,
              errorMessage: ProgramaConexaoComment.MIN_SIZE_MESSAGE_ERROR,
            }}
            max={{
              value: ProgramaConexaoComment.MAX_SIZE_MESSAGE,
              errorMessage: ProgramaConexaoComment.MAX_SIZE_MESSAGE_ERROR,
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn px-4 text-white background-spinoff"
            disabled={!!error || isPosting}
            onClick={async () => {
              setIsPosting(true);

              if (handlerComentarioProgramaConexaoCreateInMural) {
                const result =
                  await handlerComentarioProgramaConexaoCreateInMural?.createInMural(
                    {
                      autor: {
                        id: user_id,
                        nome: currentUserName,
                        avatar_path: currentUserPath,
                      },
                      created_at: new Date(),
                      id_programa,
                      message,
                    }
                  );

                if (result.ok) {
                  setShowModalPost(false);
                  setMessage("");
                  onPost();
                } else {
                  console.warn("Erro send comment", result.error);
                }
              }

              setIsPosting(false);
            }}
          >
            Enviar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
