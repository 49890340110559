import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { OutputStartup } from "../../../domain/entities/Startup";
import { HandlersDatabaseContext } from "../../../globalStore/HandlersProviders/HandlersDatabaseContext";
import { Result } from "../../../typings";
import { InfoCardStartup } from "./components/generalComponents/InfoCardStartup";

type SubPageOgarnizacoesProps = {
  userId?: string;
};

export function SubPageOgarnizacoes({
  userId = undefined,
}: SubPageOgarnizacoesProps) {
  const { handlerGetStartup } = useContext(HandlersDatabaseContext);

  const [startupsResult, setStartupsResult] =
    useState<Result<OutputStartup[]>>();

  const startups = useMemo<OutputStartup[]>(() => {
    if (startupsResult?.ok) {
      return startupsResult.value.reduce<OutputStartup[]>((prev, startup) => {
        prev.push(startup);
        return prev;
      }, []);
    }
    return [];
  }, [startupsResult]);

  const refreshStartups = useCallback(() => {
    setLoading(true);
    console.log("userId", userId)
    handlerGetStartup
      ?.getStartupsByUserIdAccepted(userId)
      .then((startupsResult) => {
        console.log("startupsResult", startupsResult);
        setLoading(false);
        setStartupsResult(startupsResult);
      });
  }, [userId, handlerGetStartup]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    refreshStartups();
  }, [refreshStartups]);

  return (
    <>
      <InfoCardStartup startups={startups} className="mb-0" loading={loading} />
    </>
  );
}
