import { useContext, useEffect } from "react";
import { useNavigate } from "react-router";

import { CurrentUserContext } from "../../../../../globalStore/CurrentUserContext";
import { ContainerResponsive } from "../../../../generalComponents/ContainerResponsive";
import { PainelElegibilidade } from "../../components/PainelElegibilidade/PainelElegibilidade";
import { HandlersDatabaseContext } from "../../../../../globalStore/HandlersProviders/HandlersDatabaseContext";

export function PainelElegibilidadeVentureCapital() {
  const navigate = useNavigate();

  const { handlerAceiteSet } = useContext(HandlersDatabaseContext);
  const { currentUserScyggz } = useContext(CurrentUserContext);

  useEffect(() => {
    const userJahTemPerfilVentureCapital =
      currentUserScyggz.status == "encontrado" &&
      Object.hasOwn(currentUserScyggz.user?.perfis ?? {}, "ventureCapital");

    if (userJahTemPerfilVentureCapital) navigate("/");
  }, [currentUserScyggz, navigate]);

  const paginacaoElegibilidade = {
    start: 1,
    end: 7,
  };
  const nextUrl = "/cadastro/venture_capital/2";

  return (
    <section
      data-testid="painel-elegibilidade-VentureCapital"
      className="h-100"
    >
      <ContainerResponsive
        backUrlMobile="/"
        textoMarcaDagua={"Venture Capital"}
        paginacao={paginacaoElegibilidade}
        showLogo={true}
      >
        <PainelElegibilidade
          requisitos={[
            "Sou um pessoa física, não estou representando um fundo de Venture Capital;",
            "Já invisto e/ou pretendo investir em startup;",
            "Já participo ou pretendo participar do ecossistema de empreendedorismo.",
          ]}
          titulo={"Venture Capital"}
          onNext={() => {
            if (handlerAceiteSet) handlerAceiteSet.set("vc", new Date());
            navigate(nextUrl);
          }}
          onPrevious={() => {
            navigate("/");
          }}
        />
      </ContainerResponsive>
    </section>
  );
}
