import { Firestore, doc, setDoc } from "@firebase/firestore";
import { FirebaseError } from "firebase/app";

import { Result } from "../typings";
import {
  FirestoreErrorsFactory,
  FirestoreError,
} from "../domain/erros/FirestoreErrors";
import { HandlerCreateMentor } from "../domain/usecases/interfaces/handlerCreateMentor";
import { Mentor, MentorInput } from "../domain/entities/Mentor";

export class HandlerCreateMentorFirebase implements HandlerCreateMentor {
  constructor(private firestore: Firestore) {}

  async createMentor(
    inputMentor: MentorInput
  ): Promise<
    Result<MentorInput, Error | Partial<Record<keyof Mentor, Error>>>
  > {
    const createMentorResult = Mentor.create(inputMentor);

    if (!createMentorResult.ok) {
      return createMentorResult;
    }

    return new Promise((resolve) => {
      const docUser = doc(
        this.firestore,
        "usuarios",
        inputMentor.user_id,
        "perfis/mentor"
      );

      setDoc(docUser, inputMentor)
        .then(() => {
          resolve({
            ok: true,
            value: {
              ...inputMentor,
              created_in: new Date(),
              habilitado: false,
            },
          });
        })
        .catch((error: FirebaseError) => {
          const instanciaErro = FirestoreErrorsFactory.createErrorsValidation(
            <FirestoreError>error.code
          );
          if (instanciaErro) {
            resolve({
              ok: false,
              error: instanciaErro,
            });
            return;
          }

          console.warn("Erro no cadastro: ", error.code, error.message);

          resolve({
            ok: false,
            error,
          });
        });
    });
  }
}
