import { useParams } from "react-router-dom";

import { PerfilHubFirebaseProvider } from "../../../globalStore/perfilHub/PerfilHubFirebaseProvider";
import { PerfilInfoHubFromContext } from "./PerfilInfoHubFromContext";

export function PainelHub() {
  const { id_hub } = useParams();

  if (!id_hub) {
    return <>...</>;
  }

  return (
    <PerfilHubFirebaseProvider
      hub={{
        id: id_hub,
      }}
    >
      <PerfilInfoHubFromContext />
    </PerfilHubFirebaseProvider>
  );
}
