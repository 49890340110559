import { Firestore, doc, getDoc } from "@firebase/firestore";

import { Result } from "../typings";
import { HandlerGetPermissionsOfUser } from "../domain/usecases/interfaces/HandlerGetPermissionsOfUser";
import { ManagerPermission } from "../domain/entities/ManagerPermission";

export class HandlerManagerPermissionFirebase
  implements HandlerGetPermissionsOfUser
{
  constructor(private firestore: Firestore) {}

  getPermissionsOfUser(
    auth_id: string
  ): Promise<Result<Readonly<ManagerPermission>>> {
    return new Promise((resolve) => {
      const docReference = doc(this.firestore, "manager_permissions", auth_id);

      getDoc(docReference)
        .then((docSnapshot) => {
          const data = docSnapshot.data() as Partial<ManagerPermission>;

          resolve({
            ok: true,
            value: {
              ...data,
              uid: docSnapshot.id,
            },
          });
        })
        .catch((error) =>
          resolve({
            ok: false,
            error,
          })
        );
    });
  }
}
