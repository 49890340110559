import { useEffect, useState } from "react";

import { TipoPerfil } from "../../../../../domain/entities/usuarioScyggz";
import { PerfilCientistaFirebaseProvider } from "../../../../../globalStore/perfilCientista/PerfilCientistaFirebaseProvider";
import { PerfilInvestidorFirebaseProvider } from "../../../../../globalStore/perfilInvestidor/PerfilInvestidorFirebaseProvider";
import { PerfilMentorFirebaseProvider } from "../../../../../globalStore/perfilMentor/PerfilMentorFirebaseProvider";
import { PerfilInfoCientistaFromContext } from "../../cientista/PerfilInfoCientistaFromContext";
import { PerfilInfoInvestidorAnjoFromContext } from "../../investidor/PerfilInfoInvestidorAnjoFromContext";
import { PerfilInfoMentorFromContext } from "../../mentor/PerfilInfoMentorFromContext";

export function WrapperPerfil({
  tipo_perfil,
  user_id,
}: {
  tipo_perfil: TipoPerfil;
  user_id: string;
}): JSX.Element {
  const [conteudo, setConteudo] = useState<JSX.Element>();

  useEffect(() => {
    switch (tipo_perfil) {
      case "mentor":
        setConteudo(
          <PerfilMentorFirebaseProvider
            mentor={{
              user_id,
            }}
          >
            <PerfilInfoMentorFromContext />
          </PerfilMentorFirebaseProvider>
        );
        break;
      case "cientista":
        setConteudo(
          <PerfilCientistaFirebaseProvider
            cientista={{
              user_id,
            }}
          >
            <PerfilInfoCientistaFromContext />
          </PerfilCientistaFirebaseProvider>
        );
        break;
      case "investidor":
        setConteudo(
          <PerfilInvestidorFirebaseProvider
            investidor={{
              user_id,
            }}
          >
            <PerfilInfoInvestidorAnjoFromContext />
          </PerfilInvestidorFirebaseProvider>
        );
        break;
      default:
        // setConteudo(<PerfilNotFinded />); // TODO: Ver se será necessário deixar algo destacado nesse tipo de perfil
        break;
    }
  }, [user_id, tipo_perfil]);

  return <>{conteudo}</>;
}
