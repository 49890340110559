import {
  ReactNode,
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from "react";
import { Subscription } from "rxjs";

import {
  AutenticacaoService,
  UserAuthRealtime,
} from "../domain/usecases/autenticacaoService";
import {
  getUserInfoObserver,
  UserInfoRealtime,
} from "../domain/usecases/getUserInfoObserver";
import {
  getUserPhoneNumberObserver,
  UserPhoneNumberRealtime,
} from "../domain/usecases/getUserPhoneNumberObserver";
import {
  getUrlLinkedinObserver,
  UserUrlLinkedinRealtime,
} from "../domain/usecases/getUrlLinkedinObserver";
import { HandlerGetUsuarioFirebase } from "../gateway/HandlerGetUsuarioFirebase";
import {
  CurrentUserContext,
  defaultCurrentUserAuth,
  defaultUserPhoneNumber,
  defaultUserScyggz,
  defaultUserUrlLinkedin,
} from "./CurrentUserContext";
import { FirebaseContext } from "./FirebaseContext";

const autenticacaoService = AutenticacaoService.newAutenticacaoService();

export const CurrentUserProvider = ({ children }: { children: ReactNode }) => {
  const { firestore } = useContext(FirebaseContext);

  const [seeAsPublic, setSeeAsPublic] = useState<boolean>(false);
  const [currentUserAuth, setCurrentUserAuth] = useState<UserAuthRealtime>(
    defaultCurrentUserAuth
  );
  const [currentUserScyggz, setCurrentUserScyggz] =
    useState<UserInfoRealtime>(defaultUserScyggz);

  const [currentUserPhoneNumber, setCurrentUserPhoneNumber] =
    useState<UserPhoneNumberRealtime>(defaultUserPhoneNumber);

  const [currentUserUrlLinkedin, setCurrentUserUrlLinkedin] =
    useState<UserUrlLinkedinRealtime>(defaultUserUrlLinkedin);

  const subUserAuth = useRef<Subscription | undefined>();
  const subUserScyggz = useRef<Subscription | undefined>();
  const subUserPhoneNumber = useRef<Subscription | undefined>();
  const subUserUrlLinkedin = useRef<Subscription | undefined>();

  useEffect(() => {
    if (subUserAuth.current) subUserAuth.current.unsubscribe();

    subUserAuth.current = autenticacaoService.currentUserAuth$.subscribe(
      (currentUserAuthRealtime) => {
        setCurrentUserAuth(currentUserAuthRealtime);
      }
    );

    return () => {
      if (subUserAuth.current) subUserAuth.current.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (subUserScyggz.current) subUserScyggz.current.unsubscribe();

    if (currentUserAuth.user_auth) {
      subUserScyggz.current = getUserInfoObserver(
        currentUserAuth.user_auth.auth_id
      ).subscribe((userInfoRealtime) => {
        console.log("currentUserScyggz Realtime", userInfoRealtime);

        setCurrentUserScyggz(userInfoRealtime);
      });
    } else {
      switch (currentUserAuth.status) {
        case "carregado":
          setCurrentUserScyggz({
            status: "nao_encontrado",
            user: null,
          });
          break;
        case "carregando":
          setCurrentUserScyggz({
            status: "carregando",
            user: null,
          });
          break;
      }
    }

    return () => {
      if (subUserScyggz.current) subUserScyggz.current.unsubscribe();
    };
  }, [currentUserAuth]);

  useEffect(() => {
    if (subUserPhoneNumber.current) subUserPhoneNumber.current.unsubscribe();

    if (currentUserAuth.user_auth) {
      subUserPhoneNumber.current = getUserPhoneNumberObserver(
        currentUserAuth.user_auth.auth_id
      ).subscribe((userPhoneNumberRealtime) => {
        setCurrentUserPhoneNumber(userPhoneNumberRealtime);
      });
    } else {
      switch (currentUserAuth.status) {
        case "carregado":
          setCurrentUserPhoneNumber({
            status: "nao_encontrado",
            userPhoneNumber: null,
          });
          break;
        case "carregando":
          setCurrentUserPhoneNumber({
            status: "carregando",
            userPhoneNumber: null,
          });
          break;
      }
    }

    return () => {
      if (subUserPhoneNumber.current) subUserPhoneNumber.current.unsubscribe();
    };
  }, [currentUserAuth]);

  useEffect(() => {
    if (subUserUrlLinkedin.current) subUserUrlLinkedin.current.unsubscribe();

    if (currentUserAuth.user_auth) {
      subUserUrlLinkedin.current = getUrlLinkedinObserver(
        currentUserAuth.user_auth.auth_id
      ).subscribe((userUrlLinkedinRealtime) => {
        console.log("userUrlLinkedinRealtime", userUrlLinkedinRealtime);

        setCurrentUserUrlLinkedin(userUrlLinkedinRealtime);
      });
    } else {
      switch (currentUserAuth.status) {
        case "carregado":
          setCurrentUserUrlLinkedin({
            status: "nao_encontrado",
            urlLinkedin: null,
          });
          break;
        case "carregando":
          setCurrentUserUrlLinkedin({
            status: "carregando",
            urlLinkedin: null,
          });
          break;
      }
    }

    return () => {
      if (subUserUrlLinkedin.current) subUserUrlLinkedin.current.unsubscribe();
    };
  }, [currentUserAuth]);

  const refreshCurrentUserScyggz = useCallback(() => {
    setCurrentUserScyggz({
      status: "carregando",
      user: currentUserScyggz.user,
    });

    return new Promise<void>((resolve) => {
      if (currentUserScyggz.user) {
        const handlerGetUsuarioFirebase = new HandlerGetUsuarioFirebase(
          firestore
        );
        handlerGetUsuarioFirebase
          .getUsuario(currentUserScyggz.user)
          .then((usuarioResult) => {
            if (usuarioResult.ok) {
              setCurrentUserScyggz({
                status: "encontrado",
                user: usuarioResult.value,
              });
            } else {
              setCurrentUserScyggz({
                status: "nao_encontrado",
                user: null,
              });
            }

            resolve(undefined);
          });
      }
    });
  }, [currentUserScyggz, firestore]);

  return (
    <CurrentUserContext.Provider
      value={{
        currentUserAuth,
        currentUserScyggz,
        currentUserPhoneNumber,
        currentUserUrlLinkedin,
        refreshCurrentUserScyggz,
        setSeeAsPublic,
        seeAsPublic,
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
};
