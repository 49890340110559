import { ReactNode, useEffect, useState } from "react";

export const ParagrafoInfo = ({
  descricao,
  classes,
  linhas = 5,
  children,
}: {
  descricao: string;
  classes?: string;
  border?: string;
  linhas?: number;
  children?: ReactNode;
}) => {
  const [isShowText, setIsShowText] = useState<boolean>(true);
  const [isShowMore, setIsShowMore] = useState<boolean>(false);
  const [paragrafoToString, setParagrafoToString] = useState("");
  const [paragrafo, setParagrafo] = useState("");

  useEffect(() => {
    setParagrafoToString(descricao);

    const qtdCaracteres = paragrafoToString.length;
    const slice = paragrafoToString.slice(0, 870);
    setIsShowMore(qtdCaracteres > 870);

    isShowMore ? setParagrafo(slice) : setParagrafo(paragrafoToString);

    !isShowText ? setParagrafo(paragrafoToString) : setParagrafo(slice);
  }, [descricao, paragrafo, isShowText, paragrafoToString, isShowMore]);

  return (
    <div className={classes}>
      {children}
      <p
        className="text-break m-0"
        style={{
          textAlign: "justify",
          lineHeight: "24px",
          WebkitLineClamp: `${linhas}`,
          fontSize: "14px",
        }}
      >
        {paragrafo}
        <span
          className="text-primary ms-2"
          style={{ cursor: "pointer" }}
          onClick={() => setIsShowText(!isShowText)}
        >
          {isShowMore && <>{isShowText ? "... ver mais" : "ver menos"}</>}
        </span>
      </p>
    </div>
  );
};
