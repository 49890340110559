import { TypePessoaRelevante } from "../../../../../domain/usecases/interfaces/HandlerGetPessoasRelevantes";
import { CardUser } from "./CardUser";

export const InfoCardUsuarios = ({
  title,
  usuarios,
  className = "",
}: {
  title: string;
  usuarios: TypePessoaRelevante[];
  className?: string;
}) => {
  return (
    <section className={className}>
      <h4 className="mb-3">{title}</h4>
      <div className="d-flex ms-4 gap-3 flex-wrap">
        {usuarios.map((usuario) => (
          <a
            href={usuario.url_link}
            key={usuario.url_link}
            className="text-reset"
          >
            <CardUser
              src={
                usuario.image_path
                  ? {
                      path: usuario.image_path,
                    }
                  : undefined
              }
              nome={usuario.nome}
              headLine={usuario.headline}
              cidade={usuario.cidade}
              perfis={usuario.perfis}
            />
          </a>
        ))}
      </div>
    </section>
  );
};
