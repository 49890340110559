import { PathRouteProps } from "react-router-dom";

import { UserStatus } from "../entities/userStatusHandler";
import { rotasVisitante } from "./rotasVisitante";

export function getRotasVisitante(): (PathRouteProps & {
  userStatus: UserStatus[];
  path: string;
  element: JSX.Element;
  showHeader: boolean;
  showFooter: boolean;
})[] {
  return rotasVisitante.map((rotaVisitante) => {
    return getRotaVisitante(rotaVisitante);
  });
}

function getRotaVisitante({
  path,
  element,
  showHeader,
  showFooter,
}: {
  path: string;
  element: JSX.Element;
  showHeader: boolean;
  showFooter: boolean;
}): PathRouteProps & {
  userStatus: UserStatus[];
  path: string;
  element: JSX.Element;
  showHeader: boolean;
  showFooter: boolean;
} {
  return {
    userStatus: ["visitante"] as UserStatus[],
    path,
    element,
    showHeader,
    showFooter,
  };
}
