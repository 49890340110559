import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CacheHub,
  HandlerCacheHub,
} from "../../../../../domain/usecases/interfaces/HandlerCacheHub";

import { HandlerGetURLFromPath } from "../../../../../domain/usecases/interfaces/HandlerGetURLFromPath";
import { HandlerSaveLogoHub } from "../../../../../domain/usecases/interfaces/HandlerSaveLogoHub";
import { CurrentUserContext } from "../../../../../globalStore/CurrentUserContext";
import { ContainerResponsive } from "../../../../generalComponents/ContainerResponsive";
import { ConteudoLogoHub } from "./ConteudoLogoHub";

export const PainelLogoHub = ({
  handlerSaveLogoHub,
  handlerGetURLFromPath,
  handlerCacheHub,
}: {
  handlerSaveLogoHub: HandlerSaveLogoHub;
  handlerGetURLFromPath: HandlerGetURLFromPath;
  handlerCacheHub: HandlerCacheHub;
}) => {
  const navigate = useNavigate();
  const { currentUserScyggz } = useContext(CurrentUserContext);

  const [cacheHub, setCacheHub] = useState<CacheHub>({});

  useEffect(() => {
    handlerCacheHub.get().then((cacheHubResult) => {
      if (cacheHubResult.ok) {
        setCacheHub(cacheHubResult.value);
      }
    });
  }, [handlerCacheHub]);

  const paginacao = {
    start: 6,
    end: 6,
  };

  return (
    <section data-testid="painel-cadastro-logo-hub" style={{ height: "100%" }}>
      <ContainerResponsive
        backUrlMobile="/cadastro/hub/5"
        paginacao={paginacao}
        textoMarcaDagua="Hub"
        showLogo={true}
      >
        <ConteudoLogoHub
          hub={{
            id: cacheHub.id ?? "",
            path_logo: cacheHub.path_logo,
            nome: cacheHub.nome ?? "",
          }}
          user={{
            nome: currentUserScyggz.user?.nome ?? "",
          }}
          handlerSaveLogoHub={handlerSaveLogoHub}
          handlerGetURLFromPath={handlerGetURLFromPath}
          onNext={() => {
            navigate("/");
            handlerCacheHub.clear();
          }}
          handlerCacheHub={handlerCacheHub}
        />
      </ContainerResponsive>
    </section>
  );
};
