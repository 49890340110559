import { doc, setDoc, Firestore, writeBatch } from "firebase/firestore";

import { TipoPerfil, UsuarioScyggz } from "../domain/entities/usuarioScyggz";
import {
  HandlerUpdateUsuarioScyggz,
  InputHandlerUpdateUsuarioScyggz,
} from "../domain/usecases/interfaces/handlerUpdateUsuarioScyggz";
import { Result } from "../typings";
import { UsuarioScyggzValidator } from "../domain/entities/UsuarioScyggzValidator";
import { HandlerSetUsersEnabled } from "../domain/usecases/interfaces/HandlerSetUsersEnabled";
import { OutputInvestidorAnjo } from "../domain/entities/InvestidorAnjo";
import { CientistaOutputEntity } from "../domain/entities/Cientista";
import { MentorOutput } from "../domain/entities/Mentor";

export class HandlerUpdateUsuarioScyggzFirebase
  implements HandlerUpdateUsuarioScyggz, HandlerSetUsersEnabled
{
  constructor(private firestore: Firestore) {}

  setUsuariosEnabled(
    users: Pick<UsuarioScyggz, "auth_user_id" | "perfis">[]
  ): Promise<Result<void>> {
    const batch = writeBatch(this.firestore);

    users.forEach((user) => {
      Object.entries(user.perfis ?? {}).forEach(([id_perfil, enabled]) => {
        if (!enabled) {
          const perfilHabilitado: Pick<
            OutputInvestidorAnjo | CientistaOutputEntity | MentorOutput,
            "habilitado"
          > = {
            habilitado: true,
          };

          if (!user.perfis) user.perfis = {};
          user.perfis[id_perfil as TipoPerfil] = true;

          batch.update(
            doc(
              this.firestore,
              "usuarios",
              user.auth_user_id,
              "perfis",
              id_perfil
            ),
            perfilHabilitado
          );
        }
      });

      if (user.perfis) {
        const userHabilitado: Pick<UsuarioScyggz, "perfis"> = {
          perfis: user.perfis,
        };

        batch.update(
          doc(this.firestore, "usuarios", user.auth_user_id),
          userHabilitado
        );
      }
    });

    return new Promise((resolve) => {
      batch
        .commit()
        .then(() =>
          resolve({
            ok: true,
            value: undefined,
          })
        )
        .catch((error) =>
          resolve({
            ok: false,
            error,
          })
        );
    });
  }

  async updateUsuarioScyggz(
    user: { auth_user_id: string },
    input: InputHandlerUpdateUsuarioScyggz
  ): Promise<
    Result<
      InputHandlerUpdateUsuarioScyggz,
      Error | Partial<Record<keyof UsuarioScyggz, Error>>
    >
  > {
    const userUpdateResult = UsuarioScyggzValidator.validateUpdate(input);

    if (!userUpdateResult.ok) return userUpdateResult;

    const docUser = doc(this.firestore, "usuarios", user.auth_user_id);

    return new Promise<Result<InputHandlerUpdateUsuarioScyggz>>((resolve) => {
      setDoc(docUser, prune(input), { merge: true })
        .then(() =>
          resolve({
            ok: true,
            value: input,
          })
        )
        .catch((error) =>
          resolve({
            ok: false,
            error,
          })
        );
    });
  }
}

function prune({
  auth_id_conexoes,
  avatar_path,
  cidade,
  headline,
  nome,
  telefone,
  url_lattes,
  url_linkedin,
}: InputHandlerUpdateUsuarioScyggz): InputHandlerUpdateUsuarioScyggz {
  return {
    auth_id_conexoes,
    avatar_path,
    cidade,
    headline,
    nome,
    telefone,
    url_lattes,
    url_linkedin,
  };
}
