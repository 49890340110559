/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Spinner } from "react-bootstrap";

import { ButtonDarkGreen } from "../../../../generalComponents/buttonDarkGreen/ButtonDarkGreen";
import { AreaEspecializacaoOutput } from "../../../../../domain/entities/areaEspecializacaoInvestidorAnjo";
import { ResizeContext } from "../../../../../hooks/useResize";
import { InputPremiacoes } from "../../../../generalComponents/InputPremiacoes/InputPremiacoes";
import {
  Premiacao,
  PremiacaoOutput,
} from "../../../../../domain/entities/premiacao";
import {
  CacheInvestidor,
  HandlerCacheInvestidor,
} from "../../../../../domain/usecases/interfaces/HandlerCacheInvestidor";
import { ButtonLightGreen } from "../../../../generalComponents/buttonLightGreen/ButtonLightGreen";
import { CurrentUserContext } from "../../../../../globalStore/CurrentUserContext";
import { InputAreasEspecializacao } from "../../../../generalComponents/InputAreasEspecializacao";
import { InvestidorAnjoValidator } from "../../../../../domain/entities/InvestidorAnjoValidator";
import { InputAssociacoesAnjo } from "../../../../generalComponents/InputAssociacoesAnjo";
import { SelectAssociacoesAnjo } from "../../../../../typings";

export const ConteudoAssociacaoAnjo = ({
  onNext,
  onBack,
  handlerCacheInvestidor,
}: {
  onNext: () => void;
  onBack: () => void;
  handlerCacheInvestidor: HandlerCacheInvestidor;
}) => {
  const [cacheInvestidor, setCacheInvestidor] = useState<CacheInvestidor>({});
  const [isEditingPremiacoes, setIsEditingPremiacoes] =
    useState<boolean>(false);

  useEffect(() => {
    handlerCacheInvestidor.get().then((cacheInvestidor) => {
      if (cacheInvestidor.ok) {
        setCacheInvestidor(cacheInvestidor.value);
      }
    });
  }, [handlerCacheInvestidor]);

  const { responsive } = useContext(ResizeContext);

  const { currentUserScyggz } = useContext(CurrentUserContext);

  const [parteDoNome, setParteDoNome] = useState("");

  useEffect(() => {
    if (currentUserScyggz.user) {
      const parteDoNome: string =
        currentUserScyggz.user.nome.split(" ").at(0) ??
        currentUserScyggz.user.nome;

      setParteDoNome(parteDoNome);
    }
  }, [currentUserScyggz]);

  return (
    <Formik<InvestidorAnjoForm>
      initialValues={{
        associacoes_anjo: cacheInvestidor.associacoes_anjo ?? [],
        areas_de_especializacao: cacheInvestidor.areas_de_especializacao ?? [],
        premiacoes_e_certificados:
          cacheInvestidor.premiacoes_e_certificados ?? [],
      }}
      validationSchema={validator}
      validateOnMount={true}
      validateOnChange={true}
      validateOnBlur={true}
      enableReinitialize={true}
      onSubmit={async (values) => {
        await handlerCacheInvestidor.save({
          areas_de_especializacao: values.areas_de_especializacao,
          associacoes_anjo: values.associacoes_anjo,
          premiacoes_e_certificados: values.premiacoes_e_certificados,
        });

        onNext();
      }}
    >
      {({
        isValid,
        handleSubmit,
        setFieldValue,
        submitForm,
        values,
        isSubmitting,
      }) => (
        <div
          style={!responsive.sm ? { minWidth: "500px", padding: "0 30px" } : {}}
        >
          <form onSubmit={handleSubmit}>
            <h4 className="card-title text-center mb-4 fw-bold">
              <span>{parteDoNome && `${parteDoNome},`}</span>
              <span className="h5 fw-bold">
                por favor fale mais das suas atividades de Investimento Anjo.
              </span>
            </h4>
            <div style={!responsive.sm ? { padding: "0 100px" } : {}}>
              <InputAssociacoesAnjo
                label="Faço parte de Associação Anjo"
                value={values.associacoes_anjo ?? []}
                onChange={(associacoes_anjo) => {
                  setFieldValue("associacoes_anjo", associacoes_anjo);

                  handlerCacheInvestidor.save({
                    associacoes_anjo: values.associacoes_anjo,
                  });
                }}
                validate={() => undefined}
                className="mb-3"
              />

              <InputAreasEspecializacao
                label="Suas áreas de especialização*"
                value={values.areas_de_especializacao}
                onChange={(areasEspecializacao) => {
                  setFieldValue("areas_de_especializacao", areasEspecializacao);
                }}
                validate={InvestidorAnjoValidator.isInvalidAreasEspecializacao}
                className="mb-3"
              />

              <InputPremiacoes
                value={values.premiacoes_e_certificados ?? []}
                onChange={(premiacoes) => {
                  setFieldValue("premiacoes_e_certificados", premiacoes);

                  handlerCacheInvestidor.save({
                    premiacoes_e_certificados: values.premiacoes_e_certificados,
                  });
                }}
                label="Liste suas premiações/certificações"
                className="col-12"
                isEditing={setIsEditingPremiacoes}
              />

              <div className=" mt-5 text-end" data-testid="test">
                {!responsive.sm && (
                  <ButtonLightGreen
                    className="me-3"
                    onClick={() => {
                      handlerCacheInvestidor.save(values);
                      onBack();
                    }}
                  >
                    Voltar
                  </ButtonLightGreen>
                )}
                <ButtonDarkGreen
                  disabled={!isValid || isEditingPremiacoes}
                  onClick={submitForm}
                  className={`pe-5 ${responsive.sm ? "w-100" : ""}`}
                >
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className={"me-2" + (isSubmitting ? "" : " invisible")}
                  />
                  Avançar
                </ButtonDarkGreen>
              </div>
            </div>
          </form>
        </div>
      )}
    </Formik>
  );
};

type InvestidorAnjoForm = {
  areas_de_especializacao: AreaEspecializacaoOutput[];
} & Partial<{
  associacoes_anjo: SelectAssociacoesAnjo[];
  premiacoes_e_certificados: PremiacaoOutput[];
}>;

const validator = Yup.object().shape({
  areas_de_especializacao: Yup.array()
    .required("Precisa especificar área de especialização")
    .min(1, "Precisa especificar área de especialização"),
  premiacoes_e_certificados: Yup.array().of(
    Yup.object().shape({
      nome: Yup.string()
        .required("O nome é obrigatório")
        .min(
          Premiacao.NOME_MINIMO_LENGTH,
          "Mínimo " + Premiacao.NOME_MINIMO_LENGTH + " caracteres"
        )
        .max(
          Premiacao.NOME_MAXIMO_LENGTH,
          "Máximo " + Premiacao.NOME_MAXIMO_LENGTH + " caracteres"
        ),
      descricao: Yup.string().max(
        Premiacao.DESCRICAO_MAXIMO_LENGTH,
        "Máximo de " + Premiacao.DESCRICAO_MAXIMO_LENGTH + " caracteres."
      ),
    })
  ),
});
