import Select, { StylesConfig } from "react-select";
import { useContext, useEffect, useState } from "react";
import makeAnimated from "react-select/animated";

import { ResizeContext } from "../../hooks/useResize";
import { HandlersContext } from "../../globalStore/HandlersProviders/HandlersContext";
import { AreaConhecimento } from "../../domain/entities/areaConhecimento";

const animatedComponents = makeAnimated();

export function InputAreasConhecimento({
  label,
  error,
  value,
  onChange,
  validate,
  className = "",
}: {
  label: string;
  error?: string;
  value: AreaConhecimento[];
  onChange: (areasConhecimento: AreaConhecimento[]) => void;
  validate: (areasConhecimento: AreaConhecimento[]) => Error | undefined;
  className?: string;
}) {
  const { handlerGetAreaConhecimento } = useContext(HandlersContext);
  const { responsive } = useContext(ResizeContext);

  const [allAreaConhecimento, setAllAreaConhecimento] = useState<
    AreaConhecimento[]
  >([]);
  const [isAllAreaConhecimentoLoading, setIsAllAreaConhecimentoLoading] =
    useState<boolean>(false);
  const [errorToShow, setErrorToShow] = useState<string>();
  const [isChanged, setIsChanged] = useState<boolean>(false);

  useEffect(() => {
    if (error) setErrorToShow(error);
  }, [error]);

  useEffect(() => {
    const errorValidate = validate(value);

    if (errorValidate) {
      setErrorToShow(errorValidate.message);
    } else {
      setErrorToShow(undefined);
    }
  }, [validate, value]);

  useEffect(() => {
    setIsAllAreaConhecimentoLoading(true);
    if (handlerGetAreaConhecimento)
      handlerGetAreaConhecimento
        .getAllAreaConhecimento()
        .then((handlerGetAreaConhecimentoResult) => {
          if (handlerGetAreaConhecimentoResult.ok)
            setAllAreaConhecimento(handlerGetAreaConhecimentoResult.value);

          setIsAllAreaConhecimentoLoading(false);
        });
  }, [handlerGetAreaConhecimento]);

  const customStylesSelect: StylesConfig<AreaConhecimento, true> = {
    control: () => ({
      border: "none",
      borderBottom: "3px #666600 solid",
      display: "flex",
      background: " #fff",
      borderBottomLeftRadius: "8px",
      textAlign: "left",
    }),
  };

  return (
    <div className={className}>
      <label
        className="form-label fw-bolder m-0"
        htmlFor="selAreasConhecimento"
      >
        {label}
      </label>
      <Select<AreaConhecimento, true>
        isLoading={isAllAreaConhecimentoLoading}
        options={allAreaConhecimento}
        styles={responsive.sm ? customStylesSelect : undefined}
        getOptionLabel={(o) => o.nome}
        getOptionValue={(o) => o.id}
        components={animatedComponents}
        isMulti
        value={value}
        placeholder=""
        onChange={(mecanismosDeSolucao) => {
          onChange([...mecanismosDeSolucao]);
          setIsChanged(true);
        }}
        onBlur={() => setIsChanged(true)}
        className={errorToShow && isChanged ? "is-invalid " : ""}
        noOptionsMessage={() => "sem opção"}
        inputId="selAreasConhecimento"
      />

      <div className="invalid-feedback flex-fill">{errorToShow}</div>
    </div>
  );
}
