import Select, { StylesConfig } from "react-select";
import { useContext, useEffect, useState } from "react";
import makeAnimated from "react-select/animated";

import { AreaEspecializacao } from "../../domain/entities/areaEspecializacaoInvestidorAnjo";
import { ResizeContext } from "../../hooks/useResize";
import { HandlersContext } from "../../globalStore/HandlersProviders/HandlersContext";

const animatedComponents = makeAnimated();

export function InputAreasEspecializacao({
  label,
  value,
  onChange,
  validate,
  className,
}: {
  label: string;
  value: AreaEspecializacao[];
  onChange: (areasEspecializacao: AreaEspecializacao[]) => void;
  validate: (areasEspecializacao: AreaEspecializacao[]) => Error | undefined;
  className?: string;
}) {
  const { handlerGetAreaEspecializacao } = useContext(HandlersContext);
  const { responsive } = useContext(ResizeContext);

  const [errorToShow, setErrorToShow] = useState<string>();
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [options, setOptions] = useState<AreaEspecializacao[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    if (handlerGetAreaEspecializacao) {
      handlerGetAreaEspecializacao
        .getAllAreaEspecializacao()
        .then((getAllAreaEspecializacaoResult) => {
          if (getAllAreaEspecializacaoResult.ok)
            setOptions(getAllAreaEspecializacaoResult.value);

          setIsLoading(false);
        });
    }
  }, [handlerGetAreaEspecializacao]);

  useEffect(() => {
    const validateErro = validate(value);

    if (validateErro) {
      setErrorToShow(validateErro.message);
    } else {
      setErrorToShow(undefined);
    }
  }, [validate, value]);

  const customStylesSelect: StylesConfig<AreaEspecializacao, true> = {
    control: () => ({
      border: "none",
      borderBottom: "3px #666600 solid",
      display: "flex",
      background: " #fff",
      borderBottomLeftRadius: "8px",
      textAlign: "left",
    }),
  };

  return (
    <div className={className}>
      <label
        htmlFor="selAreasEspecializacao"
        className="form-label fw-bolder m-0 d-block"
      >
        {label}
      </label>
      <Select<AreaEspecializacao, true>
        isLoading={isLoading}
        options={options}
        styles={responsive.sm ? customStylesSelect : undefined}
        getOptionLabel={(o) => o.nome}
        getOptionValue={(o) => o.id}
        components={animatedComponents}
        isMulti
        value={value}
        placeholder=""
        onChange={(areas_de_especializacao) => {
          onChange([...areas_de_especializacao]);
          setIsChanged(true);
        }}
        onBlur={() => setIsChanged(true)}
        className={errorToShow && isChanged ? "is-invalid " : ""}
        noOptionsMessage={() => "sem opção"}
        inputId="selAreasEspecializacao"
      />

      <div className="invalid-feedback flex-fill">{errorToShow}</div>
    </div>
  );
}
