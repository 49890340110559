import { Firestore, getDoc, doc, Timestamp } from "firebase/firestore";

import { Result } from "../../typings";
import { HandlerGetCientista } from "../../domain/usecases/interfaces/getPerfil/handlerGetCientista";
import { CientistaOutputEntity } from "../../domain/entities/Cientista";
import { fieldToDate } from "../utils/fieldToDate";

export class HandlerGetCientistaFirebase implements HandlerGetCientista {
  constructor(private firestore: Firestore) {}

  async get(auth_id: string): Promise<Result<CientistaOutputEntity>> {
    return new Promise((resolve) => {
      const docCientista = doc(
        this.firestore,
        "usuarios",
        auth_id,
        "perfis/cientista"
      );
      getDoc(docCientista)
        .then((docCientistaSnapshot) => {
          if (docCientistaSnapshot.exists()) {
            const cientista = this.convert(
              docCientistaSnapshot.data(),
              auth_id
            );

            resolve({
              ok: true,
              value: cientista,
            });
            return;
          }

          resolve({
            ok: false,
            error: new Error("Usuário não encontrado"),
          });
        })
        .catch((error) =>
          resolve({
            ok: false,
            error,
          })
        );
    });
  }

  private convert(
    input: Partial<Omit<CientistaOutputEntity, "created_in">> &
      Partial<{ created_in: Timestamp }>,
    id: string
  ): CientistaOutputEntity {
    return {
      created_in: fieldToDate(input.created_in),
      user_id: id,
      nome: input.nome ?? "",
      habilitado: !!input.habilitado,
      sobre: {
        conteudo: input.sobre?.conteudo ?? "",
      },
      areas_de_conhecimento: input.areas_de_conhecimento ?? [],
      unidades: input.unidades ?? [],

      premiacoes: input.premiacoes,
      grupos_de_pesquisa: input.grupos_de_pesquisa,
      tecnologias: input.tecnologias,
    };
  }
}
