import { ConteudoHomeExternal } from "./ConteudoHomeExternal";
import { ContainerResponsive } from "../../generalComponents/ContainerResponsive";

export function PainelHomeExternal() {
  return (
    <section className="h-100">
      <ContainerResponsive
        showLogo={false}
        children={<ConteudoHomeExternal />}
      />
    </section>
  );
}
