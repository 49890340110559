import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";

import { ItemTecnologia } from "./ItemTecnologia";
import { InputEditTecnologia } from "./InputEditTecnologia";
import { InputAddTecnologia } from "./InputAddTecnologia";
import { TecnologiaDoCientista } from "../../../domain/entities/tecnologiaDoCientista";

export function InputTecnologias({
  label,
  className,
  tecnologias = [],
  onListChange,
  showTip = true,
  isEditing,
}: {
  label: string;
  className?: string;
  tecnologias: TecnologiaDoCientista[];
  onListChange: (tecnologias: TecnologiaDoCientista[]) => void;
  showTip?: boolean;
  isEditing: (isEditing: boolean) => void;
}) {
  const [status, setStatus] = useState<
    | undefined
    | { type: "add" }
    | {
        type: "edit";
        tecnologiaToEdit: {
          tecnologia: TecnologiaDoCientista;
          index: number;
        };
      }
  >();

  useEffect(() => {
    if (status == undefined) isEditing(false);
  }, [isEditing, status]);

  return (
    <div className={className}>
      <label
        className="form-label fw-bolder m-0 d-block"
        htmlFor="selAreasConhecimento"
      >
        {label}
      </label>

      {status == undefined ? (
        <>
          {showTip && (
            <p>
              <small>
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  className="opacity-25 me-2"
                />
                Você poderá adicionar mais tecnologias após a criação da sua
                conta
              </small>
            </p>
          )}

          {tecnologias.map((tecnologia, i) => (
            <ItemTecnologia
              key={i}
              tecnologia={tecnologia}
              onClickDelete={() => {
                tecnologias.splice(i, 1);
                onListChange([...tecnologias]);
              }}
              onClickEdit={() => {
                setStatus({
                  type: "edit",
                  tecnologiaToEdit: {
                    tecnologia,
                    index: i,
                  },
                });
              }}
            />
          ))}

          <button
            type="button"
            className="btn btn-outline-success fw-bolder"
            onClick={() =>
              setStatus({
                type: "add",
              })
            }
          >
            <FontAwesomeIcon icon={faPlus} className="me-1" /> tecnologia
          </button>
        </>
      ) : (
        <>
          {status.type == "edit" ? (
            <InputEditTecnologia
              onCancel={() => {
                setStatus(undefined);
              }}
              tecnologiaToEdit={status.tecnologiaToEdit.tecnologia}
              onSave={(tecnologia) => {
                tecnologias.splice(
                  status.tecnologiaToEdit.index,
                  1,
                  tecnologia
                );
                onListChange([...tecnologias]);
                setStatus(undefined);
              }}
              onChange={() => isEditing(true)}
            />
          ) : (
            <>
              {status.type == "add" && (
                <>
                  <InputAddTecnologia
                    onAdd={(tecnologia) => {
                      onListChange([...tecnologias, tecnologia]);
                      setStatus(undefined);
                    }}
                    onCancel={() => {
                      setStatus(undefined);
                    }}
                    onChange={() => isEditing(true)}
                  />
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}
