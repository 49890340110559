import { Result } from "../../typings";

export class ProgramaConexaoComment {
  static readonly MIN_SIZE_MESSAGE = 3;
  static readonly MIN_SIZE_MESSAGE_ERROR = "Muito curto";
  static readonly MAX_SIZE_MESSAGE = 1000;
  static readonly MAX_SIZE_MESSAGE_ERROR = "Muito longo";

  readonly id!: string;
  readonly comments_counter?: number = 0;
  readonly likes?: string[];

  private constructor(
    readonly message: string,
    readonly autor: AutorComment,
    readonly created_at: Date,
    readonly id_programa: string,
    readonly parent_comment: string | null
  ) {}

  public static create(
    input: Omit<ProgramaConexaoComment, "id">
  ): Result<
    ProgramaConexaoComment,
    Partial<Record<keyof ProgramaConexaoComment, Error>>
  > {
    const isInvalid = this.isInvalidComment(input);

    if (isInvalid) {
      return {
        ok: false,
        error: isInvalid,
      };
    }

    const programa = new ProgramaConexaoComment(
      input.message,
      input.autor,
      input.created_at,
      input.id_programa,
      input.parent_comment
    );

    return {
      ok: true,
      value: programa,
    };
  }

  public static isInvalidComment(
    programaConexao: Partial<ProgramaConexaoComment>
  ): Partial<Record<keyof ProgramaConexaoComment, Error>> | undefined {
    const errors: Partial<Record<keyof ProgramaConexaoComment, Error>> = {
      message:
        programaConexao.message != undefined
          ? this.isInvalidMessage(programaConexao.message)
          : undefined,
      autor:
        programaConexao.autor != undefined
          ? this.isInvalidId(programaConexao.autor.id)
          : undefined,
      created_at:
        programaConexao.created_at != undefined
          ? this.isInvalidDateCreated(programaConexao.created_at)
          : undefined,
    };

    const hasError: boolean = Object.values(errors).some((error) => !!error);

    if (hasError) return errors;
  }

  public static isInvalidId(id: string): Error | undefined {
    if (id.length < 1) {
      return new Error("id obrigatório");
    }
  }

  public static isInvalidDateCreated(created_at?: Date): Error | undefined {
    const today: Date = new Date();

    if (!created_at) {
      return new Error("Data inválida");
    }

    if (today < created_at) {
      return new Error("Deve ser data passada");
    }
  }

  public static isInvalidMessage(message: string): Error | undefined {
    const length = message.trim().length;

    if (length < this.MIN_SIZE_MESSAGE) {
      return new Error(this.MIN_SIZE_MESSAGE_ERROR);
    }

    if (length > this.MAX_SIZE_MESSAGE) {
      return new Error(this.MAX_SIZE_MESSAGE_ERROR);
    }
  }
}

export type InvitationRule = "from_both" | "from_a" | "from_b";

type AutorComment = {
  id: string;
  nome: string;
  avatar_path?: string;
};
