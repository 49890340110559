import { useNavigate } from "react-router";

import { ConteudoCadastro } from "../../components/ConteudoCadastro";
import { ContainerResponsive } from "../../../../generalComponents/ContainerResponsive";

export function PainelCadastroAuthVentureCapital() {
  const navigate = useNavigate();

  const paginacaoElegibilidadeVentureCapital = {
    start: 2,
    end: 7,
  };

  return (
    <section
      data-testid="painel-cadastro-auth-ventureCapital"
      className="h-100"
    >
      <ContainerResponsive
        backUrlMobile="/cadastro/venture_capital/1"
        paginacao={paginacaoElegibilidadeVentureCapital}
        showLogo={true}
      >
        <ConteudoCadastro
          onRedirect={() => {
            navigate("/cadastro/venture_capital/3");
          }}
          onCadastro={() => {
            navigate("/cadastro/venture_capital/3");
          }}
        />
      </ContainerResponsive>
    </section>
  );
}
