import { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { CurrentUserContext } from "../../../../globalStore/CurrentUserContext";
import { ConteudoEscolhaPerfil } from "./ConteudoEscolhaPerfil";
import { ContainerResponsive } from "../../../generalComponents/ContainerResponsive";

export function PainelEscolhaPerfil() {
  const navigate = useNavigate();
  const {
    currentUserScyggz: { user },
  } = useContext(CurrentUserContext);

  useEffect(() => {
    if (user && Object.keys(user?.perfis ?? {}).length > 0) {
      navigate("/");
    }
  }, [navigate, user]);

  return (
    <section className="h-100">
      <ContainerResponsive showLogo={false}>
        <>
          <ConteudoEscolhaPerfil />

          <div className="text-center">
            Já faz parte do SCIENCIN?{" "}
            <Link to="/login">
              <span
                style={{ color: "#002776" }}
                className="fw-bold text-decoration-underline"
              >
                Login
              </span>
            </Link>
          </div>
        </>
      </ContainerResponsive>
    </section>
  );
}
