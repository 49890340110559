import { ReactNode } from "react";

import { HandlerQueryLocationOSM } from "../../gateway/handlerQueryLocationOSM";
import { HandlersLocationContext } from "./HandlersLocationContext";

export function HandlersOSMProvider({ children }: { children: ReactNode }) {
  return (
    <HandlersLocationContext.Provider
      value={{
        handlerQueryLocation: new HandlerQueryLocationOSM(),
        handlerQueryLocationCidade: new HandlerQueryLocationOSM(["cidade"]),
      }}
      children={children}
    />
  );
}
