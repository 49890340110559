import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

import { TipoPerfil } from "../../../../domain/entities/usuarioScyggz";
import { ImgFromPathNew } from "../../../generalComponents/ImgFromPathNew";

export function CardPerfil({
  user_id,
  nome,
  tiposPerfil,
  path_avatar,
}: {
  user_id: string;
  nome: string;
  tiposPerfil: TipoPerfil[];
  path_avatar: string | undefined;
}) {
  const urlPerfil: string = "/user/" + user_id + "/" + (tiposPerfil[0] ?? "");

  return (
    <Link to={urlPerfil}>
      <div className="card text-center">
        <div className="card-body">
          <div className="p-5">
            <ImgFromPathNew
              path={path_avatar}
              alt={nome}
              className="rounded-circle border border-2 border-white bg-white"
              elementErro={
                <div className="rounded-circle bg-secondary-subtle text-white d-flex justify-content-center align-items-center opacity-75 w-100 h-100">
                  <FontAwesomeIcon icon={faUser} className="w-50 h-50" />
                </div>
              }
            />
          </div>

          <h5 className="text-reset fw-bolder">{nome}</h5>

          <div
            className="d-block fw-bolder color-spinoff"
            style={{
              fontSize: "14px",
              textTransform: "capitalize",
            }}
          >
            {tiposPerfil.map((tipoPerfil, i) => (
              <span key={i}>
                {i > 0 && " | "}
                {tipoPerfil}
              </span>
            ))}
          </div>
        </div>
      </div>
    </Link>
  );
}
