import { Link } from "react-router-dom";

import { CidadeOutput } from "../../../../domain/entities/cidade";
import { TipoPerfil } from "../../../../domain/entities/usuarioScyggz";
import { ImgFromPath } from "../../../generalComponents/ImgFromPath";
import Avatar from "../../../../assets/images/avatarLight.jpeg";

export function BannerPessoasRelevantesItem({
  item: { url_link, image_path, nome, headline, cidade, perfis },
  className,
}: {
  item: TypeBannerPessoasRelevantesItem;
  className?: string;
}) {
  const conteudo = (
    <div className="d-flex">
      <div className="col-3 col-sm-2 p-0">
        <div className="p-0 ratio ratio-1x1">
          {image_path ? (
            <ImgFromPath
              path={image_path}
              className="rounded-circle border-white object-fit-cover"
              alt={nome}
            />
          ) : (
            <img
              className="rounded-circle border-white object-fit-cover"
              src={Avatar}
            />
          )}
        </div>
      </div>

      <div className="col-9 p-0 ps-2 d-flex flex-column justify-content-center text-dark">
        <h6 className="fw-bolder m-0">{nome}</h6>
        <span style={{ fontSize: "12px" }}>{headline}</span>
        <span style={{ fontSize: "10px" }}>
          {cidade.cidade}, {cidade.estado} - {cidade.paisCode}
        </span>
        <div>
          <span
            style={{
              fontSize: "10px",
              color: "#525200",
              textTransform: "capitalize",
              fontWeight: "bold",
            }}
          >
            {perfis.join(" | ")}
          </span>
        </div>
      </div>

      {/* <ButtonAceiteConexao
          processandoAceite={processandoAceite}
          onAceite={() => {
            setProcessandoAceite(true);
            handlerUpdateAceiteConexao
              .aceitarConexao(conexao, true)
              .then((aceiteResult) => {
                if (aceiteResult.ok && onAceiteOk) {
                  onAceiteOk();
                }
                setProcessandoAceite(false);
              });
          }}
        /> */}
    </div>
  );

  return (
    <>
      {url_link ? (
        <Link to={url_link} className={className ?? ""}>
          {conteudo}
        </Link>
      ) : (
        <div className={className ?? ""}>{conteudo}</div>
      )}
    </>
  );
}

export type TypeBannerPessoasRelevantesItem = {
  url_link: string;
  image_path: string | undefined;
  nome: string;
  headline: string | undefined;
  cidade: CidadeOutput;
  perfis: TipoPerfil[];
  onConectar?: () => void;
};
