import { Result } from "../../typings";

export class Premiacao {
  static readonly NOME_MINIMO_LENGTH = 3;
  static readonly NOME_MAXIMO_LENGTH = 70;
  static readonly DESCRICAO_MAXIMO_LENGTH = 300;

  private constructor(public nome: string, public descricao: string) {}

  public static create(
    premiacao: PremiacaoInput
  ): Result<Premiacao, { [P in keyof Partial<PremiacaoInput>]: Error }> {
    return this.validate(premiacao);
  }

  public static validate(
    premiacao: PremiacaoInput
  ): Result<PremiacaoInput, { [P in keyof Partial<PremiacaoInput>]: Error }> {
    let erroNome = "";
    const premiacaoNome = premiacao.nome.trim();
    if (premiacaoNome.length == 0) {
      erroNome = "Nome obrigatório";
    } else if (premiacaoNome.length < Premiacao.NOME_MINIMO_LENGTH) {
      erroNome =
        "Tamanho mínimo " + Premiacao.NOME_MINIMO_LENGTH + " caracteres";
    } else if (premiacaoNome.length > Premiacao.NOME_MAXIMO_LENGTH) {
      erroNome =
        "Tamanho máximo " + Premiacao.NOME_MAXIMO_LENGTH + " caracteres";
    }

    let erroDescricao = "";
    const premiacaoDescricao = premiacao.descricao.trim();
    if (premiacaoDescricao.length > Premiacao.DESCRICAO_MAXIMO_LENGTH) {
      erroDescricao =
        "Tamanho máximo " + Premiacao.DESCRICAO_MAXIMO_LENGTH + " caracteres";
    }

    if (erroNome || erroDescricao) {
      return {
        ok: false,
        error: {
          nome: erroNome ? new Error(erroNome) : undefined,
          descricao: erroDescricao ? new Error(erroDescricao) : undefined,
        },
      };
    }

    return {
      ok: true,
      value: premiacao,
    };
  }
}

export type PremiacaoInput = {
  nome: string;
  descricao: string;
};

export type PremiacaoOutput = {
  nome: string;
  descricao: string;
};
