import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { TipoPerfil } from "../domain/entities/usuarioScyggz";
import { CurrentUserContext } from "../globalStore/CurrentUserContext";

export function RouteGuardRedirectIfHasPerfil({
  perfil,
  redirectPath,
  onlyEnabled = false,
  children,
}: {
  perfil: TipoPerfil;
  redirectPath: string;
  onlyEnabled?: boolean;
  children: JSX.Element | JSX.Element[];
}) {
  const navigate = useNavigate();
  const { currentUserScyggz } = useContext(CurrentUserContext);

  const [toShow, setToShow] = useState<JSX.Element | JSX.Element[]>([]);

  useEffect(() => {
    if (currentUserScyggz.status != "carregando") {
      let hasPerfil = false;

      if (currentUserScyggz.user?.perfis) {
        if (onlyEnabled) {
          hasPerfil = !!currentUserScyggz.user.perfis[perfil];
        } else {
          hasPerfil = Object.keys(currentUserScyggz.user.perfis).includes(
            perfil
          );
        }
      }

      if (hasPerfil) {
        navigate(redirectPath);
      } else {
        setToShow(children);
      }
    }
  }, [
    perfil,
    currentUserScyggz,
    children,
    redirectPath,
    onlyEnabled,
    navigate,
  ]);

  return <>{toShow}</>;
}
