import { useContext, useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faClock,
  faExclamationCircle,
  faEye,
  faPencil,
  faUser,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { Modal } from "react-bootstrap";

import logoLinkedin from "../../../assets/images/linkedin.png";
import logoLattes from "../../../assets/images/lattes.png";
import Button from "../../generalComponents/Button";
import { OutputUsuarioScyggzEntity } from "../../../domain/entities/usuarioScyggz";
import { Conexao, ParticipanteConexao } from "../../../domain/entities/Conexao";
import { Result } from "../../../typings";
import { LinkedinPerfilButton } from "./components/LinkedinPerfilButton";
import { ModalEditUser } from "./components/ModalEditUser";
import { CurrentUserContext } from "../../../globalStore/CurrentUserContext";
import { MutualConnection } from "../../generalComponents/MutualConnection";
import { HandlersDatabaseContext } from "../../../globalStore/HandlersProviders/HandlersDatabaseContext";
import { HandlersStorageContext } from "../../../globalStore/HandlersProviders/HandlersStorageContext";
import { ImgFromPathNew } from "../../generalComponents/ImgFromPathNew";
import { perfisToArray } from "../../../hooks/perfisToArray";

export function HeaderUsuario({
  user,
  currentUser,
  conexaoComCurrentUserResult,
  onAdicionarConexao,
  // onRemoverConexao,
  onAceitarConexao,
}: {
  user: Pick<
    OutputUsuarioScyggzEntity,
    | "auth_user_id"
    | "avatar_path"
    | "nome"
    | "headline"
    | "perfis"
    | "cidade"
    | "url_linkedin"
    | "url_lattes"
    | "auth_id_conexoes"
  >;
  currentUser: Pick<
    OutputUsuarioScyggzEntity,
    | "auth_user_id"
    | "avatar_path"
    | "nome"
    | "headline"
    | "perfis"
    | "cidade"
    | "auth_id_conexoes"
  >;
  conexaoComCurrentUserResult: Result<Readonly<Conexao>, Error> | undefined;
  onAdicionarConexao: (
    autor: ParticipanteConexao,
    alvo: ParticipanteConexao
  ) => void;
  onAceitarConexao: () => void;
  onRemoverConexao: () => void;
}) {
  const { handlerUpdateUsuarioScyggz } = useContext(HandlersDatabaseContext);
  const { handlerSaveAvatar } = useContext(HandlersStorageContext);
  const { seeAsPublic, setSeeAsPublic } = useContext(CurrentUserContext);

  const [showModalEditUser, setShowModalEditUser] = useState<boolean>(false);
  const [showAlertNaoHabilitado, setShowAlertNaoHabilitado] =
    useState<boolean>(false);

  const isCurrentUser = useMemo<boolean>(
    () => user.auth_user_id == currentUser.auth_user_id,
    [currentUser.auth_user_id, user.auth_user_id]
  );
  const hasPerfisNaoHabilitado = useMemo<boolean>(
    () => Object.values(user.perfis ?? {}).some((perfil) => !perfil),
    [user.perfis]
  );
  const perfisHabilitados = useMemo<string[]>(
    () =>
      Object.entries(user.perfis ?? {})
        .filter(([, isHabilitado]) => isHabilitado)
        .map(([perfil]) => perfil),
    [user.perfis]
  );

  const currentUserHasProfile = useMemo<boolean>(
    () =>
      perfisToArray({
        perfis: currentUser.perfis,
      }).length > 0,
    [currentUser.perfis]
  );

  useEffect(() => {
    setShowAlertNaoHabilitado(hasPerfisNaoHabilitado);
  }, [hasPerfisNaoHabilitado]);

  return (
    <>
      <section className="d-flex mx-2">
        <div
          style={{
            width: "15vw",
            marginLeft: "3vw",
            marginTop: "1.5vw",
            display: "inline-block",
            verticalAlign: "top",
            minWidth: 75,
          }}
        >
          {
            <ImgFromPathNew
              handlerUpdate={
                handlerSaveAvatar &&
                currentUser.auth_user_id == user.auth_user_id &&
                !seeAsPublic
                  ? {
                      modalButtonText: "Salvar avatar",
                      modalHeaderText: "Editar avatar",
                      update: async (file) => {
                        const saveResult = await handlerSaveAvatar?.save({
                          auth_id: user.auth_user_id,
                          file,
                        });

                        if (!saveResult?.ok) return saveResult;

                        return {
                          ok: true,
                          value: {
                            path: saveResult.value.path,
                          },
                        };
                      },
                    }
                  : undefined
              }
              alt={user.nome}
              path={user.avatar_path?.path}
              className="rounded-circle border border-2 border-white bg-white"
              elementErro={
                <div className="rounded-circle bg-secondary-subtle text-white d-flex justify-content-center align-items-center opacity-75 w-100 h-100">
                  <FontAwesomeIcon icon={faUser} className="w-50 h-50" />
                </div>
              }
            />
          }
        </div>

        <div
          className="ms-2 w-100"
          style={{
            zIndex: 1,
          }}
        >
          <div
            className="d-flex flex-column justify-content-between p-1"
            style={{
              height: "8vw",
              minHeight: "100px",
            }}
          >
            {isCurrentUser && (
              <>
                <div className="d-flex justify-content-end">
                  <button
                    className="btn btn-sm btn-light d-flex align-items-center rounded-pill"
                    style={{
                      fontSize: "min(2vw, 16px)",
                    }}
                    onClick={() => setSeeAsPublic(!seeAsPublic)}
                  >
                    <FontAwesomeIcon icon={faEye} className="m-1" />
                    {seeAsPublic
                      ? "ver como administrador"
                      : "ver perfil como usuário"}
                  </button>
                </div>

                {!seeAsPublic && hasPerfisNaoHabilitado && (
                  <>
                    <div
                      className="d-flex align-items-center bg-white rounded border border-danger border-3  border-opacity-50 p-xl-0"
                      style={{
                        fontSize: "min(2vw, 16px)",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faExclamationCircle}
                        className="text-danger m-1"
                      />
                      Seu perfil está sob análise, ficará visível para os demais
                      usuários após aprovado
                    </div>

                    <Modal
                      show={showAlertNaoHabilitado}
                      onHide={() => setShowAlertNaoHabilitado(false)}
                      centered
                      scrollable={true}
                    >
                      <Modal.Body>
                        <div className="d-flex gap-3">
                          <FontAwesomeIcon
                            icon={faExclamationCircle}
                            className="text-danger m-1"
                            size="4x"
                          />
                          <div>
                            <h6 className="fw-bolder">Perfil em análise!</h6>
                            <p>
                              Uma vez aprovado, seu perfil ficará visível para
                              outras pessoas e você poderá se conectar com
                              outros usuários do SCIENCIN. Por enquanto você
                              pode editar e preencher suas informações
                            </p>
                          </div>
                        </div>
                        <div className="text-center">
                          <button
                            className="btn btn-danger px-5"
                            onClick={() => setShowAlertNaoHabilitado(false)}
                          >
                            OK
                          </button>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </>
                )}
              </>
            )}
          </div>

          <h3 className="fs-4 fw-bold d-flex align-items-end">
            <span className="flex-fill">{user.nome}</span>

            {isCurrentUser && handlerUpdateUsuarioScyggz && !seeAsPublic ? (
              <>
                <button
                  type="button"
                  className="btn bg-body-secondary text-secondary rounded-circle m-1"
                  onClick={() => setShowModalEditUser(true)}
                >
                  <FontAwesomeIcon icon={faPencil} />
                </button>

                {isCurrentUser && handlerUpdateUsuarioScyggz && (
                  <ModalEditUser
                    initialValue={user}
                    show={showModalEditUser}
                    onShowChange={(show) => {
                      setShowModalEditUser(show);
                    }}
                    handlerUpdateUser={handlerUpdateUsuarioScyggz}
                  />
                )}
              </>
            ) : (
              <button type="button" className="invisible btn m-1">
                <FontAwesomeIcon icon={faPencil} />
              </button>
            )}
          </h3>

          <h5>{user.headline}</h5>

          <h6 className="fs-6 m-0">
            {`${user.cidade.cidade}, ${user.cidade.estado} - ${user.cidade.pais}`}
          </h6>

          <div className="d-flex align-items-center gap-2">
            {perfisHabilitados.length > 0 && (
              <div
                style={{
                  fontSize: "14px",
                  color: "#525200",
                  textTransform: "capitalize",
                  fontWeight: "bold",
                }}
              >
                {perfisHabilitados.map((perfil, i) => (
                  <span key={i}>
                    {i > 0 && " | "}
                    {perfil}
                  </span>
                ))}
              </div>
            )}

            {(user.url_linkedin || user.url_lattes) &&
              currentUserHasProfile && (
                <>
                  {user.url_lattes && (
                    <a href={user.url_lattes} target="_blank">
                      <img
                        src={logoLattes}
                        alt={"lattes de " + user.nome}
                        className="p-1"
                        style={{
                          minWidth: "2rem",
                          maxWidth: "2rem",
                        }}
                      />
                    </a>
                  )}

                  {user.url_linkedin && (
                    <a href={user.url_linkedin} target="_blank">
                      <img
                        src={logoLinkedin}
                        alt={"linkedin de " + user.nome}
                        style={{
                          minWidth: "2.1rem",
                          maxWidth: "2.1rem",
                        }}
                      />
                    </a>
                  )}
                </>
              )}
          </div>

          {currentUserHasProfile && (
            <MutualConnection
              auth_id_conexoes_user={user.auth_id_conexoes ?? []}
              auth_id_conexoes_current_user={currentUser.auth_id_conexoes ?? []}
              hideMutual={user.auth_user_id == currentUser.auth_user_id}
            />
          )}

          {((!isCurrentUser && conexaoComCurrentUserResult) || seeAsPublic) &&
            currentUserHasProfile && (
              <div className="mb-4">
                {!isCurrentUser && conexaoComCurrentUserResult ? (
                  <>
                    {conexaoComCurrentUserResult.ok ? (
                      <>
                        {conexaoComCurrentUserResult.value.autor.auth_user_id ==
                        currentUser.auth_user_id ? (
                          conexaoComCurrentUserResult.value.status ==
                          "iniciado" ? (
                            <div
                              className="border rounded-3 p-1 d-inline-block"
                              style={{
                                border: "#999900 1px solid",
                                borderRadius: 16,
                                color: "#999900",
                                backgroundColor: "#fff",
                              }}
                            >
                              <FontAwesomeIcon
                                fontSize={20}
                                title="Pendente"
                                icon={faClock}
                                className="me-1"
                              />
                              Pendente
                            </div>
                          ) : conexaoComCurrentUserResult.value.status ==
                            "aprovado" ? (
                            <div className="border rounded-3 p-1">
                              <FontAwesomeIcon
                                fontSize={20}
                                title="conectados"
                                icon={faCheck}
                              />
                            </div>
                          ) : (
                            <></>
                          )
                        ) : conexaoComCurrentUserResult.value.status ==
                          "iniciado" ? (
                          <Button
                            onClick={onAceitarConexao}
                            icon={faUserPlus}
                            classes="btn btn-success"
                          >
                            Aceitar
                          </Button>
                        ) : conexaoComCurrentUserResult.value.status ==
                          "aprovado" ? (
                          <div className="border rounded-3 p-1">
                            <FontAwesomeIcon
                              fontSize={20}
                              title="conectados"
                              icon={faCheck}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <Button
                        onClick={() => onAdicionarConexao(currentUser, user)}
                        icon={faUserPlus}
                        classes="btn fw-bolder"
                        style={{
                          border: "#999900 1px solid",
                          borderRadius: 16,
                          color: "#fff",
                          backgroundColor: "#999900",
                        }}
                      >
                        Conectar
                      </Button>
                    )}
                  </>
                ) : (
                  <Button
                    icon={faUserPlus}
                    classes="btn fw-bolder"
                    style={{
                      border: "#999900 1px solid",
                      borderRadius: 16,
                      color: "#fff",
                      backgroundColor: "#999900",
                    }}
                  >
                    Conectar
                  </Button>
                )}
              </div>
            )}

          {isCurrentUser && !seeAsPublic && currentUserHasProfile && (
            <LinkedinPerfilButton dataIssue={new Date()} user={user} /> // TODO: GET DATE CREATED
          )}
        </div>
      </section>
    </>
  );
}
