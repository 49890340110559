import {
  TipoPerfil,
  TIPOS_PERFIL,
  UsuarioScyggz,
} from "../domain/entities/usuarioScyggz";

export function perfisToArray({
  perfis,
}: Pick<UsuarioScyggz, "perfis">): { tipo: TipoPerfil; habilitado: boolean }[] {
  const perfisArray: { tipo: TipoPerfil; habilitado: boolean }[] = [];

  Object.keys(perfis ?? {}).forEach((perfil) => {
    const tipoPerfil = perfil as TipoPerfil;

    if (TIPOS_PERFIL.includes(tipoPerfil)) {
      perfisArray.push({
        tipo: tipoPerfil,
        habilitado: !!perfis && !!perfis[tipoPerfil],
      });
    }
  });

  perfisArray.sort((pA, pB) => {
    if (pA.habilitado) {
      return -1;
    } else if (pB.habilitado) {
      return 1;
    }

    return 0;
  });

  return perfisArray;
}
