export const ButtonAceiteConexao = ({
  processandoAceite = false,
  onAceite,
}: {
  processandoAceite?: boolean;
  onAceite: () => void;
}) => {
  return (
    <button
      disabled={processandoAceite}
      onClick={onAceite}
      style={{
        backgroundColor: "#009253",
        color: "#fff",
        fontSize: "12px",
        borderRadius: "10px",
        border: "none",
        padding: "5px 15px",
        opacity: processandoAceite ? 0.5 : 1,
      }}
    >
      Aceitar conexão
    </button>
  );
};
