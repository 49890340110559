import { ReactNode, useContext, useEffect, useState } from "react";

import { UsuarioScyggz } from "../../domain/entities/usuarioScyggz";
import {
  ConexoesPendentesEnviadasContext,
  TypeConexoesPendentesEnviadasContext,
} from "./ConexoesPendentesEnviadasContext";
import { HandlersDatabaseContext } from "../HandlersProviders/HandlersDatabaseContext";

export function ConexoesPendentesEnviadasProvider({
  user,
  children,
}: {
  user: Pick<UsuarioScyggz, "auth_user_id">;
  children: ReactNode;
}) {
  const [conexoesPendentesEnviadasResult, setConexoesPendentesEnviadasResult] =
    useState<TypeConexoesPendentesEnviadasContext>();

  const { handlerConexaoPendentesEnviadasGet } = useContext(
    HandlersDatabaseContext
  );

  useEffect(() => {
    if (handlerConexaoPendentesEnviadasGet)
      handlerConexaoPendentesEnviadasGet
        .getConexoesPendentesEnviadas(user)
        .then((result) => {
          setConexoesPendentesEnviadasResult(result);
        });
  }, [user, handlerConexaoPendentesEnviadasGet]);

  return (
    <ConexoesPendentesEnviadasContext.Provider
      value={{
        conexoesPendentesEnviadasResult,
      }}
      children={children}
    />
  );
}
