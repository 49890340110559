import { useMemo } from "react";

import { ProgramaConexao } from "../../../../domain/entities/ProgramaConexao";
import { ImgFromPath } from "../../../generalComponents/ImgFromPath";
import { TipoCadastro } from "../../../../domain/entities/TipoCadastro";

export function ListAllItemProgramaDeConexao({
  programa: {
    logo_path,
    nome,
    about,
    stakeholder_type_group_a,
    stakeholder_type_group_b,
  },
  className = "",
}: {
  programa: ProgramaConexao;
  className?: string;
}) {
  const stakeHolders = useMemo<TipoCadastro[]>(
    () =>
      ProgramaConexao.getStakeHolders({
        stakeholder_type_group_a,
        stakeholder_type_group_b,
      }),
    [stakeholder_type_group_a, stakeholder_type_group_b]
  );

  return (
    <div className={"card shadow-sm px-4 " + className}>
      <div className="card-body d-flex gap-3">
        <div className="flex-shrink-1">
          <ImgFromPath
            path={logo_path}
            className="rounded-circle border-white object-fit-cover"
            alt={nome}
            style={{
              width: "100px",
              height: "100px",
              border: "2px solid #fff",
            }}
          />
        </div>

        <div className="flex-fill">
          <h5 className="fw-bolder">{nome}</h5>

          {stakeHolders.length > 0 && (
            <div
              className="d-block fw-bolder color-spinoff"
              style={{
                fontSize: "14px",
                textTransform: "capitalize",
              }}
            >
              {stakeHolders.map((stakeHolder, i) => (
                <span key={i}>
                  {i > 0 && " | "}
                  {stakeHolder}
                </span>
              ))}
            </div>
          )}

          <p
            className="fw-bolder m-0"
            style={{
              WebkitLineClamp: 4,
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
            }}
          >
            {about}
          </p>
        </div>
      </div>
    </div>
  );
}
