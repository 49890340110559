import arvore from "../../../../assets/images/icone_arvore.png";

export function BannerPessoasRelevantesLoading() {
  return (
    <div className="card shadow-sm rounded-4">
      <div className="card-body">
        <h5 className="card-title fw-bolder pb-5 d-flex justify-content-center gap-1 align-items-center">
          <img width={"25rem"} src={arvore} alt="Logo árvore scyggz" />
          <span>Pessoas relevantes</span>
        </h5>

        {Array(5)
          .fill(0)
          .map((_, i) => (
            <div
              key={i}
              className={"row placeholder-glow " + (i > 0 ? "mt-1" : "")}
            >
              <div className="col-3 col-sm-2 p-0">
                <div className="rounded-circle ratio ratio-1x1 placeholder bg-secondary" />
              </div>

              <div className="col-9 p-0 ps-2 d-flex flex-column justify-content-center text-dark">
                <h6 className="placeholder placeholder-sm m-1 bg-secondary"></h6>
                <span className="placeholder placeholder-xs m-1 col-5 bg-secondary"></span>
                <span className="placeholder placeholder-xs m-1 col-7 bg-secondary"></span>
                <div className="placeholder placeholder-xs m-1 col-11 bg-secondary"></div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
