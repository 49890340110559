import { useMemo, useState } from "react";
import { faRotateLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { TypeSelectStakeholder } from "./SelectStakeholder";
import { ProgramaConexao } from "../../../../../domain/entities/ProgramaConexao";
import { InputTextarea } from "../../../../generalComponents/InputTextarea";
import { ImgFromPath } from "../../../../generalComponents/ImgFromPath";

export function Etapa5NewPrograma({
  stakeholdersGroupA,
  stakeholdersGroupB,
  value: programaToSave,
  onChange: setProgramaToSave,
  onBack,
  onNext,
}: {
  stakeholdersGroupA: TypeSelectStakeholder[];
  stakeholdersGroupB: TypeSelectStakeholder[];
  value: ProgramaConexaoEtapa5;
  onChange: (value: ProgramaConexaoEtapa5) => void;
  onBack: () => void;
  onNext: () => Promise<void>;
}) {
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const erroProgramaToSave = useMemo<boolean>(() => {
    return !!ProgramaConexao.isInvalidProgramaConexao({
      message_to_a: programaToSave.message_to_a,
      message_to_b: programaToSave.message_to_b,
    });
  }, [programaToSave.message_to_a, programaToSave.message_to_b]);

  return (
    <div className="card shadow-sm">
      <div className="card-header border-0 bg-white p-0">
        <button
          type="button"
          className="btn btn-light bg-white border-0 text-muted"
          onClick={onBack}
        >
          <FontAwesomeIcon icon={faRotateLeft} className="me-2" />
          Back
        </button>
      </div>

      <div className="card-body">
        <h5 className="pb-2 fw-bolder">
          Escreva uma mensagem personalizada para os stakeholders
        </h5>
        <p className="pb-2">Esta mensagem aparecerá no convite</p>

        <div className="row align-items-start mb-3">
          <InputTextarea
            label=""
            onChange={(message_to_a) => {
              setProgramaToSave({
                ...programaToSave,
                message_to_a,
              });
            }}
            id="iptMessageA"
            title="Mensagem grupo A"
            value={programaToSave.message_to_a}
            max={{
              value: ProgramaConexao.MAX_SIZE_ABOUT,
              errorMessage: ProgramaConexao.MAX_SIZE_ABOUT_ERROR,
            }}
            min={{
              value: ProgramaConexao.MIN_SIZE_ABOUT,
              errorMessage: ProgramaConexao.MIN_SIZE_ABOUT_ERROR,
            }}
            className="col-6"
          />

          <div className="col-6 bg-warning-subtle">
            <h5 className="p-2 fw-bolder text-green-strong">
              Stakeholders grupo A
            </h5>

            <div
              style={{
                maxHeight: 134,
              }}
              className="overflow-auto"
            >
              {stakeholdersGroupA.map((stakeholderGroupA) => (
                <div
                  key={stakeholderGroupA.id}
                  title={stakeholderGroupA.nome}
                  className="col-3 d-inline-block"
                >
                  {stakeholderGroupA.avatar_path && (
                    <ImgFromPath
                      path={stakeholderGroupA.avatar_path}
                      alt={stakeholderGroupA.nome}
                      className="rounded-circle border-white object-fit-cover"
                      style={{
                        width: "36px",
                        height: "36px",
                        border: "1px solid #fff",
                      }}
                    />
                  )}

                  <span>
                    {stakeholderGroupA.nome
                      .split(" ")
                      .map((parteNome) => parteNome.at(0))
                      .join(" ")}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="row align-items-start">
          <InputTextarea
            label=""
            onChange={(message_to_b) => {
              setProgramaToSave({
                ...programaToSave,
                message_to_b,
              });
            }}
            id="iptMessageB"
            title="Mensagem grupo B"
            value={programaToSave.message_to_b}
            max={{
              value: ProgramaConexao.MAX_SIZE_ABOUT,
              errorMessage: ProgramaConexao.MAX_SIZE_ABOUT_ERROR,
            }}
            min={{
              value: ProgramaConexao.MIN_SIZE_ABOUT,
              errorMessage: ProgramaConexao.MIN_SIZE_ABOUT_ERROR,
            }}
            className="col-6"
          />

          <div className="col-6 bg-warning-subtle">
            <h5 className="p-2 fw-bolder text-green-strong">
              Stakeholders grupo B
            </h5>

            <div
              style={{
                maxHeight: 134,
              }}
              className="overflow-auto"
            >
              {stakeholdersGroupB.map((stakeholderGroupB) => (
                <div
                  key={stakeholderGroupB.id}
                  title={stakeholderGroupB.nome}
                  className="col-3 d-inline-block"
                >
                  {stakeholderGroupB.avatar_path && (
                    <ImgFromPath
                      path={stakeholderGroupB.avatar_path}
                      alt={stakeholderGroupB.nome}
                      className="rounded-circle border-white object-fit-cover"
                      style={{
                        width: "36px",
                        height: "36px",
                        border: "1px solid #fff",
                      }}
                    />
                  )}

                  <span>
                    {stakeholderGroupB.nome
                      .split(" ")
                      .map((parteNome) => parteNome.at(0))
                      .join(" ")}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="card-footer border-0 pt-0 bg-white text-center">
        <button
          type="button"
          className="btn px-4 text-white background-spinoff"
          onClick={() => {
            setIsSaving(true);
            onNext();
            setIsSaving(false);
          }}
          disabled={erroProgramaToSave || isSaving}
        >
          Continue
        </button>
      </div>
    </div>
  );
}

export type ProgramaConexaoEtapa5 = {
  message_to_a: string;
  message_to_b: string;
};
