import { Link } from "react-router-dom";

import logoHeader from "../../assets/images/logo-scyggz.png";

export default function PageNotFound() {
  return (
    <section
      className="alternate"
      style={{ border: "none", paddingTop: "170px" }}
    >
      <div className="container">
        <h1 className="text-center">404 - Error</h1>
        <h3 className="text-center mb-4">
          <strong>Página não encontrada</strong>
        </h3>

        <div className="text-center">
          <Link to="/">
            <h5 className="mb-2">Voltar para a página inicial</h5>
          </Link>
        </div>

        <br />

        <div className="text-center">
          <img
            src={logoHeader}
            style={{ width: "300px" }}
            alt="Logo do scyggz"
          />
        </div>
      </div>
    </section>
  );
}
