import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";

import { HandlerCacheMentor } from "../../../../../domain/usecases/interfaces/HandlerCacheMentor";
import { ConteudoCadastroInteresseMentoria } from "./ConteudoCadastroInteresseMentoria";
import { ContainerResponsive } from "../../../../generalComponents/ContainerResponsive";
import { HandlerCreateMentor } from "../../../../../domain/usecases/interfaces/handlerCreateMentor";
import { useContext } from "react";
import { CurrentUserContext } from "../../../../../globalStore/CurrentUserContext";

export function PainelCadastroInteressementoriaMentor({
  handlerCacheMentor,
  handlerCreateMentor,
}: {
  handlerCacheMentor: HandlerCacheMentor;
  handlerCreateMentor: HandlerCreateMentor;
}) {
  const { refreshCurrentUserScyggz } = useContext(CurrentUserContext);

  const navigate = useNavigate();

  const paginacaoMentor = {
    start: 6,
    end: 6,
  };

  return (
    <ContainerResponsive
      backUrlMobile="/cadastro/mentor/5"
      paginacao={paginacaoMentor}
      textoMarcaDagua={"Mentor(a)"}
      showLogo={true}
    >
      <ConteudoCadastroInteresseMentoria
        handlerCreateMentor={handlerCreateMentor}
        handlerCacheMentor={handlerCacheMentor}
        onBack={() => {
          navigate("/cadastro/mentor/5");
        }}
        onFinalize={async (mentor_id) => {
          handlerCacheMentor.clear();
          if (refreshCurrentUserScyggz) await refreshCurrentUserScyggz();
          navigate("/user/" + mentor_id + "/mentor");
        }}
      />
    </ContainerResponsive>
  );
}
