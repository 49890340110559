import { useContext } from "react";
import { ProgressBar } from "react-bootstrap";

import { UsuarioScyggz } from "../../../../domain/entities/usuarioScyggz";
import { ConexoesAprovadasContext } from "../../../../globalStore/conexoesAprovadas/ConexoesAprovadasContext";
import { ListConexoes } from "./ListConexoes";

export function ListConexoesAprovadas({ user }: { user: UsuarioScyggz }) {
  const { conexoesAprovadasResult } = useContext(ConexoesAprovadasContext);

  return (
    <>
      {conexoesAprovadasResult == undefined ? (
        <ProgressBar animated variant="info" now={100} className="w-100 m-1" />
      ) : (
        conexoesAprovadasResult.ok && (
          <ListConexoes
            conexoes={conexoesAprovadasResult.value.map((conexao) => ({
              id: conexao.id,
              user:
                user.auth_user_id == conexao.autor.auth_user_id
                  ? conexao.alvo
                  : conexao.autor,
            }))}
          />
        )
      )}
    </>
  );
}
