import { useContext, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Formik } from "formik";
import * as Yup from "yup";

import { UsuarioScyggz } from "../../../../../domain/entities/usuarioScyggz";
import { HandlerGetURLFromPath } from "../../../../../domain/usecases/interfaces/HandlerGetURLFromPath";
import { ResizeContext } from "../../../../../hooks/useResize";
import { ButtonDarkGreen } from "../../../../generalComponents/buttonDarkGreen/ButtonDarkGreen";
import { ImageCrop } from "../../../../generalComponents/imageCrop";
import { HandlerSaveLogoEmpresa } from "../../../../../domain/usecases/interfaces/handlerSaveLogoEmpresa";
import { LogoEmpresa } from "../../../../../domain/entities/LogoEmpresa";
import { OutputEmpresa } from "../../../../../domain/entities/Empresa";
import { HandlerCacheEmpresa } from "../../../../../domain/usecases/interfaces/HandlerCacheEmpresa";

const imageMimeType = /image\/(png|jpg|jpeg)/i;

export function ConteudoLogoEmpresa({
  empresa,
  user,
  handlerSaveLogoEmpresa,
  handlerGetURLFromPath,
  handlerCacheEmpresa,
  onNext,
}: {
  empresa: Pick<OutputEmpresa, "path_logo" | "id" | "nome">;
  user: Pick<UsuarioScyggz, "nome">;
  handlerSaveLogoEmpresa: HandlerSaveLogoEmpresa;
  handlerGetURLFromPath: HandlerGetURLFromPath;
  handlerCacheEmpresa: HandlerCacheEmpresa;
  onNext: () => void;
}) {
  const { responsive } = useContext(ResizeContext);

  const iptFileFotoEl = useRef<HTMLInputElement>(null);

  const [fileFotoDataURL, setFileFotoDataURL] = useState<string | null>(null);
  const [fileFotoSelected, setFileFotoSelected] = useState<File | null>(null);

  useEffect(() => {
    let fileReader: FileReader;
    let isCancel = false;

    handlerCacheEmpresa.get().then((cacheEmpresa) => {
      if (cacheEmpresa.ok) {
        if (cacheEmpresa.value.logoFile) {
          fileReader = new FileReader();
          fileReader.onload = (e) => {
            if (e.target) {
              const { result } = e.target;
              if (result && !isCancel) {
                setFileFotoDataURL(result as string);
              }
            }
          };
          fileReader.readAsDataURL(cacheEmpresa.value.logoFile);
        }
      }
    });

    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [handlerCacheEmpresa]);

  useEffect(() => {
    if (empresa.path_logo) {
      handlerGetURLFromPath.getURL(empresa.path_logo).then((getURLResult) => {
        if (getURLResult.ok) {
          setFileFotoDataURL(getURLResult.value);
        }
      });
    }
  }, [empresa.path_logo, handlerGetURLFromPath, user]);

  useEffect(() => {
    let fileReader: FileReader;
    let isCancel = false;
    if (fileFotoSelected) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        if (e.target) {
          const { result } = e.target;
          if (result && !isCancel) {
            setFileFotoDataURL(result as string);
          }
        }
      };
      fileReader.readAsDataURL(fileFotoSelected);
    }

    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [fileFotoSelected]);

  const nome: string | undefined = user.nome?.split(" ").at(0) ?? user.nome;

  return (
    <div style={{ width: !responsive.sm ? "500px" : "", marginTop: "30px" }}>
      <Formik<FormikValues>
        initialValues={{ newLogoFile: undefined }}
        validationSchema={validator}
        validateOnMount={true}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={async (values) => {
          if (values.newLogoFile) {
            const logoResult = LogoEmpresa.create({
              file: values.newLogoFile,
              id_empresa: empresa.id,
            });

            if (!logoResult.ok) {
              console.warn("Erro criação", logoResult.error);
              return;
            }

            const handlerSaveLogoEmpresaResult =
              await handlerSaveLogoEmpresa.save({
                file: logoResult.value.file,
                id_empresa: logoResult.value.id_empresa,
              });

            if (!handlerSaveLogoEmpresaResult.ok) {
              console.warn(handlerSaveLogoEmpresaResult.error);
              return;
            }
          }

          onNext();
        }}
      >
        {({ isValid, isSubmitting, handleSubmit, setFieldValue }) => (
          <form
            onSubmit={handleSubmit}
            className="d-flex align-items-center flex-column"
          >
            <h4 className="card-title text-center mb-5 fw-bold">
              <span>{nome && `${nome},`}</span>
              <span className="h5 fw-bold">
                {} Adicione uma logo da sua Empresa!
              </span>
            </h4>
            <div
              style={{
                height: "137px",
                width: "137px",
                backgroundColor: "#e2e2e2",
              }}
              className="position-relative border d-flex justify-content-center align-items-center rounded-circle"
              onClick={() => {
                if (!fileFotoDataURL) iptFileFotoEl.current?.click();
              }}
            >
              {fileFotoDataURL ? (
                <ImageCrop
                  alt={empresa.nome}
                  fileFotoDataURL={fileFotoDataURL}
                  onChangeBlob={(blob) => {
                    setFieldValue("newLogoFile", blob);
                  }}
                ></ImageCrop>
              ) : (
                <FontAwesomeIcon
                  title="Adicionar foto"
                  icon={faCamera}
                  style={{ fontSize: "50px", color: "#525200" }}
                  className="h1"
                />
              )}
              <span
                style={{
                  top: "102px",
                  left: "100px",
                  backgroundColor: "#999900",
                }}
                title="add foto"
                className="position-absolute badge rounded-circle p-2"
                onClick={(event) => {
                  iptFileFotoEl.current?.click();
                  event.stopPropagation();
                }}
              >
                <FontAwesomeIcon icon={faPlus} />
                <span className="visually-hidden">+</span>
              </span>
            </div>
            <input
              ref={iptFileFotoEl}
              className="d-none"
              type="file"
              accept=".png, .jpg, .jpeg"
              id="iptLogo"
              onChange={(e) => {
                const { files } = e.target;

                if (files)
                  for (let i = 0; i < files.length; i++) {
                    const file = files[i];

                    if (!file.type.match(imageMimeType)) {
                      continue;
                    }
                    setFileFotoSelected(file);
                    setFieldValue("newLogoFile", file);
                    break;
                  }
              }}
            />
            <label className="mt-1 mb-4" htmlFor="iptLogo">
              Adicione uma logo.
            </label>
            <div className=" pt-4 w-100 text-end">
              <ButtonDarkGreen
                disabled={!isValid || isSubmitting}
                isSubmit={true}
                className={(responsive.sm ? "w-100" : "") + " text-uppercase"}
              >
                Avançar
              </ButtonDarkGreen>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export type FormikValues = {
  newLogoFile?: Blob;
};

export type OutputPainelCadastroFotoUsuarioInvestidor = {
  auth_id: string;
  nome: string;
};

const validator = Yup.object().shape({
  newLogoFile: Yup.mixed(),
});
