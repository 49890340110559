import { useContext, useEffect, useState } from "react";

import { SelectLocalizacao } from "./SelectLocalizacao";
import { CidadeOutput } from "../../domain/entities/cidade";
import { HandlersLocationContext } from "../../globalStore/HandlersProviders/HandlersLocationContext";

export function InputCidade({
  label,
  value,
  onChange,
  validate,
  className = "",
}: {
  label: string;
  value: CidadeOutput | undefined;
  onChange: (cidade?: CidadeOutput) => void;
  validate: (cidade?: CidadeOutput) => Error | undefined;
  className?: string;
}) {
  const { handlerQueryLocationCidade } = useContext(HandlersLocationContext);

  const [errorToShow, setErrorToShow] = useState<string>();
  const [isChanged, setIsChanged] = useState<boolean>(false);

  useEffect(() => {
    const errorValidate = validate(value);

    if (errorValidate) {
      setErrorToShow(errorValidate.message);
    } else {
      setErrorToShow(undefined);
    }
  }, [validate, value]);

  return (
    <>
      {handlerQueryLocationCidade && (
        <div className={className}>
          <label className={"form-label fw-bolder m-0 "} htmlFor="selCidade">
            {label}
          </label>

          <SelectLocalizacao
            value={
              value
                ? {
                    tipo: "cidade",
                    ...value,
                  }
                : undefined
            }
            placeHolder=""
            onCitySelected={(cidade) => {
              if (cidade) {
                if (cidade instanceof Array) {
                  if (cidade[0].tipo == "cidade") {
                    onChange(cidade[0]);
                  }
                } else {
                  if (cidade.tipo == "cidade") {
                    onChange(cidade);
                  }
                }
              } else {
                onChange(undefined);
              }

              setIsChanged(true);
            }}
            onBlur={() => {
              setIsChanged(true);
            }}
            isMulti={false}
            className={errorToShow && isChanged ? " is-invalid" : ""}
            handlerQueryLocation={handlerQueryLocationCidade}
            id="selCidade"
          />
          <div className="invalid-feedback flex-fill">{errorToShow}</div>
        </div>
      )}
    </>
  );
}
