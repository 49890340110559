import { Auth } from "./Auth";
import { UsuarioScyggzValidator } from "./UsuarioScyggzValidator";

export const MIN_SIZE_MESSAGE = 3;
export const MIN_SIZE_MESSAGE_ERROR = "Mensagem muito curta!";

export const MAX_SIZE_MESSAGE = 1000;
export const MAX_SIZE_MESSAGE_ERROR = "Mensagem muito longa!";

export type ContatoEmail = {
  name: string;
  email: string;
  phone?: string;
  message: string;
};

export function isInvalidContatoEmail(
  input: ContatoEmail
): Partial<Record<keyof ContatoEmail, Error>> | undefined {
  const errors: Partial<Record<keyof ContatoEmail, Error>> = {};

  const erroNome = UsuarioScyggzValidator.isInvalidNome(input.name);
  if (erroNome) {
    errors.name = erroNome;
  }

  const validEmail = Auth.isValidEmail(input.email);
  if (!validEmail) {
    errors.email = new Error("Email inválido");
  }

  const erroMessage = isInvalidMessage(input.message);
  if (erroMessage) {
    errors.message = erroMessage;
  }

  if (Object.keys(errors).length > 0) {
    return errors;
  }

  return undefined;
}

export function isInvalidMessage(message: string): Error | undefined {
  if (message.length < MIN_SIZE_MESSAGE) {
    return new Error(MIN_SIZE_MESSAGE_ERROR);
  }
  if (message.length > MAX_SIZE_MESSAGE) {
    return new Error(MAX_SIZE_MESSAGE_ERROR);
  }
  return undefined;
}
