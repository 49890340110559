export function cropImage(
  imagePath: string,
  newX: number,
  newY: number,
  newWidth: number,
  newHeight: number
): Promise<Blob> {
  return new Promise((resolve, reject) => {
    const originalImage = new Image();
    originalImage.src = imagePath;
    originalImage.crossOrigin = "anonymous";

    const canvas = document.createElement("canvas");
    const ctxCanvas = canvas.getContext("2d");

    if (canvas && ctxCanvas) {
      originalImage.addEventListener("load", function () {
        canvas.width = newWidth;
        canvas.height = newHeight;

        ctxCanvas.drawImage(
          originalImage,
          newX,
          newY,
          newWidth,
          newHeight,
          0,
          0,
          newWidth,
          newHeight
        );

        canvas.toBlob((blob) => {
          if (blob) resolve(blob);
          else {
            reject("error on get blob canvas");
          }
        });
      });
    } else {
      reject("error on getContext");
    }
  });
}
