/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Yup from "yup";
import { Formik } from "formik";
import Select, { StylesConfig } from "react-select";
import makeAnimated from "react-select/animated";
import { useContext, useEffect, useState } from "react";

import { ButtonDarkGreen } from "../../../../generalComponents/buttonDarkGreen/ButtonDarkGreen";
import { CidadeOutputWithTipo } from "../../../../../domain/entities/cidade";
import { ResizeContext } from "../../../../../hooks/useResize";
import { InputPremiacoes } from "../../../../generalComponents/InputPremiacoes/InputPremiacoes";
import {
  Premiacao,
  PremiacaoOutput,
} from "../../../../../domain/entities/premiacao";
import { ButtonLightGreen } from "../../../../generalComponents/buttonLightGreen/ButtonLightGreen";
import { CurrentUserContext } from "../../../../../globalStore/CurrentUserContext";
import {
  CacheHub,
  HandlerCacheHub,
} from "../../../../../domain/usecases/interfaces/HandlerCacheHub";
import {
  ClassificacaoHub,
  CLASSIFICACOES_HUB,
} from "../../../../../domain/entities/ClassificacaoHub";
import { OutputNit } from "../../../../../domain/entities/Nit";
import { HandlerGetNit } from "../../../../../domain/usecases/interfaces/handlerGetNit";
import { HandlerCreateHub } from "../../../../../domain/usecases/interfaces/handlerCreateHub";
import { InputSobre } from "../../../../generalComponents/InputSobre";
import { Sobre } from "../../../../../domain/entities/sobre";
import { InputCidade } from "../../../../generalComponents/InputCidade";
import { UsuarioScyggzValidator } from "../../../../../domain/entities/UsuarioScyggzValidator";
import {
  InputTelefone,
  TypeInputTelefone,
} from "../../../../generalComponents/InputTelefone";
import { InputLinkedin } from "../../../../generalComponents/InputLinkedin";
import { UrlLinkedin } from "../../../../../domain/entities/urlLinkedin";

export const ConteudoSobreHub = ({
  handlerCacheHub,
  handlerGetNit,
  handlerCreateHub,
  onBack,
  onNext,
}: {
  handlerCacheHub: HandlerCacheHub;
  labelHeadline?: string;
  handlerGetNit: HandlerGetNit;
  handlerCreateHub: HandlerCreateHub;
  onNext: () => void;
  onBack: () => void;
}) => {
  const customStylesSelect: StylesConfig<{ id: string; nome: string }, false> =
    {
      control: () => ({
        border: "none",
        borderBottom: "3px #666600 solid",
        display: "flex",
        background: " #fff",
        borderBottomLeftRadius: "8px",
        textAlign: "left",
      }),
    };

  const [allNit, setAllNit] = useState<OutputNit[]>([]);
  const [isAllNitLoading, setIsAllNitLoading] = useState<boolean>(false);
  const animatedComponents = makeAnimated();

  const { responsive } = useContext(ResizeContext);

  const { currentUserScyggz } = useContext(CurrentUserContext);

  const [parteDoNome, setParteDoNome] = useState("");

  const [cacheHub, setCacheHub] = useState<CacheHub>({});
  const [isEditingPremiacoes, setIsEditingPremiacoes] =
    useState<boolean>(false);

  useEffect(() => {
    handlerCacheHub.get().then((cacheHub) => {
      if (cacheHub.ok) {
        setCacheHub(cacheHub.value);
      }
    });
  }, [handlerCacheHub]);

  useEffect(() => {
    if (currentUserScyggz.user) {
      const parteDoNome: string =
        currentUserScyggz.user.nome.split(" ").at(0) ??
        currentUserScyggz.user.nome;

      setParteDoNome(parteDoNome);
    }
  }, [currentUserScyggz]);

  useEffect(() => {
    setIsAllNitLoading(true);
    handlerGetNit.getAllEnabledNits().then((handlerGetNitResult) => {
      if (handlerGetNitResult.ok) setAllNit(handlerGetNitResult.value);

      setIsAllNitLoading(false);
    });
  }, [handlerGetNit]);

  return (
    <div style={!responsive.sm ? { padding: "0 100px" } : {}}>
      <Formik<FormikValues>
        initialValues={{
          email: cacheHub?.email ?? "",
          nit: cacheHub.nit ?? undefined,
          nome_Hub: cacheHub?.nome ?? "",
          sobre: cacheHub?.sobre ?? "",
          website: cacheHub?.website ?? "",
          linkedin: cacheHub?.linkedin ?? "",
          telefone: {
            numero: cacheHub?.telefone ?? "",
            invalid: true,
          },
          premiacoes: cacheHub?.premiacoes ?? [],
          cidade: cacheHub.cidade
            ? {
                cidade: cacheHub.cidade.cidade,
                estado: cacheHub.cidade.estado,
                pais: cacheHub.cidade.pais,
                paisCode: cacheHub.cidade.paisCode,
                tipo: "cidade",
              }
            : undefined,
          classificacao: cacheHub.classificacao ?? undefined,
        }}
        validationSchema={validator}
        validateOnMount={true}
        validateOnChange={true}
        validateOnBlur={true}
        enableReinitialize={true}
        onSubmit={async (values) => {
          await handlerCacheHub.save({
            nome: values.nome_Hub,
            sobre: values.sobre,
            nit: values.nit
              ? {
                  id: values.nit.id,
                  nome: values.nit.nome,
                }
              : undefined,
            cidade: values.cidade,
            email: values.email,
            linkedin: values.linkedin,
            premiacoes: values.premiacoes,
            telefone: values.telefone.numero,
            website: values.website,
            classificacao: values.classificacao,
          });

          if (!values.cidade) {
            console.warn("Cidade obrigatória!");
            return;
          }

          const createHubResult = await handlerCreateHub.createHub({
            email: values.email,
            gestores: [currentUserScyggz.user?.auth_user_id ?? ""],
            nome: values.nome_Hub,
            sobre: values.sobre,
            telefone: values.telefone.numero ?? "",
            linkedin: values.linkedin,
            nit: values.nit
              ? {
                  id: values.nit.id,
                  nome: values.nit.nome,
                }
              : undefined,
            premiacoes: values.premiacoes,
            website: values.website,
            classificacao: values.classificacao ?? "outros",
            cidade: values.cidade,
            created_in: new Date(),
            habilitado: false,
          });

          if (!createHubResult.ok) {
            console.warn("Erro na criação do HUB");
            return;
          } else {
            handlerCacheHub.save({
              id: createHubResult.value.id,
            });
          }

          onNext();
        }}
      >
        {({
          isValid,
          isSubmitting,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          handleChange,
          handleBlur,
          errors,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit} className="pb-2">
            <h4 className="card-title text-center mb-3 fw-bold">
              <span>{parteDoNome && `${parteDoNome}`}</span>
              <span className="h5 fw-bold">
                {}, por favor fale sobre o Hub de Inovação
              </span>
            </h4>

            <InputSobre
              label="Sobre o Hub de Inovação*:"
              value={values.sobre}
              onChange={(sobre) => {
                setFieldValue("sobre", sobre);
              }}
            />

            <div className="mb-4 col-12">
              <label className="form-label" htmlFor="selectClassificacao">
                Classificação*
              </label>
              <Select<{ label: ClassificacaoHub; value: ClassificacaoHub }>
                options={CLASSIFICACOES_HUB.map((classificacao) => ({
                  value: classificacao,
                  label: classificacao,
                }))}
                components={animatedComponents}
                placeholder=""
                onBlur={() => setFieldTouched("classificacao", true)}
                onChange={(classificacaoObj) => {
                  setFieldValue("classificacao", classificacaoObj?.value);

                  setTimeout(() => {
                    setFieldTouched("classificacao", true);
                  }, 0);
                }}
                className={
                  errors.classificacao && touched.classificacao
                    ? " is-invalid "
                    : ""
                }
                value={
                  values.classificacao
                    ? {
                        label: values.classificacao,
                        value: values.classificacao,
                      }
                    : undefined
                }
                noOptionsMessage={() => "sem opção"}
                inputId="selectClassificacao"
              />
              <div className="invalid-feedback flex-fill">Campo inválido</div>
            </div>

            <div className="mb-4">
              <label htmlFor="iptNome" className="form-label">
                Nome do Hub de Inovação*
              </label>
              <input
                type="text"
                className={
                  `${responsive.sm ? "imputMobile" : "form-control"}` +
                  (errors.nome_Hub && touched.nome_Hub ? " is-invalid" : "")
                }
                id="iptNome"
                name="nome_Hub"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.nome_Hub}
              />
              <div className="invalid-feedback flex-fill">
                {errors.nome_Hub}
              </div>
            </div>

            <div className="mb-4">
              <InputCidade
                value={values.cidade}
                label="Em qual cidade?*"
                onChange={(cidade) => {
                  setFieldValue("cidade", cidade);
                }}
                validate={UsuarioScyggzValidator.isInvalidCidade}
              />
            </div>

            <div className="mb-4">
              <InputTelefone
                label="Telefone*"
                value={values.telefone}
                onChange={(tel) => {
                  setFieldValue("telefone", tel);
                }}
                validate={(numero) => {
                  const preenchido: boolean = (numero?.length ?? 0) > 0;

                  if (!preenchido) {
                    return new Error("obrigatório");
                  }
                }}
              />
            </div>

            <div className="mb-4">
              <label htmlFor="iptWebsite" className="form-label">
                Website
              </label>
              <input
                type="text"
                className={
                  `${responsive.sm ? "imputMobile" : "form-control"}` +
                  (errors.website && touched.website ? " is-invalid" : "")
                }
                id="iptWebsite"
                name="website"
                onChange={website => {
                  const websiteStr = website.target.value;
                  let websiteFinal = websiteStr;

                  if(websiteStr === "http:/" || websiteStr === "https:/") {
                    websiteFinal = "";
                  } else if (!websiteStr.startsWith("http://") && !websiteStr.startsWith("https://")) {
                    websiteFinal = "https://" + websiteStr;
                  }

                  setFieldValue("website", websiteFinal);
                }}
                onBlur={handleBlur}
                value={values.website}
              />
              <div className="invalid-feedback flex-fill">{errors.website}</div>
            </div>

            <div className="mb-4">
              <label htmlFor="iptEmail" className="form-label">
                E-mail*
              </label>
              <input
                type="text"
                className={
                  `${responsive.sm ? "imputMobile" : "form-control"}` +
                  (errors.email && touched.email ? " is-invalid" : "")
                }
                id="iptEmail"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
              <div className="invalid-feedback flex-fill">{errors.email}</div>
            </div>

            <div className="mb-4">
              <InputLinkedin
                label="Linkedin"
                value={values.linkedin}
                onChange={(linkedin) => {
                  setFieldValue("linkedin", linkedin);
                }}
              />
            </div>

            <div className="mb-1 w-100">
              <label className="form-label" htmlFor="selectInteresse">
                Selecione uma opção abaixo, caso seja associado a um NIT
              </label>
              <Select<{ id: string; nome: string }>
                styles={responsive.sm ? customStylesSelect : undefined}
                options={allNit}
                isLoading={isAllNitLoading}
                getOptionLabel={(o) => o.nome}
                getOptionValue={(o) => o.id}
                components={animatedComponents}
                placeholder=""
                onBlur={() => setFieldTouched("nit", true)}
                onChange={(setoresAtuacao) => {
                  setFieldValue("nit", setoresAtuacao);
                  setTimeout(() => {
                    setFieldTouched("nit", true);
                  }, 0);
                }}
                className={errors.nit && touched.nit ? " is-invalid " : ""}
                noOptionsMessage={() => "sem opção"}
                inputId="selectInteresse"
                value={
                  values.nit
                    ? { id: values.nit.id, nome: values.nit.nome }
                    : undefined
                }
              />
              <div className="invalid-feedback flex-fill">Campo inválido</div>
            </div>

            <InputPremiacoes
              value={values.premiacoes ?? []}
              onChange={(premiacoes) => {
                setFieldValue("premiacoes", premiacoes);
              }}
              label="Liste suas premiações/certificações"
              className="col-12 text-end"
              showTip={true}
              isEditing={setIsEditingPremiacoes}
            />
            <div className="mt-5 fw-bold text-center d-flex flex-wrap justify-content-center gap-2">
              {!responsive.sm && (
                <p style={{ color: "#525200", maxWidth: "500px", margin: "0" }}>
                  *Obs: Após a criação do perfil do Hub, você poderá convidar
                  demais membros da sua Organização para completar o perfil.
                </p>
              )}

              <div
                className=" text-end d-flex flex-nowrap"
                style={{
                  height: "fit-content",
                  width: responsive.sm ? "100%" : "",
                }}
              >
                {!responsive.sm && (
                  <ButtonLightGreen
                    className="me-3"
                    onClick={() => {
                      handlerCacheHub.save({
                        ...values,
                        telefone: values.telefone.numero,
                      });
                      onBack();
                    }}
                  >
                    Voltar
                  </ButtonLightGreen>
                )}
                <ButtonDarkGreen
                  disabled={!isValid || isSubmitting || isEditingPremiacoes}
                  isSubmit={true}
                  className={
                    (!responsive.sm ? "ms-2" : "w-100") + " text-uppercase"
                  }
                >
                  Avançar
                </ButtonDarkGreen>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export type InputPainelSobreHubMobile = {
  nome: string;
};

export type FormikValues = {
  sobre: string;
  email: string;
  nome_Hub: string;
  nit: { id: string; nome: string } | undefined;
  telefone: TypeInputTelefone;
} & Partial<{
  classificacao: ClassificacaoHub;
  cidade: CidadeOutputWithTipo;
  website: string; // TODO: VALIDATE URL
  linkedin: string; // TODO: VALIDATE URL
  premiacoes: PremiacaoOutput[];
}>;

export type OutputPainelSobreHubMobile = {
  sobre: string;
  email: string;
  nome_Hub: string;
  telefone: TypeInputTelefone;
} & Partial<{
  cidade: CidadeOutputWithTipo;
  website: string; // TODO: VALIDATE URL
  linkedin: string; // TODO: VALIDATE URL
  premiacoes: PremiacaoOutput[];
}>;

const validator = Yup.object().shape({
  cidade: Yup.object()
    .shape({
      cidade: Yup.string().required(),
      estado: Yup.string().required(),
      pais: Yup.string().required(),
      paisCode: Yup.string().required(),
    })
    .required("Cidade é obrigatório"),
  sobre: Yup.string()
    .required(
      "Campo obrigatório, deve ter no mínimo " +
        Sobre.MINIMO_LENGTH +
        " caracteres"
    )
    .min(Sobre.MINIMO_LENGTH, "Mínimo " + Sobre.MINIMO_LENGTH + " caracteres.")
    .max(
      Sobre.MAXIMO_LENGTH,
      "Máximo de " + Sobre.MAXIMO_LENGTH + " caracteres."
    ),
  nome_Hub: Yup.string()
    .required("Campo obrigatório")
    .min(1, "Campo obrigatório"),
  website: Yup.string().url("Deve ser um link").min(5, "Mínimo 5 caracteres"),
  telefone: Yup.object()
    .shape({
      numero: Yup.string().required(),
      invalid: Yup.boolean().isFalse(),
    })
    .required("Cidade é obrigatório"),
  linkedin: Yup.string().test((urlLinkedin) => {
    return urlLinkedin ? UrlLinkedin.isValidUrlLinkedin(urlLinkedin) : true;
  }),
  email: Yup.string().required("Campo obrigatório").email("Campo inválido"),
  premiacoes: Yup.array().of(
    Yup.object().shape({
      nome: Yup.string()
        .required("O nome é obrigatório")
        .min(
          Premiacao.NOME_MINIMO_LENGTH,
          "Mínimo " + Premiacao.NOME_MINIMO_LENGTH + " caracteres"
        )
        .max(
          Premiacao.NOME_MAXIMO_LENGTH,
          "Máximo " + Premiacao.NOME_MAXIMO_LENGTH + " caracteres"
        ),
      descricao: Yup.string().max(
        Premiacao.DESCRICAO_MAXIMO_LENGTH,
        "Máximo de " + Premiacao.DESCRICAO_MAXIMO_LENGTH + " caracteres."
      ),
    })
  ),
  nit: Yup.object()
    .shape({
      id: Yup.string().required(),
      nome: Yup.string().required(),
    })
    .default(undefined),
});
