import { createContext } from "react";

import { Mentor } from "../../domain/entities/Mentor";
import {
  InputHandlerUpdateMentorSemId,
  TypeReturnHandlerUpdateMentor,
} from "../../domain/usecases/interfaces/handlerUpdateMentor";
import { Result } from "../../typings";

export const PerfilMentorContext = createContext<{
  perfilMentorResult: TypePerfilMentorContext;
  refreshMentor: (() => void) | undefined;
  updateMentor:
    | ((input: InputHandlerUpdateMentorSemId) => TypeReturnHandlerUpdateMentor)
    | undefined;
}>({
  perfilMentorResult: undefined,
  refreshMentor: undefined,
  updateMentor: undefined,
});

export type TypePerfilMentorContext = Result<Readonly<Mentor>> | undefined;
