import { Firestore, doc, setDoc } from "@firebase/firestore";
import { FirebaseError } from "firebase/app";

import { Result } from "../typings";
import { HandlerCreateCientista } from "../domain/usecases/interfaces/handlerCreateCientista";
import { Cientista, CientistaInputEntity } from "../domain/entities/Cientista";
import {
  FirestoreErrorsFactory,
  FirestoreError,
} from "../domain/erros/FirestoreErrors";

export class HandlerCreateCientistaFirebase implements HandlerCreateCientista {
  constructor(private firestore: Firestore) {}
  async createCientista(
    inputCientista: CientistaInputEntity
  ): Promise<Result<CientistaInputEntity>> {
    const createCientistaResult = Cientista.create(inputCientista);

    if (!createCientistaResult.ok) {
      return createCientistaResult;
    }

    const cientistaToFirebase: CientistaInputEntity = {
      user_id: inputCientista.user_id,
      nome: inputCientista.nome,
      sobre: inputCientista.sobre,
      areas_de_conhecimento: inputCientista.areas_de_conhecimento,
      unidades: inputCientista.unidades,
      created_in: inputCientista.created_in,
    };

    if (createCientistaResult.value.grupos_de_pesquisa) {
      cientistaToFirebase.grupos_de_pesquisa =
        createCientistaResult.value.grupos_de_pesquisa;
    }

    if (createCientistaResult.value.premiacoes) {
      cientistaToFirebase.premiacoes = createCientistaResult.value.premiacoes;
    }

    if (createCientistaResult.value.tecnologias) {
      cientistaToFirebase.tecnologias = createCientistaResult.value.tecnologias;
    }

    return new Promise((resolve) => {
      const docPerfilCientista = doc(
        this.firestore,
        "usuarios",
        cientistaToFirebase.user_id,
        "perfis/cientista"
      );

      setDoc(docPerfilCientista, cientistaToFirebase, { merge: true })
        .then(() => {
          resolve({
            ok: true,
            value: cientistaToFirebase,
          });
        })
        .catch((error: FirebaseError) => {
          const instanciaErro = FirestoreErrorsFactory.createErrorsValidation(
            <FirestoreError>error.code
          );
          if (instanciaErro) {
            resolve({
              ok: false,
              error: instanciaErro,
            });
            return;
          }

          console.warn("Erro no cadastro: ", error.code, error.message);

          resolve({
            ok: false,
            error,
          });
        });
    });
  }
}
