import { Navigate } from "react-router-dom";

import { HandlerCreateStartupFirebase } from "../gateway/handlerCreateStartupFirebase";
import { HandlerCreateUsuarioScyggzFirebase } from "../gateway/handlerCreateUsuarioScyggzFirebase";
import { HandlerGetAreaAplicacaoTecnologiaFirebase } from "../gateway/HandlerGetAreaAplicacaoTecnologiaFirebase";
import { HandlerGetModeloNegocioFirebase } from "../gateway/handlerGetModeloNegocioFirebase";
import { HandlerGetTipoGrupoPesquisaFirebase } from "../gateway/handlerGetTipoGrupoPesquisaFirebase";
import { HandlerGetUnidadeFirebase } from "../gateway/handlerGetUnidadeFirebase";
import { HandlerSaveLogoStartupFirebase } from "../gateway/HandlerSaveLogoStartupFirebase";
import { HandlerSavePitchPdfStartupFirebase } from "../gateway/HandlerSavePitchPdfStartupFirebase";
import { HandlerSavePlanilhaStartupFirebase } from "../gateway/HandlerSavePlanilhaStartupFirebase";
import { HandlerSaveTelefoneFirebase } from "../gateway/handlerSaveTelefoneFirebase";
import { HandlerSaveUrlLinkedinFirebase } from "../gateway/handlerSaveUrlLinkedinFirebase";
import { getFirebaseStorage } from "../hooks/getFirebaseStorage";
import { getFirestore } from "../hooks/getFirestore";
import { PageBusca } from "../presentation/pages/busca/PageBusca";
import { ConexoesPendentesRecebidos } from "../presentation/pages/conexoes/pendentesRecebidos/ConexoesPendentesRecebidos";
import { Dashboard } from "../presentation/pages/dashboard/Dashboard";
import { User } from "../presentation/pages/user/User";
import { SubPageOgarnizacoes } from "../presentation/pages/user/SubPageOgarnizacoes";
import { HandlerCreateHubFirebase } from "../gateway/handlerCreateHubFirebase";
import { HandlerGetNitFirebase } from "../gateway/handlerGetNitFirebase";
import { HandlerSaveLogoNitFirebase } from "../gateway/HandlerSaveLogoNitFirebase";
import { HandlerCreateNitFirebase } from "../gateway/handlerCreateNitFirebase";
import { HandlerGetAreaInvestimentoFirebase } from "../gateway/HandlerGetAreaInvestimentoFirebase";
import { HandlerCreateVentureCapitalFirebase } from "../gateway/handlerCreateVentureCapitalFirebase";
import { HandlerSaveLogoVentureCapitalFirebase } from "../gateway/HandlerSaveLogoVentureCapitalFirebase";
import { HandlerCreateMentorFirebase } from "../gateway/HandlerCreateMentorFirebase";
import { PainelCadastroUsuarioInvestidor } from "../presentation/pages/cadastro/investidorAnjo/3/PainelCadastroUsuarioInvestidor";
import { PainelCadastroFotoUsuarioInvestidor } from "../presentation/pages/cadastro/investidorAnjo/4/PainelCadastroFotoUsuarioInvestidor";
import { HandlerGetURLFromPathStorage } from "../gateway/HandlerGetURLFromPathStorage";
import { PainelElegibilidadeInvestidor } from "../presentation/pages/cadastro/investidorAnjo/1/PainelElegibilidadeInvestidor";
import { PainelInvestidorCadastroAssociacaoAnjo } from "../presentation/pages/cadastro/investidorAnjo/5/PainelInvestidorCadastroAssociacaoAnjo";
import { HandlerCacheInvestidorStorage } from "../gateway/HandlerCacheInvestidorStorage";
import { PainelInvestidorCadastroInteresseStartup } from "../presentation/pages/cadastro/investidorAnjo/6/PainelInvestidorCadastroInteresseStartup";
import { HandlerUpdateAceiteConexaoFirebase } from "../gateway/HandlerUpdateAceiteConexaoFirebase";
import { PainelEscolhaPerfil } from "../presentation/pages/components/escolhaPerfil/PainelEscolhaPerfil";
import { PainelCadastroUsuarioStartup } from "../presentation/pages/cadastro/startup/3/PainelCadastroUsuarioStartup";
import { PainelCadastroFotoUsuarioStartup } from "../presentation/pages/cadastro/startup/4/PainelCadastroFotoUsuarioStartup";
import { PainelCadastroSobreStartup } from "../presentation/pages/cadastro/startup/5/PainelCadastroSobreStartup";
import { HandlerCacheStartupStorage } from "../gateway/HandlerCacheStartupStorage";
import { PainelLogoStartup } from "../presentation/pages/cadastro/startup/7/PainelLogoStartup";
import { PainelElegibilidadeCientista } from "../presentation/pages/cadastro/cientista/1/PainelElegibilidadeCientista";
import { RouteGuardRedirectIfHasPerfil } from "./RouteGuardRedirectIfHasPerfil";
import { PainelCadastroUsuarioCientista } from "../presentation/pages/cadastro/cientista/3/PainelCadastroUsuarioCientista";
import { RouteGuardAceite } from "./RouteGuardAceite";
import { HandlerCacheCientistaStorage } from "../gateway/HandlerCacheCientistaStorage";
import { RouteGuardWithUsuario } from "./RouteGuardWithUsuario";
import { PainelCadastroFotoUsuarioCientista } from "../presentation/pages/cadastro/cientista/4/PainelCadastroFotoUsuarioCientista";
import { PainelSobreCientista } from "../presentation/pages/cadastro/cientista/5/PainelSobreCientista";
import { RouteGuardHasCache } from "./RouteGuardHasCache";
import { PainelElegibilidadeMentor } from "../presentation/pages/cadastro/Mentor/1/PainelElegibilidadeMentor";
import { PainelCadastroUsuarioMentor } from "../presentation/pages/cadastro/Mentor/3/PainelCadastroUsuarioMentor";
import { PainelCadastroFotoUsuarioMentor } from "../presentation/pages/cadastro/Mentor/4/PainelCadastroFotoUsuarioMentor";
import { HandlerCacheMentorStorage } from "../gateway/HandlerCacheMentorStorage";
import { PainelSobreMentor } from "../presentation/pages/cadastro/Mentor/5/PainelSobreMentor";
import { PainelCadastroInteressementoriaMentor } from "../presentation/pages/cadastro/Mentor/6/PainelCadastroInteressementoriaMentor";
import { PainelElegibilidadeHub } from "../presentation/pages/cadastro/hub/1/PainelElegibilidadeHub";
import { PainelCadastroUsuarioHub } from "../presentation/pages/cadastro/hub/3/PainelCadastroUsuarioHub";
import { PainelCadastroFotoUsuarioHub } from "../presentation/pages/cadastro/hub/4/PainelCadastroFotoUsuarioHub";
import { PainelCadastroSobreHub } from "../presentation/pages/cadastro/hub/5/PainelCadastroSobreHub";
import { HandlerCacheHubStorage } from "../gateway/HandlerCacheHubStorage";
import { PainelLogoHub } from "../presentation/pages/cadastro/hub/6/PainelLogoHub";
import { HandlerSaveLogoHubFirebase } from "../gateway/HandlerSaveLogoHubFirebase";
import { PainelHub } from "../presentation/pages/hub/PainelHub";
import { PainelElegibilidadeStartup } from "../presentation/pages/cadastro/startup/1/PainelElegibilidadeStartup";
import { PainelMaisSobreStartup } from "../presentation/pages/cadastro/startup/6/PainelMaisSobreStartup";
import { PainelElegibilidadeNit } from "../presentation/pages/cadastro/nit/1/PainelElegibilidadeNit";
import { PainelCadastroUsuarioNit } from "../presentation/pages/cadastro/nit/3/PainelCadastroUsuarioNit";
import { PainelCadastroFotoUsuarioNit } from "../presentation/pages/cadastro/nit/4/PainelCadastroFotoUsuarioNit";
import { PainelCadastroSobreNit } from "../presentation/pages/cadastro/nit/5/PainelCadastroSobreNit";
import { HandlerCacheNitStorage } from "../gateway/HandlerCacheNitStorage";
import { PainelLogoNit } from "../presentation/pages/cadastro/nit/6/PainelLogoNit";
import { PainelStartup } from "../presentation/pages/startup/PainelStartup";
import { PainelNit } from "../presentation/pages/nit/PainelNit";
import { PainelEmpresa } from "../presentation/pages/empresa/PainelEmpresa";
import { PainelElegibilidadeEmpresa } from "../presentation/pages/cadastro/empresa/1/PainelElegibilidadeEmpresa";
import { PainelCadastroUsuarioEmpresa } from "../presentation/pages/cadastro/empresa/3/PainelCadastroUsuarioEmpresa";
import { PainelCadastroFotoUsuarioEmpresa } from "../presentation/pages/cadastro/empresa/4/PainelCadastroFotoUsuarioEmpresa";
import { PainelSobreEmpresa } from "../presentation/pages/cadastro/empresa/5/PainelSobreEmpresa";
import { HandlerCacheEmpresaStorage } from "../gateway/HandlerCacheEmpresaStorage";
import { HandlerGetSetorAtuacaoFirebase } from "../gateway/handlerGetSetorAtuacaoFirebase";
import { HandlerGetInteresseInovacaoFirebase } from "../gateway/HandlerGetInteresseInovacaoFirebase";
import { HandlerCreateEmpresaFirebase } from "../gateway/HandlerCreateEmpresaFirebase";
import { PainelLogoEmpresa } from "../presentation/pages/cadastro/empresa/6/PainelLogoEmpresa";
import { HandlerSaveLogoEmpresaFirebase } from "../gateway/HandlerSaveLogoEmpresaFirebase";
import { PainelInteresseCientistaEmpresa } from "../presentation/pages/cadastro/empresa/7/PainelInteresseCientistaEmpresa";
import { HandlerUpdateEmpresaFirebase } from "../gateway/HandlerUpdateEmpresaFirebase";
import { PainelInteresseStatupsEmpresa } from "../presentation/pages/cadastro/empresa/8/PainelInteresseStatupsEmpresa";
import { PainelVentureCapital } from "../presentation/pages/venture_capital/PainelVentureCapital";
import { PainelElegibilidadeVentureCapital } from "../presentation/pages/cadastro/ventureCapital/1/PainelElegibilidadeVentureCapital";
import { PainelCadastroUsuarioVentureCapital } from "../presentation/pages/cadastro/ventureCapital/3/PainelCadastroUsuarioVentureCapital";
import { PainelCadastroFotoUsuarioVentureCapital } from "../presentation/pages/cadastro/ventureCapital/4/PainelCadastroFotoUsuarioVentureCapital";
import { PainelSobreVentureCapital } from "../presentation/pages/cadastro/ventureCapital/5/PainelSobreVentureCapital";
import { HandlerCacheVentureCapitalStorage } from "../gateway/HandlerCacheVentureCapitalStorage";
import { PainelLogoVentureCapital } from "../presentation/pages/cadastro/ventureCapital/7/PainelLogoVentureCapital";
import { PainelTeseDeInvestimentoVentureCapital } from "../presentation/pages/cadastro/ventureCapital/6/PainelTeseDeInvestimentoVentureCapital";
import { ConexoesAprovadas } from "../presentation/pages/conexoes/aprovadas/ConexoesAprovadas";
import { ConexoesPendentesEnviados } from "../presentation/pages/conexoes/pendentesEnviados/ConexoesPendentesEnviados";
import { RouteGuardRedirectIfHasNotPermission } from "./RouteGuardRedirectIfHasNotPermission";
import { Admin } from "../presentation/pages/admin/Admin";
import { ProgramaDeConexaoPage } from "../presentation/pages/programaDeConexao/ProgramaDeConexaoPage";
import { NewProgramaDeConexaoPage } from "../presentation/pages/programaDeConexao/new/NewProgramaDeConexaoPage";
import { MyProgramasDeConexaoPage } from "../presentation/pages/programaDeConexao/my/MyProgramasDeConexaoPage";
import { ProgramaDeConexao } from "../presentation/pages/programaDeConexao/programa/ProgramaDeConexao";
import { ConexoesPage } from "../presentation/pages/conexoes/ConexoesPage";
import { UserPage } from "../presentation/pages/user/UserPage";
import { HandlerCacheNewUserStorage } from "../gateway/HandlerCacheNewUserStorage";

const firestore = getFirestore();
const firebaseStorage = getFirebaseStorage();

const handlerSaveLogoStartup = new HandlerSaveLogoStartupFirebase(
  firebaseStorage,
  firestore
);
const handlerSavePitchPdfStartup = new HandlerSavePitchPdfStartupFirebase(
  firebaseStorage,
  firestore
);
const handlerSavePlanilhaStartup = new HandlerSavePlanilhaStartupFirebase(
  firebaseStorage,
  firestore
);
const handlerSaveUrlLinkedin = new HandlerSaveUrlLinkedinFirebase(firestore);
const handlerSaveTelefone = new HandlerSaveTelefoneFirebase(firestore);
const handlerCreateUsuarioScyggz = new HandlerCreateUsuarioScyggzFirebase(
  firestore
);
const handlerGetTipoGrupoPesquisa = new HandlerGetTipoGrupoPesquisaFirebase(
  firestore
);
const handlerGetAreaAplicacaoTecnologia =
  new HandlerGetAreaAplicacaoTecnologiaFirebase(firestore);
const handlerCreateStartup = new HandlerCreateStartupFirebase(firestore);
const handlerGetModeloNegocio = new HandlerGetModeloNegocioFirebase(firestore);
const handlerGetUnidade = new HandlerGetUnidadeFirebase(firestore);
const handlerCreateHub = new HandlerCreateHubFirebase(firestore);
const handlerGetNit = new HandlerGetNitFirebase(firestore);

const handlerSaveLogoNit = new HandlerSaveLogoNitFirebase(
  firebaseStorage,
  firestore
);
const handlerGetAreaInvestimento = new HandlerGetAreaInvestimentoFirebase(
  firestore
);
const handlerCreateVentureCapital = new HandlerCreateVentureCapitalFirebase(
  firestore
);
const handlerSaveLogoVentureCapital = new HandlerSaveLogoVentureCapitalFirebase(
  firebaseStorage,
  firestore
);
const handlerGetURLFromPath = new HandlerGetURLFromPathStorage(firebaseStorage);

const handlerCreateNit = new HandlerCreateNitFirebase(firestore);
const handlerCreateMentor = new HandlerCreateMentorFirebase(firestore);
const handlerCacheInvestidor = new HandlerCacheInvestidorStorage();
const handlerCacheStartup = new HandlerCacheStartupStorage();
const handlerUpdateAceiteConexao = new HandlerUpdateAceiteConexaoFirebase(
  firestore
);
const handlerUpdateEmpresa = new HandlerUpdateEmpresaFirebase(firestore);
const handlerGetSetorAtuacao = new HandlerGetSetorAtuacaoFirebase(firestore);
const handlerSaveLogoEmpresa = new HandlerSaveLogoEmpresaFirebase(
  firebaseStorage,
  firestore
);
const handlerGetInteresseInovacao = new HandlerGetInteresseInovacaoFirebase(
  firestore
);
const handlerCreateEmpresa = new HandlerCreateEmpresaFirebase(firestore);
const handlerSaveLogoHub = new HandlerSaveLogoHubFirebase(
  firebaseStorage,
  firestore
);
const handlerCacheCientista = new HandlerCacheCientistaStorage();
const handlerCacheMentor = new HandlerCacheMentorStorage();
const handlerCacheHub = new HandlerCacheHubStorage();
const handlerCacheNit = new HandlerCacheNitStorage();
const handlerCacheEmpresa = new HandlerCacheEmpresaStorage();
const handlerCacheVentureCapital = new HandlerCacheVentureCapitalStorage();

const handlerCacheNewUser = new HandlerCacheNewUserStorage();

export const rotasNotVisitante = [
  {
    path: "/",
    element: (
      <Dashboard handlerUpdateAceiteConexao={handlerUpdateAceiteConexao} />
    ),
    showHeader: true,
    showFooter: true,
  },
  {
    path: "/admin",
    element: (
      <RouteGuardRedirectIfHasNotPermission
        permission={"isAdmin"}
        redirectPath={"/"}
        children={<Admin />}
      />
    ),
    showHeader: true,
    showFooter: true,
  },
  {
    path: "/programa_conexao/my",
    element: <MyProgramasDeConexaoPage />,
    showHeader: true,
    showFooter: true,
  },
  {
    path: "/programa_conexao/new",
    element: <NewProgramaDeConexaoPage />,
    showHeader: true,
    showFooter: true,
  },
  {
    path: "/programa_conexao/:id_programa/:aba_programa/:id_user/:id_perfil",
    element: <ProgramaDeConexao />,
    showHeader: true,
    showFooter: true,
  },
  {
    path: "/programa_conexao/:id_programa/:aba_programa/:id_user",
    element: <ProgramaDeConexao />,
    showHeader: true,
    showFooter: true,
  },
  {
    path: "/programa_conexao/:id_programa/:aba_programa",
    element: <ProgramaDeConexao />,
    showHeader: true,
    showFooter: true,
  },
  {
    path: "/programa_conexao/:id_programa",
    element: <ProgramaDeConexao />,
    showHeader: true,
    showFooter: true,
  },
  {
    path: "/programa_conexao",
    element: <ProgramaDeConexaoPage />,
    showHeader: true,
    showFooter: true,
  },
  {
    path: "/user",
    element: <User />,
    showHeader: true,
    showFooter: true,
  },
  {
    path: "/user/:id_user",
    element: <User />,
    showHeader: true,
    showFooter: true,
  },
  {
    path: "/user/:id_user/:id_perfil",
    element: <User />,
    showHeader: true,
    showFooter: true,
  },
  {
    path: "/user/:id_user/organizacoes",
    element: <SubPageOgarnizacoes />,
    showHeader: true,
    showFooter: true,
  },
  {
    path: "/user2/:id_user/:id_perfil",
    element: (
      <div className="p-3">
        <UserPage />
      </div>
    ),
    showHeader: true,
    showFooter: true,
  },
  {
    path: "/user2/:id_user",
    element: (
      <div className="p-3">
        <UserPage />
      </div>
    ),
    showHeader: true,
    showFooter: true,
  },
  {
    path: "/user2",
    element: (
      <div className="p-3">
        <UserPage />
      </div>
    ),
    showHeader: true,
    showFooter: true,
  },
  {
    path: "/conexoes/aprovadas",
    element: <ConexoesAprovadas />,
    showHeader: true,
    showFooter: true,
  },
  {
    path: "/conexoes/pendentes/recebidos",
    element: <ConexoesPendentesRecebidos />,
    showHeader: true,
    showFooter: true,
  },
  {
    path: "/conexoes/pendentes/enviados",
    element: <ConexoesPendentesEnviados />,
    showHeader: true,
    showFooter: true,
  },
  {
    path: "/conexoes",
    element: <ConexoesPage />,
    showHeader: true,
    showFooter: true,
  },
  {
    path: "/cadastro",
    element: <PainelEscolhaPerfil />,
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/cadastro/cientista/1",
    element: (
      <RouteGuardRedirectIfHasPerfil perfil="cientista" redirectPath="/">
        <PainelElegibilidadeCientista />
      </RouteGuardRedirectIfHasPerfil>
    ),
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/cadastro/cientista/2",
    element: (
      <RouteGuardRedirectIfHasPerfil perfil="cientista" redirectPath="/">
        <Navigate to={"/cadastro/cientista/3"} />
      </RouteGuardRedirectIfHasPerfil>
    ),
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/cadastro/cientista/3",
    element: (
      <RouteGuardAceite
        required="cientista"
        redirectPath="/cadastro/cientista/1"
      >
        <PainelCadastroUsuarioCientista
          handlerSaveTelefone={handlerSaveTelefone}
          handlerCreateUsuarioScyggz={handlerCreateUsuarioScyggz}
        />
      </RouteGuardAceite>
    ),
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/cadastro/cientista/4",
    element: (
      <RouteGuardWithUsuario withoutUsuarioPath="/cadastro/cientista/3">
        <RouteGuardRedirectIfHasPerfil perfil={"cientista"} redirectPath={"/"}>
          <PainelCadastroFotoUsuarioCientista />
        </RouteGuardRedirectIfHasPerfil>
      </RouteGuardWithUsuario>
    ),
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/cadastro/cientista/5",
    element: (
      <RouteGuardRedirectIfHasPerfil perfil={"cientista"} redirectPath={"/"}>
        <RouteGuardWithUsuario withoutUsuarioPath="/cadastro/cientista/3">
          <PainelSobreCientista handlerCacheCientista={handlerCacheCientista} />
        </RouteGuardWithUsuario>
      </RouteGuardRedirectIfHasPerfil>
    ),
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/cadastro/investidor/1",
    element: (
      <RouteGuardRedirectIfHasPerfil perfil="investidor" redirectPath="/">
        <PainelElegibilidadeInvestidor />
      </RouteGuardRedirectIfHasPerfil>
    ),
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/investidor/2",
    element: (
      <RouteGuardRedirectIfHasPerfil perfil="investidor" redirectPath="/">
        <Navigate to={"/cadastro/investidor/3"} />
      </RouteGuardRedirectIfHasPerfil>
    ),
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/investidor/3",
    element: (
      <RouteGuardAceite
        required="investidor"
        redirectPath="/cadastro/investidor/1"
      >
        <PainelCadastroUsuarioInvestidor
          handlerSaveUrlLinkedin={handlerSaveUrlLinkedin}
          handlerSaveTelefone={handlerSaveTelefone}
          handlerCreateUsuarioScyggz={handlerCreateUsuarioScyggz}
        />
      </RouteGuardAceite>
    ),
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/investidor/4",
    element: (
      <RouteGuardWithUsuario withoutUsuarioPath="/cadastro/investidor/3">
        <PainelCadastroFotoUsuarioInvestidor />
      </RouteGuardWithUsuario>
    ),
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/investidor/5",
    element: (
      <RouteGuardWithUsuario withoutUsuarioPath="/cadastro/investidor/3">
        <PainelInvestidorCadastroAssociacaoAnjo
          handlerCacheInvestidor={handlerCacheInvestidor}
        />
      </RouteGuardWithUsuario>
    ),
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/cadastro/investidor/6",
    element: (
      <RouteGuardWithUsuario withoutUsuarioPath="/cadastro/investidor/3">
        <RouteGuardRedirectIfHasPerfil perfil="investidor" redirectPath="/">
          <RouteGuardHasCache
            redirectPath={"/cadastro/investidor/4"}
            requireds={["areas_de_especializacao"]}
            handlerCache={handlerCacheInvestidor}
          >
            <PainelInvestidorCadastroInteresseStartup
              handlerCacheInvestidor={handlerCacheInvestidor}
            />
          </RouteGuardHasCache>
        </RouteGuardRedirectIfHasPerfil>
      </RouteGuardWithUsuario>
    ),
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/cadastro/mentor/1",
    element: (
      <RouteGuardRedirectIfHasPerfil perfil="mentor" redirectPath="/">
        <PainelElegibilidadeMentor />
      </RouteGuardRedirectIfHasPerfil>
    ),
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/mentor/2",
    element: (
      <RouteGuardRedirectIfHasPerfil perfil="mentor" redirectPath="/">
        <Navigate to={"/cadastro/mentor/3"} />
      </RouteGuardRedirectIfHasPerfil>
    ),
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/mentor/3",
    element: (
      <RouteGuardAceite required="mentor" redirectPath="/cadastro/mentor/1">
        <PainelCadastroUsuarioMentor
          handlerSaveUrlLinkedin={handlerSaveUrlLinkedin}
          handlerSaveTelefone={handlerSaveTelefone}
          handlerCreateUsuarioScyggz={handlerCreateUsuarioScyggz}
        />
      </RouteGuardAceite>
    ),
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/mentor/4",
    element: (
      <RouteGuardWithUsuario withoutUsuarioPath="/cadastro/mentor/3">
        <PainelCadastroFotoUsuarioMentor />
      </RouteGuardWithUsuario>
    ),
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/mentor/5",
    element: (
      <RouteGuardWithUsuario withoutUsuarioPath="/cadastro/mentor/3">
        <PainelSobreMentor handlerCacheMentor={handlerCacheMentor} />
      </RouteGuardWithUsuario>
    ),
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/cadastro/mentor/6",
    element: (
      <RouteGuardWithUsuario withoutUsuarioPath="/cadastro/mentor/3">
        <RouteGuardRedirectIfHasPerfil perfil="mentor" redirectPath="/">
          <RouteGuardHasCache
            redirectPath={"/cadastro/mentor/4"}
            requireds={["sobre_o_mentor"]}
            handlerCache={handlerCacheMentor}
          >
            <PainelCadastroInteressementoriaMentor
              handlerCacheMentor={handlerCacheMentor}
              handlerCreateMentor={handlerCreateMentor}
            />
          </RouteGuardHasCache>
        </RouteGuardRedirectIfHasPerfil>
      </RouteGuardWithUsuario>
    ),
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/hub/:id_hub",
    element: <PainelHub />,
    showHeader: true,
    showFooter: true,
  },

  {
    path: "/cadastro/empresa/1",
    element: (
      <RouteGuardRedirectIfHasPerfil perfil="mentor" redirectPath="/">
        <RouteGuardRedirectIfHasPerfil perfil="cientista" redirectPath="/">
          <RouteGuardRedirectIfHasPerfil perfil="investidor" redirectPath="/">
            <PainelElegibilidadeEmpresa />
          </RouteGuardRedirectIfHasPerfil>
        </RouteGuardRedirectIfHasPerfil>
      </RouteGuardRedirectIfHasPerfil>
    ),
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/cadastro/empresa/2",
    element: (
      <RouteGuardRedirectIfHasPerfil perfil="cientista" redirectPath="/">
        <RouteGuardRedirectIfHasPerfil perfil="mentor" redirectPath="/">
          <RouteGuardRedirectIfHasPerfil perfil="investidor" redirectPath="/">
            <Navigate to={"/cadastro/empresa/3"} />
          </RouteGuardRedirectIfHasPerfil>
        </RouteGuardRedirectIfHasPerfil>
      </RouteGuardRedirectIfHasPerfil>
    ),
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/empresa/3",
    element: (
      <RouteGuardAceite required="empresa" redirectPath="/cadastro/empresa/1">
        <PainelCadastroUsuarioEmpresa
          handlerSaveUrlLinkedin={handlerSaveUrlLinkedin}
          handlerSaveTelefone={handlerSaveTelefone}
          handlerCreateUsuarioScyggz={handlerCreateUsuarioScyggz}
        />
      </RouteGuardAceite>
    ),
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/empresa/4",
    element: (
      <RouteGuardWithUsuario withoutUsuarioPath="/cadastro/empresa/3">
        <PainelCadastroFotoUsuarioEmpresa />
      </RouteGuardWithUsuario>
    ),
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/empresa/5",
    element: (
      <RouteGuardWithUsuario withoutUsuarioPath="/cadastro/empresa/3">
        <PainelSobreEmpresa
          handlerCacheEmpresa={handlerCacheEmpresa}
          handlerGetSetorAtuacao={handlerGetSetorAtuacao}
          handlerGetInteresseInovacao={handlerGetInteresseInovacao}
          handlerCreateEmpresa={handlerCreateEmpresa}
        />
      </RouteGuardWithUsuario>
    ),
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/cadastro/empresa/6",
    element: (
      <RouteGuardWithUsuario withoutUsuarioPath="/cadastro/empresa/3">
        <RouteGuardHasCache
          redirectPath={"/cadastro/empresa/5"}
          requireds={["id", "nome"]}
          handlerCache={handlerCacheEmpresa}
        >
          <PainelLogoEmpresa
            handlerSaveLogoEmpresa={handlerSaveLogoEmpresa}
            handlerGetURLFromPath={handlerGetURLFromPath}
            handlerCacheEmpresa={handlerCacheEmpresa}
          />
        </RouteGuardHasCache>
      </RouteGuardWithUsuario>
    ),
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/cadastro/empresa/7",
    element: (
      <RouteGuardWithUsuario withoutUsuarioPath="/cadastro/empresa/3">
        <RouteGuardHasCache
          redirectPath={"/cadastro/empresa/5"}
          requireds={["id", "nome"]}
          handlerCache={handlerCacheEmpresa}
        >
          <PainelInteresseCientistaEmpresa
            handlerCacheEmpresa={handlerCacheEmpresa}
            handlerUpdateEmpresa={handlerUpdateEmpresa}
            handlerGetAreaAplicacaoTecnologia={
              handlerGetAreaAplicacaoTecnologia
            }
            handlerGetTipoGrupoPesquisa={handlerGetTipoGrupoPesquisa}
          />
        </RouteGuardHasCache>
      </RouteGuardWithUsuario>
    ),
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/cadastro/empresa/8",
    element: (
      <RouteGuardWithUsuario withoutUsuarioPath="/cadastro/empresa/3">
        <RouteGuardHasCache
          redirectPath={"/cadastro/empresa/5"}
          requireds={["id", "nome"]}
          handlerCache={handlerCacheEmpresa}
        >
          <PainelInteresseStatupsEmpresa
            handlerCacheEmpresa={handlerCacheEmpresa}
            handlerUpdateEmpresa={handlerUpdateEmpresa}
            handlerGetModeloNegocio={handlerGetModeloNegocio}
          />
        </RouteGuardHasCache>
      </RouteGuardWithUsuario>
    ),
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/cadastro/hub/1",
    element: (
      <RouteGuardRedirectIfHasPerfil perfil="mentor" redirectPath="/">
        <RouteGuardRedirectIfHasPerfil perfil="cientista" redirectPath="/">
          <RouteGuardRedirectIfHasPerfil perfil="investidor" redirectPath="/">
            <PainelElegibilidadeHub />
          </RouteGuardRedirectIfHasPerfil>
        </RouteGuardRedirectIfHasPerfil>
      </RouteGuardRedirectIfHasPerfil>
    ),
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/cadastro/hub/2",
    element: (
      <RouteGuardRedirectIfHasPerfil perfil="cientista" redirectPath="/">
        <RouteGuardRedirectIfHasPerfil perfil="mentor" redirectPath="/">
          <RouteGuardRedirectIfHasPerfil perfil="investidor" redirectPath="/">
            <Navigate to={"/cadastro/hub/3"} />
          </RouteGuardRedirectIfHasPerfil>
        </RouteGuardRedirectIfHasPerfil>
      </RouteGuardRedirectIfHasPerfil>
    ),
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/hub/3",
    element: (
      <RouteGuardAceite required="hub" redirectPath="/cadastro/hub/1">
        <PainelCadastroUsuarioHub
          handlerSaveUrlLinkedin={handlerSaveUrlLinkedin}
          handlerSaveTelefone={handlerSaveTelefone}
          handlerCreateUsuarioScyggz={handlerCreateUsuarioScyggz}
        />
      </RouteGuardAceite>
    ),
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/hub/4",
    element: (
      <RouteGuardWithUsuario withoutUsuarioPath="/cadastro/hub/3">
        <PainelCadastroFotoUsuarioHub />
      </RouteGuardWithUsuario>
    ),
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/hub/5",
    element: (
      <RouteGuardWithUsuario withoutUsuarioPath="/cadastro/hub/3">
        <PainelCadastroSobreHub
          handlerCacheHub={handlerCacheHub}
          handlerGetNit={handlerGetNit}
          handlerCreateHub={handlerCreateHub}
        />
      </RouteGuardWithUsuario>
    ),
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/cadastro/hub/6",
    element: (
      <RouteGuardWithUsuario withoutUsuarioPath="/cadastro/hub/3">
        <RouteGuardHasCache
          redirectPath={"/cadastro/hub/5"}
          requireds={["id", "nome"]}
          handlerCache={handlerCacheHub}
        >
          <PainelLogoHub
            handlerSaveLogoHub={handlerSaveLogoHub}
            handlerGetURLFromPath={handlerGetURLFromPath}
            handlerCacheHub={handlerCacheHub}
          />
        </RouteGuardHasCache>
      </RouteGuardWithUsuario>
    ),
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/login",
    element: <Navigate to={"/"} />,
    showHeader: true,
    showFooter: true,
  },
  {
    path: "/busca",
    element: <PageBusca />,
    showHeader: true,
    showFooter: true,
  },
  {
    path: "/busca/:id_perfil",
    element: <PageBusca />,
    showHeader: true,
    showFooter: true,
  },
  {
    path: "/startup/:id_startup",
    element: <PainelStartup handlerSaveLogoStartup={handlerSaveLogoStartup} />,
    showHeader: true,
    showFooter: true,
  },
  {
    path: "/startup/:id_startup/:subpage",
    element: <PainelStartup handlerSaveLogoStartup={handlerSaveLogoStartup} />,
    showHeader: true,
    showFooter: true,
  },
  {
    path: "/cadastro/startup/1",
    element: (
      <RouteGuardRedirectIfHasPerfil perfil="mentor" redirectPath="/">
        <RouteGuardRedirectIfHasPerfil perfil="cientista" redirectPath="/">
          <RouteGuardRedirectIfHasPerfil perfil="investidor" redirectPath="/">
            <PainelElegibilidadeStartup
              handlerCacheNewUser={handlerCacheNewUser}
            />
          </RouteGuardRedirectIfHasPerfil>
        </RouteGuardRedirectIfHasPerfil>
      </RouteGuardRedirectIfHasPerfil>
    ),
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/cadastro/startup/2",
    element: (
      <RouteGuardRedirectIfHasPerfil perfil="cientista" redirectPath="/">
        <RouteGuardRedirectIfHasPerfil perfil="mentor" redirectPath="/">
          <RouteGuardRedirectIfHasPerfil perfil="investidor" redirectPath="/">
            <Navigate to={"/cadastro/startup/3"} />
          </RouteGuardRedirectIfHasPerfil>
        </RouteGuardRedirectIfHasPerfil>
      </RouteGuardRedirectIfHasPerfil>
    ),
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/startup/3",
    element: (
      <RouteGuardAceite required="startup" redirectPath="/cadastro/startup/1">
        <PainelCadastroUsuarioStartup
          handlerCacheNewUser={handlerCacheNewUser}
        />
      </RouteGuardAceite>
    ),
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/startup/4",
    element: (
      <RouteGuardWithUsuario
        isOrganizacao={true}
        withoutUsuarioPath="/cadastro/startup/3"
      >
        <PainelCadastroFotoUsuarioStartup />
      </RouteGuardWithUsuario>
    ),
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/startup/5",
    element: (
      <RouteGuardWithUsuario
        isOrganizacao={true}
        withoutUsuarioPath="/cadastro/startup/3"
      >
        <PainelCadastroSobreStartup handlerCacheStartup={handlerCacheStartup} />
      </RouteGuardWithUsuario>
    ),
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/cadastro/startup/6",
    element: (
      <RouteGuardWithUsuario
        isOrganizacao={true}
        withoutUsuarioPath="/cadastro/startup/3"
      >
        <RouteGuardHasCache
          redirectPath={"/cadastro/startup/5"}
          requireds={["sobre", "nome", "ano_fundacao", "cidade", "email"]}
          handlerCache={handlerCacheStartup}
        >
          <PainelMaisSobreStartup
            handlerCreateStartup={handlerCreateStartup}
            handlerCacheStartup={handlerCacheStartup}
            handlerSavePitchPdfStartup={handlerSavePitchPdfStartup}
            handlerSavePlanilhaStartup={handlerSavePlanilhaStartup}
          />
        </RouteGuardHasCache>
      </RouteGuardWithUsuario>
    ),
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/cadastro/startup/7",
    element: (
      <RouteGuardWithUsuario
        isOrganizacao={true}
        withoutUsuarioPath="/cadastro/startup/3"
      >
        <RouteGuardHasCache
          redirectPath={"/cadastro/startup/6"}
          requireds={["id"]}
          handlerCache={handlerCacheStartup}
        >
          <PainelLogoStartup
            handlerSaveLogoStartup={handlerSaveLogoStartup}
            handlerGetURLFromPath={handlerGetURLFromPath}
            handlerCacheStartup={handlerCacheStartup}
          />
        </RouteGuardHasCache>
      </RouteGuardWithUsuario>
    ),
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/empresa/:id_empresa",
    element: <PainelEmpresa />,
    showHeader: true,
    showFooter: true,
  },
  {
    path: "/nit/:id_nit",
    element: <PainelNit />,
    showHeader: true,
    showFooter: true,
  },
  {
    path: "/cadastro/nit/1",
    element: (
      <RouteGuardRedirectIfHasPerfil perfil="mentor" redirectPath="/">
        <RouteGuardRedirectIfHasPerfil perfil="cientista" redirectPath="/">
          <RouteGuardRedirectIfHasPerfil perfil="investidor" redirectPath="/">
            <PainelElegibilidadeNit />
          </RouteGuardRedirectIfHasPerfil>
        </RouteGuardRedirectIfHasPerfil>
      </RouteGuardRedirectIfHasPerfil>
    ),
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/cadastro/nit/2",
    element: (
      <RouteGuardRedirectIfHasPerfil perfil="cientista" redirectPath="/">
        <RouteGuardRedirectIfHasPerfil perfil="mentor" redirectPath="/">
          <RouteGuardRedirectIfHasPerfil perfil="investidor" redirectPath="/">
            <Navigate to={"/cadastro/nit/3"} />
          </RouteGuardRedirectIfHasPerfil>
        </RouteGuardRedirectIfHasPerfil>
      </RouteGuardRedirectIfHasPerfil>
    ),
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/nit/3",
    element: (
      <RouteGuardAceite required="nit" redirectPath="/cadastro/nit/1">
        <PainelCadastroUsuarioNit
          handlerSaveUrlLinkedin={handlerSaveUrlLinkedin}
          handlerSaveTelefone={handlerSaveTelefone}
          handlerCreateUsuarioScyggz={handlerCreateUsuarioScyggz}
        />
      </RouteGuardAceite>
    ),
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/nit/4",
    element: (
      <RouteGuardWithUsuario withoutUsuarioPath="/cadastro/nit/3">
        <PainelCadastroFotoUsuarioNit />
      </RouteGuardWithUsuario>
    ),
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/nit/5",
    element: (
      <RouteGuardWithUsuario withoutUsuarioPath="/cadastro/nit/3">
        <PainelCadastroSobreNit
          handlerCacheNit={handlerCacheNit}
          handlerGetUnidade={handlerGetUnidade}
          handlerCreateNit={handlerCreateNit}
        />
      </RouteGuardWithUsuario>
    ),
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/cadastro/nit/6",
    element: (
      <RouteGuardWithUsuario withoutUsuarioPath="/cadastro/nit/3">
        <RouteGuardHasCache
          redirectPath={"/cadastro/nit/5"}
          requireds={["id", "nome"]}
          handlerCache={handlerCacheNit}
        >
          <PainelLogoNit
            handlerSaveLogoNit={handlerSaveLogoNit}
            handlerGetURLFromPath={handlerGetURLFromPath}
            handlerCacheNit={handlerCacheNit}
          />
        </RouteGuardHasCache>
      </RouteGuardWithUsuario>
    ),
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/venture_capital/:id_venture_capital",
    element: <PainelVentureCapital />,
    showHeader: true,
    showFooter: true,
  },
  {
    path: "/cadastro/venture_capital/1",
    element: (
      <RouteGuardRedirectIfHasPerfil perfil="mentor" redirectPath="/">
        <RouteGuardRedirectIfHasPerfil perfil="cientista" redirectPath="/">
          <RouteGuardRedirectIfHasPerfil perfil="investidor" redirectPath="/">
            <PainelElegibilidadeVentureCapital />
          </RouteGuardRedirectIfHasPerfil>
        </RouteGuardRedirectIfHasPerfil>
      </RouteGuardRedirectIfHasPerfil>
    ),
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/cadastro/venture_capital/2",
    element: (
      <RouteGuardRedirectIfHasPerfil perfil="cientista" redirectPath="/">
        <RouteGuardRedirectIfHasPerfil perfil="mentor" redirectPath="/">
          <RouteGuardRedirectIfHasPerfil perfil="investidor" redirectPath="/">
            <Navigate to={"/cadastro/venture_capital/3"} />
          </RouteGuardRedirectIfHasPerfil>
        </RouteGuardRedirectIfHasPerfil>
      </RouteGuardRedirectIfHasPerfil>
    ),
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/venture_capital/3",
    element: (
      <RouteGuardAceite
        required="vc"
        redirectPath="/cadastro/venture_capital/1"
      >
        <PainelCadastroUsuarioVentureCapital
          handlerSaveUrlLinkedin={handlerSaveUrlLinkedin}
          handlerSaveTelefone={handlerSaveTelefone}
          handlerCreateUsuarioScyggz={handlerCreateUsuarioScyggz}
        />
      </RouteGuardAceite>
    ),
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/venture_capital/4",
    element: (
      <RouteGuardWithUsuario withoutUsuarioPath="/cadastro/venture_capital/3">
        <PainelCadastroFotoUsuarioVentureCapital />
      </RouteGuardWithUsuario>
    ),
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/venture_capital/5",
    element: (
      <RouteGuardWithUsuario withoutUsuarioPath="/cadastro/venture_capital/3">
        <PainelSobreVentureCapital
          handlerCacheVentureCapital={handlerCacheVentureCapital}
          handlerGetAreaInvestimento={handlerGetAreaInvestimento}
        />
      </RouteGuardWithUsuario>
    ),
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/cadastro/venture_capital/6",
    element: (
      <RouteGuardWithUsuario withoutUsuarioPath="/cadastro/venture_capital/3">
        <RouteGuardHasCache
          redirectPath={"/cadastro/venture_capital/5"}
          requireds={["nome", "email", "telefone", "areas_de_investimento"]}
          handlerCache={handlerCacheVentureCapital}
        >
          <PainelTeseDeInvestimentoVentureCapital
            handlerCacheVentureCapital={handlerCacheVentureCapital}
            handlerGetModeloNegocio={handlerGetModeloNegocio}
            handlerCreateVentureCapital={handlerCreateVentureCapital}
          />
        </RouteGuardHasCache>
      </RouteGuardWithUsuario>
    ),
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/cadastro/venture_capital/7",
    element: (
      <RouteGuardWithUsuario withoutUsuarioPath="/cadastro/venture_capital/3">
        <RouteGuardHasCache
          redirectPath={"/cadastro/venture_capital/5"}
          requireds={["id", "nome"]}
          handlerCache={handlerCacheVentureCapital}
        >
          <PainelLogoVentureCapital
            handlerSaveLogoVentureCapital={handlerSaveLogoVentureCapital}
            handlerGetURLFromPath={handlerGetURLFromPath}
            handlerCacheVentureCapital={handlerCacheVentureCapital}
          />
        </RouteGuardHasCache>
      </RouteGuardWithUsuario>
    ),
    showHeader: false,
    showFooter: true,
  },
];
