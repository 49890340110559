import { useMemo } from "react";

import { OutputStartup } from "../../../domain/entities/Startup";
import { BadgeInfo } from "../user/components/generalComponents/BadgeInfo";
import {
  InfoArquivos,
  InputInfoArquivo,
} from "../user/components/generalComponents/InfoArquivos";
import { InfoEstagioStartup } from "../user/components/generalComponents/InfoEstagioStartup";
import { InfoLinks } from "../user/components/generalComponents/InfoLinks";
import { InfoMecanismoSolucao } from "../user/components/generalComponents/InfoMecanismoSolucao";
import { InfoModeloNegocio } from "../user/components/generalComponents/InfoModeloNegocio";
import { InfoPremiacoes } from "../user/components/generalComponents/InfoPremiacoes";
import { InfoSegmentoAtuacao } from "../user/components/generalComponents/InfoSegmentoAtuacao";
import { InfoSobre } from "../user/components/generalComponents/InfoSobre";
import { EditInfoStartup } from "./EditInfoStartup";

type SubPageStartupProps = {
  startup: OutputStartup;
  currentUserIsGestor: boolean;
  seeAsPublic: boolean;
};

export function SubPageStartup({
  startup,
  currentUserIsGestor,
  seeAsPublic,
}: SubPageStartupProps) {
  const {
    sobre,
    estagio,
    modelo_negocio,
    segmento_atuacao,
    mecanismo_solucao,
    hub,
    qtde_funcionarios,
    premiacoes,
    website,
    linkedin,
    path_pitch_pdf,
    path_pitch_planilha,
    url_video,
  } = useMemo(() => startup, [startup]);

  const arquivosDefined = useMemo<InputInfoArquivo[]>(() => {
    const arquivosDefined: InputInfoArquivo[] = [];

    if (path_pitch_pdf)
      arquivosDefined.push({
        path: path_pitch_pdf,
        type: "pdf",
      });

    if (path_pitch_planilha)
      arquivosDefined.push({
        path: path_pitch_planilha,
        type: "excel",
      });
    if (url_video)
      arquivosDefined.push({
        path: url_video,
        type: "video",
      });

    return arquivosDefined;
  }, [path_pitch_pdf, path_pitch_planilha, url_video]);

  const linksDefined = useMemo<string[]>(() => {
    const linksDefined: string[] = [];

    if (website) linksDefined.push(website);
    if (linkedin) linksDefined.push(linkedin);

    return linksDefined;
  }, [linkedin, website]);

  return (
    <>
      <div className="position-absolute top-0 end-0">
        <EditInfoStartup
          startup={startup}
          enableEdit={currentUserIsGestor && !seeAsPublic && startup.habilitado}
        />
      </div>

      <InfoSobre sobre={sobre} />

      <InfoEstagioStartup estagio={estagio} />

      <div className="row">
        <div className="col-6">
          {modelo_negocio.length > 0 && (
            <InfoModeloNegocio
              modeloNegocio={modelo_negocio}
              className="mb-3"
            />
          )}

          {hub && (
            <>
              <h5 className="fw-lighter mb-3">Hub de Inovação</h5>
              <div className="ms-5">
                <h5 className="fw-bold">{hub.hub.nome}</h5>
                <h5>
                  <span className="color-spinoff">Status: </span>

                  <span>{hub.status}</span>
                </h5>
              </div>
            </>
          )}
        </div>

        <div className="col-6">
          <InfoSegmentoAtuacao segmentoAtuacao={segmento_atuacao} />

          <InfoMecanismoSolucao mecanismoSolucao={mecanismo_solucao} />

          <div className="mb-3">
            <h5 className="fw-lighter">
              Quantidade de funcionários{" "}
              <BadgeInfo children={qtde_funcionarios} />
            </h5>
          </div>
        </div>
      </div>

      <InfoArquivos arquivos={arquivosDefined} />

      <InfoPremiacoes premiacoes={premiacoes} />

      <InfoLinks links={linksDefined} />
    </>
  );
}
