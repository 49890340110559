import { useContext, useEffect, useMemo, useRef, useState } from "react";

import { ButtonDarkGreen } from "../../../../generalComponents/buttonDarkGreen/ButtonDarkGreen";
import { InputPremiacoes } from "../../../../generalComponents/InputPremiacoes/InputPremiacoes";
import { Cientista } from "../../../../../domain/entities/Cientista";
import { HandlerCacheCientista } from "../../../../../domain/usecases/interfaces/HandlerCacheCientista";
import { CurrentUserContext } from "../../../../../globalStore/CurrentUserContext";
import { ButtonLightGreen } from "../../../../generalComponents/buttonLightGreen/ButtonLightGreen";
import { InputSobre } from "../../../../generalComponents/InputSobre";
import { Sobre } from "../../../../../domain/entities/sobre";
import { InputAreasConhecimento } from "../../../../generalComponents/InputAreasConhecimento";
import { InputUnidadesInstituicao } from "../../../../generalComponents/InputUnidadesInstituicao";
import { InputTecnologias } from "../../../../generalComponents/InputTecnologias/InputTecnologias";
import { HandlersContext } from "../../../../../globalStore/HandlersProviders/HandlersContext";
import { TypeHandlerCreateCientista } from "../../../../../domain/usecases/interfaces/handlerCreateCientista";
import { InputGruposDePesquisa } from "../../../../generalComponents/InputGruposDePesquisa/InputGruposDePesquisa";
import { Spinner } from "react-bootstrap";

export function ConteudoSobreCientista({
  handlerCacheCientista,
  onNext,
  onBack,
}: {
  handlerCacheCientista: HandlerCacheCientista;
  onNext: (cientista_id: string) => void;
  onBack: () => void;
}) {
  const { handlerCreateCientista } = useContext(HandlersContext);
  const { currentUserScyggz } = useContext(CurrentUserContext);

  const [parteDoNome, setParteDoNome] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [valuesToCache, setValuesToCache] = useState<FormikValues>({
    areas_de_conhecimento: [],
    grupos_de_pesquisa: [],
    premiacoes: [],
    sobre: {
      conteudo: "",
    },
    unidades: [],
    tecnologias: [],
  });
  const [isEditingGrupoPesquisa, setIsEditingGrupoPesquisa] =
    useState<boolean>(false);
  const [isEditingPremiacoes, setIsEditingPremiacoes] =
    useState<boolean>(false);
  const [isEditingTecnologia, setIsEditingTecnologia] =
    useState<boolean>(false);

  const isValid = useMemo<boolean>(() => {
    if (
      Cientista.validateAreasConhecimento(valuesToCache.areas_de_conhecimento)
    )
      return false;

    if (
      !Cientista.validateGruposDePesquisa(
        valuesToCache.grupos_de_pesquisa ?? []
      ).ok
    )
      return false;

    if (!Cientista.validatePremiacoes(valuesToCache.premiacoes ?? []).ok)
      return false;

    if (Cientista.validateUnidades(valuesToCache.unidades ?? [])) return false;

    if (!Sobre.validate(valuesToCache.sobre).ok) return false;

    return true;
  }, [valuesToCache]);

  const cientistaToSave = useRef<TypeHandlerCreateCientista>({
    created_in: new Date(),
    nome: "",
    user_id: "",
    areas_de_conhecimento: [],
    sobre: {
      conteudo: "",
    },
    unidades: [],
  });

  useEffect(() => {
    handlerCacheCientista.get().then((getCacheCientistaResult) => {
      if (getCacheCientistaResult.ok) {
        setValuesToCache({
          areas_de_conhecimento:
            getCacheCientistaResult.value.areas_de_conhecimento ?? [],
          grupos_de_pesquisa: getCacheCientistaResult.value.grupos_de_pesquisa,
          unidades: getCacheCientistaResult.value.unidades ?? [],
          premiacoes: getCacheCientistaResult.value.premiacoes,
          sobre: {
            conteudo: getCacheCientistaResult.value.sobre?.conteudo ?? "",
          },
          tecnologias: getCacheCientistaResult.value.tecnologias,
        });

        cientistaToSave.current = {
          ...cientistaToSave.current,
          ...getCacheCientistaResult.value,
        };
      }
    });
  }, [handlerCacheCientista]);

  useEffect(() => {
    if (currentUserScyggz.user) {
      const parteDoNome: string =
        currentUserScyggz.user.nome.split(" ").at(0) ??
        currentUserScyggz.user.nome;

      setParteDoNome(parteDoNome);

      cientistaToSave.current = {
        ...cientistaToSave.current,
        user_id: currentUserScyggz.user.auth_user_id,
        nome: currentUserScyggz.user.nome,
      };
    }
  }, [currentUserScyggz]);

  useEffect(() => {
    cientistaToSave.current = {
      ...cientistaToSave.current,
      ...valuesToCache,
      user_id: currentUserScyggz.user?.auth_user_id ?? "",
    };
  }, [currentUserScyggz.user?.auth_user_id, valuesToCache]);

  return (
    <div>
      <div className="card-body">
        <p className="card-title fw-bold mb-4 text-center">
          {parteDoNome}, por favor fale mais sobre você.
        </p>

        <form
          onSubmit={(e) => {
            setIsSubmitting(true);

            handlerCacheCientista.save(valuesToCache);

            handlerCreateCientista
              ?.createCientista(cientistaToSave.current)
              .then((createCientistaResult) => {
                setIsSubmitting(false);

                if (createCientistaResult.ok)
                  onNext(createCientistaResult.value.user_id);
                else {
                  console.warn("Erro", createCientistaResult);
                }
              });

            e.preventDefault();
          }}
        >
          <InputSobre
            label="Sobre o cientista*"
            value={valuesToCache.sobre.conteudo}
            onChange={(sobre) => {
              setValuesToCache({
                ...valuesToCache,
                sobre: {
                  conteudo: sobre,
                },
              });
            }}
          />

          <InputAreasConhecimento
            label="Área(s) de Conhecimento*"
            value={valuesToCache.areas_de_conhecimento}
            onChange={(areas_de_conhecimento) => {
              setValuesToCache({
                ...valuesToCache,
                areas_de_conhecimento,
              });
            }}
            validate={Cientista.validateAreasConhecimento}
            className="mb-3"
          />

          <InputUnidadesInstituicao
            label="Unidades da Instituição (ICT)*"
            value={valuesToCache.unidades}
            onChange={(unidades) => {
              setValuesToCache({
                ...valuesToCache,
                unidades,
              });
            }}
            validate={Cientista.validateUnidades}
            className="mb-3"
          />

          <InputGruposDePesquisa
            label="Selecione o grupo de pesquisa"
            value={valuesToCache.grupos_de_pesquisa ?? []}
            onListChange={(grupos_de_pesquisa) => {
              setValuesToCache({
                ...valuesToCache,
                grupos_de_pesquisa: grupos_de_pesquisa.map((grupo) => {
                  return {
                    ...grupo,
                    autor: currentUserScyggz.user?.auth_user_id,
                    tipo: {
                      ...grupo.tipo,
                      autor: currentUserScyggz.user?.auth_user_id,
                    },
                  };
                }),
              });
            }}
            className="mb-3"
            isEditing={setIsEditingGrupoPesquisa}
          />

          <InputPremiacoes
            label="Liste as suas premiações"
            value={valuesToCache.premiacoes ?? []}
            onChange={(premiacoes) => {
              setValuesToCache({
                ...valuesToCache,
                premiacoes,
              });
            }}
            showTip={true}
            className="mb-3"
            isEditing={setIsEditingPremiacoes}
          />

          <InputTecnologias
            label="Liste suas tecnologias"
            showTip={true}
            tecnologias={valuesToCache.tecnologias ?? []}
            onListChange={(tecnologias) => {
              setValuesToCache({
                ...valuesToCache,
                tecnologias,
              });
            }}
            isEditing={setIsEditingTecnologia}
          />

          <div className="text-end mt-3">
            <ButtonLightGreen
              onClick={() => {
                setIsSubmitting(true);
                handlerCacheCientista.save(valuesToCache);
                onBack();
              }}
            >
              Voltar
            </ButtonLightGreen>
            <ButtonDarkGreen
              disabled={
                !isValid ||
                isSubmitting ||
                isEditingGrupoPesquisa ||
                isEditingPremiacoes ||
                isEditingTecnologia
              }
              isSubmit={true}
              className="ms-2 pe-5 text-uppercase"
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className={"me-2" + (isSubmitting ? "" : " invisible")}
              />
              Avançar
            </ButtonDarkGreen>
          </div>
        </form>
      </div>
    </div>
  );
}

type FormikValues = Pick<
  Cientista,
  | "sobre"
  | "areas_de_conhecimento"
  | "premiacoes"
  | "grupos_de_pesquisa"
  | "unidades"
  | "tecnologias"
>;
