import { useCallback, useState } from "react";

type InputNomeStartupProps = {
  label: string;
  nome: string;
  onChange: (nome: string) => void;
};

export function InputNomeStartup({
  label,
  nome,
  onChange,
}: InputNomeStartupProps) {
  const [error, setError] = useState<string>();
  const [value, setValue] = useState<string>(nome);

  const MIN_SIZE_NOME = 3;

  const isInvalidNome = (nome?: string): Error | undefined => {
    const isInvalid: boolean = (nome ?? "").trim().length < MIN_SIZE_NOME;
    if (isInvalid) {
      return new Error("Nome muito curto");
    }
  };

  const handlerChange = useCallback(
    (nome: string) => {
      onChange(nome);
      setValue(nome);

      const errorNome = isInvalidNome(nome);
      if (errorNome) {
        setError(errorNome.message);
      } else {
        setError(undefined);
      }
    },
    [onChange]
  );

  return (
    <div className={"mb-3"}>
      <label className="form-label fw-bolder m-0" htmlFor="iptNome">
        {label}
      </label>

      <input
        className={"form-control" + (error ? " is-invalid" : "")}
        type="text"
        min={MIN_SIZE_NOME}
        onChange={({ target: { value } }) => {
          console.log("VALUE " + value);
          handlerChange(value);
        }}
        id="iptNome"
        value={value}
      />

      <div className="invalid-feedback">{error}</div>
    </div>
  );
}
