import { ReactNode, useContext, useEffect, useState } from "react";

import { UsuarioScyggz } from "../../domain/entities/usuarioScyggz";
import {
  ConexoesAprovadasCountContext,
  TypeConexoesAprovadasCountContext,
} from "./ConexoesAprovadasCountContext";
import { HandlersDatabaseContext } from "../HandlersProviders/HandlersDatabaseContext";

export function ConexoesAprovadasCountProvider({
  user,
  children,
}: {
  user: Pick<UsuarioScyggz, "auth_user_id">;
  children: ReactNode;
}) {
  const [conexoesAprovadasCountResult, setConexoesAprovadasCountResult] =
    useState<TypeConexoesAprovadasCountContext>();

  const { handlerConexaoAprovadasCount } = useContext(HandlersDatabaseContext);

  useEffect(() => {
    if (user.auth_user_id.length > 0 && handlerConexaoAprovadasCount)
      handlerConexaoAprovadasCount
        .getConexoesAprovadasCount(user)
        .then((result) => {
          setConexoesAprovadasCountResult(result);
        });
  }, [user, handlerConexaoAprovadasCount]);

  return (
    <ConexoesAprovadasCountContext.Provider
      value={{
        conexoesAprovadasCountResult,
      }}
      children={children}
    />
  );
}
