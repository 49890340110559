import logoHeader from "../../../../assets/images/logo-scyggz.png";

const Splash = () => {
  return (
    <div
      className="vh-100 vw-100 d-flex justify-content-center align-items-center text-center"
      data-testid="splash"
    >
      <div className="card p-3 col-7 col-sm-4 col-md-3 col-xl-2">
        <img className="card-img-top" src={logoHeader} alt="Logo scyggz" />
        <div className="card-body p-0">
          <h2 className="card-text">carregando...</h2>
        </div>
      </div>
    </div>
  );
};

export default Splash;
