import { OutputVentureCapital } from "../../../domain/entities/VentureCapital";
import { HeaderPerfil } from "../components/perfil/HeaderPerfil";
import { AreasInfo } from "../user/components/generalComponents/AreasInfo";
import { InfoEstagioCicloDeVida } from "../user/components/generalComponents/InfoEstagioCicloDeVida";
import { InfoLocalidade } from "../user/components/generalComponents/InfoLocalidade";
import { InfoPremiacoes } from "../user/components/generalComponents/InfoPremiacoes";
import { InfoStatupsCards } from "../user/components/generalComponents/InfoStartupsCards";
import { ParagrafoInfo } from "../user/components/generalComponents/ParagrafoInfo";
import { TitleInfo } from "../user/components/generalComponents/TitleInfo";

export default function PerfilInfoVentureCapital({
  tese_de_investimento,
  nome,
  path_logo,
  interesse_startup_estagio,
  interesse_startup_localizacoes,
  interesse_startup_modelo_negocio,
  interesse_startup_segmento_atuacao,
  interesse_startup_mecanismo_solucao,
  premiacoes,
  volume_recursos_sob_gestao,
  startups_investidas,
}: Pick<
  OutputVentureCapital,
  | "tese_de_investimento"
  | "nome"
  | "path_logo"
  | "interesse_startup_estagio"
  | "interesse_startup_localizacoes"
  | "interesse_startup_modelo_negocio"
  | "interesse_startup_segmento_atuacao"
  | "interesse_startup_mecanismo_solucao"
  | "premiacoes"
  | "volume_recursos_sob_gestao"
  | "startups_investidas"
>) {
  return (
    <section className="container">
      <HeaderPerfil
        nome={nome}
        foto_path={path_logo}
        headline={"VentureCapital"}
      />

      <TitleInfo classes="mb-2" children="Tese de Investimento" />
      <div className="border rounded-4 p-3 mb-4">
        <ParagrafoInfo
          classes="mb-5"
          border=""
          descricao={tese_de_investimento.conteudo}
        />
        <div className="row">
          <div className="col-md-6">
            <InfoEstagioCicloDeVida estagio={interesse_startup_estagio} />
            <InfoLocalidade localidades={interesse_startup_localizacoes} />
            <AreasInfo
              areas={interesse_startup_modelo_negocio}
              title="Modelo de negócio"
            />
          </div>
          <div className="col-md-6">
            <AreasInfo
              areas={interesse_startup_segmento_atuacao}
              title="Segmentos de atuação"
            />
            <AreasInfo
              areas={interesse_startup_mecanismo_solucao}
              title="Mecanismo de solução tecnológica"
            />
          </div>
        </div>
      </div>
      <InfoPremiacoes classes="mb-4" premiacoes={premiacoes} />
      <InfoStatupsCards
        classes="mb-4"
        volumeRecursos={volume_recursos_sob_gestao}
        startups={startups_investidas}
      />
    </section>
  );
}
