import { doc, setDoc, Firestore } from "@firebase/firestore";

import {
  InputUsuarioScyggzEntity,
  OutputUsuarioScyggzEntity,
} from "../domain/entities/usuarioScyggz";
import { HandlerCreateUsuarioScyggz } from "../domain/usecases/interfaces/handlerCreateUsuarioScyggz";
import { Result } from "../typings";
import { UsuarioScyggzValidator } from "../domain/entities/UsuarioScyggzValidator";

export class HandlerCreateUsuarioScyggzFirebase
  implements HandlerCreateUsuarioScyggz
{
  constructor(private firestore: Firestore) {}

  createUsuarioScyggz(
    input: InputUsuarioScyggzEntity
  ): Promise<Result<OutputUsuarioScyggzEntity>> {
    return new Promise<Result<OutputUsuarioScyggzEntity>>((resolve) => {
      const userUpdateResult = UsuarioScyggzValidator.validateCreate(input);

      if (!userUpdateResult.ok) return userUpdateResult;

      const docUser = doc(this.firestore, "usuarios", input.auth_user_id);

      return setDoc(docUser, input, { merge: true })
        .then(() =>
          resolve({
            ok: true,
            value: { ...input, auth_user_id: docUser.id },
          })
        )
        .catch((error) =>
          resolve({
            ok: false,
            error,
          })
        );
    });
  }
}
