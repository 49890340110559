import { useNavigate } from "react-router-dom";
import { useContext } from "react";

import { HandlerCacheCientista } from "../../../../../domain/usecases/interfaces/HandlerCacheCientista";
import { ContainerResponsive } from "../../../../generalComponents/ContainerResponsive";
import { OnEnterPrevent } from "../../../../generalComponents/OnEnterPrevent";
import { ConteudoSobreCientista } from "./ConteudoSobreCientista";
import { CurrentUserContext } from "../../../../../globalStore/CurrentUserContext";

export function PainelSobreCientista({
  handlerCacheCientista,
}: {
  handlerCacheCientista: HandlerCacheCientista;
}) {
  const { refreshCurrentUserScyggz } = useContext(CurrentUserContext);

  const navigate = useNavigate();

  const paginacaoElegibilidade = {
    start: 5,
    end: 5,
  };

  const etapa4 = "/cadastro/cientista/4";

  return (
    <ContainerResponsive
      backUrlMobile={etapa4}
      textoMarcaDagua={"Cientista"}
      paginacao={paginacaoElegibilidade}
      showLogo={true}
    >
      <OnEnterPrevent>
        <ConteudoSobreCientista
          handlerCacheCientista={handlerCacheCientista}
          onBack={() => {
            navigate(etapa4);
          }}
          onNext={async (cientista_id) => {
            handlerCacheCientista.clear();
            if (refreshCurrentUserScyggz) await refreshCurrentUserScyggz();
            navigate("/user/" + cientista_id + "/cientista");
          }}
        />
      </OnEnterPrevent>
    </ContainerResponsive>
  );
}
