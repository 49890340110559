import {
  CacheNit,
  HandlerCacheNit,
} from "../domain/usecases/interfaces/HandlerCacheNit";
import { Result, Writeable } from "../typings";

export class HandlerCacheNitStorage implements HandlerCacheNit {
  private readonly keyNitPrefix = "NitCadastroInScyggz";

  clear(): Promise<Result<null>> {
    if (!localStorage) {
      return Promise.resolve({
        ok: false,
        error: new Error(
          "Não fez cache do Nit pois o navegador é incompativel"
        ),
      });
    }

    try {
      localStorage.removeItem(this.keyNitPrefix);

      return Promise.resolve({
        ok: true,
        value: null,
      });
    } catch (error) {
      return Promise.resolve({
        ok: false,
        error: error as Error,
      });
    }
  }

  async save(nit: CacheNit): Promise<Result<null, Error>> {
    if (!localStorage) {
      return Promise.resolve({
        ok: false,
        error: new Error(
          "Não fez cache do Nit pois o navegador é incompativel"
        ),
      });
    }

    try {
      const getResult = await this.get();

      if (!getResult.ok) {
        return getResult;
      }

      localStorage.setItem(
        this.keyNitPrefix,
        JSON.stringify({ ...getResult.value, ...nit })
      );

      return Promise.resolve({
        ok: true,
        value: null,
      });
    } catch (error) {
      return Promise.resolve({
        ok: false,
        error: error as Error,
      });
    }
  }

  get(): Promise<Result<CacheNit, Error>> {
    const nitStorage = localStorage.getItem(this.keyNitPrefix);

    if (!nitStorage) {
      return Promise.resolve({
        ok: true,
        value: {},
      });
    }

    const nitAny = JSON.parse(nitStorage);

    if (!nitAny) {
      return Promise.resolve({
        ok: true,
        value: {},
      });
    }

    const nit = this.convertAnyToNit(nitAny);

    return Promise.resolve({
      ok: true,
      value: nit,
    });
  }

  private convertAnyToNit(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    nitAny: any
  ): Writeable<CacheNit> {
    const nit: Writeable<CacheNit> = {};

    if (nitAny.id) nit.id = nitAny.id;

    if (nitAny.cidade) nit.cidade = nitAny.cidade;

    if (nitAny.email) nit.email = nitAny.email;

    if (nitAny.gestores) nit.gestores = nitAny.gestores;

    if (nitAny.habilitado) nit.habilitado = nitAny.habilitado;

    if (nitAny.hubs) nit.hubs = nitAny.hubs;

    if (nitAny.linkedin) nit.linkedin = nitAny.linkedin;

    if (nitAny.logo_path) nit.logo_path = nitAny.logo_path;

    if (nitAny.instituicoes) nit.instituicoes = nitAny.instituicoes;

    if (nitAny.nome) nit.nome = nitAny.nome;

    if (nitAny.sobre) nit.sobre = nitAny.sobre;

    if (nitAny.telefone) nit.telefone = nitAny.telefone;

    if (nitAny.website) nit.website = nitAny.website;

    if (nitAny.logoFile) nit.logoFile = nitAny.logoFile;

    return nit;
  }
}
