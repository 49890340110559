import { useContext } from "react";
import { ProgressBar } from "react-bootstrap";

import { PerfilVentureCapitalContext } from "../../../globalStore/perfilVentureCapital/PerfilVentureCapitalContext";
import PerfilInfoVentureCapital from "./PerfilInfoVentureCapital";

export const PerfilInfoVentureCapitalFromContext = () => {
  const { perfilVentureCapitalResult } = useContext(
    PerfilVentureCapitalContext
  );

  if (!perfilVentureCapitalResult)
    return (
      <ProgressBar animated variant="info" now={100} className="w-100 m-1" />
    );

  return perfilVentureCapitalResult.ok ? (
    <PerfilInfoVentureCapital
      interesse_startup_estagio={
        perfilVentureCapitalResult.value.interesse_startup_estagio
      }
      interesse_startup_mecanismo_solucao={
        perfilVentureCapitalResult.value.interesse_startup_mecanismo_solucao
      }
      interesse_startup_modelo_negocio={
        perfilVentureCapitalResult.value.interesse_startup_modelo_negocio
      }
      interesse_startup_segmento_atuacao={
        perfilVentureCapitalResult.value.interesse_startup_segmento_atuacao
      }
      nome={perfilVentureCapitalResult.value.nome}
      tese_de_investimento={
        perfilVentureCapitalResult.value.tese_de_investimento
      }
      interesse_startup_localizacoes={
        perfilVentureCapitalResult.value.interesse_startup_localizacoes
      }
      path_logo={perfilVentureCapitalResult.value.path_logo}
      premiacoes={perfilVentureCapitalResult.value.premiacoes}
      startups_investidas={perfilVentureCapitalResult.value.startups_investidas}
      volume_recursos_sob_gestao={
        perfilVentureCapitalResult.value.volume_recursos_sob_gestao
      }
    />
  ) : (
    <>não encontrado</>
  );
};
