import { createContext } from "react";

import { TypeHandlersLocationContext } from "./HandlersLocationContext";
import { TypeHandlersDatabaseContext } from "./HandlersDatabaseContext";
import { TypeHandlersStorageContext } from "./HandlersStorageContext";

export const HandlersContext = createContext<
  TypeHandlersLocationContext &
    TypeHandlersDatabaseContext &
    TypeHandlersStorageContext
>({});
