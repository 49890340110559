import { useContext, useEffect, useState } from "react";

import { TypePessoaRelevante } from "../../../../domain/usecases/interfaces/HandlerGetPessoasRelevantes";
import { HandlerGetPessoasRelevantesFirebase } from "../../../../gateway/firebase/HandlerGetPessoasRelevantesFirebase";
import { CurrentUserContext } from "../../../../globalStore/CurrentUserContext";
import { FirebaseContext } from "../../../../globalStore/FirebaseContext";
import { UsuarioInfoContext } from "../../../../globalStore/UsuarioInfoContext";
import { Result } from "../../../../typings";
import { BannerPessoasRelevantes } from "./BannerPessoasRelevantes";
import { BannerPessoasRelevantesLoading } from "./BannerPessoasRelevantesLoading";

export function BannerPessoasRelevantesFirebase() {
  const [pessoasRelevantesResult, setPessoasRelevantesResult] =
    useState<Result<TypePessoaRelevante[]>>();

  const { currentUserScyggz } = useContext(CurrentUserContext);
  const { usuarioInfoResult } = useContext(UsuarioInfoContext);
  const { firestore } = useContext(FirebaseContext);

  useEffect(() => {
    if (currentUserScyggz.user?.auth_user_id) {
      new HandlerGetPessoasRelevantesFirebase(firestore)
        .get({
          currentUser: currentUserScyggz.user.auth_user_id,
          showedUser: usuarioInfoResult?.ok
            ? usuarioInfoResult.value.auth_user_id
            : currentUserScyggz.user.auth_user_id,
        })
        .then((pessoasRelevantesResult) =>
          setPessoasRelevantesResult(pessoasRelevantesResult)
        );
    }
  }, [currentUserScyggz, firestore, usuarioInfoResult]);

  if (!pessoasRelevantesResult || !pessoasRelevantesResult.ok)
    return <BannerPessoasRelevantesLoading />;

  return <BannerPessoasRelevantes items={pessoasRelevantesResult.value} />;
}
