import { createContext } from "react";

import { Cientista } from "../../domain/entities/Cientista";
import {
  InputHandlerUpdateCientista,
  InputHandlerUpdateCientistaSemId,
} from "../../domain/usecases/interfaces/handlerUpdateCientista";
import { Result } from "../../typings";

export const PerfilCientistaContext = createContext<{
  perfilCientistaResult: TypePerfilCientistaContext;
  refreshCientista: (() => void) | undefined;
  updateCientista:
    | ((
        input: InputHandlerUpdateCientistaSemId
      ) => Promise<Result<InputHandlerUpdateCientista>>)
    | undefined;
}>({
  perfilCientistaResult: undefined,
  refreshCientista: undefined,
  updateCientista: undefined,
});

export type TypePerfilCientistaContext =
  | Result<Readonly<Cientista>>
  | undefined;
