import { useContext, useEffect, useMemo, useState } from "react";
import { ProgressBar } from "react-bootstrap";

import { HandlersDatabaseContext } from "../../../../../globalStore/HandlersProviders/HandlersDatabaseContext";
import { Conexao } from "../../../../../domain/entities/Conexao";
import { Result } from "../../../../../typings";
import { ConviteProgramaConexao } from "../../../../../domain/entities/ConviteProgramaConexao";

export function EstatisticasProgramaDeConexao({
  id_programa,
  className,
}: {
  id_programa: string;
  className?: string;
}) {
  const {
    handlerConexaoGetAllOfProgramaConexaoRealtime,
    handlerConviteProgramaConexaoGetAll,
  } = useContext(HandlersDatabaseContext);

  const [conexoesPrograma, setConexoesPrograma] = useState<Result<Conexao[]>>();
  const [convitesPrograma, setConvitesPrograma] =
    useState<Result<ConviteProgramaConexao[]>>();

  const conexoesProgramaEnviadas = useMemo<number>(() => {
    if (conexoesPrograma?.ok) {
      return conexoesPrograma.value.length;
    }

    return 0;
  }, [conexoesPrograma]);
  const conexoesProgramaAceitos = useMemo<number>(() => {
    if (conexoesPrograma?.ok) {
      return conexoesPrograma.value.filter(
        (conexao) => conexao.status == "aprovado"
      ).length;
    }

    return 0;
  }, [conexoesPrograma]);
  const conexoesProgramaRejeitados = useMemo<number>(() => {
    if (conexoesPrograma?.ok) {
      return conexoesPrograma.value.filter(
        (conexao) => conexao.status == "negado"
      ).length;
    }

    return 0;
  }, [conexoesPrograma]);
  const convitesProgramaEnviadas = useMemo<number>(() => {
    if (convitesPrograma?.ok) {
      return convitesPrograma.value.length;
    }

    return 0;
  }, [convitesPrograma]);
  const convitesProgramaAceitos = useMemo<number>(() => {
    if (convitesPrograma?.ok) {
      return convitesPrograma.value.filter(
        (convite) => convite.status == "accepted"
      ).length;
    }

    return 0;
  }, [convitesPrograma]);
  const convitesProgramaRejeitados = useMemo<number>(() => {
    if (convitesPrograma?.ok) {
      return convitesPrograma.value.filter(
        (convite) => convite.status == "rejected"
      ).length;
    }

    return 0;
  }, [convitesPrograma]);

  useEffect(() => {
    const sub = handlerConexaoGetAllOfProgramaConexaoRealtime
      ?.getAllOfProgramaConexaoRealtime(id_programa)
      .subscribe((conexoesPrograma) => {
        setConexoesPrograma(conexoesPrograma);
      });

    return () => {
      sub?.unsubscribe();
    };
  }, [handlerConexaoGetAllOfProgramaConexaoRealtime, id_programa]);
  useEffect(() => {
    handlerConviteProgramaConexaoGetAll
      ?.getAll(id_programa)
      .then((convitesPrograma) => setConvitesPrograma(convitesPrograma));
  }, [handlerConviteProgramaConexaoGetAll, id_programa]);

  return (
    <>
      <div className={"card shadow-sm px-2 p-md-3 " + className}>
        <div className="card-body p-2 p-md-3 align-items-center">
          <div className="table-responsive">
            <table className="table">
              <thead className="text-uppercase text-muted">
                <tr className="table-secondary border-0 rounded-1">
                  <th className="border-0 fw-bolder" colSpan={4}>
                    Convites do Programa de Conexão
                  </th>
                </tr>
                <tr className="table-light border-0">
                  <th className="border-0">Status</th>
                  <th className="border-0">Enviados</th>
                  <th className="border-0">Aceitos</th>
                  <th className="border-0 ">Rejeitados</th>
                </tr>
              </thead>
              <tbody>
                {convitesPrograma == undefined ? (
                  <>
                    <tr>
                      <td colSpan={4}>
                        <ProgressBar
                          animated
                          variant="info"
                          now={100}
                          className="w-100"
                        />
                      </td>
                    </tr>
                  </>
                ) : (
                  <>
                    <tr>
                      <td>todos</td>
                      <td>{convitesProgramaEnviadas}</td>
                      <td>{convitesProgramaAceitos}</td>
                      <td>{convitesProgramaRejeitados}</td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>

          <div className="table-responsive">
            <table className="table">
              <thead className="text-uppercase text-muted">
                <tr className="table-secondary border-0 rounded-1">
                  <th className="border-0 fw-bolder" colSpan={4}>
                    Conexões entre usuários
                  </th>
                </tr>
                <tr className="table-light border-0">
                  <th className="border-0">Status</th>
                  <th className="border-0">Enviados</th>
                  <th className="border-0">Conectados</th>
                  <th className="border-0 ">Rejeitados</th>
                </tr>
              </thead>
              <tbody>
                {conexoesPrograma == undefined ? (
                  <>
                    <tr>
                      <td colSpan={4}>
                        <ProgressBar
                          animated
                          variant="info"
                          now={100}
                          className="w-100"
                        />
                      </td>
                    </tr>
                  </>
                ) : (
                  <>
                    <tr>
                      <td>todos</td>
                      <td>{conexoesProgramaEnviadas}</td>
                      <td>{conexoesProgramaAceitos}</td>
                      <td>{conexoesProgramaRejeitados}</td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
