import { useNavigate } from "react-router";

import { ContainerResponsive } from "../../../../generalComponents/ContainerResponsive";
import { ConteudoCadastro } from "../../components/ConteudoCadastro";

export function PainelCadastroAuthInvestidor() {
  const navigate = useNavigate();

  const paginacaoElegibilidadeinvestidor = {
    start: 2,
    end: 6,
  };

  return (
    <section className="h-100">
      <ContainerResponsive
        backUrlMobile="/cadastro/investidor/1"
        paginacao={paginacaoElegibilidadeinvestidor}
        showLogo={true}
      >
        <ConteudoCadastro
          onRedirect={() => {
            navigate("/cadastro/investidor/3");
          }}
          onCadastro={() => {
            navigate("/cadastro/investidor/3");
          }}
        />
      </ContainerResponsive>
    </section>
  );
}
