import { Link } from "react-router-dom";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "./ButtonDarkGreen.module.css";
import React from "react";

type Props = {
  children: string | React.ReactNode;
  link?: string;
  disabled?: boolean;
  disabledText?: string;
  className?: string;
  icon?: IconDefinition;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  isSubmit?: boolean;
  title?: string;
};

export const ButtonDarkGreen = ({
  link,
  children,
  disabled,
  className,
  icon,
  onClick,
  isSubmit,
  title,
}: Props): JSX.Element => {
  let classNameFinal = " ";
  if (disabled) classNameFinal += "disabledButton ";
  if (className) classNameFinal += className;
  const button: JSX.Element = (
    <button
      disabled={disabled}
      onClick={onClick}
      className={
        "fw-bolder rounded-3 p-1 px-4 " + styles.button + classNameFinal
      }
      type={isSubmit ? "submit" : "button"}
      title={title}
    >
      {icon ? <FontAwesomeIcon icon={icon} /> : null}
      {children}
    </button>
  );
  return link && !disabled ? <Link to={link}>{button}</Link> : button;
};
