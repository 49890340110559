import { useNavigate } from "react-router";
import { useContext } from "react";

import { ContainerResponsive } from "../../../../generalComponents/ContainerResponsive";
import { PainelElegibilidade } from "../../components/PainelElegibilidade/PainelElegibilidade";
import { HandlersDatabaseContext } from "../../../../../globalStore/HandlersProviders/HandlersDatabaseContext";

export function PainelElegibilidadeEmpresa() {
  const navigate = useNavigate();

  const { handlerAceiteSet } = useContext(HandlersDatabaseContext);

  const paginacaoElegibilidade = {
    start: 1,
    end: 8,
  };
  const nextUrl = "/cadastro/empresa/2";

  return (
    <section data-testid="painel-elegibilidade-empresa" className="h-100">
      <ContainerResponsive
        backUrlMobile="/"
        textoMarcaDagua={"Empresa"}
        paginacao={paginacaoElegibilidade}
        showLogo={true}
      >
        <PainelElegibilidade
          requisitos={[
            "Sou um pessoa física, não estou representando um fundo de Venture Capital;",
            "Já invisto e/ou pretendo investir em startup;",
            "Já participo ou pretendo participar do ecossistema de empreendedorismo.",
          ]}
          titulo={"Empresa"}
          onNext={() => {
            if (handlerAceiteSet) handlerAceiteSet.set("empresa", new Date());
            navigate(nextUrl);
          }}
          onPrevious={() => {
            navigate("/");
          }}
        />
      </ContainerResponsive>
    </section>
  );
}
