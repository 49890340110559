import { ReactNode, useContext, useEffect, useState } from "react";

import { Hub } from "../../domain/entities/Hub";
import { HandlerGetHubFirebase } from "../../gateway/handlerGetHubFirebase";
import { FirebaseContext } from "../FirebaseContext";
import { PerfilHubContext, TypePerfilHubContext } from "./PerfilHubContext";

export function PerfilHubFirebaseProvider({
  hub,
  children,
}: {
  hub: Pick<Hub, "id">;
  children: ReactNode;
}) {
  const [perfilHubResult, setPerfilHubResultResult] =
    useState<TypePerfilHubContext>();

  const { firestore } = useContext(FirebaseContext);

  useEffect(() => {
    new HandlerGetHubFirebase(firestore).getHub(hub.id).then((result) => {
      setPerfilHubResultResult(
        result.ok
          ? {
              ok: true,
              value: result.value,
            }
          : result
      );
    });
  }, [hub, firestore]);

  return (
    <PerfilHubContext.Provider
      value={{
        perfilHubResult,
      }}
      children={children}
    />
  );
}
