import { useParams } from "react-router-dom";
import { ProgressBar } from "react-bootstrap";

import { PerfilStartupProvider } from "../../../globalStore/perfilStartup/PerfilStartupProvider";
import { PerfilInfoStartupFromContext } from "./PerfilInfoStartupFromContext";
import { HandlerSaveLogoStartup } from "../../../domain/usecases/interfaces/handlerSaveLogoStartup";

type PainelStartupProps = {
  handlerSaveLogoStartup?: HandlerSaveLogoStartup;
};

export function PainelStartup({ handlerSaveLogoStartup }: PainelStartupProps) {
  const { id_startup } = useParams();

  if (!id_startup) {
    return (
      <ProgressBar animated variant="info" now={100} className="w-100 m-1" />
    );
  }

  return (
    <PerfilStartupProvider
      startup={{
        id: id_startup,
      }}
    >
      <PerfilInfoStartupFromContext
        handlerSaveLogoStartup={handlerSaveLogoStartup}
      />
    </PerfilStartupProvider>
  );
}
