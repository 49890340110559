import { useContext } from "react";
import { ProgressBar } from "react-bootstrap";

import { ConexoesPendentesRecebidasContext } from "../../../../globalStore/conexoesPendentesRecebidas/ConexoesPendentesRecebidasContext";
import { ListConexoes } from "./ListConexoes";

export function ListConexoesPendentesRecebidas() {
  const { conexoesPendentesRecebidasResult } = useContext(
    ConexoesPendentesRecebidasContext
  );

  return (
    <>
      {conexoesPendentesRecebidasResult == undefined ? (
        <ProgressBar animated variant="info" now={100} className="w-100 m-1" />
      ) : (
        conexoesPendentesRecebidasResult.ok && (
          <ListConexoes
            conexoes={conexoesPendentesRecebidasResult.value.map((conexao) => ({
              id: conexao.id,
              user: conexao.autor,
            }))}
          />
        )
      )}
    </>
  );
}
