import { useCallback, useState } from "react";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal } from "react-bootstrap";

import {
  Premiacao,
  PremiacaoInput,
  PremiacaoOutput,
} from "../../../../../domain/entities/premiacao";
import { Result } from "../../../../../typings";

export const InfoPremiacoes = ({
  premiacoes = [],
  classes,
  handlerAdd,
}: {
  premiacoes?: PremiacaoOutput[];
  classes?: string;
  handlerAdd?: (inputAdd: PremiacaoInput) => Promise<Result<unknown>>;
}) => {
  const [showModalAdd, setShowModalAdd] = useState<boolean>(false);
  const [carregando, setCarregando] = useState<boolean>(false);
  const [errorNome, setErrorNome] = useState<string>();
  const [errorDescricao, setErrorDescricao] = useState<string>();

  const [premiacaoToAdd, setPremiacaoToAdd] = useState<PremiacaoInput>({
    nome: "",
    descricao: "",
  });

  const handleValidateToAdd = useCallback(() => {
    const validateResult = Premiacao.validate(premiacaoToAdd);
    if (validateResult.ok) {
      setErrorNome(undefined);
      setErrorDescricao(undefined);
    } else {
      if (validateResult.error.nome) {
        setErrorNome(validateResult.error.nome.message);
      } else {
        setErrorNome(undefined);
      }
      if (validateResult.error.descricao) {
        setErrorDescricao(validateResult.error.descricao.message);
      } else {
        setErrorDescricao(undefined);
      }
    }
  }, [premiacaoToAdd]);

  return (
    <>
      {premiacoes.length > 0 && (
        <section className={`${classes}`}>
          <h5 className="fw-lighter mb-3">Premiações</h5>
          <div className={"ps-4" + (handlerAdd ? " position-relative" : "")}>
            {handlerAdd && (
              <button
                type="button"
                className="btn btn-primary position-absolute top-0 start-100 translate-middle rounded-circle"
                onClick={() => setShowModalAdd(true)}
              >
                <FontAwesomeIcon icon={faAdd} />
              </button>
            )}

            {premiacoes.map((premiacao, i) => (
              <div key={i} className="mb-4">
                <h6 className="fw-bolder">{premiacao.nome}</h6>

                {premiacao.descricao && <p>{premiacao.descricao}</p>}
              </div>
            ))}
          </div>

          <Modal
            show={showModalAdd}
            onHide={() => setShowModalAdd(false)}
            centered
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <Modal.Title>Adicionar premiação</Modal.Title>

              <div className="mt-3">
                <div className="mb-2">
                  <label className="form-label" htmlFor={"iptNomePremiacao"}>
                    Nome*:
                  </label>

                  <input
                    title={"Nome da premiação "}
                    className={
                      "form-control" + (errorNome ? " is-invalid" : "")
                    }
                    type="text"
                    value={premiacaoToAdd.nome}
                    onChange={({ target: { value: nomeValue } }) => {
                      premiacaoToAdd.nome = nomeValue;
                      setPremiacaoToAdd({ ...premiacaoToAdd });
                      handleValidateToAdd();
                    }}
                    id={"iptNomePremiacao"}
                  />

                  <div className="invalid-feedback">{errorNome}</div>
                </div>

                <div className="mb-2 position-relative">
                  <label
                    className="form-label"
                    htmlFor={"iptDescricaoPremiacao"}
                  >
                    Descrição:
                  </label>

                  <textarea
                    className={
                      "form-control" + (errorDescricao ? " is-invalid" : "")
                    }
                    title={"Descrição da premiação "}
                    rows={5}
                    value={premiacaoToAdd.descricao}
                    onChange={({ target: { value: descricaoValue } }) => {
                      // onDescricaoChange(descricaoValue);
                      premiacaoToAdd.descricao = descricaoValue;
                      setPremiacaoToAdd({ ...premiacaoToAdd });
                      handleValidateToAdd();
                    }}
                    id={"iptDescricaoPremiacao"}
                  />
                  <span className="position-absolute top-0 end-0 text-black-50 fw-lighter">
                    {premiacaoToAdd.descricao.length}
                  </span>

                  <div className="invalid-feedback">{errorDescricao}</div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="success"
                disabled={!!errorNome || !!errorDescricao || carregando}
                onClick={async () => {
                  if (handlerAdd) {
                    setCarregando(true);
                    const handlerAddResult = await handlerAdd(premiacaoToAdd);
                    setCarregando(false);

                    if (!handlerAddResult.ok) {
                      console.warn(handlerAddResult.error);
                      return;
                    } else {
                      setPremiacaoToAdd({
                        nome: "",
                        descricao: "",
                      });
                      setShowModalAdd(false);
                    }
                  }
                }}
              >
                Salvar
              </Button>
            </Modal.Footer>
          </Modal>
        </section>
      )}
    </>
  );
};
