import { Firestore, getDoc, doc } from "firebase/firestore";

import {
  InputInvestidorAnjo,
  OutputInvestidorAnjo,
} from "../../domain/entities/InvestidorAnjo";
import { HandlerGetInvestidorAnjo } from "../../domain/usecases/interfaces/getPerfil/handlerGetInvestidorAnjo";
import { Result } from "../../typings";
import { fieldToDate } from "../utils/fieldToDate";

export class HandlerGetInvestidorAnjoFirebase
  implements HandlerGetInvestidorAnjo
{
  constructor(private firestore: Firestore) {}

  async get(auth_id: string): Promise<Result<OutputInvestidorAnjo>> {
    const docUser = doc(
      this.firestore,
      "usuarios",
      auth_id,
      "perfis/investidor"
    );

    return new Promise((resolve) => {
      getDoc(docUser)
        .then((docUserSnapshot) => {
          if (docUserSnapshot.exists()) {
            const docUserSnapshotData =
              docUserSnapshot.data() as InputInvestidorAnjo;

            docUserSnapshotData.created_in = fieldToDate(
              docUserSnapshotData.created_in
            );
            docUserSnapshotData.user_id = auth_id;
            docUserSnapshotData.habilitado = !!docUserSnapshotData.habilitado;

            resolve({
              ok: true,
              value: docUserSnapshotData,
            });
          }

          resolve({
            ok: false,
            error: new Error("Usuário não encontrado"),
          });
        })
        .catch((error) => {
          resolve({
            ok: false,
            error,
          });
        });
    });
  }
}
