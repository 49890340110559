import { Result } from "../../typings";
import { UsuarioScyggzValidator } from "./UsuarioScyggzValidator";

export class Auth {
  private _email: string;
  private _password: string;
  private constructor({ email, password }: InputAuthEntity) {
    this._email = email;
    this._password = password;
  }

  public static create({ email, password }: InputAuthEntity): Result<Auth> {
    email = email.trim();

    if (!this.isValidEmail(email)) {
      return {
        ok: false,
        error: new Error("Email inválido"),
      };
    }

    if (password.length < UsuarioScyggzValidator.MIN_SIZE_PASSWORD) {
      return {
        ok: false,
        error: new Error(UsuarioScyggzValidator.MIN_SIZE_PASSWORD_ERROR),
      };
    }

    return {
      ok: true,
      value: new Auth({
        email,
        password,
      }),
    };
  }

  public get email(): string {
    return this._email;
  }

  public get password(): string {
    return this._password;
  }

  public static isValidEmail(email: string) {
    const emailCheck =
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;

    return emailCheck.test(email);
  }
}

export type InputAuthEntity = {
  email: string;
  password: string;
};

export type OutputAuthEntity = {
  auth_id: string;
  email: string;
  emailVerified: boolean;
  displayName?: string;
};
