import { HandlerQueryLocation } from "../domain/usecases/interfaces/handlerQueryLocation";
import { LocationFinderOutput } from "../domain/usecases/locationFinder";
import { locationFinderOSM } from "../domain/usecases/openStreetMap/locationFinderOSM";

export class HandlerQueryLocationOSM implements HandlerQueryLocation {
  constructor(private filterTypes: string[] = []) {}
  async query(locationName: string): Promise<LocationFinderOutput[]> {
    const locations = await locationFinderOSM(locationName);

    if (this.filterTypes.length > 0)
      return locations.filter((location) =>
        this.filterTypes.includes(location.tipo)
      );

    return locations;
  }
}
