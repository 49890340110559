export const TIPOS_CADASTRO = [
  "mentor",
  "cientista",
  "investidor",
  "startup",
  "empresa",
  "vc",
  "nit",
  "hub",
] as const;

export type TipoCadastro = (typeof TIPOS_CADASTRO)[number];
