import { ReactNode } from "react";

export function TextoMarcaDagua({ children }: { children?: ReactNode }) {
  return (
    <span
      className="text-end"
      style={{
        color: "transparent",
        backgroundColor: "#bbb",
        textShadow: "0px 2px 2px rgb(255 255 255 / 100%)",
        backgroundClip: "text",
        WebkitBackgroundClip: "text",
        fontWeight: "bolder",
      }}
    >
      {children}
    </span>
  );
}
