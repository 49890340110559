import { Button, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";

import {
  InputHandlerUpdateCientistaSemId,
  TypeReturnHandlerUpdateCientista,
} from "../../../../domain/usecases/interfaces/handlerUpdateCientista";
import { Cientista } from "../../../../domain/entities/Cientista";
import { InputPremiacoes } from "../../../generalComponents/InputPremiacoes/InputPremiacoes";
import { InputSobre } from "../../../generalComponents/InputSobre";
import { InputUnidadesInstituicao } from "../../../generalComponents/InputUnidadesInstituicao";
import { InputAreasConhecimento } from "../../../generalComponents/InputAreasConhecimento";
import { InputGruposDePesquisa } from "../../../generalComponents/InputGruposDePesquisa/InputGruposDePesquisa";
import { InputTecnologias } from "../../../generalComponents/InputTecnologias/InputTecnologias";

export function ModalEditCientista({
  initialValue,
  show,
  onShowChange,
  handlerUpdateCientista,
  onUpdate,
}: {
  initialValue: InputHandlerUpdateCientistaSemId;
  show: boolean;
  onShowChange: (show: boolean) => void;
  handlerUpdateCientista: (
    input: InputHandlerUpdateCientistaSemId
  ) => TypeReturnHandlerUpdateCientista;
  onUpdate: () => void;
}) {
  const [cientistaToEdit, setCientistaToEdit] =
    useState<InputHandlerUpdateCientistaSemId>({});

  const [error, setError] = useState<
    Error | Partial<Record<keyof Cientista, Error>>
  >();
  const [carregando, setCarregando] = useState<boolean>(false);
  const [isEditingGrupoPesquisa, setIsEditingGrupoPesquisa] =
    useState<boolean>(false);
  const [isEditingPremiacoes, setIsEditingPremiacoes] =
    useState<boolean>(false);
  const [isEditingTecnologia, setIsEditingTecnologia] =
    useState<boolean>(false);

  useEffect(() => {
    setCientistaToEdit(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const cientistaValidateResult = Cientista.validateUpdate(cientistaToEdit);
    if (cientistaValidateResult.ok) {
      setError(undefined);
    } else {
      setError(cientistaValidateResult.error);
    }
  }, [cientistaToEdit]);

  return (
    <Modal
      show={show}
      onHide={() => onShowChange(false)}
      centered
      scrollable={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>Editar cientista</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputSobre
          label="Sobre"
          value={cientistaToEdit?.sobre?.conteudo ?? ""}
          onChange={(sobre) => {
            setCientistaToEdit({
              ...cientistaToEdit,
              sobre: {
                conteudo: sobre,
              },
            });
          }}
        />

        <InputUnidadesInstituicao
          label="Unidades da Instituição (ICT)*"
          value={cientistaToEdit.unidades ?? []}
          onChange={(unidades) => {
            setCientistaToEdit({
              ...cientistaToEdit,
              unidades,
            });
          }}
          validate={Cientista.validateUnidades}
          className="mb-3"
        />

        <InputAreasConhecimento
          label="Área(s) de Conhecimento*"
          value={cientistaToEdit.areas_de_conhecimento ?? []}
          onChange={(areas_de_conhecimento) => {
            setCientistaToEdit({ ...cientistaToEdit, areas_de_conhecimento });
          }}
          validate={Cientista.validateAreasConhecimento}
          className="mb-3"
        />

        <InputGruposDePesquisa
          label="Grupos de Pesquisa"
          value={cientistaToEdit.grupos_de_pesquisa ?? []}
          onListChange={(grupos_de_pesquisa) => {
            setCientistaToEdit({ ...cientistaToEdit, grupos_de_pesquisa });
          }}
          showTip={false}
          className="mb-3"
          isEditing={setIsEditingGrupoPesquisa}
        />

        <InputPremiacoes
          label="Premiações"
          value={cientistaToEdit.premiacoes ?? []}
          onChange={(premiacoes) => {
            setCientistaToEdit({ ...cientistaToEdit, premiacoes });
          }}
          showTip={false}
          className="mb-3"
          isEditing={setIsEditingPremiacoes}
        />

        <InputTecnologias
          label="Tecnologias"
          tecnologias={cientistaToEdit.tecnologias ?? []}
          onListChange={(tecnologias) => {
            setCientistaToEdit({ ...cientistaToEdit, tecnologias });
          }}
          showTip={false}
          isEditing={setIsEditingTecnologia}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="success"
          disabled={
            !!error ||
            carregando ||
            isEditingGrupoPesquisa ||
            isEditingPremiacoes ||
            isEditingTecnologia
          }
          onClick={async () => {
            setCarregando(true);

            const handlerUpdateResult = await handlerUpdateCientista(
              cientistaToEdit
            );

            setCarregando(false);

            if (!handlerUpdateResult.ok) {
              console.warn(handlerUpdateResult.error);

              setError(handlerUpdateResult.error);

              return;
            } else {
              onUpdate();
            }

            onShowChange(false);
          }}
        >
          Salvar cientista
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
