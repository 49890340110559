import HeaderUsuarioFromContext from "./HeaderUsuarioFromContext";
import { CardHeader } from "./CardHeader";

export function CardHeaderUsuarioFromContext() {
  return (
    <CardHeader>
      <HeaderUsuarioFromContext />
    </CardHeader>
  );
}
