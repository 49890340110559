import { createContext } from "react";

import { InvestidorAnjo } from "../../domain/entities/InvestidorAnjo";
import { Result } from "../../typings";
import {
  InputHandlerUpdateInvestidor,
  InputHandlerUpdateInvestidorSemId,
} from "../../domain/usecases/interfaces/handlerUpdateInvestidor";

export const PerfilInvestidorContext = createContext<{
  perfilInvestidorResult: TypePerfilInvestidorContext;
  refreshInvestidor?: () => void;
  updateInvestidor?: (
    input: InputHandlerUpdateInvestidorSemId
  ) => Promise<Result<InputHandlerUpdateInvestidor>>;
}>({
  perfilInvestidorResult: undefined,
  refreshInvestidor: undefined,
  updateInvestidor: undefined,
});

export type TypePerfilInvestidorContext =
  | Result<Readonly<InvestidorAnjo>>
  | undefined;
