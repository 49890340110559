import { ReactElement, useEffect, useState } from "react";

import { UsuarioScyggzValidator } from "../../domain/entities/UsuarioScyggzValidator";

export function InputHeadlineUsuario({
  label,
  value = "",
  onChange,
}: {
  label: ReactElement | string;
  value?: string;
  onChange: (headline: string) => void;
}) {
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  useEffect(() => {
    const errorHeadline = UsuarioScyggzValidator.isInvalidHeadline(value);
    if (errorHeadline) {
      setError(errorHeadline.message);
    } else {
      setError(undefined);
    }
  }, [value]);

  return (
    <div className={"mb-3"}>
      <label className="form-label fw-bolder m-0" htmlFor="iptHeadline">
        {label}
      </label>

      <input
        className={"form-control" + (error && isChanged ? " is-invalid" : "")}
        type="text"
        title="headline"
        max={UsuarioScyggzValidator.MAX_SIZE_HEADLINE}
        onChange={({ target: { value } }) => {
          onChange(value);
          setIsChanged(true);
        }}
        onBlur={() => setIsChanged(true)}
        id="iptHeadline"
        value={value}
      />

      <div className="invalid-feedback">{error}</div>
    </div>
  );
}
