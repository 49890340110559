import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";

import { EstagioStartup } from "../../../../domain/entities/EstagioStartup";
import { LocalizacaoOutput } from "../../../../domain/entities/localizacao";
import { MecanismoDeSolucaoTecnologicaOutput } from "../../../../domain/entities/mecanismoDeSolucaoTecnologica";
import { ModeloNegocioOutput } from "../../../../domain/entities/modeloNegocio";
import { PremiacaoOutput } from "../../../../domain/entities/premiacao";
import { SegmentoAtuacaoOutput } from "../../../../domain/entities/segmentoAtuacao";
import {
  InputHandlerUpdateMentorSemId,
  TypeReturnHandlerUpdateMentor,
} from "../../../../domain/usecases/interfaces/handlerUpdateMentor";
import { InfoDisponibilidadeMensal } from "../components/generalComponents/InfoDisponibilidadeMensal";
import { InfoEstagioInteresse } from "../components/generalComponents/InfoEstagioInteresse";
import { InfoLocalidade } from "../components/generalComponents/InfoLocalidade";
import { InfoMecanismoSolucao } from "../components/generalComponents/InfoMecanismoSolucao";
import { InfoModeloNegocio } from "../components/generalComponents/InfoModeloNegocio";
import { InfoPremiacoes } from "../components/generalComponents/InfoPremiacoes";
import { InfoSegmentoAtuacao } from "../components/generalComponents/InfoSegmentoAtuacao";
import { InfoSobre } from "../components/generalComponents/InfoSobre";
import { ModalEditMentor } from "./ModalEditMentor";

export const PerfilInfoMentor = ({
  interesse_startup_estagio,
  interesse_startup_mecanismo_solucao,
  interesse_startup_modelo_negocio,
  interesse_startup_segmento_atuacao,
  sobre_o_mentor,
  horas_disponibilidade_mensal,
  interesse_startup_localizacoes,
  premiacoes,
  refreshMentor,
  updateMentor,
}: {
  sobre_o_mentor: string;
  interesse_startup_estagio: EstagioStartup[];
  interesse_startup_modelo_negocio: ModeloNegocioOutput[];
  interesse_startup_segmento_atuacao: SegmentoAtuacaoOutput[];
  interesse_startup_mecanismo_solucao: MecanismoDeSolucaoTecnologicaOutput[];
} & Partial<{
  horas_disponibilidade_mensal: number;
  premiacoes: PremiacaoOutput[];
  interesse_startup_localizacoes: LocalizacaoOutput[];
  refreshMentor: (() => void) | undefined;
  updateMentor:
    | ((input: InputHandlerUpdateMentorSemId) => TypeReturnHandlerUpdateMentor)
    | undefined;
}>) => {
  const [showModalEdit, setShowModalEdit] = useState<boolean>(false);

  return (
    <section className="position-relative">
      {updateMentor && (
        <>
          <button
            type="button"
            className="btn bg-body-secondary position-absolute top-0 start-100 translate-middle rounded-circle text-secondary"
            onClick={() => setShowModalEdit(true)}
          >
            <FontAwesomeIcon icon={faPencil} />
          </button>

          <ModalEditMentor
            initialValue={{
              horas_disponibilidade_mensal,
              sobre_o_mentor,
              interesse_startup_estagio,
              interesse_startup_localizacoes,
              interesse_startup_modelo_negocio,
              interesse_startup_segmento_atuacao,
              interesse_startup_mecanismo_solucao,
              premiacoes,
            }}
            show={showModalEdit}
            onShowChange={(show) => {
              setShowModalEdit(show);
            }}
            handlerUpdateMentor={updateMentor}
            onUpdate={() => {
              if (refreshMentor) refreshMentor();
            }}
          />
        </>
      )}

      <InfoSobre sobre={sobre_o_mentor} />

      <div className="mb-4">
        <InfoDisponibilidadeMensal
          disponibilidade={horas_disponibilidade_mensal}
        />
      </div>

      <div className="mb-4 row mx-0">
        <div className="col-12 col-md-6 p-0">
          {interesse_startup_estagio.length > 0 && (
            <InfoEstagioInteresse
              estagio_interesse={interesse_startup_estagio}
            />
          )}

          {(interesse_startup_localizacoes?.length ?? 0) > 0 && (
            <InfoLocalidade localidades={interesse_startup_localizacoes} />
          )}

          {interesse_startup_modelo_negocio.length > 0 && (
            <InfoModeloNegocio
              modeloNegocio={interesse_startup_modelo_negocio}
            />
          )}
        </div>

        <div className="col-12 col-md-6 p-0">
          {interesse_startup_segmento_atuacao.length > 0 && (
            <InfoSegmentoAtuacao
              segmentoAtuacao={interesse_startup_segmento_atuacao}
            />
          )}

          {interesse_startup_mecanismo_solucao.length > 0 && (
            <InfoMecanismoSolucao
              mecanismoSolucao={interesse_startup_mecanismo_solucao}
            />
          )}
        </div>
      </div>

      {(premiacoes?.length ?? 0) > 0 && (
        <InfoPremiacoes classes="mb-4" premiacoes={premiacoes} />
      )}
    </section>
  );
};
