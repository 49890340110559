import { useContext, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faLock } from "@fortawesome/free-solid-svg-icons";

import { ResizeContext } from "../../hooks/useResize";
import { UsuarioScyggzValidator } from "../../domain/entities/UsuarioScyggzValidator";

export function InputPassword({
  value,
  onChange,
  className,
}: {
  value: string;
  onChange: (password: string) => void;
  className?: string;
}) {
  const { responsive } = useContext(ResizeContext);

  const [showPassword, setShowPassword] = useState(false);
  const [isChanged, setIsChanged] = useState<boolean>(false);

  const error = useMemo<string | undefined>(() => {
    const isInvalidPassword = UsuarioScyggzValidator.isInvalidPassword(value);

    if (isInvalidPassword) {
      return isInvalidPassword.message;
    } else {
      return undefined;
    }
  }, [value]);

  return (
    <div className={"input-group mb-2 has-validation " + (className ?? "")}>
      {!responsive && (
        <span className="input-group-text border-end-0 bg-secondary bg-opacity-10">
          <FontAwesomeIcon icon={faLock} className="opacity-25" />
        </span>
      )}
      <input
        type={showPassword ? "text" : "password"}
        name="password"
        onChange={({ target: { value } }) => {
          onChange(value);
          setIsChanged(true);
        }}
        onBlur={() => {
          setIsChanged(true);
        }}
        value={value}
        className={`${
          responsive.sm
            ? "imputMobile"
            : "form-control border-start-0 border-end-0 bg-secondary bg-opacity-10 text-secondary"
        } ${error && isChanged ? " is-invalid" : ""}`}
        placeholder="Senha*"
      />
      <button
        style={{
          top: responsive.sm ? "14px" : "",
          border: responsive.sm ? "none" : "",
        }}
        className={`${
          responsive.sm
            ? "btn bg-white bg-opacity-10 m-0 position-absolute lh-1 shadow-none end-0"
            : "btn border-start-0 bg-white border bg-opacity-10 m-0"
        }`}
        type="button"
        onClick={() => {
          setShowPassword(!showPassword);
        }}
        title={showPassword ? "Esconder senha" : "Exibir senha"}
      >
        <FontAwesomeIcon
          icon={showPassword ? faEyeSlash : faEye}
          className="opacity-25"
        />
      </button>
      <div className="invalid-feedback">{error}</div>
    </div>
  );
}
