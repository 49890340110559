import { Result } from "../../typings";
import { AreaEspecializacaoOutput } from "./areaEspecializacaoInvestidorAnjo";
import { EstagioStartup } from "./EstagioStartup";
import {
  MAXIMO_HORAS_DISPONIBILIDADE_MENSAL,
  MINIMO_HORAS_DISPONIBILIDADE_MENSAL,
} from "./HorasMensal";
import { LocalizacaoOutput } from "./localizacao";
import { MecanismoDeSolucaoTecnologicaOutput } from "./mecanismoDeSolucaoTecnologica";
import { MentorValidator } from "./MentorValidator";
import { ModeloNegocioOutput } from "./modeloNegocio";
import { Premiacao, PremiacaoInput, PremiacaoOutput } from "./premiacao";
import { SegmentoAtuacaoOutput } from "./segmentoAtuacao";

export class Mentor {
  public habilitado?: boolean;
  public interesse_startup_localizacoes?: LocalizacaoOutput[];
  public premiacoes?: Premiacao[];
  public horas_disponibilidade_mensal?: number;

  static readonly MINIMO_HORAS_DISPONIBILIDADE_MENSAL =
    MINIMO_HORAS_DISPONIBILIDADE_MENSAL;
  static readonly MAXIMO_HORAS_DISPONIBILIDADE_MENSAL =
    MAXIMO_HORAS_DISPONIBILIDADE_MENSAL;

  private constructor(
    public user_id: string,
    public nome: string,
    public areas_de_especializacao: AreaEspecializacaoOutput[],
    public sobre_o_mentor: string,
    public interesse_startup_estagio: EstagioStartup[],
    interesse_startup_localizacoes: LocalizacaoOutput[],
    public interesse_startup_modelo_negocio: ModeloNegocioOutput[],
    public interesse_startup_segmento_atuacao: SegmentoAtuacaoOutput[],
    public interesse_startup_mecanismo_solucao: MecanismoDeSolucaoTecnologicaOutput[],
    horas_disponibilidade_mensal: number,
    premiacoes: Premiacao[],
    public created_in: Date,
    habilitado: boolean | undefined
  ) {
    this.interesse_startup_localizacoes = interesse_startup_localizacoes;
    this.horas_disponibilidade_mensal = horas_disponibilidade_mensal;
    this.premiacoes = premiacoes;
    this.habilitado = habilitado;
  }

  public static create(
    inputMentor: MentorInput
  ): Result<MentorInput, Partial<Record<keyof Mentor, Error>>> {
    const validateCreateResult = this.validateCreate(inputMentor);

    if (!validateCreateResult.ok) {
      return validateCreateResult;
    }

    return {
      ok: true,
      value: inputMentor,
    };
  }

  public static validateCreate(
    attributes: Mentor
  ): Result<null, Partial<Record<keyof Mentor, Error>>> {
    return new MentorValidator().validateCreate(attributes);
  }

  public static validateUpdate(
    attributes: Partial<Mentor>
  ): Result<null, Partial<Record<keyof Mentor, Error>>> {
    return new MentorValidator().validateUpdate(attributes);
  }

  static validateUserId(user_id: string): Result<null> {
    user_id = user_id.trim();
    const isUserIdValid: boolean = user_id.length > 0;
    if (!isUserIdValid) {
      return {
        ok: false,
        error: new Error("User id inválido"),
      };
    }

    return {
      ok: true,
      value: null,
    };
  }

  static validateInteresseStartupEstagio(
    interesse_startup_estagio: EstagioStartup[]
  ): Result<null> {
    const isInteresseStartupEstagioValid: boolean =
      interesse_startup_estagio.length > 0;

    if (!isInteresseStartupEstagioValid) {
      return {
        ok: false,
        error: new Error("Precisa especificar estágio startup de interesse"),
      };
    }

    return {
      ok: true,
      value: null,
    };
  }

  static validateInteresseStartupModeloNegocio(
    interesse_startup_modelo_negocio: ModeloNegocioOutput[]
  ): Result<null> {
    const isInteresseStartupModeloNegocioValid: boolean =
      interesse_startup_modelo_negocio.length > 0;
    if (!isInteresseStartupModeloNegocioValid) {
      return {
        ok: false,
        error: new Error(
          "Precisa especificar modelo de negócio da startup de interesse"
        ),
      };
    }

    return {
      ok: true,
      value: null,
    };
  }

  static validateInteresseStartupSegmentoAtuacao(
    interesse_startup_segmento_atuacao: SegmentoAtuacaoOutput[]
  ): Result<null> {
    const isInteresseStartupSegmentoAtuacaoValid: boolean =
      interesse_startup_segmento_atuacao.length > 0;
    if (!isInteresseStartupSegmentoAtuacaoValid) {
      return {
        ok: false,
        error: new Error(
          "Precisa especificar segmento de atuação da startup de interesse"
        ),
      };
    }

    return {
      ok: true,
      value: null,
    };
  }

  static validateInteresseStartupMecanismoSolucao(
    interesse_startup_mecanismo_solucao: SegmentoAtuacaoOutput[]
  ): Result<null> {
    const isInteresseStartupSegmentoAtuacaoValid: boolean =
      interesse_startup_mecanismo_solucao.length > 0;
    if (!isInteresseStartupSegmentoAtuacaoValid) {
      return {
        ok: false,
        error: new Error(
          "Precisa especificar segmento de atuação da startup de interesse"
        ),
      };
    }

    return {
      ok: true,
      value: null,
    };
  }

  public static validatePremiacoes(
    premiacoes: PremiacaoInput[]
  ): Result<
    PremiacaoInput[],
    { [P in keyof Partial<PremiacaoInput>]: Error } | undefined
  > {
    let hasError = false;

    const errors = premiacoes
      .map((premiacao) => Premiacao.validate(premiacao))
      .map((result) => {
        if (result.ok) {
          return undefined;
        } else {
          hasError = true;
          return result.error;
        }
      });

    if (hasError) {
      return {
        ok: false,
        error: errors[0],
      };
    } else {
      return {
        ok: true,
        value: premiacoes,
      };
    }
  }
}

export type MentorInput = {
  user_id: string;
  nome: string;
  sobre_o_mentor: string;
  areas_de_especializacao: AreaEspecializacaoOutput[];
  interesse_startup_estagio: EstagioStartup[];
  interesse_startup_modelo_negocio: ModeloNegocioOutput[];
  interesse_startup_segmento_atuacao: SegmentoAtuacaoOutput[];
  interesse_startup_mecanismo_solucao: MecanismoDeSolucaoTecnologicaOutput[];
  created_in: Date;
} & Partial<{
  habilitado: boolean;
  horas_disponibilidade_mensal: number;
  premiacoes: PremiacaoOutput[];
  interesse_startup_localizacoes: LocalizacaoOutput[];
}>;

export type MentorOutput = MentorInput;
