import { StylesConfig } from "react-select";
import CreatableSelect from "react-select/creatable";
import { useContext, useEffect, useState } from "react";
import makeAnimated from "react-select/animated";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { ResizeContext } from "../../hooks/useResize";
import { HandlersContext } from "../../globalStore/HandlersProviders/HandlersContext";
import { SelectAssociacoesAnjo } from "../../typings";

const animatedComponents = makeAnimated();

export function InputAssociacoesAnjo({
  label,
  value,
  onChange,
  validate,
  className,
}: {
  label: string;
  value: SelectAssociacoesAnjo[];
  onChange: (associacoesAnjo: SelectAssociacoesAnjo[]) => void;
  validate: (associacoesAnjo: SelectAssociacoesAnjo[]) => Error | undefined;
  className?: string;
}) {
  const { handlerGetAssociacaoAnjo } = useContext(HandlersContext);
  const { responsive } = useContext(ResizeContext);

  const [errorToShow, setErrorToShow] = useState<string>();
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [options, setOptions] = useState<SelectAssociacoesAnjo[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    if (handlerGetAssociacaoAnjo) {
      handlerGetAssociacaoAnjo
        .getAllAssociacaoAnjo()
        .then((getAllAssociacaoAnjoResult) => {
          if (getAllAssociacaoAnjoResult.ok)
            setOptions(getAllAssociacaoAnjoResult.value);

          setIsLoading(false);
        });
    }
  }, [handlerGetAssociacaoAnjo]);

  useEffect(() => {
    const validateErro = validate(value);

    if (validateErro) {
      setErrorToShow(validateErro.message);
    } else {
      setErrorToShow(undefined);
    }
  }, [validate, value]);

  const customStylesSelect: StylesConfig<SelectAssociacoesAnjo, true> = {
    control: () => ({
      border: "none",
      borderBottom: "3px #666600 solid",
      display: "flex",
      background: " #fff",
      borderBottomLeftRadius: "8px",
      textAlign: "left",
    }),
  };

  return (
    <div className={className}>
      <label
        className="form-label fw-bolder m-0 d-block"
        htmlFor="selAssociacoesAnjo"
      >
        {label}
      </label>
      <CreatableSelect<SelectAssociacoesAnjo, true>
        value={value}
        onChange={(areas_de_especializacao) => {
          onChange([...areas_de_especializacao]);
          setIsChanged(true);
        }}
        options={options}
        noOptionsMessage={() => "sem opção"}
        placeholder=""
        getOptionLabel={(o) => o.nome ?? o.label}
        getOptionValue={(o) => o.id ?? o.value}
        formatCreateLabel={(nome) => (
          <span
            className="rounded p-1 text-bg-success"
            title={"adicionar: " + nome}
          >
            <FontAwesomeIcon icon={faPlus} className="me-1" />

            {nome}
          </span>
        )}
        components={animatedComponents}
        createOptionPosition="first"
        isLoading={isLoading}
        inputId="selAssociacoesAnjo"
        isMulti
        onBlur={() => setIsChanged(true)}
        styles={responsive.sm ? customStylesSelect : undefined}
        className={errorToShow && isChanged ? "is-invalid " : ""}
      />

      <div className="invalid-feedback flex-fill">{errorToShow}</div>
    </div>
  );
}
