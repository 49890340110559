import { Firestore, setDoc, doc } from "@firebase/firestore";

import { Result } from "../typings";
import { HandlerUpdateEmpresa } from "../domain/usecases/interfaces/handlerUpdateEmpresa";
import { InputUpdateEmpresa } from "../domain/entities/Empresa";

export class HandlerUpdateEmpresaFirebase implements HandlerUpdateEmpresa {
  constructor(private firestore: Firestore) {}
  updateEmpresa(
    inputUpdateEmpresa: InputUpdateEmpresa
  ): Promise<Result<InputUpdateEmpresa, Error>> {
    const inputToFirebase: InputUpdateEmpresa = {
      id: inputUpdateEmpresa.id,
    };

    if (inputUpdateEmpresa.interesses_cientista_tecnologia_areas_aplicacao) {
      inputToFirebase.interesses_cientista_tecnologia_areas_aplicacao =
        inputUpdateEmpresa.interesses_cientista_tecnologia_areas_aplicacao;
    }
    if (inputUpdateEmpresa.interesses_cientista_tecnologia_mecanismos_solucao) {
      inputToFirebase.interesses_cientista_tecnologia_mecanismos_solucao =
        inputUpdateEmpresa.interesses_cientista_tecnologia_mecanismos_solucao;
    }
    if (inputUpdateEmpresa.interesses_cientista_tecnologia_area_conhecimento) {
      inputToFirebase.interesses_cientista_tecnologia_area_conhecimento =
        inputUpdateEmpresa.interesses_cientista_tecnologia_area_conhecimento;
    }
    if (inputUpdateEmpresa.interesses_cientista_grupo_pesquisa) {
      inputToFirebase.interesses_cientista_grupo_pesquisa =
        inputUpdateEmpresa.interesses_cientista_grupo_pesquisa;
    }
    if (inputUpdateEmpresa.interesse_startup_estagio) {
      inputToFirebase.interesse_startup_estagio =
        inputUpdateEmpresa.interesse_startup_estagio;
    }
    if (inputUpdateEmpresa.interesse_startup_localizacoes) {
      inputToFirebase.interesse_startup_localizacoes =
        inputUpdateEmpresa.interesse_startup_localizacoes;
    }
    if (inputUpdateEmpresa.interesse_startup_modelo_negocio) {
      inputToFirebase.interesse_startup_modelo_negocio =
        inputUpdateEmpresa.interesse_startup_modelo_negocio;
    }
    if (inputUpdateEmpresa.interesse_startup_segmento_atuacao) {
      inputToFirebase.interesse_startup_segmento_atuacao =
        inputUpdateEmpresa.interesse_startup_segmento_atuacao;
    }
    if (inputUpdateEmpresa.interesse_startup_mecanismo_solucao) {
      inputToFirebase.interesse_startup_mecanismo_solucao =
        inputUpdateEmpresa.interesse_startup_mecanismo_solucao;
    }

    return new Promise((resolve) => {
      setDoc(
        doc(this.firestore, "empresas/" + inputToFirebase.id),
        inputToFirebase,
        {
          merge: true,
        }
      )
        .then(() =>
          resolve({
            ok: true,
            value: inputToFirebase,
          })
        )
        .catch((error) =>
          resolve({
            ok: false,
            error,
          })
        );
    });
  }
}
