import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";

import {
  TecnologiaDoCientista,
  TecnologiaDoCientistaInput,
  TecnologiaDoCientistaOutput,
} from "../../../../../domain/entities/tecnologiaDoCientista";
import { Result } from "../../../../../typings";
import { BadgeInfo } from "./BadgeInfo";
import { InputAreasConhecimento } from "../../../../generalComponents/InputAreasConhecimento";
import { InputMecanismosSolucao } from "../../../../generalComponents/InputMecanismosSolucao";
import { InputAreasAplicacaoTecnologia } from "../../../../generalComponents/InputAreasAplicacaoTecnologia";

export const InfoTecnologias = ({
  tecnologias,
  handlerAdd,
}: {
  tecnologias?: TecnologiaDoCientistaOutput[];
  handlerAdd?: (
    inputAdd: TecnologiaDoCientistaOutput
  ) => Promise<Result<unknown>>;
}) => {
  const [showModalAdd, setShowModalAdd] = useState<boolean>(false);
  const [carregando, setCarregando] = useState<boolean>(false);
  const [tecnologiaToAdd, setTecnologiaToAdd] =
    useState<TecnologiaDoCientistaInput>({
      nome: "",
      resumo: "",
      areas_de_aplicacao: [],
      areas_de_conhecimento: [],
      mecanismos_de_solucao: [],
    });
  const [errorInputNome, setErrorInputNome] = useState<string>();
  const [errorAreaAplicacao, setErrorAreaAplicacao] = useState<string>();
  const [errorAreaConhecimento, setErrorAreaConhecimento] = useState<string>();
  const [errorMecanismoSolucao, setErroMecanismoSolucao] = useState<string>();
  const [erroResumo, setErroResumo] = useState<string>();

  useEffect(() => {
    const validateResult = TecnologiaDoCientista.validate(tecnologiaToAdd);
    if (validateResult.ok) {
      setErrorInputNome(undefined);
      setErrorAreaAplicacao(undefined);
      setErrorAreaConhecimento(undefined);
      setErroMecanismoSolucao(undefined);
      setErroResumo(undefined);
    } else {
      if (validateResult.error.nome)
        setErrorInputNome(validateResult.error.nome.message);
      else {
        setErrorInputNome(undefined);
      }

      if (validateResult.error.areas_de_aplicacao)
        setErrorAreaAplicacao(validateResult.error.areas_de_aplicacao.message);
      else {
        setErrorAreaAplicacao(undefined);
      }

      if (validateResult.error.areas_de_conhecimento)
        setErrorAreaConhecimento(
          validateResult.error.areas_de_conhecimento.message
        );
      else {
        setErrorAreaConhecimento(undefined);
      }

      if (validateResult.error.mecanismos_de_solucao)
        setErroMecanismoSolucao(
          validateResult.error.mecanismos_de_solucao.message
        );
      else {
        setErroMecanismoSolucao(undefined);
      }

      if (validateResult.error.resumo)
        setErroResumo(validateResult.error.resumo.message);
      else {
        setErroResumo(undefined);
      }
    }
  }, [tecnologiaToAdd]);

  return (
    <section>
      <h5 className="text-green-strong fw-bolder mb-3">Tecnologias</h5>

      <div className="ps-4 position-relative">
        {handlerAdd && (
          <button
            type="button"
            className="btn btn-primary position-absolute top-0 start-100 translate-middle rounded-circle"
            onClick={() => setShowModalAdd(true)}
          >
            <FontAwesomeIcon icon={faAdd} />
          </button>
        )}

        {tecnologias && tecnologias.length > 0 && (
          <>
            {tecnologias.map((tecnologia, i) => (
              <div key={i} className="mb-5">
                <h6 className="fw-bolder" children={tecnologia.nome} />

                <p>{tecnologia.resumo}</p>

                <div className="d-flex p-0 gap-2">
                  <div className="col-md-4">
                    <small className="m-0 fw-bolder">
                      Área de Conhecimento
                    </small>
                    <div>
                      {tecnologia.areas_de_conhecimento.map((area, i) => (
                        <BadgeInfo
                          key={i}
                          children={area.nome}
                          classes="text-break text-wrap"
                        />
                      ))}
                    </div>
                  </div>

                  <div className="col-md-4">
                    <small className="m-0 fw-bolder">Área de Aplicação</small>
                    <div>
                      {tecnologia.areas_de_aplicacao.map((area, i) => (
                        <BadgeInfo
                          key={i}
                          children={area.nome}
                          classes="text-break text-wrap"
                        />
                      ))}
                    </div>
                  </div>

                  <div className="col-md-4">
                    <small className="m-0 fw-bolder">
                      Mecanismo de Solução Tecnológica
                    </small>
                    <div>
                      {tecnologia.mecanismos_de_solucao.map((area, i) => (
                        <BadgeInfo
                          key={i}
                          children={area.nome}
                          classes="text-break text-wrap"
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}

        <Modal
          show={showModalAdd}
          onHide={() => setShowModalAdd(false)}
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <Modal.Title className="mb-3">Adicionar premiação</Modal.Title>

            <div className="mb-3">
              <label className="form-label">Nome da tecnologia:</label>
              <input
                name="nome"
                className={
                  "form-control" + (errorInputNome ? " is-invalid " : "")
                }
                type="text"
                onChange={({ target: { value } }) => {
                  setTecnologiaToAdd({
                    ...tecnologiaToAdd,
                    nome: value,
                  });
                }}
                value={tecnologiaToAdd.nome}
                title="Nome da tecnologia"
              />
              <div className="invalid-feedback flex-fill text-center">
                {errorInputNome}
              </div>
            </div>

            <div className="mb-3" title="Áreas de aplicação da tecnologia">
              <InputAreasAplicacaoTecnologia
                label="Áreas de aplicação da tecnologia*"
                value={tecnologiaToAdd.areas_de_aplicacao}
                onChange={(areas_de_aplicacao) => {
                  setTecnologiaToAdd({
                    ...tecnologiaToAdd,
                    areas_de_aplicacao,
                  });
                }}
                validate={
                  TecnologiaDoCientista.validateAreasAplicacaoTecnologia
                }
              />
            </div>

            <div className="mb-3" title="Áreas de conhecimento da tecnologia">
              <InputAreasConhecimento
                label="Áreas de conhecimento da tecnologia*"
                value={tecnologiaToAdd.areas_de_conhecimento}
                onChange={(areas_de_conhecimento) => {
                  setTecnologiaToAdd({
                    ...tecnologiaToAdd,
                    areas_de_conhecimento: [...areas_de_conhecimento],
                  });
                }}
                validate={TecnologiaDoCientista.validateAreasConhecimento}
              />
            </div>

            <div className="mb-3" title="Mecanismo de solução tecnológica">
              <InputMecanismosSolucao
                label="Mecanismo de solução tecnológica*"
                value={tecnologiaToAdd.mecanismos_de_solucao}
                onChange={(mecanismos_de_solucao) => {
                  setTecnologiaToAdd({
                    ...tecnologiaToAdd,
                    mecanismos_de_solucao,
                  });
                }}
                validate={TecnologiaDoCientista.validateMecanismosTecnologia}
              />
            </div>

            <div className="position-relative">
              <span className="position-absolute top-0 end-0 text-black-50 fw-lighter">
                {tecnologiaToAdd.resumo.length}
              </span>
              <label className="form-label">Resumo da tecnologia</label>
              <textarea
                title="Resumo da tecnologia"
                rows={10}
                name="resumo"
                className={"form-control " + (erroResumo ? " is-invalid" : "")}
                value={tecnologiaToAdd.resumo}
                onChange={({ target: { value: resumo } }) =>
                  setTecnologiaToAdd({ ...tecnologiaToAdd, resumo })
                }
                style={{ resize: "none", height: "296px", width: "100%" }}
              ></textarea>
              <div className="invalid-feedback flex-fill text-center">
                {erroResumo}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              disabled={
                !!errorInputNome ||
                !!errorAreaAplicacao ||
                !!errorAreaConhecimento ||
                !!errorMecanismoSolucao ||
                !!erroResumo ||
                carregando
              }
              onClick={async () => {
                if (handlerAdd) {
                  setCarregando(true);
                  const handlerAddResult = await handlerAdd(tecnologiaToAdd);
                  setCarregando(false);

                  if (!handlerAddResult.ok) {
                    console.warn(handlerAddResult.error);
                    return;
                  } else {
                    setTecnologiaToAdd({
                      nome: "",
                      resumo: "",
                      areas_de_aplicacao: [],
                      areas_de_conhecimento: [],
                      mecanismos_de_solucao: [],
                    });
                    setShowModalAdd(false);
                  }
                }
              }}
            >
              Salvar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </section>
  );
};
