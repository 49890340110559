import { MecanismoDeSolucaoTecnologicaOutput } from "../../../../../domain/entities/mecanismoDeSolucaoTecnologica";
import { BadgeInfo } from "./BadgeInfo";

export const InfoMecanismoSolucao = ({
  mecanismoSolucao = [],
}: {
  mecanismoSolucao?: MecanismoDeSolucaoTecnologicaOutput[];
}) => {
  return (
    <>
      {mecanismoSolucao.length > 0 && (
        <div className="mb-3">
          <h5 className="fw-lighter">Mecanismo de solução tecnológica</h5>

          {mecanismoSolucao.map((mecanismo, i) => (
            <BadgeInfo key={i} children={mecanismo.nome} />
          ))}
        </div>
      )}
    </>
  );
};
