import { useNavigate } from "react-router-dom";

import { HandlerCacheVentureCapital } from "../../../../../domain/usecases/interfaces/HandlerCacheVentureCapital";
import { HandlerCreateVentureCapital } from "../../../../../domain/usecases/interfaces/handlerCreateVentureCapital";
import { HandlerGetModeloNegocio } from "../../../../../domain/usecases/interfaces/handlerGetModeloNegocio";
import { ContainerResponsive } from "../../../../generalComponents/ContainerResponsive";
import { ConteudoTeseDeInvestimentoVentureCapital } from "./ConteudoTeseDeInvestimentoVentureCapital";

export const PainelTeseDeInvestimentoVentureCapital = ({
  handlerCacheVentureCapital,
  handlerGetModeloNegocio,
  handlerCreateVentureCapital,
}: {
  handlerCacheVentureCapital: HandlerCacheVentureCapital;
  handlerGetModeloNegocio: HandlerGetModeloNegocio;
  handlerCreateVentureCapital: HandlerCreateVentureCapital;
}) => {
  const navigate = useNavigate();

  const paginacao = {
    start: 6,
    end: 7,
  };

  return (
    <section
      data-testid="painel-cadastro-logo-ventureCapital"
      style={{ height: "100%" }}
    >
      <ContainerResponsive
        backUrlMobile="/cadastro/venture_capital/5"
        paginacao={paginacao}
        textoMarcaDagua="Venture Capital"
        showLogo={true}
      >
        <ConteudoTeseDeInvestimentoVentureCapital
          handlerGetModeloNegocio={handlerGetModeloNegocio}
          handlerCacheVentureCapital={handlerCacheVentureCapital}
          onBack={() => {
            navigate("/cadastro/venture_capital/5");
          }}
          onNext={() => {
            navigate("/cadastro/venture_capital/7");
          }}
          handlerCreateVentureCapital={handlerCreateVentureCapital}
        />
      </ContainerResponsive>
    </section>
  );
};
