import { Firestore, collection, getDocs } from "@firebase/firestore";

import { Result } from "../typings";
import { HandlerGetModeloNegocio } from "../domain/usecases/interfaces/handlerGetModeloNegocio";
import { ModeloNegocioOutput } from "../domain/entities/modeloNegocio";

export class HandlerGetModeloNegocioFirebase
  implements HandlerGetModeloNegocio
{
  constructor(private firestore: Firestore) {}

  getAllModeloNegocio(): Promise<Result<ModeloNegocioOutput[], Error>> {
    return new Promise((resolve) => {
      getDocs(collection(this.firestore, "modelos_negocio_startup"))
        .then((querySnapshot) => {
          const modelos: ModeloNegocioOutput[] = querySnapshot.docs.map(
            (docSnapshot) => {
              const modelo = docSnapshot.data() as ModeloNegocioOutput;
              modelo.id = docSnapshot.id;
              return modelo;
            }
          );

          resolve({
            ok: true,
            value: modelos,
          });
        })
        .catch((error) =>
          resolve({
            ok: false,
            error,
          })
        );
    });
  }
}
