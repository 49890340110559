import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { HandlersDatabaseContext } from "../../../../globalStore/HandlersProviders/HandlersDatabaseContext";
import { Result } from "../../../../typings";
import { CurrentUserContext } from "../../../../globalStore/CurrentUserContext";
import { ConviteProgramaConexao } from "../../../../domain/entities/ConviteProgramaConexao";
import { ListItemConviteProgramaDeConexao } from "./ListItemConviteProgramaDeConexao";
import { ProgramaConexao } from "../../../../domain/entities/ProgramaConexao";

export function ListConviteProgramaDeConexao() {
  const { currentUserAuth } = useContext(CurrentUserContext);
  const {
    handlerConviteProgramaConexaoGetByAlvo,
    handlerProgramaConexaoGetByIds,
  } = useContext(HandlersDatabaseContext);

  const navigate = useNavigate();

  const [conviteResult, setConvitesResult] =
    useState<Result<ConviteProgramaConexaoWriteablePrograma[]>>();
  const [programasDosConvitesResult, setProgramasDosConvitesResult] =
    useState<Result<ProgramaConexao[]>>();

  const convites = useMemo<Result<ConviteProgramaConexao[]> | undefined>(() => {
    let convites: Result<ConviteProgramaConexao[]> | undefined;

    if (conviteResult?.ok) {
      if (programasDosConvitesResult?.ok) {
        programasDosConvitesResult.value.forEach((programaDoConvite) => {
          const conviteDoPrograma = conviteResult.value.find(
            (convite) => convite.programa_conexao.id == programaDoConvite.id
          );

          if (conviteDoPrograma) {
            conviteDoPrograma.programa_conexao = programaDoConvite;
          }
        });
      }

      convites = conviteResult;
    }

    return convites;
  }, [conviteResult, programasDosConvitesResult]);

  const refreshConvite = useCallback(() => {
    if (currentUserAuth.user_auth?.auth_id)
      handlerConviteProgramaConexaoGetByAlvo
        ?.getByUserAlvo(currentUserAuth.user_auth.auth_id)
        .then((convitesResult) => setConvitesResult(convitesResult));
  }, [
    currentUserAuth.user_auth?.auth_id,
    handlerConviteProgramaConexaoGetByAlvo,
  ]);

  useEffect(() => {
    refreshConvite();
  }, [refreshConvite]);
  useEffect(() => {
    if (conviteResult?.ok && conviteResult.value.length > 0) {
      handlerProgramaConexaoGetByIds
        ?.getByIds(conviteResult.value.map((convite) => convite.id))
        .then((programasResult) =>
          setProgramasDosConvitesResult(programasResult)
        );
    }
  }, [conviteResult, handlerProgramaConexaoGetByIds]);

  return (
    <>
      {convites ? (
        <>
          {convites.ok ? (
            <>
              {convites.value
                .filter((convite) => convite.status == undefined)
                .map((convite) => (
                  <ListItemConviteProgramaDeConexao
                    key={convite.id}
                    convite={convite}
                    className="mb-2"
                    onConviteRejected={refreshConvite}
                    onConviteAccepted={() => {
                      navigate(
                        "/programa_conexao/" + convite.programa_conexao.id
                      );
                    }}
                  />
                ))}
            </>
          ) : (
            <>erro</>
          )}
        </>
      ) : (
        <></>
        // <>carregando...</>
      )}
    </>
  );
}

type ConviteProgramaConexaoWriteablePrograma = Omit<
  ConviteProgramaConexao,
  "programa_conexao"
> & {
  programa_conexao: Pick<
    ProgramaConexao,
    | "id"
    | "nome"
    | "logo_path"
    | "stakeholder_type_group_a"
    | "stakeholder_type_group_b"
    | "message_to_a"
    | "message_to_b"
  >;
};
