import { useContext, useEffect } from "react";
import { ProgressBar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { HandlersContext } from "../../../../globalStore/HandlersProviders/HandlersContext";

export function ChecagemEmail({ oobCode }: { oobCode: string }) {
  const navigate = useNavigate();

  const { handlerAuth } = useContext(HandlersContext);

  useEffect(() => {
    if (handlerAuth && oobCode) {
      handlerAuth.applyActionCode(oobCode).finally(() => navigate("/"));
    }
  }, [handlerAuth, navigate, oobCode]);

  return (
    <>
      <ProgressBar animated variant="info" now={100} className="w-100" />
    </>
  );
}
