import { Navigate } from "react-router-dom";

import { PainelElegibilidadeCientista } from "../presentation/pages/cadastro/cientista/1/PainelElegibilidadeCientista";
import { PainelCadastroAuthCientista } from "../presentation/pages/cadastro/cientista/2/PainelCadastroAuthCientista";
import { PainelElegibilidadeEmpresa } from "../presentation/pages/cadastro/empresa/1/PainelElegibilidadeEmpresa";
import { PainelCadastroAuthEmpresa } from "../presentation/pages/cadastro/empresa/2/PainelCadastroAuthEmpresa";
import { PainelElegibilidadeHub } from "../presentation/pages/cadastro/hub/1/PainelElegibilidadeHub";
import { PainelCadastroAuthHub } from "../presentation/pages/cadastro/hub/2/PainelCadastroAuthHub";
import { PainelElegibilidadeInvestidor } from "../presentation/pages/cadastro/investidorAnjo/1/PainelElegibilidadeInvestidor";
import { PainelCadastroAuthInvestidor } from "../presentation/pages/cadastro/investidorAnjo/2/PainelCadastroAuthInvestidor";
import { PainelElegibilidadeMentor } from "../presentation/pages/cadastro/Mentor/1/PainelElegibilidadeMentor";
import { PainelCadastroAuthMentor } from "../presentation/pages/cadastro/Mentor/2/PainelCadastroAuthMentor";
import { PainelElegibilidadeNit } from "../presentation/pages/cadastro/nit/1/PainelElegibilidadeNit";
import { PainelCadastroAuthNit } from "../presentation/pages/cadastro/nit/2/PainelCadastroAuthNit";
import { PainelElegibilidadeStartup } from "../presentation/pages/cadastro/startup/1/PainelElegibilidadeStartup";
import { PainelCadastroAuthStartup } from "../presentation/pages/cadastro/startup/2/PainelCadastroAuthStartup";
import { PainelElegibilidadeVentureCapital } from "../presentation/pages/cadastro/ventureCapital/1/PainelElegibilidadeVentureCapital";
import { PainelCadastroAuthVentureCapital } from "../presentation/pages/cadastro/ventureCapital/2/PainelCadastroAuthVentureCapital";
import { PainelEscolhaPerfil } from "../presentation/pages/components/escolhaPerfil/PainelEscolhaPerfil";
import { PainelHomeExternal } from "../presentation/pages/home/PainelHomeExternal";
import { EsqueceuSenha } from "../presentation/pages/esqueceusenha/EsqueceuSenha";
import { PainelLogin } from "../presentation/pages/login/components/PainelLogin";
import { ResetPasswordEnviado } from "../presentation/pages/login/components/ResetPasswordEnviado";
import PageUnauthorized from "../presentation/pages/PageUnauthorized";
import { RouteGuardAceite } from "./RouteGuardAceite";
import { HandlerCacheNewUserStorage } from "../gateway/HandlerCacheNewUserStorage";

const handlerCacheNewUser = new HandlerCacheNewUserStorage();

export const rotasVisitante = [
  {
    path: "/",
    element: <PainelHomeExternal />,
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/user/*",
    element: <PageUnauthorized />,
    showHeader: true,
    showFooter: true,
  },
  {
    path: "/login",
    element: <PainelLogin />,
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/cadastro",
    element: <PainelEscolhaPerfil />,
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/cadastro/cientista/1",
    element: <PainelElegibilidadeCientista />,
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/cadastro/cientista/2",
    element: (
      <RouteGuardAceite
        required={"cientista"}
        redirectPath={"/cadastro/cientista/1"}
      >
        <PainelCadastroAuthCientista />
      </RouteGuardAceite>
    ),
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/cadastro/cientista/3",
    element: <Navigate to={"/cadastro/cientista/2"} />,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/cientista/4",
    element: <Navigate to={"/cadastro/cientista/2"} />,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/cientista/5",
    element: <Navigate to={"/cadastro/cientista/2"} />,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/cientista/6",
    element: <Navigate to={"/cadastro/cientista/2"} />,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/investidor/1",
    element: <PainelElegibilidadeInvestidor />,
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/cadastro/investidor/2",
    element: (
      <RouteGuardAceite
        required={"investidor"}
        redirectPath={"/cadastro/investidor/1"}
      >
        <PainelCadastroAuthInvestidor />
      </RouteGuardAceite>
    ),
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/cadastro/investidor/3",
    element: <Navigate to={"/cadastro/investidor/2"} />,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/investidor/4",
    element: <Navigate to={"/cadastro/investidor/2"} />,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/investidor/5",
    element: <Navigate to={"/cadastro/investidor/2"} />,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/investidor/6",
    element: <Navigate to={"/cadastro/investidor/2"} />,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/mentor/1",
    element: <PainelElegibilidadeMentor />,
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/cadastro/mentor/2",
    element: (
      <RouteGuardAceite required={"mentor"} redirectPath={"/cadastro/mentor/1"}>
        <PainelCadastroAuthMentor />
      </RouteGuardAceite>
    ),
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/cadastro/mentor/3",
    element: <Navigate to={"/cadastro/mentor/2"} />,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/mentor/4",
    element: <Navigate to={"/cadastro/mentor/2"} />,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/mentor/5",
    element: <Navigate to={"/cadastro/mentor/2"} />,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/mentor/6",
    element: <Navigate to={"/cadastro/mentor/2"} />,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/empresa/1",
    element: <PainelElegibilidadeEmpresa />,
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/cadastro/empresa/2",
    element: (
      <RouteGuardAceite
        required={"empresa"}
        redirectPath={"/cadastro/empresa/1"}
      >
        <PainelCadastroAuthEmpresa />
      </RouteGuardAceite>
    ),
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/cadastro/empresa/3",
    element: <Navigate to={"/cadastro/empresa/2"} />,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/empresa/4",
    element: <Navigate to={"/cadastro/empresa/2"} />,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/empresa/5",
    element: <Navigate to={"/cadastro/empresa/2"} />,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/empresa/6",
    element: <Navigate to={"/cadastro/empresa/2"} />,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/empresa/7",
    element: <Navigate to={"/cadastro/empresa/2"} />,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/empresa/8",
    element: <Navigate to={"/cadastro/empresa/2"} />,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/hub/1",
    element: <PainelElegibilidadeHub />,
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/cadastro/hub/2",
    element: (
      <RouteGuardAceite required={"hub"} redirectPath={"/cadastro/hub/1"}>
        <PainelCadastroAuthHub />
      </RouteGuardAceite>
    ),
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/cadastro/hub/3",
    element: <Navigate to={"/cadastro/hub/2"} />,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/hub/4",
    element: <Navigate to={"/cadastro/hub/2"} />,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/hub/5",
    element: <Navigate to={"/cadastro/hub/2"} />,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/hub/6",
    element: <Navigate to={"/cadastro/hub/2"} />,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/startup/1",
    element: (
      <PainelElegibilidadeStartup handlerCacheNewUser={handlerCacheNewUser} />
    ),
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/cadastro/startup/2",
    element: (
      <RouteGuardAceite
        required={"startup"}
        redirectPath={"/cadastro/startup/1"}
      >
        <PainelCadastroAuthStartup />
      </RouteGuardAceite>
    ),
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/cadastro/startup/3",
    element: <Navigate to={"/cadastro/startup/2"} />,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/startup/4",
    element: <Navigate to={"/cadastro/startup/2"} />,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/startup/5",
    element: <Navigate to={"/cadastro/startup/2"} />,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/startup/6",
    element: <Navigate to={"/cadastro/startup/2"} />,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/startup/7",
    element: <Navigate to={"/cadastro/startup/2"} />,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/startup/8",
    element: <Navigate to={"/cadastro/startup/2"} />,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/nit/1",
    element: <PainelElegibilidadeNit />,
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/cadastro/nit/2",
    element: (
      <RouteGuardAceite required={"nit"} redirectPath={"/cadastro/nit/1"}>
        <PainelCadastroAuthNit />
      </RouteGuardAceite>
    ),
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/cadastro/nit/3",
    element: <Navigate to={"/cadastro/nit/2"} />,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/nit/4",
    element: <Navigate to={"/cadastro/nit/2"} />,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/nit/5",
    element: <Navigate to={"/cadastro/nit/2"} />,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/nit/6",
    element: <Navigate to={"/cadastro/nit/2"} />,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/venture_capital/1",
    element: <PainelElegibilidadeVentureCapital />,
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/cadastro/venture_capital/2",
    element: (
      <RouteGuardAceite
        required={"vc"}
        redirectPath={"/cadastro/venture_capital/1"}
      >
        <PainelCadastroAuthVentureCapital />
      </RouteGuardAceite>
    ),
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/cadastro/venture_capital/3",
    element: <Navigate to={"/cadastro/venture_capital/2"} />,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/venture_capital/4",
    element: <Navigate to={"/cadastro/venture_capital/2"} />,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/venture_capital/5",
    element: <Navigate to={"/cadastro/venture_capital/2"} />,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/venture_capital/6",
    element: <Navigate to={"/cadastro/venture_capital/2"} />,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/venture_capital/7",
    element: <Navigate to={"/cadastro/venture_capital/2"} />,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/reset_password_sent",
    element: <ResetPasswordEnviado />,
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/esqueceusenha",
    element: <EsqueceuSenha />,
    showHeader: false,
    showFooter: true,
  },
];
