import { ReactNode, useContext } from "react";

import { HandlersStorageContext } from "./HandlersStorageContext";
import { HandlerGetURLFromPathStorage } from "../../gateway/HandlerGetURLFromPathStorage";
import { FirebaseContext } from "../FirebaseContext";
import { HandlerSaveAvatarFirebase } from "../../gateway/HandlerSaveAvatarFirebase";
import { HandlerProgramaConexaoSaveLogoFirebase } from "../../gateway/HandlerProgramaConexaoSaveLogo";

export function HandlersStorageFirebaseContext({
  children,
}: {
  children: ReactNode;
}) {
  const { firebaseStorage, firestore } = useContext(FirebaseContext);

  return (
    <HandlersStorageContext.Provider
      value={{
        handlerGetURLFromPath: new HandlerGetURLFromPathStorage(
          firebaseStorage
        ),
        handlerSaveAvatar: new HandlerSaveAvatarFirebase(
          firebaseStorage,
          firestore
        ),
        handlerProgramaConexaoSaveLogo:
          new HandlerProgramaConexaoSaveLogoFirebase(
            firebaseStorage,
            firestore
          ),
      }}
      children={children}
    />
  );
}
