import { useContext } from "react";

import { CurrentUserContext } from "../../../../globalStore/CurrentUserContext";
import HTMLHead from "../../../generalComponents/HTMLHead";
import { ListConexoesPendentesEnviadas } from "../components/ListConexoesPendentesEnviadas";
import { ConexoesPendentesEnviadasProvider } from "../../../../globalStore/conexoesPendentesEnviadas/ConexoesPendentesEnviadasProvider";

export function ConexoesPendentesEnviados() {
  const { currentUserScyggz } = useContext(CurrentUserContext);

  return currentUserScyggz.user ? (
    <>
      <HTMLHead
        title="Conexões pendentes recebidas"
        description="Minhas Conexões Pendentes Enviadas"
      />
      <ConexoesPendentesEnviadasProvider user={currentUserScyggz.user}>
        <ListConexoesPendentesEnviadas />
      </ConexoesPendentesEnviadasProvider>
    </>
  ) : (
    <></>
  );
}
