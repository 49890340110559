import { ContainerResponsive } from "../../../generalComponents/ContainerResponsive";

export function ResetPasswordEnviado() {
  return (
    <section data-testid="painel-elegibilidade-investidor" className="h-100">
      <ContainerResponsive
        showLogo={false}
        children={<h3>Email de redefinição de senha enviado!</h3>}
      />
    </section>
  );
}
