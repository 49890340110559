import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styles from "./Breadcrumbs.module.css"
import { Link } from "react-router-dom"
import { IconDefinition } from "@fortawesome/fontawesome-svg-core"


type Props = {
    icon: IconDefinition,
    title: string,
    menuList: {
        previous: {
            title: string,
            link: string,
        }[],
        page: string,

    }
}


export default function Breadcrumbs(props: Props) {

    return (
        <>
        <section className="page-header page-header-xs">
            <div className="container d-flex justify-content-between align-items-center flex-wrap my-2" >

                <h1>
                    <FontAwesomeIcon icon={props.icon} className="me-2 opacity-75" aria-hidden="true" />
                    {props.title}
                </h1>

                <nav id="breadcrumbs" className={styles.breadcrumbsDivisor} aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        {props.menuList && props.menuList.previous.map((menuItem , index)=> (
                            <li className="breadcrumb-item" key={index}><Link className={styles.breadcrumbLink} to={menuItem.link}>{menuItem.title}</Link></li>)
                        )}
                        <li className="breadcrumb-item active" aria-current="page">{props.menuList?.page}</li>
                    </ol>
                </nav>
            </div>
        </section>
        <section className='background-light-green p-0' >&nbsp;</section>
        </>
    )
}
