import { Result, Writeable } from "../typings";
import {
  CacheMentor,
  HandlerCacheMentor,
} from "../domain/usecases/interfaces/HandlerCacheMentor";

export class HandlerCacheMentorStorage implements HandlerCacheMentor {
  private readonly keyMentorPrefix = "MentorCadastroInScyggz";

  clear(): Promise<Result<null>> {
    if (!localStorage) {
      return Promise.resolve({
        ok: false,
        error: new Error(
          "Não fez cache do Mentor pois o navegador é incompativel"
        ),
      });
    }

    try {
      localStorage.removeItem(this.keyMentorPrefix);

      return Promise.resolve({
        ok: true,
        value: null,
      });
    } catch (error) {
      return Promise.resolve({
        ok: false,
        error: error as Error,
      });
    }
  }

  async save(mentor: CacheMentor): Promise<Result<null, Error>> {
    if (!localStorage) {
      return Promise.resolve({
        ok: false,
        error: new Error(
          "Não fez cache do Mentor pois o navegador é incompativel"
        ),
      });
    }

    try {
      const getResult = await this.get();

      if (!getResult.ok) {
        return getResult;
      }

      localStorage.setItem(
        this.keyMentorPrefix,
        JSON.stringify({ ...getResult.value, ...mentor })
      );

      return Promise.resolve({
        ok: true,
        value: null,
      });
    } catch (error) {
      return Promise.resolve({
        ok: false,
        error: error as Error,
      });
    }
  }

  get(): Promise<Result<CacheMentor, Error>> {
    const mentorStorage = localStorage.getItem(this.keyMentorPrefix);

    if (!mentorStorage) {
      return Promise.resolve({
        ok: true,
        value: {},
      });
    }

    const mentorAny = JSON.parse(mentorStorage);

    if (!mentorAny) {
      return Promise.resolve({
        ok: true,
        value: {},
      });
    }

    const mentor = this.convertAnyToMentor(mentorAny);

    return Promise.resolve({
      ok: true,
      value: mentor,
    });
  }

  private convertAnyToMentor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mentorAny: any
  ): Writeable<CacheMentor> {
    const mentor: Writeable<CacheMentor> = {};

    if (mentorAny.user_id) mentor.user_id = mentorAny.user_id;

    if (mentorAny.premiacoes) mentor.premiacoes = mentorAny.premiacoes;

    if (mentorAny.sobre_o_mentor)
      mentor.sobre_o_mentor = mentorAny.sobre_o_mentor;

    if (mentorAny.horas_disponibilidade_mensal)
      mentor.horas_disponibilidade_mensal =
        mentorAny.horas_disponibilidade_mensal;

    if (mentorAny.interesse_startup_estagio)
      mentor.interesse_startup_estagio = mentorAny.interesse_startup_estagio;

    if (mentorAny.interesse_startup_localizacoes)
      mentor.interesse_startup_localizacoes =
        mentorAny.interesse_startup_localizacoes;

    if (mentorAny.interesse_startup_modelo_negocio)
      mentor.interesse_startup_modelo_negocio =
        mentorAny.interesse_startup_modelo_negocio;

    if (mentorAny.interesse_startup_segmento_atuacao)
      mentor.interesse_startup_segmento_atuacao =
        mentorAny.interesse_startup_segmento_atuacao;

    if (mentorAny.interesse_startup_mecanismo_solucao)
      mentor.interesse_startup_mecanismo_solucao =
        mentorAny.interesse_startup_mecanismo_solucao;

    return mentor;
  }
}
