import Select, { StylesConfig } from "react-select";
import { useContext, useEffect, useState } from "react";
import makeAnimated from "react-select/animated";

import { ResizeContext } from "../../hooks/useResize";
import { HandlersContext } from "../../globalStore/HandlersProviders/HandlersContext";
import { AreaAplicacaoTecnologia } from "../../domain/entities/areaAplicacaoTecnologia";

const animatedComponents = makeAnimated();

export function InputAreasAplicacaoTecnologia({
  label,
  value,
  onChange,
  validate,
  className = "",
}: {
  label: string;
  value: AreaAplicacaoTecnologia[];
  onChange: (areaaAplicacaoTecnologia: AreaAplicacaoTecnologia[]) => void;
  validate: (
    areaaAplicacaoTecnologia: AreaAplicacaoTecnologia[]
  ) => Error | undefined;
  className?: string;
}) {
  const { handlerGetAreaAplicacaoTecnologia } = useContext(HandlersContext);

  const { responsive } = useContext(ResizeContext);

  const [allAreaAplicacaoTecnologia, setAllAreaAplicacaoTecnologia] = useState<
    AreaAplicacaoTecnologia[]
  >([]);
  const [
    isAllAreaAplicacaoTecnologiaLoading,
    setIsAllAreaAplicacaoTecnologiaLoading,
  ] = useState<boolean>(false);

  const [errorToShow, setErrorToShow] = useState<string>();
  const [isChanged, setIsChanged] = useState<boolean>(false);

  useEffect(() => {
    const errorValidate = validate(value);

    if (errorValidate) {
      setErrorToShow(errorValidate.message);
    } else {
      setErrorToShow(undefined);
    }
  }, [validate, value]);

  useEffect(() => {
    setIsAllAreaAplicacaoTecnologiaLoading(true);
    if (handlerGetAreaAplicacaoTecnologia)
      handlerGetAreaAplicacaoTecnologia
        .getAllAreaAplicacaoTecnologia()
        .then((handlerGetAreaAplicacaoTecnologiaResult) => {
          if (handlerGetAreaAplicacaoTecnologiaResult.ok)
            setAllAreaAplicacaoTecnologia(
              handlerGetAreaAplicacaoTecnologiaResult.value
            );

          setIsAllAreaAplicacaoTecnologiaLoading(false);
        });
  }, [handlerGetAreaAplicacaoTecnologia]);

  const customStylesSelect: StylesConfig<AreaAplicacaoTecnologia, true> = {
    control: () => ({
      border: "none",
      borderBottom: "3px #666600 solid",
      display: "flex",
      background: " #fff",
      borderBottomLeftRadius: "8px",
      textAlign: "left",
    }),
  };

  return (
    <div className={className}>
      <label
        className="form-label fw-bolder m-0"
        htmlFor="selAreaAplicacaoTecnologia"
      >
        {label}
      </label>
      <Select<AreaAplicacaoTecnologia, true>
        isLoading={isAllAreaAplicacaoTecnologiaLoading}
        options={allAreaAplicacaoTecnologia}
        styles={responsive.sm ? customStylesSelect : undefined}
        getOptionLabel={(o) => o.nome}
        getOptionValue={(o) => o.id}
        components={animatedComponents}
        isMulti
        value={value}
        placeholder=""
        onChange={(areaaAplicacaoTecnologia) => {
          onChange([...areaaAplicacaoTecnologia]);
          setIsChanged(true);
        }}
        onBlur={() => setIsChanged(true)}
        className={errorToShow && isChanged ? "is-invalid " : ""}
        noOptionsMessage={() => "sem opção"}
        inputId="selAreaAplicacaoTecnologia"
      />

      <div className="invalid-feedback flex-fill">{errorToShow}</div>
    </div>
  );
}
