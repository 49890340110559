import { Link } from "react-router-dom";

import styles from "./Header.module.css";
import logoHeader from "../../../../assets/images/logo-scyggz.png";
import Button from "../../../generalComponents/Button";

const Header = () => {
  return (
    <header id="topNav">
      <div className="container">
        <div className="row d-flex align-items-center justify-content-between">
          <div className="col-md-6">
            <Link to="/">
              <img className={styles.logo} src={logoHeader} alt="logo" />
            </Link>
          </div>
          <div className="col-md-6">
            <div className="text-end">
              <Button
                link="/login"
                classes={"buttonWhite"}
                children={"Login"}
              />
              <Button
                classes="buttonGreen"
                children={"Cadastre-se"}
                link="/cadastro"
              />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
