import { useContext, useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";

import { TextH4Info } from "./TextH4Info";
import { TextH5Info } from "./TextH5Info";
import { InstituicaoDeCienciaETecnologiaOutput } from "../../../../../domain/entities/instituicaoDeCienciaETecnologia";
import {
  UnidadeInstituicaoDeCienciaETecnologiaInput,
  UnidadeInstituicaoDeCienciaETecnologiaOutput,
} from "../../../../../domain/entities/unidadeInstituicaoDeCienciaETecnologia";
import { InstituicaoContext } from "../../../../../globalStore/InstituicaoProvider/InstituicaoContext";
import { Result } from "../../../../../typings";

export const InfoInstituicao = ({
  classes,
  unidades,
  handlerAdd,
}: {
  classes?: string;
  unidades: {
    id: string;
    nome: string;
    instituicao: { id: string; nome: string };
  }[];
  handlerAdd?: (
    inputAdd: UnidadeInstituicaoDeCienciaETecnologiaInput
  ) => Promise<Result<unknown>>;
}) => {
  const { allInstituicaoResult, refresh } = useContext(InstituicaoContext);

  const [instituicoes, setInstituicoes] = useState<
    InstituicaoDeCienciaETecnologiaOutput[]
  >([]);
  const [showModalAdd, setShowModalAdd] = useState<boolean>(false);
  const [carregando, setCarregando] = useState<boolean>(false);

  const [instituicaoToAdd, setInstituicaoToAdd] =
    useState<InstituicaoDeCienciaETecnologiaOutput | null>(null);
  const [unidadeToAdd, setUnidadeToAdd] = useState<Pick<
    UnidadeInstituicaoDeCienciaETecnologiaOutput,
    "id" | "nome"
  > | null>(null);

  const animatedComponents = makeAnimated();

  useEffect(() => {
    const instituicoes: InstituicaoDeCienciaETecnologiaOutput[] = [];
    unidades.forEach((unidade) => {
      const instituicao = instituicoes.find(
        (inst) => inst.id == unidade.instituicao.id
      );

      if (instituicao) {
        instituicao.unidades.push(unidade);
      } else {
        instituicoes.push({
          id: unidade.instituicao.id,
          nome: unidade.instituicao.nome,
          unidades: [unidade],
        });
      }
    });
    setInstituicoes(instituicoes);
  }, [unidades]);

  useEffect(() => {
    if (handlerAdd && refresh) refresh();
  }, [handlerAdd, refresh]);

  return (
    <div className={classes ?? ""}>
      <div>
        <h5 className="text-green-strong fw-bolder">Instituição (ICT)</h5>
        {handlerAdd && (
          <button
            type="button"
            className="btn btn-primary rounded-circle m-2"
            onClick={() => setShowModalAdd(true)}
          >
            <FontAwesomeIcon icon={faAdd} />
          </button>
        )}
      </div>

      <div className="d-flex row" style={{ gap: "10px" }}>
        {instituicoes.map((institucao, i) => (
          <div
            key={i}
            className="d-flex flex-column col-md-5 col-12"
            style={{ marginLeft: "35px" }}
          >
            <TextH5Info children={institucao.nome} />
            <div className="ms-4">
              <TextH4Info children="Unidade" />
              <div className="d-flex">
                {institucao.unidades.map((unidade, i) => (
                  <TextH5Info key={i} classes="ms-2" children={unidade.nome} />
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>

      <Modal show={showModalAdd} onHide={() => setShowModalAdd(false)} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Modal.Title>Adicionar Unidade</Modal.Title>

          <div className="mb-3">
            <label className="form-label" htmlFor="selInstituicao">
              Instituição (ICT)*
            </label>

            <Select<InstituicaoDeCienciaETecnologiaOutput>
              isLoading={!allInstituicaoResult}
              options={
                allInstituicaoResult?.ok ? allInstituicaoResult.value : []
              }
              getOptionLabel={(o) => o.nome}
              getOptionValue={(o) => o.id}
              components={animatedComponents}
              placeholder={""}
              name="instituicao"
              onChange={(instituicao) => {
                if (instituicao?.id != instituicaoToAdd?.id) {
                  setInstituicaoToAdd(instituicao);
                  setUnidadeToAdd(null);
                }
              }}
              className={!instituicaoToAdd ? " is-invalid " : ""}
              noOptionsMessage={() => "sem opção"}
              inputId="selInstituicao"
              value={instituicaoToAdd}
            />
            <div className="invalid-feedback flex-fill">Campo obrigatório</div>
          </div>

          <div className="mb-3">
            <label className="form-label" htmlFor="selUnidade">
              Unidade*
            </label>
            <Select<
              Pick<UnidadeInstituicaoDeCienciaETecnologiaOutput, "id" | "nome">
            >
              isLoading={!allInstituicaoResult}
              options={instituicaoToAdd?.unidades}
              getOptionLabel={(o) => o.nome}
              getOptionValue={(o) => o.id}
              isOptionDisabled={(o) => {
                return unidades.some(
                  (unidade) =>
                    unidade.id == o.id &&
                    unidade.instituicao.id == instituicaoToAdd?.id
                );
              }}
              components={animatedComponents}
              placeholder={""}
              name="unidade"
              onChange={(unidade) => {
                setUnidadeToAdd(unidade);
              }}
              isDisabled={!instituicaoToAdd}
              className={!unidadeToAdd ? " is-invalid " : ""}
              noOptionsMessage={() => "sem opção"}
              value={unidadeToAdd}
              inputId="selUnidade"
            />
            <div className="invalid-feedback flex-fill">Campo obrigatório</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            disabled={!instituicaoToAdd || !unidadeToAdd || carregando}
            onClick={async () => {
              if (handlerAdd && unidadeToAdd && instituicaoToAdd) {
                setCarregando(true);
                const handlerAddResult = await handlerAdd({
                  id: unidadeToAdd.id,
                  nome: unidadeToAdd.nome,
                  instituicao: {
                    id: instituicaoToAdd.id,
                    nome: instituicaoToAdd.nome,
                  },
                });
                setCarregando(false);
                if (!handlerAddResult.ok) {
                  console.warn(handlerAddResult.error);
                  return;
                } else {
                  setInstituicaoToAdd(null);
                  setUnidadeToAdd(null);
                  setShowModalAdd(false);
                }
              }
            }}
          >
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

// type InstituicaoDeCienciaETecnologiaOutput = {
//   id: string;
//   nome: string;
//   unidades: { id: string; nome: string }[];
// };
