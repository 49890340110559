import { Result } from "../../typings";
import { HandlerValidate } from "../usecases/interfaces/HandlerValidate";
import { EstagioStartup } from "./EstagioStartup";
import { validateHorasDisponibilidadeMensal } from "./HorasMensal";
import { Mentor } from "./Mentor";
import { LocalizacaoOutput, isLocalizacaoValida } from "./localizacao";
import { ModeloNegocioOutput } from "./modeloNegocio";
import { Premiacao, PremiacaoInput } from "./premiacao";
import { SegmentoAtuacaoOutput } from "./segmentoAtuacao";
import { Sobre } from "./sobre";

export class MentorValidator implements HandlerValidate<Mentor> {
  validateUpdate(
    attributes: Partial<Mentor>
  ): Result<null, Partial<Record<keyof Mentor, Error>>> {
    const errors: Partial<Record<keyof Mentor, Error>> = {};

    if (attributes.user_id !== undefined) {
      const error = MentorValidator.isInvalidUserId(attributes.user_id);
      if (error) {
        errors.user_id = error;
      }
    }

    if (attributes.sobre_o_mentor != undefined) {
      const validateSobreoResult = Sobre.validate({
        conteudo: attributes.sobre_o_mentor,
      });
      if (!validateSobreoResult.ok) {
        errors.sobre_o_mentor = validateSobreoResult.error;
      }
    }

    if (attributes.horas_disponibilidade_mensal != undefined) {
      const validateHorasDisponibilidadeMensalResult =
        validateHorasDisponibilidadeMensal(
          attributes.horas_disponibilidade_mensal
        );
      if (!validateHorasDisponibilidadeMensalResult.ok) {
        errors.horas_disponibilidade_mensal =
          validateHorasDisponibilidadeMensalResult.error;
      }
    }

    if (attributes.premiacoes != undefined) {
      const premiacoesResult = MentorValidator.validatePremiacoes(
        attributes.premiacoes
      );
      if (!premiacoesResult.ok) {
        errors.premiacoes = new Error(
          "Erro nas premiações: " +
            Object.values(premiacoesResult.error ?? {})
              .map((error) => error.message)
              .join(", ")
        );
      }
    }

    if (attributes.interesse_startup_estagio != undefined) {
      const errorInteresseStartupEstagio =
        MentorValidator.validateInteresseStartupEstagio(
          attributes.interesse_startup_estagio
        );
      if (errorInteresseStartupEstagio) {
        errors.interesse_startup_estagio = errorInteresseStartupEstagio;
      }
    }

    if (attributes.interesse_startup_modelo_negocio != undefined) {
      const errorInteresseStartupModeloNegocio =
        MentorValidator.validateInteresseStartupModeloNegocio(
          attributes.interesse_startup_modelo_negocio
        );
      if (errorInteresseStartupModeloNegocio) {
        errors.interesse_startup_modelo_negocio =
          errorInteresseStartupModeloNegocio;
      }
    }

    if (attributes.interesse_startup_segmento_atuacao != undefined) {
      const errorValidateInteresseStartupSegmentoAtuacao =
        MentorValidator.validateInteresseStartupSegmentoAtuacao(
          attributes.interesse_startup_segmento_atuacao
        );
      if (errorValidateInteresseStartupSegmentoAtuacao) {
        errors.interesse_startup_segmento_atuacao =
          errorValidateInteresseStartupSegmentoAtuacao;
      }
    }

    if (attributes.interesse_startup_mecanismo_solucao != undefined) {
      const validateInteresseStartupMecanismoSolucaoResult =
        MentorValidator.validateInteresseStartupMecanismoSolucao(
          attributes.interesse_startup_mecanismo_solucao
        );

      if (!validateInteresseStartupMecanismoSolucaoResult.ok) {
        errors.interesse_startup_mecanismo_solucao =
          validateInteresseStartupMecanismoSolucaoResult.error;
      }
    }

    if (Object.keys(errors).length > 0) {
      return {
        ok: false,
        error: errors,
      };
    }

    return {
      ok: true,
      value: null,
    };
  }

  validateCreate(
    attributes: Mentor
  ): Result<null, Partial<Record<keyof Mentor, Error>>> {
    const errors: Partial<Record<keyof Mentor, Error>> = {};

    const error = MentorValidator.isInvalidUserId(attributes.user_id);
    if (error) {
      errors.user_id = error;
    }

    const validateSobreResult = Sobre.validate({
      conteudo: attributes.sobre_o_mentor ?? "",
    });
    if (!validateSobreResult.ok) {
      errors.sobre_o_mentor = validateSobreResult.error;
    }

    const validateHorasDisponibilidadeMensalResult =
      validateHorasDisponibilidadeMensal(
        attributes.horas_disponibilidade_mensal
      );
    if (!validateHorasDisponibilidadeMensalResult.ok) {
      errors.horas_disponibilidade_mensal =
        validateHorasDisponibilidadeMensalResult.error;
    }

    const premiacoesResult = MentorValidator.validatePremiacoes(
      attributes.premiacoes ?? []
    );
    if (!premiacoesResult.ok) {
      errors.premiacoes = new Error(
        "Erro nas premiações: " +
          Object.values(premiacoesResult.error ?? {})
            .map((error) => error.message)
            .join(", ")
      );
    }

    const errorInteresseStartupEstagio =
      MentorValidator.validateInteresseStartupEstagio(
        attributes.interesse_startup_estagio
      );
    if (errorInteresseStartupEstagio) {
      errors.interesse_startup_estagio = errorInteresseStartupEstagio;
    }

    const errorInteresseStartupModeloNegocio =
      MentorValidator.validateInteresseStartupModeloNegocio(
        attributes.interesse_startup_modelo_negocio
      );
    if (errorInteresseStartupModeloNegocio) {
      errors.interesse_startup_modelo_negocio =
        errorInteresseStartupModeloNegocio;
    }

    const errorValidateInteresseStartupSegmentoAtuacao =
      MentorValidator.validateInteresseStartupSegmentoAtuacao(
        attributes.interesse_startup_segmento_atuacao
      );
    if (errorValidateInteresseStartupSegmentoAtuacao) {
      errors.interesse_startup_segmento_atuacao =
        errorValidateInteresseStartupSegmentoAtuacao;
    }

    const validateInteresseStartupMecanismoSolucaoResult =
      MentorValidator.validateInteresseStartupMecanismoSolucao(
        attributes.interesse_startup_mecanismo_solucao
      );
    if (!validateInteresseStartupMecanismoSolucaoResult.ok) {
      errors.interesse_startup_mecanismo_solucao =
        validateInteresseStartupMecanismoSolucaoResult.error;
    }

    if (Object.keys(errors).length > 0) {
      return {
        ok: false,
        error: errors,
      };
    }

    return {
      ok: true,
      value: null,
    };
  }

  static isInvalidUserId(user_id: string): Error | undefined {
    if (user_id.trim().length < 1) {
      return new Error("User id inválido");
    }
  }

  public static validateInteresseStartupEstagio(
    interesse_startup_estagio: EstagioStartup[]
  ): Error | undefined {
    const isInteresseStartupEstagioValid: boolean =
      interesse_startup_estagio.length > 0;

    if (!isInteresseStartupEstagioValid) {
      return new Error("Precisa especificar estágio startup de interesse");
    }
  }

  public static validateInteresseStartupModeloNegocio(
    interesse_startup_modelo_negocio: ModeloNegocioOutput[]
  ): Error | undefined {
    const isInteresseStartupModeloNegocioValid: boolean =
      interesse_startup_modelo_negocio.length > 0;
    if (!isInteresseStartupModeloNegocioValid) {
      return new Error(
        "Precisa especificar modelo de negócio da startup de interesse"
      );
    }
  }

  public static validateInteresseStartupSegmentoAtuacao(
    interesse_startup_segmento_atuacao: SegmentoAtuacaoOutput[]
  ): Error | undefined {
    const isInteresseStartupSegmentoAtuacaoValid: boolean =
      interesse_startup_segmento_atuacao.length > 0;
    if (!isInteresseStartupSegmentoAtuacaoValid) {
      return new Error(
        "Precisa especificar segmento de atuação da startup de interesse"
      );
    }
  }

  static validateInteresseStartupMecanismoSolucao(
    interesse_startup_mecanismo_solucao: SegmentoAtuacaoOutput[]
  ): Result<null> {
    const isInteresseStartupSegmentoAtuacaoValid: boolean =
      interesse_startup_mecanismo_solucao.length > 0;
    if (!isInteresseStartupSegmentoAtuacaoValid) {
      return {
        ok: false,
        error: new Error(
          "Precisa especificar segmento de atuação da startup de interesse"
        ),
      };
    }

    return {
      ok: true,
      value: null,
    };
  }

  public static validatePremiacoes(
    premiacoes: PremiacaoInput[]
  ): Result<
    PremiacaoInput[],
    { [P in keyof Partial<PremiacaoInput>]: Error } | undefined
  > {
    let hasError = false;

    const errors = premiacoes
      .map((premiacao) => Premiacao.validate(premiacao))
      .map((result) => {
        if (result.ok) {
          return undefined;
        } else {
          hasError = true;
          return result.error;
        }
      });

    if (hasError) {
      return {
        ok: false,
        error: errors[0],
      };
    } else {
      return {
        ok: true,
        value: premiacoes,
      };
    }
  }

  public static isInvalidLocais(
    locais: LocalizacaoOutput[] | undefined
  ): Error | undefined {
    const someInvalid: boolean = (locais ?? [])?.some((local) => {
      return !isLocalizacaoValida(local);
    });

    if (someInvalid) {
      return new Error("Inválido");
    }
  }
}
