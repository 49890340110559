import Select, { StylesConfig } from "react-select";
import { useContext, useMemo } from "react";
import makeAnimated from "react-select/animated";

import { ResizeContext } from "../../hooks/useResize";
import { TIPOS_PERFIL, TipoPerfil } from "../../domain/entities/usuarioScyggz";

const animatedComponents = makeAnimated();

const todosValue = {
  label: "Todos",
  value: undefined,
};

export function InputPerfil({
  label,
  value,
  onChange,
  className,
}: {
  label: string;
  value?: TipoPerfil;
  onChange: (tipoPerfil: TipoPerfil | undefined) => void;
  className?: string;
}) {
  const { responsive } = useContext(ResizeContext);

  const selectedValue = useMemo<{
    label: string;
    value: string | undefined;
  }>(() => {
    if (value) {
      return {
        label: value,
        value: value,
      };
    }

    return todosValue;
  }, [value]);

  const customStylesSelect: StylesConfig<{
    label: string;
    value: string | undefined;
  }> = {
    control: () => ({
      border: "none",
      borderBottom: "3px #666600 solid",
      display: "flex",
      background: " #fff",
      borderBottomLeftRadius: "8px",
      textAlign: "left",
    }),
  };

  return (
    <div className={className}>
      <label className="form-label fw-bolder m-0" htmlFor="selTipoPerfil">
        {label}
      </label>
      <Select<{
        label: string;
        value: string | undefined;
      }>
        options={[
          todosValue,
          ...TIPOS_PERFIL.map((t) => ({
            label: t,
            value: t,
          })),
        ]}
        getOptionLabel={(o) => o.label}
        styles={responsive.sm ? customStylesSelect : undefined}
        components={animatedComponents}
        value={selectedValue}
        placeholder=""
        onChange={(tipoPerfil) => {
          if (tipoPerfil) onChange(tipoPerfil.value as TipoPerfil);
          else onChange(undefined);
        }}
        noOptionsMessage={() => "sem opção"}
        inputId="selTipoPerfil"
      />
    </div>
  );
}
