import { useNavigate } from "react-router-dom";

import { HandlerCacheEmpresa } from "../../../../../domain/usecases/interfaces/HandlerCacheEmpresa";
import { HandlerGetAreaAplicacaoTecnologia } from "../../../../../domain/usecases/interfaces/handlerGetAreaAplicacaoTecnologia";
import { HandlerGetTipoGrupoPesquisa } from "../../../../../domain/usecases/interfaces/handlerGetTipoGrupoPesquisa";
import { HandlerUpdateEmpresa } from "../../../../../domain/usecases/interfaces/handlerUpdateEmpresa";
import { ContainerResponsive } from "../../../../generalComponents/ContainerResponsive";
import { ConteudoInteresseCientistaEmpresa } from "./ConteudoInteresseCientistaEmpresa";

export const PainelInteresseCientistaEmpresa = ({
  handlerCacheEmpresa,
  handlerUpdateEmpresa,
  handlerGetAreaAplicacaoTecnologia,
  handlerGetTipoGrupoPesquisa,
}: {
  handlerCacheEmpresa: HandlerCacheEmpresa;
  handlerUpdateEmpresa: HandlerUpdateEmpresa;
  handlerGetAreaAplicacaoTecnologia: HandlerGetAreaAplicacaoTecnologia;
  handlerGetTipoGrupoPesquisa: HandlerGetTipoGrupoPesquisa;
}) => {
  const navigate = useNavigate();

  const paginacao = {
    start: 7,
    end: 8,
  };

  return (
    <section
      data-testid="painel-interesse-cientista-empresa"
      style={{ height: "100%" }}
    >
      <ContainerResponsive
        backUrlMobile="/cadastro/empresa/6"
        paginacao={paginacao}
        textoMarcaDagua={"Empresa"}
        showLogo={true}
      >
        <ConteudoInteresseCientistaEmpresa
          handlerUpdateEmpresa={handlerUpdateEmpresa}
          handlerGetAreaAplicacaoTecnologia={handlerGetAreaAplicacaoTecnologia}
          handlerGetTipoGrupoPesquisa={handlerGetTipoGrupoPesquisa}
          handlerCacheEmpresa={handlerCacheEmpresa}
          onBack={() => {
            navigate("/cadastro/empresa/6");
          }}
          onNext={() => {
            navigate("/cadastro/empresa/8");
          }}
        />
      </ContainerResponsive>
    </section>
  );
};
