import { Firestore, updateDoc, doc } from "firebase/firestore";
import { ref, uploadBytes, FirebaseStorage } from "firebase/storage";

import { Result } from "../typings";
import { ProgramaConexao } from "../domain/entities/ProgramaConexao";
import { HandlerProgramaConexaoSaveLogo } from "../domain/usecases/interfaces/HandlerProgramaConexaoSaveLogo";

export class HandlerProgramaConexaoSaveLogoFirebase
  implements HandlerProgramaConexaoSaveLogo
{
  readonly collectionPath = "programas_conexao";

  constructor(
    private firebaseStorage: FirebaseStorage,
    private firestore: Firestore
  ) {}

  save({
    id_programa,
    file,
  }: {
    id_programa: string;
    file: Blob;
  }): Promise<Result<{ logo_path: string }>> {
    const storageRef = ref(
      this.firebaseStorage,
      this.collectionPath +
        "/" +
        id_programa +
        "/logo/" +
        new Date().getTime().toString()
    );

    return new Promise((resolve) => {
      uploadBytes(storageRef, file)
        .then(async () => {
          const docEmpresa = doc(
            this.firestore,
            this.collectionPath,
            id_programa
          );

          const logo: Pick<ProgramaConexao, "logo_path"> = {
            logo_path: storageRef.fullPath,
          };

          updateDoc(docEmpresa, logo)
            .then(() =>
              resolve({
                ok: true,
                value: {
                  logo_path: logo.logo_path,
                },
              })
            )
            .catch((error) => resolve({ ok: false, error }));
        })
        .catch((error) => {
          console.warn("save", error);
          resolve({
            ok: false,
            error,
          });
        });
    });
  }
}
