import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { CurrentUserContext } from "../../../../../globalStore/CurrentUserContext";
import { ConteudoAvatar } from "../../components/ConteudoAvatar";
import { ContainerResponsive } from "../../../../generalComponents/ContainerResponsive";

export function PainelCadastroFotoUsuarioInvestidor() {
  const navigate = useNavigate();
  const { currentUserScyggz } = useContext(CurrentUserContext);

  const paginacao = {
    start: 4,
    end: 6,
  };

  const backUrl = "/cadastro/investidor/3";

  return (
    <ContainerResponsive
      backUrlMobile={backUrl}
      paginacao={paginacao}
      textoMarcaDagua="Investidor Anjo"
      showLogo={true}
    >
      <ConteudoAvatar
        user={
          currentUserScyggz.user ?? {
            auth_user_id: "",
            nome: "",
          }
        }
        onBack={() => {
          navigate(backUrl);
        }}
        onCadastro={() => {
          navigate("/cadastro/investidor/5");
        }}
      />
    </ContainerResponsive>
  );
}
