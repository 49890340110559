import { useState } from "react";
import { NavDropdown } from "react-bootstrap";
import { AlignType } from "react-bootstrap/esm/types";

export function HeaderNavDropdown({
  title,
  children,
  align,
}: {
  title: JSX.Element;
  children: JSX.Element | JSX.Element[];
  align?: AlignType;
}) {
  const [show, setShow] = useState(false);

  return (
    <>
      <NavDropdown
        title={title}
        className="removeArrowDropdown d-none d-lg-block headerNavDropdown"
        show={show}
        onMouseOver={() => {
          setShow(true);
        }}
        onMouseOut={() => {
          setShow(false);
        }}
        align={align}
      >
        {children}
      </NavDropdown>

      <NavDropdown
        title={title}
        className="removeArrowDropdown d-block d-lg-none"
      >
        {children}
      </NavDropdown>
    </>
  );
}
