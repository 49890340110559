import { PathRouteProps } from "react-router-dom";
import { rotasAll } from "./rotasAll";

export function getRotasAll(): (PathRouteProps & {
  userStatus: [];
  path: string;
  element: JSX.Element;
  showHeader: boolean;
  showFooter: boolean;
})[] {
  return rotasAll.map((rotaAll) => {
    return getRotaNotVisitante(rotaAll);
  });
}

function getRotaNotVisitante({
  path,
  element,
  showHeader,
  showFooter,
}: {
  path: string;
  element: JSX.Element;
  showHeader: boolean;
  showFooter: boolean;
}): PathRouteProps & {
  userStatus: [];
  path: string;
  element: JSX.Element;
  showHeader: boolean;
  showFooter: boolean;
} {
  return {
    userStatus: [],
    path,
    element,
    showHeader,
    showFooter,
  };
}
