import { useContext } from "react";

import { HandlerUpdateAceiteConexao } from "../../../../domain/usecases/interfaces/HandlerUpdateAceiteConexao";
import { ConexoesPendentesRecebidasContext } from "../../../../globalStore/conexoesPendentesRecebidas/ConexoesPendentesRecebidasContext";
import { CardConviteConexao } from "./CardConviteConexao";

export function ConvitesConexaoRecebidas({
  handlerUpdateAceiteConexao,
}: {
  handlerUpdateAceiteConexao: HandlerUpdateAceiteConexao;
}): JSX.Element {
  const { conexoesPendentesRecebidasResult } = useContext(
    ConexoesPendentesRecebidasContext
  );

  return conexoesPendentesRecebidasResult?.ok ? (
    <div
      className="card p-4 border-0 rounded-4"
      style={{ height: "fit-content" }}
    >
      <h4 className="fw-normal">Convites de Conexão</h4>

      {conexoesPendentesRecebidasResult.value.length > 0 ? (
        <div className="container-fluid row row-cols-1 row-cols-lg-2 g-4 p-4 pe-0">
          {conexoesPendentesRecebidasResult.value.map(
            (conexaoPendenteRecebida) => {
              return (
                <div key={conexaoPendenteRecebida.id} className="col">
                  <CardConviteConexao
                    conexao={conexaoPendenteRecebida}
                    handlerUpdateAceiteConexao={handlerUpdateAceiteConexao}
                  />
                </div>
              );
            }
          )}
        </div>
      ) : (
        <span className="text-muted">nenhum convite pendente</span>
      )}
    </div>
  ) : (
    <></>
  );
}
