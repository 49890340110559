import { createContext } from "react";

import { Conexao } from "../../domain/entities/Conexao";
import { Result } from "../../typings";

export const ConexoesAprovadasContext = createContext<{
  conexoesAprovadasResult: TypeConexoesAprovadasContext;
}>({
  conexoesAprovadasResult: undefined,
});

export type TypeConexoesAprovadasContext =
  | Result<Readonly<Conexao>[], Error>
  | undefined;
