import { Firestore, collection, getDocs } from "@firebase/firestore";

import { Result } from "../typings";
import { HandlerGetInteresseInovacao } from "../domain/usecases/interfaces/handlerGetInteresseInovacao";
import { InteresseInovacaoOutput } from "../domain/entities/InteresseInovacao";

export class HandlerGetInteresseInovacaoFirebase
  implements HandlerGetInteresseInovacao
{
  constructor(private firestore: Firestore) {}

  getAllInteresseInovacao(): Promise<Result<InteresseInovacaoOutput[]>> {
    return new Promise((resolve) => {
      getDocs(collection(this.firestore, "interesse_inovacao"))
        .then((querySnapshot) => {
          const interesses: InteresseInovacaoOutput[] = querySnapshot.docs.map(
            (docSnapshot) => {
              const interesse = docSnapshot.data() as InteresseInovacaoOutput;
              interesse.id = docSnapshot.id;
              return interesse;
            }
          );

          resolve({
            ok: true,
            value: interesses,
          });
        })
        .catch((error) =>
          resolve({
            ok: false,
            error,
          })
        );
    });
  }
}
