import { Button, Modal } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";

import { HandlersStorageContext } from "../../globalStore/HandlersProviders/HandlersStorageContext";
import { InputImage } from "./InputImage";
import { Result } from "../../typings";

export function ModalEditImage({
  buttonText,
  headerText,
  path,
  alt,
  show,
  onShowChange,
  onUpdate,
}: {
  buttonText: string;
  headerText: string;
  path?: string;
  alt: string;
  show: boolean;
  onShowChange: (show: boolean) => void;
  onUpdate: (file: Blob) => Promise<Result<{ path: string }>>;
}) {
  const { handlerGetURLFromPath } = useContext(HandlersStorageContext);

  const [initialValue, setInitialValue] = useState<string>();
  const [value, setValue] = useState<{ imageBase: Blob; cropImage: Blob }>();
  const [carregando, setCarregando] = useState<boolean>(false);

  useEffect(() => {
    if (path)
      handlerGetURLFromPath?.getURL(path).then((urlResult) => {
        if (urlResult.ok) setInitialValue(urlResult.value);
      });
  }, [handlerGetURLFromPath, path]);

  return (
    <>
      {onUpdate && (
        <Modal
          show={show}
          onHide={() => {
            onShowChange(false);
            setValue(undefined);
          }}
          centered
          scrollable={true}
        >
          <Modal.Header closeButton>
            <Modal.Title children={headerText} />
          </Modal.Header>
          <Modal.Body className="text-center">
            <InputImage
              alt={alt}
              title={alt}
              initialValue={initialValue}
              value={value}
              onChange={(change) => {
                setValue(change);
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              disabled={carregando}
              onClick={async () => {
                setCarregando(true);

                if (value?.cropImage || value?.imageBase) {
                  const avatarResult = await onUpdate(
                    value?.cropImage ?? value?.imageBase
                  );

                  if (avatarResult.ok) {
                    onShowChange(false);
                  } else {
                    console.warn(avatarResult.error);
                  }
                } else {
                  onShowChange(false);
                }

                setCarregando(false);
                setValue(undefined);
              }}
              children={buttonText}
            />
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}
