import { useContext } from "react";

import { CurrentUserContext } from "../../../../globalStore/CurrentUserContext";
import { UsuarioInfoContext } from "../../../../globalStore/UsuarioInfoContext";
import { AbasPerfis } from "./AbasPerfis";
import { TipoPerfil } from "../../../../domain/entities/usuarioScyggz";

type AbasPerfisFromUserContextProps = {
  perfilAtivo?: TipoPerfil;
  onClickOrganizacoes: (show: boolean) => void;
  showOrganizacoes: boolean;
  userHasOrganizacoes: boolean;
};

export function AbasPerfisFromUserContext({
  perfilAtivo = undefined,
  onClickOrganizacoes,
  showOrganizacoes,
  userHasOrganizacoes,
}: AbasPerfisFromUserContextProps) {
  const { usuarioInfoResult } = useContext(UsuarioInfoContext);
  const { currentUserScyggz } = useContext(CurrentUserContext);

  return (
    <>
      {usuarioInfoResult?.ok && (
        <AbasPerfis
          isCurrentUser={
            currentUserScyggz.user?.auth_user_id ==
            usuarioInfoResult.value.auth_user_id
          }
          user={usuarioInfoResult.value}
          perfilAtivo={perfilAtivo}
          formatURL={({ user_id, perfil }) => "/user/" + user_id + "/" + perfil}
          onClickOrganizacoes={onClickOrganizacoes}
          showOrganizacoes={showOrganizacoes}
          userHasOrganizacoes={userHasOrganizacoes}
        />
      )}
    </>
  );
}
