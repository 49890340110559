import { Firestore, doc, updateDoc, writeBatch } from "@firebase/firestore";

import { Result } from "../typings";
import {
  InputUpdateStartup,
  OutputStartup,
  InputUpdateStartupHeader,
} from "../domain/entities/Startup";
import { HandlerUpdateStartup } from "../domain/usecases/interfaces/handlerUpdateStartup";
import { removeUndefined } from "./utils/removeUndefined";
import { HandlerSetStartupEnabled } from "../domain/usecases/interfaces/HandlerSetStartupEnabled";

export class HandlerUpdateStartupFirebase
  implements HandlerUpdateStartup, HandlerSetStartupEnabled
{
  constructor(private firestore: Firestore) {}

  setStartupEnabled(
    startups: Pick<OutputStartup, "id">[]
  ): Promise<Result<void>> {
    const batch = writeBatch(this.firestore);

    startups.forEach((startup) => {
      return new Promise((resolve) => {
        updateDoc(
          doc(this.firestore, "startup", startup.id),
          "habilitado",
          true
        )
          .then(() => {
            resolve({
              ok: true,
              value: undefined,
            });
          })
          .catch((error) => {
            resolve({
              ok: false,
              error,
            });
          });
      });
    });
    return new Promise((resolve) => {
      batch
        .commit()
        .then(() =>
          resolve({
            ok: true,
            value: undefined,
          })
        )
        .catch((error) =>
          resolve({
            ok: false,
            error,
          })
        );
    });
  }

  updateStartup(
    input: InputUpdateStartup
  ): Promise<Result<InputUpdateStartup, Error>> {
    return new Promise((resolve) => {
      updateDoc(
        doc(this.firestore, "startup", input.id),
        removeUndefined(input)
      )
        .then(() =>
          resolve({
            ok: true,
            value: input,
          })
        )
        .catch((error) =>
          resolve({
            ok: false,
            error,
          })
        );
    });
  }

  updateStartupHeader(
    input: InputUpdateStartupHeader
  ): Promise<Result<InputUpdateStartupHeader, Error>> {
    return new Promise((resolve) => {
      updateDoc(
        doc(this.firestore, "startup", input.id),
        removeUndefined(input)
      )
        .then(() =>
          resolve({
            ok: true,
            value: input,
          })
        )
        .catch((error) =>
          resolve({
            ok: false,
            error,
          })
        );
    });
  }
}
