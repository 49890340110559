import { useParams } from "react-router-dom";

import { PerfilVentureCapitalFirebaseProvider } from "../../../globalStore/perfilVentureCapital/PerfilVentureCapitalFirebaseProvider";
import { PerfilInfoVentureCapitalFromContext } from "./PerfilInfoVentureCapitalFromContext";

export function PainelVentureCapital() {
  const { id_venture_capital } = useParams();

  if (!id_venture_capital) {
    return <>...</>;
  }

  return (
    <PerfilVentureCapitalFirebaseProvider
      ventureCapital={{
        id: id_venture_capital,
      }}
    >
      <PerfilInfoVentureCapitalFromContext />
    </PerfilVentureCapitalFirebaseProvider>
  );
}
