import { useCallback, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";

import {
  InputUpdateStartupHeader,
  Startup,
} from "../../../domain/entities/Startup";
import { HandlerUpdateStartup } from "../../../domain/usecases/interfaces/handlerUpdateStartup";
import { InputNomeStartup } from "../../generalComponents/InputNomeStartup";
import { InputCidade } from "../../generalComponents/InputCidade";

type ModalEditStartupHeaderProps = {
  initialValue: InputUpdateStartupHeader;
  show: boolean;
  onShowChange: (show: boolean, statup?: InputUpdateStartupHeader) => void;
  handlerUpdateStartup?: HandlerUpdateStartup;
  afterUpdate?: () => void;
};

export function ModalEditStartupHeader({
  initialValue,
  show,
  onShowChange,
  handlerUpdateStartup,
  afterUpdate,
}: ModalEditStartupHeaderProps) {
  const [startupToEdit, setStartupToEdit] =
    useState<InputUpdateStartupHeader>(initialValue);
  const [errors, setErrors] = useState<
    Error | Partial<Record<keyof InputUpdateStartupHeader, Error>>
  >();
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const onUpdate = useCallback(async () => {
    setIsUpdating(true);

    if (handlerUpdateStartup) {
      const result = await handlerUpdateStartup.updateStartupHeader(
        startupToEdit
      );

      if (result.ok) {
        if (afterUpdate) afterUpdate();
        onShowChange(false, startupToEdit);
      } else {
        setErrors(result.error);
      }
    }

    setIsUpdating(false);
  }, [afterUpdate, handlerUpdateStartup, onShowChange, startupToEdit]);

  useEffect(() => {
    setStartupToEdit(initialValue);
  }, [initialValue]);
  useEffect(() => {
    setErrors(Startup.validateUpdate(startupToEdit));
  }, [startupToEdit]);

  return (
    <Modal
      show={show}
      onHide={() => onShowChange(false)}
      centered
      size="lg"
      scrollable={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>Editar startup</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {initialValue.nome && (
          <InputNomeStartup
            label="Nome*"
            nome={initialValue.nome}
            onChange={(nome) => {
              setStartupToEdit({
                ...startupToEdit,
                nome,
              });
            }}
          />
        )}

        <InputCidade
          value={startupToEdit.cidade}
          label="Cidade*"
          onChange={(cidade) => {
            setStartupToEdit({
              ...startupToEdit,
              cidade,
            });
          }}
          validate={Startup.validateCidade}
          className="mb-3"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="success"
          disabled={!!errors || isUpdating}
          onClick={onUpdate}
        >
          Salvar startup
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
