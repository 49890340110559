import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CacheEmpresa,
  HandlerCacheEmpresa,
} from "../../../../../domain/usecases/interfaces/HandlerCacheEmpresa";
import { HandlerGetURLFromPath } from "../../../../../domain/usecases/interfaces/HandlerGetURLFromPath";
import { HandlerSaveLogoEmpresa } from "../../../../../domain/usecases/interfaces/handlerSaveLogoEmpresa";
import { CurrentUserContext } from "../../../../../globalStore/CurrentUserContext";
import { ContainerResponsive } from "../../../../generalComponents/ContainerResponsive";
import { ConteudoLogoEmpresa } from "./ConteudoLogoEmpresa";

export const PainelLogoEmpresa = ({
  handlerSaveLogoEmpresa,
  handlerGetURLFromPath,
  handlerCacheEmpresa,
}: {
  handlerSaveLogoEmpresa: HandlerSaveLogoEmpresa;
  handlerGetURLFromPath: HandlerGetURLFromPath;
  handlerCacheEmpresa: HandlerCacheEmpresa;
}) => {
  const navigate = useNavigate();
  const { currentUserScyggz } = useContext(CurrentUserContext);
  const [cacheEmpresa, setCacheEmpresa] = useState<CacheEmpresa>({});

  const paginacao = {
    start: 6,
    end: 8,
  };

  useEffect(() => {
    handlerCacheEmpresa.get().then((cacheEmpresaResult) => {
      if (cacheEmpresaResult.ok) {
        setCacheEmpresa(cacheEmpresaResult.value);
      }
    });
  }, [handlerCacheEmpresa]);

  useEffect(() => {
    if (currentUserScyggz.status == "nao_encontrado") {
      navigate("/cadastro/empresa/1");
    }
  }, [currentUserScyggz, navigate]);

  const conteudo = (
    <ConteudoLogoEmpresa
      empresa={{
        id: cacheEmpresa.id ?? "",
        path_logo: cacheEmpresa.path_logo,
        nome: cacheEmpresa.nome ?? "",
      }}
      user={{ nome: currentUserScyggz.user?.nome ?? "" }}
      handlerSaveLogoEmpresa={handlerSaveLogoEmpresa}
      handlerGetURLFromPath={handlerGetURLFromPath}
      onNext={() => {
        navigate("/cadastro/empresa/7");
      }}
      handlerCacheEmpresa={handlerCacheEmpresa}
    />
  );

  return (
    <section
      data-testid="painel-cadastro-logo-empresa"
      style={{ height: "100%" }}
    >
      <ContainerResponsive
        backUrlMobile="/cadastro/empresa/5"
        paginacao={paginacao}
        textoMarcaDagua={"Empresa"}
        showLogo={true}
      >
        {conteudo}
      </ContainerResponsive>
    </section>
  );
};
