import { Result } from "../../typings";

export class Avatar {
  private constructor(public auth_id: string, public file: Blob) {}

  public static create({ auth_id, file }: InputAvatarEntity): Result<Avatar> {
    const errors: Error[] = [];

    auth_id = auth_id.trim();
    const isAuthValid: boolean = auth_id.length > 0;
    if (!isAuthValid) {
      errors.push(new Error("Auth id do avatar inválido"));
    }

    // TODO: VALIDATE FIELDS AVATAR

    if (errors.length > 0) {
      return {
        ok: false,
        error: errors[0],
      };
    }

    return {
      ok: true,
      value: new Avatar(auth_id, file),
    };
  }

  public get path(): string {
    return (
      "usuarios/" + this.auth_id + "/avatar/" + new Date().getTime().toString()
    );
  }
}

export type InputAvatarEntity = {
  auth_id: string;
  file: Blob;
};

export type OutputAvatarEntity = {
  path: string;
};
