export class Pais {
  private constructor(public pais: string, public paisCode: string) {}

  public static create({ pais, paisCode }: PaisInput): Pais | null {
    const isValidPais: boolean = pais.length > 1;
    const isValidPaisCode: boolean = paisCode.length > 1;
    if (isValidPais && isValidPaisCode) {
      return new Pais(pais, paisCode);
    }

    return null;
  }
}

export type PaisInput = {
  pais: string;
  paisCode: string;
};
export type PaisOutput = PaisInput;

export type PaisOutputWithTipo = PaisOutput & { tipo: "pais" };
