import { ReactNode, useCallback, useContext, useEffect, useState } from "react";

import { Cientista } from "../../domain/entities/Cientista";
import { InputHandlerUpdateCientistaSemId } from "../../domain/usecases/interfaces/handlerUpdateCientista";
import { HandlerGetCientistaFirebase } from "../../gateway/getPerfil/HandlerGetCientistaFirebase";
import { HandlerUpdateCientistaFirebase } from "../../gateway/HandlerUpdateCientistaFirebase";
import { FirebaseContext } from "../FirebaseContext";
import {
  PerfilCientistaContext,
  TypePerfilCientistaContext,
} from "./PerfilCientistaContext";

export function PerfilCientistaFirebaseProvider({
  cientista,
  children,
}: {
  cientista: Pick<Cientista, "user_id">;
  children: ReactNode;
}) {
  const { firestore } = useContext(FirebaseContext);

  const [perfilCientistaResult, setPerfilCientistaResult] =
    useState<TypePerfilCientistaContext>();

  const refreshCientista = useCallback(() => {
    new HandlerGetCientistaFirebase(firestore)
      .get(cientista.user_id)
      .then((result) => {
        setPerfilCientistaResult(
          result.ok
            ? {
                ok: true,
                value: {
                  user_id: result.value.user_id,
                  nome: result.value.nome,
                  habilitado: !!result.value.habilitado,
                  created_in: result.value.created_in,
                  areas_de_conhecimento: result.value.areas_de_conhecimento,
                  grupos_de_pesquisa: result.value.grupos_de_pesquisa,
                  premiacoes: result.value.premiacoes,
                  sobre: result.value.sobre,
                  tecnologias: result.value.tecnologias,
                  unidades: result.value.unidades,
                },
              }
            : result
        );
      });
  }, [cientista, firestore]);

  const updateCientista = useCallback(
    (inputCientista: InputHandlerUpdateCientistaSemId) => {
      return new HandlerUpdateCientistaFirebase(firestore).updateCientista({
        ...inputCientista,
        user_id: cientista.user_id,
      });
    },
    [cientista, firestore]
  );

  useEffect(() => {
    refreshCientista();
  }, [cientista.user_id, firestore, refreshCientista]);

  return (
    <PerfilCientistaContext.Provider
      value={{
        perfilCientistaResult,
        refreshCientista,
        updateCientista,
      }}
      children={children}
    />
  );
}
