/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useContext } from "react";
import "react-phone-input-2/lib/style.css";
import { Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import { Spinner } from "react-bootstrap";

import { ButtonDarkGreen } from "../../../../generalComponents/buttonDarkGreen/ButtonDarkGreen";
import { EstagioStartup } from "../../../../../domain/entities/EstagioStartup";
import { MecanismoDeSolucaoTecnologicaOutput } from "../../../../../domain/entities/mecanismoDeSolucaoTecnologica";
import { ModeloNegocioOutput } from "../../../../../domain/entities/modeloNegocio";
import { SegmentoAtuacaoOutput } from "../../../../../domain/entities/segmentoAtuacao";
import { TeseDeInvestimentoOutput } from "../../../../../domain/entities/teseDeInvestimento";
import { LocationFinderOutput } from "../../../../../domain/usecases/locationFinder";
import { ResizeContext } from "../../../../../hooks/useResize";
import { AreaEspecializacao } from "../../../../../domain/entities/areaEspecializacaoInvestidorAnjo";
import { Premiacao } from "../../../../../domain/entities/premiacao";
import { ButtonLightGreen } from "../../../../generalComponents/buttonLightGreen/ButtonLightGreen";
import { HandlerCacheInvestidor } from "../../../../../domain/usecases/interfaces/HandlerCacheInvestidor";
import { HandlerCache } from "../../../../../domain/usecases/interfaces/HandlerCache";
import { InputLocais } from "../../../../generalComponents/InputLocais";
import { InvestidorAnjoValidator } from "../../../../../domain/entities/InvestidorAnjoValidator";
import { InputSegmentosAtuacao } from "../../../../generalComponents/InputSegmentosAtuacao";
import { InputMecanismosSolucao } from "../../../../generalComponents/InputMecanismosSolucao";
import { InputTeseInvestimento } from "../../../../generalComponents/InputTeseInvestimento";
import { InputModelosNegocio } from "../../../../generalComponents/InputModelosNegocio";
import { InputEstagiosStartup } from "../../../../generalComponents/InputEstagiosStartup";
import { HandlersContext } from "../../../../../globalStore/HandlersProviders/HandlersContext";
import { SelectAssociacoesAnjo } from "../../../../../typings";

export function ConteudoCadastroInteresseStartup({
  inputPainelInteresseStartup,
  handlerCacheInvestidor,
  onBack,
  onFinalize,
}: {
  inputPainelInteresseStartup: InputPainelInteresseStartup;
  handlerCacheInvestidor: HandlerCacheInvestidor;
  onBack: (output: FormikFields) => void;
  onFinalize: (output: OutputPainelInteresseStartupMobile) => void;
}) {
  const { handlerCreateInvestidor } = useContext(HandlersContext);
  const { responsive } = useContext(ResizeContext);

  const parteDoNome: string =
    inputPainelInteresseStartup.nome.split(" ").at(0) ??
    inputPainelInteresseStartup.nome;
  return (
    <Formik<FormikFields>
      initialValues={{
        interesse_startup_estagio:
          inputPainelInteresseStartup.interesse_startup_estagio ?? [],
        interesse_startup_localizacoes:
          inputPainelInteresseStartup.interesse_startup_localizacoes ?? [],
        interesse_startup_mecanismo_solucao:
          inputPainelInteresseStartup.interesse_startup_mecanismo_solucao ?? [],
        interesse_startup_modelo_negocio:
          inputPainelInteresseStartup.interesse_startup_modelo_negocio ?? [],
        interesse_startup_segmento_atuacao:
          inputPainelInteresseStartup.interesse_startup_segmento_atuacao ?? [],
        tese_de_investimento:
          inputPainelInteresseStartup.tese_de_investimento ?? "",
      }}
      enableReinitialize={true}
      validationSchema={validator}
      validateOnMount={true}
      validateOnChange={true}
      validateOnBlur={true}
      onSubmit={async (values) => {
        if (!handlerCreateInvestidor) {
          console.warn("handlerCreateInvestidor missing");

          return;
        }

        const handlerCreateInvestidorResult =
          await handlerCreateInvestidor.createInvestidor({
            user_id: inputPainelInteresseStartup.auth_user_id,
            nome: inputPainelInteresseStartup.nome,
            areas_de_especializacao:
              inputPainelInteresseStartup.areas_de_especializacao,
            tese_de_investimento: {
              conteudo: values.tese_de_investimento,
            },
            interesse_startup_estagio: values.interesse_startup_estagio,
            interesse_startup_modelo_negocio:
              values.interesse_startup_modelo_negocio,
            interesse_startup_segmento_atuacao:
              values.interesse_startup_segmento_atuacao,
            interesse_startup_mecanismo_solucao:
              values.interesse_startup_mecanismo_solucao,
            associacoes_anjo: inputPainelInteresseStartup.associacoes_anjo,
            interesse_startup_localizacoes:
              values.interesse_startup_localizacoes,
            premiacoes_e_certificados:
              inputPainelInteresseStartup.premiacoes_e_certificados,
            created_in: new Date(),
          });

        if (handlerCreateInvestidorResult.ok) {
          onFinalize({
            auth_id: inputPainelInteresseStartup.auth_user_id,
            nome: inputPainelInteresseStartup.nome,
            interesse_startup_estagio: values.interesse_startup_estagio,
            interesse_startup_localizacoes:
              values.interesse_startup_localizacoes,
            interesse_startup_mecanismo_solucao:
              values.interesse_startup_mecanismo_solucao,
            interesse_startup_modelo_negocio:
              values.interesse_startup_modelo_negocio,
            interesse_startup_segmento_atuacao:
              values.interesse_startup_segmento_atuacao,
            tese_de_investimento: { conteudo: values.tese_de_investimento },
          });
        } else {
          console.warn(handlerCreateInvestidorResult.error);
        }
      }}
    >
      {({
        isValid,
        values,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        submitForm,
        isSubmitting,
      }) => (
        <form className={`${!responsive.sm && "px-3"}`} onSubmit={handleSubmit}>
          <h4 className="card-title text-center mb-5 fw-bold">
            <span>{parteDoNome && `${parteDoNome},`}</span>
            <span className="h5 fw-bold">
              {} selecione abaixo o perfil de Startup que você procura.
            </span>
          </h4>

          <InputTeseInvestimento
            label="Tese de investimento*"
            value={values.tese_de_investimento}
            onChange={(teseInvestimento) => {
              setFieldValue("tese_de_investimento", teseInvestimento);
            }}
            className="mb-3"
          />

          <InputEstagiosStartup
            label="Estágio da startup de interesse*"
            value={values.interesse_startup_estagio}
            onChange={(estagiosStartup) => {
              setFieldValue("interesse_startup_estagio", estagiosStartup);
            }}
            validate={InvestidorAnjoValidator.isInvalidInteresseStartupEstagio}
            className="mb-3"
          />

          <InputLocais
            label="Em que localidades as Startups devem estar"
            value={values.interesse_startup_localizacoes}
            onChange={(interesse_startup_localizacoes) => {
              setFieldValue(
                "interesse_startup_localizacoes",
                interesse_startup_localizacoes
              );
            }}
            validate={InvestidorAnjoValidator.isInvalidLocais}
            className="mb-3"
          />

          <InputModelosNegocio
            label="Com o modelo de negócio*"
            value={values.interesse_startup_modelo_negocio}
            onChange={(modelosNegocio) => {
              setFieldValue("interesse_startup_modelo_negocio", modelosNegocio);
            }}
            validate={
              InvestidorAnjoValidator.isInvalidInteresseStartupModeloNegocio
            }
            className="mb-3"
          />

          <InputSegmentosAtuacao
            label="Que atuam nos seguintes segmentos*"
            value={values.interesse_startup_segmento_atuacao}
            onChange={(interesse_startup_segmento_atuacao) => {
              setTimeout(() => {
                setFieldTouched("interesse_startup_segmento_atuacao", true);
              }, 0);
              setFieldValue(
                "interesse_startup_segmento_atuacao",
                interesse_startup_segmento_atuacao
              );
            }}
            validate={
              InvestidorAnjoValidator.isInvalidInteresseStartupSegmentoAtuacao
            }
            className="mb-3"
          />

          <InputMecanismosSolucao
            label="Que utilizam os seguintes mecanismos de solução tecnológica*"
            value={values.interesse_startup_mecanismo_solucao ?? []}
            onChange={(interesse_startup_mecanismo_solucao) => {
              setFieldValue(
                "interesse_startup_mecanismo_solucao",
                interesse_startup_mecanismo_solucao
              );
            }}
            validate={
              InvestidorAnjoValidator.isInvalidInteresseStartupMecanismoSolucao
            }
            className="mb-3"
          />

          <div className={`${responsive.sm ? "mt-5" : "text-end mt-2"}`}>
            {!responsive.sm && (
              <ButtonLightGreen
                className="me-3"
                onClick={() => {
                  onBack(values);
                }}
              >
                Voltar
              </ButtonLightGreen>
            )}
            <ButtonDarkGreen
              disabled={!isValid || isSubmitting}
              onClick={submitForm}
              className={`pe-5 ${responsive.sm && "w-100"}`}
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className={"me-2" + (isSubmitting ? "" : " invisible")}
              />
              Finalizar
            </ButtonDarkGreen>
          </div>

          <CacheFormik handlerCache={handlerCacheInvestidor}></CacheFormik>
        </form>
      )}
    </Formik>
  );
}

function CacheFormik<T>({
  handlerCache,
}: {
  handlerCache: HandlerCache<T>;
}): JSX.Element {
  const { values } = useFormikContext<T>();

  useEffect(() => {
    handlerCache.save(values);
  }, [handlerCache, values]);

  return <></>;
}

export type InputPainelInteresseStartup = {
  auth_user_id: string;
  nome: string;
  tese_de_investimento: string;
  interesse_startup_estagio: EstagioStartup[];
  interesse_startup_modelo_negocio: ModeloNegocioOutput[];
  interesse_startup_segmento_atuacao: SegmentoAtuacaoOutput[];
  interesse_startup_mecanismo_solucao: MecanismoDeSolucaoTecnologicaOutput[];
  areas_de_especializacao: Pick<AreaEspecializacao, "id" | "nome">[];
  associacoes_anjo: SelectAssociacoesAnjo[];
} & Partial<{
  interesse_startup_localizacoes: LocationFinderOutput[];
  premiacoes_e_certificados: Pick<Premiacao, "nome" | "descricao">[];
}>;

type FormikFields = {
  tese_de_investimento: string;
  interesse_startup_estagio: EstagioStartup[];
  interesse_startup_localizacoes: LocationFinderOutput[];
  interesse_startup_modelo_negocio: ModeloNegocioOutput[];
  interesse_startup_segmento_atuacao: SegmentoAtuacaoOutput[];
  interesse_startup_mecanismo_solucao: MecanismoDeSolucaoTecnologicaOutput[];
};

export type OutputPainelInteresseStartupMobile = {
  auth_id: string;
  nome: string;
  tese_de_investimento: TeseDeInvestimentoOutput;
  interesse_startup_estagio: EstagioStartup[];
  interesse_startup_localizacoes: LocationFinderOutput[];
  interesse_startup_modelo_negocio: ModeloNegocioOutput[];
  interesse_startup_segmento_atuacao: SegmentoAtuacaoOutput[];
  interesse_startup_mecanismo_solucao: MecanismoDeSolucaoTecnologicaOutput[];
};

const validator = Yup.object().shape({
  tese_de_investimento: Yup.string()
    .required("Campo obrigatório, deve ter no mínimo 100 caracteres")
    .min(100, "Mínimo 100 caracteres")
    .max(500, "Máximo 500 caracteres"),
  interesse_startup_estagio: Yup.array()
    .required("Precisa especificar estágio startup de interesse")
    .min(1, "Precisa especificar estágio startup de interesse"),
  interesse_startup_localizacoes: Yup.array(),
  interesse_startup_modelo_negocio: Yup.array()
    .required("Precisa especificar modelo de negócio da startup de interesse")
    .min(1, "Precisa especificar modelo de negócio da startup de interesse"),
  interesse_startup_segmento_atuacao: Yup.array()
    .required("Precisa especificar segmento de atuação da startup de interesse")
    .min(1, "Precisa especificar segmento de atuação da startup de interesse"),
  interesse_startup_mecanismo_solucao: Yup.array()
    .required(
      "Precisa especificar mecanismo de solução da startup de interesse"
    )
    .min(1, "Precisa especificar mecanismo de solução da startup de interesse"),
});
