import { useContext } from "react";
import { ResizeContext } from "../../../../hooks/useResize";
import ButtonWhite from "../../../generalComponents/Button";
import { ButtonLightGreen } from "../../../generalComponents/buttonLightGreen/ButtonLightGreen";
import { LogoTitlePerfilMobile } from "../../cadastro/components/LogoTitlePerfilMobile";

export const ConteudoEscolhaPerfil = () => {
  const { responsive } = useContext(ResizeContext);

  return (
    <div
      style={!responsive.sm ? { padding: "30px 80px" } : {}}
      className="d-flex flex-column align-items-center"
    >
      <div className="d-flex align-items-center justify-content-center mb-4">
        <LogoTitlePerfilMobile />

        <span className="ms-2" style={{ fontSize: 44 }}>
          Sciencin
        </span>
      </div>

      <h2 className="mb-3">Escolha seu perfil abaixo:</h2>

      <div className="row w-100 g-1 g-sm-3 g-md-4">
        <div className="col-4">
          <ButtonWhite
            classes="buttonWhiteHome px-2 m-0"
            link="/cadastro/cientista"
          >
            <span
              style={{
                fontSize: responsive.sm ? "18px" : "22px",
                fontWeight: "bold",
              }}
            >
              Cientista
            </span>
          </ButtonWhite>
        </div>

        <div className="col-4">
          <ButtonWhite
            classes="buttonWhiteHome px-2 m-0"
            link="/cadastro/investidor"
          >
            <span
              style={{
                fontSize: responsive.sm ? "18px" : "22px",
                fontWeight: "bold",
              }}
            >
              Investidor Anjo
            </span>
          </ButtonWhite>
        </div>

        <div className="col-4">
          <ButtonWhite
            classes="buttonWhiteHome px-2 m-0"
            link="/cadastro/mentor"
          >
            <span
              style={{
                fontSize: responsive.sm ? "18px" : "22px",
                fontWeight: "bold",
              }}
            >
              Mentor
            </span>
          </ButtonWhite>
        </div>
      </div>

      <h2 className="mt-4 mb-3">Cadastre sua organização:</h2>

      <div className="row w-100 g-1 g-sm-3 g-md-4">
        <div className="col-4">
          <ButtonWhite
            classes="buttonWhiteHome px-2 m-0"
            link="/cadastro/startup"
          >
            <span
              style={{
                fontSize: responsive.sm ? "18px" : "22px",
                fontWeight: "bold",
              }}
            >
              Startup
            </span>
          </ButtonWhite>
        </div>

        <div className="col-4">
          <ButtonWhite
            classes="buttonWhiteHome px-2 m-0"
            link="/cadastro/venture_capital"
            disabled
          >
            <span
              style={{
                fontSize: responsive.sm ? "18px" : "22px",
                fontWeight: "bold",
              }}
            >
              Venture Capital
            </span>
          </ButtonWhite>
        </div>

        <div className="col-4">
          <ButtonWhite
            classes="buttonWhiteHome px-2 m-0"
            link="/cadastro/empresa"
            disabled
          >
            <span
              style={{
                fontSize: responsive.sm ? "18px" : "22px",
                fontWeight: "bold",
              }}
            >
              Empresa
            </span>
          </ButtonWhite>
        </div>

        <div className="col-4">
          <ButtonWhite
            classes="buttonWhiteHome px-2 m-0"
            link="/cadastro/nit"
            disabled
          >
            <span
              style={{
                fontSize: responsive.sm ? "18px" : "22px",
                fontWeight: "bold",
              }}
            >
              NIT
            </span>
          </ButtonWhite>
        </div>

        <div className="col-4">
          <ButtonWhite
            classes="buttonWhiteHome px-2 m-0"
            link="/cadastro/hub"
            disabled
          >
            <span
              style={{
                fontSize: responsive.sm ? "18px" : "22px",
                fontWeight: "bold",
              }}
            >
              Hub de Inovação
            </span>
          </ButtonWhite>
        </div>
      </div>

      {!responsive.sm && (
        <div className="align-self-end">
          <ButtonLightGreen className="mt-3 mb-4" link={"/"}>
            VOLTAR
          </ButtonLightGreen>
        </div>
      )}
    </div>
  );
};
