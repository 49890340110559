import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useContext, useEffect, useMemo, useState } from "react";
import slug from "slug";
slug.defaults.modes.pretty.replacement = "_";
slug.defaults.modes.rfc3986.replacement = "_";

import { InputEditGrupoDePesquisa } from "./InputEditGrupoDePesquisa";
import { InputAddGrupoDePesquisa } from "./InputAddGrupoDePesquisa";
import { GrupoDePesquisa } from "../../../domain/entities/grupoDePesquisa";
import { ItemGrupoDePesquisa } from "./ItemGrupoDePesquisa";
import { TipoDeGrupoDePesquisa } from "../../../domain/entities/tipoDeGrupoDePesquisa";
import { Either, NewValueSelect } from "../../../typings";
import { HandlersContext } from "../../../globalStore/HandlersProviders/HandlersContext";
import { OutputHandlerGetTipoGruposPesquisa } from "../../../domain/usecases/interfaces/handlerGetTipoGrupoPesquisa";
import { CurrentUserContext } from "../../../globalStore/CurrentUserContext";

export function InputGruposDePesquisa({
  label,
  className,
  value = [],
  onListChange,
  showTip = true,
  isEditing,
}: {
  label: string;
  className?: string;
  value: GrupoDePesquisa[];
  onListChange: (gruposDePesquisa: GrupoDePesquisa[]) => void;
  showTip?: boolean;
  isEditing: (isEditing: boolean) => void;
}) {
  const { handlerGetTipoGrupoPesquisa } = useContext(HandlersContext);
  const { currentUserScyggz } = useContext(CurrentUserContext);

  const [allTipoGrupoPesquisa, setAllTipoGrupoPesquisa] = useState<
    TipoDeGrupoDePesquisa[]
  >([]);
  const [isAllTipoGrupoPesquisaLoading, setIsAllTipoGrupoPesquisaLoading] =
    useState<boolean>(false);
  const [status, setStatus] = useState<
    | undefined
    | { type: "add" }
    | {
        type: "edit";
        grupoDePesquisaToEdit: {
          grupoDePesquisa: TypeInputGrupoDePesquisa;
          index: number;
        };
      }
  >();
  const [valueInput, setValueInput] = useState<TypeInputGrupoDePesquisa[]>([]);

  const allTipoGrupoPesquisaWithSelected = useMemo<
    SelectTipoDeGrupoDePesquisa[]
  >(() => {
    const newAllTipoGrupoPesquisaWithSelected = allTipoGrupoPesquisa.map(
      (tipo) => convertTipoGrupoToSelectTipoGrupo(tipo)
    );

    value.forEach((grupo) => {
      const tipo = newAllTipoGrupoPesquisaWithSelected.find((tipo) => {
        const id: string = tipo.id ?? tipo.value;

        return id == grupo.tipo.id;
      });

      if (!tipo) {
        newAllTipoGrupoPesquisaWithSelected.push(
          convertTipoGrupoToSelectTipoGrupo({ ...grupo.tipo, grupos: [] })
        );
      }
    });

    return newAllTipoGrupoPesquisaWithSelected;
  }, [allTipoGrupoPesquisa, value]);

  useEffect(() => {
    setValueInput(
      value.map((grupo) => {
        return convertGrupoToTypeGrupo(grupo);
      })
    );
  }, [value]);

  useEffect(() => {
    if (status == undefined) isEditing(false);
  }, [isEditing, status]);

  useEffect(() => {
    setIsAllTipoGrupoPesquisaLoading(true);
    if (handlerGetTipoGrupoPesquisa)
      handlerGetTipoGrupoPesquisa
        .getAllTipoGrupoPesquisa()
        .then((getAllTipoGrupoPesquisaResult) => {
          if (getAllTipoGrupoPesquisaResult.ok)
            setAllTipoGrupoPesquisa(
              filtrarEnabledAndAutor(
                getAllTipoGrupoPesquisaResult.value,
                currentUserScyggz.user?.auth_user_id ?? ""
              )
            );

          setIsAllTipoGrupoPesquisaLoading(false);
        });
  }, [currentUserScyggz.user?.auth_user_id, handlerGetTipoGrupoPesquisa]);

  return (
    <div className={className}>
      <label
        className="form-label fw-bolder m-0 d-block"
        htmlFor="selAreasConhecimento"
      >
        {label}
      </label>

      {status == undefined ? (
        <>
          {showTip && (
            <p>
              <small>
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  className="opacity-25 me-2"
                />
                Você poderá adicionar mais grupos de pesquisa após a criação da
                sua conta
              </small>
            </p>
          )}

          {valueInput.map((grupoDePesquisa, i) => (
            <ItemGrupoDePesquisa
              key={i}
              grupoDePesquisa={grupoDePesquisa}
              onClickDelete={() => {
                valueInput.splice(i, 1);
                onListChange(
                  [...valueInput].map((typeGrupo) =>
                    convertTypeGrupoToGrupo(typeGrupo)
                  )
                );
              }}
              onClickEdit={() => {
                setStatus({
                  type: "edit",
                  grupoDePesquisaToEdit: {
                    grupoDePesquisa,
                    index: i,
                  },
                });
              }}
            />
          ))}

          <button
            type="button"
            className="btn btn-outline-success fw-bolder"
            title="adicionar grupo de pesquisa"
            onClick={() =>
              setStatus({
                type: "add",
              })
            }
          >
            <FontAwesomeIcon icon={faPlus} className="me-1" /> grupo de pesquisa
          </button>
        </>
      ) : (
        <>
          {status.type == "edit" ? (
            <InputEditGrupoDePesquisa
              onCancel={() => {
                setStatus(undefined);
              }}
              grupoDePesquisaToEdit={
                status.grupoDePesquisaToEdit.grupoDePesquisa
              }
              onSave={(grupoDePesquisa) => {
                value.splice(
                  status.grupoDePesquisaToEdit.index,
                  1,
                  convertTypeGrupoToGrupo(grupoDePesquisa)
                );
                onListChange([...value]);
                setStatus(undefined);
              }}
              allTipoGrupoPesquisa={allTipoGrupoPesquisaWithSelected} // TODO: FIX
              isLoading={isAllTipoGrupoPesquisaLoading}
              onChange={() => isEditing(true)}
            />
          ) : (
            <>
              {status.type == "add" && (
                <>
                  <InputAddGrupoDePesquisa
                    onAdd={(grupoDePesquisa) => {
                      onListChange([
                        ...value,
                        convertTypeGrupoToGrupo(grupoDePesquisa),
                      ]);
                      setStatus(undefined);
                    }}
                    onCancel={() => {
                      setStatus(undefined);
                    }}
                    allTipoGrupoPesquisa={allTipoGrupoPesquisaWithSelected}
                    isLoading={isAllTipoGrupoPesquisaLoading}
                    onChange={() => isEditing(true)}
                  />
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

function convertTipoGrupoToSelectTipoGrupo(
  tipo: TipoDeGrupoDePesquisa
): SelectTipoDeGrupoDePesquisa {
  if (tipo.aprovado) {
    return {
      id: tipo.id,
      nome: tipo.nome,
      aprovado: tipo.aprovado,
      grupos: tipo.grupos.map((grupo) => {
        if (grupo.aprovado) {
          return {
            id: grupo.id,
            nome: grupo.nome,
            aprovado: grupo.aprovado,
            tipo: tipo,
          };
        } else {
          return {
            __isNew__: true,
            value: grupo.id,
            label: grupo.nome,
          };
        }
      }),
    };
  }

  return {
    __isNew__: true,
    value: tipo.id,
    label: tipo.nome,
  };
}

function filtrarEnabledAndAutor(
  allTipos: OutputHandlerGetTipoGruposPesquisa[],
  autor_id: string
): OutputHandlerGetTipoGruposPesquisa[] {
  return allTipos.filter((tipo) => tipo.autor === autor_id || tipo.aprovado);
}

function convertTypeGrupoToGrupo(
  grupo: TypeInputGrupoDePesquisa
): GrupoDePesquisa {
  return {
    id: grupo.grupo.id ?? slug(grupo.grupo.value),
    nome: grupo.grupo.nome ?? grupo.grupo.label,
    aprovado: !!grupo.grupo.aprovado,
    tipo: {
      id: grupo.tipo.id ?? slug(grupo.tipo.value),
      nome: grupo.tipo.nome ?? grupo.tipo.label,
      aprovado: !!grupo.tipo.aprovado,
    },
  };
}

function convertGrupoToTypeGrupo(
  grupo: GrupoDePesquisa
): TypeInputGrupoDePesquisa {
  if (grupo.aprovado) {
    return {
      grupo: {
        id: grupo.id,
        nome: grupo.nome,
        aprovado: true,
        tipo: grupo.tipo,
      },
      tipo: {
        id: grupo.tipo.id,
        nome: grupo.tipo.nome,
        aprovado: true,
        grupos: [], // TODO: FIX:
      },
    };
  }

  if (grupo.tipo.aprovado) {
    return {
      grupo: {
        __isNew__: true,
        label: grupo.nome,
        value: grupo.id,
      },
      tipo: {
        id: grupo.tipo.id,
        nome: grupo.tipo.nome,
        aprovado: true,
        grupos: [],
      },
    };
  }

  return {
    grupo: {
      __isNew__: true,
      label: grupo.nome,
      value: grupo.id,
    },
    tipo: {
      __isNew__: true,
      label: grupo.tipo.nome,
      value: grupo.tipo.id,
    },
  };
}

export type SelectTipoDeGrupoDePesquisa = Either<
  Omit<TipoDeGrupoDePesquisa, "grupos"> & {
    grupos: SelectGrupoDePesquisa[];
  },
  NewValueSelect
>;

export type SelectGrupoDePesquisa = Either<GrupoDePesquisa, NewValueSelect>;

export type TypeInputGrupoDePesquisa = {
  tipo: SelectTipoDeGrupoDePesquisa;
  grupo: SelectGrupoDePesquisa;
};
