import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { CurrentUserContext } from "../globalStore/CurrentUserContext";

export function RouteGuardWithUsuario({
  withUsuarioPath,
  withoutUsuarioPath,
  isOrganizacao,
  children,
}: {
  withUsuarioPath?: string;
  withoutUsuarioPath?: string;
  isOrganizacao?: boolean;
  children: JSX.Element;
}) {
  const { currentUserAuth, currentUserScyggz } = useContext(CurrentUserContext);

  const navigate = useNavigate();
  const [toShow, setToShow] = useState<JSX.Element>();

  useEffect(() => {
    if (currentUserAuth.status == "carregado") {
      if (
        currentUserAuth.user_auth &&
        currentUserScyggz.status == "encontrado"
      ) {
        if (withUsuarioPath) {
          navigate(withUsuarioPath);
          console.warn("Usuário encontrado: redirect to", withUsuarioPath);
        } else {
          setToShow(children);
        }
      } else if (currentUserScyggz.status == "nao_encontrado" && isOrganizacao == false) {
        if (withoutUsuarioPath) {
          navigate(withoutUsuarioPath);
          console.warn(
            "Usuário não encontrado: redirect to",
            withoutUsuarioPath
          );
        } else {
          setToShow(children);
        }
      } else if (isOrganizacao) {
        setToShow(children);
      }
    }
  }, [
    withUsuarioPath,
    currentUserScyggz.status,
    withoutUsuarioPath,
    children,
    currentUserAuth.status,
    currentUserAuth.user_auth,
    navigate,
    isOrganizacao
  ]);

  return <>{toShow}</>;
}
