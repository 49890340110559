import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { HandlerCache } from "../domain/usecases/interfaces/HandlerCache";

export function RouteGuardHasCache<T>({
  redirectPath,
  requireds,
  handlerCache,
  children,
}: {
  redirectPath: string;

  requireds: (keyof T)[];

  handlerCache: HandlerCache<T>;

  children: JSX.Element;
}) {
  const navigate = useNavigate();
  const [toShow, setToShow] = useState<JSX.Element>();

  useEffect(() => {
    handlerCache.get().then((cacheResult) => {
      if (
        cacheResult.ok &&
        requireds.every((required) => cacheResult.value[required])
      ) {
        setToShow(children);
      } else {
        navigate(redirectPath);
        console.warn(
          "Campos requeridos em cache: ",
          requireds,
          "cacheResult: ",
          cacheResult
        );
      }
    });
  }, [children, handlerCache, navigate, redirectPath, requireds]);

  return <>{toShow}</>;
}
