/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ReactElement,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { Spinner } from "react-bootstrap";

import { Cidade, CidadeOutput } from "../../../../domain/entities/cidade";
import { Telefone } from "../../../../domain/entities/telefone";
import { UrlLinkedin } from "../../../../domain/entities/urlLinkedin";
import {
  UsuarioScyggz,
  InputUsuarioScyggzEntity,
} from "../../../../domain/entities/usuarioScyggz";
import { HandlerCreateUsuarioScyggz } from "../../../../domain/usecases/interfaces/handlerCreateUsuarioScyggz";
import { HandlerSaveTelefone } from "../../../../domain/usecases/interfaces/handlerSaveTelefone";
import { HandlerSaveUrlLinkedin } from "../../../../domain/usecases/interfaces/handlerSaveUrlLinkedin";
import { Result } from "../../../../typings";
import { ButtonDarkGreen } from "../../../generalComponents/buttonDarkGreen/ButtonDarkGreen";
import { ResizeContext } from "../../../../hooks/useResize";
import { InputCidade } from "../../../generalComponents/InputCidade";
import { UsuarioScyggzValidator } from "../../../../domain/entities/UsuarioScyggzValidator";
import {
  InputTelefone,
  TypeInputTelefone,
} from "../../../generalComponents/InputTelefone";
import { InputLinkedin } from "../../../generalComponents/InputLinkedin";
import { InputHeadlineUsuario } from "../../../generalComponents/InputHeadlineUsuario";
import {
  HandlerUpdateUsuarioScyggz,
  InputHandlerUpdateUsuarioScyggz,
} from "../../../../domain/usecases/interfaces/handlerUpdateUsuarioScyggz";
import { HandleCacheNewUser } from "../../../../domain/usecases/interfaces/HandleCacheNewUser";

type ConteudoCadastroInfosPessoaisProps = {
  inputPainelCadastroInfosPessoais: InputPainelCadastroInfosPessoais;
  labelHeadline?: ReactElement | string;
  handlerSaveUrlLinkedin?: HandlerSaveUrlLinkedin;
  handlerSaveTelefone?: HandlerSaveTelefone;
  handlerCreateUsuarioScyggz?: HandlerCreateUsuarioScyggz;
  handlerUpdateUsuarioScyggz?: HandlerUpdateUsuarioScyggz;
  onCadastro: (output: OutputPainelCadastroInfosPessoais) => void;
  userExiste?: boolean;
  handlerCacheNewUser?: HandleCacheNewUser;
  handlerSalvandoNovoUsuario?: (salvando: boolean) => void;
};

export const ConteudoCadastroInfosPessoais = ({
  inputPainelCadastroInfosPessoais,
  labelHeadline = (
    <>
      Sua profissão <span className="opacity-75"> (headline)</span>
    </>
  ),
  handlerSaveUrlLinkedin,
  handlerSaveTelefone,
  handlerCreateUsuarioScyggz,
  handlerUpdateUsuarioScyggz,
  onCadastro,
  userExiste,
  handlerCacheNewUser,
  handlerSalvandoNovoUsuario,
}: ConteudoCadastroInfosPessoaisProps) => {
  const { responsive } = useContext(ResizeContext);

  const [values, setValues] = useState<InputFormInfosPessoais>({
    headline: inputPainelCadastroInfosPessoais.headline ?? "",
    phoneNumber: inputPainelCadastroInfosPessoais.phoneNumber ?? {
      invalid: true,
      numero: "",
    },
    urlLinkedin: inputPainelCadastroInfosPessoais.urlLinkedin ?? "",
    cidade: inputPainelCadastroInfosPessoais.cidade,
  });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const isValid: boolean = useMemo(() => {
    const validateResult = UsuarioScyggzValidator.validateUpdate({
      headline: values.headline,
      telefone: values.phoneNumber?.invalid ? "" : values.phoneNumber?.numero,
      url_linkedin: values.urlLinkedin,
      cidade: values.cidade,
    });

    return validateResult.ok;
  }, [values]);
  const parteDoNome: string = useMemo(
    () =>
      inputPainelCadastroInfosPessoais.nome.split(" ").at(0) ??
      inputPainelCadastroInfosPessoais.nome,
    [inputPainelCadastroInfosPessoais.nome]
  );
  const onSubmit = useCallback(() => {
    if (!handlerCreateUsuarioScyggz) {
      console.warn("required handlerCreateUsuarioScyggz");
      return;
    }
    if (!handlerSaveUrlLinkedin) {
      console.warn("required handlerSaveUrlLinkedin");
      return;
    }
    if (!handlerSaveTelefone) {
      console.warn("required handlerSaveTelefone");
      return;
    }

    if (!userExiste) {
      const cidadeResult = Cidade.create({
        cidade: values.cidade?.cidade ?? "",
        estado: values.cidade?.estado ?? "",
        pais: values.cidade?.pais ?? "",
        paisCode: values.cidade?.paisCode ?? "",
      });
      if (!cidadeResult.ok) {
        console.warn("Necessita especificar cidade");
        setIsSubmitting(false);
        return;
      }

      const telefoneResult = Telefone.create({
        auth_id: inputPainelCadastroInfosPessoais.auth_id,
        numero: values.phoneNumber?.numero ?? "",
      });
      if (!telefoneResult.ok) {
        console.warn("Necessita especificar telefone");
        setIsSubmitting(false);
        return;
      }

      const urlLinkedinResult = UrlLinkedin.create({
        auth_id: inputPainelCadastroInfosPessoais.auth_id,
        urlLinkedin: values.urlLinkedin ?? "",
      });

      const usuarioScyggzResult = UsuarioScyggz.createUsuario({
        auth_user_id: inputPainelCadastroInfosPessoais.auth_id,
        nome: inputPainelCadastroInfosPessoais.nome,
        cidade: {
          cidade: cidadeResult.value.cidade,
          estado: cidadeResult.value.estado.estado,
          pais: cidadeResult.value.estado.pais.pais,
          paisCode: cidadeResult.value.estado.pais.paisCode,
        },
        url_linkedin: urlLinkedinResult.ok
          ? urlLinkedinResult.value.urlLinkedin
          : undefined,
        headline: values.headline,
        telefone: telefoneResult.value.numero,
        aceite_termo_de_servico:
          inputPainelCadastroInfosPessoais.aceite_termo_de_servico,
        aceite_politica_de_privacidade:
          inputPainelCadastroInfosPessoais.aceite_politica_de_privacidade,
      });

      if (!usuarioScyggzResult.ok) {
        //console.warn("Erro validação usuário scyggz", usuarioScyggzResult.error);
        setIsSubmitting(false);
        return;
      }

      const allPromises: Promise<Result<any>>[] = [];

      if (handlerSalvandoNovoUsuario) {
        handlerSalvandoNovoUsuario(true);
      }

      if (urlLinkedinResult.ok) {
        allPromises.push(
          handlerSaveUrlLinkedin.saveUrlLinkedin({
            auth_id: inputPainelCadastroInfosPessoais.auth_id,
            urlLinkedin: urlLinkedinResult.value.urlLinkedin,
          })
        );
      }
      allPromises.push(
        handlerSaveTelefone.saveTelefone({
          auth_id: inputPainelCadastroInfosPessoais.auth_id,
          numero: telefoneResult.value.numero,
        })
      );

      const inputUsuarioScyggzEntity: InputUsuarioScyggzEntity = {
        auth_user_id: usuarioScyggzResult.value.auth_user_id,
        nome: usuarioScyggzResult.value.nome,
        cidade: usuarioScyggzResult.value.cidade,
        url_linkedin: usuarioScyggzResult.value.url_linkedin,
        headline: usuarioScyggzResult.value.headline,
        telefone: usuarioScyggzResult.value.telefone,
        aceite_termo_de_servico:
          usuarioScyggzResult.value.aceite_termo_de_servico,
        aceite_politica_de_privacidade:
          usuarioScyggzResult.value.aceite_politica_de_privacidade,
      };

      allPromises.push(
        handlerCreateUsuarioScyggz.createUsuarioScyggz(inputUsuarioScyggzEntity)
      );

      Promise.all(allPromises).then((results) => {
        setIsSubmitting(false);

        if (results.every((result) => result.ok)) {
          handlerCacheNewUser?.save(true);

          if (handlerSalvandoNovoUsuario) {
            handlerSalvandoNovoUsuario(false);
          }

          onCadastro({
            auth_id: inputUsuarioScyggzEntity.auth_user_id,
            nome: inputUsuarioScyggzEntity.nome,
          });
        } else {
          console.warn("Error", results);

          if (handlerSalvandoNovoUsuario) {
            handlerSalvandoNovoUsuario(false);
          }
        }
      });
    } else {
      if (handlerSalvandoNovoUsuario) {
        handlerSalvandoNovoUsuario(false);
      }

      if (!handlerUpdateUsuarioScyggz) {
        console.warn("required handlerUpdateUsuarioScyggz");
        return;
      }

      const inputAlterUsuarioScyggzEntity: InputHandlerUpdateUsuarioScyggz = {
        headline: values.headline,
      };

      handlerUpdateUsuarioScyggz.updateUsuarioScyggz(
        {
          auth_user_id: inputPainelCadastroInfosPessoais.auth_id,
        },
        inputAlterUsuarioScyggzEntity
      );

      onCadastro({
        auth_id: inputPainelCadastroInfosPessoais.auth_id,
        nome: inputPainelCadastroInfosPessoais.nome,
      });
    }
  }, [
    handlerUpdateUsuarioScyggz,
    handlerCreateUsuarioScyggz,
    handlerSaveTelefone,
    handlerSaveUrlLinkedin,
    inputPainelCadastroInfosPessoais.aceite_politica_de_privacidade,
    inputPainelCadastroInfosPessoais.aceite_termo_de_servico,
    inputPainelCadastroInfosPessoais.auth_id,
    inputPainelCadastroInfosPessoais.nome,
    onCadastro,
    values.cidade?.cidade,
    values.cidade?.estado,
    values.cidade?.pais,
    values.cidade?.paisCode,
    values.headline,
    values.phoneNumber?.numero,
    values.urlLinkedin,
    userExiste,
    handlerCacheNewUser,
    handlerSalvandoNovoUsuario,
  ]);

  return (
    <div className={"p-2 rounded-1 overflow-auto"}>
      <h5 className="card-title text-center mb-4 fw-bold">
        <span className="h4 fw-bolder">{parteDoNome}</span>, insira os seguintes
        dados pessoais.
      </h5>

      {!userExiste && (
        <div>
          <div
            style={{ marginBottom: responsive.sm ? "-20px" : "" }}
            className={`${responsive.sm ?? "mb-2"}`}
          >
            <InputTelefone
              label={!responsive.sm ? "Celular*" : ""}
              value={values.phoneNumber}
              onChange={(phoneNumber) => {
                setValues((values) => ({ ...values, phoneNumber }));
              }}
              validate={(numero) => {
                const preenchido: boolean = (numero?.length ?? 0) > 0;

                if (!preenchido) {
                  return new Error("obrigatório");
                }
              }}
              className="mb-3"
            />
          </div>

          <div className="mb-4">
            <InputCidade
              label={!responsive.sm ? "Qual a sua cidade*" : ""}
              value={values.cidade}
              onChange={(cidade) => {
                setValues((values) => ({ ...values, cidade }));
              }}
              validate={UsuarioScyggzValidator.isInvalidCidade}
            />
          </div>

          <div className="mb-4">
            <InputLinkedin
              label="Linkedin"
              value={values.urlLinkedin}
              onChange={(urlLinkedin) => {
                setValues((values) => ({ ...values, urlLinkedin }));
              }}
            />
          </div>
        </div>
      )}

      <div className="mb-4">
        <InputHeadlineUsuario
          label={labelHeadline}
          value={values.headline}
          onChange={(headline) => {
            setValues((values) => ({ ...values, headline }));
          }}
        />
      </div>

      <div className="pt-4 text-end">
        <ButtonDarkGreen
          disabled={(!isValid || isSubmitting) && !userExiste}
          className={`pe-5 ${responsive.sm && "w-100"} text-uppercase`}
          onClick={onSubmit}
        >
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className={"me-2" + (isSubmitting ? "" : " invisible")}
          />
          avançar
        </ButtonDarkGreen>
      </div>
    </div>
  );
};
//criar uma validação para o caso de ser só startup com usuario existente

export type InputPainelCadastroInfosPessoais = {
  auth_id: string;
  nome: string;
  aceite_termo_de_servico: Date;
  aceite_politica_de_privacidade: Date;
  phoneNumber?: TypeInputTelefone;
  cidade?: CidadeOutput;
  urlLinkedin?: string;
  headline?: string;
};

export type InputFormInfosPessoais = {
  phoneNumber: TypeInputTelefone;
  cidade?: CidadeOutput;
  urlLinkedin?: string;
  headline?: string;
};

export type OutputPainelCadastroInfosPessoais = {
  auth_id: string;
  nome: string;
};
