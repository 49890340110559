import { TextoMarcaDaguaMobile } from "../../../generalComponents/TextoMarcaDaguaMobile";
import arvore from "../../../../assets/images/icone_arvore.png"

export function LogoTitlePerfilMobile({
  textMarcaDagua
}: {
  textMarcaDagua?: string;
}): JSX.Element {

  const imgLogo = <img width={68} src={arvore} alt="Logo Arvore" />

  return (
    textMarcaDagua ?
      <div className="d-flex flex-column align-items-center mt-3">
        {imgLogo}
        <h3 className="text-center">
          <TextoMarcaDaguaMobile>{textMarcaDagua}</TextoMarcaDaguaMobile>
        </h3>
      </div>

      : imgLogo
  )
}