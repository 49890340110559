import { useEffect, useMemo, useState } from "react";

import { UsuarioScyggz } from "../../../../domain/entities/usuarioScyggz";

export function LinkedinPerfilButton({
  dataIssue,
  user,
  className = "",
}: {
  dataIssue: Date;
  user: Pick<UsuarioScyggz, "auth_user_id" | "nome">;
  className?: string | undefined;
}) {
  const issueYear = useMemo<number>(() => dataIssue.getFullYear(), [dataIssue]);
  const issueMonth = useMemo<number>(
    () => dataIssue.getMonth() + 1,
    [dataIssue]
  );

  const [urlPerfil, setUrlPerfil] = useState<string>("");

  useEffect(() => {
    setUrlPerfil("https://sciencin.com/user/" + user.auth_user_id);
  }, [user]);

  return (
    <a
      className={className}
      target="_blank"
      href={`https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=Perfil Scyggz&organizationId=80758728&issueYear=${issueYear}&issueMonth=${issueMonth}&certUrl=${urlPerfil}`}
    >
      <img
        src="https://download.linkedin.com/desktop/add2profile/buttons/pt_BR.png"
        alt="Adicionar Perfil scyggz no LinkedIn"
      />
    </a>
  );
}
