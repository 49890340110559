import { Firestore, collection, getDocs } from "@firebase/firestore";

import { Result } from "../typings";
import { HandlerGetMecanismoSolucao } from "../domain/usecases/interfaces/handlerGetMecanismoSolucao";
import { MecanismoDeSolucaoTecnologicaOutput } from "../domain/entities/mecanismoDeSolucaoTecnologica";

export class HandlerGetMecanismoSolucaoFirebase
  implements HandlerGetMecanismoSolucao
{
  constructor(private firestore: Firestore) {}

  getAllMecanismoSolucao(): Promise<
    Result<MecanismoDeSolucaoTecnologicaOutput[]>
  > {
    return new Promise((resolve) => {
      getDocs(collection(this.firestore, "mecanismos_startup"))
        .then((querySnapshot) => {
          const mecanismos: MecanismoDeSolucaoTecnologicaOutput[] =
            querySnapshot.docs.map((docSnapshot) => {
              const mecanismo =
                docSnapshot.data() as MecanismoDeSolucaoTecnologicaOutput;
              mecanismo.id = docSnapshot.id;
              return mecanismo;
            });

          resolve({
            ok: true,
            value: mecanismos,
          });
        })
        .catch((error) =>
          resolve({
            ok: false,
            error,
          })
        );
    });
  }
}
