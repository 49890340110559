import { useNavigate } from "react-router-dom";

import { HandlerCacheInvestidor } from "../../../../../domain/usecases/interfaces/HandlerCacheInvestidor";
import { ContainerResponsive } from "../../../../generalComponents/ContainerResponsive";
import { ConteudoAssociacaoAnjo } from "./ConteudoAssociacaoAnjo";

export const PainelInvestidorCadastroAssociacaoAnjo = ({
  handlerCacheInvestidor,
}: {
  handlerCacheInvestidor: HandlerCacheInvestidor;
}) => {
  const navigate = useNavigate();

  const paginacao = {
    start: 5,
    end: 6,
  };

  const backUrl = "/cadastro/investidor/4";
  const textoMarcaDagua = "Investidor(a) Anjo";
  const etapa6 = "/cadastro/investidor/6";

  return (
    <section style={{ height: "100%" }}>
      <ContainerResponsive
        backUrlMobile={backUrl}
        paginacao={paginacao}
        textoMarcaDagua={textoMarcaDagua}
        showLogo={true}
      >
        <ConteudoAssociacaoAnjo
          handlerCacheInvestidor={handlerCacheInvestidor}
          onBack={() => {
            navigate(backUrl);
          }}
          onNext={() => {
            navigate(etapa6);
          }}
        />
      </ContainerResponsive>
    </section>
  );
};
