import { Result } from "../../typings";
import {
  AreaAplicacaoTecnologia,
  AreaAplicacaoTecnologiaOutput,
} from "./areaAplicacaoTecnologia";
import { AreaConhecimento, AreaConhecimentoOutput } from "./areaConhecimento";
import {
  MecanismoDeSolucaoTecnologica,
  MecanismoDeSolucaoTecnologicaOutput,
} from "./mecanismoDeSolucaoTecnologica";

export class TecnologiaDoCientista {
  static readonly NOME_MINIMO_LENGTH = 1;
  static readonly NOME_MINIMO_ERROR = "Nome obrigatório";
  static readonly NOME_MAXIMO_LENGTH = 80;
  static readonly NOME_MAXIMO_ERROR =
    "Máximo " + TecnologiaDoCientista.NOME_MAXIMO_LENGTH + " caracteres";

  static readonly RESUMO_MINIMO_LENGTH = 10;
  static readonly RESUMO_MINIMO_ERROR =
    "Mínimo " + TecnologiaDoCientista.RESUMO_MINIMO_LENGTH + " caracteres";
  static readonly RESUMO_MAXIMO_LENGTH = 500;
  static readonly RESUMO_MAXIMO_ERROR =
    "Máximo " + TecnologiaDoCientista.RESUMO_MAXIMO_LENGTH + " caracteres";

  private constructor(
    public nome: string,
    public areas_de_aplicacao: AreaAplicacaoTecnologiaOutput[],
    public areas_de_conhecimento: AreaConhecimentoOutput[],
    public mecanismos_de_solucao: MecanismoDeSolucaoTecnologicaOutput[],
    public resumo: string
  ) {}

  public static create(
    tecnologiaInput: TecnologiaDoCientistaInput
  ): Result<
    TecnologiaDoCientistaInput,
    { [P in keyof Partial<TecnologiaDoCientistaInput>]: Error }
  > {
    return TecnologiaDoCientista.validate(tecnologiaInput);
  }

  public static validate(
    tecnologia: TecnologiaDoCientistaInput
  ): Result<
    TecnologiaDoCientistaInput,
    { [P in keyof Partial<TecnologiaDoCientistaInput>]: Error }
  > {
    let erroNome = "";
    tecnologia.nome = tecnologia.nome.trim();
    if (tecnologia.nome.length < this.NOME_MINIMO_LENGTH) {
      erroNome = this.NOME_MINIMO_ERROR;
    } else if (tecnologia.nome.length > this.NOME_MAXIMO_LENGTH) {
      erroNome = this.NOME_MAXIMO_ERROR;
    }

    let erroResumo = "";
    tecnologia.resumo = tecnologia.resumo.trim();
    if (tecnologia.resumo.length < 10) {
      erroResumo = "Tamanho mínimo 10 caracteres";
    } else if (tecnologia.resumo.length > 500) {
      erroResumo = "Tamanho máximo 500 caracteres";
    }

    let erroAreasAplicacao = "";
    if (tecnologia.areas_de_aplicacao.length == 0) {
      erroAreasAplicacao = "Áreas de aplicação obrigatório";
    }

    const erroAreasConhecimento = this.validateAreasConhecimento(
      tecnologia.areas_de_conhecimento
    );

    let erroMecanismo = "";
    if (tecnologia.mecanismos_de_solucao.length == 0) {
      erroMecanismo = "Mecanismo de solução obrigatório";
    }

    if (
      erroNome ||
      erroResumo ||
      erroAreasAplicacao ||
      erroAreasConhecimento ||
      erroMecanismo
    ) {
      return {
        ok: false,
        error: {
          nome: erroNome ? new Error(erroNome) : undefined,
          resumo: erroResumo ? new Error(erroResumo) : undefined,
          areas_de_aplicacao: erroAreasAplicacao
            ? new Error(erroAreasAplicacao)
            : undefined,
          areas_de_conhecimento: erroAreasConhecimento
            ? erroAreasConhecimento
            : undefined,
          mecanismos_de_solucao: erroMecanismo
            ? new Error(erroMecanismo)
            : undefined,
        },
      };
    }

    return {
      ok: true,
      value: tecnologia,
    };
  }

  public static validateAreasConhecimento(
    areas: AreaConhecimento[] | undefined
  ): Error | undefined {
    if ((areas?.length ?? 0) < 1) {
      return new Error("Áreas de conhecimento obrigatório");
    }
  }

  public static validateMecanismosTecnologia(
    mecanismos: MecanismoDeSolucaoTecnologica[] | undefined
  ): Error | undefined {
    if ((mecanismos?.length ?? 0) < 1) {
      return new Error("Mecanismo de Solução Tecnológica obrigatório");
    }
  }

  public static validateAreasAplicacaoTecnologia(
    areas: AreaAplicacaoTecnologia[] | undefined
  ): Error | undefined {
    if ((areas?.length ?? 0) < 1) {
      return new Error("Áreas de aplicação tecnológica obrigatório");
    }
  }
}

export type TecnologiaDoCientistaInput = {
  nome: string;
  areas_de_aplicacao: AreaAplicacaoTecnologiaOutput[];
  areas_de_conhecimento: AreaAplicacaoTecnologiaOutput[];
  mecanismos_de_solucao: MecanismoDeSolucaoTecnologicaOutput[];
  resumo: string;
};

export type TecnologiaDoCientistaOutput = {
  nome: string;
  areas_de_aplicacao: AreaAplicacaoTecnologiaOutput[];
  areas_de_conhecimento: AreaConhecimentoOutput[];
  mecanismos_de_solucao: MecanismoDeSolucaoTecnologicaOutput[];
  resumo: string;
};
