import { Firestore, addDoc, collection } from "@firebase/firestore";

import { Result } from "../typings";
import { HandlerCreateEmpresa } from "../domain/usecases/interfaces/handlerCreateEmpresa";
import {
  Empresa,
  InputEmpresa,
  OutputEmpresa,
} from "../domain/entities/Empresa";

export class HandlerCreateEmpresaFirebase implements HandlerCreateEmpresa {
  constructor(private firestore: Firestore) {}
  createEmpresa(inputEmpresa: InputEmpresa): Promise<Result<OutputEmpresa>> {
    const inputToFirebase: InputEmpresa = {
      nome: inputEmpresa.nome,
      telefone: inputEmpresa.telefone,
      sobre: inputEmpresa.sobre,
      email: inputEmpresa.email,
      gestores: inputEmpresa.gestores,
      interesses_inovacao: inputEmpresa.interesses_inovacao,
      setores_atuacao: inputEmpresa.setores_atuacao,
      created_in: inputEmpresa.created_in,
      habilitado: inputEmpresa.habilitado,
    };

    if (inputEmpresa.website) {
      inputToFirebase.website = inputEmpresa.website;
    }
    if (inputEmpresa.path_logo) {
      inputToFirebase.path_logo = inputEmpresa.path_logo;
    }
    if (inputEmpresa.linkedin) {
      inputToFirebase.linkedin = inputEmpresa.linkedin;
    }
    if (inputEmpresa.interesses_cientista_tecnologia_areas_aplicacao) {
      inputToFirebase.interesses_cientista_tecnologia_areas_aplicacao =
        inputEmpresa.interesses_cientista_tecnologia_areas_aplicacao;
    }
    if (inputEmpresa.interesses_cientista_tecnologia_mecanismos_solucao) {
      inputToFirebase.interesses_cientista_tecnologia_mecanismos_solucao =
        inputEmpresa.interesses_cientista_tecnologia_mecanismos_solucao;
    }
    if (inputEmpresa.interesses_cientista_tecnologia_area_conhecimento) {
      inputToFirebase.interesses_cientista_tecnologia_area_conhecimento =
        inputEmpresa.interesses_cientista_tecnologia_area_conhecimento;
    }
    if (inputEmpresa.interesses_cientista_grupo_pesquisa) {
      inputToFirebase.interesses_cientista_grupo_pesquisa =
        inputEmpresa.interesses_cientista_grupo_pesquisa;
    }
    if (inputEmpresa.interesse_startup_estagio) {
      inputToFirebase.interesse_startup_estagio =
        inputEmpresa.interesse_startup_estagio;
    }
    if (inputEmpresa.interesse_startup_localizacoes) {
      inputToFirebase.interesse_startup_localizacoes =
        inputEmpresa.interesse_startup_localizacoes;
    }
    if (inputEmpresa.interesse_startup_modelo_negocio) {
      inputToFirebase.interesse_startup_modelo_negocio =
        inputEmpresa.interesse_startup_modelo_negocio;
    }
    if (inputEmpresa.interesse_startup_segmento_atuacao) {
      inputToFirebase.interesse_startup_segmento_atuacao =
        inputEmpresa.interesse_startup_segmento_atuacao;
    }
    if (inputEmpresa.interesse_startup_mecanismo_solucao) {
      inputToFirebase.interesse_startup_mecanismo_solucao =
        inputEmpresa.interesse_startup_mecanismo_solucao;
    }

    const createEmpresaResult = Empresa.create(inputToFirebase);
    if (!createEmpresaResult.ok) return Promise.resolve(createEmpresaResult);

    return new Promise((resolve) => {
      addDoc(collection(this.firestore, "empresas"), inputToFirebase)
        .then((docEmpresa) =>
          resolve({
            ok: true,
            value: {
              ...inputToFirebase,
              id: docEmpresa.id,
            },
          })
        )
        .catch((error) =>
          resolve({
            ok: false,
            error,
          })
        );
    });
  }
}
