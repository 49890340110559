import { LocalizacaoOutput, isLocalizacaoValida } from "./localizacao";
import { MecanismoDeSolucaoTecnologica } from "./mecanismoDeSolucaoTecnologica";
import { SegmentoAtuacaoOutput } from "./segmentoAtuacao";

export class VentureCapitalValidator {
  public static isInvalidLocais(
    locais: LocalizacaoOutput[] | undefined
  ): Error | undefined {
    const someInvalid: boolean = (locais ?? [])?.some((local) => {
      return !isLocalizacaoValida(local);
    });

    if (someInvalid) {
      return new Error("Inválido");
    }
  }

  public static validateInteresseStartupSegmentoAtuacao(
    interesse_startup_segmento_atuacao: SegmentoAtuacaoOutput[]
  ): Error | undefined {
    if (interesse_startup_segmento_atuacao.length < 1) {
      return new Error(
        "Precisa especificar segmento de atuação da startup de interesse"
      );
    }
  }

  public static validateInteresseStartupMecanismoSolucao(
    interesse_startup_mecanismos: MecanismoDeSolucaoTecnologica[]
  ): Error | undefined {
    if (interesse_startup_mecanismos.length < 1) {
      return new Error(
        "Precisa especificar mecanismo de solução da startup de interesse"
      );
    }
  }
}
