import { createContext } from "react";

import { OutputStartup } from "../../domain/entities/Startup";
import { Result } from "../../typings";

export const PerfilStartupContext = createContext<{
  perfilStartupResult: TypePerfilStartupContext;
  refreshStartup?: () => void;
}>({
  perfilStartupResult: undefined,
  refreshStartup: undefined,
});

export type TypePerfilStartupContext = Result<OutputStartup> | undefined;
