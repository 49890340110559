import { ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { Subscription } from "rxjs";

import { UsuarioScyggz } from "../../domain/entities/usuarioScyggz";
import {
  ConexoesPendentesRecebidasContext,
  TypeConexoesPendentesRecebidasContext,
} from "./ConexoesPendentesRecebidasContext";
import { HandlersDatabaseContext } from "../HandlersProviders/HandlersDatabaseContext";

export function ConexoesPendentesRecebidasProvider({
  user,
  realtime = false,
  children,
}: {
  user: Pick<UsuarioScyggz, "auth_user_id">;
  realtime?: boolean;
  children: ReactNode;
}) {
  const [
    conexoesPendentesRecebidasResult,
    setConexoesPendentesRecebidasResult,
  ] = useState<TypeConexoesPendentesRecebidasContext>();

  const {
    handlerConexaoPendentesRecebidasGetRealtime,
    handlerConexaoPendentesRecebidasGet,
  } = useContext(HandlersDatabaseContext);

  const refreshConexoesPendentesRecebidas = useCallback(() => {
    let sub: Subscription | undefined;

    if (user.auth_user_id) {
      if (realtime) {
        if (handlerConexaoPendentesRecebidasGetRealtime) {
          sub = handlerConexaoPendentesRecebidasGetRealtime
            .getConexoesPendentesRecebidasRealtime(user)
            .subscribe((result) => setConexoesPendentesRecebidasResult(result));
        } else {
          console.warn("required handlerConexaoPendentesRecebidasGetRealtime");
        }
      } else {
        if (handlerConexaoPendentesRecebidasGet) {
          handlerConexaoPendentesRecebidasGet
            .getConexoesPendentesRecebidas(user)
            .then((result) => {
              setConexoesPendentesRecebidasResult(result);
            });
        } else {
          console.warn("required handlerConexaoPendentesRecebidasGet");
        }
      }
    }

    return () => {
      if (sub) sub.unsubscribe();
    };
  }, [
    handlerConexaoPendentesRecebidasGet,
    handlerConexaoPendentesRecebidasGetRealtime,
    realtime,
    user,
  ]);

  useEffect(() => {
    refreshConexoesPendentesRecebidas();
  }, [refreshConexoesPendentesRecebidas]);

  return (
    <ConexoesPendentesRecebidasContext.Provider
      value={{
        conexoesPendentesRecebidasResult,
        refreshConexoesPendentesRecebidas,
      }}
      children={children}
    />
  );
}
