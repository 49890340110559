import bgCard from "../../../../../assets/images/logoCardUser.jpeg";
import { OutputAvatarEntity } from "../../../../../domain/entities/avatar";
import { CidadeOutput } from "../../../../../domain/entities/cidade";
import { ImgFromPath } from "../../../../generalComponents/ImgFromPath";
import Avatar from "../../../../../assets/images/avatarLight.jpeg";

type CardStartupProps = {
  src?: OutputAvatarEntity;
  nome: string;
  cidade?: CidadeOutput;
  estagio: string;
  className?: string;
};

export const CardStartup = ({
  src,
  nome,
  cidade,
  estagio,
  className = "",
}: CardStartupProps) => {
  return (
    <div
      style={{
        minHeight: "170px",
        border: "1px solid #525200",
        borderRadius: "20px",
      }}
      className={className}
    >
      <div
        style={{
          height: "45px",
          width: "100%",
          backgroundImage: `url(${bgCard})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          borderTopRightRadius: "19px",
          borderTopLeftRadius: "19px",
        }}
      />

      <div
        className="d-flex flex-column px-2"
        style={{ position: "relative", top: "-22px" }}
      >
        <div className="p-0 ratio ratio-1x1">
          {src?.path ? (
            <ImgFromPath
              path={src?.path}
              className="rounded-circle border-white object-fit-cover"
              alt={nome}
            />
          ) : (
            <img
              className="rounded-circle border-white object-fit-cover"
              src={Avatar}
            />
          )}
        </div>

        <h4 style={{ fontSize: "16px", fontWeight: "700" }}>{nome}</h4>
        {/* <h4 style={{ fontSize: "12px", fontWeight: "700" }}>{estagio}</h4> */}
        <span style={{ fontSize: "12px" }}>{estagio}</span>

        <div
          style={{ fontSize: "10px", paddingBottom: "5px" }}
        >{`${cidade?.cidade}, ${cidade?.estado} - ${cidade?.pais}`}</div>
      </div>
    </div>
  );
};
