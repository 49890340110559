import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ConteudoSobreNit } from "./ConteudoSobreNit";
import { HandlerCacheNit } from "../../../../../domain/usecases/interfaces/HandlerCacheNit";
import { HandlerCreateNit } from "../../../../../domain/usecases/interfaces/handlerCreateNit";
import { HandlerGetUnidade } from "../../../../../domain/usecases/interfaces/handlerGetUnidade";
import { CurrentUserContext } from "../../../../../globalStore/CurrentUserContext";
import { ContainerResponsive } from "../../../../generalComponents/ContainerResponsive";

export const PainelCadastroSobreNit = ({
  handlerCacheNit,
  handlerGetUnidade,
  handlerCreateNit,
}: {
  handlerCacheNit: HandlerCacheNit;
  handlerGetUnidade: HandlerGetUnidade;
  handlerCreateNit: HandlerCreateNit;
}) => {
  const navigate = useNavigate();
  const { currentUserScyggz } = useContext(CurrentUserContext);

  const paginacaoNit = {
    start: 5,
    end: 6,
  };

  useEffect(() => {
    if (currentUserScyggz.status == "nao_encontrado") {
      navigate("/cadastro/nit/1");
    }
  }, [currentUserScyggz, navigate]);

  const conteudo = (
    <ConteudoSobreNit
      handlerCreateNit={handlerCreateNit}
      handlerGetUnidade={handlerGetUnidade}
      onNext={() => {
        navigate("/cadastro/nit/6");
      }}
      handlerCacheNit={handlerCacheNit}
      onBack={() => {
        navigate("/cadastro/nit/4");
      }}
    />
  );

  return (
    <section data-testid="painel-cadastro-foto-nit" style={{ height: "100%" }}>
      <ContainerResponsive
        backUrlMobile="/cadastro/nit/4"
        paginacao={paginacaoNit}
        textoMarcaDagua="NIT"
        showLogo={true}
      >
        {conteudo}
      </ContainerResponsive>
    </section>
  );
};
