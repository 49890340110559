import { Result } from "../../typings";

export class UrlLinkedin {
  public auth_id: string;
  public urlLinkedin: string;
  private constructor(input: InputUrlLinkedin) {
    this.auth_id = input.auth_id;
    this.urlLinkedin = input.urlLinkedin;
  }

  static isValidUrlLinkedin(inputUrl: string) {
    try {
      const url = new URL(inputUrl);

      if (!url.host.includes("linkedin.com") || url.pathname.length < 2) {
        return false;
      }

      return true;
    } catch (err) {
      return false;
    }
  }

  public static create({
    auth_id,
    urlLinkedin,
  }: InputUrlLinkedin): Result<UrlLinkedin> {
    const isAuthValid: boolean = auth_id.length > 0;
    if (!isAuthValid) {
      return {
        ok: false,
        error: new Error("Auth id do avatar inválido"),
      };
    }

    if (!UrlLinkedin.isValidUrlLinkedin(urlLinkedin)) {
      return {
        ok: false,
        error: new Error("URL Linkedin inválida"),
      };
    }

    return {
      ok: true,
      value: new UrlLinkedin({ auth_id, urlLinkedin }),
    };
  }
}

export type InputUrlLinkedin = {
  auth_id: string;
  urlLinkedin: string;
};

export type OutputUrlLinkedin = InputUrlLinkedin;
