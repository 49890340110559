import { Firestore, getDocs, collection } from "@firebase/firestore";

import { Result } from "../../typings";
import {
  OutputUsuarioScyggzEntity,
  TipoPerfil,
  TIPOS_PERFIL,
} from "../../domain/entities/usuarioScyggz";
import {
  HandlerGetPessoasRelevantes,
  TypePessoaRelevante,
} from "../../domain/usecases/interfaces/HandlerGetPessoasRelevantes";

export class HandlerGetPessoasRelevantesFirebase
  implements HandlerGetPessoasRelevantes
{
  constructor(private firestore: Firestore) {}

  get({
    currentUser,
    showedUser,
  }: {
    currentUser: string;
    showedUser: string;
  }): Promise<Result<TypePessoaRelevante[]>> {
    return new Promise((resolve) => {
      getDocs(collection(this.firestore, "usuarios"))
        .then((queryUserSnapshot) => {
          const pessoasRelevantes: TypePessoaRelevante[] =
            queryUserSnapshot.docs
              .map((docUserSnapshot) => {
                const docUserSnapshotData =
                  docUserSnapshot.data() as OutputUsuarioScyggzEntity;

                docUserSnapshotData.auth_user_id = docUserSnapshot.id;

                docUserSnapshotData.perfis = Object.keys(
                  docUserSnapshotData.perfis ?? {}
                )
                  .filter((perfil) =>
                    TIPOS_PERFIL.includes(perfil as TipoPerfil)
                  )
                  .reduce<{ [id_perfil in TipoPerfil]?: boolean }>(
                    (prev, current) => {
                      if (
                        docUserSnapshotData.perfis &&
                        docUserSnapshotData.perfis[current as TipoPerfil]
                      ) {
                        prev[current as TipoPerfil] =
                          docUserSnapshotData.perfis &&
                          docUserSnapshotData.perfis[current as TipoPerfil];
                      }

                      return prev;
                    },
                    {}
                  );

                return docUserSnapshotData;
              })
              .filter(
                (usuario) =>
                  !usuario.auth_id_conexoes?.includes(currentUser) &&
                  usuario.auth_user_id != currentUser &&
                  usuario.auth_user_id != showedUser
              )
              .map((usuario) => {
                const pessoaRelevante: TypePessoaRelevante = {
                  cidade: usuario.cidade,
                  headline: usuario.headline,
                  image_path: usuario.avatar_path?.path,
                  nome: usuario.nome,
                  perfis: Object.keys(usuario.perfis ?? []) as TipoPerfil[],
                  url_link: "/user/" + usuario.auth_user_id,
                };

                return pessoaRelevante;
              })
              .filter((pessoaRelevante) => pessoaRelevante.perfis.length > 0);

          for (let i = pessoasRelevantes.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [pessoasRelevantes[i], pessoasRelevantes[j]] = [
              pessoasRelevantes[j],
              pessoasRelevantes[i],
            ];
          }

          resolve({
            ok: true,
            value: pessoasRelevantes.splice(0, 5),
          });
        })
        .catch((error) => {
          console.warn("Erro HandlerPessoasRelevantesFirebase", error);

          resolve({
            ok: false,
            error,
          });
        });
    });
  }
}
