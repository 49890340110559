import { ReactNode, useContext, useEffect, useState } from "react";

import { OutputVentureCapital } from "../../domain/entities/VentureCapital";
import { HandlerGetVentureCapitalFirebase } from "../../gateway/handlerGetVentureCapitalFirebase";
import { FirebaseContext } from "../FirebaseContext";
import {
  PerfilVentureCapitalContext,
  TypePerfilVentureCapitalContext,
} from "./PerfilVentureCapitalContext";

export function PerfilVentureCapitalFirebaseProvider({
  ventureCapital,
  children,
}: {
  ventureCapital: Pick<OutputVentureCapital, "id">;
  children: ReactNode;
}) {
  const [perfilVentureCapitalResult, setPerfilVentureCapitalResultResult] =
    useState<TypePerfilVentureCapitalContext>();

  const { firestore } = useContext(FirebaseContext);

  useEffect(() => {
    new HandlerGetVentureCapitalFirebase(firestore)
      .getVentureCapital(ventureCapital.id)
      .then((result) => {
        setPerfilVentureCapitalResultResult(
          result.ok
            ? {
                ok: true,
                value: result.value,
              }
            : result
        );
      });
  }, [ventureCapital, firestore]);

  return (
    <PerfilVentureCapitalContext.Provider
      value={{
        perfilVentureCapitalResult,
      }}
      children={children}
    />
  );
}
