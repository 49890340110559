import { Firestore, addDoc, collection } from "@firebase/firestore";

import { Result } from "../typings";
import { InputStartup, OutputStartup } from "../domain/entities/Startup";
import { HandlerCreateStartup } from "../domain/usecases/interfaces/handlerCreateStartup";

export class HandlerCreateStartupFirebase implements HandlerCreateStartup {
  constructor(private firestore: Firestore) {}

  createStartup(inputStartup: InputStartup): Promise<Result<OutputStartup>> {
    const inputToFirebase: InputStartup = {
      nome: inputStartup.nome,
      cidade: inputStartup.cidade,
      ano_fundacao: inputStartup.ano_fundacao,
      email: inputStartup.email,
      estagio: inputStartup.estagio,
      modelo_negocio: inputStartup.modelo_negocio,
      segmento_atuacao: inputStartup.segmento_atuacao,
      gestores: inputStartup.gestores,
      qtde_funcionarios: inputStartup.qtde_funcionarios,
      sobre: inputStartup.sobre,
      created_in: new Date(),
      habilitado: false,
      recusado: false,
    };

    if (inputStartup.faturamento_medio_mensal) {
      inputToFirebase.faturamento_medio_mensal =
        inputStartup.faturamento_medio_mensal;
    }
    if (inputStartup.path_logo) {
      inputToFirebase.path_logo = inputStartup.path_logo;
    }
    if (inputStartup.mecanismo_solucao) {
      inputToFirebase.mecanismo_solucao = inputStartup.mecanismo_solucao;
    }
    if (inputStartup.mercado_potencial) {
      inputToFirebase.mercado_potencial = inputStartup.mercado_potencial;
    }
    if (inputStartup.path_pitch_pdf) {
      inputToFirebase.path_pitch_pdf = inputStartup.path_pitch_pdf;
    }
    if (inputStartup.path_pitch_planilha) {
      inputToFirebase.path_pitch_planilha = inputStartup.path_pitch_planilha;
    }
    if (inputStartup.url_video) {
      inputToFirebase.url_video = inputStartup.url_video;
    }
    if (inputStartup.telefone) {
      inputToFirebase.telefone = inputStartup.telefone;
    }
    if (inputStartup.last_funding_round) {
      inputToFirebase.last_funding_round = inputStartup.last_funding_round;
    }
    if (inputStartup.website) {
      inputToFirebase.website = inputStartup.website;
    }
    if (inputStartup.linkedin) {
      inputToFirebase.linkedin = inputStartup.linkedin;
    }
    if (inputStartup.hub) {
      inputToFirebase.hub = inputStartup.hub;
    }
    if (inputStartup.premiacoes) {
      inputToFirebase.premiacoes = inputStartup.premiacoes;
    }
    if (inputStartup.investidores) {
      inputToFirebase.investidores = inputStartup.investidores;
    }

    return new Promise((resolve) => {
      addDoc(collection(this.firestore, "startup"), inputToFirebase)
        .then((docStartup) =>
          resolve({
            ok: true,
            value: {
              ...inputToFirebase,
              id: docStartup.id,
            },
          })
        )
        .catch((error) =>
          resolve({
            ok: false,
            error,
          })
        );
    });
  }
}
