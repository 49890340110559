import { ProgressBar } from "react-bootstrap";
import { OutputStartup } from "../../../../../domain/entities/Startup";
import { CardStartup } from "./CardStartup";

type InfoCardStartupProps = {
  startups: OutputStartup[];
  className?: string;
  loading: boolean;
};

export const InfoCardStartup = ({
  startups,
  className = "",
  loading,
}: InfoCardStartupProps) => {
  return (
    <section className={className}>
      {loading ? (
        <ProgressBar animated variant="info" now={100} className="w-100" />
      ) : (
        <div className="d-flex ms-4 gap-3 flex-wrap align-items-center">
          {startups && startups.length > 0 ? (
            startups.map((startup) => (
              <div key={startup.id} className="text-center">
                <a href={"/startup/" + startup.id} className="text-reset">
                  <CardStartup
                    src={
                      startup.path_logo
                        ? {
                            path: startup.path_logo,
                          }
                        : undefined
                    }
                    nome={startup.nome}
                    cidade={startup.cidade}
                    estagio={startup.estagio}
                  />
                </a>
              </div>
            ))
          ) : (
            <div className="w-100">
              <h1 className="text-center mb-0 ">
                Não há organizações cadastradas para o usuário
              </h1>
            </div>
          )}
        </div>
      )}
    </section>
  );
};
