import { createContext } from "react";

import { HandlerGetModeloNegocio } from "../../domain/usecases/interfaces/handlerGetModeloNegocio";
import { HandlerGetSegmentoAtuacao } from "../../domain/usecases/interfaces/handlerGetSegmentoAtuacao";
import { HandlerGetMecanismoSolucao } from "../../domain/usecases/interfaces/handlerGetMecanismoSolucao";
import { HandlerGetAreaConhecimento } from "../../domain/usecases/interfaces/handlerGetAreaConhecimento";
import { HandlerGetUnidade } from "../../domain/usecases/interfaces/handlerGetUnidade";
import { HandlerUpdateUsuarioScyggz } from "../../domain/usecases/interfaces/handlerUpdateUsuarioScyggz";
import { HandlerGetAreaAplicacaoTecnologia } from "../../domain/usecases/interfaces/handlerGetAreaAplicacaoTecnologia";
import { HandlerCreateCientista } from "../../domain/usecases/interfaces/handlerCreateCientista";
import { HandlerGetTipoGrupoPesquisa } from "../../domain/usecases/interfaces/handlerGetTipoGrupoPesquisa";
import { HandlerCreateMentor } from "../../domain/usecases/interfaces/handlerCreateMentor";
import { HandlerGetAreaEspecializacao } from "../../domain/usecases/interfaces/handlerGetAreaEspecializacao";
import { HandlerAuth } from "../../domain/usecases/interfaces/HandlerAuth";
import { HandlerGetAssociacaoAnjo } from "../../domain/usecases/interfaces/handlerGetAssociacaoAnjo";
import { HandlerCreateInvestidor } from "../../domain/usecases/interfaces/HandlerCreateInvestidor";
import { HandlerGetUsuario } from "../../domain/usecases/interfaces/HandlerGetUser";
import { HandlerGetUsersWaitingAprovement } from "../../domain/usecases/interfaces/HandlerGetUsersWaitingAprovement";
import { HandlerGetStartupWaitingAprovement } from "../../domain/usecases/interfaces/HandlerGetStartupWaitingAprovement";
import { HandlerGetPermissionsOfUser } from "../../domain/usecases/interfaces/HandlerGetPermissionsOfUser";
import { HandlerSetUsersEnabled } from "../../domain/usecases/interfaces/HandlerSetUsersEnabled";
import { HandlerSetStartupEnabled } from "../../domain/usecases/interfaces/HandlerSetStartupEnabled";
import { HandlerContatoEmailSend } from "../../domain/usecases/interfaces/HandlerContatoEmailSend";
import { HandlerConviteProgramaConexaoCreate } from "../../domain/usecases/interfaces/HandlerConviteProgramaConexaoCreate";
import { HandlerConviteProgramaConexaoGetByUserAlvo } from "../../domain/usecases/interfaces/HandlerConviteProgramaConexaoGetByUserAlvo";
import { HandlerConviteProgramaConexaoAccept } from "../../domain/usecases/interfaces/HandlerConviteProgramaConexaoAccept";
import { HandlerConviteProgramaConexaoReject } from "../../domain/usecases/interfaces/HandlerConviteProgramaConexaoReject";
import { HandlerProgramaConexaoCreate } from "../../domain/usecases/interfaces/HandlerProgramaConexaoCreate";
import { HandlerProgramaConexaoGetAll } from "../../domain/usecases/interfaces/HandlerProgramaConexaoGetAll";
import { HandlerProgramaConexaoGetAllByGestor } from "../../domain/usecases/interfaces/HandlerProgramaConexaoGetAllByGestor";
import { HandlerProgramaConexaoUpdate } from "../../domain/usecases/interfaces/HandlerProgramaConexaoUpdate";
import { HandlerProgramaConexaoDelete } from "../../domain/usecases/interfaces/HandlerProgramaConexaoDelete";
import { HandlerConviteProgramaConexaoGetAllAccepted } from "../../domain/usecases/interfaces/HandlerConviteProgramaConexaoGetAllAccepted";
import { HandlerConviteProgramaConexaoGetAll } from "../../domain/usecases/interfaces/HandlerConviteProgramaConexaoGetAll";
import { HandlerConviteProgramaConexaoDelete } from "../../domain/usecases/interfaces/HandlerConviteProgramaConexaoDelete";
import { HandlerProgramaConexaoGetByIds } from "../../domain/usecases/interfaces/HandlerProgramaConexaoGetByIds";
import { HandlerPerfisGetEnabled } from "../../domain/usecases/interfaces/HandlerPerfisGetEnabled";
import { HandlerGetEmpresa } from "../../domain/usecases/interfaces/handlerGetEmpresa";
import { HandlerGetStartup } from "../../domain/usecases/interfaces/handlerGetStartup";
import { HandlerGetVentureCapital } from "../../domain/usecases/interfaces/handlerGetVentureCapital";
import { HandlerGetNit } from "../../domain/usecases/interfaces/handlerGetNit";
import { HandlerGetHub } from "../../domain/usecases/interfaces/handlerGetHub";
import { HandlerComentarioProgramaConexaoGetAllMural } from "../../domain/usecases/interfaces/HandlerComentarioProgramaConexaoGetAllMural";
import { HandlerComentarioProgramaConexaoCreateInMural } from "../../domain/usecases/interfaces/HandlerComentarioProgramaConexaoCreateInMural";
import { HandlerComentarioProgramaConexaoCreateResponse } from "../../domain/usecases/interfaces/HandlerComentarioProgramaConexaoCreateResponse";
import { HandlerComentarioProgramaConexaoDelete } from "../../domain/usecases/interfaces/HandlerComentarioProgramaConexaoDelete";
import { HandlerComentarioProgramaConexaoUpdate } from "../../domain/usecases/interfaces/HandlerComentarioProgramaConexaoUpdate";
import { HandlerConexaoCreate } from "../../domain/usecases/interfaces/HandlerConexaoCreate";
import { HandlerConexaoGetAllOfUserRealtime } from "../../domain/usecases/interfaces/HandlerConexaoGetAllOfUserRealtime";
import { HandlerGetMentor } from "../../domain/usecases/interfaces/getPerfil/handlerGetMentor";
import { HandlerGetCientista } from "../../domain/usecases/interfaces/getPerfil/handlerGetCientista";
import { HandlerGetInvestidorAnjo } from "../../domain/usecases/interfaces/getPerfil/handlerGetInvestidorAnjo";
import { HandlerComentarioProgramaConexaoGetResponses } from "../../domain/usecases/interfaces/HandlerComentarioProgramaConexaoGetResponses";
import { HandlerComentarioProgramaConexaoAddLike } from "../../domain/usecases/interfaces/HandlerComentarioProgramaConexaoAddLike";
import { HandlerComentarioProgramaConexaoRemoveLike } from "../../domain/usecases/interfaces/HandlerComentarioProgramaConexaoRemoveLike";
import { HandlerConexaoGetAllOfProgramaConexaoRealtime } from "../../domain/usecases/interfaces/HandlerConexaoGetAllOfProgramaConexaoRealtime";
import { HandlerAceiteSet } from "../../domain/usecases/interfaces/HandlerAceiteSet";
import { HandlerAceiteGet } from "../../domain/usecases/interfaces/HandlerAceiteGet";
import { HandlerAceiteClear } from "../../domain/usecases/interfaces/HandlerAceiteClear";
import { HandlerCreateAuthWithName } from "../../domain/usecases/interfaces/handlerCreateAuthWithName";
import { HandlerCreateUsuarioScyggz } from "../../domain/usecases/interfaces/handlerCreateUsuarioScyggz";
import { HandlerSaveUrlLinkedin } from "../../domain/usecases/interfaces/handlerSaveUrlLinkedin";
import { HandlerSaveTelefone } from "../../domain/usecases/interfaces/handlerSaveTelefone";
import { HandlerConexaoAprovadasGet } from "../../domain/usecases/interfaces/Conexoes/HandlerConexaoAprovadasGet";
import { HandlerConexaoAprovadasCount } from "../../domain/usecases/interfaces/Conexoes/HandlerConexaoAprovadasCount";
import { HandlerConexaoPendentesEnviadasCount } from "../../domain/usecases/interfaces/Conexoes/HandlerConexaoPendentesEnviadasCount";
import { HandlerConexaoPendentesEnviadasGet } from "../../domain/usecases/interfaces/Conexoes/HandlerConexaoPendentesEnviadasGet";
import { HandlerConexaoPendentesRecebidasCount } from "../../domain/usecases/interfaces/Conexoes/HandlerConexaoPendentesRecebidasCount";
import { HandlerConexaoPendentesRecebidasGet } from "../../domain/usecases/interfaces/Conexoes/HandlerConexaoPendentesRecebidasGet";
import { HandlerConexaoEntreUsuariosGet } from "../../domain/usecases/interfaces/Conexoes/HandlerConexaoEntreUsuariosGet";
import { HandlerConexaoPendentesRecebidasGetRealtime } from "../../domain/usecases/interfaces/Conexoes/HandlerConexaoPendentesRecebidasGetRealtime";
import { HandlerConexaoStatusCountRealtime } from "../../domain/usecases/interfaces/Conexoes/HandlerConexaoStatusCountRealtime";
import { HandlerUpdateStartup } from "../../domain/usecases/interfaces/handlerUpdateStartup";
import { HandlerConviteStartupEmployeeGetAll } from "../../domain/usecases/interfaces/HandlerConviteStartupEmployeeGetAll";
import { HandlerConviteStartupEmployeeCreate } from "../../domain/usecases/interfaces/HandlerConviteStartupEmployeeCreate";
import { HandlerConviteStartupEmployeeGetByUserAlvo } from "../../domain/usecases/interfaces/HandlerConviteStartupEmployeeGetByUserAlvo";
import { HandlerConviteStartupEmployeeAccept } from "../../domain/usecases/interfaces/HandlerConviteStartupEmployeeAccept";
import { HandlerConviteStartupEmployeeReject } from "../../domain/usecases/interfaces/HandlerConviteStartupEmployeeReject";
import { HandlerConviteStartupEmployeeGetAllAccepted } from "../../domain/usecases/interfaces/HandlerConviteStartupEmployeeGetAllAccepted";
import { HandlerConviteStartupEmployeeDelete } from "../../domain/usecases/interfaces/HandlerConviteStartupEmployeeDelete";
import { HandlerConviteStartupEmployeeGetAllNotRejected } from "../../domain/usecases/interfaces/HandlerConviteStartupEmployeeGetAllNotRejected";

export const HandlersDatabaseContext =
  createContext<TypeHandlersDatabaseContext>({});

export type TypeHandlersDatabaseContext = {
  handlerCreateCientista?: HandlerCreateCientista;
  handlerCreateMentor?: HandlerCreateMentor;
  handlerCreateInvestidor?: HandlerCreateInvestidor;
  handlerGetModeloNegocio?: HandlerGetModeloNegocio;
  handlerGetSegmentoAtuacao?: HandlerGetSegmentoAtuacao;
  handlerGetMecanismoSolucao?: HandlerGetMecanismoSolucao;
  handlerGetAreaConhecimento?: HandlerGetAreaConhecimento;
  handlerGetUnidade?: HandlerGetUnidade;
  handlerGetUsuario?: HandlerGetUsuario;
  handlerGetUsersWaitingAprovement?: HandlerGetUsersWaitingAprovement;
  handlerGetStartupWaitingAprovement?: HandlerGetStartupWaitingAprovement;
  handlerUpdateUsuarioScyggz?: HandlerUpdateUsuarioScyggz;
  handlerGetAreaAplicacaoTecnologia?: HandlerGetAreaAplicacaoTecnologia;
  handlerGetAssociacaoAnjo?: HandlerGetAssociacaoAnjo;
  handlerGetTipoGrupoPesquisa?: HandlerGetTipoGrupoPesquisa;
  handlerGetAreaEspecializacao?: HandlerGetAreaEspecializacao;
  handlerAuth?: HandlerAuth;
  handlerGetPermissionsOfUser?: HandlerGetPermissionsOfUser;
  handlerSetUsersEnabled?: HandlerSetUsersEnabled;
  handlerSetStartupEnabled?: HandlerSetStartupEnabled;
  handlerContatoEmailSend?: HandlerContatoEmailSend;
  handlerConviteProgramaConexaoCreate?: HandlerConviteProgramaConexaoCreate;
  handlerConviteProgramaConexaoGetByAlvo?: HandlerConviteProgramaConexaoGetByUserAlvo;
  handlerConviteProgramaConexaoAccept?: HandlerConviteProgramaConexaoAccept;
  handlerConviteProgramaConexaoReject?: HandlerConviteProgramaConexaoReject;
  handlerConviteProgramaConexaoGetAllAccepted?: HandlerConviteProgramaConexaoGetAllAccepted;
  handlerConviteProgramaConexaoGetAll?: HandlerConviteProgramaConexaoGetAll;
  handlerConviteProgramaConexaoDelete?: HandlerConviteProgramaConexaoDelete;
  handlerProgramaConexaoCreate?: HandlerProgramaConexaoCreate;
  handlerProgramaConexaoGetAll?: HandlerProgramaConexaoGetAll;
  handlerProgramaConexaoGetAllByGestor?: HandlerProgramaConexaoGetAllByGestor;
  handlerProgramaConexaoUpdate?: HandlerProgramaConexaoUpdate;
  handlerProgramaConexaoDelete?: HandlerProgramaConexaoDelete;
  handlerProgramaConexaoGetByIds?: HandlerProgramaConexaoGetByIds;
  handlerConviteStartupEmployeeCreate?: HandlerConviteStartupEmployeeCreate;
  handlerConviteStartupEmployeeGetByUserAlvo?: HandlerConviteStartupEmployeeGetByUserAlvo;
  handlerConviteStartupEmployeeAccept?: HandlerConviteStartupEmployeeAccept;
  handlerConviteStartupEmployeeReject?: HandlerConviteStartupEmployeeReject;
  handlerConviteStartupEmployeeGetAllAccepted?: HandlerConviteStartupEmployeeGetAllAccepted;
  handlerConviteStartupEmployeeGetAll?: HandlerConviteStartupEmployeeGetAll;
  handlerConviteStartupEmployeeGetAllNotRejected?: HandlerConviteStartupEmployeeGetAllNotRejected;
  handlerConviteStartupEmployeeDelete?: HandlerConviteStartupEmployeeDelete;
  handlerPerfisGetEnabled?: HandlerPerfisGetEnabled;
  handlerGetEmpresa?: HandlerGetEmpresa;
  handlerUpdateStartup?: HandlerUpdateStartup;
  handlerGetStartup?: HandlerGetStartup;
  handlerGetVentureCapital?: HandlerGetVentureCapital;
  handlerGetNit?: HandlerGetNit;
  handlerGetHub?: HandlerGetHub;
  handlerComentarioProgramaConexaoGetAllMural?: HandlerComentarioProgramaConexaoGetAllMural;
  handlerComentarioProgramaConexaoGetResponses?: HandlerComentarioProgramaConexaoGetResponses;
  handlerComentarioProgramaConexaoCreateInMural?: HandlerComentarioProgramaConexaoCreateInMural;
  handlerComentarioProgramaConexaoCreateResponse?: HandlerComentarioProgramaConexaoCreateResponse;
  handlerComentarioProgramaConexaoUpdate?: HandlerComentarioProgramaConexaoUpdate;
  handlerComentarioProgramaConexaoAddLike?: HandlerComentarioProgramaConexaoAddLike;
  handlerComentarioProgramaConexaoRemoveLike?: HandlerComentarioProgramaConexaoRemoveLike;
  handlerComentarioProgramaConexaoDelete?: HandlerComentarioProgramaConexaoDelete;
  handlerConexaoCreate?: HandlerConexaoCreate;
  handlerConexaoGetAllOfUserRealtime?: HandlerConexaoGetAllOfUserRealtime;
  handlerGetMentor?: HandlerGetMentor;
  handlerGetCientista?: HandlerGetCientista;
  handlerGetInvestidorAnjo?: HandlerGetInvestidorAnjo;
  handlerConexaoGetAllOfProgramaConexaoRealtime?: HandlerConexaoGetAllOfProgramaConexaoRealtime;
  handlerAceiteSet?: HandlerAceiteSet;
  handlerAceiteGet?: HandlerAceiteGet;
  handlerAceiteClear?: HandlerAceiteClear;
  handlerCreateAuthWithName?: HandlerCreateAuthWithName;
  handlerCreateUsuarioScyggz?: HandlerCreateUsuarioScyggz;
  handlerSaveUrlLinkedin?: HandlerSaveUrlLinkedin;
  handlerSaveTelefone?: HandlerSaveTelefone;
  handlerConexaoAprovadasGet?: HandlerConexaoAprovadasGet;
  handlerConexaoAprovadasCount?: HandlerConexaoAprovadasCount;
  handlerConexaoPendentesEnviadasCount?: HandlerConexaoPendentesEnviadasCount;
  handlerConexaoPendentesEnviadasGet?: HandlerConexaoPendentesEnviadasGet;
  handlerConexaoPendentesRecebidasCount?: HandlerConexaoPendentesRecebidasCount;
  handlerConexaoPendentesRecebidasGet?: HandlerConexaoPendentesRecebidasGet;
  handlerConexaoEntreUsuariosGet?: HandlerConexaoEntreUsuariosGet;
  handlerConexaoPendentesRecebidasGetRealtime?: HandlerConexaoPendentesRecebidasGetRealtime;
  handlerConexaoStatusCountRealtime?: HandlerConexaoStatusCountRealtime;
};
