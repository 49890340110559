import { Button, Modal } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";

import { Cientista } from "../../../../domain/entities/Cientista";
import { InputPremiacoes } from "../../../generalComponents/InputPremiacoes/InputPremiacoes";
import { InputSobre } from "../../../generalComponents/InputSobre";
import { InputUnidadesInstituicao } from "../../../generalComponents/InputUnidadesInstituicao";
import { InputAreasConhecimento } from "../../../generalComponents/InputAreasConhecimento";
import { InputGruposDePesquisa } from "../../../generalComponents/InputGruposDePesquisa/InputGruposDePesquisa";
import { InputTecnologias } from "../../../generalComponents/InputTecnologias/InputTecnologias";
import { HandlersContext } from "../../../../globalStore/HandlersProviders/HandlersContext";
import { TypeHandlerCreateCientista } from "../../../../domain/usecases/interfaces/handlerCreateCientista";
import { UsuarioScyggz } from "../../../../domain/entities/usuarioScyggz";

export function ModalAddCientista({
  user,
  show,
  onShowChange,
  onCreate,
}: {
  user: Pick<UsuarioScyggz, "nome" | "auth_user_id">;
  show: boolean;
  onShowChange: (show: boolean) => void;
  onCreate: () => void;
}) {
  const { handlerCreateCientista } = useContext(HandlersContext);

  const [cientistaToCreate, setCientistaToCreate] =
    useState<TypeHandlerCreateCientista>({
      areas_de_conhecimento: [],
      created_in: new Date(),
      nome: "",
      sobre: {
        conteudo: "",
      },
      unidades: [],
      user_id: "",
    });
  const [error, setError] = useState<
    Error | Partial<Record<keyof Cientista, Error>>
  >();
  const [carregando, setCarregando] = useState<boolean>(false);
  const [isEditingGrupoPesquisa, setIsEditingGrupoPesquisa] =
    useState<boolean>(false);
  const [isEditingPremiacoes, setIsEditingPremiacoes] =
    useState<boolean>(false);
  const [isEditingTecnologia, setIsEditingTecnologia] =
    useState<boolean>(false);

  useEffect(() => {
    setCientistaToCreate((cientistaToCreate) => ({
      ...cientistaToCreate,
      user_id: user.auth_user_id,
      nome: user.nome,
    }));
  }, [user]);

  useEffect(() => {
    const cientistaValidateResult = Cientista.validateCreate(cientistaToCreate);
    if (cientistaValidateResult.ok) {
      setError(undefined);
    } else {
      setError(cientistaValidateResult.error);
    }
  }, [cientistaToCreate]);

  return (
    <Modal
      show={show}
      onHide={() => onShowChange(false)}
      centered
      scrollable={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>Adicionar perfil cientista</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputSobre
          label="Sobre"
          value={cientistaToCreate?.sobre?.conteudo ?? ""}
          onChange={(sobre) => {
            setCientistaToCreate({
              ...cientistaToCreate,
              sobre: {
                conteudo: sobre,
              },
            });
          }}
        />

        <InputUnidadesInstituicao
          label="Unidades da Instituição (ICT)*"
          value={cientistaToCreate.unidades ?? []}
          onChange={(unidades) => {
            setCientistaToCreate({
              ...cientistaToCreate,
              unidades,
            });
          }}
          validate={Cientista.validateUnidades}
          className="mb-3"
        />

        <InputAreasConhecimento
          label="Área(s) de Conhecimento*"
          value={cientistaToCreate.areas_de_conhecimento ?? []}
          onChange={(areas_de_conhecimento) => {
            setCientistaToCreate({
              ...cientistaToCreate,
              areas_de_conhecimento,
            });
          }}
          validate={Cientista.validateAreasConhecimento}
          className="mb-3"
        />

        <InputGruposDePesquisa
          label="Grupos de Pesquisa"
          value={cientistaToCreate.grupos_de_pesquisa ?? []}
          onListChange={(grupos_de_pesquisa) => {
            setCientistaToCreate({ ...cientistaToCreate, grupos_de_pesquisa });
          }}
          showTip={false}
          className="mb-3"
          isEditing={setIsEditingGrupoPesquisa}
        />

        <InputPremiacoes
          label="Premiações"
          value={cientistaToCreate.premiacoes ?? []}
          onChange={(premiacoes) => {
            setCientistaToCreate({ ...cientistaToCreate, premiacoes });
          }}
          showTip={false}
          className="mb-3"
          isEditing={setIsEditingPremiacoes}
        />

        <InputTecnologias
          label="Tecnologias"
          tecnologias={cientistaToCreate.tecnologias ?? []}
          onListChange={(tecnologias) => {
            setCientistaToCreate({ ...cientistaToCreate, tecnologias });
          }}
          showTip={false}
          isEditing={setIsEditingTecnologia}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="success"
          disabled={
            !!error ||
            carregando ||
            isEditingGrupoPesquisa ||
            isEditingPremiacoes ||
            isEditingTecnologia
          }
          onClick={async () => {
            setCarregando(true);

            if (handlerCreateCientista) {
              const handlerUpdateResult =
                await handlerCreateCientista.createCientista(cientistaToCreate);

              setCarregando(false);

              if (!handlerUpdateResult.ok) {
                console.warn(handlerUpdateResult.error);

                setError(handlerUpdateResult.error);

                return;
              } else {
                onCreate();
              }
            }

            onShowChange(false);
          }}
        >
          Salvar cientista
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
