import {
  Firestore,
  collection,
  where,
  query,
  Timestamp,
  doc,
  onSnapshot,
} from "@firebase/firestore";
import { Observable } from "rxjs";

import { Result } from "../typings";
import { OutputVentureCapital } from "../domain/entities/VentureCapital";
import { HandlerGetVentureCapitalRealtime } from "../domain/usecases/interfaces/HandlerGetVentureCapitalRealtime";

export class HandlerGetVentureCapitalRealtimeFirebase
  implements HandlerGetVentureCapitalRealtime
{
  constructor(private firestore: Firestore) {}

  getAllEnabledVentureCapitals(): Observable<Result<OutputVentureCapital[]>> {
    return new Observable((sub) => {
      const queryEnabledVentureCapitals = query(
        collection(this.firestore, "venture_capital"),
        where("habilitado", "==", true)
      );

      onSnapshot(
        queryEnabledVentureCapitals,
        (querySnapshot) => {
          const ventureCapitals: OutputVentureCapital[] =
            querySnapshot.docs.map((docSnapshot) => {
              const ventureCapital = docSnapshot.data() as OutputVentureCapital;
              ventureCapital.id = docSnapshot.id;
              ventureCapital.created_in = (
                ventureCapital.created_in as unknown as Timestamp
              ).toDate();
              return ventureCapital;
            });

          sub.next({
            ok: true,
            value: ventureCapitals,
          });
        },
        () =>
          sub.next({
            ok: true,
            value: [],
          })
      );
    });
  }

  getVentureCapital(id: string): Observable<Result<OutputVentureCapital>> {
    return new Observable((sub) => {
      const docVentureCapital = doc(this.firestore, "venture_capital", id);

      onSnapshot(
        docVentureCapital,
        (docSnapshot) => {
          const ventureCapital = docSnapshot.data() as OutputVentureCapital;
          ventureCapital.id = docSnapshot.id;
          ventureCapital.created_in = (
            ventureCapital.created_in as unknown as Timestamp
          ).toDate();

          sub.next({
            ok: true,
            value: ventureCapital,
          });
        },
        (error) =>
          sub.next({
            ok: false,
            error,
          })
      );
    });
  }

  getVentureCapitalsGestor(
    id_gerente: string
  ): Observable<Result<OutputVentureCapital[]>> {
    return new Observable((sub) => {
      const queryVentureCapitalsGestor = query(
        collection(this.firestore, "venture_capital"),
        where("gestores", "array-contains", id_gerente)
      );

      onSnapshot(
        queryVentureCapitalsGestor,
        (querySnapshot) => {
          const ventureCapitals: OutputVentureCapital[] =
            querySnapshot.docs.map((docSnapshot) => {
              const ventureCapital = docSnapshot.data() as OutputVentureCapital;
              ventureCapital.id = docSnapshot.id;
              ventureCapital.created_in = (
                ventureCapital.created_in as unknown as Timestamp
              ).toDate();
              return ventureCapital;
            });

          sub.next({
            ok: true,
            value: ventureCapitals,
          });
        },
        () =>
          sub.next({
            ok: true,
            value: [],
          })
      );
    });
  }
}
