import {
  Firestore,
  collection,
  addDoc,
  getDocs,
  query,
  where,
  updateDoc,
  doc,
  Timestamp,
  deleteDoc,
} from "firebase/firestore";

import { Result, Writeable } from "../typings";
import { ProgramaConexao } from "../domain/entities/ProgramaConexao";
import { HandlerProgramaConexaoCreate } from "../domain/usecases/interfaces/HandlerProgramaConexaoCreate";
import { HandlerProgramaConexaoGetAllByGestor } from "../domain/usecases/interfaces/HandlerProgramaConexaoGetAllByGestor";
import { HandlerProgramaConexaoGetAll } from "../domain/usecases/interfaces/HandlerProgramaConexaoGetAll";
import { HandlerProgramaConexaoUpdate } from "../domain/usecases/interfaces/HandlerProgramaConexaoUpdate";
import { HandlerProgramaConexaoGetByIds } from "../domain/usecases/interfaces/HandlerProgramaConexaoGetByIds";
import { HandlerProgramaConexaoDelete } from "../domain/usecases/interfaces/HandlerProgramaConexaoDelete";

export class HandlerProgramaConexaoFirestore
  implements
    HandlerProgramaConexaoCreate,
    HandlerProgramaConexaoGetAllByGestor,
    HandlerProgramaConexaoGetAll,
    HandlerProgramaConexaoUpdate,
    HandlerProgramaConexaoGetByIds,
    HandlerProgramaConexaoDelete
{
  readonly collectionPath = "programas_conexao";

  constructor(private firestore: Firestore) {}

  create(
    programa: Omit<ProgramaConexao, "id">
  ): Promise<Result<Pick<ProgramaConexao, "id">>> {
    return new Promise((resolve) => {
      const programaResult = ProgramaConexao.create(programa);

      if (!programaResult.ok) {
        resolve({
          ok: false,
          error: new Error("Erro de validação no Programa de Conexão", {
            cause: programaResult.error,
          }),
        });
      }

      addDoc(collection(this.firestore, this.collectionPath), programa)
        .then((docRef) => {
          updateDoc(docRef, "id", docRef.id);

          resolve({
            ok: true,
            value: {
              id: docRef.id,
            },
          });
        })
        .catch((error) => {
          console.warn("create", error);
          resolve({
            ok: false,
            error,
          });
        });
    });
  }

  getByIds(ids_programas: string[]): Promise<Result<ProgramaConexao[]>> {
    if (ids_programas.length < 1)
      return Promise.resolve({
        ok: true,
        value: [],
      });
    return new Promise((resolve) => {
      getDocs(
        query(
          collection(this.firestore, this.collectionPath),
          where("id", "in", ids_programas)
        )
      )
        .then((querySnapshot) => {
          const programas: ProgramaConexao[] = querySnapshot.docs.map(
            (docSnapshot) => {
              const programa = docSnapshot.data() as Writeable<ProgramaConexao>;
              programa.id = docSnapshot.id;
              programa.start_date = (
                programa.start_date as unknown as Timestamp
              ).toDate();
              programa.end_date = (
                programa.end_date as unknown as Timestamp
              ).toDate();
              return programa;
            }
          );

          resolve({
            ok: true,
            value: programas,
          });
        })
        .catch((error) => {
          console.warn("getByIds", error);
          resolve({
            ok: true,
            value: [],
          });
        });
    });
  }

  getAllByGestor(id_gestor: string): Promise<Result<ProgramaConexao[]>> {
    return new Promise((resolve) => {
      getDocs(
        query(
          collection(this.firestore, this.collectionPath),
          where("gestores", "array-contains", id_gestor)
        )
      )
        .then((querySnapshot) => {
          const programas: ProgramaConexao[] = querySnapshot.docs.map(
            (docSnapshot) => {
              const programa = docSnapshot.data() as Writeable<ProgramaConexao>;
              programa.id = docSnapshot.id;
              return programa;
            }
          );

          resolve({
            ok: true,
            value: programas,
          });
        })
        .catch((error) => {
          console.warn("getAllByGestor", error);
          resolve({
            ok: true,
            value: [],
          });
        });
    });
  }

  getAll(): Promise<Result<ProgramaConexao[]>> {
    return new Promise((resolve) => {
      getDocs(collection(this.firestore, this.collectionPath))
        .then((querySnapshot) => {
          const programas: ProgramaConexao[] = querySnapshot.docs.map(
            (docSnapshot) => {
              const programa = docSnapshot.data() as Writeable<ProgramaConexao>;
              programa.id = docSnapshot.id;
              return programa;
            }
          );

          resolve({
            ok: true,
            value: programas,
          });
        })
        .catch((error) => {
          console.warn("getAll", error);
          resolve({
            ok: true,
            value: [],
          });
        });
    });
  }

  update(
    programa: Partial<
      Pick<
        ProgramaConexao,
        | "about"
        | "end_date"
        | "start_date"
        | "gestores"
        | "invitation_rule"
        | "message_to_a"
        | "message_to_b"
        | "nome"
        | "stakeholder_type_group_a"
        | "stakeholder_type_group_b"
      >
    > &
      Pick<ProgramaConexao, "id">
  ): Promise<Result<void>> {
    return new Promise((resolve) => {
      const isInvalidProgramaConexao =
        ProgramaConexao.isInvalidProgramaConexao(programa);

      if (isInvalidProgramaConexao) {
        resolve({
          ok: false,
          error: new Error("Erro de validação no Programa de Conexão"),
        });
      }

      updateDoc(
        doc(this.firestore, this.collectionPath + "/" + programa.id),
        programa
      )
        .then(() => {
          resolve({
            ok: true,
            value: undefined,
          });
        })
        .catch((error) => {
          console.warn("update", error);
          resolve({
            ok: false,
            error,
          });
        });
    });
  }

  delete(programa: Pick<ProgramaConexao, "id">): Promise<Result<void>> {
    return new Promise((resolve) => {
      deleteDoc(doc(this.firestore, this.collectionPath + "/" + programa.id))
        .then(() => {
          resolve({
            ok: true,
            value: undefined,
          });
        })
        .catch((error) => {
          console.warn("delete", error);
          resolve({
            ok: false,
            error,
          });
        });
    });
  }
}
