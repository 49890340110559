import {
  Firestore,
  doc,
  setDoc,
  collection,
  query,
  where,
  onSnapshot,
  getDocs,
  getCountFromServer,
  getDoc,
} from "firebase/firestore";
import { Observable } from "rxjs";

import { Result, Writeable } from "../typings";
import { HandlerConexaoCreate } from "../domain/usecases/interfaces/HandlerConexaoCreate";
import { Conexao, ParticipanteConexao } from "../domain/entities/Conexao";
import { HandlerConexaoGetAllOfUserRealtime } from "../domain/usecases/interfaces/HandlerConexaoGetAllOfUserRealtime";
import { HandlerConexaoGetAllOfProgramaConexaoRealtime } from "../domain/usecases/interfaces/HandlerConexaoGetAllOfProgramaConexaoRealtime";
import { HandlerConexaoGetAllOfProgramaConexao } from "../domain/usecases/interfaces/HandlerConexaoGetAllOfProgramaConexao";
import { HandlerConexaoAprovadasGet } from "../domain/usecases/interfaces/Conexoes/HandlerConexaoAprovadasGet";
import { HandlerConexaoAprovadasCount } from "../domain/usecases/interfaces/Conexoes/HandlerConexaoAprovadasCount";
import { HandlerConexaoPendentesEnviadasCount } from "../domain/usecases/interfaces/Conexoes/HandlerConexaoPendentesEnviadasCount";
import { HandlerConexaoPendentesEnviadasGet } from "../domain/usecases/interfaces/Conexoes/HandlerConexaoPendentesEnviadasGet";
import { HandlerConexaoPendentesRecebidasCount } from "../domain/usecases/interfaces/Conexoes/HandlerConexaoPendentesRecebidasCount";
import { HandlerConexaoPendentesRecebidasGet } from "../domain/usecases/interfaces/Conexoes/HandlerConexaoPendentesRecebidasGet";
import { HandlerConexaoEntreUsuariosGet } from "../domain/usecases/interfaces/Conexoes/HandlerConexaoEntreUsuariosGet";
import { UsuarioScyggz } from "../domain/entities/usuarioScyggz";
import { HandlerConexaoPendentesRecebidasGetRealtime } from "../domain/usecases/interfaces/Conexoes/HandlerConexaoPendentesRecebidasGetRealtime";
import { HandlerConexaoStatusCountRealtime } from "../domain/usecases/interfaces/Conexoes/HandlerConexaoStatusCountRealtime";
import { ConexoesStatus } from "../domain/entities/ConexoesStatus";

export class HandlerConexaoFirebase
  implements
    HandlerConexaoCreate,
    HandlerConexaoGetAllOfUserRealtime,
    HandlerConexaoGetAllOfProgramaConexaoRealtime,
    HandlerConexaoGetAllOfProgramaConexao,
    HandlerConexaoAprovadasGet,
    HandlerConexaoAprovadasCount,
    HandlerConexaoPendentesEnviadasCount,
    HandlerConexaoPendentesEnviadasGet,
    HandlerConexaoPendentesRecebidasCount,
    HandlerConexaoPendentesRecebidasGet,
    HandlerConexaoEntreUsuariosGet,
    HandlerConexaoPendentesRecebidasGetRealtime,
    HandlerConexaoStatusCountRealtime
{
  readonly collectionPath = "conexoes";

  constructor(private firestore: Firestore) {}

  create({
    autor,
    alvo,
    id_programa,
  }: {
    autor: ParticipanteConexao;
    alvo: ParticipanteConexao;
    id_programa?: string;
  }): Promise<Result<Readonly<Conexao>, Error>> {
    const conexaoResult = Conexao.create(autor, alvo, id_programa);

    if (!conexaoResult.ok) return Promise.resolve(conexaoResult);

    const conexaoToSave: Conexao = {
      ...conexaoResult.value,
      id: conexaoResult.value.id,
    };

    return new Promise((resolve) => {
      setDoc(
        doc(this.firestore, this.collectionPath, conexaoResult.value.id),
        conexaoToSave
      )
        .then(() => {
          resolve({
            ok: true,
            value: conexaoToSave,
          });
        })
        .catch((error) => {
          console.warn("create", error);

          resolve({
            ok: false,
            error,
          });
        });
    });
  }

  getAllOfUser(
    user_id: string
  ): Observable<{ alvo?: Result<Conexao[]>; autor?: Result<Conexao[]> }> {
    return new Observable((sub) => {
      const retorno: { alvo?: Result<Conexao[]>; autor?: Result<Conexao[]> } =
        {};

      const conexoesRef = collection(this.firestore, this.collectionPath);

      const conexoesOndeEhAlvo = query(
        conexoesRef,
        where("alvo.auth_user_id", "==", user_id)
      );

      const snapshotAlvo = onSnapshot(conexoesOndeEhAlvo, {
        next: (alvoSnapshot) => {
          retorno.alvo = {
            ok: true,
            value: alvoSnapshot.docs.map((doc) => {
              const data = doc.data() as Writeable<Conexao>;
              data.id = doc.id;
              return data;
            }),
          };
          sub.next({ ...retorno });
        },
        error: (error) => {
          console.warn("getAllOfUser alvo", error);

          retorno.alvo = {
            ok: false,
            error,
          };
          sub.next({ ...retorno });
        },
      });

      const conexoesOndeEhAutor = query(
        conexoesRef,
        where("autor.auth_user_id", "==", user_id)
      );

      const snapshotAutor = onSnapshot(conexoesOndeEhAutor, {
        next: (alvoSnapshot) => {
          retorno.autor = {
            ok: true,
            value: alvoSnapshot.docs.map((doc) => {
              const data = doc.data() as Writeable<Conexao>;
              data.id = doc.id;
              return data;
            }),
          };
          sub.next({ ...retorno });
        },
        error: (error) => {
          console.warn("getAllOfUser autor", error);

          retorno.autor = {
            ok: false,
            error,
          };
          sub.next({ ...retorno });
        },
      });

      sub.add(() => {
        snapshotAlvo();
        snapshotAutor();
      });
    });
  }

  getAllOfProgramaConexaoRealtime(
    id_programa: string
  ): Observable<Result<Conexao[]>> {
    return new Observable((sub) => {
      const conexoesPrograma = query(
        collection(this.firestore, this.collectionPath),
        where("id_programa", "==", id_programa)
      );

      const snapshotSub = onSnapshot(conexoesPrograma, {
        next: (alvoSnapshot) => {
          sub.next({
            ok: true,
            value: alvoSnapshot.docs.map((doc) => {
              const data = doc.data() as Writeable<Conexao>;
              data.id = doc.id;
              return data;
            }),
          });
        },
        error: (error) => {
          console.warn("getAllOfProgramaConexaoRealtime", error);

          sub.next({
            ok: false,
            error,
          });
        },
      });

      sub.add(() => {
        snapshotSub();
      });
    });
  }

  getAllOfProgramaConexao(id_programa: string): Promise<Result<Conexao[]>> {
    return new Promise((resolve) => {
      const conexoesPrograma = query(
        collection(this.firestore, this.collectionPath),
        where("id_programa", "==", id_programa)
      );

      getDocs(conexoesPrograma)
        .then((alvoSnapshot) => {
          resolve({
            ok: true,
            value: alvoSnapshot.docs.map((doc) => {
              const data = doc.data() as Writeable<Conexao>;
              data.id = doc.id;
              return data;
            }),
          });
        })
        .catch((error) => {
          console.warn("getAllOfProgramaConexao", error);

          resolve({
            ok: false,
            error,
          });
        });
    });
  }

  getConexoesAprovadas(
    usuario: Pick<UsuarioScyggz, "auth_user_id">
  ): Promise<Result<Readonly<Conexao>[], Error>> {
    return new Promise((resolve) => {
      const conexoesRef = collection(this.firestore, "conexoes");

      const conexoesOndeEhAutor = query(
        conexoesRef,
        where("autor.auth_user_id", "==", usuario.auth_user_id),
        where("status", "==", "aprovado")
      );

      const conexoesOndeEhAlvo = query(
        conexoesRef,
        where("alvo.auth_user_id", "==", usuario.auth_user_id),
        where("status", "==", "aprovado")
      );

      Promise.all([getDocs(conexoesOndeEhAutor), getDocs(conexoesOndeEhAlvo)])
        .then(
          ([
            conexoesOndeEhAutorQuerySnapshot,
            conexoesOndeEhAlvoQuerySnapshot,
          ]) => {
            const conexoes = [
              ...conexoesOndeEhAutorQuerySnapshot.docs,
              ...conexoesOndeEhAlvoQuerySnapshot.docs,
            ].map((conexaoDocSnapshot) => {
              const conexaoDocSnapshotData = conexaoDocSnapshot.data();
              const conexao = conexaoDocSnapshotData as Readonly<Conexao>;

              return {
                id: conexaoDocSnapshot.id,
                autor: conexao.autor,
                alvo: conexao.alvo,
                status: conexao.status,
                created_at:
                  conexaoDocSnapshotData.created_at?.toDate() ?? new Date(),
                updated_at:
                  conexaoDocSnapshotData.updated_at?.toDate() ?? new Date(),
              };
            });

            resolve({
              ok: true,
              value: conexoes,
            });
          }
        )
        .catch((error) => {
          resolve({
            ok: false,
            error,
          });
        });
    });
  }

  getConexoesAprovadasCount(
    usuario: Pick<UsuarioScyggz, "auth_user_id">
  ): Promise<Result<number, Error>> {
    return new Promise((resolve) => {
      const conexoesRef = collection(this.firestore, "conexoes");

      const conexoesOndeEhAutor = query(
        conexoesRef,
        where("autor.auth_user_id", "==", usuario.auth_user_id),
        where("status", "==", "aprovado")
      );

      const conexoesOndeEhAlvo = query(
        conexoesRef,
        where("alvo.auth_user_id", "==", usuario.auth_user_id),
        where("status", "==", "aprovado")
      );

      Promise.all([
        getCountFromServer(conexoesOndeEhAutor),
        getCountFromServer(conexoesOndeEhAlvo),
      ])
        .then(
          ([
            qtdeConexoesOndeEhAutorQuerySnapshot,
            qtdeConexoesOndeEhAlvoQuerySnapshot,
          ]) => {
            resolve({
              ok: true,
              value:
                qtdeConexoesOndeEhAutorQuerySnapshot.data().count +
                qtdeConexoesOndeEhAlvoQuerySnapshot.data().count,
            });
          }
        )
        .catch((error) => {
          resolve({
            ok: false,
            error,
          });
        });
    });
  }

  getConexoesPendentesRecebidas(
    usuario: Pick<UsuarioScyggz, "auth_user_id">
  ): Promise<Result<Readonly<Conexao>[], Error>> {
    return new Promise((resolve) => {
      const conexoesRef = collection(this.firestore, "conexoes");

      const conexoesOndeEhAlvo = query(
        conexoesRef,
        where("alvo.auth_user_id", "==", usuario.auth_user_id),
        where("status", "==", "iniciado")
      );

      getDocs(conexoesOndeEhAlvo)
        .then((conexoesOndeEhAlvoQuerySnapshot) => {
          const conexoes = conexoesOndeEhAlvoQuerySnapshot.docs.map(
            (conexaoDocSnapshot) => {
              const conexaoDocSnapshotData = conexaoDocSnapshot.data();
              const conexao = conexaoDocSnapshotData as Readonly<Conexao>;

              return {
                id: conexaoDocSnapshot.id,
                autor: conexao.autor,
                alvo: conexao.alvo,
                status: conexao.status,
                created_at:
                  conexaoDocSnapshotData.created_at?.toDate() ?? new Date(),
                updated_at:
                  conexaoDocSnapshotData.updated_at?.toDate() ?? new Date(),
              };
            }
          );

          resolve({
            ok: true,
            value: conexoes,
          });
        })
        .catch((error) => {
          resolve({
            ok: false,
            error,
          });
        });
    });
  }

  getConexoesPendentesRecebidasCount(
    usuario: Pick<UsuarioScyggz, "auth_user_id">
  ): Promise<Result<number>> {
    return new Promise((resolve) => {
      const conexoesRef = collection(this.firestore, "conexoes");

      const conexoesOndeEhAlvo = query(
        conexoesRef,
        where("alvo.auth_user_id", "==", usuario.auth_user_id),
        where("status", "==", "iniciado")
      );

      getCountFromServer(conexoesOndeEhAlvo)
        .then((qtdeConexoesOndeEhAlvoQuerySnapshot) => {
          resolve({
            ok: true,
            value: qtdeConexoesOndeEhAlvoQuerySnapshot.data().count,
          });
        })
        .catch((error) => {
          resolve({
            ok: false,
            error,
          });
        });
    });
  }

  getConexoesPendentesEnviadas(
    usuario: Pick<UsuarioScyggz, "auth_user_id">
  ): Promise<Result<Readonly<Conexao>[], Error>> {
    return new Promise((resolve) => {
      const conexoesRef = collection(this.firestore, "conexoes");

      const conexoesOndeEhAutor = query(
        conexoesRef,
        where("autor.auth_user_id", "==", usuario.auth_user_id),
        where("status", "==", "iniciado")
      );

      getDocs(conexoesOndeEhAutor)
        .then((conexoesOndeEhAutorQuerySnapshot) => {
          const conexoes = conexoesOndeEhAutorQuerySnapshot.docs.map(
            (conexaoDocSnapshot) => {
              const conexaoDocSnapshotData = conexaoDocSnapshot.data();
              const conexao = conexaoDocSnapshotData as Readonly<Conexao>;

              return {
                id: conexaoDocSnapshot.id,
                autor: conexao.autor,
                alvo: conexao.alvo,
                status: conexao.status,
                created_at:
                  conexaoDocSnapshotData.created_at?.toDate() ?? new Date(),
                updated_at:
                  conexaoDocSnapshotData.updated_at?.toDate() ?? new Date(),
              };
            }
          );

          resolve({
            ok: true,
            value: conexoes,
          });
        })
        .catch((error) => {
          resolve({
            ok: false,
            error,
          });
        });
    });
  }

  getConexoesPendentesEnviadasCount(
    usuario: Pick<UsuarioScyggz, "auth_user_id">
  ): Promise<Result<number>> {
    return new Promise((resolve) => {
      const conexoesRef = collection(this.firestore, "conexoes");

      const conexoesOndeEhAutor = query(
        conexoesRef,
        where("autor.auth_user_id", "==", usuario.auth_user_id),
        where("status", "==", "iniciado")
      );

      getCountFromServer(conexoesOndeEhAutor)
        .then((qtdeConexoesOndeEhAutorQuerySnapshot) => {
          resolve({
            ok: true,
            value: qtdeConexoesOndeEhAutorQuerySnapshot.data().count,
          });
        })
        .catch((error) => {
          resolve({
            ok: false,
            error,
          });
        });
    });
  }

  getConexaoEntreUsuarios(
    userA: Pick<UsuarioScyggz, "auth_user_id">,
    userB: Pick<UsuarioScyggz, "auth_user_id">
  ): Promise<Result<Readonly<Conexao>>> {
    return new Promise((resolve) => {
      const conexaoId = Conexao.getId(userA, userB);

      getDoc(doc(this.firestore, "conexoes", conexaoId))
        .then((conexaoDocSnapshot) => {
          if (conexaoDocSnapshot.exists()) {
            const conexaoDocSnapshotData = conexaoDocSnapshot.data();
            const conexao = conexaoDocSnapshotData as Readonly<Conexao>;

            resolve({
              ok: true,
              value: {
                id: conexaoDocSnapshot.id,
                autor: conexao.autor,
                alvo: conexao.alvo,
                status: conexao.status,
                created_at:
                  conexaoDocSnapshotData.created_at?.toDate() ?? new Date(),
                updated_at:
                  conexaoDocSnapshotData.updated_at?.toDate() ?? new Date(),
              },
            });
          } else {
            resolve({
              ok: false,
              error: new Error("Não existe"),
            });
          }
        })
        .catch((error) =>
          resolve({
            ok: false,
            error,
          })
        );
    });
  }

  getConexoesPendentesRecebidasRealtime(
    usuario: Pick<UsuarioScyggz, "auth_user_id">
  ): Observable<Result<Readonly<Conexao>[]>> {
    return new Observable((sub) => {
      const conexoesRef = collection(this.firestore, "conexoes");

      const conexoesOndeEhAlvo = query(
        conexoesRef,
        where("alvo.auth_user_id", "==", usuario.auth_user_id),
        where("status", "==", "iniciado")
      );

      onSnapshot(
        conexoesOndeEhAlvo,
        (conexoesDocSnapshot) => {
          const conexoes = conexoesDocSnapshot.docs.map(
            (conexaoDocSnapshot) => {
              const conexaoDocSnapshotData = conexaoDocSnapshot.data();
              const conexao = conexaoDocSnapshotData as Readonly<Conexao>;

              return {
                id: conexaoDocSnapshot.id,
                autor: conexao.autor,
                alvo: conexao.alvo,
                status: conexao.status,
                created_at:
                  conexaoDocSnapshotData.created_at?.toDate() ?? new Date(),
                updated_at:
                  conexaoDocSnapshotData.updated_at?.toDate() ?? new Date(),
              };
            }
          );
          sub.next({
            ok: true,
            value: conexoes,
          });
        },
        (error) => {
          sub.next({
            ok: false,
            error,
          });
        }
      );
    });
  }

  getConexoesStatusCountRealtime(
    usuario: Pick<UsuarioScyggz, "auth_user_id">
  ): Observable<Result<ConexoesStatus, Error>> {
    return new Observable((sub) => {
      const conexoesStatusRef = doc(
        this.firestore,
        "usuarios",
        usuario.auth_user_id,
        "conexoes/counters"
      );

      onSnapshot(
        conexoesStatusRef,
        (conexoesStatusDocSnapshot) => {
          const conexoesStatusDocSnapshotData =
            conexoesStatusDocSnapshot.data() as
              | Partial<ConexoesStatus>
              | undefined;

          sub.next({
            ok: true,
            value: {
              qtdeAprovadas: conexoesStatusDocSnapshotData?.qtdeAprovadas ?? 0,
              qtdePendentesEnviadas:
                conexoesStatusDocSnapshotData?.qtdePendentesEnviadas ?? 0,
              qtdePendentesRecebidas:
                conexoesStatusDocSnapshotData?.qtdePendentesRecebidas ?? 0,
            },
          });
        },
        (error) => {
          sub.next({
            ok: false,
            error,
          });
        }
      );
    });
  }
}
