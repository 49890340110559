import { useContext } from "react";

import { UserOrganizacoesContext } from "../../../../globalStore/userOrganizacoes/UserOrganizacoesContext";
import { TextH2Bold } from "../../../generalComponents/TextH2Bold";
import { GerenciarOrganizacoesSection } from "./GerenciarOrganizacoesSection";

export const GerenciarOrganizacoes = () => {
  const { organizacoesResult } = useContext(UserOrganizacoesContext);

  return (
    <aside style={{ width: "100%" }} className="border rounded-4 p-4 card">
      <TextH2Bold classes="mb-3" children="Gerenciar organizações" />

      {organizacoesResult?.startups?.ok && (
        <GerenciarOrganizacoesSection
          baseLink="/startup"
          title="Startups"
          organizacoes={organizacoesResult.startups.value}
        />
      )}

      {organizacoesResult?.empresas?.ok && (
        <GerenciarOrganizacoesSection
          baseLink="/empresa"
          title="Empresas"
          organizacoes={organizacoesResult.empresas.value}
        />
      )}

      {organizacoesResult?.nits?.ok && (
        <GerenciarOrganizacoesSection
          baseLink="/nit"
          title="Nits"
          organizacoes={organizacoesResult.nits.value}
        />
      )}

      {organizacoesResult?.hubs?.ok && (
        <GerenciarOrganizacoesSection
          baseLink="/hub"
          title="Hubs"
          organizacoes={organizacoesResult.hubs.value}
        />
      )}

      {organizacoesResult?.vcs?.ok && (
        <GerenciarOrganizacoesSection
          baseLink="/venture_capital"
          title="Venture Capitals"
          organizacoes={organizacoesResult.vcs.value}
        />
      )}
    </aside>
  );
};
