import { Firestore, collection, getDocs } from "@firebase/firestore";

import { Result } from "../typings";
import { HandlerGetAssociacaoAnjo } from "../domain/usecases/interfaces/handlerGetAssociacaoAnjo";
import { AssociacaoAnjoOutput } from "../domain/entities/associacaoAnjo";

export class HandlerGetAssociacaoAnjoFirebase
  implements HandlerGetAssociacaoAnjo
{
  constructor(private firestore: Firestore) {}

  getAllAssociacaoAnjo(): Promise<Result<AssociacaoAnjoOutput[], Error>> {
    return new Promise((resolve) => {
      getDocs(collection(this.firestore, "associacoes_anjo"))
        .then((querySnapshot) => {
          const associacoes: AssociacaoAnjoOutput[] = querySnapshot.docs.map(
            (docSnapshot) => {
              const associacao = docSnapshot.data() as AssociacaoAnjoOutput;
              associacao.id = docSnapshot.id;
              return associacao;
            }
          );

          resolve({
            ok: true,
            value: associacoes,
          });
        })
        .catch((error) => {
          resolve({
            ok: false,
            error,
          });
        });
    });
  }
}
