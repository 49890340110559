export const BadgeInfo = ({
  children,
  classes,
}: {
  classes?: string;
  children: string | string[] | number;
}) => {
  return (
    <span
      className={` me-2 mt-2 px-1 py-0 badge  text-black ${classes}`}
      style={{ background: "#E5E5BF", fontSize: "16px", fontWeight: "600" }}
    >
      {children}
    </span>
  );
};
