/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useContext } from "react";
import "react-phone-input-2/lib/style.css";
import { Field, Formik } from "formik";
import Select, { StylesConfig } from "react-select";
import makeAnimated from "react-select/animated";
import * as Yup from "yup";

import { ButtonDarkGreen } from "../../../../generalComponents/buttonDarkGreen/ButtonDarkGreen";
import {
  EstagioStartup,
  ESTAGIO_STARTUP,
} from "../../../../../domain/entities/EstagioStartup";
import { MecanismoDeSolucaoTecnologicaOutput } from "../../../../../domain/entities/mecanismoDeSolucaoTecnologica";
import { ModeloNegocioOutput } from "../../../../../domain/entities/modeloNegocio";
import { SegmentoAtuacaoOutput } from "../../../../../domain/entities/segmentoAtuacao";
import { LocationFinderOutput } from "../../../../../domain/usecases/locationFinder";
import { HandlerGetModeloNegocio } from "../../../../../domain/usecases/interfaces/handlerGetModeloNegocio";
import { ResizeContext } from "../../../../../hooks/useResize";
import { ButtonLightGreen } from "../../../../generalComponents/buttonLightGreen/ButtonLightGreen";
import {
  CacheEmpresa,
  HandlerCacheEmpresa,
} from "../../../../../domain/usecases/interfaces/HandlerCacheEmpresa";
import { CurrentUserContext } from "../../../../../globalStore/CurrentUserContext";
import { HandlerUpdateEmpresa } from "../../../../../domain/usecases/interfaces/handlerUpdateEmpresa";
import { InputLocais } from "../../../../generalComponents/InputLocais";
import { InputSegmentosAtuacao } from "../../../../generalComponents/InputSegmentosAtuacao";
import { InputMecanismosSolucao } from "../../../../generalComponents/InputMecanismosSolucao";

const animatedComponents = makeAnimated();

export function ConteudoInteresseStartupsEmpresa({
  handlerGetModeloNegocio,
  handlerCacheEmpresa,
  handlerUpdateEmpresa,
  onBack,
  onFinalize,
}: {
  handlerGetModeloNegocio: HandlerGetModeloNegocio;
  handlerCacheEmpresa: HandlerCacheEmpresa;
  handlerUpdateEmpresa: HandlerUpdateEmpresa;
  onBack: () => void;
  onFinalize: () => void;
}) {
  const customStylesSelect: StylesConfig<ModeloNegocioOutput, true> = {
    control: () => ({
      border: "none",
      borderBottom: "3px #666600 solid",
      display: "flex",
      background: " #fff",
      borderBottomLeftRadius: "8px",
      textAlign: "left",
    }),
  };

  const [allModeloNegocio, setAllModeloNegocio] = useState<
    ModeloNegocioOutput[]
  >([]);
  const [isAllModeloNegocioLoading, setIsAllModeloNegocioLoading] =
    useState<boolean>(false);

  const { currentUserScyggz } = useContext(CurrentUserContext);

  const [parteDoNome, setParteDoNome] = useState("");

  const [cacheEmpresa, setCacheEmpresa] = useState<CacheEmpresa>({});

  useEffect(() => {
    if (currentUserScyggz.user) {
      const parteDoNome: string =
        currentUserScyggz.user.nome.split(" ").at(0) ??
        currentUserScyggz.user.nome;

      setParteDoNome(parteDoNome);
    }
  }, [currentUserScyggz]);

  useEffect(() => {
    handlerCacheEmpresa.get().then((cacheEmpresaResult) => {
      if (cacheEmpresaResult.ok) {
        setCacheEmpresa(cacheEmpresaResult.value);
      }
    });
  }, [handlerCacheEmpresa]);

  useEffect(() => {
    setIsAllModeloNegocioLoading(true);
    handlerGetModeloNegocio
      .getAllModeloNegocio()
      .then((handlerGetModeloNegocioResult) => {
        if (handlerGetModeloNegocioResult.ok)
          setAllModeloNegocio(handlerGetModeloNegocioResult.value);

        setIsAllModeloNegocioLoading(false);
      });
  }, [handlerGetModeloNegocio]);

  const { responsive } = useContext(ResizeContext);

  return (
    <Formik<FormikFields>
      initialValues={{
        interesse_startup_estagio: cacheEmpresa.interesse_startup_estagio ?? [],
        interesse_startup_localizacoes:
          cacheEmpresa.interesse_startup_localizacoes ?? [],
        interesse_startup_mecanismo_solucao:
          cacheEmpresa.interesse_startup_mecanismo_solucao ?? [],
        interesse_startup_modelo_negocio:
          cacheEmpresa.interesse_startup_modelo_negocio ?? [],
        interesse_startup_segmento_atuacao:
          cacheEmpresa.interesse_startup_segmento_atuacao ?? [],
      }}
      enableReinitialize={true}
      validationSchema={validator}
      validateOnMount={true}
      validateOnChange={true}
      validateOnBlur={true}
      onSubmit={async (values) => {
        const saveToCache: CacheEmpresa = {
          interesse_startup_estagio: values.interesse_startup_estagio,
          interesse_startup_localizacoes: values.interesse_startup_localizacoes,
          interesse_startup_modelo_negocio:
            values.interesse_startup_modelo_negocio,
          interesse_startup_segmento_atuacao:
            values.interesse_startup_segmento_atuacao,
          interesse_startup_mecanismo_solucao:
            values.interesse_startup_mecanismo_solucao,
        };

        await handlerCacheEmpresa.save(saveToCache);
        if (!currentUserScyggz.user?.auth_user_id) {
          console.warn("Precisa estar logado para fazer o update da empresa");
          return;
        }

        const updateEmpresa = await handlerUpdateEmpresa.updateEmpresa({
          id: cacheEmpresa.id ?? "",
          ...values,
        });

        if (updateEmpresa.ok) {
          onFinalize();
        } else {
          console.warn("Erro no update da empresa");
        }
      }}
    >
      {({
        isValid,
        errors,
        touched,
        values,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        submitForm,
      }) => (
        <form className={`${!responsive.sm && "px-3"}`} onSubmit={handleSubmit}>
          <h4 className="card-title text-center mb-5 fw-bold">
            <span>{parteDoNome && `${parteDoNome},`}</span>
            <span className="h5 fw-bold">
              {} selecione abaixo o perfil de Startup que você procura.
            </span>
          </h4>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: !responsive.sm ? " 1fr" : "",
              gap: "20px",
            }}
          >
            <div>
              <div className="mb-3" role="group">
                <label className="form-label">
                  Qual(is) o(s) estágio(s) da startup de interesse?*
                </label>
                <div
                  style={
                    responsive.sm
                      ? {
                          marginLeft: "auto",
                          marginRight: "auto",
                          width: "fit-content",
                          borderColor: "#ccc",
                          borderWidth: 1,
                          borderStyle: "solid",
                        }
                      : {
                          borderColor: "#ccc",
                          borderWidth: 1,
                          borderStyle: "solid",
                        }
                  }
                  className={
                    `${
                      responsive.sm ? "flex-column" : ""
                    } d-flex justify-content-between flex-wrap rounded-1 p-2 ` +
                    (errors.interesse_startup_estagio &&
                    touched.interesse_startup_estagio
                      ? " is-invalid"
                      : "")
                  }
                >
                  {ESTAGIO_STARTUP.map((estagio) => (
                    <div
                      key={estagio}
                      className={"form-check form-check-inline"}
                    >
                      <Field
                        className="form-check-input checkboxDarkGreen"
                        type="checkbox"
                        name="interesse_startup_estagio"
                        value={estagio}
                        id={"rdb" + estagio}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={"rdb" + estagio}
                      >
                        {estagio}
                      </label>
                    </div>
                  ))}
                </div>
                <div className="invalid-feedback flex-fill">
                  {errors.interesse_startup_estagio}
                </div>
              </div>

              <div className="mb-4">
                <InputLocais
                  label="Em que localidades as Startups devem estar"
                  value={values.interesse_startup_localizacoes}
                  onChange={(interesse_startup_localizacoes) => {
                    setFieldValue(
                      "interesse_startup_localizacoes",
                      interesse_startup_localizacoes
                    );
                  }}
                  validate={() => undefined}
                />
              </div>

              <div className="mb-4">
                <label className="form-label" htmlFor="selModelos">
                  Com o modelo de negócio
                </label>
                <Select<ModeloNegocioOutput, true>
                  styles={responsive.sm ? customStylesSelect : undefined}
                  options={allModeloNegocio}
                  isLoading={isAllModeloNegocioLoading}
                  getOptionLabel={(o) => o.nome}
                  getOptionValue={(o) => o.id}
                  components={animatedComponents}
                  isMulti={true}
                  placeholder=""
                  name="interesse_startup_modelo_negocio"
                  onChange={(interesse_startup_modelo_negocio) => {
                    setTimeout(() => {
                      setFieldTouched("interesse_startup_modelo_negocio", true);
                    }, 0);
                    setFieldValue(
                      "interesse_startup_modelo_negocio",
                      interesse_startup_modelo_negocio
                    );
                  }}
                  value={values.interesse_startup_modelo_negocio}
                  onBlur={() => {
                    setFieldTouched("interesse_startup_modelo_negocio", true);
                  }}
                  className={
                    errors.interesse_startup_modelo_negocio &&
                    touched.interesse_startup_modelo_negocio
                      ? " is-invalid"
                      : ""
                  }
                  noOptionsMessage={() => "sem opção"}
                  inputId="selModelos"
                />
                <div className="invalid-feedback flex-fill">
                  {errors.interesse_startup_modelo_negocio}
                </div>
              </div>

              <div className="mb-4">
                <InputSegmentosAtuacao
                  label="Que atuam nos seguintes segmentos"
                  value={values.interesse_startup_segmento_atuacao}
                  onChange={(interesse_startup_segmento_atuacao) => {
                    setFieldValue(
                      "interesse_startup_segmento_atuacao",
                      interesse_startup_segmento_atuacao
                    );
                  }}
                  validate={() => undefined}
                />
              </div>

              <div className="mb-5">
                <InputMecanismosSolucao
                  label="Que utilizam os seguintes mecanismos de solução tecnológica"
                  value={values.interesse_startup_mecanismo_solucao ?? []}
                  onChange={(interesse_startup_mecanismo_solucao) => {
                    setFieldValue(
                      "interesse_startup_mecanismo_solucao",
                      interesse_startup_mecanismo_solucao
                    );
                  }}
                  validate={() => undefined}
                />
              </div>
            </div>
          </div>
          <div className=" fw-bold text-center d-flex flex-wrap justify-content-center gap-2">
            {!responsive.sm && (
              <p style={{ color: "#525200", maxWidth: "500px", margin: "0" }}>
                *Obs: Após a criação do perfil da Empresa, você poderá convidar
                demais membros da sua Organização para completar o perfil.
              </p>
            )}

            <div
              className={`${responsive.sm ? "mt-2 w-100" : "text-end mt-2"}`}
            >
              {!responsive.sm && (
                <ButtonLightGreen
                  className="me-3"
                  onClick={async () => {
                    const saveToCache: CacheEmpresa = {
                      interesse_startup_estagio:
                        values.interesse_startup_estagio,
                      interesse_startup_localizacoes:
                        values.interesse_startup_localizacoes,
                      interesse_startup_modelo_negocio:
                        values.interesse_startup_modelo_negocio,
                      interesse_startup_segmento_atuacao:
                        values.interesse_startup_segmento_atuacao,
                      interesse_startup_mecanismo_solucao:
                        values.interesse_startup_mecanismo_solucao,
                    };

                    await handlerCacheEmpresa.save(saveToCache);
                    onBack();
                  }}
                >
                  Voltar
                </ButtonLightGreen>
              )}
              <ButtonDarkGreen
                disabled={!isValid}
                onClick={submitForm}
                className={`${responsive.sm && "w-100"}`}
              >
                Finalizar
              </ButtonDarkGreen>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}

type FormikFields = {
  interesse_startup_estagio: EstagioStartup[];
  interesse_startup_localizacoes: LocationFinderOutput[];
  interesse_startup_modelo_negocio: ModeloNegocioOutput[];
  interesse_startup_segmento_atuacao: SegmentoAtuacaoOutput[];
  interesse_startup_mecanismo_solucao: MecanismoDeSolucaoTecnologicaOutput[];
};

const validator = Yup.object().shape({
  interesse_startup_estagio: Yup.array(),
  interesse_startup_localizacoes: Yup.array(),
  interesse_startup_modelo_negocio: Yup.array(),
  interesse_startup_segmento_atuacao: Yup.array(),
  interesse_startup_mecanismo_solucao: Yup.array(),
});
