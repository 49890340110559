import {
  Firestore,
  collection,
  getDocs,
  where,
  query,
  Timestamp,
  doc,
  getDoc,
} from "@firebase/firestore";

import { Result } from "../typings";
import { HandlerGetNit } from "../domain/usecases/interfaces/handlerGetNit";
import { OutputNit } from "../domain/entities/Nit";

export class HandlerGetNitFirebase implements HandlerGetNit {
  constructor(private firestore: Firestore) {}

  getAllEnabledNits(): Promise<Result<OutputNit[]>> {
    return new Promise((resolve) => {
      const queryEnabledNits = query(
        collection(this.firestore, "nits"),
        where("habilitado", "==", true)
      );

      getDocs(queryEnabledNits)
        .then((querySnapshot) => {
          const nits: OutputNit[] = querySnapshot.docs.map((docSnapshot) => {
            const nit = docSnapshot.data() as OutputNit;
            nit.id = docSnapshot.id;
            nit.created_in = (nit.created_in as unknown as Timestamp).toDate();
            return nit;
          });

          resolve({
            ok: true,
            value: nits,
          });
        })
        .catch(() =>
          resolve({
            ok: true,
            value: [],
          })
        );
    });
  }

  getNit(id: string): Promise<Result<OutputNit>> {
    return new Promise((resolve) => {
      const docNit = doc(this.firestore, "nits", id);

      getDoc(docNit)
        .then((docSnapshot) => {
          const nit = docSnapshot.data() as OutputNit;
          nit.id = docSnapshot.id;
          nit.created_in = (nit.created_in as unknown as Timestamp).toDate();

          resolve({
            ok: true,
            value: nit,
          });
        })
        .catch((error) =>
          resolve({
            ok: false,
            error,
          })
        );
    });
  }

  getNitsGestor(id_gerente: string): Promise<Result<OutputNit[]>> {
    return new Promise((resolve) => {
      const queryNitsGestor = query(
        collection(this.firestore, "nits"),
        where("gestores", "array-contains", id_gerente)
      );

      getDocs(queryNitsGestor)
        .then((querySnapshot) => {
          const nits: OutputNit[] = querySnapshot.docs.map((docSnapshot) => {
            const nit = docSnapshot.data() as OutputNit;
            nit.id = docSnapshot.id;
            nit.created_in = (nit.created_in as unknown as Timestamp).toDate();
            return nit;
          });

          resolve({
            ok: true,
            value: nits,
          });
        })
        .catch(() =>
          resolve({
            ok: true,
            value: [],
          })
        );
    });
  }
}
