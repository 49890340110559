import { useNavigate } from "react-router-dom";

import { HandlerCacheEmpresa } from "../../../../../domain/usecases/interfaces/HandlerCacheEmpresa";
import { HandlerGetModeloNegocio } from "../../../../../domain/usecases/interfaces/handlerGetModeloNegocio";
import { HandlerUpdateEmpresa } from "../../../../../domain/usecases/interfaces/handlerUpdateEmpresa";
import { ContainerResponsive } from "../../../../generalComponents/ContainerResponsive";
import { ConteudoInteresseStartupsEmpresa } from "./ConteudoInteresseStartupsEmpresa";

export const PainelInteresseStatupsEmpresa = ({
  handlerCacheEmpresa,
  handlerUpdateEmpresa,
  handlerGetModeloNegocio,
}: {
  handlerCacheEmpresa: HandlerCacheEmpresa;
  handlerUpdateEmpresa: HandlerUpdateEmpresa;
  handlerGetModeloNegocio: HandlerGetModeloNegocio;
}) => {
  const navigate = useNavigate();

  const paginacao = {
    start: 8,
    end: 8,
  };

  return (
    <section
      data-testid="painel-interesse-cientista-empresa"
      style={{ height: "100%" }}
    >
      <ContainerResponsive
        backUrlMobile="cadastro/empresa/7"
        paginacao={paginacao}
        textoMarcaDagua={"Empresa"}
        showLogo={true}
      >
        <ConteudoInteresseStartupsEmpresa
          handlerUpdateEmpresa={handlerUpdateEmpresa}
          handlerGetModeloNegocio={handlerGetModeloNegocio}
          handlerCacheEmpresa={handlerCacheEmpresa}
          onBack={() => {
            navigate("/cadastro/empresa/7");
          }}
          onFinalize={() => {
            handlerCacheEmpresa.clear();
            navigate("/");
          }}
        />
      </ContainerResponsive>
    </section>
  );
};
