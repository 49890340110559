export const TitleInfo = ({
  children,
  classes,

}: {
  children?: string
  classes?: string

}) => {
  return (
    <h2 className={`${classes}`} style={{ fontSize: "18px", fontWeight: "600", marginBottom: "0", lineHeight: "24px", color: "#414141" }} >
      {children}
    </h2>
  )
}

