export const TIPOS_ORGANIZACAO = [
  "startup",
  //TODO
  //   "hub",
  //   "nit",
  //   "empresa",
  //   "vc",
] as const;

export type TipoOrganizacao = (typeof TIPOS_ORGANIZACAO)[number];
