import { Firestore, addDoc, collection } from "@firebase/firestore";

import { Result } from "../typings";
import { HandlerCreateNit } from "../domain/usecases/interfaces/handlerCreateNit";
import { InputNit, Nit, OutputNit } from "../domain/entities/Nit";

export class HandlerCreateNitFirebase implements HandlerCreateNit {
  constructor(private firestore: Firestore) {}

  createNit(inputNit: InputNit): Promise<Result<OutputNit>> {
    const inputToFirebase: InputNit = {
      nome: inputNit.nome,
      sobre: inputNit.sobre,
      cidade: inputNit.cidade,
      instituicoes: inputNit.instituicoes,
      gestores: inputNit.gestores,
      email: inputNit.email,
      telefone: inputNit.telefone,
      created_in: inputNit.created_in,
      habilitado: inputNit.habilitado,
    };

    if (inputNit.logo_path) {
      inputToFirebase.logo_path = inputNit.logo_path;
    }
    if (inputNit.linkedin) {
      inputToFirebase.linkedin = inputNit.linkedin;
    }
    if (inputNit.website) {
      inputToFirebase.website = inputNit.website;
    }
    if (inputNit.hubs) {
      inputToFirebase.hubs = inputNit.hubs;
    }

    const nitCreateResult = Nit.create(inputToFirebase);
    if (!nitCreateResult.ok) return Promise.resolve(nitCreateResult);

    return new Promise((resolve) => {
      addDoc(collection(this.firestore, "nits"), inputToFirebase)
        .then((docNit) =>
          resolve({
            ok: true,
            value: {
              ...inputToFirebase,
              id: docNit.id,
              created_in: new Date(),
              habilitado: false,
            },
          })
        )
        .catch((error) =>
          resolve({
            ok: false,
            error,
          })
        );
    });
  }
}
