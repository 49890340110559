import { Result } from "../../typings";

export class Telefone {
  public auth_id: string;
  public numero: string;
  private constructor(input: InputTelefone) {
    this.auth_id = input.auth_id;
    this.numero = input.numero;
  }
  public static create({ auth_id, numero }: InputTelefone): Result<Telefone> {
    const errors: Error[] = [];

    auth_id = auth_id.trim();
    const isAuthValid: boolean = auth_id.length > 0;
    if (!isAuthValid) {
      errors.push(new Error("Auth id do avatar inválido"));
    }

    numero = numero.trim();
    //TODO: VALIDAR TELEFONE
    const isNumeroValid: boolean = numero.length > 7;
    if (!isNumeroValid) {
      errors.push(new Error("Número de telefone inválido"));
    }

    if (errors.length > 0) {
      return {
        ok: false,
        error: errors[0],
      };
    }

    return {
      ok: true,
      value: new Telefone({ auth_id, numero }),
    };
  }
}

export type InputTelefone = {
  auth_id: string;
  numero: string;
};

export type OutputTelefone = InputTelefone;
