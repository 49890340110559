import { Result } from "../../../typings";
import {
  InputInvestidorAnjo,
  InvestidorAnjo,
} from "../../entities/InvestidorAnjo";
import { AssociacaoAnjo } from "../../entities/associacaoAnjo";
import { CacheInvestidor } from "./HandlerCacheInvestidor";

export interface HandlerUpdateInvestidor {
  updateInvestidor(
    inputInvestidor: InputHandlerUpdateInvestidor
  ): TypeReturnHandlerUpdateInvestidor;
}

export type InputHandlerUpdateInvestidorSemId = Partial<
  Pick<
    CacheInvestidor,
    | "areas_de_especializacao"
    | "associacoes_anjo"
    | "interesse_startup_estagio"
    | "interesse_startup_localizacoes"
    | "interesse_startup_mecanismo_solucao"
    | "interesse_startup_modelo_negocio"
    | "interesse_startup_segmento_atuacao"
    | "premiacoes_e_certificados"
    | "tese_de_investimento"
  >
>;

export type InputHandlerUpdateInvestidor = InputHandlerUpdateInvestidorSemId & {
  user_id: string;
};

export type TypeReturnHandlerUpdateInvestidor = Promise<
  Result<
    InputHandlerUpdateInvestidor,
    Error | Partial<Record<keyof InvestidorAnjo, Error>>
  >
>;

export function formatInvestidorAnjoUpdate(
  investidorInput: InputHandlerUpdateInvestidor
): {
  investidor: Partial<InputInvestidorAnjo>;
  newAssociacoes: string[];
} {
  const associacoes_anjo: Pick<AssociacaoAnjo, "id" | "nome" | "validado">[] =
    [];
  const newAssociacoes: string[] = [];

  investidorInput.associacoes_anjo?.forEach((associacao_anjo) => {
    if (associacao_anjo.__isNew__) {
      newAssociacoes.push(associacao_anjo.label);
    } else {
      associacoes_anjo.push({
        id: associacao_anjo.id ?? associacao_anjo.value,
        nome: associacao_anjo.nome ?? associacao_anjo.label,
        validado: !!associacao_anjo.validado,
      });
    }
  });

  const investidor: Partial<InputInvestidorAnjo> = {
    ...investidorInput,
    associacoes_anjo,
  };

  return {
    investidor,
    newAssociacoes,
  };
}
