import {
  faEarthAmericas,
  faEnvelope,
  faQuestionCircle,
  faUsersViewfinder,
} from "@fortawesome/free-solid-svg-icons";
import { useContext, useEffect, useState } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

import { doLogout } from "../../../../domain/usecases/doLogout";
import { CurrentUserContext } from "../../../../globalStore/CurrentUserContext";
import { HeaderIcon } from "./headerIcon/HeaderIcon";
import { FotoHeaderInternal } from "./fotoHeaderInternal/FotoHeaderInternal";
import { HeaderNavDropdown } from "./headerNavDropdown/HeaderNavDropdown";
import logoHeader from "../../../../assets/images/logo-scyggz.png";
import { faConnectdevelop } from "@fortawesome/free-brands-svg-icons";

function HeaderInternal() {
  const { currentUserScyggz, currentUserAuth } = useContext(CurrentUserContext);
  const [userName, setUserName] = useState<string>("");

  useEffect(() => {
    setUserName(
      currentUserScyggz.user?.nome ??
        currentUserAuth.user_auth?.displayName ??
        ""
    );
  }, [currentUserScyggz, currentUserAuth]);

  return (
    <Navbar bg="light" variant="light" expand="lg">
      <Container>
        <Navbar.Brand as={Link} to="/">
          <img
            src={logoHeader}
            height="70"
            className="d-inline-block align-top"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="justify-content-end"
        >
          <Nav style={{ textTransform: "uppercase" }} navbarScroll>
            <Nav.Link as={Link} to="/conexoes">
              <HeaderIcon label="Minhas Conexões" icon={faConnectdevelop} />
            </Nav.Link>
            <Nav.Link as={Link} to="/programa_conexao">
              <HeaderIcon
                label="Programas de Conexão"
                icon={faUsersViewfinder}
              />
            </Nav.Link>

            <Nav.Link as={Link} to="/busca">
              <HeaderIcon label="Matchmaking" icon={faEarthAmericas} />
            </Nav.Link>

            <Nav.Link as={Link} to="/contato">
              <HeaderIcon label="Contato" icon={faEnvelope} />
            </Nav.Link>

            <HeaderNavDropdown
              title={
                <HeaderIcon
                  label="Perfil"
                  icon={
                    <FotoHeaderInternal
                      nome={userName}
                      avatar={currentUserScyggz.user?.avatar_path?.path ?? ""}
                      size={"sm"}
                    ></FotoHeaderInternal>
                  }
                />
              }
              align={"end"}
            >
              <NavDropdown.Item as={Link} to="/user">
                <FotoHeaderInternal
                  nome={userName}
                  avatar={currentUserScyggz.user?.avatar_path?.path ?? ""}
                  size={"md"}
                />
                {userName}
              </NavDropdown.Item>

              <NavDropdown.Item
                as={Link}
                to="/"
                onClick={doLogout}
                className="text-center text-danger"
              >
                Sair
              </NavDropdown.Item>
            </HeaderNavDropdown>

            <Navbar.Text className="border-start d-none d-lg-block p-0"></Navbar.Text>
            <Navbar.Text className="border-bottom d-block d-lg-none p-0"></Navbar.Text>

            <HeaderNavDropdown
              title={<HeaderIcon label="Sobre" icon={faQuestionCircle} />}
              align={"end"}
            >
              <NavDropdown.Item as={Link} to="/scyggz">
                Sciencin
              </NavDropdown.Item>
            </HeaderNavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default HeaderInternal;
