import { ReactNode, useState, useEffect, useContext } from "react";

import { UsuarioScyggz } from "../domain/entities/usuarioScyggz";
import { HandlerGetUsuarioFirebase } from "../gateway/HandlerGetUsuarioFirebase";
import { FirebaseContext } from "./FirebaseContext";
import { Result } from "../typings";
import { UsuarioInfoContext } from "./UsuarioInfoContext";

export const UsuarioInfoFirebaseProvider = ({
  usuario,
  children,
}: {
  usuario: Pick<UsuarioScyggz, "auth_user_id">;
  children: ReactNode;
}) => {
  const [usuarioResut, setUsuarioResut] =
    useState<Result<Readonly<UsuarioScyggz>, Error>>();
  const { firestore } = useContext(FirebaseContext);

  useEffect(() => {
    const handlerGetUsuario = new HandlerGetUsuarioFirebase(firestore);

    handlerGetUsuario.getUsuario(usuario).then((usuarioResut) => {
      setUsuarioResut(usuarioResut);
    });
  }, [usuario, firestore]);

  return (
    <UsuarioInfoContext.Provider value={{ usuarioInfoResult: usuarioResut }}>
      {children}
    </UsuarioInfoContext.Provider>
  );
};
