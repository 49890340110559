import { Firestore, collection, getDocs } from "@firebase/firestore";

import { Result } from "../typings";
import { HandlerGetUnidade } from "../domain/usecases/interfaces/handlerGetUnidade";
import { UnidadeInstituicaoDeCienciaETecnologiaOutput } from "../domain/entities/unidadeInstituicaoDeCienciaETecnologia";

export class HandlerGetUnidadeFirebase implements HandlerGetUnidade {
  constructor(private firestore: Firestore) {}
  getAllUnidade(): Promise<
    Result<UnidadeInstituicaoDeCienciaETecnologiaOutput[], Error>
  > {
    return new Promise((resolve) => {
      getDocs(collection(this.firestore, "unidades_instituicao"))
        .then((querySnapshot) => {
          const unidades: UnidadeInstituicaoDeCienciaETecnologiaOutput[] =
            querySnapshot.docs.map((docSnapshot) => {
              const unidade =
                docSnapshot.data() as UnidadeInstituicaoDeCienciaETecnologiaOutput;
              unidade.id = docSnapshot.id;
              return unidade;
            });

          resolve({
            ok: true,
            value: unidades,
          });
        })
        .catch((error) =>
          resolve({
            ok: false,
            error,
          })
        );
    });
  }
}
