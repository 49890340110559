import { ReactNode, useContext, useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { OutputEmpresa } from "../../domain/entities/Empresa";
import { OutputHub } from "../../domain/entities/Hub";
import { OutputNit } from "../../domain/entities/Nit";

import { OutputStartup } from "../../domain/entities/Startup";
import { UsuarioScyggz } from "../../domain/entities/usuarioScyggz";
import { OutputVentureCapital } from "../../domain/entities/VentureCapital";
import { HandlerGetEmpresaRealtimeFirebase } from "../../gateway/HandlerGetEmpresaRealtimeFirebase";
import { HandlerGetHubRealtimeFirebase } from "../../gateway/HandlerGetHubRealtimeFirebase";
import { HandlerGetNitRealtimeFirebase } from "../../gateway/HandlerGetNitRealtimeFirebase";
import { HandlerGetStartupRealtimeFirebase } from "../../gateway/HandlerGetStartupRealtimeFirebase";
import { HandlerGetVentureCapitalRealtimeFirebase } from "../../gateway/HandlerGetVentureCapitalRealtimeFirebase";
import { Result } from "../../typings";
import { FirebaseContext } from "../FirebaseContext";
import { UserOrganizacoesContext } from "./UserOrganizacoesContext";

export function UserOrganizacoesRealtimeFirebaseProvider({
  user,
  children,
}: {
  user: Pick<UsuarioScyggz, "auth_user_id">;
  children: ReactNode;
}) {
  const [startupsResult, setStartupsResult] =
    useState<Result<OutputStartup[]>>();
  const [hubsResult, setHubsResult] = useState<Result<OutputHub[]>>();
  const [empresasResult, setEmpresasResult] =
    useState<Result<OutputEmpresa[]>>();
  const [nitsResult, setNitsResult] = useState<Result<OutputNit[]>>();
  const [vcsResult, setVcsResult] = useState<Result<OutputVentureCapital[]>>();

  const { firestore } = useContext(FirebaseContext);

  useEffect(() => {
    let subStartups: Subscription | undefined;
    let subHubs: Subscription | undefined;
    let subEmpresas: Subscription | undefined;
    let subNits: Subscription | undefined;
    let subVCs: Subscription | undefined;

    if (user.auth_user_id) {
      subStartups = new HandlerGetStartupRealtimeFirebase(firestore)
        .getStartupsGestor(user.auth_user_id)
        .subscribe((result) => {
          setStartupsResult(result);
        });

      subHubs = new HandlerGetHubRealtimeFirebase(firestore)
        .getHubsGestor(user.auth_user_id)
        .subscribe((result) => {
          setHubsResult(result);
        });

      subEmpresas = new HandlerGetEmpresaRealtimeFirebase(firestore)
        .getEmpresasGestor(user.auth_user_id)
        .subscribe((result) => {
          setEmpresasResult(result);
        });

      subNits = new HandlerGetNitRealtimeFirebase(firestore)
        .getNitsGestor(user.auth_user_id)
        .subscribe((result) => {
          setNitsResult(result);
        });

      subVCs = new HandlerGetVentureCapitalRealtimeFirebase(firestore)
        .getVentureCapitalsGestor(user.auth_user_id)
        .subscribe((result) => {
          setVcsResult(result);
        });
    }

    return () => {
      if (subStartups) subStartups.unsubscribe();
      if (subHubs) subHubs.unsubscribe();
      if (subEmpresas) subEmpresas.unsubscribe();
      if (subNits) subNits.unsubscribe();
      if (subVCs) subVCs.unsubscribe();
    };
  }, [user, firestore]);

  return (
    <UserOrganizacoesContext.Provider
      value={{
        organizacoesResult: {
          startups: startupsResult,
          hubs: hubsResult,
          empresas: empresasResult,
          nits: nitsResult,
          vcs: vcsResult,
        },
      }}
      children={children}
    />
  );
}
