import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSSProperties, ReactElement } from "react";
import { Link } from "react-router-dom";

type Props = {
  children: ReactElement | string;
  link?: string;
  classes?: string;
  disabled?: boolean;
  disabledText?: string;
  icon?: IconDefinition;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  className?: string;
  style?: CSSProperties | undefined;
};

const Button = ({
  classes,
  link,
  children,
  disabled,
  disabledText,
  icon,
  onClick,
  className,
  style = undefined,
}: Props) => {
  return link && !disabled ? (
    <Link to={link} className={className}>
      <button
        onClick={onClick}
        className={`${classes} ${disabled ? "disabledButton" : ""}`}
        style={style}
      >
        {icon ? <FontAwesomeIcon icon={icon} /> : null} {children}
        {disabledText ? (
          <span
            className="d-block text-green mt-2"
            style={{ fontSize: "14px" }}
          >
            {disabledText}
          </span>
        ) : null}
      </button>
    </Link>
  ) : (
    <button
      onClick={onClick}
      className={`${classes} ${className} ${disabled ? "disabledButton" : ""}`}
      style={style}
    >
      {icon ? <FontAwesomeIcon icon={icon} /> : null} {children}
      {disabledText ? (
        <span className="d-block text-green mt-2" style={{ fontSize: "14px" }}>
          {disabledText}
        </span>
      ) : null}
    </button>
  );
};

export default Button;
