import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { Subscription } from "rxjs";
import { useNavigate, useParams } from "react-router-dom";

import { HandlersDatabaseContext } from "../../../../../globalStore/HandlersProviders/HandlersDatabaseContext";
import { Result } from "../../../../../typings";
import { ConviteProgramaConexao } from "../../../../../domain/entities/ConviteProgramaConexao";
import { CurrentUserContext } from "../../../../../globalStore/CurrentUserContext";
import { MembroItemProgramaDeConexao } from "./MembroItemProgramaDeConexao";
import { Conexao, StatusConexao } from "../../../../../domain/entities/Conexao";
import { ModalShowUser } from "./ModalShowUser";
import { useUserPerfil } from "../../../../../hooks/useUserPerfil";
import { AdicionarConviteProgramaConexao } from "./AdicionarConviteProgramaConexao";
import { ProgramaConexao } from "../../../../../domain/entities/ProgramaConexao";

export function MembrosProgramaDeConexao({
  programa,
  isGestor,
  className,
}: {
  programa: Pick<
    ProgramaConexao,
    | "id"
    | "stakeholder_type_group_a"
    | "stakeholder_type_group_b"
    | "nome"
    | "logo_path"
    | "message_to_a"
    | "message_to_b"
  >;
  isGestor: boolean;
  className?: string;
}) {
  const {
    handlerConviteProgramaConexaoGetAll,
    handlerConviteProgramaConexaoGetAllAccepted,
    handlerConexaoGetAllOfUserRealtime,
    handlerConexaoCreate,
  } = useContext(HandlersDatabaseContext);
  const {
    currentUserScyggz: { user: currentUser },
  } = useContext(CurrentUserContext);

  const navigate = useNavigate();
  const { id_user, id_perfil } = useParams();
  const userPerfil = useUserPerfil({
    id_user,
    id_perfil,
    currentUser,
  });

  const [convitesResult, setConvitesResult] =
    useState<Result<ConviteProgramaConexao[]>>();
  const [showUser, setShowUser] = useState<boolean>(false);
  const [conexoesCurrentUser, setConexoesCurrentUser] = useState<{
    alvo?: Result<Conexao[]>;
    autor?: Result<Conexao[]>;
  }>({});

  const isCurrentUser = useMemo<boolean>(
    () =>
      !!userPerfil &&
      userPerfil.ok &&
      userPerfil.value.user.auth_user_id == currentUser?.auth_user_id,
    [currentUser?.auth_user_id, userPerfil]
  );

  const statusConexaoWithCurrentUser: (
    convite_alvo_id: string
  ) => StatusConexao | undefined = useCallback(
    (convite_alvo_id) => {
      if (conexoesCurrentUser.alvo?.ok) {
        const conexao = conexoesCurrentUser.alvo.value.find(
          (conexao) => conexao.autor.auth_user_id == convite_alvo_id
        );
        if (conexao) {
          return conexao.status;
        }
      }

      if (conexoesCurrentUser.autor?.ok) {
        const conexao = conexoesCurrentUser.autor.value.find(
          (conexao) => conexao.alvo.auth_user_id == convite_alvo_id
        );
        if (conexao) {
          return conexao.status;
        }
      }
    },
    [conexoesCurrentUser.alvo, conexoesCurrentUser.autor]
  );

  const refreshMembros = useCallback(() => {
    if (isGestor) {
      if (handlerConviteProgramaConexaoGetAll) {
        handlerConviteProgramaConexaoGetAll
          .getAll(programa.id)
          .then((convitesResult) => {
            setConvitesResult(convitesResult);
          });
      }
    } else {
      if (handlerConviteProgramaConexaoGetAllAccepted) {
        handlerConviteProgramaConexaoGetAllAccepted
          .getAllAccepted(programa.id)
          .then((convitesResult) => {
            setConvitesResult(convitesResult);
          });
      }
    }
  }, [
    handlerConviteProgramaConexaoGetAll,
    handlerConviteProgramaConexaoGetAllAccepted,
    programa.id,
    isGestor,
  ]);

  useEffect(() => {
    let sub: Subscription | undefined;

    if (currentUser?.auth_user_id && handlerConexaoGetAllOfUserRealtime) {
      sub = handlerConexaoGetAllOfUserRealtime
        .getAllOfUser(currentUser.auth_user_id)
        .subscribe((conexoesCurrentUser) => {
          setConexoesCurrentUser(conexoesCurrentUser);
        });
    }

    return () => {
      sub?.unsubscribe();
    };
  }, [currentUser?.auth_user_id, handlerConexaoGetAllOfUserRealtime]);

  useEffect(() => {
    refreshMembros();
  }, [refreshMembros]);

  useEffect(() => {
    setShowUser(!!id_user);
  }, [id_user]);

  return (
    <>
      <div className={"card shadow-sm px-2 p-md-3 " + className}>
        <div className="card-body p-2 p-md-3 align-items-center">
          {convitesResult == undefined ? (
            <ProgressBar animated variant="info" now={100} className="w-100" />
          ) : (
            convitesResult.ok && (
              <>
                <div className="table-responsive">
                  {isGestor && (
                    <div className="mb-2">
                      <AdicionarConviteProgramaConexao
                        programa={{
                          stakeholder_type_group_a:
                            programa.stakeholder_type_group_a,

                          stakeholder_type_group_b:
                            programa.stakeholder_type_group_b,

                          nome: programa.nome,
                          id: programa.id,
                          logo_path: programa.logo_path,
                          message_to_a: programa.message_to_a,
                          message_to_b: programa.message_to_b,
                        }}
                        ids_membros={convitesResult.value.map(
                          (convites) => convites.alvo.id
                        )}
                        onAdd={refreshMembros}
                      />
                    </div>
                  )}

                  <table className="table">
                    <thead>
                      <tr className="table-secondary border-0 text-uppercase text-muted">
                        <th className="border-0 rounded-start-1">Nome</th>
                        <th className="border-0">Grupo</th>
                        <th className="border-0">Perfis</th>
                        <th className="border-0">Cidade</th>
                        <th className="border-0 rounded-end-1">Ação</th>
                      </tr>
                    </thead>
                    <tbody>
                      {convitesResult.value.map((convite, index, convites) => (
                        <MembroItemProgramaDeConexao
                          key={convite.id}
                          convite={convite}
                          statusConexaoWithCurrentUser={statusConexaoWithCurrentUser(
                            convite.alvo.id
                          )}
                          isGestor={isGestor}
                          isLastInList={index == convites.length - 1}
                          refreshMembros={() => {
                            refreshMembros();
                          }}
                          formatURL={({ user_id, perfil }) =>
                            "/programa_conexao/" +
                            programa.id +
                            "/membros/" +
                            user_id +
                            "/" +
                            perfil
                          }
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            )
          )}
        </div>
      </div>

      {id_user && (
        <ModalShowUser
          show={showUser}
          onShowChange={(showUser) => {
            if (!showUser) {
              navigate("/programa_conexao/" + programa.id + "/membros/");
            }
          }}
          baseUrl={"/programa_conexao/" + programa.id + "/membros"}
          isCurrentUser={isCurrentUser}
          current_user_auth_id_conexoes={currentUser?.auth_id_conexoes}
          userPerfil={userPerfil}
          onClickConnect={
            handlerConexaoCreate &&
            currentUser &&
            !isCurrentUser &&
            statusConexaoWithCurrentUser(id_user) == undefined
              ? (alvo) => {
                  handlerConexaoCreate.create({
                    id_programa: programa.id,
                    autor: {
                      auth_user_id: currentUser.auth_user_id,
                      nome: currentUser.nome,
                      avatar_path: currentUser.avatar_path,
                    },
                    alvo,
                  });
                }
              : undefined
          }
        />
      )}
    </>
  );
}
