/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Yup from "yup";
import { Formik } from "formik";
import makeAnimated from "react-select/animated";
import Select, { StylesConfig } from "react-select";
import { useContext, useEffect, useState } from "react";

import { ButtonDarkGreen } from "../../../../generalComponents/buttonDarkGreen/ButtonDarkGreen";
import { CidadeOutputWithTipo } from "../../../../../domain/entities/cidade";
import { ResizeContext } from "../../../../../hooks/useResize";
import { PremiacaoOutput } from "../../../../../domain/entities/premiacao";
import { ButtonLightGreen } from "../../../../generalComponents/buttonLightGreen/ButtonLightGreen";
import { CurrentUserContext } from "../../../../../globalStore/CurrentUserContext";
import {
  CacheEmpresa,
  HandlerCacheEmpresa,
} from "../../../../../domain/usecases/interfaces/HandlerCacheEmpresa";
import { InstituicaoDeCienciaETecnologia } from "../../../../../domain/entities/instituicaoDeCienciaETecnologia";
import { InputEmpresa } from "../../../../../domain/entities/Empresa";
import { HandlerCreateEmpresa } from "../../../../../domain/usecases/interfaces/handlerCreateEmpresa";
import { SetorAtuacaoOutput } from "../../../../../domain/entities/SetorAtuacao";
import { HandlerGetInteresseInovacao } from "../../../../../domain/usecases/interfaces/handlerGetInteresseInovacao";
import { HandlerGetSetorAtuacao } from "../../../../../domain/usecases/interfaces/handlerGetSetorAtuacao";
import { InteresseInovacaoOutput } from "../../../../../domain/entities/InteresseInovacao";
import { InputSobre } from "../../../../generalComponents/InputSobre";
import { Sobre } from "../../../../../domain/entities/sobre";
import {
  InputTelefone,
  TypeInputTelefone,
} from "../../../../generalComponents/InputTelefone";
import { InputLinkedin } from "../../../../generalComponents/InputLinkedin";
import { UrlLinkedin } from "../../../../../domain/entities/urlLinkedin";

export const ConteudoSobreEmpresa = ({
  handlerCacheEmpresa,
  handlerCreateEmpresa,
  handlerGetSetorAtuacao,
  handlerGetInteresseInovacao,
  onBack,
  onNext,
}: {
  handlerCacheEmpresa: HandlerCacheEmpresa;
  labelHeadline?: string;
  handlerGetSetorAtuacao: HandlerGetSetorAtuacao;
  handlerGetInteresseInovacao: HandlerGetInteresseInovacao;
  handlerCreateEmpresa: HandlerCreateEmpresa;
  onNext: () => void;
  onBack: () => void;
}) => {
  const customStylesSelectSetorAtuacao: StylesConfig<SetorAtuacaoOutput, true> =
    {
      control: () => ({
        border: "none",
        borderBottom: "3px #666600 solid",
        display: "flex",
        background: " #fff",
        borderBottomLeftRadius: "8px",
        textAlign: "left",
      }),
      input: () => ({
        position: "absolute",
      }),
    };

  const customStylesSelectInteresseInovacao: StylesConfig<
    InteresseInovacaoOutput,
    true
  > = {
    control: () => ({
      border: "none",
      borderBottom: "3px #666600 solid",
      display: "flex",
      background: " #fff",
      borderBottomLeftRadius: "8px",
      textAlign: "left",
    }),
    input: () => ({
      position: "absolute",
    }),
  };

  const animatedComponents = makeAnimated();

  const { responsive } = useContext(ResizeContext);

  const { currentUserScyggz } = useContext(CurrentUserContext);

  const [parteDoNome, setParteDoNome] = useState("");

  const [cacheEmpresa, setCacheEmpresa] = useState<CacheEmpresa>({});

  const [allSetoresAtuacao, setAllSetoresAtuacao] = useState<
    SetorAtuacaoOutput[]
  >([]);
  const [isAllSetoresAtuacaoLoading, setIsAllSetoresAtuacaoLoading] =
    useState<boolean>(false);

  const [allInteresseInovacao, setAllInteresseInovacao] = useState<
    SetorAtuacaoOutput[]
  >([]);
  const [isAllInteresseInovacaoLoading, setIsAllInteresseInovacaoLoading] =
    useState<boolean>(false);

  useEffect(() => {
    setIsAllSetoresAtuacaoLoading(true);
    handlerGetSetorAtuacao
      .getAllSetorAtuacao()
      .then((handlerGetSetorAtuacaoResult) => {
        if (handlerGetSetorAtuacaoResult.ok)
          setAllSetoresAtuacao(handlerGetSetorAtuacaoResult.value);

        setIsAllSetoresAtuacaoLoading(false);
      });
  }, [handlerGetSetorAtuacao]);

  useEffect(() => {
    setIsAllInteresseInovacaoLoading(true);
    handlerGetInteresseInovacao
      .getAllInteresseInovacao()
      .then((handlerGetInteresseInovacaoResult) => {
        if (handlerGetInteresseInovacaoResult.ok)
          setAllInteresseInovacao(handlerGetInteresseInovacaoResult.value);

        setIsAllInteresseInovacaoLoading(false);
      });
  }, [handlerGetInteresseInovacao]);

  useEffect(() => {
    handlerCacheEmpresa.get().then((cacheEmpresa) => {
      if (cacheEmpresa.ok) {
        setCacheEmpresa(cacheEmpresa.value);
      }
    });
  }, [handlerCacheEmpresa]);

  useEffect(() => {
    if (currentUserScyggz.user) {
      const parteDoNome: string =
        currentUserScyggz.user.nome.split(" ").at(0) ??
        currentUserScyggz.user.nome;

      setParteDoNome(parteDoNome);
    }
  }, [currentUserScyggz]);

  return (
    <div style={!responsive.sm ? { padding: "0 70px" } : {}}>
      <Formik<FormikValues>
        initialValues={{
          email: cacheEmpresa?.email ?? "",
          nome_empresa: cacheEmpresa?.nome ?? "",
          sobre: cacheEmpresa?.sobre ?? "",
          website: cacheEmpresa?.website ?? "",
          linkedin: cacheEmpresa?.linkedin ?? "",
          telefone: cacheEmpresa?.telefone
            ? {
                numero: cacheEmpresa.telefone,
                invalid: true,
              }
            : {
                numero: "",
                invalid: true,
              },
          interesses_inovacao: cacheEmpresa?.interesses_inovacao ?? [],
          setores_atuacao: cacheEmpresa.setores_atuacao ?? [],
        }}
        validationSchema={validator}
        validateOnMount={true}
        validateOnChange={true}
        validateOnBlur={true}
        enableReinitialize={true}
        onSubmit={async (values) => {
          await handlerCacheEmpresa.save({
            nome: values.nome_empresa,
            sobre: values.sobre,
            email: values.email,
            linkedin: values.linkedin,
            telefone: values.telefone.numero,
            website: values.website,
            setores_atuacao: values.setores_atuacao,
            interesses_inovacao: values.interesses_inovacao,
          });

          if (!values.telefone) {
            console.warn("Telefone Obrigatório");
            return;
          }

          const createEmpresa: InputEmpresa = {
            email: values.email,
            gestores: [currentUserScyggz.user?.auth_user_id ?? ""],
            nome: values.nome_empresa,
            sobre: values.sobre,
            telefone: values.telefone.numero,
            linkedin: values.linkedin,
            website: values.website,
            setores_atuacao: values.setores_atuacao,
            interesses_inovacao: values.interesses_inovacao,
            created_in: new Date(),
            habilitado: false,
          };

          const createEmpresaResult = await handlerCreateEmpresa.createEmpresa(
            createEmpresa
          );

          if (!createEmpresaResult.ok) {
            console.warn("Erro na criação do Empresa");
            return;
          } else {
            await handlerCacheEmpresa.save({
              id: createEmpresaResult.value.id,
            });
          }

          onNext();
        }}
      >
        {({
          isValid,
          isSubmitting,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          handleChange,
          handleBlur,
          errors,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit} className="pb-2">
            <h4 className="card-title text-center mb-4 fw-bold">
              <span>{parteDoNome && `${parteDoNome},`}</span>
              <span className="h5 fw-bold">
                {} por favor fale sobre o Empresa.
              </span>
            </h4>

            <InputSobre
              label="Sobre a Empresa*:"
              value={values.sobre}
              onChange={(sobre) => {
                setFieldValue("sobre", sobre);
              }}
            />

            <div className="mb-4">
              <label htmlFor="iptNome" className="form-label">
                Nome da Empresa*
              </label>
              <input
                type="text"
                className={
                  `${responsive.sm ? "imputMobile" : "form-control"}` +
                  (errors.nome_empresa && touched.nome_empresa
                    ? " is-invalid"
                    : "")
                }
                id="iptNome"
                name="nome_empresa"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.nome_empresa}
              />
              <div className="invalid-feedback flex-fill">
                {errors.nome_empresa}
              </div>
            </div>

            <div className="mb-4">
              <InputTelefone
                label="Telefone*"
                value={values.telefone}
                onChange={(tel) => {
                  setFieldValue("telefone", tel);
                }}
                validate={(numero) => {
                  const preenchido: boolean = (numero?.length ?? 0) > 0;

                  if (!preenchido) {
                    return new Error("obrigatório");
                  }
                }}
              />
            </div>

            <div className="mb-4">
              <InputLinkedin
                label="Linkedin"
                value={values.linkedin}
                onChange={(linkedin) => {
                  setFieldValue("linkedin", linkedin);
                }}
              />
            </div>

            <div className="mb-4">
              <label htmlFor="iptWebsite" className="form-label">
                Website
              </label>
              <input
                type="text"
                className={
                  `${responsive.sm ? "imputMobile" : "form-control"}` +
                  (errors.website && touched.website ? " is-invalid" : "")
                }
                id="iptWebsite"
                name="website"
                onChange={website => {
                  const websiteStr = website.target.value;
                  let websiteFinal = websiteStr;

                  if(websiteStr === "http:/" || websiteStr === "https:/") {
                    websiteFinal = "";
                  } else if (!websiteStr.startsWith("http://") && !websiteStr.startsWith("https://")) {
                    websiteFinal = "https://" + websiteStr;
                  }

                  setFieldValue("website", websiteFinal);
                }}
                onBlur={handleBlur}
                value={values.website}
              />
              <div className="invalid-feedback flex-fill">{errors.website}</div>
            </div>

            <div className="mb-4">
              <label htmlFor="iptEmail" className="form-label">
                E-mail*
              </label>
              <input
                type="text"
                className={
                  `${responsive.sm ? "imputMobile" : "form-control"}` +
                  (errors.email && touched.email ? " is-invalid" : "")
                }
                id="iptEmail"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
              <div className="invalid-feedback flex-fill">{errors.email}</div>
            </div>

            <div className="mb-4 w-100">
              <label className="form-label" htmlFor="selectSetores">
                Setores de atuação*
              </label>
              <Select<SetorAtuacaoOutput, true>
                options={allSetoresAtuacao}
                isLoading={isAllSetoresAtuacaoLoading}
                getOptionLabel={(o) => o.nome}
                getOptionValue={(o) => o.id}
                components={animatedComponents}
                placeholder=""
                styles={
                  responsive.sm ? customStylesSelectSetorAtuacao : undefined
                }
                isMulti={true}
                onBlur={() => setFieldTouched("setores_atuacao", true)}
                onChange={(setoresAtuacao) => {
                  setFieldValue("setores_atuacao", setoresAtuacao);
                  setTimeout(() => setFieldTouched("setores_atuacao", true), 0);
                }}
                value={values.setores_atuacao}
                className={
                  errors.setores_atuacao && touched.setores_atuacao
                    ? " is-invalid "
                    : ""
                }
                noOptionsMessage={() => "sem opção"}
                inputId="selectSetores"
              />
              <div className="invalid-feedback flex-fill">
                {errors.setores_atuacao}
              </div>
            </div>

            <div className="mb-1 w-100">
              <label className="form-label" htmlFor="selectInteresse">
                Interesses de inovação*
              </label>
              <Select<InteresseInovacaoOutput, true>
                options={allInteresseInovacao}
                isLoading={isAllInteresseInovacaoLoading}
                getOptionLabel={(o) => o.nome}
                getOptionValue={(o) => o.id}
                components={animatedComponents}
                placeholder=""
                isMulti={true}
                styles={
                  responsive.sm
                    ? customStylesSelectInteresseInovacao
                    : undefined
                }
                value={values.interesses_inovacao}
                onBlur={() => setFieldTouched("interesses_inovacao", true)}
                onChange={(interesseInovacao) => {
                  setFieldValue("interesses_inovacao", interesseInovacao);
                  setTimeout(
                    () => setFieldTouched("interesses_inovacao", true),
                    0
                  );
                }}
                className={
                  errors.interesses_inovacao && touched.interesses_inovacao
                    ? " is-invalid "
                    : ""
                }
                noOptionsMessage={() => "sem opção"}
                inputId="selectInteresse"
              />
              <div className="invalid-feedback flex-fill">
                {errors.interesses_inovacao}
              </div>
            </div>

            <div className="mt-5 fw-bold text-center d-flex flex-wrap justify-content-center gap-2">
              {!responsive.sm && (
                <p style={{ color: "#525200", maxWidth: "500px", margin: "0" }}>
                  *Obs: Após a criação do perfil da Empresa, você poderá
                  convidar demais membros da sua Organização para completar o
                  perfil.
                </p>
              )}

              <div
                className=" text-end d-flex flex-nowrap"
                style={{
                  height: "fit-content",
                  width: responsive.sm ? "100%" : "",
                }}
              >
                {!responsive.sm && (
                  <ButtonLightGreen
                    className="me-3"
                    onClick={() => {
                      handlerCacheEmpresa.save({
                        ...values,
                        telefone: values.telefone.numero,
                      });
                      onBack();
                    }}
                  >
                    Voltar
                  </ButtonLightGreen>
                )}
                <ButtonDarkGreen
                  disabled={!isValid || isSubmitting}
                  isSubmit={true}
                  className={
                    (!responsive.sm ? "ms-2" : "w-100") + " text-uppercase"
                  }
                >
                  Avançar
                </ButtonDarkGreen>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

type FormikValues = {
  sobre: string;
  email: string;
  nome_empresa: string;
  interesses_inovacao: InteresseInovacaoOutput[];
  setores_atuacao: SetorAtuacaoOutput[];
  telefone: TypeInputTelefone;
} & Partial<{
  cidade: CidadeOutputWithTipo;
  website: string; // TODO: VALIDATE URL
  linkedin: string; // TODO: VALIDATE URLc
  instituicoes: Pick<InstituicaoDeCienciaETecnologia, "id" | "nome">[];
}>;

export type OutputPainelSobreEmpresaMobile = {
  sobre: string;
  email: string;
  nome_empresa: string;
  ano_fundacao: number;
  telefone: TypeInputTelefone;
} & Partial<{
  cidade: CidadeOutputWithTipo;
  website: string; // TODO: VALIDATE URL
  linkedin: string; // TODO: VALIDATE URL
  premiacoes: PremiacaoOutput[];
}>;

const validator = Yup.object().shape({
  sobre: Yup.string()
    .required(
      "Campo obrigatório, deve ter no mínimo " +
        Sobre.MINIMO_LENGTH +
        " caracteres"
    )
    .min(Sobre.MINIMO_LENGTH, "Mínimo " + Sobre.MINIMO_LENGTH + " caracteres.")
    .max(
      Sobre.MAXIMO_LENGTH,
      "Máximo de " + Sobre.MAXIMO_LENGTH + " caracteres."
    ),
  nome_empresa: Yup.string()
    .required("Campo obrigatório")
    .min(1, "Campo obrigatório"),
  website: Yup.string().url("Deve ser um link").min(5, "Mínimo 5 caracteres"),
  telefone: Yup.object()
    .shape({
      numero: Yup.string().required(),
      invalid: Yup.boolean().isFalse(),
    })
    .required("Cidade é obrigatório"),
  linkedin: Yup.string().test((urlLinkedin) => {
    return urlLinkedin ? UrlLinkedin.isValidUrlLinkedin(urlLinkedin) : true;
  }),
  email: Yup.string().required("Campo obrigatório").email("Campo inválido"),
  interesses_inovacao: Yup.array()
    .min(1, "Campo obrigatório")
    .required("Campo obrigatório"),
  setores_atuacao: Yup.array()
    .min(1, "Campo obrigatório")
    .required("Campo obrigatório"),
});
