export const FUNDING_ROUNDS = [
  "Recursos de Fomento:Fapesp, Programa Privado, Outro",
  "Investimento Anjo",
  "Equity Crowdfunding",
  "PreSeed",
  "Seed",
  "Serie A",
  "Serie B",
  "Serie C",
  "Serie D",
  "Serie E",
  "Serie F",
  "Serie G",
  "Serie H",
  "Serie I",
  "Serie J",
  "Serie K",
  "Serie L",
  "Serie M",
  "Serie N",
  "Serie O",
  "Serie P",
  "Serie Q",
] as const;

export type FundingRound = typeof FUNDING_ROUNDS[number];
