import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { OutputStartup } from "../../../domain/entities/Startup";
import { HandlersDatabaseContext } from "../../../globalStore/HandlersProviders/HandlersDatabaseContext";
import { Result } from "../../../typings";
import { ConviteStartupEmployee } from "../../../domain/entities/ConviteStartupEmployee";
import { InfoCardConvitesStartup } from "../user/components/generalComponents/InfoCardConvitesStartup";

type SubPagePessoasProps = {
  startup: OutputStartup;
  currentUserIsGestor: boolean;
  seeAsPublic: boolean;
};

export function SubPagePessoas({
  startup,
  currentUserIsGestor,
  seeAsPublic,
}: SubPagePessoasProps) {
  const {
    handlerConviteStartupEmployeeGetAllAccepted,
    handlerConviteStartupEmployeeGetAllNotRejected,
  } = useContext(HandlersDatabaseContext);

  const [convitesResult, setConvitesResult] =
    useState<Result<ConviteStartupEmployee[]>>();

  const employers = useMemo<ConviteStartupEmployee[]>(() => {
    if (convitesResult?.ok) {
      return convitesResult.value.reduce<ConviteStartupEmployee[]>(
        (prev, convite) => {
          prev.push(convite);
          return prev;
        },
        []
      );
    }
    return [];
  }, [convitesResult]);

  const refreshConvites = useCallback(() => {
    setLoading(true);
    if (currentUserIsGestor) {
      handlerConviteStartupEmployeeGetAllNotRejected
        ?.getAllNotRejected(startup.id)
        .then((convitesResult) => {
          setLoading(false);
          setConvitesResult(convitesResult);
        });
    } else {
      handlerConviteStartupEmployeeGetAllAccepted
        ?.getAllAccepted(startup.id)
        .then((convitesResult) => {
          setLoading(false);
          setConvitesResult(convitesResult);
        });
    }
  }, [
    currentUserIsGestor,
    handlerConviteStartupEmployeeGetAllAccepted,
    handlerConviteStartupEmployeeGetAllNotRejected,
    startup.id,
  ]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    refreshConvites();
  }, [refreshConvites]);

  return (
    <>
      <InfoCardConvitesStartup
        convites={employers}
        className="mb-0"
        refreshConvites={refreshConvites}
        loading={loading}
        seeAsPublic={seeAsPublic}
      />
    </>
  );
}
