import {
  Auth,
  sendPasswordResetEmail,
  checkActionCode,
  confirmPasswordReset,
  signInWithEmailAndPassword,
  applyActionCode,
} from "firebase/auth";
import { FirebaseError } from "firebase/app";

import { HandlerAuth } from "../domain/usecases/interfaces/HandlerAuth";
import { Result } from "../typings";
import { AuthErrorValidation } from "../domain/erros/AuthErrors";

export class HandlerAuthFirebase implements HandlerAuth {
  constructor(private auth: Auth) {}

  sendPasswordResetEmail(
    email: string
  ): Promise<Result<null, AuthErrorValidation | FirebaseError | Error>> {
    return new Promise((resolve) => {
      sendPasswordResetEmail(this.auth, email)
        .then(() =>
          resolve({
            ok: true,
            value: null,
          })
        )
        .catch((error) =>
          resolve({
            ok: false,
            error,
          })
        );
    });
  }

  checkActionCode(code: string): Promise<Result<{ email: string }>> {
    return new Promise((resolve) => {
      checkActionCode(this.auth, code)
        .then((actionCodeInfo) => {
          resolve({
            ok: true,
            value: {
              email: actionCodeInfo.data.email ?? "",
            },
          });
        })
        .catch((reason) => {
          resolve({
            ok: false,
            error: reason,
          });
        });
    });
  }

  confirmPasswordReset(
    code: string,
    newPassword: string
  ): Promise<Result<void>> {
    return new Promise((resolve) => {
      confirmPasswordReset(this.auth, code, newPassword)
        .then(() => {
          resolve({
            ok: true,
            value: undefined,
          });
        })
        .catch((reason) => {
          resolve({
            ok: false,
            error: reason,
          });
        });
    });
  }

  signInWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<Result<void>> {
    return new Promise((resolve) => {
      signInWithEmailAndPassword(this.auth, email, password).then(() => {
        resolve({
          ok: true,
          value: undefined,
        });
      });
    });
  }

  applyActionCode(code: string): Promise<Result<void>> {
    return new Promise((resolve) => {
      applyActionCode(this.auth, code)
        .then(() => {
          resolve({
            ok: true,
            value: undefined,
          });
        })
        .catch((error) =>
          resolve({
            ok: false,
            error,
          })
        );
    });
  }
}
