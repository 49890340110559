import { Firestore, setDoc, doc } from "firebase/firestore";
import { FirebaseError } from "firebase/app";

import { InputUrlLinkedin, UrlLinkedin } from "../domain/entities/urlLinkedin";
import { HandlerSaveUrlLinkedin } from "../domain/usecases/interfaces/handlerSaveUrlLinkedin";
import { Result } from "../typings";
import {
  FirestoreError,
  FirestoreErrorsFactory,
} from "../domain/erros/FirestoreErrors";

export class HandlerSaveUrlLinkedinFirebase implements HandlerSaveUrlLinkedin {
  constructor(private firestore: Firestore) {}

  async saveUrlLinkedin(
    input: InputUrlLinkedin
  ): Promise<Result<UrlLinkedin, Error>> {
    const urlLinkedinResult = UrlLinkedin.create(input);

    if (!urlLinkedinResult.ok) return urlLinkedinResult;

    const docUrlLinkedin = doc(this.firestore, "url_linkedin", input.auth_id);

    return new Promise<Result<UrlLinkedin, Error>>((resolve) => {
      setDoc(docUrlLinkedin, input)
        .then(() =>
          resolve({
            ok: true,
            value: input,
          })
        )
        .catch((error: FirebaseError) => {
          const instanciaErro = FirestoreErrorsFactory.createErrorsValidation(
            <FirestoreError>error.code
          );

          return resolve({
            ok: false,
            error: instanciaErro,
          });
        });
    });
  }
}
