import {
  faEnvelope,
  faEye,
  faEyeSlash,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik } from "formik";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";

import { AuthErrorValidation } from "../../../../domain/erros/AuthErrors";
import { AutenticacaoService } from "../../../../domain/usecases/autenticacaoService";
import { getEmailFromStorage } from "../../../../hooks/handlerEmailFromStorage";
import { ResizeContext } from "../../../../hooks/useResize";
import arvore from "../../../../assets/images/icone_arvore.png";
import { CheckboxDarkGreen } from "../../../generalComponents/checkboxDarkGreen/CheckboxDarkGreen";

export const ConteudoPainelLogin = () => {
  const autenticacaoService = AutenticacaoService.newAutenticacaoService();

  const { responsive } = useContext(ResizeContext);
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Formik
      initialValues={{
        email: getEmailFromStorage() ?? "",
        password: "",
      }}
      validationSchema={autenticacaoService.LoginSchema}
      onSubmit={(values, { setSubmitting, setFieldError }) => {
        autenticacaoService.doLogin(values.email, values.password).subscribe({
          error: (error: Error) => {
            console.warn("ERRO IN FORM:", error.message);

            if ("campos" in error) {
              (error as AuthErrorValidation).campos.forEach((campoErro) =>
                setFieldError(campoErro, error.message)
              );
            }
            setSubmitting(false);
          },
          complete: () => setSubmitting(false),
        });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValid,
      }) => (
        <form
          className={`${responsive.sm ? "px-1" : "px-5"}`}
          onSubmit={handleSubmit}
        >
          <div className="d-flex flex-column align-items-center mt-3 mb-2">
            <img
              style={{ fontWeight: "bold" }}
              width={68}
              src={arvore}
              alt="Logo Arvore"
            />
          </div>

          <h2
            style={{ fontSize: "60px", fontWeight: "bold" }}
            className="text-center mb-4"
          >
            Login
          </h2>

          <div className="input-group mb-4 has-validation">
            {!responsive.sm && (
              <span className="input-group-text border-end-0 bg-secondary bg-opacity-10">
                <FontAwesomeIcon icon={faEnvelope} className="opacity-25" />
              </span>
            )}
            <input
              type="email"
              name="email"
              className={`${
                responsive.sm
                  ? "imputMobile mt-3"
                  : "form-control border-start-0 m-0 bg-secondary bg-opacity-10 text-secondary"
              }
                    ${
                      errors.email && touched.email && errors.email
                        ? " is-invalid"
                        : ""
                    }`}
              placeholder="Email*"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
            />
            <div className="invalid-feedback">
              {errors.email && touched.email && errors.email}
            </div>
          </div>

          <div className="input-group mb-2 has-validation position-relative mb-4">
            {!responsive && (
              <span className="input-group-text border-end-0 bg-secondary bg-opacity-10">
                <FontAwesomeIcon icon={faLock} className="opacity-25" />
              </span>
            )}
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              className={`${
                responsive.sm
                  ? "imputMobile mt-3"
                  : "form-control border-start-0 border-end-0 m-0 bg-secondary bg-opacity-10 text-secondary"
              }
                      ${
                        errors.password && errors.password ? " is-invalid" : ""
                      }`}
              placeholder="Senha*"
            />
            <button
              style={{
                top: responsive.sm ? "14px" : "",
                border: responsive.sm ? "none" : "",
              }}
              className={`${
                responsive.sm
                  ? "btn bg-white bg-opacity-10 m-0 position-absolute lh-1 shadow-none end-0"
                  : "btn border-start-0 bg-white border bg-opacity-10 m-0"
              }`}
              type="button"
              onClick={() => {
                setShowPassword(!showPassword);
              }}
              title={showPassword ? "Esconder senha" : "Exibir senha"}
            >
              <FontAwesomeIcon
                icon={showPassword ? faEyeSlash : faEye}
                className="opacity-25"
              />
            </button>
            <div className="invalid-feedback">
              {errors.password && errors.password}
            </div>
          </div>

          <div className="d-flex aligb-items-center justify-content-between mb-4">
            <div className="d-flex gap-2">
              <CheckboxDarkGreen />
              <span className="fw-bold">Manter-me logado</span>
            </div>

            <Link to={"/esqueceusenha"}>
              <span
                style={{ color: "#002776", borderBottom: "1px #002776 solid" }}
              >
                Esqueceu a senha?{" "}
              </span>
            </Link>
          </div>

          <button
            className="w-100 btn btn-lg fw-bold "
            style={{
              backgroundColor: "#525200",
              color: " #fff ",
              fontSize: "28px",
            }}
            type="submit"
            disabled={isSubmitting || !isValid}
          >
            Login
          </button>

          <div className="form-check mt-3 fw-light mb-4">
            <label style={{ fontSize: "14px" }} className="form-check-label">
              Ao continuar, você concorda com nossos
              <Link to={"/termosdeuso"} target="_blank">
                <span className="text-decoration-underline fw-bold ms-1 text-black">
                  Termos de Serviços
                </span>
              </Link>
              ,
              <Link to={"/politicadeprivacidade"} target="_blank">
                <span className="text-decoration-underline fw-bold mx-1 text-black">
                  Política de Privacidade
                </span>
              </Link>
            </label>
          </div>

          <div className="text-center mb-2">
            Não tem uma conta?
            <Link to={"/cadastro"}>
              <span
                style={{ color: "#002776", borderBottom: "1px #002776 solid" }}
              >
                {" "}
                Cadastre-se agora{" "}
              </span>
            </Link>
          </div>
        </form>
      )}
    </Formik>
  );
};
