import { useContext } from "react";
import { ProgressBar } from "react-bootstrap";

import { ConexoesPendentesEnviadasContext } from "../../../../globalStore/conexoesPendentesEnviadas/ConexoesPendentesEnviadasContext";
import { ListConexoes } from "./ListConexoes";

export function ListConexoesPendentesEnviadas() {
  const { conexoesPendentesEnviadasResult } = useContext(
    ConexoesPendentesEnviadasContext
  );

  return (
    <>
      {conexoesPendentesEnviadasResult == undefined ? (
        <ProgressBar animated variant="info" now={100} className="w-100 m-1" />
      ) : (
        conexoesPendentesEnviadasResult.ok && (
          <ListConexoes
            conexoes={conexoesPendentesEnviadasResult.value.map((conexao) => ({
              id: conexao.id,
              user: conexao.alvo,
            }))}
          />
        )
      )}
    </>
  );
}
