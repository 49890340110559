import { faRotateLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function Etapa1NewPrograma({
  onBack,
  onNext,
}: {
  onBack: () => void;
  onNext: () => void;
}) {
  return (
    <div className="card shadow-sm">
      <div className="card-header border-0 bg-white p-0">
        <button
          type="button"
          className="btn btn-light bg-white border-0 text-muted"
          onClick={onBack}
        >
          <FontAwesomeIcon icon={faRotateLeft} className="me-2" />
          Back
        </button>
      </div>

      <div className="card-body text-center">
        <h5 className="pb-2 fw-bolder">O que é um programa de conexão?</h5>

        <p>
          É uma ferramenta de gestão de Ecossistema de Empreendedorismo e
          Inovação para estimular a conexão entre profissionais com interesse em
          atuar para criação de soluções inovadoras com impacto na sociedade
        </p>
      </div>

      <div className="card-footer border-0 pt-0 bg-white text-center">
        <button
          type="button"
          className="btn px-4 text-white background-spinoff"
          onClick={onNext}
        >
          Continue
        </button>
      </div>
    </div>
  );
}
