import { Hub } from "../../../domain/entities/Hub";
import { HeaderPerfil } from "../components/perfil/HeaderPerfil";
import { InfoPremiacoes } from "../user/components/generalComponents/InfoPremiacoes";
import { InfoSobre } from "../user/components/generalComponents/InfoSobre";
import { TextH2Info } from "../user/components/generalComponents/TextH2Info";
import { TextH5Info } from "../user/components/generalComponents/TextH5Info";

export default function PerfilInfoHub({
  sobre,
  nome,
  cidade,
  path_logo,
  classificacao,
  premiacoes,
  nit,
}: Pick<
  Hub,
  | "sobre"
  | "nome"
  | "cidade"
  | "path_logo"
  | "classificacao"
  | "premiacoes"
  | "nit"
>) {
  return (
    <section className="container">
      <HeaderPerfil
        nome={nome}
        cidade={cidade}
        foto_path={path_logo}
        headline={"Hub"}
      />

      <section>
        <InfoSobre sobre={sobre} />
        <div className="border rounded-4 p-3 d-flex mb-4 row m-0 gap-2s">
          <div className="col-md-6 ">
            <TextH2Info children="Associado ao NIT" />
            <TextH5Info classes="ms-5 mt-2" children={nit?.nome} />
          </div>
          <div className="col-md-5">
            <TextH2Info children="Classificação" />
            <TextH5Info classes="ms-5 mt-2" children={classificacao} />
          </div>
        </div>
        <InfoPremiacoes classes="mb-4" premiacoes={premiacoes} />
      </section>
    </section>
  );
}
