import { useContext, useEffect, useState } from "react";

import { ResizeContext } from "../../hooks/useResize";
import { UrlLattes } from "../../domain/entities/UrlLattes";

export function InputLattes({
  label,
  value = "",
  onChange,
  required = false,
}: {
  label: string;
  value?: string;
  onChange: (urlLattes?: string) => void;
  required?: boolean;
}) {
  const { responsive } = useContext(ResizeContext);

  const [error, setError] = useState<string>();
  const [isChanged, setIsChanged] = useState<boolean>(false);

  useEffect(() => {
    const validateResult = UrlLattes.create({
      auth_id: "...",
      urlLattes: value ?? "",
    });

    if (validateResult.ok) {
      setError(undefined);
    } else {
      if (value || required) {
        setError(validateResult.error.message);
      } else {
        setError(undefined);
      }
    }
  }, [required, value]);

  return (
    <div className={"mb-3 has-validation"}>
      <label className="form-label fw-bolder m-0" htmlFor="iptUrlLattes">
        {label}
      </label>

      <input
        type="url"
        title="Lattes"
        className={
          `${responsive.sm ? "imputMobile" : "form-control"}` +
          (error && isChanged ? " is-invalid" : "")
        }
        id="iptUrlLattes"
        onChange={({ target: { value } }) => {
          onChange(value);
          setIsChanged(true);
        }}
        onBlur={() => {
          setIsChanged(true);
        }}
        placeholder={responsive.sm ? "Lattes" : ""}
        value={value}
      />

      <div className="invalid-feedback">{error}</div>
    </div>
  );
}
