export class PermissaoNegadaError implements Error {
  name = "permission-denied";
  message = "Premissão negada";
}

export class FirestoreErrorsFactory {
  static createErrorsValidation(error_code: FirestoreError): Error {
    switch (error_code) {
      case "permission-denied":
        return new PermissaoNegadaError();
    }
    return new Error("Erro");
  }
}

export type FirestoreError = "permission-denied";
