import { useContext, useEffect, useMemo, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";

import { InputNomeUsuario } from "../../generalComponents/InputNomeUsuario";
import {
  ContatoEmail,
  MIN_SIZE_MESSAGE,
  MIN_SIZE_MESSAGE_ERROR,
  MAX_SIZE_MESSAGE,
  MAX_SIZE_MESSAGE_ERROR,
  isInvalidContatoEmail,
} from "../../../domain/entities/ContatoEmail";
import { InputEmail } from "../../generalComponents/InputEmail";
import {
  InputTelefone,
  TypeInputTelefone,
} from "../../generalComponents/InputTelefone";
import { InputTextarea } from "../../generalComponents/InputTextarea";
import { ButtonDarkGreen } from "../../generalComponents/buttonDarkGreen/ButtonDarkGreen";
import { HandlersDatabaseContext } from "../../../globalStore/HandlersProviders/HandlersDatabaseContext";
import { CurrentUserContext } from "../../../globalStore/CurrentUserContext";
import { Result } from "../../../typings";

export function Contato() {
  const [sendResult, setSendResult] = useState<Result<void>>();
  const [contato, setContato] = useState<{
    name: string;
    email: string;
    phone?: TypeInputTelefone;
    message: string;
  }>({
    email: "",
    message: "",
    name: "",
    phone: {
      invalid: false,
      numero: "",
    },
  });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { currentUserAuth, currentUserScyggz, currentUserPhoneNumber } =
    useContext(CurrentUserContext);
  const { handlerContatoEmailSend } = useContext(HandlersDatabaseContext);

  const contatoToSend = useMemo<ContatoEmail>(
    () => ({
      email: contato.email,
      message: contato.message,
      name: contato.name,
      phone: contato.phone?.invalid ? undefined : contato.phone?.numero,
    }),
    [
      contato.email,
      contato.message,
      contato.name,
      contato.phone?.invalid,
      contato.phone?.numero,
    ]
  );

  const isValid = useMemo<boolean>(() => {
    if (
      contato.phone?.numero &&
      contato.phone?.numero.length > 0 &&
      contato.phone.invalid
    ) {
      return false;
    }

    const invalid = isInvalidContatoEmail(contatoToSend);

    return !invalid;
  }, [contato.phone?.invalid, contato.phone?.numero, contatoToSend]);

  useEffect(() => {
    const nameAuth = currentUserAuth.user_auth?.displayName;
    const nameUsuario = currentUserScyggz.user?.nome;

    if (nameUsuario) {
      setContato((contato) => ({
        ...contato,
        name: nameUsuario,
      }));
    } else if (nameAuth) {
      setContato((contato) => ({
        ...contato,
        name: nameAuth,
      }));
    }
  }, [currentUserAuth.user_auth?.displayName, currentUserScyggz.user?.nome]);

  useEffect(() => {
    const emailAuth = currentUserAuth.user_auth?.email;

    if (emailAuth) {
      setContato((contato) => ({
        ...contato,
        email: emailAuth,
      }));
    }
  }, [currentUserAuth.user_auth?.email]);

  useEffect(() => {
    const telefoneValue = currentUserPhoneNumber.userPhoneNumber?.numero;
    const telefoneUsuario = currentUserScyggz.user?.telefone;

    if (telefoneUsuario) {
      setContato((contato) => ({
        ...contato,
        phone: {
          invalid: false,
          numero: telefoneUsuario,
        },
      }));
    } else if (telefoneValue) {
      setContato((contato) => ({
        ...contato,
        phone: {
          invalid: false,
          numero: telefoneValue,
        },
      }));
    }
  }, [
    currentUserPhoneNumber.userPhoneNumber?.numero,
    currentUserScyggz.user?.telefone,
  ]);

  return (
    <div className="p-5 col-12 col-md-7 m-auto">
      {sendResult?.ok ? (
        <div className="alert alert-success" role="alert">
          Contato enviado com sucesso!
          <Link to="/" className="alert-link">
            <h5 className="m-0 mt-2">Voltar para a página inicial</h5>
          </Link>
        </div>
      ) : (
        <>
          {sendResult?.error && (
            <div className="alert alert-danger" role="alert">
              Erro no envio!
            </div>
          )}

          {!currentUserAuth.user_auth?.displayName &&
            !currentUserScyggz.user?.nome && (
              <InputNomeUsuario
                label="Nome*"
                value={contato.name}
                onChange={(name) => {
                  setContato((contato) => ({
                    ...contato,
                    name,
                  }));
                }}
              />
            )}

          {!currentUserAuth.user_auth?.email && (
            <InputEmail
              label="Email*"
              value={contato.email}
              required={true}
              onChange={(email) => {
                setContato((contato) => ({
                  ...contato,
                  email: email ?? "",
                }));
              }}
            />
          )}

          {!currentUserPhoneNumber.userPhoneNumber?.numero &&
            !currentUserScyggz.user?.telefone && (
              <InputTelefone
                label="Telefone"
                value={contato.phone}
                onChange={(phone) => {
                  setContato((contato) => ({
                    ...contato,
                    phone,
                  }));
                }}
                validate={() => undefined}
                className="mb-3"
              />
            )}

          <InputTextarea
            label="Mensagem*"
            value={contato.message}
            onChange={(message) => {
              setContato((contato) => ({
                ...contato,
                message,
              }));
            }}
            min={{
              value: MIN_SIZE_MESSAGE,
              errorMessage: MIN_SIZE_MESSAGE_ERROR,
            }}
            max={{
              value: MAX_SIZE_MESSAGE,
              errorMessage: MAX_SIZE_MESSAGE_ERROR,
            }}
            id="iptMessage"
            title="mensagem"
          />

          <ButtonDarkGreen
            disabled={!isValid || isSubmitting}
            isSubmit={true}
            className="pe-5"
            onClick={async () => {
              setIsSubmitting(true);

              if (handlerContatoEmailSend) {
                const sendResult = await handlerContatoEmailSend.send(
                  contatoToSend,
                  !!currentUserAuth.user_auth
                );

                setSendResult(sendResult);
              } else console.warn("required handlerContatoEmailSend");

              setIsSubmitting(false);
            }}
          >
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className={"me-2" + (isSubmitting ? "" : " invisible")}
            />
            Enviar
          </ButtonDarkGreen>
        </>
      )}
    </div>
  );
}
