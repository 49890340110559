import { ReactNode, useContext } from "react";

import { HandlerUpdateAceiteConexaoFirebase } from "../../gateway/HandlerUpdateAceiteConexaoFirebase";
import { FirebaseContext } from "../FirebaseContext";
import { HandlerConexaoContext } from "./HandlerConexaoContext";
import { HandlerConexaoFirebase } from "../../gateway/HandlerConexaoFirebase";

export function HandlerConexaoFirebaseProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { firestore } = useContext(FirebaseContext);

  return (
    <HandlerConexaoContext.Provider
      value={{
        handlerUpdateAceiteConexao: new HandlerUpdateAceiteConexaoFirebase(
          firestore
        ),
        handlerCreateConexao: new HandlerConexaoFirebase(firestore),
      }}
      children={children}
    />
  );
}
