import { Result } from "../../typings";

export class Sobre {
  static readonly MINIMO_LENGTH = 3;
  static readonly MAXIMO_LENGTH = 500;

  private constructor(public conteudo: string) {}

  static create(sobre: SobreInput): Result<Sobre> {
    return Sobre.validate(sobre);
  }

  public static validate({ conteudo }: Sobre): Result<Sobre> {
    conteudo = conteudo.trim();

    if (conteudo.length < Sobre.MINIMO_LENGTH) {
      return {
        ok: false,
        error: new Error("Mínimo de " + Sobre.MINIMO_LENGTH + " caracteres"),
      };
    }

    if (conteudo.length > Sobre.MAXIMO_LENGTH) {
      return {
        ok: false,
        error: new Error("Máximo de " + Sobre.MAXIMO_LENGTH + " caracteres"),
      };
    }

    return {
      ok: true,
      value: {
        conteudo,
      },
    };
  }
}

export type SobreInput = { conteudo: string };
export type SobreOutput = SobreInput;
