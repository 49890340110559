import { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { Button, Modal } from "react-bootstrap";

import {
  GrupoDePesquisaInput,
  GrupoDePesquisaOutput,
} from "../../../../../domain/entities/grupoDePesquisa";
import { TipoDeGrupoDePesquisaOutput } from "../../../../../domain/entities/tipoDeGrupoDePesquisa";
import { TextH4Info } from "./TextH4Info";
import { TextH5Info } from "./TextH5Info";
import { useResize } from "../../../../../hooks/useResize";
import { DividerInfo } from "./dividerInfo/DividerInfo";
import { Result } from "../../../../../typings";
import { TipoGrupoContext } from "../../../../../globalStore/TipoGrupoProvider/TipoGrupoContext";
import { Cientista } from "../../../../../domain/entities/Cientista";
import { InputGruposDePesquisa } from "../../../../generalComponents/InputGruposDePesquisa/InputGruposDePesquisa";

export const InfoGrupoDePesquisa = ({
  gruposDePesquisa,
  classes,
  handlerEdit,
}: {
  gruposDePesquisa?: GrupoDePesquisaOutput[];
  classes?: string;
  handlerEdit?: (
    inputEdit: GrupoDePesquisaOutput[]
  ) => Promise<Result<unknown>>;
}) => {
  const { sm } = useResize();

  const { refresh } = useContext(TipoGrupoContext);

  const [showModalAdd, setShowModalAdd] = useState<boolean>(false);
  const [carregando, setCarregando] = useState<boolean>(false);

  const [tiposGruposDePesquisa, setTiposGruposDePesquisa] = useState<
    TipoDeGrupoDePesquisaOutput[]
  >([]);

  const [gruposDePesquisaToSave, setGruposDePesquisaToSave] = useState<
    GrupoDePesquisaInput[]
  >([]);

  const [hasError, setHasError] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  useEffect(() => {
    const validateUpdate = Cientista.validateGruposDePesquisa(
      gruposDePesquisaToSave
    );

    setHasError(!validateUpdate.ok);
  }, [gruposDePesquisaToSave]);

  useEffect(() => {
    const tiposGrupos: TipoDeGrupoDePesquisaOutput[] = [];
    gruposDePesquisa?.forEach((grupo) => {
      const tipoGrupo = tiposGrupos.find((tipo) => tipo.id == grupo.tipo.id);

      if (tipoGrupo) {
        tipoGrupo.grupos.push(grupo);
      } else {
        tiposGrupos.push({
          id: grupo.tipo.id,
          nome: grupo.tipo.nome,
          aprovado: grupo.tipo.aprovado,
          grupos: [grupo],
        });
      }
    });
    setTiposGruposDePesquisa(tiposGrupos);
    setGruposDePesquisaToSave(gruposDePesquisa ?? []);
  }, [gruposDePesquisa]);

  useEffect(() => {
    if (handlerEdit && refresh) refresh();
  }, [handlerEdit, refresh]);

  return (
    <>
      {gruposDePesquisa !== undefined && (
        <div className={`${classes}`}>
          <div>
            <h5 className="text-green-strong fw-bolder">
              Grupos de Pesquisa (tipo)
            </h5>
            {handlerEdit && (
              <button
                type="button"
                className="btn btn-primary rounded-circle m-2"
                onClick={() => setShowModalAdd(true)}
              >
                <FontAwesomeIcon icon={faPencil} />
              </button>
            )}
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: sm ? "1fr" : "1fr 1fr 1fr",
            }}
          >
            {tiposGruposDePesquisa.map((tipoGrupo, i) => (
              <div key={i} className="ms-4">
                <div
                  className="d-flex flex-column g-col-md-3"
                  style={{ width: "fit-content" }}
                >
                  <TextH5Info children={tipoGrupo.nome} />
                </div>
                <div className="ms-4">
                  <TextH4Info children="Grupos de pesquisa" />
                  <ul className="d-flex flex-wrap mb-3 p-0">
                    {tipoGrupo.grupos.map((grupo, i, array) => (
                      <div key={i}>
                        <DividerInfo
                          children={
                            <TextH5Info
                              classes={"ms-2"}
                              children={grupo.nome}
                            />
                          }
                          array={array}
                          index={i}
                        />
                      </div>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      <Modal show={showModalAdd} onHide={() => setShowModalAdd(false)} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="p-5">
          <Modal.Title>Selecione o grupo de pesquisa</Modal.Title>

          <InputGruposDePesquisa
            label={""}
            value={gruposDePesquisaToSave}
            onListChange={(gruposDePesquisa) => {
              setGruposDePesquisaToSave(gruposDePesquisa);
            }}
            showTip={false}
            isEditing={setIsEditing}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            disabled={hasError || carregando || isEditing}
            onClick={async () => {
              if (handlerEdit) {
                setCarregando(true);
                const handlerEditResult = await handlerEdit(
                  gruposDePesquisaToSave
                );
                setCarregando(false);

                if (!handlerEditResult.ok) {
                  console.warn(handlerEditResult.error);
                  return;
                } else {
                  setGruposDePesquisaToSave([]);
                  setShowModalAdd(false);
                }
              }
            }}
          >
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
