import { LocalizacaoOutput } from "./localizacao";
import { AssociacaoAnjo } from "./associacaoAnjo";
import { TeseDeInvestimentoInput } from "./teseDeInvestimento";
import { Result } from "../../typings";
import { EstagioStartup } from "./EstagioStartup";
import { Premiacao } from "./premiacao";
import { Startup } from "./Startup";
import { ModeloNegocio } from "./modeloNegocio";
import { AreaEspecializacao } from "./areaEspecializacaoInvestidorAnjo";
import { SegmentoAtuacao } from "./segmentoAtuacao";
import { MecanismoDeSolucaoTecnologica } from "./mecanismoDeSolucaoTecnologica";
import { InvestidorAnjoValidator } from "./InvestidorAnjoValidator";
import { InputHandlerUpdateInvestidor } from "../usecases/interfaces/handlerUpdateInvestidor";
import { TypeHandlerCreateInvestidor } from "../usecases/interfaces/HandlerCreateInvestidor";

export class InvestidorAnjo {
  private constructor(private input: InputInvestidorAnjo) {}

  public get user_id() {
    return this.input.user_id;
  }

  public get nome() {
    return this.input.nome;
  }

  public get created_in() {
    return this.input.created_in;
  }

  public get habilitado() {
    return this.input.habilitado;
  }

  public get tese_de_investimento() {
    return this.input.tese_de_investimento;
  }

  public get associacoes_anjo() {
    return this.input.associacoes_anjo;
  }

  public get areas_de_especializacao() {
    return this.input.areas_de_especializacao;
  }

  public get interesse_startup_estagio() {
    return this.input.interesse_startup_estagio;
  }

  public get interesse_startup_localizacoes() {
    return this.input.interesse_startup_localizacoes;
  }

  public get interesse_startup_modelo_negocio() {
    return this.input.interesse_startup_modelo_negocio;
  }

  public get interesse_startup_segmento_atuacao() {
    return this.input.interesse_startup_segmento_atuacao;
  }

  public get interesse_startup_mecanismo_solucao() {
    return this.input.interesse_startup_mecanismo_solucao;
  }

  public get startups_investidas() {
    return this.input.startups_investidas;
  }

  public get premiacoes_e_certificados() {
    return this.input.premiacoes_e_certificados;
  }

  public static create(
    input: TypeHandlerCreateInvestidor
  ): Result<
    TypeHandlerCreateInvestidor,
    Partial<Record<keyof TypeHandlerCreateInvestidor, Error>>
  > {
    const validateResult = InvestidorAnjo.validateCreate(input);

    if (!validateResult.ok) {
      return validateResult;
    }

    return {
      ok: true,
      value: {
        ...input,
        created_in: new Date(),
      },
    };
  }

  public static validateCreate(
    attributes: TypeHandlerCreateInvestidor
  ): Result<null, Partial<Record<keyof InvestidorAnjo, Error>>> {
    const validator = new InvestidorAnjoValidator();
    return validator.validateCreate(attributes);
  }

  public static validateUpdate(
    attributes: Partial<InputHandlerUpdateInvestidor>
  ): Result<null, Partial<Record<keyof InputHandlerUpdateInvestidor, Error>>> {
    const validator = new InvestidorAnjoValidator();
    return validator.validateUpdate(attributes);
  }
}

export type InputInvestidorAnjo = {
  nome: string;
  user_id: string;
  areas_de_especializacao: Pick<AreaEspecializacao, "id" | "nome">[];
  tese_de_investimento: TeseDeInvestimentoInput;
  interesse_startup_estagio: EstagioStartup[];
  interesse_startup_modelo_negocio: Pick<ModeloNegocio, "id" | "nome">[];
  interesse_startup_segmento_atuacao: Pick<SegmentoAtuacao, "id" | "nome">[];
  interesse_startup_mecanismo_solucao: Pick<
    MecanismoDeSolucaoTecnologica,
    "id" | "nome"
  >[];
  created_in: Date;
} & Partial<{
  associacoes_anjo: Pick<
    AssociacaoAnjo,
    "id" | "nome" | "validado" | "cidade" | "path_logo"
  >[];
  interesse_startup_localizacoes: LocalizacaoOutput[];
  premiacoes_e_certificados: Pick<Premiacao, "nome" | "descricao">[];
  startups_investidas: Pick<Startup, "id" | "nome" | "cidade" | "path_logo">[];
  habilitado: boolean;
}>;

export type OutputInvestidorAnjo = InputInvestidorAnjo;
