import { NavLink } from "react-router-dom";

export function AbasLinks({
  links,
  activePath,
}: {
  links: { label: string; path: string }[];
  activePath: string;
}) {
  return (
    <>
      {links.map(({ label, path }) => (
        <NavLink
          key={path}
          className={
            "fs-5 fw-bold px-2 px-sm-3 px-md-4 py-2 rounded-top " +
            (path == activePath
              ? "bg-white color-spinoff"
              : "bg-body-secondary text-secondary")
          }
          to={path}
        >
          {label}
        </NavLink>
      ))}
    </>
  );
}
