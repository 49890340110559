import { createContext } from "react";

import { Conexao } from "../../domain/entities/Conexao";
import { Result } from "../../typings";

export const ConexaoEntreUsuariosContext = createContext<{
  conexaoEntreUsuariosResult: TypeConexaoEntreUsuariosContext;
  refreshConexaoEntreUsuarios: () => void;
}>({
  conexaoEntreUsuariosResult: undefined,
  refreshConexaoEntreUsuarios: () => undefined,
});

export type TypeConexaoEntreUsuariosContext =
  | Result<Readonly<Conexao>, Error>
  | undefined;
