import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";

import { Sobre } from "../../../../../domain/entities/sobre";
import { Result } from "../../../../../typings";
import { ParagrafoInfo } from "./ParagrafoInfo";
import { InputSobre } from "../../../../generalComponents/InputSobre";

export const InfoSobre = ({
  sobre,
  classes,
  canUpdate,
}: {
  sobre: string;
  classes?: string;
  canUpdate?: (inputUpdate: {
    sobre: string;
  }) => Promise<Result<{ sobre: string }>>;
}) => {
  const [valueSobre, setValueSobre] = useState<string>("");
  const [showModalEdit, setShowModalEdit] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>();
  const [carregando, setCarregando] = useState<boolean>(false);

  useEffect(() => {
    setValueSobre(sobre);
  }, [sobre]);

  useEffect(() => {
    if (canUpdate) {
      const validateUpdateResult = Sobre.validate({ conteudo: valueSobre });

      if (validateUpdateResult.ok) {
        setErrorMsg(undefined);
      } else {
        setErrorMsg(validateUpdateResult.error.message);
      }
    }
  }, [canUpdate, valueSobre]);

  return (
    <>
      <article className={`${classes ?? ""} d-flex flex-column mb-4`}>
        <h4 className="fw-bolder">Sobre</h4>
        <ParagrafoInfo
          classes={"mt-2 " + (canUpdate ? "position-relative" : "")}
          descricao={sobre}
        >
          {canUpdate && (
            <button
              type="button"
              className="btn btn-primary position-absolute top-0 start-100 translate-middle rounded-circle"
              onClick={() => setShowModalEdit(true)}
            >
              <FontAwesomeIcon icon={faPencil} />
            </button>
          )}
        </ParagrafoInfo>
      </article>

      <Modal
        show={showModalEdit}
        onHide={() => setShowModalEdit(false)}
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Modal.Title>Editar sobre</Modal.Title>

          <InputSobre
            label=""
            value={valueSobre}
            onChange={(sobre) => {
              setValueSobre(sobre);
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            disabled={!!errorMsg || carregando}
            onClick={async () => {
              if (canUpdate) {
                setCarregando(true);

                const handlerUpdateResult = await canUpdate({
                  sobre: valueSobre,
                });

                setCarregando(false);

                if (!handlerUpdateResult.ok) {
                  console.warn(handlerUpdateResult.error);
                  return;
                }
              }

              setShowModalEdit(false);
            }}
          >
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
