import { createContext } from "react";

import { OutputHub } from "../../domain/entities/Hub";
import { Result } from "../../typings";

export const PerfilHubContext = createContext<{
  perfilHubResult: TypePerfilHubContext;
}>({
  perfilHubResult: undefined,
});

export type TypePerfilHubContext = Result<OutputHub> | undefined;
