import { useContext, useEffect, useState } from "react";

import {
  ESTAGIO_STARTUP,
  EstagioStartup,
} from "../../domain/entities/EstagioStartup";
import { ResizeContext } from "../../hooks/useResize";

export function InputEstagioStartup({
  label,
  value,
  onChange,
  validate,
  className,
}: {
  label: string;
  value?: EstagioStartup;
  onChange: (estagiosStartup?: EstagioStartup) => void;
  validate: (estagiosStartup?: EstagioStartup) => Error | undefined;
  className?: string;
}) {
  const { responsive } = useContext(ResizeContext);

  const [error, setError] = useState<string>();
  const [isChanged, setIsChanged] = useState<boolean>(false);

  useEffect(() => {
    const error = validate(value);
    if (error) {
      setError(error.message);
    } else {
      setError(undefined);
    }
  }, [validate, value]);

  return (
    <div className={className}>
      <label
        className={
          "form-label fw-bolder w-100" +
          (error && isChanged ? " is-invalid" : "")
        }
      >
        Estágio da sua startup*
      </label>
      <div
        style={
          responsive.sm
            ? {
                marginLeft: "auto",
                marginRight: "auto",
                width: "fit-content",
                borderColor: "#ccc",
                borderWidth: 1,
                borderStyle: "solid",
              }
            : {
                borderColor: "#ccc",
                borderWidth: 1,
                borderStyle: "solid",
              }
        }
        className={`${
          responsive.sm ? "flex-column" : ""
        } d-flex justify-content-between flex-wrap rounded-1 p-2 `}
      >
        {ESTAGIO_STARTUP.map((estagio) => (
          <div className="form-check form-check-inline" key={estagio}>
            <input
              className="form-check-input checkboxDarkGreen"
              type="radio"
              name={label}
              value={estagio}
              checked={value == estagio}
              onChange={({ target: { checked } }) => {
                if (checked) onChange(estagio);
                setIsChanged(true);
              }}
              id={"rdb" + estagio}
            />
            <label className="form-check-label" htmlFor={"rdb" + estagio}>
              {estagio}
            </label>
          </div>
        ))}
      </div>
      <div className="invalid-feedback flex-fill">{error}</div>
    </div>
  );
}
