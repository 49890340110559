import slug from "slug";
slug.defaults.modes.pretty.replacement = "_";
slug.defaults.modes.rfc3986.replacement = "_";

import { Result } from "../../typings";
import { Cidade } from "./cidade";

export class AssociacaoAnjo {
  public path_logo?: string;
  public cidade?: Readonly<Cidade>;
  public gestores?: string[];

  private constructor(
    public id: string,
    public nome: string,
    public validado: boolean,
    gestores?: string[],
    path_logo?: string,
    cidade?: Readonly<Cidade>
  ) {
    this.path_logo = path_logo;
    this.cidade = cidade;
    this.gestores = gestores;
  }

  public static create({
    nome,
    gestores,
    path_logo,
    cidade,
  }: AssociacaoAnjoInput): Result<AssociacaoAnjo> {
    nome = nome.trim();
    if (nome.length < 2) {
      return {
        ok: false,
        error: new Error("Nome de associação anjo inválido"),
      };
    }

    return {
      ok: true,
      value: new AssociacaoAnjo(
        slug(nome),
        nome,
        false,
        gestores,
        path_logo,
        cidade
      ),
    };
  }
}

export type AssociacaoAnjoInput = {
  nome: string;
} & Partial<{
  gestores: string[];
  path_logo: string;
  cidade: Readonly<Cidade>;
}>;

export type AssociacaoAnjoOutput = AssociacaoAnjoInput & {
  id: string;
  validado: boolean;
};
