import { useContext, useEffect, useMemo, useState } from "react";
import { Button, ProgressBar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { ContainerResponsive } from "../../../generalComponents/ContainerResponsive";
import { InputPassword } from "../../../generalComponents/InputPassword";
import { UsuarioScyggzValidator } from "../../../../domain/entities/UsuarioScyggzValidator";
import { HandlersContext } from "../../../../globalStore/HandlersProviders/HandlersContext";
import { AuthErrorsFactory } from "../../../../domain/erros/AuthErrors";
import { Result } from "../../../../typings";

const urlLogin = "/login";
const urlHome = "/";

export function RedefinirSenha({ oobCode }: { oobCode: string }) {
  const navigate = useNavigate();
  const { handlerAuth } = useContext(HandlersContext);

  const [checkActionCodeResult, setCheckActionCodeResult] =
    useState<Result<{ email: string }>>();
  const [newPassword, setNewPassword] = useState<string>("");
  const [errorRequest, setErrorRequest] = useState<string>();

  const errorNewPassword = useMemo<string | undefined>(() => {
    const isInvalidPassword =
      UsuarioScyggzValidator.isInvalidPassword(newPassword);

    if (isInvalidPassword) {
      return isInvalidPassword.message;
    } else {
      return undefined;
    }
  }, [newPassword]);

  useEffect(() => {
    handlerAuth
      ?.checkActionCode(oobCode)
      .then((checkActionCodeResult) =>
        setCheckActionCodeResult(checkActionCodeResult)
      );
  }, [handlerAuth, oobCode]);

  return (
    <ContainerResponsive showLogo={false} className="col-10 col-sm-8 col-md-6">
      {checkActionCodeResult == undefined ? (
        <ProgressBar animated variant="info" now={100} className="w-100" />
      ) : (
        <>
          {checkActionCodeResult.ok ? (
            <>
              Redefinir a senha de <b>{checkActionCodeResult.value.email}</b>
              <InputPassword
                value={newPassword}
                onChange={(password) => {
                  setNewPassword(password);
                  setErrorRequest(undefined);
                }}
                className="mt-3"
              />
              <div className="d-flex justify-content-between">
                <div
                  className="text-danger flex-fill"
                  style={{ fontSize: ".85rem" }}
                >
                  {errorRequest}
                </div>

                <Button
                  variant="success"
                  disabled={!newPassword || !!errorNewPassword || !handlerAuth}
                  onClick={async () => {
                    if (handlerAuth) {
                      const confirmPasswordResetResult =
                        await handlerAuth.confirmPasswordReset(
                          oobCode,
                          newPassword
                        );

                      if (confirmPasswordResetResult.ok) {
                        const signInWithEmailAndPasswordResult =
                          await handlerAuth.signInWithEmailAndPassword(
                            checkActionCodeResult.value.email,
                            newPassword
                          );

                        if (signInWithEmailAndPasswordResult.ok) {
                          navigate(urlHome);
                        } else {
                          navigate(urlLogin);
                        }
                      } else {
                        const error = AuthErrorsFactory.formatErrorInstance(
                          confirmPasswordResetResult.error
                        );

                        setErrorRequest(error.message);
                      }
                    }
                  }}
                >
                  salvar
                </Button>
              </div>
            </>
          ) : (
            <>Operação inválida</>
          )}
        </>
      )}
    </ContainerResponsive>
  );
}
