import {
  ESTAGIO_STARTUP,
  EstagioStartup,
} from "../../../../../domain/entities/EstagioStartup";
import { TextH2Info } from "./TextH2Info";

export const InfoEstagioStartup = ({
  estagio,
}: {
  estagio: EstagioStartup;
}) => {
  return (
    <>
      {/* <>
        <TextH2Info classes="mb-5" children="Estágio da Startup" />

        <div className="d-flex justify-content-evenly position-relative">
          {ESTAGIO_STARTUP.map((estagioStartup) => (
            <div
              key={estagioStartup}
              className={
                "d-flex flex-column justify-content-center align-items-center " +
                (estagio == estagioStartup ? "" : "")
              }
            >
              <div
                className={
                  "border border-2 border-color-spinoff rounded-circle z-1 " +
                  (estagio == estagioStartup
                    ? "background-color-spinoff"
                    : "bg-secondary-subtle")
                }
                style={{
                  width: "56px",
                  height: "56px",
                }}
              />

              <span
                className={
                  "mt-2 badge text-black fw-light fs-6 " +
                  (estagio == estagioStartup ? "" : "opacity-25")
                }
                style={{
                  background: "#E5E5BF",
                }}
              >
                {estagioStartup}
              </span>
            </div>
          ))}

          <div
            className="bg-secondary-subtle position-absolute"
            style={{
              width: "65%",
              top: "27px",
              height: "3px",
            }}
          />
        </div>
      </> */}

      {/* ///////// */}

      <>
        <TextH2Info classes="mb-5" children="Estágio da Startup" />

        <table className="table table-borderless">
          <tbody>
            <tr>
              {ESTAGIO_STARTUP.map((estagioStartup, index, array) => (
                <td
                  key={estagioStartup}
                  className="position-relative justify-content-center align-items-center text-center"
                >
                  <div
                    className={
                      "position-relative d-inline-block border border-2 border-color-spinoff rounded-circle z-1 " +
                      (estagio == estagioStartup
                        ? "background-color-spinoff"
                        : "bg-secondary-subtle")
                    }
                    style={{
                      width: 60,
                      height: 60,
                    }}
                  />

                  <div
                    className="bg-secondary-subtle position-absolute p-0 m-0 w-100"
                    style={{
                      top: 30,
                      height: "3px",
                      left: index == 0 ? "50%" : "",
                      right: index == array.length - 1 ? "50%" : "",
                    }}
                  />
                </td>
              ))}
            </tr>
            <tr>
              {ESTAGIO_STARTUP.map((estagioStartup) => (
                <td
                  key={estagioStartup}
                  className="justify-content-center align-items-center text-center"
                >
                  <span
                    className={
                      "mt-2 badge text-black fw-light fs-6 " +
                      (estagio == estagioStartup ? "" : "opacity-25")
                    }
                    style={{
                      background: "#E5E5BF",
                    }}
                  >
                    {estagioStartup}
                  </span>
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </>
    </>
  );
};
