import { useContext, useMemo, useState } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";

import { ListSelectStakeholders } from "../../new/components/ListSelectStakeholders";
import { TypeSelectStakeholder } from "../../new/components/SelectStakeholder";
import { ProgramaConexao } from "../../../../../domain/entities/ProgramaConexao";
import { HandlersDatabaseContext } from "../../../../../globalStore/HandlersProviders/HandlersDatabaseContext";
import { ConviteProgramaConexao } from "../../../../../domain/entities/ConviteProgramaConexao";

export function AdicionarConviteProgramaConexao({
  programa: {
    id,
    stakeholder_type_group_a,
    stakeholder_type_group_b,
    logo_path,
    message_to_a,
    message_to_b,
    nome,
  },
  ids_membros,
  onAdd,
}: {
  programa: Pick<
    ProgramaConexao,
    | "id"
    | "stakeholder_type_group_a"
    | "stakeholder_type_group_b"
    | "logo_path"
    | "message_to_a"
    | "message_to_b"
    | "nome"
  >;
  ids_membros: string[];
  onAdd: () => void;
}) {
  const { handlerConviteProgramaConexaoCreate } = useContext(
    HandlersDatabaseContext
  );

  const [showModal, setShowModal] = useState<"a" | "b">();

  const [selectedsA, setSelectedsA] = useState<TypeSelectStakeholder[]>([]);
  const [submitingConvitesA, setSubmitingConvitesA] = useState<boolean>(false);
  const selectedsANotMembers = useMemo<TypeSelectStakeholder[]>(
    () => selectedsA.filter((selected) => !ids_membros.includes(selected.id)),
    [ids_membros, selectedsA]
  );

  const [selectedsB, setSelectedsB] = useState<TypeSelectStakeholder[]>([]);
  const [submitingConvitesB, setSubmitingConvitesB] = useState<boolean>(false);
  const selectedsBNotMembers = useMemo<TypeSelectStakeholder[]>(
    () => selectedsB.filter((selected) => !ids_membros.includes(selected.id)),
    [ids_membros, selectedsB]
  );

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle variant="secondary">convidar</Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={() => setShowModal("a")}>
            convidar para grupo A
          </Dropdown.Item>
          <Dropdown.Item onClick={() => setShowModal("b")}>
            convidar para grupo B
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <Modal
        show={showModal == "a"}
        onHide={() => {
          setShowModal(undefined);
        }}
        size="xl"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Selecionar Grupo A</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <ListSelectStakeholders
            typesStakeholders={stakeholder_type_group_a}
            selectedStakeholders={selectedsA}
            onChangeSelectedStakeholders={(selecteds) => {
              setSelectedsA(selecteds);
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            disabled={submitingConvitesA}
            onClick={async () => {
              setSubmitingConvitesA(true);

              const addResult =
                await handlerConviteProgramaConexaoCreate?.create(
                  selectedsANotMembers.map((select) => ({
                    id: ConviteProgramaConexao.idFormat({
                      alvo: {
                        id: select.id,
                      },
                      programa_conexao: {
                        id,
                      },
                    }),
                    alvo: {
                      id: select.id,
                      cidade: select.cidade,
                      nome: select.nome,
                      typesStakeholder: select.typesStakeholder,
                      avatar_path: select.avatar_path,
                      headline: select.headline,
                    },
                    programa_conexao: {
                      id,
                      logo_path,
                      message_to_a,
                      message_to_b,
                      nome,
                      stakeholder_type_group_a,
                      stakeholder_type_group_b,
                    },
                    status: undefined,
                    to_group: ["a"],
                  }))
                );

              if (addResult?.ok) {
                onAdd();
                setShowModal(undefined);
                setSelectedsA([]);
              }

              setSubmitingConvitesA(false);
            }}
          >
            enviar convites
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showModal == "b"}
        onHide={() => {
          setShowModal(undefined);
        }}
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Selecionar Grupo B</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <ListSelectStakeholders
            typesStakeholders={stakeholder_type_group_b}
            selectedStakeholders={selectedsB}
            onChangeSelectedStakeholders={(selecteds) => {
              setSelectedsB(selecteds);
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            disabled={submitingConvitesB}
            onClick={async () => {
              setSubmitingConvitesB(true);

              const addResult =
                await handlerConviteProgramaConexaoCreate?.create(
                  selectedsBNotMembers.map((select) => ({
                    id: ConviteProgramaConexao.idFormat({
                      alvo: {
                        id: select.id,
                      },
                      programa_conexao: {
                        id,
                      },
                    }),
                    alvo: {
                      id: select.id,
                      cidade: select.cidade,
                      nome: select.nome,
                      typesStakeholder: select.typesStakeholder,
                      avatar_path: select.avatar_path,
                      headline: select.headline,
                    },
                    programa_conexao: {
                      id,
                      logo_path,
                      message_to_a,
                      message_to_b,
                      nome,
                      stakeholder_type_group_a,
                      stakeholder_type_group_b,
                    },
                    status: undefined,
                    to_group: ["b"],
                  }))
                );

              if (addResult?.ok) {
                onAdd();
                setShowModal(undefined);
                setSelectedsB([]);
              }

              setSubmitingConvitesB(false);
            }}
          >
            enviar convites
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
