import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { CurrentUserContext } from "../../../../../globalStore/CurrentUserContext";
import { ConteudoCadastroInfosPessoais } from "../../components/ConteudoCadastroInfosPessoais";
import { HandlerSaveUrlLinkedin } from "../../../../../domain/usecases/interfaces/handlerSaveUrlLinkedin";
import { HandlerSaveTelefone } from "../../../../../domain/usecases/interfaces/handlerSaveTelefone";
import { HandlerCreateUsuarioScyggz } from "../../../../../domain/usecases/interfaces/handlerCreateUsuarioScyggz";
import { useIsMounted } from "../../../../../hooks/useIsMounted";
import { ContainerResponsive } from "../../../../generalComponents/ContainerResponsive";
import { getNomeFromStorage } from "../../../../../hooks/handlerNameFromStorage";
import { HandlersDatabaseContext } from "../../../../../globalStore/HandlersProviders/HandlersDatabaseContext";

export function PainelCadastroUsuarioVentureCapital({
  handlerSaveUrlLinkedin,
  handlerSaveTelefone,
  handlerCreateUsuarioScyggz,
}: {
  handlerSaveUrlLinkedin: HandlerSaveUrlLinkedin;
  handlerSaveTelefone: HandlerSaveTelefone;
  handlerCreateUsuarioScyggz: HandlerCreateUsuarioScyggz;
}) {
  const navigate = useNavigate();
  const isMounted = useIsMounted();

  const { handlerAceiteGet } = useContext(HandlersDatabaseContext);
  const { currentUserAuth } = useContext(CurrentUserContext);

  const [dataAceite, setDataAceite] = useState<Date>(new Date());

  useEffect(() => {
    if (handlerAceiteGet)
      handlerAceiteGet.get("vc").then((handlerAceiteResult) => {
        if (handlerAceiteResult.ok) {
          if (handlerAceiteResult.value) {
            if (isMounted) setDataAceite(handlerAceiteResult.value);
          }
        }
      });
  }, [handlerAceiteGet, isMounted]);

  const paginacaoVentureCapital = {
    start: 3,
    end: 7,
  };

  return (
    <section
      data-testid="painel-cadastro-usuario-ventureCapital"
      className="h-100"
    >
      <ContainerResponsive
        backUrlMobile="/cadastro/venture_capital/1"
        paginacao={paginacaoVentureCapital}
        textoMarcaDagua="Venture Capital"
        showLogo={true}
      >
        <ConteudoCadastroInfosPessoais
          inputPainelCadastroInfosPessoais={{
            auth_id: currentUserAuth.user_auth?.auth_id ?? "",
            nome:
              currentUserAuth.user_auth?.displayName ??
              getNomeFromStorage() ??
              "",
            aceite_termo_de_servico: dataAceite,
            aceite_politica_de_privacidade: dataAceite,
            phoneNumber: undefined,
            cidade: undefined,
            urlLinkedin: undefined,
            headline: undefined,
          }}
          labelHeadline={"Seu cargo/função na Venture Capital"}
          handlerSaveUrlLinkedin={handlerSaveUrlLinkedin}
          handlerSaveTelefone={handlerSaveTelefone}
          handlerCreateUsuarioScyggz={handlerCreateUsuarioScyggz}
          onCadastro={function () {
            navigate("/cadastro/venture_capital/4");
          }}
        />
      </ContainerResponsive>
    </section>
  );
}
