export function FileSelect(
  {
    label,
    value,
    erro,
    onBlur,
    onChange,
    mimeType,
    accept,
    id,
    className
  }:
    {
      label: string,
      value: File | undefined,
      onChange: (file: File | null) => void,
      erro?: string,
      onBlur?: React.FocusEventHandler<HTMLInputElement>,
      mimeType: RegExp,
      accept?: string,
      id?: string,
      className?: string,
    }) {

  return <div className={className}>
    <label htmlFor={id} className="form-label d-block">
      {label}
    </label>

    <div
      className={
        "input-group" +
        (erro
          ? " is-invalid"
          : "")
      }
    >
      {
        value ?
          <input
            type="text"
            disabled
            className={
              "form-control"
            }
            id={id}
            onChange={(e) => {
              const { files } = e.target;

              let fileToSave: File | null = null;

              if (files)
                for (let i = 0; i < files.length; i++) {
                  const file = files[i];

                  if (!file.type.match(mimeType)) {
                    continue;
                  }
                  fileToSave = file;

                  break;
                }

              setTimeout(async () => {

                if (onChange)
                  onChange(fileToSave)
              }, 0);
            }}
            value={value.name}
          />
          :
          <input
            type="file"
            className={
              "form-control" +
              (erro
                ? " is-invalid"
                : "")
            }
            id={id}
            accept={accept}
            onBlur={onBlur}
            onChange={(e) => {
              const { files } = e.target;

              let fileToSave: File | null = null;

              if (files)
                for (let i = 0; i < files.length; i++) {
                  const file = files[i];

                  if (!file.type.match(mimeType)) {
                    continue;
                  }
                  fileToSave = file;

                  break;
                }

              setTimeout(async () => {

                if (onChange)
                  onChange(fileToSave)
              }, 0);
            }}
            value={""}
          />
      }

      {value && (
        <button
          className="btn btn-outline-secondary"
          type="button"
          onClick={() => {

            onChange(null)
          }}
        >
          X
        </button>
      )}
    </div>

    <div className="invalid-feedback flex-fill">
      {erro}
    </div>
  </div>
}