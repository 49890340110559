import { ReactNode } from "react";

import { HandlersContext } from "./HandlersContext";
import { HandlersDatabaseContext } from "./HandlersDatabaseContext";
import { HandlersFirebaseProvider } from "./HandlersFirebaseProvider";
import { HandlersLocationContext } from "./HandlersLocationContext";
import { HandlersOSMProvider } from "./HandlersOSMProvider";
import { HandlersStorageContext } from "./HandlersStorageContext";
import { HandlersStorageFirebaseContext } from "./HandlersStorageFirebaseContext";

export function HandlersFirebaseAndOSMProvider({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <HandlersFirebaseProvider>
      <HandlersOSMProvider>
        <HandlersStorageFirebaseContext>
          <HandlersDatabaseContext.Consumer
            children={(handlersDatabase) => (
              <HandlersLocationContext.Consumer
                children={(handlersLocation) => (
                  <HandlersStorageContext.Consumer
                    children={(handlersStorage) => (
                      <HandlersContext.Provider
                        value={{
                          ...handlersDatabase,
                          ...handlersLocation,
                          ...handlersStorage,
                        }}
                        children={children}
                      />
                    )}
                  />
                )}
              />
            )}
          />
        </HandlersStorageFirebaseContext>
      </HandlersOSMProvider>
    </HandlersFirebaseProvider>
  );
}
