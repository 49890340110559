import { useContext } from "react";
import { ProgressBar } from "react-bootstrap";

import { PerfilHubContext } from "../../../globalStore/perfilHub/PerfilHubContext";
import PerfilInfoHub from "./PerfilInfoHub";

export const PerfilInfoHubFromContext = () => {
  const { perfilHubResult } = useContext(PerfilHubContext);

  if (!perfilHubResult)
    return (
      <ProgressBar animated variant="info" now={100} className="w-100 m-1" />
    );

  return perfilHubResult.ok ? (
    <PerfilInfoHub
      sobre={perfilHubResult.value.sobre}
      cidade={perfilHubResult.value.cidade}
      nome={perfilHubResult.value.nome}
      path_logo={perfilHubResult.value.path_logo}
      classificacao={perfilHubResult.value.classificacao}
      nit={perfilHubResult.value.nit}
      premiacoes={perfilHubResult.value.premiacoes}
    />
  ) : (
    <>não encontrado</>
  );
};
