import { ref, getDownloadURL, FirebaseStorage } from "firebase/storage";

import { Result } from "../typings";
import { HandlerGetURLFromPath } from "../domain/usecases/interfaces/HandlerGetURLFromPath";

export class HandlerGetURLFromPathStorage implements HandlerGetURLFromPath {
  constructor(private firebaseStorage: FirebaseStorage) {}

  getURL(path: string): Promise<Result<string>> {
    return new Promise((resolve) => {
      const pathReference = ref(this.firebaseStorage, "/" + path);
      getDownloadURL(pathReference)
        .then((url) =>
          resolve({
            ok: true,
            value: url,
          })
        )
        .catch((error) =>
          resolve({
            ok: false,
            error,
          })
        );
    });
  }
}
