import { Firestore, collection, getDocs } from "@firebase/firestore";

import { Result } from "../typings";
import { HandlerGetSegmentoAtuacao } from "../domain/usecases/interfaces/handlerGetSegmentoAtuacao";
import { SegmentoAtuacaoOutput } from "../domain/entities/segmentoAtuacao";

export class HandlerGetSegmentoAtuacaoFirebase
  implements HandlerGetSegmentoAtuacao
{
  constructor(private firestore: Firestore) {}

  getAllSegmentoAtuacao(): Promise<Result<SegmentoAtuacaoOutput[], Error>> {
    return new Promise((resolve) => {
      getDocs(collection(this.firestore, "segmentos_startup"))
        .then((querySnapshot) => {
          const segmentos: SegmentoAtuacaoOutput[] = querySnapshot.docs.map(
            (docSnapshot) => {
              const segmento = docSnapshot.data() as SegmentoAtuacaoOutput;
              segmento.id = docSnapshot.id;
              return segmento;
            }
          );

          resolve({
            ok: true,
            value: segmentos,
          });
        })
        .catch((error) =>
          resolve({
            ok: false,
            error,
          })
        );
    });
  }
}
