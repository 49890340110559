import { useContext } from "react";

import {
  PERFIL,
  TIPO_PERFIL_MM,
  TipoPerfilMM,
} from "../../domain/entities/TipoPerfilMM";
import { ResizeContext } from "../../hooks/useResize";

export function InputTipoPerfilMM({
  label,
  value,
  onChange,
  className,
}: {
  label: string;
  value: TipoPerfilMM;
  onChange: (tiposFiltroMM: TipoPerfilMM) => void;
  className?: string;
}) {
  const { responsive } = useContext(ResizeContext);

  return (
    <div className={className}>
      <label className={"form-label fw-bolder m-0"}>{label}</label>
      <div
        style={
          responsive.sm
            ? {
                marginLeft: "auto",
                marginRight: "auto",
                width: "fit-content",
                borderColor: "#ccc",
                borderWidth: 1,
                borderStyle: "solid",
              }
            : {
                borderColor: "#ccc",
                borderWidth: 1,
                borderStyle: "solid",
              }
        }
        className={`${
          responsive.sm ? "flex-column" : ""
        } d-flex justify-content-between flex-wrap rounded-1 p-1 `}
      >
        {TIPO_PERFIL_MM.map((tipoPerfilMM) => (
          <div className="form-check form-check-inline" key={tipoPerfilMM}>
            <input
              className="form-check-input checkboxDarkGreen"
              type="radio"
              name={label}
              value={tipoPerfilMM}
              checked={value == tipoPerfilMM}
              onChange={({ target: { checked } }) => {
                if (checked) onChange(tipoPerfilMM);
              }}
              id={"rdb" + tipoPerfilMM}
            />
            <label className="form-check-label" htmlFor={"rdb" + tipoPerfilMM}>
              {tipoPerfilMM == PERFIL ? "Perfil" : "Organização"}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}
