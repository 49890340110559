import { Firestore, doc, setDoc } from "firebase/firestore";

import {
  HandlerCreateInvestidor,
  TypeHandlerCreateInvestidor,
} from "../domain/usecases/interfaces/HandlerCreateInvestidor";
import { Result } from "../typings";
import {
  AssociacaoAnjo,
  AssociacaoAnjoOutput,
} from "../domain/entities/associacaoAnjo";
import { InvestidorAnjo } from "../domain/entities/InvestidorAnjo";
import { HandlerCreateAssociacaoAnjoFirebase } from "./HandlerCreateAssociacaoAnjoFirebase";

export class HandlerCreateInvestidorFirebase
  implements HandlerCreateInvestidor
{
  constructor(private firestore: Firestore) {}

  async createInvestidor(
    inputInvestidor: TypeHandlerCreateInvestidor
  ): Promise<
    Result<
      TypeHandlerCreateInvestidor,
      Partial<Record<keyof TypeHandlerCreateInvestidor, Error>> | Error
    >
  > {
    const associacoes_anjo: Pick<AssociacaoAnjo, "id" | "nome" | "validado">[] =
      [];
    const newsAssociacoesAnjo: string[] = [];

    inputInvestidor.associacoes_anjo?.forEach((associacao_anjo) => {
      if (associacao_anjo.__isNew__) {
        newsAssociacoesAnjo.push(associacao_anjo.label);
      } else {
        associacoes_anjo.push({
          id: associacao_anjo.id ?? associacao_anjo.value,
          nome: associacao_anjo.nome ?? associacao_anjo.label,
          validado: !!associacao_anjo.validado,
        });
      }
    });

    if (newsAssociacoesAnjo.length > 0) {
      const handlerCreateAssociacaoAnjoFirebase =
        new HandlerCreateAssociacaoAnjoFirebase(this.firestore);

      const allPromisesCreateAssociacao: Promise<
        Result<AssociacaoAnjoOutput, Error>
      >[] = newsAssociacoesAnjo.map((newAssociacaoAnjo) => {
        return handlerCreateAssociacaoAnjoFirebase.createAssociacaoAnjo({
          nome: newAssociacaoAnjo,
          gestores: [inputInvestidor.user_id],
        });
      });

      const allCreateAssociacaoResults = await Promise.all(
        allPromisesCreateAssociacao
      );

      inputInvestidor.associacoes_anjo = associacoes_anjo;

      allCreateAssociacaoResults.forEach((createdAssociacao) => {
        if (createdAssociacao.ok) {
          inputInvestidor.associacoes_anjo =
            inputInvestidor.associacoes_anjo ?? [];

          inputInvestidor.associacoes_anjo.push(createdAssociacao.value);
        }
      });
    }

    const investidorAnjoInputEntityResult =
      InvestidorAnjo.create(inputInvestidor);

    if (!investidorAnjoInputEntityResult.ok) {
      return investidorAnjoInputEntityResult;
    }

    const docInvestidor = doc(
      this.firestore,
      "usuarios",
      inputInvestidor.user_id,
      "perfis/investidor"
    );

    return new Promise((resolve) => {
      setDoc(docInvestidor, inputInvestidor)
        .then(() =>
          resolve({
            ok: true,
            value: {
              ...inputInvestidor,
              created_in: new Date(),
            },
          })
        )
        .catch((error) =>
          resolve({
            ok: false,
            error,
          })
        );
    });
  }
}
