import { Button, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";

import { InputSobre } from "../../../generalComponents/InputSobre";
import {
  InputHandlerUpdateMentorSemId,
  TypeReturnHandlerUpdateMentor,
} from "../../../../domain/usecases/interfaces/handlerUpdateMentor";
import { Mentor, MentorOutput } from "../../../../domain/entities/Mentor";
import { InputHorasMensal } from "../../../generalComponents/InputHorasMensal";
import { InputPremiacoes } from "../../../generalComponents/InputPremiacoes/InputPremiacoes";
import { InputModelosNegocio } from "../../../generalComponents/InputModelosNegocio";
import { MentorValidator } from "../../../../domain/entities/MentorValidator";
import { InputEstagiosStartup } from "../../../generalComponents/InputEstagiosStartup";
import { InputLocais } from "../../../generalComponents/InputLocais";
import { InputSegmentosAtuacao } from "../../../generalComponents/InputSegmentosAtuacao";
import { InputMecanismosSolucao } from "../../../generalComponents/InputMecanismosSolucao";

export function ModalEditMentor({
  initialValue,
  show,
  onShowChange,
  handlerUpdateMentor,
  onUpdate,
}: {
  initialValue: InputHandlerUpdateMentorSemId;
  show: boolean;
  onShowChange: (show: boolean) => void;
  handlerUpdateMentor: (
    input: InputHandlerUpdateMentorSemId
  ) => TypeReturnHandlerUpdateMentor;
  onUpdate: () => void;
}) {
  const [mentorToEdit, setMentorToEdit] =
    useState<InputHandlerUpdateMentorSemId>({});

  const [error, setError] = useState<
    Error | Partial<Record<keyof MentorOutput, Error>>
  >();
  const [carregando, setCarregando] = useState<boolean>(false);
  const [isEditingPremiacoes, setIsEditingPremiacoes] =
    useState<boolean>(false);

  useEffect(() => {
    setMentorToEdit(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const mentorValidateResult = Mentor.validateUpdate(mentorToEdit);
    if (mentorValidateResult.ok) {
      setError(undefined);
    } else {
      setError(mentorValidateResult.error);
    }
  }, [mentorToEdit]);

  return (
    <Modal
      show={show}
      onHide={() => onShowChange(false)}
      centered
      scrollable={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>Editar mentor</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputSobre
          label="Sobre"
          value={mentorToEdit?.sobre_o_mentor ?? ""}
          onChange={(sobre_o_mentor) => {
            setMentorToEdit({ ...mentorToEdit, sobre_o_mentor });
          }}
        />

        <InputHorasMensal
          label="Disponibilidade mensal (horas)"
          value={mentorToEdit.horas_disponibilidade_mensal ?? 0}
          onChange={(horas_disponibilidade_mensal) => {
            setMentorToEdit({ ...mentorToEdit, horas_disponibilidade_mensal });
          }}
        />

        <h6 className="fw-bolder">Startups com interesse em mentoria</h6>
        <div className="border rounded-4 mb-4 mt-2 p-2 row mx-0 gap-3">
          <InputEstagiosStartup
            label="Estágio da startup de interesse*"
            value={mentorToEdit.interesse_startup_estagio ?? []}
            onChange={(interesse_startup_estagio) => {
              setMentorToEdit({
                ...mentorToEdit,
                interesse_startup_estagio,
              });
            }}
            validate={MentorValidator.validateInteresseStartupEstagio}
          />

          <InputLocais
            label="Em que localidades as Startups devem estar"
            value={mentorToEdit.interesse_startup_localizacoes ?? []}
            onChange={(interesse_startup_localizacoes) => {
              setMentorToEdit({
                ...mentorToEdit,
                interesse_startup_localizacoes,
              });
            }}
            validate={MentorValidator.isInvalidLocais}
          />

          <InputModelosNegocio
            label="Modelos de negócio*"
            value={mentorToEdit.interesse_startup_modelo_negocio ?? []}
            onChange={(interesse_startup_modelo_negocio) => {
              setMentorToEdit({
                ...mentorToEdit,
                interesse_startup_modelo_negocio,
              });
            }}
            validate={MentorValidator.validateInteresseStartupModeloNegocio}
          />

          <InputSegmentosAtuacao
            label="Que atuam nos seguintes segmentos*"
            value={mentorToEdit.interesse_startup_segmento_atuacao ?? []}
            onChange={(interesse_startup_segmento_atuacao) => {
              setMentorToEdit({
                ...mentorToEdit,
                interesse_startup_segmento_atuacao,
              });
            }}
            validate={MentorValidator.validateInteresseStartupSegmentoAtuacao}
          />

          <InputMecanismosSolucao
            label="Que utilizam os seguintes mecanismos de solução tecnológica*"
            value={mentorToEdit.interesse_startup_mecanismo_solucao ?? []}
            onChange={(interesse_startup_mecanismo_solucao) => {
              setMentorToEdit({
                ...mentorToEdit,
                interesse_startup_mecanismo_solucao,
              });
            }}
            validate={(mecanismosDeSolucao) => {
              const result =
                MentorValidator.validateInteresseStartupMecanismoSolucao(
                  mecanismosDeSolucao
                );
              if (!result.ok) return result.error;
            }}
          />
        </div>

        <InputPremiacoes
          label="Premiações"
          value={mentorToEdit.premiacoes ?? []}
          onChange={(premiacoes) => {
            setMentorToEdit({ ...mentorToEdit, premiacoes });
          }}
          showTip={false}
          isEditing={setIsEditingPremiacoes}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="success"
          disabled={!!error || carregando || isEditingPremiacoes}
          onClick={async () => {
            setCarregando(true);

            const handlerUpdateResult = await handlerUpdateMentor(mentorToEdit);

            setCarregando(false);

            if (!handlerUpdateResult.ok) {
              console.warn(handlerUpdateResult.error);

              setError(handlerUpdateResult.error);

              return;
            } else {
              onUpdate();
            }

            onShowChange(false);
          }}
        >
          Salvar mentor
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
