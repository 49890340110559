import { useNavigate } from "react-router";

import { ConteudoCadastro } from "../../components/ConteudoCadastro";
import { ContainerResponsive } from "../../../../generalComponents/ContainerResponsive";
import { OnEnterPrevent } from "../../../../generalComponents/OnEnterPrevent";

export function PainelCadastroAuthCientista() {
  const navigate = useNavigate();

  const paginacaoElegibilidadeCientista = {
    start: 2,
    end: 5,
  };

  return (
    <ContainerResponsive
      backUrlMobile="/cadastro/cientista/1"
      paginacao={paginacaoElegibilidadeCientista}
      showLogo={true}
    >
      <OnEnterPrevent>
        <ConteudoCadastro
          onRedirect={() => {
            navigate("/cadastro/cientista/3");
          }}
          onCadastro={() => {
            navigate("/cadastro/cientista/3");
          }}
        />
      </OnEnterPrevent>
    </ContainerResponsive>
  );
}
