import { createContext } from "react";

import { Conexao } from "../../domain/entities/Conexao";
import { Result } from "../../typings";

export const ConexoesPendentesRecebidasContext = createContext<{
  conexoesPendentesRecebidasResult: TypeConexoesPendentesRecebidasContext;
  refreshConexoesPendentesRecebidas: () => void;
}>({
  conexoesPendentesRecebidasResult: undefined,
  refreshConexoesPendentesRecebidas: () => undefined,
});

export type TypeConexoesPendentesRecebidasContext =
  | Result<Readonly<Conexao>[]>
  | undefined;
