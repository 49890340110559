import { useContext, useEffect, useState } from "react";

import { UrlLinkedin } from "../../domain/entities/urlLinkedin";
import { ResizeContext } from "../../hooks/useResize";

export function InputLinkedin({
  label,
  value = "",
  onChange,
  required = false,
}: {
  label: string;
  value?: string;
  onChange: (urlLinkedin?: string) => void;
  required?: boolean;
}) {
  const { responsive } = useContext(ResizeContext);

  const [error, setError] = useState<string>();
  const [isChanged, setIsChanged] = useState<boolean>(false);

  useEffect(() => {
    const validateResult = UrlLinkedin.create({
      auth_id: "...",
      urlLinkedin: value ?? "",
    });

    if (validateResult.ok) {
      setError(undefined);
    } else {
      if (value || required) {
        setError(validateResult.error.message);
      } else {
        setError(undefined);
      }
    }
  }, [required, value]);

  return (
    <div className={"mb-3 has-validation"}>
      <label className="form-label fw-bolder m-0" htmlFor="iptUrlLinkedin">
        {label}
      </label>

      <input
        type="url"
        title="Linkedin"
        className={
          `${responsive.sm ? "imputMobile" : "form-control"}` +
          (error && isChanged ? " is-invalid" : "")
        }
        id="iptUrlLinkedin"
        onChange={({ target: { value } }) => {
          console.log("value", value)
          if(value === "http://linkedin.com" || 
              value === "https://linkedin.com" ||
              value === "http://linkedin.com/" ||
              value === "https://linkedin.com/") {
            value = "";
          } else if (!value.startsWith("http://linkedin.com") && 
              !value.startsWith("https://linkedin.com") && 
              !value.startsWith("http://www.linkedin.com") &&
              !value.startsWith("https://www.linkedin.com")) {
            value = "https://linkedin.com/" + value;
          }

          onChange(value);
          setIsChanged(true);
        }}
        onBlur={() => {
          setIsChanged(true);
        }}
        placeholder={responsive.sm ? "Linkedin" : ""}
        value={value}
      />

      <div className="invalid-feedback">{error}</div>
    </div>
  );
}
