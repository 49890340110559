import { useMemo, useState } from "react";

import { TeseDeInvestimento } from "../../domain/entities/teseDeInvestimento";

export function InputTeseInvestimento({
  label,
  value,
  onChange,
  className,
}: {
  label: string;
  value: string;
  onChange: (teseInvestimento: string) => void;
  className?: string;
}) {
  const [isChanged, setIsChanged] = useState<boolean>(false);

  const error = useMemo<string | undefined>(() => {
    const isInvalid = TeseDeInvestimento.validate({
      conteudo: value,
    });

    if (isInvalid.ok) {
      return undefined;
    } else {
      return isInvalid.error.message;
    }
  }, [value]);

  return (
    <div className={"has-validation " + (className ?? "")}>
      <label className="form-label fw-bolder m-0" htmlFor="iptTeseInvestimento">
        {label}
      </label>

      <textarea
        className={"form-control" + (error && isChanged ? " is-invalid" : "")}
        onChange={({ target: { value } }) => {
          onChange(value);
          setIsChanged(true);
        }}
        onBlur={() => setIsChanged(true)}
        rows={7}
        id="iptTeseInvestimento"
        value={value}
      />

      <div className={"d-flex justify-content-between"}>
        <div className="text-danger flex-fill">{error}</div>
        <span className={"text-black-50 fw-lighter"}>
          {value.trim().length} / {TeseDeInvestimento.MAXIMO_LENGTH}
        </span>
      </div>
    </div>
  );
}
