import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { ProgressBar } from "react-bootstrap";

import { ProgramaConexao } from "../../../../domain/entities/ProgramaConexao";
import { HandlersDatabaseContext } from "../../../../globalStore/HandlersProviders/HandlersDatabaseContext";
import { Result } from "../../../../typings";
import { HeaderProgramaDeConexao } from "./components/HeaderProgramaDeConexao";
import HTMLHead from "../../../generalComponents/HTMLHead";
import { CurrentUserContext } from "../../../../globalStore/CurrentUserContext";
import { BodyProgramaDeConexao } from "./components/BodyProgramaDeConexao";

export function ProgramaDeConexao() {
  const { id_programa } = useParams();

  const { currentUserAuth } = useContext(CurrentUserContext);
  const { handlerProgramaConexaoGetByIds } = useContext(
    HandlersDatabaseContext
  );
  const [programaDeConexaoResult, setProgramaDeConexaoResult] =
    useState<Result<ProgramaConexao>>();

  const isGestor = useMemo<boolean>(
    () =>
      !!currentUserAuth.user_auth?.auth_id &&
      !!programaDeConexaoResult?.ok &&
      programaDeConexaoResult.value.gestores.includes(
        currentUserAuth.user_auth.auth_id
      ),
    [currentUserAuth.user_auth?.auth_id, programaDeConexaoResult]
  );

  const refresh = useCallback(() => {
    if (id_programa)
      handlerProgramaConexaoGetByIds?.getByIds([id_programa]).then((result) => {
        if (result.ok) {
          if (result.value[0]) {
            setProgramaDeConexaoResult({
              ok: true,
              value: result.value[0],
            });
          } else {
            setProgramaDeConexaoResult({
              ok: false,
              error: new Error("programa não encontrado"),
            });
          }
        } else {
          setProgramaDeConexaoResult(result);
        }
      });
  }, [handlerProgramaConexaoGetByIds, id_programa]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return (
    <>
      {programaDeConexaoResult ? (
        <>
          {programaDeConexaoResult.ok ? (
            <>
              <HTMLHead
                title={
                  "Programa de conexão - " + programaDeConexaoResult.value.nome
                }
                description={""}
              />

              <div className="d-flex p-3 gap-3 flex-wrap">
                <HeaderProgramaDeConexao
                  programa={programaDeConexaoResult.value}
                  isGestor={isGestor}
                  className="col-12"
                  refresh={refresh}
                />

                <BodyProgramaDeConexao
                  programa={programaDeConexaoResult.value}
                  isGestor={isGestor}
                />
              </div>
            </>
          ) : (
            <>erro</>
          )}
        </>
      ) : (
        <ProgressBar animated variant="info" now={100} className="w-100" />
      )}
    </>
  );
}
