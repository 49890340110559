import { Pais } from "./pais";

export class Estado {
  private constructor(public estado: string, public pais: Pais) {}

  public static create({ estado, pais, paisCode }: EstadoInput): Estado | null {
    const isValidEstado: boolean = estado.length > 1;
    if (!isValidEstado) return null;

    const paisResult = Pais.create({ pais, paisCode });
    if (paisResult == null) {
      return null;
    }

    return new Estado(estado, paisResult);
  }
}

export type EstadoInput = {
  estado: string;
  pais: string;
  paisCode: string;
};
export type EstadoOutput = EstadoInput;

export type EstadoOutputWithTipo = EstadoOutput & { tipo: "estado" };
