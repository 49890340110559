/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Yup from "yup";
import { Formik } from "formik";
import { useContext, useEffect, useState } from "react";

import { ButtonDarkGreen } from "../../../../generalComponents/buttonDarkGreen/ButtonDarkGreen";
import { CidadeOutputWithTipo } from "../../../../../domain/entities/cidade";
import { ResizeContext } from "../../../../../hooks/useResize";
import { InputPremiacoes } from "../../../../generalComponents/InputPremiacoes/InputPremiacoes";
import {
  Premiacao,
  PremiacaoOutput,
} from "../../../../../domain/entities/premiacao";
import { ButtonLightGreen } from "../../../../generalComponents/buttonLightGreen/ButtonLightGreen";
import { CurrentUserContext } from "../../../../../globalStore/CurrentUserContext";
import {
  CacheStartup,
  HandlerCacheStartup,
} from "../../../../../domain/usecases/interfaces/HandleCacheStartup";
import { InputCidade } from "../../../../generalComponents/InputCidade";
import { UsuarioScyggzValidator } from "../../../../../domain/entities/UsuarioScyggzValidator";
import {
  InputTelefone,
  TypeInputTelefone,
} from "../../../../generalComponents/InputTelefone";
import { InputLinkedin } from "../../../../generalComponents/InputLinkedin";
import { UrlLinkedin } from "../../../../../domain/entities/urlLinkedin";
import { InputTextarea } from "../../../../generalComponents/InputTextarea";
import { Startup } from "../../../../../domain/entities/Startup";

export const ConteudoSobreStartup = ({
  handlerCacheStartup,
  onBack,
  onNext,
}: {
  handlerCacheStartup: HandlerCacheStartup;
  onNext: () => void;
  onBack: () => void;
}) => {
  const { responsive } = useContext(ResizeContext);

  const { currentUserScyggz } = useContext(CurrentUserContext);

  const [parteDoNome, setParteDoNome] = useState("");

  const [cacheStartup, setCacheStartup] = useState<CacheStartup>({});
  const [isEditingPremiacoes, setIsEditingPremiacoes] =
    useState<boolean>(false);

  useEffect(() => {
    handlerCacheStartup.get().then((cacheStartup) => {
      if (cacheStartup.ok) {
        setCacheStartup(cacheStartup.value);
      }
    });
  }, [handlerCacheStartup]);

  useEffect(() => {
    if (currentUserScyggz.user) {
      const parteDoNome: string =
        currentUserScyggz.user.nome.split(" ").at(0) ??
        currentUserScyggz.user.nome;

      setParteDoNome(parteDoNome);
    }
  }, [currentUserScyggz]);

  return (
    <div style={!responsive.sm ? { padding: "0 100px" } : {}}>
      <Formik<FormikValues>
        initialValues={{
          email: cacheStartup?.email ?? "",
          ano_fundacao: cacheStartup?.ano_fundacao ?? 0,
          nome_startup: cacheStartup?.nome ?? "",
          sobre: cacheStartup?.sobre ?? "",
          website: cacheStartup?.website ?? "",
          linkedin: cacheStartup?.linkedin ?? "",
          telefone: cacheStartup?.telefone
            ? {
                numero: cacheStartup.telefone,
                invalid: false,
              }
            : undefined,
          premiacoes: cacheStartup?.premiacoes ?? [],
          cidade: cacheStartup.cidade
            ? {
                cidade: cacheStartup.cidade.cidade,
                estado: cacheStartup.cidade.estado,
                pais: cacheStartup.cidade.pais,
                paisCode: cacheStartup.cidade.paisCode,
                tipo: "cidade",
              }
            : undefined,
        }}
        validationSchema={validator}
        validateOnMount={true}
        validateOnChange={true}
        validateOnBlur={true}
        enableReinitialize={true}
        onSubmit={async (values) => {
          const cacheStartupSaveResult = await handlerCacheStartup.save({
            nome: values.nome_startup,
            sobre: values.sobre,
            ano_fundacao: values.ano_fundacao,
            cidade: values.cidade
              ? {
                  cidade: values.cidade.cidade,
                  estado: values.cidade.estado,
                  pais: values.cidade.pais,
                  paisCode: values.cidade.paisCode,
                }
              : undefined,
            email: values.email,
            linkedin: values.linkedin,
            premiacoes: values.premiacoes,
            telefone: values.telefone?.numero,
            website: values.website,
          });

          if (cacheStartupSaveResult.ok) onNext();
        }}
      >
        {({
          isValid,
          isSubmitting,
          handleSubmit,
          setFieldValue,
          handleChange,
          handleBlur,
          errors,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit} className="pb-2">
            <h4 className="card-title text-center mb-3 fw-bold">
              <span>{parteDoNome && `${parteDoNome},`}</span>
              <span className="h5 fw-bold">
                {} por favor, fale sobre sua Startup.
              </span>
            </h4>

            <InputTextarea
              label="Sobre a Startup*:"
              value={values.sobre}
              onChange={(sobre) => {
                setFieldValue("sobre", sobre);
              }}
              id="iptSobre"
              title="sobre"
              min={{
                value: Startup.SOBRE_MINIMO_LENGTH,
                errorMessage: Startup.SOBRE_MINIMO_MESSAGE,
              }}
              max={{
                value: Startup.SOBRE_MAXIMO_LENGTH,
                errorMessage: Startup.SOBRE_MAXIMO_MESSAGE,
              }}
            />

            <div className="mb-4">
              <label htmlFor="iptNome" className="form-label">
                Nome da Startup*
              </label>
              <input
                type="text"
                className={
                  `${responsive.sm ? "imputMobile" : "form-control"}` +
                  (errors.nome_startup && touched.nome_startup
                    ? " is-invalid"
                    : "")
                }
                id="iptNome"
                name="nome_startup"
                title="nome"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.nome_startup}
              />
              <div className="invalid-feedback flex-fill">
                {errors.nome_startup}
              </div>
            </div>

            <div className="mb-4">
              <label htmlFor="iptFundacao" className="form-label">
                Ano de fundação*
              </label>
              <input
                type="number"
                className={
                  `${responsive.sm ? "imputMobile" : "form-control"}` +
                  (errors.ano_fundacao && touched.ano_fundacao
                    ? " is-invalid"
                    : "")
                }
                id="iptFundacao"
                name="ano_fundacao"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.ano_fundacao > 0 ? values.ano_fundacao : ""}
                title="ano de fundação"
                min={1}
              />
              <div className="invalid-feedback flex-fill">
                {errors.ano_fundacao}
              </div>
            </div>

            <div className="mb-4">
              <InputCidade
                value={values.cidade}
                label="Em qual cidade?*"
                onChange={(cidade) => {
                  setFieldValue("cidade", cidade);
                }}
                validate={UsuarioScyggzValidator.isInvalidCidade}
              />
            </div>

            <div className="mb-4">
              <InputTelefone
                label={!responsive.sm ? "Telefone" : ""}
                value={values.telefone}
                onChange={(tel) => {
                  setFieldValue("telefone", tel);
                }}
                validate={() => undefined}
              />
            </div>

            <div className="mb-4">
              <label htmlFor="iptWebsite" className="form-label">
                Website
              </label>
              <input
                type="text"
                className={
                  `${responsive.sm ? "imputMobile" : "form-control"}` +
                  (errors.website && touched.website ? " is-invalid" : "")
                }
                id="iptWebsite"
                name="website"
                onChange={(website) => {
                  const websiteStr = website.target.value;
                  let websiteFinal = websiteStr;

                  if (websiteStr === "http:/" || websiteStr === "https:/") {
                    websiteFinal = "";
                  } else if (
                    !websiteStr.startsWith("http://") &&
                    !websiteStr.startsWith("https://")
                  ) {
                    websiteFinal = "https://" + websiteStr;
                  }

                  setFieldValue("website", websiteFinal);
                }}
                onBlur={handleBlur}
                value={values.website}
              />
              <div className="invalid-feedback flex-fill">{errors.website}</div>
            </div>

            <div className="mb-4">
              <label htmlFor="iptEmail" className="form-label">
                E-mail da startup*
              </label>
              <input
                type="text"
                className={
                  `${responsive.sm ? "imputMobile" : "form-control"}` +
                  (errors.email && touched.email ? " is-invalid" : "")
                }
                id="iptEmail"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
              <div className="invalid-feedback flex-fill">{errors.email}</div>
            </div>

            <InputLinkedin
              label="Linkedin da startup"
              value={values.linkedin}
              onChange={(linkedin) => {
                setFieldValue("linkedin", linkedin);
              }}
            />

            <InputPremiacoes
              value={values.premiacoes ?? []}
              onChange={(premiacoes) => {
                setFieldValue("premiacoes", premiacoes);
              }}
              label="Liste suas premiações/certificações"
              showTip={true}
              isEditing={setIsEditingPremiacoes}
            />
            <div className="mt-5 fw-bold text-center d-flex flex-wrap justify-content-center gap-2">
              {!responsive.sm && (
                <p style={{ color: "#525200", maxWidth: "500px", margin: "0" }}>
                  *Obs: Após a criação do perfil da Startup, você poderá
                  convidar demais membros da sua Organização para completar o
                  perfil.
                </p>
              )}

              <div
                className=" text-end d-flex flex-nowrap"
                style={{
                  height: "fit-content",
                  width: responsive.sm ? "100%" : "",
                }}
              >
                {!responsive.sm && (
                  <ButtonLightGreen
                    className="me-3"
                    onClick={async () => {
                      const cacheSaveResult = await handlerCacheStartup.save({
                        ...values,
                        nome: values.nome_startup,
                        telefone: values.telefone?.numero,
                      });

                      if (cacheSaveResult.ok) onBack();
                      else
                        console.warn("cacheSaveResult", cacheSaveResult.error);
                    }}
                  >
                    Voltar
                  </ButtonLightGreen>
                )}
                <ButtonDarkGreen
                  disabled={!isValid || isSubmitting || isEditingPremiacoes}
                  isSubmit={true}
                  className={
                    (!responsive.sm ? "ms-2" : "w-100") + " text-uppercase"
                  }
                >
                  Avançar
                </ButtonDarkGreen>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export type InputPainelSobreStartupMobile = {
  nome: string;
};

export type FormikValues = {
  sobre: string;
  email: string;
  nome_startup: string;
  ano_fundacao: number;
} & Partial<{
  cidade: CidadeOutputWithTipo;
  telefone: TypeInputTelefone;
  website: string; // TODO: VALIDATE URL
  linkedin: string;
  premiacoes: PremiacaoOutput[];
}>;

export type OutputPainelSobreStartupMobile = {
  sobre: string;
  email: string;
  nome_startup: string;
  ano_fundacao: number;
} & Partial<{
  cidade: CidadeOutputWithTipo;
  telefone: TypeInputTelefone;
  website: string; // TODO: VALIDATE URL
  linkedin: string;
  premiacoes: PremiacaoOutput[];
}>;

const validator = Yup.object().shape({
  cidade: Yup.object()
    .shape({
      cidade: Yup.string().required(),
      estado: Yup.string().required(),
      pais: Yup.string().required(),
      paisCode: Yup.string().required(),
    })
    .required("Cidade é obrigatório"),
  sobre: Yup.string()
    .required(
      "Campo obrigatório, deve ter no mínimo " +
        Startup.SOBRE_MINIMO_LENGTH +
        " caracteres"
    )
    .min(Startup.SOBRE_MINIMO_LENGTH, Startup.SOBRE_MINIMO_MESSAGE)
    .max(Startup.SOBRE_MAXIMO_LENGTH, Startup.SOBRE_MAXIMO_MESSAGE),
  nome_startup: Yup.string()
    .required("Campo obrigatório")
    .min(1, "Campo obrigatório"),
  ano_fundacao: Yup.number()
    .required("Campo obrigatório")
    .min(1900, "Ano inválido"), // TODO: validação ano_fundação
  website: Yup.string().url("Deve ser um link").min(5, "Mínimo 5 caracteres"),
  telefone: Yup.object()
    .shape({
      numero: Yup.string(),
      invalid: Yup.boolean(),
    })
    .test(({ numero, invalid }) => {
      if (invalid) return !numero;

      return !!numero;
    }),
  linkedin: Yup.string().test((urlLinkedin) => {
    return urlLinkedin ? UrlLinkedin.isValidUrlLinkedin(urlLinkedin) : true;
  }),
  email: Yup.string().required("Campo obrigatório").email("Campo inválido"),
  premiacoes: Yup.array().of(
    Yup.object().shape({
      nome: Yup.string()
        .required("O nome é obrigatório")
        .min(
          Premiacao.NOME_MINIMO_LENGTH,
          "Mínimo " + Premiacao.NOME_MINIMO_LENGTH + " caracteres"
        )
        .max(
          Premiacao.NOME_MAXIMO_LENGTH,
          "Máximo " + Premiacao.NOME_MAXIMO_LENGTH + " caracteres"
        ),
      descricao: Yup.string().max(
        Premiacao.DESCRICAO_MAXIMO_LENGTH,
        "Máximo de " + Premiacao.DESCRICAO_MAXIMO_LENGTH + " caracteres."
      ),
    })
  ),
});
