import { useContext } from "react";
import { ProgressBar } from "react-bootstrap";

import { PerfilStartupContext } from "../../../globalStore/perfilStartup/PerfilStartupContext";
import PerfilInfoStartup from "./PerfilInfoStartup";
import { CurrentUserContext } from "../../../globalStore/CurrentUserContext";
import { HandlerSaveLogoStartup } from "../../../domain/usecases/interfaces/handlerSaveLogoStartup";

type PerfilInfoStartupFromContextProps = {
  handlerSaveLogoStartup?: HandlerSaveLogoStartup;
};

export const PerfilInfoStartupFromContext = ({
  handlerSaveLogoStartup,
}: PerfilInfoStartupFromContextProps) => {
  const { perfilStartupResult } = useContext(PerfilStartupContext);
  const { currentUserAuth } = useContext(CurrentUserContext);

  if (!perfilStartupResult)
    return (
      <ProgressBar animated variant="info" now={100} className="w-100 m-1" />
    );

  return perfilStartupResult.ok ? (
    <PerfilInfoStartup
      startup={perfilStartupResult.value}
      currentUserIsGestor={perfilStartupResult.value.gestores.includes(
        currentUserAuth.user_auth?.auth_id ?? ""
      )}
      handlerSaveLogoStartup={handlerSaveLogoStartup}
    />
  ) : (
    <>não encontrado</>
  );
};
