import { Firestore, addDoc, collection } from "@firebase/firestore";

import { Result } from "../typings";
import { HandlerCreateHub } from "../domain/usecases/interfaces/handlerCreateHub";
import { Hub, InputHub, OutputHub } from "../domain/entities/Hub";

export class HandlerCreateHubFirebase implements HandlerCreateHub {
  constructor(private firestore: Firestore) {}
  createHub(inputHub: InputHub): Promise<Result<OutputHub>> {
    const inputToFirebase: InputHub = {
      nome: inputHub.nome,
      sobre: inputHub.sobre,
      cidade: inputHub.cidade,
      gestores: inputHub.gestores,
      email: inputHub.email,
      telefone: inputHub.telefone,
      classificacao: inputHub.classificacao,
      created_in: inputHub.created_in,
      habilitado: inputHub.habilitado,
    };

    if (inputHub.nit) {
      inputToFirebase.nit = inputHub.nit;
    }
    if (inputHub.website) {
      inputToFirebase.website = inputHub.website;
    }
    if (inputHub.linkedin) {
      inputToFirebase.linkedin = inputHub.linkedin;
    }
    if (inputHub.premiacoes) {
      inputToFirebase.premiacoes = inputHub.premiacoes;
    }

    const hubCreateResult = Hub.create(inputToFirebase);
    if (!hubCreateResult.ok) return Promise.resolve(hubCreateResult);

    return new Promise((resolve) => {
      addDoc(collection(this.firestore, "hubs"), inputToFirebase)
        .then((docHub) =>
          resolve({
            ok: true,
            value: {
              ...inputToFirebase,
              id: docHub.id,
              created_in: new Date(),
              habilitado: false,
            },
          })
        )
        .catch((error) =>
          resolve({
            ok: false,
            error,
          })
        );
    });
  }
}
