import {
  Firestore,
  collection,
  getDocs,
  getDoc,
  doc,
  query,
  where,
  Timestamp,
} from "@firebase/firestore";

import { Result } from "../typings";
import { HandlerGetHub } from "../domain/usecases/interfaces/handlerGetHub";
import { OutputHub } from "../domain/entities/Hub";

export class HandlerGetHubFirebase implements HandlerGetHub {
  constructor(private firestore: Firestore) {}

  getAllEnabledHubs(): Promise<Result<OutputHub[]>> {
    return new Promise((resolve) => {
      const queryEnabledHubs = query(
        collection(this.firestore, "hubs"),
        where("habilitado", "==", true)
      );

      getDocs(queryEnabledHubs)
        .then((querySnapshot) => {
          const hubs: OutputHub[] = querySnapshot.docs.map((docSnapshot) => {
            const hub = docSnapshot.data() as OutputHub;
            hub.id = docSnapshot.id;
            const created_in = hub.created_in as unknown as
              | Timestamp
              | undefined;
            if (created_in) hub.created_in = created_in.toDate();

            return hub;
          });

          resolve({
            ok: true,
            value: hubs,
          });
        })
        .catch(() =>
          resolve({
            ok: true,
            value: [],
          })
        );
    });
  }

  getHub(id: string): Promise<Result<OutputHub>> {
    return new Promise((resolve) => {
      const docHub = doc(this.firestore, "hubs", id);

      getDoc(docHub)
        .then((docSnapshot) => {
          const hub = docSnapshot.data() as OutputHub;
          hub.id = docSnapshot.id;
          hub.created_in = (hub.created_in as unknown as Timestamp).toDate();

          resolve({
            ok: true,
            value: hub,
          });
        })
        .catch((error) =>
          resolve({
            ok: false,
            error,
          })
        );
    });
  }

  getHubsGestor(id_gerente: string): Promise<Result<OutputHub[], Error>> {
    return new Promise((resolve) => {
      const queryHubs = query(
        collection(this.firestore, "hubs"),
        where("gestores", "array-contains", id_gerente)
      );

      getDocs(queryHubs)
        .then((querySnapshot) => {
          const hubs: OutputHub[] = querySnapshot.docs.map((docSnapshot) => {
            const hub = docSnapshot.data() as OutputHub;
            hub.id = docSnapshot.id;
            hub.created_in = (hub.created_in as unknown as Timestamp).toDate();
            return hub;
          });

          resolve({
            ok: true,
            value: hubs,
          });
        })
        .catch(() =>
          resolve({
            ok: true,
            value: [],
          })
        );
    });
  }
}
