import { createContext } from "react";

import { ConexoesStatus } from "../../domain/entities/ConexoesStatus";
import { Result } from "../../typings";

export const ConexoesStatusCountContext = createContext<{
  conexoesStatusCountResult: TypeConexoesStatusCountContext;
}>({
  conexoesStatusCountResult: undefined,
});

export type TypeConexoesStatusCountContext = Result<ConexoesStatus> | undefined;
