import {
  Firestore,
  collection,
  getDocs,
  getDoc,
  doc,
  query,
  where,
  Timestamp,
} from "@firebase/firestore";

import { Result } from "../typings";
import { HandlerGetVentureCapital } from "../domain/usecases/interfaces/handlerGetVentureCapital";
import { OutputVentureCapital } from "../domain/entities/VentureCapital";

export class HandlerGetVentureCapitalFirebase
  implements HandlerGetVentureCapital
{
  constructor(private firestore: Firestore) {}

  getAllEnabledVentureCapitals(): Promise<Result<OutputVentureCapital[]>> {
    return new Promise((resolve) => {
      const queryEnabledVentureCapitals = query(
        collection(this.firestore, "venture_capital"),
        where("habilitado", "==", true)
      );

      getDocs(queryEnabledVentureCapitals)
        .then((querySnapshot) => {
          const ventureCapitals: OutputVentureCapital[] =
            querySnapshot.docs.map((docSnapshot) => {
              const ventureCapital = docSnapshot.data() as OutputVentureCapital;
              ventureCapital.id = docSnapshot.id;
              ventureCapital.created_in = (
                ventureCapital.created_in as unknown as Timestamp
              ).toDate();
              return ventureCapital;
            });

          resolve({
            ok: true,
            value: ventureCapitals,
          });
        })
        .catch(() =>
          resolve({
            ok: true,
            value: [],
          })
        );
    });
  }

  getVentureCapital(id: string): Promise<Result<OutputVentureCapital>> {
    return new Promise((resolve) => {
      const docVentureCapital = doc(this.firestore, "venture_capital", id);

      getDoc(docVentureCapital)
        .then((docSnapshot) => {
          const ventureCapital = docSnapshot.data() as OutputVentureCapital;
          ventureCapital.id = docSnapshot.id;
          ventureCapital.created_in = (
            ventureCapital.created_in as unknown as Timestamp
          ).toDate();

          resolve({
            ok: true,
            value: ventureCapital,
          });
        })
        .catch((error) =>
          resolve({
            ok: false,
            error,
          })
        );
    });
  }

  getVentureCapitalsGestor(
    id_gerente: string
  ): Promise<Result<OutputVentureCapital[], Error>> {
    return new Promise((resolve) => {
      const queryVentureCapitals = query(
        collection(this.firestore, "venture_capital"),
        where("gestores", "array-contains", id_gerente)
      );

      getDocs(queryVentureCapitals)
        .then((querySnapshot) => {
          const ventureCapitals: OutputVentureCapital[] =
            querySnapshot.docs.map((docSnapshot) => {
              const ventureCapital = docSnapshot.data() as OutputVentureCapital;
              ventureCapital.id = docSnapshot.id;
              ventureCapital.created_in = (
                ventureCapital.created_in as unknown as Timestamp
              ).toDate();
              return ventureCapital;
            });

          resolve({
            ok: true,
            value: ventureCapitals,
          });
        })
        .catch(() =>
          resolve({
            ok: true,
            value: [],
          })
        );
    });
  }
}
