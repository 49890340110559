import { createContext } from "react";

import { OutputNit } from "../../domain/entities/Nit";
import { Result } from "../../typings";

export const PerfilNitContext = createContext<{
  perfilNitResult: TypePerfilNitContext;
}>({
  perfilNitResult: undefined,
});

export type TypePerfilNitContext = Result<OutputNit> | undefined;
