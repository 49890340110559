import { ref, uploadBytes, FirebaseStorage } from "firebase/storage";
import { doc, setDoc, Firestore } from "firebase/firestore";

import { Result } from "../typings";
import { HandlerSavePlanilhaStartup } from "../domain/usecases/interfaces/handlerSavePlanilhaStartup";
import {
  InputPlanilhaStartup,
  OutputPlanilhaStartup,
  PlanilhaStartup,
} from "../domain/entities/PlanilhaStartup";
import { OutputStartup } from "../domain/entities/Startup";

export class HandlerSavePlanilhaStartupFirebase
  implements HandlerSavePlanilhaStartup
{
  constructor(
    private firebaseStorage: FirebaseStorage,
    private firestore: Firestore
  ) {}

  async save(
    inputPlanilhaStartup: InputPlanilhaStartup
  ): Promise<Result<OutputPlanilhaStartup>> {
    const planilhaStartupResult = await PlanilhaStartup.create(
      inputPlanilhaStartup
    );

    if (!planilhaStartupResult.ok) return planilhaStartupResult;

    const storageRef = ref(
      this.firebaseStorage,
      planilhaStartupResult.value.path
    );

    return new Promise((resolve) => {
      uploadBytes(storageRef, planilhaStartupResult.value.file)
        .then(async () => {
          const docStartup = doc(
            this.firestore,
            "startup",
            planilhaStartupResult.value.id_startup
          );

          const planilha: Pick<OutputStartup, "path_pitch_planilha"> = {
            path_pitch_planilha: storageRef.fullPath,
          };

          await setDoc(docStartup, planilha, { merge: true });

          resolve(planilhaStartupResult);
        })
        .catch((error) =>
          resolve({
            ok: false,
            error,
          })
        );
    });
  }
}
