import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  CacheStartup,
  HandlerCacheStartup,
} from "../../../../../domain/usecases/interfaces/HandleCacheStartup";
import { HandlerGetURLFromPath } from "../../../../../domain/usecases/interfaces/HandlerGetURLFromPath";
import { HandlerSaveLogoStartup } from "../../../../../domain/usecases/interfaces/handlerSaveLogoStartup";
import { CurrentUserContext } from "../../../../../globalStore/CurrentUserContext";
import { ContainerResponsive } from "../../../../generalComponents/ContainerResponsive";
import { ConteudoLogoStartup } from "./ConteudoLogoStartup";

export const PainelLogoStartup = ({
  handlerSaveLogoStartup,
  handlerGetURLFromPath,
  handlerCacheStartup,
}: {
  handlerSaveLogoStartup: HandlerSaveLogoStartup;
  handlerGetURLFromPath: HandlerGetURLFromPath;
  handlerCacheStartup: HandlerCacheStartup;
}) => {
  const navigate = useNavigate();
  const { currentUserScyggz } = useContext(CurrentUserContext);

  const [cacheStartup, setCacheStartup] = useState<CacheStartup>({});

  useEffect(() => {
    handlerCacheStartup.get().then((cacheStartupResult) => {
      if (cacheStartupResult.ok) {
        setCacheStartup(cacheStartupResult.value);
      }
    });
  }, [handlerCacheStartup]);

  const paginacao = {
    start: 7,
    end: 7,
  };

  return (
    <section
      data-testid="painel-cadastro-logo-startup"
      style={{ height: "100%" }}
    >
      <ContainerResponsive
        backUrlMobile="/cadastro/startup/6"
        paginacao={paginacao}
        textoMarcaDagua="Startup"
        showLogo={true}
      >
        <ConteudoLogoStartup
          startup={{
            id: cacheStartup.id ?? "",
            path_logo: cacheStartup.path_logo,
            nome: cacheStartup.nome ?? "",
          }}
          user={{
            nome: currentUserScyggz.user?.nome ?? "",
          }}
          handlerSaveLogoStartup={handlerSaveLogoStartup}
          handlerGetURLFromPath={handlerGetURLFromPath}
          onNext={(id_startup) => {
            navigate("/startup/" + id_startup);
          }}
          handlerCacheStartup={handlerCacheStartup}
        />
      </ContainerResponsive>
    </section>
  );
};
