import { useCallback, useContext, useEffect, useState } from "react";

import { HandlersContext } from "../../../globalStore/HandlersProviders/HandlersContext";
import {
  OutputUsuarioScyggzEntity,
  TipoPerfil,
} from "../../../domain/entities/usuarioScyggz";
import { BannerPessoasRelevantesItem } from "../components/BannerPessoasRelevantes/BannerPessoasRelevantesItem";
import { ItemListStartupItem } from "../components/ItemListStartup/ItemListStartupItem";
import { OutputStartup } from "../../../domain/entities/Startup";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

export function Admin() {
  const { handlerGetUsersWaitingAprovement, handlerSetUsersEnabled, handlerGetStartupWaitingAprovement, handlerSetStartupEnabled} =
    useContext(HandlersContext);

  const [users, setUsers] = useState<OutputUsuarioScyggzEntity[]>();
  const [startups, setStartups] = useState<OutputStartup[]>();

  const refresh = useCallback(() => {

    handlerGetStartupWaitingAprovement
      ?.getStartupWaitingAprovement()
      .then((result) => {
        console.log("result", result)
        if (result.ok) {
          setStartups(result.value);
        }
      });

    handlerGetUsersWaitingAprovement
      ?.getUsuariosWaitingAprovement()
      .then((result) => {
        console.log("result2", result)
        if (result.ok) {
          setUsers(result.value);
        }
      });
  }, [handlerGetUsersWaitingAprovement, handlerGetStartupWaitingAprovement]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  
  return (
    <div className="p-3">
      <div className="card">
        <div className="card-header">Novos cadastros aguardando aprovação</div>
        <Tabs
          defaultActiveKey="users"
          id="uncontrolled-tab-example"
          className="mt-2"
        >
          <Tab eventKey="users" title="Usuários">
            <>
              {users && users.length > 0 ? (
                <ul className="list-group list-group-flush list-group-numbered">
                  {users?.map((user) => (
                    <li className="list-group-item" key={user.auth_user_id}>
                      <div className="d-flex align-items-center">
                        <BannerPessoasRelevantesItem
                          item={{
                            url_link: "/user/" + user.auth_user_id,
                            image_path: user.avatar_path?.path,
                            nome: user.nome,
                            headline: user.headline,
                            cidade: user.cidade,
                            perfis: Object.keys(user.perfis ?? {}) as TipoPerfil[],
                            onConectar: undefined,
                          }}
                          className="col-6 d-block"
                        />

                        <div className="col-6 p-3">
                          <button
                            type="button"
                            className="btn btn-success"
                            onClick={async () => {
                              if (handlerSetUsersEnabled) {
                                await handlerSetUsersEnabled.setUsuariosEnabled([
                                  user,
                                ]);

                                refresh();
                              }
                            }}
                          >
                            habilitar
                          </button>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="card-text px-5 py-1">
                  <small className="text-body-secondary">nenhum</small>
                </p>
              )}
            </>
          </Tab>
          <Tab eventKey="startups" title="Startups">
            <>
            {startups && startups.length > 0 ? (
                <ul className="list-group list-group-flush list-group-numbered">
                  {startups?.map((startup) => (
                    <li className="list-group-item" key={startup.id}>
                      <div className="d-flex align-items-center">
                        <ItemListStartupItem
                          item={{
                            url_link: "/startup/" + startup.id,
                            image_path: startup.path_logo,
                            nome: startup.nome,
                            website: startup.website,
                            cidade: startup.cidade,
                          }}
                          className="col-6 d-block"
                        />
                        <div className="col-6 p-3">
                          <button
                            type="button"
                            className="btn btn-success"
                            onClick={async () => {
                              if (handlerSetStartupEnabled) {
                                await handlerSetStartupEnabled.setStartupEnabled([
                                  startup,
                                ]);

                                refresh();
                              }
                            }}
                          >
                            habilitar
                          </button>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="card-text px-5 py-1">
                  <small className="text-body-secondary">nenhum</small>
                </p>
              )}
            </>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}
