import { useCallback, useState } from "react";

import { Sobre } from "../../domain/entities/sobre";
import { HandlerCache } from "../../domain/usecases/interfaces/HandlerCache";

export function InputSobre<T, X extends keyof T, Y extends Record<X, string>>({
  label,
  value,
  onChange,
  cacheOnChange,
}: {
  label: string;
  value: string;
  onChange: (sobre: string) => void;
  cacheOnChange?: {
    handler: HandlerCache<T> & { save: (toSave: Y) => unknown };
    field: X;
  };
}) {
  const [error, setError] = useState<string>();

  const handlerChange = useCallback(
    (sobre: string) => {
      onChange(sobre);

      if (cacheOnChange) {
        const toCache: Y = {} as Y;
        toCache[cacheOnChange.field] = sobre as never;
        cacheOnChange.handler.save(toCache);
      }

      const validateResult = Sobre.validate({
        conteudo: sobre,
      });
      if (validateResult.ok) {
        setError(undefined);
      } else {
        setError(validateResult.error.message);
      }
    },
    [cacheOnChange, onChange]
  );

  return (
    <div className={"mb-3 has-validation"}>
      <label className="form-label fw-bolder" htmlFor="iptSobre">
        {label}
      </label>

      <textarea
        className={"form-control" + (error ? " is-invalid" : "")}
        onChange={({ target: { value } }) => {
          handlerChange(value);
        }}
        rows={7}
        id="iptSobre"
        value={value}
        title="sobre"
      />

      <div className={"d-flex justify-content-between"}>
        <div className="text-danger flex-fill">{error}</div>
        <span className={"text-black-50 fw-lighter"}>
          {value.trim().length} / {Sobre.MAXIMO_LENGTH}
        </span>
      </div>
    </div>
  );
}
