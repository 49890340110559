import { useSearchParams } from "react-router-dom";
import { useMemo } from "react";

import { ContainerResponsive } from "../../generalComponents/ContainerResponsive";
import HTMLHead from "../../generalComponents/HTMLHead";
import { RedefinirSenha } from "./components/RedefinirSenha";
import { ChecagemEmail } from "./components/ChecagemEmail";

export function UserMgmt() {
  const [searchParams] = useSearchParams();

  const requirements = useMemo<{ mode: string | null; oobCode: string | null }>(
    () => ({
      mode: searchParams.get("mode"),
      oobCode: searchParams.get("oobCode"),
    }),
    [searchParams]
  );

  return requirements.mode == "resetPassword" && requirements.oobCode ? (
    <>
      <HTMLHead title={"redefinir senha"} description={""} />

      <RedefinirSenha oobCode={requirements.oobCode} />
    </>
  ) : requirements.mode == "verifyEmail" && requirements.oobCode ? (
    <>
      <HTMLHead title={"checagem de email"} description={""} />

      <ChecagemEmail oobCode={requirements.oobCode} />
    </>
  ) : (
    <>
      <HTMLHead title={"link inválido"} description={""} />

      <ContainerResponsive showLogo={false}>
        Operação inválida
      </ContainerResponsive>
    </>
  );
}
