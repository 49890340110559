import { useCallback, useContext, useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";

import { ProgramaConexao } from "../../../../../domain/entities/ProgramaConexao";
import { PostMuralComment } from "./PostMuralComment";
import { HandlersDatabaseContext } from "../../../../../globalStore/HandlersProviders/HandlersDatabaseContext";
import { ProgramaConexaoComment } from "../../../../../domain/entities/ProgramaConexaoComment";
import { Result } from "../../../../../typings";
import { CommentInMural } from "./CommentInMural";

export function MuralProgramaDeConexao({
  programa: { id },
  isGestor,
  currentUserName: { user_id, nome, avatar_path },
}: {
  programa: Pick<ProgramaConexao, "id">;
  isGestor: boolean;
  currentUserName: {
    user_id: string;
    nome: string;
    avatar_path?: string;
  };
}) {
  const {
    handlerComentarioProgramaConexaoGetAllMural,
    handlerComentarioProgramaConexaoCreateResponse,
  } = useContext(HandlersDatabaseContext);

  const [commentsMuralResult, setCommentsMuralResult] =
    useState<Result<ProgramaConexaoComment[]>>();

  const refreshMural = useCallback(() => {
    handlerComentarioProgramaConexaoGetAllMural
      ?.getAllMural(id)
      .then((commentsMuralResult) =>
        setCommentsMuralResult(commentsMuralResult)
      );
  }, [handlerComentarioProgramaConexaoGetAllMural, id]);

  useEffect(() => {
    refreshMural();
  }, [refreshMural]);

  return (
    <>
      <PostMuralComment
        className="border-top-0 mb-2"
        currentUserName={nome}
        currentUserPath={avatar_path}
        id_programa={id}
        onPost={() => {
          refreshMural();
        }}
        user_id={user_id}
      />

      {commentsMuralResult ? (
        <>
          {commentsMuralResult.ok ? (
            <>
              {commentsMuralResult.value.map((commentMural) => (
                <CommentInMural
                  key={commentMural.id}
                  isGestor={isGestor}
                  comment={commentMural}
                  className="mb-2"
                  refresh={() => refreshMural()}
                  createResponse={
                    handlerComentarioProgramaConexaoCreateResponse
                      ? async ({ parent_comment, message }) => {
                          return handlerComentarioProgramaConexaoCreateResponse.createResponse(
                            {
                              autor: {
                                id: user_id,
                                nome,
                                avatar_path,
                              },
                              created_at: new Date(),
                              id_programa: id,
                              message,
                              parent_comment,
                            }
                          );
                        }
                      : undefined
                  }
                />
              ))}
            </>
          ) : (
            <>erro</>
          )}
        </>
      ) : (
        <ProgressBar animated variant="info" now={100} className="w-100" />
      )}
    </>
  );
}
