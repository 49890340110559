import { useContext, useState } from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faUsersViewfinder } from "@fortawesome/free-solid-svg-icons";

import { ProgramaConexao } from "../../../../../domain/entities/ProgramaConexao";
import { dateFormat } from "../../../../../utils/defaultDateFormat";
import { HandlersStorageContext } from "../../../../../globalStore/HandlersProviders/HandlersStorageContext";
import { ImgFromPathNew } from "../../../../generalComponents/ImgFromPathNew";
import { ModalEditProgramaDeConexao } from "./ModalEditProgramaDeConexao";
import { HandlersDatabaseContext } from "../../../../../globalStore/HandlersProviders/HandlersDatabaseContext";

export function HeaderProgramaDeConexao({
  programa: { id, logo_path, nome, about, start_date, end_date },
  isGestor,
  className,
  refresh,
}: {
  programa: ProgramaConexao;
  isGestor: boolean;
  className?: string;
  refresh: () => void;
}) {
  const { handlerProgramaConexaoUpdate } = useContext(HandlersDatabaseContext);
  const { handlerProgramaConexaoSaveLogo } = useContext(HandlersStorageContext);

  const [showModalEdit, setShowModalEdit] = useState<boolean>(false);

  return (
    <div className={"card shadow-sm px-2 p-md-3 " + className}>
      <div className="card-body p-2 p-md-3 d-flex gap-2 gap-sm-3 gap-md-4">
        <div className="col-2 col-md-1">
          {handlerProgramaConexaoUpdate && isGestor && (
            <>
              <button
                type="button"
                className="btn bg-body-secondary position-absolute top-0 end-0 rounded-circle text-secondary p-0 m-3"
                style={{
                  width: 35,
                  height: 35,
                }}
                onClick={() => setShowModalEdit(true)}
              >
                <FontAwesomeIcon icon={faPencil} />
              </button>

              <ModalEditProgramaDeConexao
                initialValue={{
                  id,
                  nome,
                  about,
                  duration: {
                    start: start_date,
                    end: end_date,
                  },
                }}
                show={showModalEdit}
                onShowChange={setShowModalEdit}
                handlerProgramaConexaoUpdate={handlerProgramaConexaoUpdate}
                onUpdate={() => {
                  setShowModalEdit(false);
                  refresh();
                }}
              />
            </>
          )}

          <ImgFromPathNew
            path={logo_path}
            alt={nome}
            className="rounded-circle border border-2 border-white bg-white"
            handlerUpdate={
              handlerProgramaConexaoSaveLogo && isGestor
                ? {
                    modalButtonText: "Salvar Logo",
                    modalHeaderText: "Editar Logo",
                    update: async (file) => {
                      const saveResult =
                        await handlerProgramaConexaoSaveLogo.save({
                          id_programa: id,
                          file,
                        });

                      if (!saveResult.ok) {
                        return saveResult;
                      }

                      refresh();

                      return {
                        ok: true,
                        value: {
                          path: saveResult.value.logo_path,
                        },
                      };
                    },
                  }
                : undefined
            }
          />
        </div>

        <div className="flex-fill">
          <h4 className="fw-bolder">
            <FontAwesomeIcon icon={faUsersViewfinder} className="me-3" />
            {nome}
          </h4>

          <h6
            className="text-muted fw-lighter"
            style={{
              fontSize: 14,
            }}
          >
            duração: {moment(start_date).format(dateFormat.toUpperCase())} -{" "}
            {moment(end_date).format(dateFormat.toUpperCase())}
          </h6>

          <h5 className="fw-bolder m-0">sobre</h5>
          <p
            className="m-0"
            style={{
              WebkitLineClamp: 4,
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
            }}
          >
            {about}
          </p>
        </div>
      </div>
    </div>
  );
}
