import {
  faEnvelope,
  faEye,
  faEyeSlash,
  faLock,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik } from "formik";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";

import { AuthErrorValidation } from "../../../../domain/erros/AuthErrors";
import { getEmailFromStorage } from "../../../../hooks/handlerEmailFromStorage";
import {
  getNomeFromStorage,
  saveNomeToStorage,
} from "../../../../hooks/handlerNameFromStorage";
import { ResizeContext } from "../../../../hooks/useResize";
import { ButtonDarkGreen } from "../../../generalComponents/buttonDarkGreen/ButtonDarkGreen";
import { CheckboxDarkGreen } from "../../../generalComponents/checkboxDarkGreen/CheckboxDarkGreen";
import { UsuarioScyggzValidator } from "../../../../domain/entities/UsuarioScyggzValidator";
import { Spinner } from "react-bootstrap";
import { HandlersDatabaseContext } from "../../../../globalStore/HandlersProviders/HandlersDatabaseContext";
import { ModalLogin } from "./ModalLogin";

export function ConteudoCadastro({
  onCadastro,
  onRedirect,
}: {
  onCadastro: (output: OutputPainelCadastro) => void;
  onRedirect: () => void;
}) {
  const [showPassword, setShowPassword] = useState(false);
  const [showModalLogin, setShowModalLogin] = useState(false);
  const [redirecionamentoEmail, setRedirecionamentoEmail] = useState(false);
  const [emailFill, setEmailFill] = useState("");

  const { handlerCreateAuthWithName } = useContext(HandlersDatabaseContext);
  const { responsive } = useContext(ResizeContext);

  return (
    <div data-testid="painel_cadastro" className="mx-5">
      <h4 className="card-title text-center mb-3 fw-bolder">
        Inscreva-se com seu e-mail
      </h4>

      <Formik<FormikFields>
        initialValues={{
          nome: getNomeFromStorage() ?? "",
          email: getEmailFromStorage() ?? "",
          password: "",
          aceito: false,
        }}
        initialErrors={{ aceito: "" }}
        validationSchema={validator}
        onSubmit={async function (values, { setFieldError }) {
          saveNomeToStorage(values.nome);

          if (!handlerCreateAuthWithName) {
            console.warn("undefined handlerCreateAuthWithName");
            return;
          }

          const createAuthUserResult =
            await handlerCreateAuthWithName.createAuthUserWithName({
              email: values.email,
              password: values.password,
              name: values.nome,
            });

          if (createAuthUserResult.ok) {
            const output: OutputPainelCadastro = {
              auth_id: createAuthUserResult.value.auth_id,
              name: createAuthUserResult.value.name,
            };

            return onCadastro(output);
          }

          console.warn("ERRO CADASTRO:", createAuthUserResult.error.message);

          if ("campos" in createAuthUserResult.error) {
            (createAuthUserResult.error as AuthErrorValidation).campos.forEach(
              (campoErro) => {
                setFieldError(campoErro, createAuthUserResult.error.message);
                if (
                  createAuthUserResult.error &&
                  createAuthUserResult.error.name == "auth/email-already-in-use"
                ) {
                  setRedirecionamentoEmail(true);
                  setShowModalLogin(true);
                }
              }
            );
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValid,
          setFieldValue,
          validateForm,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="input-group mb-2 has-validation">
              {!responsive.sm && (
                <span className="input-group-text border-end-0 bg-secondary bg-opacity-10">
                  <FontAwesomeIcon icon={faUser} className="opacity-25" />
                </span>
              )}
              <input
                type="text"
                title="nome"
                name="nome"
                className={`${
                  responsive.sm
                    ? "imputMobile "
                    : "form-control border-start-0 m-0 bg-secondary bg-opacity-10 text-secondary"
                }
                    ${
                      errors.nome && touched.nome && errors.nome
                        ? " is-invalid"
                        : ""
                    }`}
                placeholder="Nome Completo*"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.nome}
              />
              <div className="invalid-feedback">
                {errors.nome && touched.nome && errors.nome}
              </div>
            </div>

            <div className="input-group mb-2 has-validation">
              {!responsive.sm && (
                <span className="input-group-text border-end-0 bg-secondary bg-opacity-10">
                  <FontAwesomeIcon icon={faEnvelope} className="opacity-25" />
                </span>
              )}
              <input
                type="email"
                title="email"
                name="email"
                className={`${
                  responsive.sm
                    ? "imputMobile mt-3"
                    : "form-control border-start-0 m-0 bg-secondary bg-opacity-10 text-secondary"
                }
                    ${
                      errors.email && touched.email && errors.email
                        ? " is-invalid"
                        : ""
                    }`}
                placeholder="Email*"
                onChange={(e) => {
                  setEmailFill(e.target?.value);
                  handleChange(e);
                }}
                onBlur={handleBlur}
                value={values.email}
              />
              <div className="invalid-feedback">
                {errors.email && touched.email && errors.email}
              </div>
            </div>

            <div className="input-group mb-2 has-validation position-relative">
              {!responsive && (
                <span className="input-group-text border-end-0 bg-secondary bg-opacity-10">
                  <FontAwesomeIcon icon={faLock} className="opacity-25" />
                </span>
              )}
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                title="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                className={`${
                  responsive.sm
                    ? "imputMobile mt-3"
                    : "form-control border-start-0 border-end-0 m-0 bg-secondary bg-opacity-10 text-secondary"
                }
                      ${
                        errors.password && errors.password ? " is-invalid" : ""
                      }`}
                placeholder="Senha*"
              />
              <button
                style={{
                  top: responsive.sm ? "14px" : "",
                  border: responsive.sm ? "none" : "",
                }}
                className={`${
                  responsive.sm
                    ? "btn bg-white bg-opacity-10 m-0 position-absolute lh-1 shadow-none end-0"
                    : "btn border-start-0 bg-white border bg-opacity-10 m-0"
                }`}
                type="button"
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
                title={showPassword ? "Esconder senha" : "Exibir senha"}
              >
                <FontAwesomeIcon
                  icon={showPassword ? faEyeSlash : faEye}
                  className="opacity-25"
                />
              </button>
              <div className="invalid-feedback">
                {errors.password && errors.password}
              </div>
            </div>

            {!responsive.sm && (
              <div>
                <span
                  style={{ fontSize: "14px", margin: 0 }}
                  className="fw-bold form-check-label"
                >
                  Força da senha:
                </span>
                <span style={{ fontSize: "12px", display: "inline-block" }}>
                  {" "}
                  Use pelo menos {UsuarioScyggzValidator.MIN_SIZE_PASSWORD}
                  caracteres. Não use uma senha de outro site e tente misturar
                  letras, números e símbolos.
                </span>
              </div>
            )}

            <div className="form-check mt-4 fw-light">
              <CheckboxDarkGreen
                id="chkElegivel"
                title="aceite de termos de serviços e política de privacidade"
                value={values.aceito}
                onChange={(checked) => {
                  setFieldValue("aceito", checked);
                  setTimeout(() => {
                    validateForm();
                  }, 0);
                }}
              />
              <label style={{ fontSize: "11px" }} className="form-check-label">
                Ao se inscrever, você confirma que aceita nossos
                <Link to={"/termosdeuso"} target="_blank">
                  <span className="text-decoration-underline fw-bold ms-1 text-black">
                    Termos de Serviços
                  </span>
                </Link>
                ,
                <Link to={"/politicadeprivacidade"} target="_blank">
                  <span className="text-decoration-underline fw-bold mx-1 text-black">
                    Política de Privacidade
                  </span>
                </Link>
                e a Política de Cookies da plataforma <b>sciencin</b>.
              </label>
            </div>
            <div className="text-center mt-3">
              <ButtonDarkGreen
                className={`d-flex justify-content-center align-items-center gap-2 ${
                  !responsive.sm ? "p-3 w-100 fs-4" : "w-100 fs-4"
                }`}
                isSubmit={true}
                disabled={isSubmitting || !isValid}
              >
                <Spinner
                  as="span"
                  animation="border"
                  role="status"
                  aria-hidden="true"
                  className={isSubmitting ? "" : "invisible"}
                />
                INSCREVER-SE
              </ButtonDarkGreen>
            </div>

            {!responsive.sm && (
              <div className="d-flex flex-column align-items-center mt-2">
                <span style={{ fontSize: "15px" }} className="fw-bold">
                  Você receberá um email de confirmação para autenticação do
                  cadastro!
                </span>
                <div className="text-center mt-4">
                  Já tem uma conta? {}
                  <span
                    onClick={() => {
                      setRedirecionamentoEmail(false);
                      setShowModalLogin(true);
                    }}
                    style={{ color: "#002776" }}
                    className="fw-bold text-decoration-underline cursor-pointer"
                  >
                    Login
                  </span>
                </div>
              </div>
            )}
          </form>
        )}
      </Formik>
      <ModalLogin
        show={showModalLogin}
        redirecionamentoEmail={redirecionamentoEmail}
        onShowChange={() => setShowModalLogin(false)}
        onRedirect={onRedirect}
        emailFill={emailFill}
      ></ModalLogin>
    </div>
  );
}

type FormikFields = {
  nome: string;
  email: string;
  password: string;
  aceito: boolean;
};

export type OutputPainelCadastro = {
  auth_id: string;
  name: string;
};

const validator = Yup.object().shape({
  nome: Yup.string()
    .min(UsuarioScyggzValidator.MIN_SIZE_NOME, "Nome muito curto")
    .required("Nome obrigatório"),
  email: Yup.string().email("Email inválido").required("Email obrigatório"),
  password: Yup.string()
    .min(
      UsuarioScyggzValidator.MIN_SIZE_PASSWORD,
      UsuarioScyggzValidator.MIN_SIZE_PASSWORD_ERROR
    )
    .required("Senha obrigatória"),
  aceito: Yup.boolean().isTrue(),
});
