import { useEffect, useState } from "react";

export function InputText({
  label,
  value = "",
  onChange,
  id,
  title,
  min,
  max,
  className = "",
}: {
  label: string;
  value?: string;
  onChange: (value: string) => void;
  id: string;
  title: string;
  min?: { value: number; errorMessage: string };
  max?: { value: number; errorMessage: string };
  className?: string;
}) {
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  useEffect(() => {
    if (min && value.length < min.value) {
      setError(min.errorMessage);
    } else if (max && value.length > max.value) {
      setError(max.errorMessage);
    } else {
      setError(undefined);
    }
  }, [max, min, value.length]);

  return (
    <div className={className}>
      <label className="form-label fw-bolder m-0" htmlFor={id}>
        {label}
      </label>

      <input
        className={"form-control" + (error && isChanged ? " is-invalid" : "")}
        type="text"
        title={title}
        min={min?.value}
        max={max?.value}
        onChange={({ target: { value } }) => {
          onChange(value);
          setIsChanged(true);
        }}
        onBlur={() => setIsChanged(true)}
        id={id}
        value={value}
      />

      <div className="d-flex justify-content-between">
        <div className="text-danger flex-fill" style={{ fontSize: ".85rem" }}>
          {isChanged && <>{error}</>}
        </div>

        <span className="text-black-50 fw-lighter">
          {value.trim().length}

          {max && <>/{max.value}</>}
        </span>
      </div>
    </div>
  );
}
