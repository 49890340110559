import { createContext } from "react";

import { TipoDeGrupoDePesquisaOutput } from "../../domain/entities/tipoDeGrupoDePesquisa";
import { Result } from "../../typings";

export const TipoGrupoContext = createContext<{
  allTipoGrupoResult: Result<TipoDeGrupoDePesquisaOutput[]> | undefined;
  refresh: (() => void) | undefined;
}>({
  allTipoGrupoResult: undefined,
  refresh: undefined,
});
