import { useContext, useMemo } from "react";
import { useParams } from "react-router-dom";

import { UserContentPage } from "./components/UserContentPage";
import { CurrentUserContext } from "../../../globalStore/CurrentUserContext";
import { useUserPerfil } from "../../../hooks/useUserPerfil";

export function UserPage() {
  const { id_user, id_perfil } = useParams();
  const {
    currentUserScyggz: { user: currentUser },
  } = useContext(CurrentUserContext);

  const userPerfil = useUserPerfil({
    id_user,
    id_perfil,
    currentUser,
  });

  const { currentUserScyggz } = useContext(CurrentUserContext);

  const isCurrentUser = useMemo<boolean>(
    () =>
      !!userPerfil &&
      userPerfil.ok &&
      userPerfil.value.user.auth_user_id ==
        currentUserScyggz.user?.auth_user_id,
    [currentUserScyggz.user?.auth_user_id, userPerfil]
  );

  return (
    <UserContentPage
      baseUrl={"/user2"}
      isCurrentUser={isCurrentUser}
      current_user_auth_id_conexoes={currentUserScyggz.user?.auth_id_conexoes}
      userPerfil={userPerfil}
    />
  );
}
