import {
  ContatoEmail,
  isInvalidContatoEmail,
} from "../../domain/entities/ContatoEmail";
import { Firestore, addDoc, collection } from "@firebase/firestore";

import { HandlerContatoEmailSend } from "../../domain/usecases/interfaces/HandlerContatoEmailSend";
import { Result } from "../../typings";

export class HandlerContatoEmailSendFirebase
  implements HandlerContatoEmailSend
{
  constructor(private firestore: Firestore) {}

  send(
    contatoEmail: ContatoEmail,
    authenticated: boolean
  ): Promise<Result<void>> {
    return new Promise((resolve) => {
      const contatoEmailFirestore: ContatoEmailFirestore = {
        toUids: ["GfcnCuUECcOESqDnLWLBeeCW0XF2", "liliam_sanchez_carrete"],
        template: {
          name: authenticated ? "email_autenticado" : "email_visitante",
          data: contatoEmail,
        },
      };

      const invalidContato = isInvalidContatoEmail(contatoEmail);

      if (invalidContato) {
        resolve({
          ok: false,
          error: new Error("invalid contato email"),
        });
      }

      addDoc(collection(this.firestore, "mail"), contatoEmailFirestore)
        .then(() => {
          resolve({
            ok: true,
            value: undefined,
          });
        })
        .catch((error) => {
          resolve({
            ok: false,
            error,
          });
        });
    });
  }
}

export type ContatoEmailFirestore = {
  template: {
    name: "email_visitante" | "email_autenticado";
    data: ContatoEmail;
  };
  to?: string;
  toUids?: string[];
};
