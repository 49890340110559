import { Button, Modal } from "react-bootstrap";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  ESTAGIO_STARTUP,
  EstagioStartup,
} from "../../../../../domain/entities/EstagioStartup";
import { BadgeInfo } from "./BadgeInfo";
import { Result } from "../../../../../typings";
import { Mentor } from "../../../../../domain/entities/Mentor";

export const InfoEstagioInteresse = ({
  estagio_interesse,
  canUpdate,
}: {
  estagio_interesse: EstagioStartup[];
  canUpdate?: (estagio_interesse: EstagioStartup[]) => Promise<Result<null>>;
}) => {
  const [valueEstagioInteresse, setValueEstagioInteresse] =
    useState<EstagioStartup[]>(estagio_interesse);
  const [showModalEdit, setShowModalEdit] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>();
  const [carregando, setCarregando] = useState<boolean>(false);

  useEffect(() => {
    const validateInteresseEstagioResult =
      Mentor.validateInteresseStartupEstagio(valueEstagioInteresse);

    if (validateInteresseEstagioResult.ok) {
      setErrorMsg(undefined);
    } else {
      setErrorMsg(validateInteresseEstagioResult.error.message);
    }
  }, [valueEstagioInteresse]);

  return (
    <div className="mb-3">
      <h5 className="text-green-strong fw-bolder">Estágio do ciclo de vida</h5>

      {canUpdate && (
        <button
          type="button"
          className="btn btn-primary rounded-circle ms-2"
          onClick={() => setShowModalEdit(true)}
        >
          <FontAwesomeIcon icon={faPencil} />
        </button>
      )}

      {estagio_interesse.map((estagio, i) => (
        <BadgeInfo children={estagio} key={i} />
      ))}

      <Modal
        show={showModalEdit}
        onHide={() => setShowModalEdit(false)}
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Modal.Title>Editar estágio da startup de interesse</Modal.Title>

          <div
            style={{
              borderColor: "#ccc",
              borderWidth: 1,
              borderStyle: "solid",
            }}
            className={
              "d-flex justify-content-between flex-wrap rounded-1 p-2 mt-3" +
              (errorMsg ? " is-invalid" : "")
            }
          >
            {ESTAGIO_STARTUP.map((estagio) => (
              <div key={estagio} className={"form-check form-check-inline"}>
                <input
                  className="form-check-input checkboxDarkGreen"
                  type="checkbox"
                  name="interesse_startup_estagio"
                  value={estagio}
                  checked={valueEstagioInteresse.includes(estagio)}
                  onChange={({ target: { checked } }) => {
                    if (checked)
                      setValueEstagioInteresse([
                        ...valueEstagioInteresse,
                        estagio,
                      ]);
                    else {
                      setValueEstagioInteresse(
                        valueEstagioInteresse.filter(
                          (valueEstagio) => valueEstagio != estagio
                        )
                      );
                    }
                  }}
                  id={"rdb" + estagio}
                />
                <label className="form-check-label" htmlFor={"rdb" + estagio}>
                  {estagio}
                </label>
              </div>
            ))}
          </div>
          <div className="invalid-feedback flex-fill">{errorMsg}</div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            disabled={!!errorMsg || carregando}
            onClick={async () => {
              if (canUpdate) {
                setCarregando(true);

                const handlerUpdateResult = await canUpdate(
                  valueEstagioInteresse
                );

                setCarregando(false);

                if (!handlerUpdateResult.ok) {
                  console.warn(handlerUpdateResult.error);
                  return;
                }
              }

              setShowModalEdit(false);
            }}
          >
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
