import { ReactNode } from "react";

export const TextH2Info = ({
  children,
  classes,
}: {
  children: ReactNode;
  classes?: string;
}) => {
  return (
    <h2
      className={`${classes}`}
      style={{ fontSize: "20px", fontWeight: "500", marginBottom: "0" }}
    >
      {children}
    </h2>
  );
};
