import { createContext } from "react";

import { UsuarioScyggz } from "../domain/entities/usuarioScyggz";
import { Result } from "../typings";

export const UsuarioInfoContext = createContext<UsuarioInfoContextType>({
  usuarioInfoResult: undefined,
});

export type UsuarioInfoContextType = {
  usuarioInfoResult: Result<Readonly<UsuarioScyggz>> | undefined;
};
