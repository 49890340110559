import { Result } from "../../typings";
import { EstagioStartup } from "./EstagioStartup";
import { MecanismoDeSolucaoTecnologica } from "./mecanismoDeSolucaoTecnologica";
import { ModeloNegocio } from "./modeloNegocio";
import { SegmentoAtuacao } from "./segmentoAtuacao";
import { LocalizacaoOutput } from "./localizacao";
import { Startup } from "./Startup";
import { ValorFinanceiro } from "./ValorFinanceiro";
import { AreaInvestimento } from "./AreaInvestimento";
import { TeseDeInvestimento } from "./teseDeInvestimento";
import { Premiacao } from "./premiacao";

export class VentureCapital {
  private constructor(private input: InputVentureCapital) {}
  public static create(input: InputVentureCapital): Result<VentureCapital> {
    const errors: Error[] = [];

    // nome: string;
    // telefone: string;
    // email: string;
    // areas_de_investimento: AreaInvestimentoOutput[];
    // tese_de_investimento: TeseDeInvestimentoOutput;
    // gestores: { auth_id: string }[];
    // interesse_startup_estagio: EstagioStartup[];
    // interesse_startup_modelo_negocio: ModeloNegocioOutput[];
    // interesse_startup_segmento_atuacao: SegmentoAtuacaoOutput[];
    // interesse_startup_mecanismo_solucao: MecanismoDeSolucaoTecnologicaOutput[];
    // TODO: validate input Venture Capital entity

    if (errors.length > 0) {
      return {
        ok: false,
        error: errors[0],
      };
    }

    return {
      ok: true,
      value: new VentureCapital(input),
    };
  }
  public get nome() {
    return this.input.nome;
  }

  public get telefone() {
    return this.input.telefone;
  }

  public get email() {
    return this.input.email;
  }

  public get areas_de_investimento() {
    return this.input.areas_de_investimento;
  }

  public get gestores() {
    return this.input.gestores;
  }

  public get interesse_startup_estagio() {
    return this.input.interesse_startup_estagio;
  }

  public get interesse_startup_modelo_negocio() {
    return this.input.interesse_startup_modelo_negocio;
  }

  public get interesse_startup_segmento_atuacao() {
    return this.input.interesse_startup_segmento_atuacao;
  }

  public get interesse_startup_mecanismo_solucao() {
    return this.input.interesse_startup_mecanismo_solucao;
  }

  public get habilitado() {
    return this.input.habilitado;
  }

  public get website() {
    return this.input.website;
  }

  public get premiacoes() {
    return this.input.premiacoes;
  }

  public get startups_investidas() {
    return this.input.startups_investidas;
  }

  public get volume_recursos_sob_gestao() {
    return this.input.volume_recursos_sob_gestao;
  }

  public get path_logo() {
    return this.input.path_logo;
  }

  public get interesse_startup_localizacoes() {
    return this.input.interesse_startup_localizacoes;
  }
}

export type InputVentureCapital = {
  nome: string;
  telefone: string;
  email: string;
  areas_de_investimento: Pick<AreaInvestimento, "id" | "nome">[];
  tese_de_investimento: Pick<TeseDeInvestimento, "conteudo">;
  gestores: string[];
  interesse_startup_estagio: EstagioStartup[];
  interesse_startup_modelo_negocio: Pick<ModeloNegocio, "id" | "nome">[];
  interesse_startup_segmento_atuacao: Pick<SegmentoAtuacao, "id" | "nome">[];
  interesse_startup_mecanismo_solucao: Pick<
    MecanismoDeSolucaoTecnologica,
    "id" | "nome"
  >[];
  created_in: Date;
  habilitado: boolean;
} & Partial<{
  website: string; // TODO: VALIDATE URL
  premiacoes: Pick<Premiacao, "nome" | "descricao">[];
  startups_investidas: Pick<Startup, "id" | "nome" | "cidade" | "path_logo">[];
  volume_recursos_sob_gestao: Pick<ValorFinanceiro, "moeda" | "valor">;
  path_logo: string;
  interesse_startup_localizacoes: LocalizacaoOutput[];
}>;

export type OutputVentureCapital = InputVentureCapital & {
  id: string;
};
