import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { CurrentUserContext } from "../../../../../globalStore/CurrentUserContext";
import { ContainerResponsive } from "../../../../generalComponents/ContainerResponsive";
import { ConteudoAvatar } from "../../components/ConteudoAvatar";

export const PainelCadastroFotoUsuarioEmpresa = () => {
  const navigate = useNavigate();

  const { currentUserScyggz } = useContext(CurrentUserContext);

  const paginacao = {
    start: 4,
    end: 8,
  };

  const backUrl = "/cadastro/empresa/3";

  return (
    <ContainerResponsive
      backUrlMobile={backUrl}
      paginacao={paginacao}
      textoMarcaDagua="Empresa"
      showLogo={true}
    >
      <>
        {currentUserScyggz.user && (
          <ConteudoAvatar
            user={currentUserScyggz.user}
            onBack={() => {
              navigate(backUrl);
            }}
            onCadastro={() => {
              navigate("/cadastro/empresa/5");
            }}
          />
        )}
      </>
    </ContainerResponsive>
  );
};
