import { ReactNode, useCallback, useContext, useEffect, useState } from "react";

import { InvestidorAnjo } from "../../domain/entities/InvestidorAnjo";
import { HandlerGetInvestidorAnjoFirebase } from "../../gateway/getPerfil/handlerGetInvestidorAnjoFirebase";
import { FirebaseContext } from "../FirebaseContext";
import {
  PerfilInvestidorContext,
  TypePerfilInvestidorContext,
} from "./PerfilInvestidorContext";
import { InputHandlerUpdateInvestidorSemId } from "../../domain/usecases/interfaces/handlerUpdateInvestidor";
import { HandlerUpdateInvestidorFirebase } from "../../gateway/HandlerUpdateInvestidorFirebase";

export function PerfilInvestidorFirebaseProvider({
  investidor,
  children,
}: {
  investidor: Pick<InvestidorAnjo, "user_id">;
  children: ReactNode;
}) {
  const [perfilInvestidorResult, setPerfilInvestidorResultResult] =
    useState<TypePerfilInvestidorContext>();

  const { firestore } = useContext(FirebaseContext);

  const refreshInvestidor = useCallback(() => {
    new HandlerGetInvestidorAnjoFirebase(firestore)
      .get(investidor.user_id)
      .then((result) => {
        setPerfilInvestidorResultResult(
          result.ok
            ? {
                ok: true,
                value: {
                  user_id: result.value.user_id,
                  nome: result.value.nome,
                  habilitado: !!result.value.habilitado,
                  areas_de_especializacao: result.value.areas_de_especializacao,
                  associacoes_anjo: result.value.associacoes_anjo,
                  interesse_startup_estagio:
                    result.value.interesse_startup_estagio,
                  interesse_startup_localizacoes:
                    result.value.interesse_startup_localizacoes,
                  interesse_startup_mecanismo_solucao:
                    result.value.interesse_startup_mecanismo_solucao,
                  interesse_startup_modelo_negocio:
                    result.value.interesse_startup_modelo_negocio,
                  interesse_startup_segmento_atuacao:
                    result.value.interesse_startup_segmento_atuacao,
                  premiacoes_e_certificados:
                    result.value.premiacoes_e_certificados,
                  startups_investidas: result.value.startups_investidas,
                  tese_de_investimento: result.value.tese_de_investimento,
                  created_in: new Date(),
                },
              }
            : result
        );
      });
  }, [firestore, investidor.user_id]);

  const updateInvestidor = useCallback(
    (inputInvestidor: InputHandlerUpdateInvestidorSemId) => {
      return new HandlerUpdateInvestidorFirebase(firestore).updateInvestidor({
        ...inputInvestidor,
        user_id: investidor.user_id,
      });
    },
    [investidor, firestore]
  );

  useEffect(() => {
    refreshInvestidor();
  }, [refreshInvestidor]);

  return (
    <PerfilInvestidorContext.Provider
      value={{
        perfilInvestidorResult,
        updateInvestidor,
        refreshInvestidor,
      }}
      children={children}
    />
  );
}
