import { UserStatus } from "../entities/userStatusHandler";
import Splash from "../presentation/pages/components/splash/Splash";

export const rotasCarregandoAuth = [
  {
    userStatus: ["carregando_auth"] as UserStatus[],
    path: "/*",
    element: <Splash />,
    showHeader: false,
    showFooter: false,
  },
];
