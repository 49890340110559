import { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsersViewfinder } from "@fortawesome/free-solid-svg-icons";
import { ProgressBar } from "react-bootstrap";

import { ProgramaConexao } from "../../../../domain/entities/ProgramaConexao";
import { HandlersDatabaseContext } from "../../../../globalStore/HandlersProviders/HandlersDatabaseContext";
import { Result } from "../../../../typings";
import { ListAllItemProgramaDeConexao } from "./ListAllItemProgramaDeConexao";

export function ListAllProgramaDeConexao() {
  const { handlerProgramaConexaoGetAll } = useContext(HandlersDatabaseContext);

  const [programasResult, setProgramasResult] =
    useState<Result<ProgramaConexao[]>>();

  useEffect(() => {
    handlerProgramaConexaoGetAll
      ?.getAll()
      .then((programasResult) => setProgramasResult(programasResult));
  }, [handlerProgramaConexaoGetAll]);

  return (
    <div className="card shadow-sm px-2">
      <div className="card-body">
        <h5 className="pb-2 fw-bolder">
          <FontAwesomeIcon icon={faUsersViewfinder} className="me-2" />
          Programas de conexão
        </h5>

        <>
          {programasResult ? (
            <>
              {programasResult.ok ? (
                <>
                  {programasResult.value.map((programa, index) => (
                    <ListAllItemProgramaDeConexao
                      key={programa.id}
                      programa={programa}
                      className={index == 0 ? "" : "mt-2"}
                    />
                  ))}
                </>
              ) : (
                <>erro</>
              )}
            </>
          ) : (
            <ProgressBar animated variant="info" now={100} className="w-100" />
          )}
        </>
      </div>
    </div>
  );
}
