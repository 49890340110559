import { useContext, useState } from "react";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ButtonDarkGreen } from "../../../../generalComponents/buttonDarkGreen/ButtonDarkGreen";
import { ButtonLightGreen } from "../../../../generalComponents/buttonLightGreen/ButtonLightGreen";
import { CheckboxDarkGreen } from "../../../../generalComponents/checkboxDarkGreen/CheckboxDarkGreen";
import { ResizeContext } from "../../../../../hooks/useResize";

export function PainelElegibilidade({
  requisitos,
  titulo,
  onNext,
  onPrevious,
}: {
  requisitos: string[];
  titulo: string;
  onNext: () => void;
  onPrevious: () => void;
}) {
  const [checkedElegivel, setCheckedElegivel] = useState(false);

  const { responsive } = useContext(ResizeContext);

  return (
    <>
      <h5 className="card-title fw-bolder">{titulo},</h5>
      <h6 className="card-subtitle fw-bold">
        Vamos confirmar sua elegibilidade:
      </h6>

      {requisitos.map((requisito, i) => (
        <p key={i} className="card-text mt-4 m-0 requisito">
          <FontAwesomeIcon icon={faCheck} className="pe-1" />
          {requisito}
        </p>
      ))}

      <div className="form-check mt-4">
        <CheckboxDarkGreen
          id="chkElegivel"
          value={checkedElegivel}
          onChange={() => setCheckedElegivel(!checkedElegivel)}
        />
        <label className="form-check-label" htmlFor="chkElegivel">
          Confirmo que sou elegível.
        </label>
      </div>
      <div className="text-end mt-3">
        {!responsive.sm && (
          <ButtonLightGreen onClick={onPrevious} className="text-uppercase">
            voltar
          </ButtonLightGreen>
        )}
        <ButtonDarkGreen
          className={`${responsive.sm ? "w-100" : "ms-2"} text-uppercase`}
          onClick={onNext}
          disabled={!checkedElegivel}
        >
          avançar
        </ButtonDarkGreen>
      </div>
    </>
  );
}
