import {
  Firestore,
  getDoc,
  doc,
  query,
  getDocs,
  collection,
  where,
  DocumentData,
} from "firebase/firestore";

import { Result } from "../typings";
import { HandlerGetUsuario } from "../domain/usecases/interfaces/HandlerGetUser";
import {
  InputUsuarioScyggzEntity,
  OutputUsuarioScyggzEntity,
  TipoPerfil,
  TIPOS_PERFIL,
  UsuarioScyggz,
} from "../domain/entities/usuarioScyggz";
import { fieldToDate } from "./utils/fieldToDate";
import { HandlerGetUsersWaitingAprovement } from "../domain/usecases/interfaces/HandlerGetUsersWaitingAprovement";
import { chunkArray } from "../utils/chunkArray";

export class HandlerGetUsuarioFirebase
  implements HandlerGetUsuario, HandlerGetUsersWaitingAprovement
{
  constructor(private firestore: Firestore) {}

  getUsuario(
    user: Pick<UsuarioScyggz, "auth_user_id">
  ): Promise<Result<Readonly<OutputUsuarioScyggzEntity>>> {
    return new Promise((resolve) => {
      const docUser = doc(this.firestore, "usuarios", user.auth_user_id);

      getDoc(docUser)
        .then((docUserSnapshot) => {
          if (docUserSnapshot.exists()) {
            resolve({
              ok: true,
              value: docDataToUser(docUserSnapshot.data(), docUserSnapshot.id),
            });
          } else {
            resolve({
              ok: false,
              error: new Error("user data undefined"),
            });
          }
        })
        .catch((error) => {
          resolve({
            ok: false,
            error,
          });
        });
    });
  }

  getUsuarios(
    ids: string[]
  ): Promise<Result<Readonly<OutputUsuarioScyggzEntity>[]>> {
    if (ids.length > 30) {
      console.warn("limitation of getUsuarios is 30");

      return new Promise((resolve) => {
        const getUsuariosPromises = chunkArray(ids, 30).map((ids) => {
          return this.getUsuarios(ids);
        });

        Promise.all(getUsuariosPromises).then((results) => {
          const usuarios: OutputUsuarioScyggzEntity[] = results.reduce<
            OutputUsuarioScyggzEntity[]
          >((prevUsuarios, result) => {
            if (result.ok) {
              prevUsuarios.push(...result.value);
            }
            return prevUsuarios;
          }, []);

          resolve({
            ok: true,
            value: usuarios,
          });
        });
      });
    }

    return new Promise((resolve) => {
      const docUser = query(
        collection(this.firestore, "usuarios"),
        where("auth_user_id", "in", ids)
      );

      getDocs(docUser)
        .then((querySnapshot) => {
          resolve({
            ok: true,
            value: querySnapshot.docs.map((doc) =>
              docDataToUser(doc.data(), doc.id)
            ),
          });
        })
        .catch((error) => {
          resolve({
            ok: false,
            error,
          });
        });
    });
  }

  getUsuariosWaitingAprovement(): Promise<
    Result<Readonly<InputUsuarioScyggzEntity>[]>
  > {
    return new Promise((resolve) => {
      const docUser = collection(this.firestore, "usuarios");

      getDocs(docUser)
        .then((querySnapshot) => {
          resolve({
            ok: true,
            value: querySnapshot.docs
              .map((doc) => docDataToUser(doc.data(), doc.id))
              .filter((user) =>
                Object.values(user.perfis ?? {}).some(
                  (enabled) => enabled === false
                )
              ),
          });
        })
        .catch((error) => {
          resolve({
            ok: false,
            error,
          });
        });
    });
  }
}

function docDataToUser(
  doc: DocumentData,
  id: string
): Readonly<OutputUsuarioScyggzEntity> {
  const usuario: OutputUsuarioScyggzEntity = {
    ...doc,
    auth_user_id: id,
    perfis: Object.keys(doc.perfis ?? {})
      .filter((perfil) => TIPOS_PERFIL.includes(perfil as TipoPerfil))
      .reduce<{ [id_perfil in TipoPerfil]?: boolean }>((prev, current) => {
        prev[current as TipoPerfil] =
          doc && doc.perfis && doc.perfis[current as TipoPerfil];
        return prev;
      }, {}),
    aceite_politica_de_privacidade: fieldToDate(
      doc.aceite_politica_de_privacidade
    ),
    aceite_termo_de_servico: fieldToDate(doc.aceite_termo_de_servico),
    cidade: doc.cidade,
    nome: doc.nome,
    telefone: doc.telefone,
  };

  return usuario;
}
