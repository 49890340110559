import { useEffect, useState } from "react";

import {
  STATUS_STARTUP_HUB,
  StatusStartupHub,
} from "../../domain/entities/Startup";

export function InputStatusHub({
  label,
  value,
  onChange,
  validate,
  className,
  disabled,
}: {
  label: string;
  value?: StatusStartupHub;
  onChange: (status_hub?: StatusStartupHub) => void;
  validate: (status_hub?: StatusStartupHub) => Error | undefined;
  className?: string;
  disabled?: boolean;
}) {
  const [error, setError] = useState<string>();
  const [isChanged, setIsChanged] = useState<boolean>(false);

  useEffect(() => {
    const error = validate(value);
    if (error) {
      setError(error.message);
    } else {
      setError(undefined);
    }
  }, [validate, value]);

  return (
    <div className={className}>
      <label
        className={
          "form-label w-100" + (error && isChanged ? " is-invalid" : "")
        }
      >
        Hub de Inovação (status)
      </label>
      <div className="text-center">
        {STATUS_STARTUP_HUB.map((status) => (
          <div className="form-check form-check-inline" key={status}>
            <input
              className="form-check-input checkboxDarkGreen"
              type="radio"
              name={label}
              value={value}
              checked={value == status}
              onChange={({ target: { checked } }) => {
                if (checked) onChange(status);
                setIsChanged(true);
              }}
              onBlur={() => setIsChanged(true)}
              id={"rdb" + status}
              disabled={disabled}
            />
            <label className="form-check-label" htmlFor={"rdb" + status}>
              {status}
            </label>
          </div>
        ))}
      </div>
      <div className="invalid-feedback flex-fill">{error}</div>
    </div>
  );
}
