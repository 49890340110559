import { useContext, useEffect, useState } from "react";

import { ResizeContext } from "../../hooks/useResize";
import { Auth } from "../../domain/entities/Auth";

export function InputEmail({
  label,
  value = "",
  onChange,
  required = false,
}: {
  label: string;
  value?: string;
  onChange: (email?: string) => void;
  required?: boolean;
}) {
  const { responsive } = useContext(ResizeContext);

  const [error, setError] = useState<string>();
  const [isChanged, setIsChanged] = useState<boolean>(false);

  useEffect(() => {
    const validateResult = Auth.isValidEmail(value);

    if (validateResult) {
      setError(undefined);
    } else {
      if (value || required) {
        setError("Email inválido");
      } else {
        setError(undefined);
      }
    }
  }, [required, value]);

  return (
    <div className={"mb-3 has-validation"}>
      <label className="form-label fw-bolder m-0" htmlFor="iptEmail">
        {label}
      </label>

      <input
        type="email"
        title="Email"
        className={
          `${responsive.sm ? "imputMobile" : "form-control"}` +
          (error && isChanged ? " is-invalid" : "")
        }
        id="iptEmail"
        onChange={({ target: { value } }) => {
          onChange(value);
          setIsChanged(true);
        }}
        onBlur={() => {
          setIsChanged(true);
        }}
        placeholder={responsive.sm ? "Email" : ""}
        value={value}
      />

      <div className="invalid-feedback">{error}</div>
    </div>
  );
}
