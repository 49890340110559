import { InputAuthEntity, OutputAuthEntity } from "../domain/entities/Auth";
import { AutenticacaoService } from "../domain/usecases/autenticacaoService";
import { HandlerCreateAuth } from "../domain/usecases/interfaces/handlerCreateAuth";
import { Result } from "../typings";

export class HandlerCreateAuthFirebase implements HandlerCreateAuth {
  createAuthUser(input: InputAuthEntity): Promise<Result<OutputAuthEntity>> {
    return new Promise<Result<OutputAuthEntity>>((resolve) => {
      const autenticacaoService: AutenticacaoService =
        AutenticacaoService.newAutenticacaoService();

      return autenticacaoService
        .cadastrarUsuario(input.email, input.password)
        .subscribe({
          next: (userCredential) =>
            resolve({
              ok: true,
              value: {
                auth_id: userCredential.user.uid,
                email: userCredential.user.email ?? "",
                emailVerified: userCredential.user.emailVerified,
              },
            }),
          error: (error) =>
            resolve({
              ok: false,
              error,
            }),
        });
    });
  }
}
