/* eslint-disable @typescript-eslint/no-explicit-any */
import { Formik } from "formik";
import * as Yup from "yup";
import { useContext, useState, useEffect } from "react";

import { ButtonDarkGreen } from "../../../../generalComponents/buttonDarkGreen/ButtonDarkGreen";
import { ButtonLightGreen } from "../../../../generalComponents/buttonLightGreen/ButtonLightGreen";
import {
  Premiacao,
  PremiacaoOutput,
} from "../../../../../domain/entities/premiacao";
import { InputPremiacoes } from "../../../../generalComponents/InputPremiacoes/InputPremiacoes";
import { Mentor, MentorOutput } from "../../../../../domain/entities/Mentor";
import { CurrentUserContext } from "../../../../../globalStore/CurrentUserContext";
import { ResizeContext } from "../../../../../hooks/useResize";
import {
  CacheMentor,
  HandlerCacheMentor,
} from "../../../../../domain/usecases/interfaces/HandlerCacheMentor";
import { Sobre } from "../../../../../domain/entities/sobre";
import { InputSobre } from "../../../../generalComponents/InputSobre";
import { InputHorasMensal } from "../../../../generalComponents/InputHorasMensal";
import { Spinner } from "react-bootstrap";

export function ConteudoSobreMentor({
  handlerCacheMentor,
  onBack,
  onNext,
}: {
  handlerCacheMentor: HandlerCacheMentor;
  onBack: () => void;
  onNext: () => void;
}) {
  const { responsive } = useContext(ResizeContext);

  const { currentUserScyggz } = useContext(CurrentUserContext);

  const [parteDoNome, setParteDoNome] = useState("");

  const [cacheMentor, setCacheMentor] = useState<CacheMentor>({});
  const [isEditingPremiacoes, setIsEditingPremiacoes] =
    useState<boolean>(false);

  useEffect(() => {
    handlerCacheMentor.get().then((cacheMentorResult) => {
      if (cacheMentorResult.ok) {
        setCacheMentor(cacheMentorResult.value);
      }
    });
  }, [handlerCacheMentor]);

  useEffect(() => {
    if (currentUserScyggz.user) {
      const parteDoNome: string =
        currentUserScyggz.user.nome.split(" ").at(0) ??
        currentUserScyggz.user.nome;

      setParteDoNome(parteDoNome);
    }
  }, [currentUserScyggz]);

  function handleChangeField(
    field: string,
    value: any,
    setFieldValue: (field: string, value: any) => void,
    setFieldTouched: (field: string, touched: boolean) => void
  ) {
    setFieldValue(field, value);
    setTimeout(() => setFieldTouched(field, true), 0);
  }

  return Formik<FormikFields>({
    initialValues: {
      sobre_o_mentor: cacheMentor.sobre_o_mentor ?? "",
      horas_disponibilidade_mensal: cacheMentor.horas_disponibilidade_mensal,
      premiacoes: cacheMentor.premiacoes,
    },
    validateOnMount: true,
    onSubmit: async function (values) {
      await handlerCacheMentor.save({
        sobre_o_mentor: values.sobre_o_mentor,
        horas_disponibilidade_mensal: values.horas_disponibilidade_mensal,
        premiacoes: values.premiacoes,
      });
      onNext();
    },
    validationSchema: validator,
    enableReinitialize: true,
    children: ({
      handleSubmit,
      isValid,
      setFieldValue,
      setFieldTouched,
      submitForm,
      values,
      isSubmitting,
    }) => (
      <div
        className={`${!responsive.sm && "p-4"} pb-2`}
        data-testid="painel_cadastro_interesse_mentor"
      >
        <p style={{ fontSize: "22px" }} className="card-title fw-bold mb-4">
          <b>{parteDoNome}</b>, por favor fale de suas atividades como Mentor.
        </p>

        <form className="row" onSubmit={handleSubmit}>
          <div className="mb-3">
            <InputSobre
              label="Sobre o Mentor*"
              value={values.sobre_o_mentor}
              onChange={(sobre) => {
                handleChangeField(
                  "sobre_o_mentor",
                  sobre,
                  setFieldValue,
                  setFieldTouched
                );
              }}
            />

            <InputHorasMensal
              label="Disponibilidade mensal (horas):"
              value={values.horas_disponibilidade_mensal ?? 0}
              onChange={(horas_disponibilidade_mensal) => {
                handleChangeField(
                  "horas_disponibilidade_mensal",
                  horas_disponibilidade_mensal,
                  setFieldValue,
                  setFieldTouched
                );
              }}
            />

            <InputPremiacoes
              label="Liste suas premiações"
              value={values.premiacoes ?? []}
              onChange={(premiacoes) => {
                setFieldValue("premiacoes", premiacoes);
              }}
              showTip={true}
              isEditing={setIsEditingPremiacoes}
            />
          </div>

          <div className="text-end mt-3">
            <ButtonLightGreen
              onClick={() => {
                handlerCacheMentor.save({
                  sobre_o_mentor: values.sobre_o_mentor,
                  horas_disponibilidade_mensal:
                    values.horas_disponibilidade_mensal,
                  premiacoes: values.premiacoes,
                });
                onBack();
              }}
            >
              Voltar
            </ButtonLightGreen>
            <ButtonDarkGreen
              disabled={!isValid || isSubmitting || isEditingPremiacoes}
              onClick={submitForm}
              className="ms-2 pe-5"
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className={"me-2" + (isSubmitting ? "" : " invisible")}
              />
              Avançar
            </ButtonDarkGreen>
          </div>
        </form>
      </div>
    ),
  });
}

export type InputCadastroInteresseMentoriaMentor = {
  user_id: string;
  nome: string;
} & Partial<FormikFields>;

type FormikFields = {
  sobre_o_mentor: string;
} & Partial<{
  horas_disponibilidade_mensal: number;
  premiacoes: PremiacaoOutput[];
}>;

const validator = Yup.object().shape({
  sobre_o_mentor: Yup.string()
    .required("Campo obrigatório")
    .min(Sobre.MINIMO_LENGTH, ({ min }) => "Mínimo " + min + " caracteres")
    .max(Sobre.MAXIMO_LENGTH, ({ max }) => "Máximo " + max + " caracteres"),
  horas_disponibilidade_mensal: Yup.number()
    .min(Mentor.MINIMO_HORAS_DISPONIBILIDADE_MENSAL, "Campo inválido")
    .max(
      Mentor.MAXIMO_HORAS_DISPONIBILIDADE_MENSAL,
      ({ max }) => "Máximo " + max + " horas"
    ),
  premiacoes: Yup.array().of(
    Yup.object().shape({
      nome: Yup.string()
        .required("O nome é obrigatório")
        .min(
          Premiacao.NOME_MINIMO_LENGTH,
          "Mínimo " + Premiacao.NOME_MINIMO_LENGTH + " caracteres"
        )
        .max(
          Premiacao.NOME_MAXIMO_LENGTH,
          "Máximo " + Premiacao.NOME_MAXIMO_LENGTH + " caracteres"
        ),
      descricao: Yup.string().max(
        Premiacao.DESCRICAO_MAXIMO_LENGTH,
        "Máximo de " + Premiacao.DESCRICAO_MAXIMO_LENGTH + " caracteres."
      ),
    })
  ),
});

export type OutputCadastroInteresseMentoriaMentor = MentorOutput;
