import { useContext } from "react";

import { CurrentUserContext } from "../../../../globalStore/CurrentUserContext";
import Header from "./Header";
import HeaderInternal from "./HeaderInternal";

const HeaderToRender = () => {
  const { currentUserAuth } = useContext(CurrentUserContext);

  return !currentUserAuth.user_auth ? <Header /> : <HeaderInternal />;
};

export default HeaderToRender;
