import {
  Firestore,
  doc,
  updateDoc,
  getDocs,
  query,
  collection,
  where,
  deleteDoc,
  writeBatch,
} from "firebase/firestore";

import { Result, Writeable } from "../typings";
import { HandlerConviteStartupEmployeeAccept } from "../domain/usecases/interfaces/HandlerConviteStartupEmployeeAccept";
import { ConviteStartupEmployee } from "../domain/entities/ConviteStartupEmployee";
import { HandlerConviteStartupEmployeeReject } from "../domain/usecases/interfaces/HandlerConviteStartupEmployeeReject";
import { HandlerConviteStartupEmployeeGetByUserAlvo } from "../domain/usecases/interfaces/HandlerConviteStartupEmployeeGetByUserAlvo";
import { HandlerConviteStartupEmployeeCreate } from "../domain/usecases/interfaces/HandlerConviteStartupEmployeeCreate";
import { HandlerConviteStartupEmployeeGetAllAccepted } from "../domain/usecases/interfaces/HandlerConviteStartupEmployeeGetAllAccepted";
import { HandlerConviteStartupEmployeeGetAll } from "../domain/usecases/interfaces/HandlerConviteStartupEmployeeGetAll";
import { HandlerConviteStartupEmployeeDelete } from "../domain/usecases/interfaces/HandlerConviteStartupEmployeeDelete";
import { HandlerConviteStartupEmployeeGetAllNotRejected } from "../domain/usecases/interfaces/HandlerConviteStartupEmployeeGetAllNotRejected";

export class HandlerConviteStartupEmployeeFirestore
  implements
    HandlerConviteStartupEmployeeAccept,
    HandlerConviteStartupEmployeeReject,
    HandlerConviteStartupEmployeeGetByUserAlvo,
    HandlerConviteStartupEmployeeCreate,
    HandlerConviteStartupEmployeeGetAllAccepted,
    HandlerConviteStartupEmployeeGetAll,
    HandlerConviteStartupEmployeeDelete,
    HandlerConviteStartupEmployeeGetAllNotRejected
{
  readonly collectionPath = "convites_startup_employee";

  constructor(private firestore: Firestore) {}

  create(convites: ConviteStartupEmployee[]): Promise<Result<void, Error[]>> {
    if (convites.length < 1)
      return Promise.resolve({
        ok: true,
        value: undefined,
      });

    return new Promise((resolve) => {
      const writeConvitesBatch = writeBatch(this.firestore);

      convites.forEach((convite: Writeable<ConviteStartupEmployee>) => {
        convite.id = ConviteStartupEmployee.idFormat(convite);
        writeConvitesBatch.set(
          doc(this.firestore, this.collectionPath, convite.id),
          convite
        );
      });

      writeConvitesBatch
        .commit()
        .then(() => {
          resolve({
            ok: true,
            value: undefined,
          });
        })
        .catch((error) => {
          console.warn("create", error);
          resolve({
            ok: false,
            error,
          });
        });
    });
  }

  getByUserAlvo(id_alvo: string): Promise<Result<ConviteStartupEmployee[]>> {
    return new Promise((resolve) => {
      getDocs(
        query(
          collection(this.firestore, this.collectionPath),
          where("alvo.id", "==", id_alvo),
          where("alvo.typesStakeholder", "array-contains-any", [
            "mentor",
            "cientista",
            "investidor",
          ])
        )
      )
        .then((querySnapshot) => {
          const convites: ConviteStartupEmployee[] = querySnapshot.docs.map(
            (docSnapshot) => {
              const convite =
                docSnapshot.data() as Writeable<ConviteStartupEmployee>;
              convite.id = docSnapshot.id;
              return convite;
            }
          );

          resolve({
            ok: true,
            value: convites,
          });
        })
        .catch((error) => {
          console.warn("getByUserAlvo", error);
          resolve({
            ok: false,
            error,
          });
        });
    });
  }

  getAll(id_startup: string): Promise<Result<ConviteStartupEmployee[]>> {
    return new Promise((resolve) => {
      getDocs(
        query(
          collection(this.firestore, this.collectionPath),
          where("startup.id", "==", id_startup)
        )
      )
        .then((querySnapshot) => {
          const convites: ConviteStartupEmployee[] = querySnapshot.docs.map(
            (docSnapshot) => {
              const convite =
                docSnapshot.data() as Writeable<ConviteStartupEmployee>;
              convite.id = docSnapshot.id;
              return convite;
            }
          );

          convites.sort((conviteA, conviteB) =>
            conviteA.alvo.nome.localeCompare(conviteB.alvo.nome)
          );

          resolve({
            ok: true,
            value: convites,
          });
        })
        .catch((error) => {
          console.warn("getAll", error);
          resolve({
            ok: false,
            error,
          });
        });
    });
  }

  getAllNotRejected(
    id_startup: string
  ): Promise<Result<ConviteStartupEmployee[]>> {
    return new Promise((resolve) => {
      getDocs(
        query(
          collection(this.firestore, this.collectionPath),
          where("startup.id", "==", id_startup),
          where("status", "!=", "rejected")
        )
      )
        .then((querySnapshot) => {
          const convites: ConviteStartupEmployee[] = querySnapshot.docs.map(
            (docSnapshot) => {
              const convite =
                docSnapshot.data() as Writeable<ConviteStartupEmployee>;
              convite.id = docSnapshot.id;
              return convite;
            }
          );

          convites.sort(
            (conviteA, conviteB) =>
              conviteA.funcao.localeCompare(conviteB.funcao) &&
              conviteA.alvo.nome.localeCompare(conviteB.alvo.nome)
          );
          resolve({
            ok: true,
            value: convites,
          });
        })
        .catch((error) => {
          resolve({
            ok: false,
            error,
          });
        });
    });
  }

  getAllAccepted(
    id_startup: string
  ): Promise<Result<ConviteStartupEmployee[]>> {
    return new Promise((resolve) => {
      getDocs(
        query(
          collection(this.firestore, this.collectionPath),
          where("startup.id", "==", id_startup),
          where("status", "==", "accepted")
        )
      )
        .then((querySnapshot) => {
          const convites: ConviteStartupEmployee[] = querySnapshot.docs.map(
            (docSnapshot) => {
              const convite =
                docSnapshot.data() as Writeable<ConviteStartupEmployee>;
              convite.id = docSnapshot.id;
              return convite;
            }
          );

          convites.sort((conviteA, conviteB) =>
            conviteA.alvo.nome.localeCompare(conviteB.alvo.nome)
          );

          resolve({
            ok: true,
            value: convites,
          });
        })
        .catch((error) => {
          resolve({
            ok: false,
            error,
          });
        });
    });
  }

  accept(convite: Pick<ConviteStartupEmployee, "id">): Promise<Result<void>> {
    return this.updateConvite(convite, true);
  }

  reject(convite: Pick<ConviteStartupEmployee, "id">): Promise<Result<void>> {
    return this.updateConvite(convite, false);
  }

  updateConvite(
    convite: Pick<ConviteStartupEmployee, "id">,
    aceitar: boolean
  ): Promise<Result<void>> {
    return new Promise((resolve) => {
      const docConvite = doc(this.firestore, this.collectionPath, convite.id);

      const docUpdate: Pick<ConviteStartupEmployee, "status"> = {
        status: aceitar ? "accepted" : "rejected",
      };

      updateDoc(docConvite, docUpdate)
        .then(() => {
          resolve({
            ok: true,
            value: undefined,
          });
        })
        .catch((error) => {
          resolve({
            ok: false,
            error,
          });
        });
    });
  }

  delete(convite: Pick<ConviteStartupEmployee, "id">): Promise<Result<void>> {
    return new Promise((resolve) => {
      const docConvite = doc(this.firestore, this.collectionPath, convite.id);

      deleteDoc(docConvite)
        .then(() => {
          resolve({
            ok: true,
            value: undefined,
          });
        })
        .catch((error) => {
          resolve({
            ok: false,
            error,
          });
        });
    });
  }
}
