import { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import {
  TIPOS_PERFIL,
  TipoPerfil,
} from "../../../../domain/entities/usuarioScyggz";
import { InputAreasEspecializacao } from "../../../generalComponents/InputAreasEspecializacao";
import { InputPerfil } from "../../../generalComponents/InputPerfil";
import { InputAreasConhecimento } from "../../../generalComponents/InputAreasConhecimento";
import { AreaConhecimento } from "../../../../domain/entities/areaConhecimento";
import { AreaEspecializacao } from "../../../../domain/entities/areaEspecializacaoInvestidorAnjo";
import {
  ORGANIZACAO,
  PERFIL,
  TipoPerfilMM,
  TIPO_PERFIL_MM,
} from "../../../../domain/entities/TipoPerfilMM";
import { InputTipoPerfilMM } from "../../../generalComponents/InputTipoPerfilMM";
import { InputOrganizacao } from "../../../generalComponents/InputOrganizacao";
import { TipoOrganizacao } from "../../../../domain/entities/organizacaoScyggz";
import { TIPOS_CADASTRO } from "../../../../domain/entities/TipoCadastro";

export function HeaderFilter() {
  const [searchParams, setSearchParams] = useSearchParams();

  useMemo<TipoPerfilMM | undefined>(() => {
    const tipoVisualizacao = searchParams.get("visualizacao");
    if (
      tipoVisualizacao &&
      TIPO_PERFIL_MM.includes(tipoVisualizacao as TipoPerfilMM)
    ) {
      return tipoVisualizacao as TipoPerfilMM;
    }
  }, [searchParams]);

  const perfil = useMemo<TipoPerfil | undefined>(() => {
    const perfil = searchParams.get("perfil");

    if (perfil && TIPOS_PERFIL.includes(perfil as TipoPerfil)) {
      return perfil as TipoPerfil;
    }
  }, [searchParams]);

  const areas_de_conhecimento = useMemo<AreaConhecimento[] | undefined>(
    () =>
      searchParams
        .get("areas_de_conhecimento")
        ?.split(",")
        .filter((area) => area)
        .map((area) => ({
          id: area,
          nome: area,
        })),
    [searchParams]
  );

  const areas_de_especializacao = useMemo<AreaEspecializacao[] | undefined>(
    () =>
      searchParams
        .get("areas_de_especializacao")
        ?.split(",")
        .filter((area) => area)
        .map((area) => ({
          id: area,
          nome: area,
        })),
    [searchParams]
  );

  const organizacao = useMemo<TipoOrganizacao | undefined>(() => {
    const organizacao = searchParams.get("organizacao");

    if (
      organizacao &&
      TIPOS_CADASTRO.includes(organizacao as TipoOrganizacao)
    ) {
      return organizacao as TipoOrganizacao;
    }
  }, [searchParams]);

  const [tipoPerfilMM, setTipoPerfilMM] = useState<TipoPerfilMM>(PERFIL);

  const filtroPerfil =
    perfil == "cientista" && tipoPerfilMM == PERFIL ? (
      <InputAreasConhecimento
        label="Áreas de conhecimento"
        value={areas_de_conhecimento ?? []}
        onChange={(areas_de_conhecimento) => {
          setSearchParams((searchParams) => {
            if (areas_de_conhecimento && areas_de_conhecimento.length > 0) {
              searchParams.set(
                "areas_de_conhecimento",
                areas_de_conhecimento.map((area) => area.nome).join(",")
              );
            } else searchParams.delete("areas_de_conhecimento");

            return searchParams;
          });
        }}
        validate={() => undefined}
        className="d-flex flex-column"
      />
    ) : (
      <>
        {(perfil == "mentor" ||
          (perfil == "investidor" && tipoPerfilMM == PERFIL)) && (
          <InputAreasEspecializacao
            label="Áreas de especialização"
            value={areas_de_especializacao ?? []}
            onChange={(areas_de_especializacao) => {
              setSearchParams((searchParams) => {
                if (
                  areas_de_especializacao &&
                  areas_de_especializacao.length > 0
                )
                  searchParams.set(
                    "areas_de_especializacao",
                    areas_de_especializacao.map((area) => area.nome).join(",")
                  );
                else searchParams.delete("areas_de_especializacao");

                return searchParams;
              });
            }}
            validate={() => undefined}
          />
        )}
      </>
    );

  return (
    <div className="card col-12 bg-light p-2 flex-row align-items-end gap-2">
      <InputTipoPerfilMM
        label="Tipo Visualização"
        value={tipoPerfilMM}
        onChange={(tipoPerfilMM) => {
          setTipoPerfilMM(tipoPerfilMM);
          setSearchParams((searchParams) => {
            searchParams.set("visualizacao", tipoPerfilMM);
            if (tipoPerfilMM == PERFIL) {
              searchParams.delete("organizacao");
            } else {
              searchParams.delete("perfil");
              searchParams.delete("areas_de_conhecimento");
              searchParams.delete("areas_de_especializacao");
            }
            return searchParams;
          });
        }}
      />

      {tipoPerfilMM == ORGANIZACAO ? (
        <InputOrganizacao
          label={"Organização"}
          value={organizacao}
          onChange={(newOrganizacao) => {
            setSearchParams((searchParams) => {
              if (newOrganizacao) {
                searchParams.set("organizacao", newOrganizacao);
                searchParams.delete("perfil");
              } else {
                searchParams.delete("organizacao");
              }

              return searchParams;
            });
          }}
          className="d-flex flex-column"
        />
      ) : (
        <InputPerfil
          label={"Perfil"}
          value={perfil}
          onChange={(newPerfil) => {
            setSearchParams((searchParams) => {
              if (newPerfil) {
                searchParams.set("perfil", newPerfil);
                searchParams.delete("organizacao");
              } else {
                searchParams.delete("perfil");
              }

              searchParams.delete("areas_de_conhecimento");
              searchParams.delete("areas_de_especializacao");

              return searchParams;
            });
          }}
          className="d-flex flex-column"
        />
      )}

      {filtroPerfil}
    </div>
  );
}
