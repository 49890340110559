import { Result } from "../typings";
import { TipoCadastro } from "../domain/entities/TipoCadastro";
import { HandlerAceiteClear } from "../domain/usecases/interfaces/HandlerAceiteClear";
import { HandlerAceiteGet } from "../domain/usecases/interfaces/HandlerAceiteGet";
import { HandlerAceiteSet } from "../domain/usecases/interfaces/HandlerAceiteSet";

export class HandlerAceiteStorage
  implements HandlerAceiteClear, HandlerAceiteGet, HandlerAceiteSet
{
  private readonly keyAceitePrefix = "aceiteCadastroInScyggz";

  clear(perfil: TipoCadastro): Promise<Result<null, Error>> {
    if (!localStorage) {
      return Promise.resolve({
        ok: false,
        error: new Error("Não salvou o aceite pois o navegador é incompativel"),
      });
    }

    try {
      localStorage.removeItem(this.keyAceitePrefix + perfil);

      return Promise.resolve({
        ok: true,
        value: null,
      });
    } catch (error) {
      return Promise.resolve({
        ok: false,
        error: error as Error,
      });
    }
  }

  get(perfil: TipoCadastro): Promise<Result<Date | null, Error>> {
    const dataAceite = localStorage.getItem(this.keyAceitePrefix + perfil);

    if (!dataAceite) {
      return Promise.resolve({
        ok: true,
        value: null,
      });
    }

    return Promise.resolve({
      ok: true,
      value: new Date(dataAceite),
    });
  }

  set(perfil: TipoCadastro, dataAceite: Date): Promise<Result<null, Error>> {
    if (!localStorage) {
      return Promise.resolve({
        ok: false,
        error: new Error("Não salvou o aceite pois o navegador é incompativel"),
      });
    }

    try {
      localStorage.setItem(this.keyAceitePrefix + perfil, dataAceite.toJSON());

      return Promise.resolve({
        ok: true,
        value: null,
      });
    } catch (error) {
      return Promise.resolve({
        ok: false,
        error: error as Error,
      });
    }
  }
}
