import { PathRouteProps } from "react-router-dom";
import { UserStatus } from "../entities/userStatusHandler";

import { rotasNotVisitante } from "./rotasNotVisitante";

export function getRotasNotVisitante(): (PathRouteProps & {
  userStatus: UserStatus[];
  path: string;
  element: JSX.Element;
  showHeader: boolean;
  showFooter: boolean;
})[] {
  return rotasNotVisitante.map((rotaNotVisitante) => {
    return getRotaNotVisitante(rotaNotVisitante);
  });
}

function getRotaNotVisitante({
  path,
  element,
  showHeader,
  showFooter,
}: {
  path: string;
  element: JSX.Element;
  showHeader: boolean;
  showFooter: boolean;
}): PathRouteProps & {
  userStatus: UserStatus[];
  path: string;
  element: JSX.Element;
  showHeader: boolean;
  showFooter: boolean;
} {
  return {
    userStatus: [
      "carregando_user_scyggz",
      "carregando_user_phone_number",
      "usuario_com_telefone_pendente",
      "email_nao_verificado",
      "usuario_com_info_pendente",
      "usuario_sem_perfil",
      "usuario_ok",
    ] as UserStatus[],
    path,
    element,
    showHeader,
    showFooter,
  };
}
