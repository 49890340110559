import { Result } from "../../typings";
import { Estado } from "./estado";

export class Cidade {
  private constructor(public cidade: string, public estado: Estado) {}

  public static create({
    cidade,
    estado,
    pais,
    paisCode,
  }: CidadeInput): Result<Cidade> {
    const isValidCidade: boolean = cidade.length > 1;
    if (!isValidCidade)
      return {
        ok: false,
        error: new Error("Nome cidade inválido"),
      };

    const estadoResult = Estado.create({ estado, pais, paisCode });
    if (estadoResult == null)
      return {
        ok: false,
        error: new Error("Estado inválido"),
      };

    return {
      ok: true,
      value: new Cidade(cidade, estadoResult),
    };
  }
}

export type CidadeInput = {
  cidade: string;
  estado: string;
  pais: string;
  paisCode: string;
};
export type CidadeOutput = CidadeInput;

export type CidadeOutputWithTipo = CidadeOutput & { tipo: "cidade" };
