import { Firestore, addDoc, collection } from "@firebase/firestore";

import { Result } from "../typings";
import { HandlerCreateVentureCapital } from "../domain/usecases/interfaces/handlerCreateVentureCapital";
import {
  InputVentureCapital,
  OutputVentureCapital,
  VentureCapital,
} from "../domain/entities/VentureCapital";

export class HandlerCreateVentureCapitalFirebase
  implements HandlerCreateVentureCapital
{
  constructor(private firestore: Firestore) {}
  createVentureCapital(
    inputVentureCapital: InputVentureCapital
  ): Promise<Result<OutputVentureCapital>> {
    const inputToFirebase: InputVentureCapital = {
      nome: inputVentureCapital.nome,
      telefone: inputVentureCapital.telefone,
      email: inputVentureCapital.email,
      areas_de_investimento: inputVentureCapital.areas_de_investimento,
      tese_de_investimento: inputVentureCapital.tese_de_investimento,
      gestores: inputVentureCapital.gestores,
      interesse_startup_estagio: inputVentureCapital.interesse_startup_estagio,
      interesse_startup_modelo_negocio:
        inputVentureCapital.interesse_startup_modelo_negocio,
      interesse_startup_segmento_atuacao:
        inputVentureCapital.interesse_startup_segmento_atuacao,
      interesse_startup_mecanismo_solucao:
        inputVentureCapital.interesse_startup_mecanismo_solucao,
      created_in: new Date(),
      habilitado: false,
    };

    if (inputVentureCapital.website) {
      inputToFirebase.website = inputVentureCapital.website;
    }
    if (inputVentureCapital.premiacoes) {
      inputToFirebase.premiacoes = inputVentureCapital.premiacoes;
    }
    if (inputVentureCapital.startups_investidas) {
      inputToFirebase.startups_investidas =
        inputVentureCapital.startups_investidas;
    }
    if (inputVentureCapital.volume_recursos_sob_gestao) {
      inputToFirebase.volume_recursos_sob_gestao =
        inputVentureCapital.volume_recursos_sob_gestao;
    }
    if (inputVentureCapital.path_logo) {
      inputToFirebase.path_logo = inputVentureCapital.path_logo;
    }
    if (inputVentureCapital.interesse_startup_localizacoes) {
      inputToFirebase.interesse_startup_localizacoes =
        inputVentureCapital.interesse_startup_localizacoes;
    }

    const ventureCapitalCreateResult = VentureCapital.create(inputToFirebase);
    if (!ventureCapitalCreateResult.ok)
      return Promise.resolve(ventureCapitalCreateResult);

    return new Promise((resolve) => {
      addDoc(collection(this.firestore, "venture_capital"), inputToFirebase)
        .then((docStartup) =>
          resolve({
            ok: true,
            value: {
              ...inputToFirebase,
              id: docStartup.id,
            },
          })
        )
        .catch((error) =>
          resolve({
            ok: false,
            error,
          })
        );
    });
  }
}
