import Select, { StylesConfig } from "react-select";
import { useContext, useEffect, useState } from "react";
import makeAnimated from "react-select/animated";

import { ResizeContext } from "../../hooks/useResize";
import {
  MecanismoDeSolucaoTecnologica,
  MecanismoDeSolucaoTecnologicaOutput,
} from "../../domain/entities/mecanismoDeSolucaoTecnologica";
import { HandlersDatabaseContext } from "../../globalStore/HandlersProviders/HandlersDatabaseContext";

const animatedComponents = makeAnimated();

export function InputMecanismosSolucao({
  label,
  value,
  onChange,
  validate,
  className = "",
}: {
  label: string;
  value: MecanismoDeSolucaoTecnologica[];
  onChange: (mecanismoDeSolucao: MecanismoDeSolucaoTecnologica[]) => void;
  validate: (
    mecanismosDeSolucao: MecanismoDeSolucaoTecnologica[]
  ) => Error | undefined;
  className?: string;
}) {
  const { handlerGetMecanismoSolucao } = useContext(HandlersDatabaseContext);

  const { responsive } = useContext(ResizeContext);

  const [allMecanismoSolucao, setAllMecanismoSolucao] = useState<
    MecanismoDeSolucaoTecnologicaOutput[]
  >([]);
  const [isAllMecanismoSolucaoLoading, setIsAllMecanismoSolucaoLoading] =
    useState<boolean>(false);

  const [errorToShow, setErrorToShow] = useState<string>();
  const [isChanged, setIsChanged] = useState<boolean>(false);

  useEffect(() => {
    const errorValidate = validate(value);

    if (errorValidate) {
      setErrorToShow(errorValidate.message);
    } else {
      setErrorToShow(undefined);
    }
  }, [validate, value]);

  useEffect(() => {
    setIsAllMecanismoSolucaoLoading(true);
    if (handlerGetMecanismoSolucao)
      handlerGetMecanismoSolucao
        .getAllMecanismoSolucao()
        .then((handlerGetMecanismoSolucaoResult) => {
          if (handlerGetMecanismoSolucaoResult.ok)
            setAllMecanismoSolucao(handlerGetMecanismoSolucaoResult.value);

          setIsAllMecanismoSolucaoLoading(false);
        });
  }, [handlerGetMecanismoSolucao]);

  const customStylesSelect: StylesConfig<MecanismoDeSolucaoTecnologica, true> =
    {
      control: () => ({
        border: "none",
        borderBottom: "3px #666600 solid",
        display: "flex",
        background: " #fff",
        borderBottomLeftRadius: "8px",
        textAlign: "left",
      }),
    };

  return (
    <div className={className}>
      <label
        className="form-label fw-bolder m-0"
        htmlFor="selMecanismoDeSolucao"
      >
        {label}
      </label>
      <Select<MecanismoDeSolucaoTecnologica, true>
        isLoading={isAllMecanismoSolucaoLoading}
        options={allMecanismoSolucao}
        styles={responsive.sm ? customStylesSelect : undefined}
        getOptionLabel={(o) => o.nome}
        getOptionValue={(o) => o.id}
        components={animatedComponents}
        isMulti
        value={value}
        placeholder=""
        onChange={(mecanismosDeSolucao) => {
          onChange([...mecanismosDeSolucao]);
          setIsChanged(true);
        }}
        onBlur={() => setIsChanged(true)}
        className={errorToShow && isChanged ? "is-invalid " : ""}
        noOptionsMessage={() => "sem opção"}
        inputId="selMecanismoDeSolucao"
      />

      <div className="invalid-feedback flex-fill">{errorToShow}</div>
    </div>
  );
}
