import { Result } from "../../typings";
import { UsuarioScyggz } from "./usuarioScyggz";

export class Conexao {
  public status: StatusConexao = "iniciado";
  public created_at: Date = new Date();
  public updated_at: Date = new Date();

  private constructor(
    public autor: ParticipanteConexao,
    public alvo: ParticipanteConexao,
    public id_programa?: string
  ) {}

  public get id(): string {
    return Conexao.getId(this.autor, this.alvo);
  }

  public static getId(
    userA: Pick<UsuarioScyggz, "auth_user_id">,
    userB: Pick<UsuarioScyggz, "auth_user_id">
  ): string {
    if (userA.auth_user_id.localeCompare(userB.auth_user_id) < 0) {
      return userA.auth_user_id + "|" + userB.auth_user_id;
    }

    return userB.auth_user_id + "|" + userA.auth_user_id;
  }

  public static create(
    autor: ParticipanteConexao,
    alvo: ParticipanteConexao,
    id_programa?: string
  ): Result<Conexao> {
    if (autor.auth_user_id == alvo.auth_user_id) {
      return {
        ok: false,
        error: new Error("Não se pode criar uma conexão pra si mesmo"),
      };
    }

    return {
      ok: true,
      value: new Conexao(autor, alvo, id_programa),
    };
  }
}

export type StatusConexao = "iniciado" | "aprovado" | "negado";

export type ParticipanteConexao = Pick<
  UsuarioScyggz,
  "auth_user_id" | "nome" | "avatar_path" | "headline" | "cidade"
> &
  Partial<Pick<UsuarioScyggz, "perfis">>;
