import { Navigate } from "react-router-dom";
import { useEffect } from "react";

import PageNotFound from "../presentation/pages/PageNotFound";
import PoliticaDePrivacidade from "../presentation/pages/politicaDePrivacidade/PoliticaDePrivacidade";
import SobreNos from "../presentation/pages/SobreNos";
import TermosDeUso from "../presentation/pages/termosDeUso/TermosDeUso";
import { Contato } from "../presentation/pages/contato/Contato";
import { UserMgmt } from "../presentation/pages/userMgmt/UserMgmt";

export const rotasAll = [
  {
    path: "/scyggz",
    element: <SobreNos />,
    showHeader: true,
    showFooter: true,
  },
  {
    path: "/termosdeuso",
    element: <TermosDeUso />,
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/politicadeprivacidade",
    element: <PoliticaDePrivacidade />,
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/contato",
    element: <Contato />,
    showHeader: true,
    showFooter: true,
  },
  {
    path: "/*",
    element: <PageNotFound />,
    showHeader: true,
    showFooter: true,
  },
  {
    path: "/cadastro/cientista",
    element: <Navigate to={"/cadastro/cientista/1"} />,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/investidor",
    element: <Navigate to={"/cadastro/investidor/1"} />,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/mentor",
    element: <Navigate to={"/cadastro/mentor/1"} />,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/empresa",
    element: <Navigate to={"/cadastro/empresa/1"} />,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/hub",
    element: <Navigate to={"/cadastro/hub/1"} />,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/startup",
    element: <Navigate to={"/cadastro/startup/1"} />,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/nit",
    element: <Navigate to={"/cadastro/nit/1"} />,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/cadastro/venture_capital",
    element: <Navigate to={"/cadastro/venture_capital/1"} />,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/usermgmt",
    element: <UserMgmt />,
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/startup-world-cup-brasil-2023/*",
    element: (
      <>
        <Redirect link="https://scyggz.krably.com/startup-world-cup-brasil-2023" />
      </>
    ),
    showHeader: false,
    showFooter: false,
  },
];

function Redirect({ link }: { link: string }) {
  useEffect(() => {
    window.location.replace(link);
  }, [link]);

  return <></>;
}
