import { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";

import { HandlersStorageContext } from "../../globalStore/HandlersProviders/HandlersStorageContext";
import { HandlerGetURLFromPathResult } from "../../domain/usecases/interfaces/HandlerGetURLFromPath";
import { Result } from "../../typings";
import { ModalEditImage } from "./ModalEditImage";

export function ImgFromPathNew({
  path,
  className,
  style,
  alt,
  elementErro,
  handlerUpdate,
}: {
  path?: string;
  className?: string;
  style?: React.CSSProperties;
  alt: string;
  elementErro?: JSX.Element;
  handlerUpdate?: {
    update: (file: Blob) => Promise<Result<{ path: string }>>;
    modalButtonText: string;
    modalHeaderText: string;
  };
}): JSX.Element | null {
  const { handlerGetURLFromPath } = useContext(HandlersStorageContext);

  const [urlResult, setUrlResult] = useState<HandlerGetURLFromPathResult>();
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    if (path) {
      handlerGetURLFromPath?.getURL(path).then((result) => {
        setUrlResult(result);
      });
    } else {
      setUrlResult({
        ok: false,
        error: new Error("path undefined"),
      });
    }
  }, [handlerGetURLFromPath, path]);

  return (
    <div className="ratio ratio-1x1 position-relative">
      {urlResult ? (
        <div className="">
          {handlerUpdate && (
            <>
              <button
                type="button"
                className="btn bg-body-secondary position-absolute top-0 end-0 rounded-circle text-secondary p-0"
                style={{
                  zIndex: 2,
                  width: "20%",
                  minWidth: 30,
                  height: "20%",
                  minHeight: 30,
                }}
                onClick={() => setShowModal(true)}
              >
                <FontAwesomeIcon icon={faPencil} />
              </button>

              <ModalEditImage
                buttonText={handlerUpdate.modalButtonText}
                headerText={handlerUpdate.modalHeaderText}
                path={path}
                show={showModal}
                onShowChange={(show) => {
                  setShowModal(show);
                }}
                alt={alt}
                onUpdate={handlerUpdate.update}
              />
            </>
          )}

          {urlResult.ok ? (
            <img
              src={urlResult.value}
              alt={alt}
              className={"h-100 w-100 object-fit-cover " + className}
              style={style}
            />
          ) : (
            elementErro
          )}
        </div>
      ) : (
        <div className="placeholder-glow">
          <div
            className={"placeholder h-100 w-100 " + (className || "")}
            style={style}
          ></div>
        </div>
      )}
    </div>
  );
}
