import { ref, uploadBytes, FirebaseStorage } from "firebase/storage";
import { doc, setDoc, Firestore } from "firebase/firestore";

import {
  Avatar,
  InputAvatarEntity,
  OutputAvatarEntity,
} from "../domain/entities/avatar";
import { HandlerSaveAvatar } from "../domain/usecases/interfaces/handlerSaveAvatar";
import { Result } from "../typings";
import { OutputUsuarioScyggzEntity } from "../domain/entities/usuarioScyggz";

export class HandlerSaveAvatarFirebase implements HandlerSaveAvatar {
  constructor(
    private firebaseStorage: FirebaseStorage,
    private firestore: Firestore
  ) {}

  async save(
    inputAvatarEntity: InputAvatarEntity
  ): Promise<Result<OutputAvatarEntity>> {
    const avatarResult = Avatar.create(inputAvatarEntity);

    if (!avatarResult.ok) return avatarResult;

    const storageRef = ref(this.firebaseStorage, avatarResult.value.path);

    return new Promise((resolve) => {
      uploadBytes(storageRef, avatarResult.value.file)
        .then(async () => {
          const docUser = doc(
            this.firestore,
            "usuarios",
            avatarResult.value.auth_id
          );

          const logo: Pick<OutputUsuarioScyggzEntity, "avatar_path"> = {
            avatar_path: {
              path: storageRef.fullPath,
            },
          };

          await setDoc(docUser, logo, { merge: true });

          resolve(avatarResult);
        })
        .catch((error) =>
          resolve({
            ok: false,
            error,
          })
        );
    });
  }
}
