import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { CurrentUserContext } from "../../../../../globalStore/CurrentUserContext";
import { ContainerResponsive } from "../../../../generalComponents/ContainerResponsive";
import { PainelElegibilidade } from "../../components/PainelElegibilidade/PainelElegibilidade";
import { HandlersDatabaseContext } from "../../../../../globalStore/HandlersProviders/HandlersDatabaseContext";
import { HandleCacheNewUser } from "../../../../../domain/usecases/interfaces/HandleCacheNewUser";

type PainelElegibilidadeStartupProps = {
  handlerCacheNewUser?: HandleCacheNewUser;
};

export const PainelElegibilidadeStartup = ({
  handlerCacheNewUser,
}: PainelElegibilidadeStartupProps) => {
  const navigate = useNavigate();

  const { handlerAceiteSet } = useContext(HandlersDatabaseContext);
  const { currentUserScyggz } = useContext(CurrentUserContext);

  useEffect(() => {
    handlerCacheNewUser?.save(false);
  });

  useEffect(() => {
    const userJahTemPerfilStartup =
      currentUserScyggz.status == "encontrado" &&
      Object.hasOwn(currentUserScyggz.user?.perfis ?? {}, "startup");

    if (userJahTemPerfilStartup) navigate("/");
  }, [currentUserScyggz, navigate]);

  const textoMarcaDagua = "Startup";
  const paginacaoElegibilidadeStartup = {
    start: 1,
    end: 7,
  };
  const backUrlMobile = "/";
  const nextUrl = "/cadastro/startup/2";

  return (
    <ContainerResponsive
      textoMarcaDagua={textoMarcaDagua}
      paginacao={paginacaoElegibilidadeStartup}
      backUrlMobile={backUrlMobile}
      showLogo={true}
    >
      <PainelElegibilidade
        requisitos={[
          "Estou empreendendo ou para começar a empreender;",
          "Tenho interesse em apresentar minhas solução(ões) para investidores e empresas;",
          "Tenho interesse em receber mentoria.",
        ]}
        titulo={"Startup"}
        onNext={() => {
          if (handlerAceiteSet) handlerAceiteSet.set("startup", new Date());
          navigate(nextUrl);
        }}
        onPrevious={() => {
          navigate("/");
        }}
      />
    </ContainerResponsive>
  );
};
