import { Cidade } from "../../../../../domain/entities/cidade";
import { ImgFromPath } from "../../../../generalComponents/ImgFromPath";

export const CardLogoInfoEmpresa = ({
  nome,
  src,
  alt,
  local
}: {
  nome: string;
  src?: string;
  alt: string;
  local?: Readonly<Cidade>;
}) => {

  return (
    <div
      className="border rounded-4 border-dark d-flex align-items-center justify-content-center gap-3"
      style={{ height: "131px", width: "257px" }}
    >
      {src && (
        <ImgFromPath path={src} alt={alt} />
        // <img className="border rounded-circle " style={{ height: "73px", width: "73px" }} src={src} alt={alt} />
      )}
      <div className="d-flex flex-column align-items-start">
        <h4
          style={{
            fontSize: "18px",
            fontWeight: "700"
          }}>{nome}</h4>
        {local && (
          <span style={{
            fontSize: "11px",
            fontWeight: "500"
          }}>{`${local?.cidade}, ${local?.estado.estado} - ${local?.estado.pais.pais}`}</span>
        )}
      </div>
    </div>
  )
}