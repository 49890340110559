export const TextH5Info = ({
  children,
  classes,
}: {
  classes?: string;
  children?: string | number;
}) => {


  return (
    <h5 className={classes} style={{ fontSize: "18px", fontWeight: "bold", lineHeight: "22px", letterSpacing: "-1.5%", textAlign: "justify", marginBottom: "0" }}>
      {children}
    </h5>
  )
}