import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CacheNit,
  HandlerCacheNit,
} from "../../../../../domain/usecases/interfaces/HandlerCacheNit";

import { HandlerGetURLFromPath } from "../../../../../domain/usecases/interfaces/HandlerGetURLFromPath";

import { HandlerSaveLogoNit } from "../../../../../domain/usecases/interfaces/handlerSaveLogoNit";
import { CurrentUserContext } from "../../../../../globalStore/CurrentUserContext";
import { ContainerResponsive } from "../../../../generalComponents/ContainerResponsive";
import { ConteudoLogoNit } from "./ConteudoLogoNit";

export const PainelLogoNit = ({
  handlerSaveLogoNit,
  handlerGetURLFromPath,
  handlerCacheNit,
}: {
  handlerSaveLogoNit: HandlerSaveLogoNit;
  handlerGetURLFromPath: HandlerGetURLFromPath;
  handlerCacheNit: HandlerCacheNit;
}) => {
  const navigate = useNavigate();
  const { currentUserScyggz } = useContext(CurrentUserContext);
  const [cacheNit, setCacheNit] = useState<CacheNit>();

  const paginacao = {
    start: 6,
    end: 6,
  };

  useEffect(() => {
    handlerCacheNit.get().then((result) => {
      if (result.ok) {
        setCacheNit(result.value);
      }
    });
  }, [handlerCacheNit]);

  return (
    <section data-testid="painel-cadastro-logo-Nit" style={{ height: "100%" }}>
      <ContainerResponsive
        backUrlMobile="/cadastro/Nit/5"
        paginacao={paginacao}
        textoMarcaDagua="NIT"
        showLogo={true}
      >
        <ConteudoLogoNit
          nit={{
            id: cacheNit?.id ?? "",
            logo_path: cacheNit?.logo_path,
          }}
          user={
            currentUserScyggz.user ?? {
              nome: "",
            }
          }
          handlerSaveLogoNit={handlerSaveLogoNit}
          handlerGetURLFromPath={handlerGetURLFromPath}
          onNext={() => {
            navigate("/");
            handlerCacheNit.clear();
          }}
          handlerCacheNit={handlerCacheNit}
        />
      </ContainerResponsive>
    </section>
  );
};
