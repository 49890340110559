import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faPlus } from "@fortawesome/free-solid-svg-icons";
import CreatableSelect from "react-select/creatable";
import slug from "slug";
slug.defaults.modes.pretty.replacement = "_";
slug.defaults.modes.rfc3986.replacement = "_";

import {
  SelectGrupoDePesquisa,
  SelectTipoDeGrupoDePesquisa,
  TypeInputGrupoDePesquisa,
} from "./InputGruposDePesquisa";

export function InputEditGrupoDePesquisa({
  grupoDePesquisaToEdit,
  onSave,
  onCancel,
  allTipoGrupoPesquisa,
  isLoading,
  onChange,
}: {
  grupoDePesquisaToEdit: TypeInputGrupoDePesquisa;
  onSave: (grupoDePesquisa: TypeInputGrupoDePesquisa) => void;
  onCancel: () => void;
  allTipoGrupoPesquisa: SelectTipoDeGrupoDePesquisa[];
  isLoading: boolean;
  onChange: () => void;
}) {
  const [tipoGrupoPesquisaSelected, setTipoGrupoPesquisaSelected] =
    useState<SelectTipoDeGrupoDePesquisa | null>(null);
  const [grupoPesquisaSelected, setGrupoPesquisaSelected] =
    useState<SelectGrupoDePesquisa | null>(null);

  const [errors, setErrors] =
    useState<Partial<Record<keyof TypeInputGrupoDePesquisa, string>>>();

  useEffect(() => {
    setTipoGrupoPesquisaSelected(grupoDePesquisaToEdit.tipo);
    setGrupoPesquisaSelected(grupoDePesquisaToEdit.grupo);
  }, [grupoDePesquisaToEdit]);

  useEffect(() => {
    const errorTipo: string | undefined = tipoGrupoPesquisaSelected
      ? undefined
      : "Tipo obrigatório";

    const errorGrupo: string | undefined = grupoPesquisaSelected
      ? undefined
      : "Grupo obrigatório";

    if (errorTipo || errorGrupo) {
      setErrors({
        tipo: errorTipo,
        grupo: errorGrupo,
      });
    } else {
      setErrors(undefined);
    }
  }, [grupoPesquisaSelected, tipoGrupoPesquisaSelected]);

  return (
    <div className="shadow p-2 m-3 rounded bg-body-tertiary border">
      <h6 className="text-success">
        <FontAwesomeIcon icon={faPencil} className="me-2" />
        Editando
      </h6>

      <label
        className="form-label fw-bolder m-0"
        htmlFor="selTipoGrupoPesquisa"
      >
        Tipo de grupo de pesquisa
      </label>
      <CreatableSelect<SelectTipoDeGrupoDePesquisa, false>
        value={tipoGrupoPesquisaSelected}
        onChange={(tipoGrupoPesquisa) => {
          setTipoGrupoPesquisaSelected(tipoGrupoPesquisa);
          setGrupoPesquisaSelected(null);
          onChange();
        }}
        options={allTipoGrupoPesquisa}
        filterOption={(option, input) => {
          const inputSlug = slug(input);

          const nomeSlug: string = slug(option.data.nome ?? option.data.value);

          if (nomeSlug.includes(inputSlug)) return true;

          return false;
        }}
        noOptionsMessage={() => "sem opção"}
        placeholder=""
        getOptionLabel={(o) => o.nome ?? o.label}
        getOptionValue={(o) => o.id ?? o.value}
        formatCreateLabel={(nome) => (
          <span className="rounded p-1 text-bg-success">
            <FontAwesomeIcon icon={faPlus} className="me-1" />

            {nome}
          </span>
        )}
        isClearable={false}
        components={{
          SingleValue: ({ data, children }) => {
            return (
              <div
                style={{
                  gridArea: "1/1/2/3",
                }}
              >
                {data.__isNew__ ? (
                  <span className="rounded p-1 text-bg-success">
                    <FontAwesomeIcon icon={faPlus} className="me-1" />

                    {children}
                  </span>
                ) : (
                  <>{children}</>
                )}
              </div>
            );
          },
        }}
        createOptionPosition="first"
        isLoading={isLoading}
        inputId="selTipoGrupoPesquisa"
        className="mb-3"
      />

      <label className="form-label fw-bolder m-0" htmlFor="selGrupoPesquisa">
        Grupo de pesquisa
      </label>
      <CreatableSelect<SelectGrupoDePesquisa, false>
        value={grupoPesquisaSelected}
        placeholder=""
        isLoading={isLoading}
        getOptionLabel={(o) => o.nome ?? o.label}
        getOptionValue={(o) => o.id ?? o.value}
        onChange={(grupoPesquisa) => {
          setGrupoPesquisaSelected(grupoPesquisa);
          onChange();
        }}
        options={tipoGrupoPesquisaSelected?.grupos ?? []}
        filterOption={(option, input) => {
          const inputSlug = slug(input);

          const nomeSlug: string = slug(option.data.nome ?? option.data.value);

          if (nomeSlug.includes(inputSlug)) return true;

          return false;
        }}
        noOptionsMessage={() => "sem opção"}
        formatCreateLabel={(nome) => (
          <span className="rounded p-1 text-bg-success">
            <FontAwesomeIcon icon={faPlus} className="me-1" />

            {nome}
          </span>
        )}
        isClearable={false}
        components={{
          SingleValue: ({ data, children }) => {
            return (
              <div
                style={{
                  gridArea: "1/1/2/3",
                }}
              >
                {data.__isNew__ ? (
                  <span className="rounded p-1 text-bg-success">
                    <FontAwesomeIcon icon={faPlus} className="me-1" />

                    {children}
                  </span>
                ) : (
                  <>{children}</>
                )}
              </div>
            );
          },
        }}
        createOptionPosition="first"
        inputId="selGrupoPesquisa"
        className="mb-3"
      />

      <button
        type="button"
        className={
          "btn fw-bolder me-2" +
          (!errors ? " btn-outline-success" : " btn-outline-secondary")
        }
        onClick={() => {
          if (grupoPesquisaSelected && tipoGrupoPesquisaSelected) {
            onSave({
              grupo: grupoPesquisaSelected,
              tipo: tipoGrupoPesquisaSelected,
            });
          }
        }}
        disabled={!!errors}
      >
        Salvar
      </button>

      <button
        type="button"
        className="btn btn-outline-warning fw-bolder"
        onClick={onCancel}
      >
        Cancelar
      </button>
    </div>
  );
}
