import { createContext } from "react";

import {
  AutenticacaoService,
  UserAuthRealtime,
} from "../domain/usecases/autenticacaoService";
import { UserInfoRealtime } from "../domain/usecases/getUserInfoObserver";
import { UserPhoneNumberRealtime } from "../domain/usecases/getUserPhoneNumberObserver";
import { UserUrlLinkedinRealtime } from "../domain/usecases/getUrlLinkedinObserver";

export type UserContextType = {
  currentUserAuth: UserAuthRealtime;
  currentUserScyggz: UserInfoRealtime;
  currentUserPhoneNumber: UserPhoneNumberRealtime;
  currentUserUrlLinkedin: UserUrlLinkedinRealtime;

  refreshCurrentUserScyggz?: () => Promise<void>;

  setSeeAsPublic: React.Dispatch<React.SetStateAction<boolean>>;
  seeAsPublic: boolean;
  newUser?: false;
};

const autenticacaoService = AutenticacaoService.newAutenticacaoService();

export const defaultCurrentUserAuth: UserAuthRealtime =
  autenticacaoService.getCurrentUserAuth();
export const defaultUserScyggz: UserInfoRealtime = {
  status: "carregando",
  user: null,
};
export const defaultUserPhoneNumber: UserPhoneNumberRealtime = {
  status: "carregando",
  userPhoneNumber: null,
};
export const defaultUserUrlLinkedin: UserUrlLinkedinRealtime = {
  status: "carregando",
  urlLinkedin: null,
};

export const CurrentUserContext = createContext<UserContextType>({
  currentUserAuth: defaultCurrentUserAuth,
  currentUserScyggz: defaultUserScyggz,
  currentUserPhoneNumber: defaultUserPhoneNumber,
  currentUserUrlLinkedin: defaultUserUrlLinkedin,
  setSeeAsPublic: () => undefined,
  seeAsPublic: false,
  newUser: false,
});
