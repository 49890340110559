import { Button, Dropdown, Modal, ProgressBar } from "react-bootstrap";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import {
  IconDefinition,
  faEllipsis,
  faThumbsUp as thumbsUpChecked,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import {
  faMessage,
  faThumbsUp as thumbsUpUnchecked,
} from "@fortawesome/free-regular-svg-icons";

import { ProgramaConexaoComment } from "../../../../../domain/entities/ProgramaConexaoComment";
import { ImgFromPathNew } from "../../../../generalComponents/ImgFromPathNew";
import { dateFormat } from "../../../../../utils/defaultDateFormat";
import { HandlersDatabaseContext } from "../../../../../globalStore/HandlersProviders/HandlersDatabaseContext";
import { CurrentUserContext } from "../../../../../globalStore/CurrentUserContext";
import { InputTextarea } from "../../../../generalComponents/InputTextarea";
import { Result } from "../../../../../typings";

export function CommentInMural({
  isGestor,
  comment: {
    id,
    id_programa,
    message,
    autor: { id: id_autor, nome, avatar_path },
    created_at,
    comments_counter,
    likes,
  },
  className,
  refresh,
  createResponse,
}: {
  isGestor: boolean;
  comment: ProgramaConexaoComment;
  className?: string;
  refresh: () => void;
  createResponse?: (inputResponse: {
    parent_comment: string;
    message: string;
  }) => Promise<Result<void>>;
}) {
  const { currentUserAuth } = useContext(CurrentUserContext);
  const {
    handlerComentarioProgramaConexaoUpdate,
    handlerComentarioProgramaConexaoDelete,
    handlerComentarioProgramaConexaoAddLike,
    handlerComentarioProgramaConexaoRemoveLike,
  } = useContext(HandlersDatabaseContext);

  const [commentsCounterToShow, setCommentsCounterToShow] = useState<number>(0);
  const [isUpdating, setIsUpdating] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [messageToEdit, setMessageToEdit] = useState(message);
  const [isSendingInputResponse, setIsSendingInputResponse] = useState(false);
  const [showInputResponse, setShowInputResponse] = useState(false);
  const [inputResponse, setInputResponse] = useState("");
  const [inputResponseError, setInputResponseError] = useState<Error>();

  const error = useMemo<Error | undefined>(
    () => ProgramaConexaoComment.isInvalidMessage(messageToEdit),
    [messageToEdit]
  );
  const isLikeChecked = useMemo<boolean>(() => {
    return (
      !!currentUserAuth.user_auth?.auth_id &&
      !!likes?.includes(currentUserAuth.user_auth.auth_id)
    );
  }, [currentUserAuth.user_auth?.auth_id, likes]);
  const likesCounterToShow = useMemo<number>(
    () => likes?.length ?? 0,
    [likes?.length]
  );

  const resetMessage = useCallback(() => {
    setMessageToEdit(message);
  }, [message]);

  useEffect(() => {
    setInputResponseError(
      ProgramaConexaoComment.isInvalidMessage(inputResponse)
    );
  }, [inputResponse]);

  useEffect(() => {
    setCommentsCounterToShow(comments_counter ?? 0);
  }, [comments_counter]);

  return (
    <div className={"card shadow-sm px-2 p-md-3 " + className}>
      <div className="card-body p-2 p-md-3 gap-2 gap-sm-3 gap-md-4">
        <div className="d-flex flex-wrap">
          {(currentUserAuth.user_auth?.auth_id == id_autor || isGestor) && (
            <>
              <Dropdown className="position-absolute top-0 end-0">
                <Dropdown.Toggle variant="" className="removeCaret">
                  <FontAwesomeIcon icon={faEllipsis} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {handlerComentarioProgramaConexaoUpdate &&
                    currentUserAuth.user_auth?.auth_id == id_autor && (
                      <Dropdown.Item
                        as={Button}
                        variant="light"
                        onClick={() => {
                          resetMessage();
                          setShowEditModal(true);
                        }}
                      >
                        Editar
                      </Dropdown.Item>
                    )}

                  {handlerComentarioProgramaConexaoDelete && (
                    <Dropdown.Item
                      className="text-danger"
                      as={Button}
                      variant="light"
                      onClick={async () => {
                        const deleteResult =
                          await handlerComentarioProgramaConexaoDelete?.delete({
                            id,
                            id_programa,
                          });

                        if (deleteResult.ok) {
                          refresh();
                        } else {
                          console.warn("delete erro", deleteResult.error);
                        }
                      }}
                    >
                      Deletar
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>

              <Modal
                show={showEditModal}
                onHide={() => {
                  setShowEditModal(false);
                }}
                centered
                scrollable={true}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Editar postagem</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                  <InputTextarea
                    label=""
                    value={messageToEdit}
                    onChange={setMessageToEdit}
                    id="iptMessage"
                    title=""
                    min={{
                      value: ProgramaConexaoComment.MIN_SIZE_MESSAGE,
                      errorMessage:
                        ProgramaConexaoComment.MIN_SIZE_MESSAGE_ERROR,
                    }}
                    max={{
                      value: ProgramaConexaoComment.MAX_SIZE_MESSAGE,
                      errorMessage:
                        ProgramaConexaoComment.MAX_SIZE_MESSAGE_ERROR,
                    }}
                  />
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="success"
                    disabled={isUpdating || !!error}
                    onClick={async () => {
                      setIsUpdating(true);

                      if (handlerComentarioProgramaConexaoUpdate) {
                        const updateResult =
                          await handlerComentarioProgramaConexaoUpdate.update({
                            id,
                            id_programa,
                            message: messageToEdit,
                          });

                        if (updateResult.ok) {
                          setShowEditModal(false);
                          refresh();
                        } else {
                          console.warn(updateResult.error);
                        }
                      }

                      setIsUpdating(false);
                    }}
                  >
                    Salvar postagem
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          )}

          <div className="col-2 col-lg-1 pe-1 pe-sm-5 pe-md-3">
            <ImgFromPathNew
              alt={nome}
              path={avatar_path}
              className="rounded-circle border border-2 border-white bg-white"
              elementErro={
                <div className="rounded-circle bg-secondary-subtle text-white d-flex justify-content-center align-items-center opacity-75 w-100 h-100">
                  <FontAwesomeIcon icon={faUser} className="w-50 h-50" />
                </div>
              }
            />
          </div>

          <div>
            <h5 className="fw-bolder">{nome}</h5>
            <h6 className="text-muted">
              {moment(created_at).format(dateFormat.toUpperCase())}
            </h6>
          </div>
        </div>

        <pre
          className="col-12 mt-4"
          style={{
            whiteSpace: "pre-wrap",
          }}
        >
          {message}
        </pre>

        <div className="d-flex justify-content-between text-muted fw-lighter">
          <span>
            {likesCounterToShow} {likesCounterToShow == 1 ? "like" : "likes"}
          </span>
          <span>
            {commentsCounterToShow}{" "}
            {commentsCounterToShow == 1 ? "comentário" : "comentários"}
          </span>
        </div>

        <div className="border-top pt-3 d-flex justify-content-around">
          <div>
            {isLikeChecked
              ? handlerComentarioProgramaConexaoRemoveLike && (
                  <button
                    className="btn text-muted"
                    onClick={async () => {
                      if (currentUserAuth.user_auth?.auth_id) {
                        const removeLikeResult =
                          await handlerComentarioProgramaConexaoRemoveLike.removeLike(
                            {
                              id,
                              id_programa,
                            },
                            currentUserAuth.user_auth.auth_id
                          );

                        if (removeLikeResult.ok) {
                          refresh();
                        } else {
                          console.warn("removeLikeResult", removeLikeResult);
                        }
                      }
                    }}
                  >
                    <FontAwesomeIcon
                      icon={thumbsUpChecked as IconDefinition}
                      className="me-2"
                    />
                    like
                  </button>
                )
              : handlerComentarioProgramaConexaoAddLike && (
                  <button
                    className="btn text-muted"
                    onClick={async () => {
                      if (currentUserAuth.user_auth?.auth_id) {
                        const addLikeResult =
                          await handlerComentarioProgramaConexaoAddLike.addLike(
                            {
                              id,
                              id_programa,
                            },
                            currentUserAuth.user_auth.auth_id
                          );

                        if (addLikeResult.ok) {
                          refresh();
                        } else {
                          console.warn("addLikeResult", addLikeResult);
                        }
                      }
                    }}
                  >
                    <FontAwesomeIcon
                      icon={thumbsUpUnchecked as IconDefinition}
                      className="me-2"
                    />
                    like
                  </button>
                )}
          </div>

          <div>
            {createResponse && (
              <button
                className="btn text-muted"
                onClick={() => setShowInputResponse(true)}
              >
                <FontAwesomeIcon
                  icon={faMessage as IconDefinition}
                  className="me-2"
                />
                comentar
              </button>
            )}
          </div>
        </div>

        {showInputResponse && createResponse && (
          <div className="input-group mb-3">
            <div
              className="form-control"
              placeholder="Adicione uma resposta..."
              aria-label="Adicione uma resposta..."
              contentEditable={true}
              onInput={({ target }) => {
                const value: string =
                  (target as HTMLDivElement).innerText ?? "";
                setInputResponse(value);
              }}
            />
            <button
              className="btn btn-outline-secondary"
              type="button"
              disabled={!!inputResponseError || isSendingInputResponse}
              onClick={async () => {
                setIsSendingInputResponse(true);

                const createResponseResult = await createResponse({
                  message: inputResponse,
                  parent_comment: id,
                });

                setIsSendingInputResponse(false);

                if (createResponseResult.ok) {
                  setCommentsCounterToShow(
                    (commentsCounterToShow) => (commentsCounterToShow ?? 0) + 1
                  );
                  setShowInputResponse(false);
                  setInputResponse("");
                  setInputResponseError(undefined);
                  refresh();
                } else {
                  console.warn(
                    "createResponseResult",
                    createResponseResult.error
                  );
                  setInputResponseError(createResponseResult.error);
                }
              }}
            >
              Enviar
            </button>
          </div>
        )}

        <ListCommentsResponses
          comment={{
            id,
            id_programa,
          }}
          onGetComments={(qtdeComments) => {
            setCommentsCounterToShow(qtdeComments);
          }}
        />
      </div>
    </div>
  );
}

function ListCommentsResponses({
  comment,
  onGetComments,
}: {
  comment: Pick<ProgramaConexaoComment, "id" | "id_programa">;
  onGetComments: (qtdeComments: number) => void;
}) {
  const { handlerComentarioProgramaConexaoGetResponses } = useContext(
    HandlersDatabaseContext
  );

  const [responsesResult, setResponsesResult] =
    useState<Result<ProgramaConexaoComment[]>>();

  useEffect(() => {
    handlerComentarioProgramaConexaoGetResponses
      ?.getResponses(comment)
      .then((responsesResult) => setResponsesResult(responsesResult));
  }, [comment, handlerComentarioProgramaConexaoGetResponses]);

  useEffect(() => {
    if (responsesResult?.ok) onGetComments(responsesResult.value.length);
  }, [onGetComments, responsesResult]);

  return (
    <>
      {responsesResult ? (
        responsesResult.ok ? (
          responsesResult.value.map(
            ({ autor: { nome, avatar_path }, message, id }) => (
              <div key={id} className="overflow-auto mb-3 px-xl-5">
                <div className="col-2 col-sm-1 pe-1 ps-lg-4 pe-lg-3 px-xl-4 float-start">
                  <ImgFromPathNew
                    alt={nome}
                    path={avatar_path}
                    className="rounded-circle border border-2 border-white bg-white"
                    elementErro={
                      <div className="rounded-circle bg-secondary-subtle text-white d-flex justify-content-center align-items-center opacity-75 w-100 h-100">
                        <FontAwesomeIcon icon={faUser} className="w-50 h-50" />
                      </div>
                    }
                  />
                </div>

                <p className="fw-bolder m-0">{nome}</p>

                <p className="m-0">{message}</p>
              </div>
            )
          )
        ) : (
          <>error</>
        )
      ) : (
        <ProgressBar animated variant="info" now={100} className="w-100 m-1" />
      )}
    </>
  );
}
