import { ConteudoEsqueceuSenha } from "./ConteudoEsqueceuSenha";
import { ContainerResponsive } from "../../generalComponents/ContainerResponsive";
import HTMLHead from "../../generalComponents/HTMLHead";

export function EsqueceuSenha() {
  return (
    <>
      <HTMLHead title={"esqueceu a senha?"} description={""} />

      <section className="h-100">
        <ContainerResponsive
          showLogo={false}
          children={<ConteudoEsqueceuSenha />}
        />
      </section>
    </>
  );
}
