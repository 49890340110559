import { createContext } from "react";

import { HandlerQueryLocation } from "../../domain/usecases/interfaces/handlerQueryLocation";

export const HandlersLocationContext =
  createContext<TypeHandlersLocationContext>({});

export type TypeHandlersLocationContext = {
  handlerQueryLocation?: HandlerQueryLocation;
  handlerQueryLocationCidade?: HandlerQueryLocation;
};
