import { InstituicaoDeCienciaETecnologia } from "../../../../../domain/entities/instituicaoDeCienciaETecnologia"
import { DividerInfo } from "./dividerInfo/DividerInfo"
import { TextH2Info } from "./TextH2Info"
import { TextH5Info } from "./TextH5Info"

export const InfoPertenceInstituicao = ({
  instituicoes,
  classes
}: {
  instituicoes?: Pick<InstituicaoDeCienciaETecnologia, "id" | "nome">[]
  classes: string;
}) => {

  return (
    <section className={classes}>
      <div className="border rounded-4 p-3" style={{ width: "fit-content" }}>
        <TextH2Info children="Pertence à(s) Instutuição(ões) (ICT)" />
        <div className="d-flex p-2" style={{ marginLeft: "100px" }}>
          {instituicoes?.map((grupo, i, array) =>
            <DividerInfo key={i} children={<TextH5Info classes={"ms-2"} children={grupo.nome} />} array={array} index={i} />
          )}
        </div>
      </div>
    </section>
  )
}