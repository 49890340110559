import { ReactNode, useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";

import logotipo from "../../assets/images/logoScyggz-footer.png";
import BG from "../../assets/images/BG.jpeg";
import { ResizeContext } from "../../hooks/useResize";
import { TextoMarcaDagua } from "./textoMarcaDagua";
import { LogoTitlePerfilMobile } from "../pages/cadastro/components/LogoTitlePerfilMobile";
import { Pagination } from "../pages/cadastro/components/Pagination/Pagination";

export const ContainerResponsive = ({
  backUrlMobile,
  textoMarcaDagua,
  paginacao,
  showLogo,
  children,
  className,
}: {
  backUrlMobile?: string;
  textoMarcaDagua?: string;
  paginacao?: {
    start: number;
    end: number;
  };
  showLogo: boolean;
  children: ReactNode;
  className?: string;
}) => {
  const { responsive } = useContext(ResizeContext);

  const classnameMain =
    "d-flex flex-column w-100 h-100 align-items-center justify-content-center";

  const styleMain = useMemo<React.CSSProperties>(() => {
    if (responsive.sm) {
      return { backgroundColor: "#818100" };
    }

    return {
      backgroundImage: "url(" + BG + ")",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    };
  }, [responsive.sm]);

  const classNameDiv1Children =
    "overflow-auto w-100 p-2 flex-fill d-flex flex-column align-items-center";

  const classNameDiv2Children = useMemo<string>(
    () => "bg-white p-3 rounded-3 overflow-auto m-auto " + (className ?? ""),
    [className]
  );

  const styleDiv2Children = useMemo<React.CSSProperties>(() => {
    // const style: React.CSSProperties = { flexBasis: "100%" };
    const style: React.CSSProperties = {};

    if (responsive.sm) {
      style.boxShadow = "-5px 8px #525200";
    }

    return style;
  }, [responsive.sm]);

  return (
    <>
      <main className={classnameMain} style={styleMain}>
        <>
          {responsive.sm && backUrlMobile && (
            <Link to={backUrlMobile} title="voltar">
              <FontAwesomeIcon
                style={{
                  position: "absolute",
                  top: "30px",
                  left: 20,
                  color: "#525200",
                }}
                fontSize={45}
                icon={faArrowLeftLong}
              />
            </Link>
          )}

          {responsive.sm && showLogo && (
            <LogoTitlePerfilMobile textMarcaDagua={textoMarcaDagua} />
          )}

          {!responsive.sm && showLogo && (
            <img
              src={logotipo}
              alt="logo"
              className={"align-self-start ps-5 pt-4"}
              style={{
                height: "15% !important",
              }}
            />
          )}

          <div className={classNameDiv1Children}>
            <div className={classNameDiv2Children} style={styleDiv2Children}>
              {!responsive.sm && textoMarcaDagua && (
                <h3 className="mb-4 text-end">
                  <TextoMarcaDagua>{textoMarcaDagua}</TextoMarcaDagua>
                </h3>
              )}
              {children}
            </div>
          </div>

          {paginacao && (
            <Pagination start={paginacao?.start} end={paginacao?.end} />
          )}
        </>
      </main>
    </>
  );
};
