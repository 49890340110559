import { useEffect, useState } from "react";
import { Startup } from "../../../../../domain/entities/Startup";
import { ValorFinanceiro } from "../../../../../domain/entities/ValorFinanceiro";
import { CardLogoOrganizacaoCidadeOutput } from "./CardLogoOrganizacaoCidadeOutput";
import { TextH2Info } from "./TextH2Info";
import { TitleInfo } from "./TitleInfo";


export const InfoStatupsCards = ({
  startups,
  classes,
  volumeRecursos
}: {
  startups?: Pick<Startup, "nome" | "path_logo" | "cidade">[];
  classes?: string;
  volumeRecursos?: Pick<ValorFinanceiro, "moeda" | "valor">;
}) => {

  const [moeda, setMoeda] = useState("")

  useEffect(() => {
    switch (volumeRecursos?.moeda) {
      case "real":
        return setMoeda("R$")
      case "dollar":
        return setMoeda("$")
      default:
        break;
    }
  }, [volumeRecursos?.moeda])


  return (
    <>
      {startups && startups.length > 0 &&
        <section className={classes}>
          <div className="d-flex align-items-center mb-3 flex-wrap">
            <TitleInfo classes="" children="Startups Investidas" />
            {volumeRecursos &&
              <div className="d-flex align-items-center flex-wrap">
                <TextH2Info classes="ms-2" children="(Volume de recursos sob gestão)" />
                <TitleInfo classes="ms-1 border rounded-4 p-2" children={`${moeda} ${volumeRecursos.valor}`} />
              </div>
            }
          </div>
          <div className="ms-2 d-flex gap-3 flex-wrap">
            {startups?.map((startup, i) => (
              <CardLogoOrganizacaoCidadeOutput
                key={i}
                src={startup.path_logo}
                alt={startup.nome}
                local={startup.cidade}
                nome={startup.nome}
              />
            ))}
          </div>
        </section>
      }
    </>
  );
};