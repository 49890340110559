import { useContext } from "react";
import { ProgressBar } from "react-bootstrap";

import { CurrentUserContext } from "../../../../globalStore/CurrentUserContext";
import { PerfilMentorContext } from "../../../../globalStore/perfilMentor/PerfilMentorContext";
import PerfilNotFinded from "../PerfilNotFinded";
import { PerfilInfoMentor } from "./PerfilInfoMentor";

export const PerfilInfoMentorFromContext = () => {
  const { perfilMentorResult, refreshMentor, updateMentor } =
    useContext(PerfilMentorContext);
  const { currentUserAuth, seeAsPublic } = useContext(CurrentUserContext);

  return (
    <>
      {perfilMentorResult == undefined ? (
        <ProgressBar animated variant="info" now={100} className="w-100" />
      ) : (
        <>
          {perfilMentorResult.ok ? (
            <PerfilInfoMentor
              premiacoes={perfilMentorResult.value.premiacoes}
              sobre_o_mentor={perfilMentorResult.value.sobre_o_mentor}
              interesse_startup_estagio={
                perfilMentorResult.value.interesse_startup_estagio
              }
              interesse_startup_mecanismo_solucao={
                perfilMentorResult.value.interesse_startup_mecanismo_solucao
              }
              interesse_startup_modelo_negocio={
                perfilMentorResult.value.interesse_startup_modelo_negocio
              }
              interesse_startup_segmento_atuacao={
                perfilMentorResult.value.interesse_startup_segmento_atuacao
              }
              horas_disponibilidade_mensal={
                perfilMentorResult.value.horas_disponibilidade_mensal
              }
              interesse_startup_localizacoes={
                perfilMentorResult.value.interesse_startup_localizacoes
              }
              refreshMentor={refreshMentor}
              updateMentor={
                currentUserAuth.user_auth?.auth_id ==
                  perfilMentorResult.value.user_id && !seeAsPublic
                  ? updateMentor
                  : undefined
              }
            />
          ) : (
            <PerfilNotFinded perfil="mentor" />
          )}
        </>
      )}
    </>
  );
};
