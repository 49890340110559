import { useContext, useEffect, useState } from "react";

import { SelectLocalizacao } from "./SelectLocalizacao";
import { LocationFinderOutput } from "../../domain/usecases/locationFinder";
import { HandlersContext } from "../../globalStore/HandlersProviders/HandlersContext";

export function InputLocais({
  label,
  value,
  onChange,
  validate,
  className,
}: {
  label: string;
  value: LocationFinderOutput[];
  onChange: (locais: LocationFinderOutput[]) => void;
  validate: (locais?: LocationFinderOutput[]) => Error | undefined;
  className?: string;
}) {
  const { handlerQueryLocation } = useContext(HandlersContext);

  const [errorToShow, setErrorToShow] = useState<string>();
  const [isChanged, setIsChanged] = useState<boolean>(false);

  useEffect(() => {
    const errorValidate = validate(value);

    if (errorValidate) {
      setErrorToShow(errorValidate.message);
    } else {
      setErrorToShow(undefined);
    }
  }, [validate, value]);

  return (
    <>
      {handlerQueryLocation && (
        <div className={className}>
          <label className="form-label m-0 fw-bolder" htmlFor="selLocais">
            {label}
          </label>
          <SelectLocalizacao
            value={value}
            placeHolder=""
            onCitySelected={(interesse_startup_localizacoes) => {
              if (interesse_startup_localizacoes) {
                if (Array.isArray(interesse_startup_localizacoes)) {
                  onChange(interesse_startup_localizacoes);
                } else {
                  onChange([
                    interesse_startup_localizacoes as LocationFinderOutput,
                  ]);
                }
              } else {
                onChange([]);
              }

              setIsChanged(true);
            }}
            onBlur={() => {
              setIsChanged(true);
            }}
            isMulti={true}
            className={errorToShow && isChanged ? " is-invalid" : ""}
            handlerQueryLocation={handlerQueryLocation}
            id="selLocais"
          />
          <div className="invalid-feedback flex-fill">{errorToShow}</div>
        </div>
      )}
    </>
  );
}
