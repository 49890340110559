import {
  faQuestionCircle,
  faBullhorn,
} from "@fortawesome/free-solid-svg-icons";
import { Fade } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import Breadcrumbs from "./components/breadcrumbs/Breadcrumbs";
import logoScyggz from "../../assets/images/logo-scyggz-new.png";

export default function SobreNos() {
  return (
    <div className="bg-white">
      <Breadcrumbs
        icon={faQuestionCircle}
        title="Sobre o Sciencin"
        menuList={{
          previous: [{ title: "Dashboard", link: "/dashboard" }],
          page: "Sobre o Sciencin",
        }}
      />
      <section>
        <div className="container pb-4 pt-4">
          <div className="row mb-4 align-items-center">
            <div className="col-md-4">
              <Fade in={true} timeout={0.6}>
                <img className="img-fluid" src={logoScyggz} alt="Scyggz Logo" />
              </Fade>
            </div>

            <div className="col-md-8">
              <p className="text-center">
                A plataforma de conexões entre Cientistas e Empreendedores, os
                desenvolvedores de soluções tecnológicas COM investidores Early
                Stage e Empresas com interesse na criação de valor através da
                inovação.
              </p>
              <h1 className="text-center">Qual o significado do Sciencin?</h1>
              <p className="text-center">
                Criamos esse nome para representar o significado e a origem de
                nossa empresa e de nosso time.
              </p>
              <hr />
              <h1 className="text-center">&nbsp;SC + YGG + Z</h1>
              <hr />
              <p>
                <strong>
                  <u>SC</u> -
                </strong>{" "}
                Refere-se ao termo Science
              </p>
              <p>
                <strong>
                  <u>YGG</u> -&nbsp;
                </strong>
                Yggdrasil consiste em uma &aacute;rvore enorme considerada o
                eixo do mundo na mitologia n&oacute;rdica. Nesse sentido,
                localiza-se no centro do Universo ligando os nove mundos
                mitol&oacute;gicos. No sciencin estamos conectando v&aacute;rios
                stakeholders fazendo refer&ecirc;ncia aos nove mundos
                mitol&oacute;gicos: Cientistas, Empreendedores, Startups,
                Empresas, Venture Capital, Investidores Anjo, Mentores,
                Incubadoras e NITs.
              </p>
              <p>
                <strong>
                  <u>Z</u> -&nbsp;
                </strong>
                Refere-se ao termo business, incorporado ao final da palavra
                para ser a refer&ecirc;ncia sonora ao termo neg&oacute;cios em
                ingl&ecirc;s para complementar a refer&ecirc;ncia ao objetivo de
                nossa empresa: Ci&ecirc;ncia se conectando com o mercado
                atrav&eacute;s da plataforma que conecta nove stakeholders.
              </p>
            </div>
          </div>
          <div className="row text-center">
            <Link to="/contato">
              <button
                className="btn"
                style={{
                  backgroundColor: "#660",
                  fontSize: "20px",
                  borderColor: "#660",
                  color: "#fff",
                  width: "100%",
                }}
              >
                <FontAwesomeIcon icon={faBullhorn} />
                <span> FALE COM O SCIENCIN</span>
              </button>
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}
