import { useContext, useEffect, useState } from "react";
import Select, { StylesConfig } from "react-select";
import "react-phone-input-2/lib/style.css";
import makeAnimated from "react-select/animated";
import { Formik } from "formik";
import * as Yup from "yup";

import { ButtonDarkGreen } from "../../../../generalComponents/buttonDarkGreen/ButtonDarkGreen";
import { SetorAtuacaoOutput } from "../../../../../domain/entities/SetorAtuacao";
import { AreaAplicacaoTecnologiaOutput } from "../../../../../domain/entities/areaAplicacaoTecnologia";
import { MecanismoDeSolucaoTecnologicaOutput } from "../../../../../domain/entities/mecanismoDeSolucaoTecnologica";
import { AreaConhecimentoOutput } from "../../../../../domain/entities/areaConhecimento";
import { HandlerGetAreaAplicacaoTecnologia } from "../../../../../domain/usecases/interfaces/handlerGetAreaAplicacaoTecnologia";
import {
  HandlerGetTipoGrupoPesquisa,
  OutputHandlerGetTipoGruposPesquisa,
} from "../../../../../domain/usecases/interfaces/handlerGetTipoGrupoPesquisa";
import { HandlerUpdateEmpresa } from "../../../../../domain/usecases/interfaces/handlerUpdateEmpresa";
import { InputUpdateEmpresa } from "../../../../../domain/entities/Empresa";
import {
  CacheEmpresa,
  HandlerCacheEmpresa,
} from "../../../../../domain/usecases/interfaces/HandlerCacheEmpresa";
import { CurrentUserContext } from "../../../../../globalStore/CurrentUserContext";
import { ResizeContext } from "../../../../../hooks/useResize";
import { ButtonLightGreen } from "../../../../generalComponents/buttonLightGreen/ButtonLightGreen";
import { GrupoDePesquisaOutput } from "../../../../../domain/entities/grupoDePesquisa";
import { InputMecanismosSolucao } from "../../../../generalComponents/InputMecanismosSolucao";
import { InputAreasConhecimento } from "../../../../generalComponents/InputAreasConhecimento";
import { InputGruposDePesquisa } from "../../../../generalComponents/InputGruposDePesquisa/InputGruposDePesquisa";

export function ConteudoInteresseCientistaEmpresa({
  handlerUpdateEmpresa,
  handlerGetAreaAplicacaoTecnologia,
  handlerGetTipoGrupoPesquisa,
  handlerCacheEmpresa,
  onBack,
  onNext,
}: {
  handlerUpdateEmpresa: HandlerUpdateEmpresa;
  handlerGetAreaAplicacaoTecnologia: HandlerGetAreaAplicacaoTecnologia;
  handlerGetTipoGrupoPesquisa: HandlerGetTipoGrupoPesquisa;
  handlerCacheEmpresa: HandlerCacheEmpresa;
  onBack: () => void;
  onNext: () => void;
}) {
  const customStylesSelectSetorAtuacao: StylesConfig<SetorAtuacaoOutput, true> =
    {
      control: () => ({
        border: "none",
        borderBottom: "3px #666600 solid",
        display: "flex",
        background: " #fff",
        borderBottomLeftRadius: "8px",
        textAlign: "left",
      }),
      input: () => ({
        position: "absolute",
      }),
    };

  const { responsive } = useContext(ResizeContext);

  const { currentUserScyggz } = useContext(CurrentUserContext);

  const [parteDoNome, setParteDoNome] = useState("");

  const [cacheEmpresa, setCacheEmpresa] = useState<CacheEmpresa>({});

  const animatedComponents = makeAnimated();

  const [allAreasAplicacao, setAllAreasAplicacao] = useState<
    AreaAplicacaoTecnologiaOutput[]
  >([]);
  const [isAllAreasAplicacaoLoading, setIsAllAreasAplicacaoLoading] =
    useState<boolean>(false);

  const [, setAllTipoGrupoPesquisa] = useState<
    OutputHandlerGetTipoGruposPesquisa[]
  >([]);
  const [, setIsAllTipoGrupoPesquisaLoading] = useState<boolean>(false);
  const [isEditingGrupoPesquisa, setIsEditingGrupoPesquisa] =
    useState<boolean>(false);

  useEffect(() => {
    setIsAllAreasAplicacaoLoading(true);
    handlerGetAreaAplicacaoTecnologia
      .getAllAreaAplicacaoTecnologia()
      .then((handlerGetAreasAplicacaoResult) => {
        if (handlerGetAreasAplicacaoResult.ok)
          setAllAreasAplicacao(handlerGetAreasAplicacaoResult.value);

        setIsAllAreasAplicacaoLoading(false);
      });
  }, [handlerGetAreaAplicacaoTecnologia]);

  useEffect(() => {
    setIsAllTipoGrupoPesquisaLoading(true);
    handlerGetTipoGrupoPesquisa
      .getAllTipoGrupoPesquisa()
      .then((handlerGetTipoGrupoPesquisaResult) => {
        if (handlerGetTipoGrupoPesquisaResult.ok)
          setAllTipoGrupoPesquisa(handlerGetTipoGrupoPesquisaResult.value);

        setIsAllTipoGrupoPesquisaLoading(false);
      });
  }, [handlerGetTipoGrupoPesquisa]);

  useEffect(() => {
    handlerCacheEmpresa.get().then((cacheEmpresaResult) => {
      if (cacheEmpresaResult.ok) {
        setCacheEmpresa(cacheEmpresaResult.value);
      }
    });
  }, [handlerCacheEmpresa]);

  useEffect(() => {
    if (currentUserScyggz.user) {
      const parteDoNome: string =
        currentUserScyggz.user.nome.split(" ").at(0) ??
        currentUserScyggz.user.nome;

      setParteDoNome(parteDoNome);
    }
  }, [currentUserScyggz]);

  return (
    <Formik<FormikFields>
      initialValues={{
        interesses_cientista_tecnologia_areas_aplicacao:
          cacheEmpresa.interesses_cientista_tecnologia_areas_aplicacao,
        interesses_cientista_tecnologia_mecanismos_solucao:
          cacheEmpresa.interesses_cientista_tecnologia_mecanismos_solucao,
        interesses_cientista_tecnologia_area_conhecimento:
          cacheEmpresa.interesses_cientista_tecnologia_area_conhecimento,
        interesses_cientista_grupo_pesquisa:
          cacheEmpresa.interesses_cientista_grupo_pesquisa,
      }}
      validationSchema={validator}
      validateOnMount={true}
      validateOnChange={true}
      validateOnBlur={true}
      enableReinitialize={true}
      onSubmit={async (values) => {
        const cacheToSave: CacheEmpresa = {
          interesses_cientista_tecnologia_areas_aplicacao:
            values.interesses_cientista_tecnologia_areas_aplicacao,
          interesses_cientista_tecnologia_mecanismos_solucao:
            values.interesses_cientista_tecnologia_mecanismos_solucao,
          interesses_cientista_tecnologia_area_conhecimento:
            values.interesses_cientista_tecnologia_area_conhecimento,
        };

        if (values.interesses_cientista_grupo_pesquisa) {
          cacheToSave.interesses_cientista_grupo_pesquisa =
            values.interesses_cientista_grupo_pesquisa.map((grupo) => ({
              id: grupo.id,
              nome: grupo.nome,
              aprovado: grupo.aprovado,
              tipo: grupo.tipo,
            }));
        }

        handlerCacheEmpresa.save(cacheToSave);

        const inputUpdateEmpresa: InputUpdateEmpresa = {
          interesses_cientista_tecnologia_areas_aplicacao:
            values.interesses_cientista_tecnologia_areas_aplicacao,
          interesses_cientista_tecnologia_mecanismos_solucao:
            values.interesses_cientista_tecnologia_mecanismos_solucao,
          interesses_cientista_tecnologia_area_conhecimento:
            values.interesses_cientista_tecnologia_area_conhecimento,
          id: cacheEmpresa.id ?? "",
        };

        if (
          values.interesses_cientista_grupo_pesquisa &&
          values.interesses_cientista_grupo_pesquisa.length > 0
        ) {
          inputUpdateEmpresa.interesses_cientista_grupo_pesquisa =
            values.interesses_cientista_grupo_pesquisa.map((grupo) => ({
              id: grupo.id,
              nome: grupo.nome,
              aprovado: grupo.aprovado,
              tipo: grupo.tipo,
            }));
        }

        const handlerCreateEmpresaResult =
          await handlerUpdateEmpresa.updateEmpresa(inputUpdateEmpresa);
        if (!handlerCreateEmpresaResult.ok) {
          console.warn(
            "Erro ao atualizar empresa",
            handlerCreateEmpresaResult.error
          );
          return;
        }
        onNext();
      }}
    >
      {({
        isValid,
        isSubmitting,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        errors,
        touched,
        values,
      }) => (
        <form
          onSubmit={handleSubmit}
          className={`${!responsive.sm && "px-4"} pb-2`}
          data-testid="painel_cadastro_interesse_cientista_empresa"
        >
          <h3 className="card-title mb-4 fw-bold">
            <span style={{ fontSize: "24px" }}>{parteDoNome}</span>
            <span style={{ fontSize: "24px" }} className="h5 fw-bold">
              , gostaria de conectar a sua empresa com cientistas?
            </span>
          </h3>

          <div className="d-flex align-items-center flex-column">
            <div className="mb-2 col-12">
              <label className="form-label" htmlFor="selectAreasAplicacao">
                Cientistas com tecnologias nas seguintes áreas de aplicação
              </label>
              <Select<SetorAtuacaoOutput, true>
                options={allAreasAplicacao}
                isLoading={isAllAreasAplicacaoLoading}
                getOptionLabel={(o) => o.nome}
                getOptionValue={(o) => o.id}
                components={animatedComponents}
                placeholder=""
                styles={
                  responsive.sm ? customStylesSelectSetorAtuacao : undefined
                }
                isMulti={true}
                value={values.interesses_cientista_tecnologia_areas_aplicacao}
                onBlur={() =>
                  setFieldTouched(
                    "interesses_cientista_tecnologia_areas_aplicacao",
                    true
                  )
                }
                onChange={(areas) => {
                  setFieldValue(
                    "interesses_cientista_tecnologia_areas_aplicacao",
                    areas
                  );
                  setFieldTouched(
                    "interesses_cientista_tecnologia_areas_aplicacao",
                    true
                  );
                }}
                className={
                  errors.interesses_cientista_tecnologia_areas_aplicacao &&
                  touched.interesses_cientista_tecnologia_areas_aplicacao
                    ? " is-invalid "
                    : ""
                }
                noOptionsMessage={() => "sem opção"}
                inputId="selectAreasAplicacao"
              />
              <div className="invalid-feedback flex-fill">
                {errors.interesses_cientista_tecnologia_areas_aplicacao}
              </div>
            </div>

            <div className="mb-2 col-12">
              <InputMecanismosSolucao
                label="Cientistas com tecnologias com os seguintes mecanismos de solução"
                value={
                  values.interesses_cientista_tecnologia_mecanismos_solucao ??
                  []
                }
                onChange={(
                  interesses_cientista_tecnologia_mecanismos_solucao
                ) => {
                  setFieldValue(
                    "interesses_cientista_tecnologia_mecanismos_solucao",
                    interesses_cientista_tecnologia_mecanismos_solucao
                  );
                }}
                validate={() => undefined}
              />
            </div>

            <div className="mb-5 col-12">
              <InputAreasConhecimento
                label="Cientistas com tecnologias nas seguintes áreas de conhecimento"
                value={
                  values.interesses_cientista_tecnologia_area_conhecimento ?? []
                }
                onChange={(
                  interesses_cientista_tecnologia_area_conhecimento
                ) => {
                  setFieldValue(
                    "interesses_cientista_tecnologia_area_conhecimento",
                    interesses_cientista_tecnologia_area_conhecimento
                  );
                }}
                validate={() => undefined}
              />
            </div>

            <InputGruposDePesquisa
              label="Selecione o grupo de pesquisa"
              value={values.interesses_cientista_grupo_pesquisa ?? []}
              onListChange={(gruposDePesquisa) => {
                setFieldValue(
                  "interesses_cientista_grupo_pesquisa",
                  gruposDePesquisa
                );
                setFieldTouched("interesses_cientista_grupo_pesquisa", true);
              }}
              isEditing={setIsEditingGrupoPesquisa}
            />
          </div>
          <div className="mt-5 fw-bold text-center d-flex flex-wrap justify-content-center gap-2">
            {!responsive.sm && (
              <p style={{ color: "#525200", maxWidth: "500px", margin: "0" }}>
                *Obs: Após a criação do perfil da Empresa, você poderá convidar
                demais membros da sua Organização para completar o perfil.
              </p>
            )}

            <div
              className=" text-end d-flex flex-nowrap"
              style={{
                height: "fit-content",
                width: responsive.sm ? "100%" : "",
              }}
            >
              {!responsive.sm && (
                <ButtonLightGreen
                  className="me-3"
                  onClick={() => {
                    const cacheToSave: CacheEmpresa = values;

                    if (values.interesses_cientista_grupo_pesquisa) {
                      cacheToSave.interesses_cientista_grupo_pesquisa =
                        values.interesses_cientista_grupo_pesquisa.map(
                          (grupo) => ({
                            id: grupo.id,
                            nome: grupo.nome,
                            aprovado: grupo.aprovado,
                            tipo: grupo.tipo,
                          })
                        );
                    }

                    handlerCacheEmpresa.save(cacheToSave);
                    onBack();
                  }}
                >
                  Voltar
                </ButtonLightGreen>
              )}
              <ButtonDarkGreen
                disabled={!isValid || isSubmitting || isEditingGrupoPesquisa}
                isSubmit={true}
                className={
                  (!responsive.sm ? "ms-2" : "w-100") + " text-uppercase"
                }
              >
                Avançar
              </ButtonDarkGreen>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}

export type FormikFields = Partial<{
  interesses_cientista_tecnologia_areas_aplicacao: AreaAplicacaoTecnologiaOutput[];
  interesses_cientista_tecnologia_mecanismos_solucao: MecanismoDeSolucaoTecnologicaOutput[];
  interesses_cientista_tecnologia_area_conhecimento: AreaConhecimentoOutput[];
  interesses_cientista_grupo_pesquisa: GrupoDePesquisaOutput[];
}>;

const validator = Yup.object().shape({
  interesses_cientista_tecnologia_areas_aplicacao: Yup.array(),
  interesses_cientista_tecnologia_mecanismos_solucao: Yup.array(),
  interesses_cientista_tecnologia_area_conhecimento: Yup.array(),
  interesses_cientista_grupo_pesquisa: Yup.array(),
});
