import { Result, Writeable } from "../../typings";
import { Premiacao, PremiacaoInput } from "./premiacao";
import {
  TecnologiaDoCientista,
  TecnologiaDoCientistaInput,
} from "./tecnologiaDoCientista";
import { AreaAplicacaoTecnologia } from "./areaAplicacaoTecnologia";
import { Sobre, SobreInput } from "./sobre";
import {
  GrupoDePesquisa,
  GrupoDePesquisaInput,
  GrupoDePesquisaOutput,
} from "./grupoDePesquisa";
import { AreaConhecimento } from "./areaConhecimento";
import { UnidadeInstituicaoDeCienciaETecnologia } from "./unidadeInstituicaoDeCienciaETecnologia";

export class Cientista {
  private constructor(private input: CientistaInputEntity) {}

  public static create(input: CientistaInputEntity): Result<Cientista> {
    const validateResult = Cientista.validateCreate(input);

    if (!validateResult.ok) {
      return validateResult;
    }

    return {
      ok: true,
      value: new Cientista(input),
    };
  }
  public get user_id() {
    return this.input.user_id;
  }

  public get unidades() {
    return this.input.unidades;
  }

  public get areas_de_conhecimento() {
    return this.input.areas_de_conhecimento;
  }

  public get sobre() {
    return this.input.sobre;
  }

  public get grupos_de_pesquisa() {
    return this.input.grupos_de_pesquisa;
  }

  public get premiacoes() {
    return this.input.premiacoes;
  }

  public get tecnologias() {
    return this.input.tecnologias;
  }

  public get nome() {
    return this.input.nome;
  }

  public get created_in() {
    return this.input.created_in;
  }

  public get habilitado() {
    return !!this.input.habilitado;
  }

  public static validateCreate(
    attributes: Writeable<Partial<Cientista>>
  ): Result<Partial<Cientista>> {
    const errors: Error[] = [];

    attributes.user_id = attributes.user_id?.trim() ?? "";
    const isUserIdValid: boolean = attributes.user_id.length > 0;
    if (!isUserIdValid) {
      return {
        ok: false,
        error: new Error("User id inválido"),
      };
    }

    attributes.nome = attributes.nome?.trim() ?? "";
    const isUserNameValid: boolean = attributes.nome.length > 0;
    if (!isUserNameValid) {
      return {
        ok: false,
        error: new Error("User nome inválido '" + attributes.nome + "'"),
      };
    }

    const errorValidateAreasConhecimento = this.validateAreasConhecimento(
      attributes.areas_de_conhecimento
    );

    if (errorValidateAreasConhecimento) {
      return {
        ok: false,
        error: errorValidateAreasConhecimento,
      };
    }

    const sobreResult = Sobre.validate({
      conteudo: attributes.sobre?.conteudo.trim() ?? "",
    });
    if (!sobreResult.ok) {
      return {
        ok: false,
        error: new Error("Precisa especificar sobre o cientista."),
      };
    }

    attributes.unidades = attributes.unidades ?? [];
    const hasUnidade: boolean = attributes.unidades.length > 0;
    const isUnidadeInvalid = attributes.unidades.some(
      (unidade) => unidade.id.length < 1 || unidade.instituicao.id.length < 1
    );
    if (isUnidadeInvalid || !hasUnidade) {
      return {
        ok: false,
        error: new Error("Precisa especificar a unidade."),
      };
    }

    const premiacoesResult = Cientista.validatePremiacoes(
      attributes.premiacoes ?? []
    );
    if (!premiacoesResult.ok) {
      return {
        ok: false,
        error: new Error("Erro nas premiações!"),
      };
    }

    const tecnologiasResult = Cientista.validateTecnologias(
      attributes.tecnologias ?? []
    );
    if (!tecnologiasResult.ok) {
      return {
        ok: false,
        error: new Error("Erro nas tecnologias!"),
      };
    }

    const gruposPesquisaResult = Cientista.validateGruposDePesquisa(
      attributes.grupos_de_pesquisa ?? []
    );
    if (!gruposPesquisaResult.ok) {
      return {
        ok: false,
        error: new Error("Erro nos grupos de pesquisa!"),
      };
    }

    if (errors.length > 0) {
      return {
        ok: false,
        error: errors[0],
      };
    }

    return {
      ok: true,
      value: attributes,
    };
  }

  public static validateUpdate(
    attributes: Partial<Cientista>
  ): Result<Partial<Cientista>> {
    const errors: Error[] = [];

    if (attributes.user_id !== undefined) {
      const isUserIdValid: boolean = attributes.user_id.length > 0;
      if (!isUserIdValid) {
        return {
          ok: false,
          error: new Error("User id inválido"),
        };
      }
    }

    if (typeof attributes.nome !== "undefined") {
      const isUserNameValid: boolean = attributes.nome.trim().length > 0;
      if (!isUserNameValid) {
        return {
          ok: false,
          error: new Error("User nome inválido '" + attributes.nome + "'"),
        };
      }
    }

    if (typeof attributes.areas_de_conhecimento != "undefined") {
      const isAreaDeConhecimentoValid: boolean =
        attributes.areas_de_conhecimento.length > 0;
      if (!isAreaDeConhecimentoValid) {
        return {
          ok: false,
          error: new Error("Precisa especificar a área de conhecimento."),
        };
      }
    }

    if (typeof attributes.sobre != "undefined") {
      const validateSobreResult = Sobre.validate(attributes.sobre);

      if (!validateSobreResult.ok) {
        return validateSobreResult;
      }
    }

    if (typeof attributes.unidades != "undefined") {
      const hasUnidade: boolean = attributes.unidades.length > 0;
      const isUnidadeInvalid = attributes.unidades.some(
        (unidade) => unidade.id.length < 1 || unidade.instituicao.id.length < 1
      );
      if (isUnidadeInvalid || !hasUnidade) {
        return {
          ok: false,
          error: new Error("Precisa especificar a unidade"),
        };
      }
    }

    if (typeof attributes.premiacoes != "undefined") {
      const premiacoesResult = Cientista.validatePremiacoes(
        attributes.premiacoes
      );
      if (!premiacoesResult.ok) {
        return {
          ok: false,
          error: new Error(
            "Erro nas premiações: " +
              Object.values(premiacoesResult.error ?? {})
                .map((error) => error.message)
                .join(", ")
          ),
        };
      }
    }

    if (typeof attributes.tecnologias != "undefined") {
      const tecnologiasResult = Cientista.validateTecnologias(
        attributes.tecnologias
      );
      if (!tecnologiasResult.ok) {
        return {
          ok: false,
          error: new Error(
            "Erro nas tecnologias: " +
              Object.values(tecnologiasResult.error ?? {})
                .map((error) => error.message)
                .join(", ")
          ),
        };
      }
    }

    if (typeof attributes.grupos_de_pesquisa != "undefined") {
      const gruposPesquisaResult = Cientista.validateGruposDePesquisa(
        attributes.grupos_de_pesquisa
      );
      if (!gruposPesquisaResult.ok) {
        return {
          ok: false,
          error: new Error(
            "Erro nos grupos de pesquisa: " +
              Object.values(gruposPesquisaResult.error ?? {})
                .map((error) => error.message)
                .join(", ")
          ),
        };
      }
    }

    if (errors.length > 0) {
      return {
        ok: false,
        error: errors[0],
      };
    }

    return {
      ok: true,
      value: attributes,
    };
  }

  public static validatePremiacoes(
    premiacoes: PremiacaoInput[]
  ): Result<
    PremiacaoInput[],
    { [P in keyof Partial<PremiacaoInput>]: Error } | undefined
  > {
    let hasError = false;

    const errors = premiacoes
      .map((premiacao) => Premiacao.validate(premiacao))
      .map((result) => {
        if (result.ok) {
          return undefined;
        } else {
          hasError = true;
          return result.error;
        }
      });

    if (hasError) {
      return {
        ok: false,
        error: errors[0],
      };
    } else {
      return {
        ok: true,
        value: premiacoes,
      };
    }
  }

  public static validateTecnologias(
    tecnologias: TecnologiaDoCientistaInput[]
  ): Result<
    TecnologiaDoCientistaInput[],
    { [P in keyof Partial<TecnologiaDoCientistaInput>]: Error } | undefined
  > {
    let hasError = false;

    const errors = tecnologias
      .map((tecnologia) => TecnologiaDoCientista.validate(tecnologia))
      .map((result) => {
        if (result.ok) {
          return undefined;
        } else {
          hasError = true;
          return result.error;
        }
      });

    if (hasError) {
      return {
        ok: false,
        error: errors[0],
      };
    } else {
      return {
        ok: true,
        value: tecnologias,
      };
    }
  }

  public static validateGruposDePesquisa(
    grupos_de_pesquisa: GrupoDePesquisaInput[]
  ): Result<
    GrupoDePesquisaInput[],
    { [P in keyof Partial<GrupoDePesquisaInput>]: Error } | undefined
  > {
    let hasError = false;

    const errors = grupos_de_pesquisa
      .map((grupo_de_pesquisa) => GrupoDePesquisa.validate(grupo_de_pesquisa))
      .map((result) => {
        if (result.ok) {
          return undefined;
        } else {
          hasError = true;
          return result.error;
        }
      });

    if (hasError) {
      return {
        ok: false,
        error: errors[0],
      };
    } else {
      return {
        ok: true,
        value: grupos_de_pesquisa,
      };
    }
  }

  public static validateAreasConhecimento(
    areas: AreaConhecimento[] | undefined
  ): Error | undefined {
    if ((areas?.length ?? 0) < 1) {
      return new Error("Precisa especificar a área de conhecimento.");
    }
  }

  public static validateUnidades(
    unidades: UnidadeInstituicaoDeCienciaETecnologia[] | undefined
  ): Error | undefined {
    if ((unidades?.length ?? 0) < 1) {
      return new Error("Unidade de instituição é obrigatório");
    }
  }
}

export type CientistaInputEntity = {
  user_id: string;
  nome: string;
  sobre: SobreInput;
  areas_de_conhecimento: Pick<AreaAplicacaoTecnologia, "id" | "nome">[];
  unidades: {
    id: string;
    nome: string;
    instituicao: { id: string; nome: string };
  }[];
  created_in: Date;
} & Partial<{
  habilitado: boolean;
  grupos_de_pesquisa: Pick<
    GrupoDePesquisaOutput,
    "id" | "nome" | "tipo" | "aprovado" | "autor"
  >[];
  premiacoes: Pick<Premiacao, "nome" | "descricao">[];
  tecnologias: Pick<
    TecnologiaDoCientista,
    | "nome"
    | "resumo"
    | "areas_de_conhecimento"
    | "areas_de_aplicacao"
    | "mecanismos_de_solucao"
  >[];
}>;

export type CientistaOutputEntity = CientistaInputEntity;
