import { useNavigate } from "react-router";
import { useContext } from "react";

import { ContainerResponsive } from "../../../../generalComponents/ContainerResponsive";
import { PainelElegibilidade } from "../../components/PainelElegibilidade/PainelElegibilidade";
import { HandlersDatabaseContext } from "../../../../../globalStore/HandlersProviders/HandlersDatabaseContext";

export function PainelElegibilidadeInvestidor() {
  const navigate = useNavigate();

  const { handlerAceiteSet } = useContext(HandlersDatabaseContext);

  const paginacaoElegibilidadeinvestidor = {
    start: 1,
    end: 6,
  };
  const nextUrl = "/cadastro/investidor/2";

  return (
    <section className="h-100">
      <ContainerResponsive
        backUrlMobile="/"
        textoMarcaDagua={"Investidor Anjo"}
        paginacao={paginacaoElegibilidadeinvestidor}
        showLogo={true}
      >
        <PainelElegibilidade
          requisitos={[
            "Sou uma pessoa física, não estou representando um fundo de Venture Capital;",
            "Já invisto e/ou pretendo investir em startups;",
            "Já participo ou pretendo participar do ecossistema de empreendedorismo;",
          ]}
          titulo={"Investidor Anjo"}
          onNext={() => {
            if (handlerAceiteSet)
              handlerAceiteSet.set("investidor", new Date());
            navigate(nextUrl);
          }}
          onPrevious={() => {
            navigate("/");
          }}
        />
      </ContainerResponsive>
    </section>
  );
}
