import { OutputEmpresa } from "../../../domain/entities/Empresa";
import { HeaderPerfil } from "../components/perfil/HeaderPerfil";
import { AreasInfo } from "../user/components/generalComponents/AreasInfo";
import { InfoSobre } from "../user/components/generalComponents/InfoSobre";

export default function PerfilInfoEmpresa({
  sobre,
  nome,
  path_logo,
  setores_atuacao,
  interesses_inovacao,
}: Pick<
  OutputEmpresa,
  "sobre" | "nome" | "path_logo" | "setores_atuacao" | "interesses_inovacao"
>) {
  return (
    <section className="container">
      <HeaderPerfil nome={nome} foto_path={path_logo} headline={"Empresa"} />

      <div>
        <InfoSobre sobre={sobre} />
      </div>
      <div className="border rounded-4 p-3 m-0 row mb-4">
        <div className="col-md-6">
          <AreasInfo title="Setores de atuação" areas={setores_atuacao} />
        </div>
        <div className="col-md-6">
          <AreasInfo
            title="Interesses de Inovação"
            areas={interesses_inovacao}
          />
        </div>
      </div>
    </section>
  );
}
