import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { CurrentUserContext } from "../globalStore/CurrentUserContext";
import { HandlersDatabaseContext } from "../globalStore/HandlersProviders/HandlersDatabaseContext";
import { ManagerPermission } from "../domain/entities/ManagerPermission";

export function RouteGuardRedirectIfHasNotPermission({
  permission,
  redirectPath,
  children,
}: {
  permission: keyof Omit<ManagerPermission, "uid">;
  redirectPath: string;
  children: JSX.Element | JSX.Element[];
}) {
  const { currentUserScyggz } = useContext(CurrentUserContext);
  const { handlerGetPermissionsOfUser } = useContext(HandlersDatabaseContext);

  const navigate = useNavigate();

  const [toShow, setToShow] = useState<JSX.Element | JSX.Element[]>([]);

  useEffect(() => {
    if (currentUserScyggz.status != "carregando") {
      if (currentUserScyggz.user?.auth_user_id) {
        if (handlerGetPermissionsOfUser) {
          handlerGetPermissionsOfUser
            .getPermissionsOfUser(currentUserScyggz.user.auth_user_id)
            .then((permissionsOfUserResult) => {
              if (
                permissionsOfUserResult.ok &&
                permissionsOfUserResult.value[permission]
              ) {
                setToShow(children);
              } else {
                console.warn(
                  "redirect because dont have permission " + permission
                );
                navigate(redirectPath);
              }
            });
        } else {
          console.warn("redirect because dont have permission " + permission);
          navigate(redirectPath);
        }
      } else {
        console.warn("redirect because dont have permission " + permission);
        navigate(redirectPath);
      }
    }
  }, [
    children,
    currentUserScyggz.status,
    currentUserScyggz.user?.auth_user_id,
    handlerGetPermissionsOfUser,
    navigate,
    permission,
    redirectPath,
  ]);

  return <>{toShow}</>;
}
