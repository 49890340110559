import { Result, Writeable } from "../typings";
import {
  CacheInvestidor,
  HandlerCacheInvestidor,
} from "../domain/usecases/interfaces/HandlerCacheInvestidor";

export class HandlerCacheInvestidorStorage implements HandlerCacheInvestidor {
  private readonly keyInvestidorPrefix = "investidorCadastroInScyggz";

  clear(): Promise<Result<null>> {
    if (!localStorage) {
      return Promise.resolve({
        ok: false,
        error: new Error(
          "Não fez cache do investidor pois o navegador é incompativel"
        ),
      });
    }

    try {
      localStorage.removeItem(this.keyInvestidorPrefix);

      return Promise.resolve({
        ok: true,
        value: null,
      });
    } catch (error) {
      return Promise.resolve({
        ok: false,
        error: error as Error,
      });
    }
  }

  async save(investidor: CacheInvestidor): Promise<Result<null, Error>> {
    if (!localStorage) {
      return Promise.resolve({
        ok: false,
        error: new Error(
          "Não fez cache do investidor pois o navegador é incompativel"
        ),
      });
    }

    try {
      const getResult = await this.get();

      if (!getResult.ok) {
        return getResult;
      }

      localStorage.setItem(
        this.keyInvestidorPrefix,
        JSON.stringify({ ...getResult.value, ...investidor })
      );

      return Promise.resolve({
        ok: true,
        value: null,
      });
    } catch (error) {
      return Promise.resolve({
        ok: false,
        error: error as Error,
      });
    }
  }

  get(): Promise<Result<CacheInvestidor, Error>> {
    const investidorStorage = localStorage.getItem(this.keyInvestidorPrefix);

    if (!investidorStorage) {
      return Promise.resolve({
        ok: true,
        value: {},
      });
    }

    const investidorAny = JSON.parse(investidorStorage);

    if (!investidorAny) {
      return Promise.resolve({
        ok: true,
        value: {},
      });
    }

    const investidor = this.convertAnyToInvestidor(investidorAny);

    return Promise.resolve({
      ok: true,
      value: investidor,
    });
  }

  private convertAnyToInvestidor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    investidorAny: any
  ): Writeable<CacheInvestidor> {
    const investidor: Writeable<CacheInvestidor> = {};

    if (investidorAny.associacoes_anjo)
      investidor.associacoes_anjo = investidorAny.associacoes_anjo;

    if (investidorAny.areas_de_especializacao)
      investidor.areas_de_especializacao =
        investidorAny.areas_de_especializacao;

    if (investidorAny.premiacoes_e_certificados)
      investidor.premiacoes_e_certificados =
        investidorAny.premiacoes_e_certificados;

    if (investidorAny.interesse_startup_estagio)
      investidor.interesse_startup_estagio =
        investidorAny.interesse_startup_estagio;

    if (investidorAny.interesse_startup_localizacoes)
      investidor.interesse_startup_localizacoes =
        investidorAny.interesse_startup_localizacoes;

    if (investidorAny.interesse_startup_modelo_negocio)
      investidor.interesse_startup_modelo_negocio =
        investidorAny.interesse_startup_modelo_negocio;

    if (investidorAny.interesse_startup_segmento_atuacao)
      investidor.interesse_startup_segmento_atuacao =
        investidorAny.interesse_startup_segmento_atuacao;

    if (investidorAny.interesse_startup_mecanismo_solucao)
      investidor.interesse_startup_mecanismo_solucao =
        investidorAny.interesse_startup_mecanismo_solucao;

    if (investidorAny.tese_de_investimento)
      investidor.tese_de_investimento = investidorAny.tese_de_investimento;

    return investidor;
  }
}
