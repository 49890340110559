import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik } from "formik";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { AuthErrorValidation } from "../../../domain/erros/AuthErrors";
import { AutenticacaoService } from "../../../domain/usecases/autenticacaoService";
import { getEmailFromStorage } from "../../../hooks/handlerEmailFromStorage";
import { ResizeContext } from "../../../hooks/useResize";

export const ConteudoEsqueceuSenha = () => {
  const navigate = useNavigate();
  const { responsive } = useContext(ResizeContext);
  const autenticacaoService = AutenticacaoService.newAutenticacaoService();

  return (
    <Formik<{ email: string }>
      initialValues={{
        email: getEmailFromStorage() ?? "",
      }}
      validationSchema={emailSchema}
      onSubmit={async (values, { setFieldError }) => {
        const resetPasswordResult = await autenticacaoService.resetPassword(
          values.email
        );

        if (resetPasswordResult.ok) {
          navigate("/reset_password_sent");
        } else {
          if ("campos" in resetPasswordResult.error) {
            (resetPasswordResult.error as AuthErrorValidation).campos.forEach(
              (campoErro) =>
                setFieldError(campoErro, resetPasswordResult.error.message)
            );
          }
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValid,
      }) => (
        <form
          className={`${responsive.sm ? "px-1" : "px-5"}`}
          onSubmit={handleSubmit}
        >
          <div className="text-center mb-4">
            <h2
              className="m-0"
              style={{ fontSize: "50px", fontWeight: "bold" }}
            >
              Esqueceu a Senha?
            </h2>
            <span style={{ fontSize: "20px", fontWeight: "bold" }}>
              Para redefinir sua senha, informe sua conta de email.
            </span>
          </div>

          <div className="input-group mb-4 has-validation">
            {!responsive.sm && (
              <span className="input-group-text border-end-0 bg-secondary bg-opacity-10">
                <FontAwesomeIcon icon={faEnvelope} className="opacity-25" />
              </span>
            )}
            <input
              type="email"
              name="email"
              className={`${
                responsive.sm
                  ? "imputMobile mt-3"
                  : "form-control border-start-0 m-0 bg-secondary bg-opacity-10 text-secondary"
              }
                    ${
                      errors.email && touched.email && errors.email
                        ? " is-invalid"
                        : ""
                    }`}
              placeholder="Email*"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
            />
            <div className="invalid-feedback">
              {errors.email && touched.email && errors.email}
            </div>
          </div>

          <div className="mb-2 text-center">
            <span className="fw-bold">
              Você receberá um email automático com um link para redefinir sua
              senha.
            </span>
          </div>

          <div className="text-center">
            <button
              className="btn btn-lg fw-bold "
              style={{
                backgroundColor: "#525200",
                color: " #fff ",
                fontSize: "28px",
                width: "80%",
              }}
              type="submit"
              disabled={isSubmitting || !isValid}
            >
              REDEFINIR SENHA
            </button>
          </div>
          <div className="form-check mt-2 fw-bold mb-4">
            <label style={{ fontSize: "14px" }} className="form-check-label">
              Caso não tenha acesso ao e-mail cadastrado, entre em contato com o
              nosso Antendimento.
            </label>
          </div>

          <div className="d-flex justify-content-between">
            <div className="text-center mb-2 fw-bold">
              Email não cadastrado?
              <Link to={"/cadastro"}>
                <span
                  style={{
                    color: "#002776",
                    borderBottom: "1px #002776 solid",
                  }}
                >
                  {" "}
                  Cadastre-se agora{" "}
                </span>
              </Link>
            </div>

            <div className="text-center mb-2 fw-bold">
              Voltar para:
              <Link to={"/login"}>
                <span
                  style={{
                    color: "#002776",
                    borderBottom: "1px #002776 solid",
                  }}
                >
                  {" "}
                  Login{" "}
                </span>
              </Link>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

const emailSchema = Yup.object().shape({
  email: Yup.string().email("Email inválido").required("Email obrigatório"),
});
