import { useEffect, useMemo, useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";

import {
  TIPOS_CADASTRO,
  TipoCadastro,
} from "../../domain/entities/TipoCadastro";
import showStakeholder from "../../utils/showStakeholder";

export function InputStakeholdersGrupo({
  label,
  value,
  id,
  onChange,
  validate,
  className = "",
}: {
  label: string;
  value: TipoCadastro[];
  id: string;
  onChange: (stakeholders: TipoCadastro[]) => void;
  validate: (stakeholders: TipoCadastro[]) => Error | Error[] | undefined;
  className?: string;
}) {
  const [error, setError] = useState<Error | Error[]>();
  const [isChanged, setIsChanged] = useState<boolean>(false);

  const stakeholdersNotSelected = useMemo<TipoCadastro[]>(() => {
    return TIPOS_CADASTRO.filter((tipo) => !value.includes(tipo));
  }, [value]);

  useEffect(() => {
    const validateError = validate(value);

    if (validateError) {
      setError(validateError);
    } else {
      setError(undefined);
    }
  }, [validate, value]);

  return (
    <div className={"mb-3 has-validation " + className}>
      <label className="form-label fw-bolder m-0 d-block" htmlFor={id}>
        {label}
      </label>

      {value.map((stakeholder, indexValue) => (
        <div key={stakeholder} className="mb-2">
          <StakeholderDropdown
            stakeholder={stakeholder}
            stakeholdersSelect={stakeholdersNotSelected}
            onRemoveClick={() => {
              value.splice(indexValue, 1);

              onChange([...value]);

              setIsChanged(true);
            }}
            onStakeholderChange={(newStakeholder) => {
              value.splice(indexValue, 1, newStakeholder);

              onChange([...value]);

              setIsChanged(true);
            }}
          />

          {isChanged &&
            error instanceof Array &&
            error[indexValue] instanceof Error && (
              <div className="text-danger flex-fill mb-2">
                {error[indexValue].message}
              </div>
            )}
        </div>
      ))}

      <AddDropdown
        stakeholdersSelect={stakeholdersNotSelected}
        onStakeholderSelect={(stakeholder) => {
          value.push(stakeholder);

          onChange([...value]);

          setIsChanged(true);
        }}
      />

      {isChanged && error instanceof Error && (
        <div className="text-danger flex-fill">{error.message}</div>
      )}
    </div>
  );
}

function AddDropdown({
  stakeholdersSelect,
  onStakeholderSelect,
}: {
  stakeholdersSelect: TipoCadastro[];
  onStakeholderSelect: (stakeholder: TipoCadastro) => void;
}) {
  return (
    <>
      {stakeholdersSelect.length > 0 && (
        <DropdownButton
          title={<FontAwesomeIcon icon={faPlus} />}
          variant="light"
          className="removeCaret"
        >
          {stakeholdersSelect.map((stakeholderSelect) => (
            <Dropdown.Item
              key={stakeholderSelect}
              onClick={() => onStakeholderSelect(stakeholderSelect)}
            >
              {showStakeholder(stakeholderSelect)}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      )}
    </>
  );
}

function StakeholderDropdown({
  stakeholder,
  stakeholdersSelect,
  onStakeholderChange,
  onRemoveClick,
}: {
  stakeholder: TipoCadastro;
  stakeholdersSelect: TipoCadastro[];
  onStakeholderChange: (stakeholder: TipoCadastro) => void;
  onRemoveClick: () => void;
}) {
  return (
    <>
      {stakeholdersSelect.length > 0 ? (
        <DropdownButton
          title={<span className="pe-4">{showStakeholder(stakeholder)}</span>}
          variant="light"
        >
          {stakeholdersSelect.map((stakeholderSelect) => (
            <Dropdown.Item
              key={stakeholderSelect}
              onClick={() => onStakeholderChange(stakeholderSelect)}
            >
              {showStakeholder(stakeholderSelect)}
            </Dropdown.Item>
          ))}

          <Dropdown.Item
            onClick={() => onRemoveClick()}
            className="bg-danger-subtle text-danger text-center"
          >
            <FontAwesomeIcon icon={faXmark} />
          </Dropdown.Item>
        </DropdownButton>
      ) : (
        <div
          className="btn-group"
          role="group"
          aria-label="Basic mixed styles example"
        >
          <button type="button" className="btn btn-light">
            {showStakeholder(stakeholder)}
          </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => onRemoveClick()}
          >
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </div>
      )}
    </>
  );
}
