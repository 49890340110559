/* eslint-disable quotes */
import {
  FocusEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Select, {
  GroupBase,
  MultiValue,
  SingleValue,
  StylesConfig,
} from "react-select";
import { BehaviorSubject, debounceTime, filter, switchMap, tap } from "rxjs";

import { HandlerQueryLocation } from "../../domain/usecases/interfaces/handlerQueryLocation";
import { LocationFinderOutput } from "../../domain/usecases/locationFinder";

export function SelectLocalizacao({
  placeHolder,
  onCitySelected,
  onBlur,
  isMulti,
  className,
  handlerQueryLocation,
  value,
  style,
  id,
}: {
  placeHolder?: string;
  style?:
    | StylesConfig<
        LocationFinderOutput,
        boolean,
        GroupBase<LocationFinderOutput>
      >
    | undefined;
  onCitySelected?: (
    city: SingleValue<LocationFinderOutput> | MultiValue<LocationFinderOutput>
  ) => void;
  onBlur?: FocusEventHandler | undefined;
  isMulti?: boolean;
  className?: string;
  handlerQueryLocation: HandlerQueryLocation;
  value?: SingleValue<LocationFinderOutput> | MultiValue<LocationFinderOutput>;
  id?: string;
}): JSX.Element {
  const [carregando, setCarregando] = useState(false);
  const [inputSearch, setInputSearch] = useState("");
  const [locationFinderResult, setLocationFinderResult] = useState<
    LocationFinderOutput[]
  >([]);

  const onExecLocationFinderSubject = useMemo(
    () => new BehaviorSubject<string>(inputSearch),
    [inputSearch]
  );

  useEffect(() => {
    if (inputSearch) onExecLocationFinderSubject.next(inputSearch);
    else setLocationFinderResult([]);

    const sub = onExecLocationFinderSubject
      .pipe(
        tap((inputValue) => {
          if (!inputValue) {
            setCarregando(false);
          }
        }),
        filter((inputValue) => !!inputValue),
        tap(() => setCarregando(true)),
        debounceTime(700),
        switchMap((inputValue) => handlerQueryLocation.query(inputValue))
      )
      .subscribe((locations) => {
        setLocationFinderResult(locations);
        setCarregando(false);
      });

    return function () {
      sub.unsubscribe();
    };
  }, [handlerQueryLocation, inputSearch, onExecLocationFinderSubject]);

  const handleInputSearchChange = (inputValue: string) => {
    setInputSearch(inputValue);
  };

  const handleSelectChange = useCallback(
    (
      selectedValue:
        | SingleValue<LocationFinderOutput>
        | MultiValue<LocationFinderOutput>
    ) => {
      if (
        JSON.stringify(selectedValue).localeCompare(JSON.stringify(value)) !=
          0 &&
        onCitySelected
      ) {
        onCitySelected(selectedValue);
      }
    },
    [onCitySelected, value]
  );

  return (
    <Select<LocationFinderOutput, boolean>
      styles={style}
      getOptionLabel={(o) => getLabelLocation(o)}
      getOptionValue={(o) => getValueLocation(o)}
      placeholder={placeHolder}
      isLoading={carregando}
      options={locationFinderResult}
      hideSelectedOptions={false}
      onInputChange={handleInputSearchChange}
      onChange={handleSelectChange}
      loadingMessage={() => "carregando..."}
      noOptionsMessage={({ inputValue }) =>
        inputValue
          ? 'local "' + inputValue + '" não encontrado'
          : "buscar local..."
      }
      onBlur={onBlur}
      isMulti={isMulti}
      className={className}
      value={value}
      inputId={id}
    ></Select>
  );
}

function getLabelLocation(location: LocationFinderOutput): string {
  switch (location.tipo) {
    case "pais":
      return location.pais + " (país)";
    case "estado":
      return location.estado + ", " + location.pais + " (estado)";
    case "cidade":
      return (
        location.cidade +
        ", " +
        location.estado +
        ", " +
        location.pais +
        " (cidade)"
      );
    default:
      return "?";
  }
}

function getValueLocation(location: LocationFinderOutput): string {
  switch (location.tipo) {
    case "pais":
      return location.tipo + "|" + location.paisCode;
    case "estado":
      return location.tipo + "|" + location.paisCode + "|" + location.estado;
    case "cidade":
      return (
        location.tipo +
        "|" +
        location.paisCode +
        "|" +
        location.estado +
        "|" +
        location.cidade
      );
    default:
      return "?";
  }
}
