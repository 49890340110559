import { Result } from "../../typings";
import { EstagioStartup } from "./EstagioStartup";
import { MecanismoDeSolucaoTecnologicaOutput } from "./mecanismoDeSolucaoTecnologica";
import { ModeloNegocioOutput } from "./modeloNegocio";
import { SegmentoAtuacaoOutput } from "./segmentoAtuacao";
import { AreaConhecimentoOutput } from "./areaConhecimento";
import { AreaAplicacaoTecnologiaOutput } from "./areaAplicacaoTecnologia";
import { InteresseInovacaoOutput } from "./InteresseInovacao";
import { SetorAtuacaoOutput } from "./SetorAtuacao";
import { GrupoDePesquisa, GrupoDePesquisaOutput } from "./grupoDePesquisa";
import { LocalizacaoOutput } from "./localizacao";

export class Empresa {
  private constructor(private input: InputEmpresa) {}
  public static create(input: InputEmpresa): Result<Empresa> {
    const errors: Error[] = [];

    // nome: string;
    // telefone: string;
    // sobre: string;
    // email: string;
    // gestores: { auth_id: string }[];
    // interesses_inovacao: InteresseInovacaoOutput[];
    // setores_atuacao: SetorAtuacaoOutput[];
    // TODO: validate input empresa entity

    if (errors.length > 0) {
      return {
        ok: false,
        error: errors[0],
      };
    }

    return {
      ok: true,
      value: new Empresa(input),
    };
  }
  public get sobre(): string {
    return this.input.sobre;
  }
  public get setores_atuacao(): SetorAtuacaoOutput[] {
    return this.input.setores_atuacao;
  }
  public get interesses_inovacao(): InteresseInovacaoOutput[] {
    return this.input.interesses_inovacao;
  }
  public get website(): string | undefined {
    return this.input.website;
  }
  public get nome() {
    return this.input.nome;
  }

  public get telefone() {
    return this.input.telefone;
  }
  public get email() {
    return this.input.email;
  }
  public get gestores() {
    return this.input.gestores;
  }
  public get path_logo() {
    return this.input.path_logo;
  }
  public get linkedin() {
    return this.input.linkedin;
  }
  public get interesses_cientista_tecnologia_areas_aplicacao() {
    return this.input.interesses_cientista_tecnologia_areas_aplicacao;
  }
  public get interesses_cientista_tecnologia_mecanismos_solucao() {
    return this.input.interesses_cientista_tecnologia_mecanismos_solucao;
  }
  public get interesses_cientista_tecnologia_area_conhecimento() {
    return this.input.interesses_cientista_tecnologia_area_conhecimento;
  }
  public get interesses_cientista_grupo_pesquisa() {
    return this.input.interesses_cientista_grupo_pesquisa;
  }
  public get interesse_startup_estagio() {
    return this.input.interesse_startup_estagio;
  }
  public get interesse_startup_localizacoes() {
    return this.input.interesse_startup_localizacoes;
  }
  public get interesse_startup_modelo_negocio() {
    return this.input.interesse_startup_modelo_negocio;
  }
  public get interesse_startup_segmento_atuacao() {
    return this.input.interesse_startup_segmento_atuacao;
  }
  public get interesse_startup_mecanismo_solucao() {
    return this.input.interesse_startup_mecanismo_solucao;
  }
}

export type InputEmpresa = {
  nome: string;
  telefone: string;
  sobre: string;
  email: string;
  gestores: string[];
  interesses_inovacao: InteresseInovacaoOutput[];
  setores_atuacao: SetorAtuacaoOutput[];
  created_in: Date;
  habilitado: boolean;
} & Partial<{
  website: string; // TODO: VALIDATE URL
  path_logo: string;
  linkedin: string; // TODO: VALIDATE URL
  interesses_cientista_tecnologia_areas_aplicacao: AreaAplicacaoTecnologiaOutput[];
  interesses_cientista_tecnologia_mecanismos_solucao: MecanismoDeSolucaoTecnologicaOutput[];
  interesses_cientista_tecnologia_area_conhecimento: AreaConhecimentoOutput[];
  interesses_cientista_grupo_pesquisa: GrupoDePesquisaOutput[];

  interesse_startup_estagio: EstagioStartup[];
  interesse_startup_localizacoes: LocalizacaoOutput[];
  interesse_startup_modelo_negocio: ModeloNegocioOutput[];
  interesse_startup_segmento_atuacao: SegmentoAtuacaoOutput[];
  interesse_startup_mecanismo_solucao: MecanismoDeSolucaoTecnologicaOutput[];
}>;

export type OutputEmpresa = InputEmpresa & {
  id: string;
};

export type InputUpdateEmpresa = {
  id: string;
} & Partial<{
  interesses_cientista_tecnologia_areas_aplicacao: AreaAplicacaoTecnologiaOutput[];
  interesses_cientista_tecnologia_mecanismos_solucao: MecanismoDeSolucaoTecnologicaOutput[];
  interesses_cientista_tecnologia_area_conhecimento: AreaConhecimentoOutput[];
  interesses_cientista_grupo_pesquisa: Pick<
    GrupoDePesquisa,
    "id" | "nome" | "tipo" | "aprovado"
  >[];

  interesse_startup_estagio: EstagioStartup[];
  interesse_startup_localizacoes: LocalizacaoOutput[];
  interesse_startup_modelo_negocio: ModeloNegocioOutput[];
  interesse_startup_segmento_atuacao: SegmentoAtuacaoOutput[];
  interesse_startup_mecanismo_solucao: MecanismoDeSolucaoTecnologicaOutput[];
}>;
