import { useContext } from "react";
import { useParams } from "react-router-dom";

import { CurrentUserContext } from "../../../globalStore/CurrentUserContext";
import UserAndPerfilToShow from "./UserAndPerfilToShow";

export function User() {
  const { id_user, id_perfil } = useParams();

  const {
    currentUserScyggz: { user },
  } = useContext(CurrentUserContext);

  return (
    <>
      {user && (
        <UserAndPerfilToShow
          id_user={id_user}
          id_perfil={id_perfil}
          currentUser={user}
        />
      )}
    </>
  );
}
