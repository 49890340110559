import {
  Auth,
  User,
  onAuthStateChanged,
  sendSignInLinkToEmail,
  ActionCodeSettings,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { doc, setDoc } from "@firebase/firestore";
import { FirebaseError } from "firebase/app";
import { BehaviorSubject, catchError, from } from "rxjs";
import * as Yup from "yup";

import { FirebaseService } from "../../infra/firebase/firebaseService";
import {
  AuthError,
  AuthErrorValidation,
  AuthErrorsFactory,
} from "../erros/AuthErrors";
import { InputUsuarioScyggzEntity } from "../entities/usuarioScyggz";
import { saveEmailToStorage } from "../../hooks/handlerEmailFromStorage";
import { OutputAuthEntity } from "../entities/Auth";
import { HandlerAuthFirebase } from "../../gateway/HandlerAuthFirebase";
import { Result } from "../../typings";
import { UsuarioScyggzValidator } from "../entities/UsuarioScyggzValidator";

export class AutenticacaoService {
  static autenticacaoInstancia: AutenticacaoService;

  currentUserAuth$ = new BehaviorSubject<UserAuthRealtime>({
    status: "carregando",
    user_auth: this.getCurrentUser(),
  });

  private onUserChangedCallback: undefined | ((user: User | null) => void);

  LoginSchema = Yup.object().shape({
    email: Yup.string().email("Email inválido").required("Email obrigatório"),
    password: Yup.string()
      .min(
        UsuarioScyggzValidator.MIN_SIZE_PASSWORD,
        UsuarioScyggzValidator.MIN_SIZE_PASSWORD_ERROR
      )
      .required("Senha obrigatória"),
  });

  CadastroSchemaValidation = Yup.object().shape({
    nome: Yup.string()
      .min(UsuarioScyggzValidator.MIN_SIZE_NOME, "Nome muito curto")
      .required("Nome obrigatório"),
    email: Yup.string().email("Email inválido").required("Email obrigatório"),
    password: Yup.string()
      .min(
        UsuarioScyggzValidator.MIN_SIZE_PASSWORD,
        UsuarioScyggzValidator.MIN_SIZE_PASSWORD_ERROR
      )
      .required("Senha obrigatória"),
  });

  private constructor(private firebaseAuth: Auth) {
    this.addUserObserver();
  }

  static newAutenticacaoService() {
    if (!AutenticacaoService.autenticacaoInstancia) {
      AutenticacaoService.autenticacaoInstancia = new AutenticacaoService(
        FirebaseService.newFirebaseService().getAuth()
      );
    }

    return AutenticacaoService.autenticacaoInstancia;
  }

  private addUserObserver = () => {
    onAuthStateChanged(this.firebaseAuth, (user) => {
      if (this.onUserChangedCallback) this.onUserChangedCallback(user);

      if (user && user.uid && user.email) {
        this.currentUserAuth$.next({
          status: "carregado",
          user_auth: {
            auth_id: user.uid,
            email: user.email,
            emailVerified: user.emailVerified,
          },
        });
      } else {
        this.currentUserAuth$.next({
          status: "carregado",
          user_auth: null,
        });
      }
    });
  };

  private getCurrentUser(): OutputAuthEntity | null {
    const user = this.firebaseAuth.currentUser;
    if (user && user.uid && user.email) {
      return {
        auth_id: user.uid,
        email: user.email,
        emailVerified: user.emailVerified,
      };
    } else {
      return null;
    }
  }

  getCurrentUserAuth(): UserAuthRealtime {
    return this.currentUserAuth$.value;
  }

  doLogin = (email: string, password: string) => {
    this.currentUserAuth$.next({ status: "logando", user_auth: null });
    return from(
      signInWithEmailAndPassword(this.firebaseAuth, email, password)
    ).pipe(
      catchError((error: FirebaseError) => {
        this.currentUserAuth$.next({ status: "carregado", user_auth: null });

        const instanciaErro = AuthErrorsFactory.createAuthErrorsValidation(
          <AuthError>error.code
        );
        if (instanciaErro) {
          throw instanciaErro;
        }

        console.warn("Erro no login: " + error.code);
        throw new Error("Erro");
      })
    );
  };

  doLogout = () => {
    return this.firebaseAuth.signOut();
  };

  signInWithEmailLink = (email: string) => {
    const actionCodeSettings: ActionCodeSettings = {
      url: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN ?? "scyggz.web.app",
      handleCodeInApp: true,
    };

    return sendSignInLinkToEmail(this.firebaseAuth, email, actionCodeSettings)
      .then(() => {
        saveEmailToStorage(email);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.warn(`errorCode:${errorCode}, errorMessage:${errorMessage}`);
      });
  };

  cadastrarUsuario(email: string, password: string) {
    this.currentUserAuth$.next({ status: "cadastrando", user_auth: null });
    return from(
      createUserWithEmailAndPassword(this.firebaseAuth, email, password)
    ).pipe(
      catchError((error: FirebaseError) => {
        this.currentUserAuth$.next({ status: "carregado", user_auth: null });

        const instanciaErro = AuthErrorsFactory.createAuthErrorsValidation(
          <AuthError>error.code
        );
        if (instanciaErro) {
          throw instanciaErro;
        }

        console.warn("Erro no cadastro: " + error.code);
        throw new Error("Erro");
      })
    );
  }

  addUserFirestore(auth_id: string, user: InputUsuarioScyggzEntity) {
    const firebaseService: FirebaseService =
      FirebaseService.newFirebaseService();

    const docUser = doc(firebaseService.getFirestore(), "usuarios", auth_id);

    return setDoc(docUser, user);
  }

  async resetPassword(
    email: string
  ): Promise<Result<null, AuthErrorValidation | FirebaseError | Error>> {
    const handlerResetPasswordFirebase = new HandlerAuthFirebase(
      this.firebaseAuth
    );

    return handlerResetPasswordFirebase
      .sendPasswordResetEmail(email)
      .then((result) => {
        if (!result.ok) {
          if (result.error instanceof FirebaseError) {
            const instanciaErro = AuthErrorsFactory.createAuthErrorsValidation(
              <AuthError>result.error.code
            );

            if (instanciaErro)
              return {
                ok: false,
                error: instanciaErro,
              };

            return {
              ok: false,
              error: result.error,
            };
          }
        }

        return result;
      });
  }
}

export type UserAuthRealtime = {
  status: StatusUserAuthRealtime;
  user_auth: OutputAuthEntity | null;
};

type StatusUserAuthRealtime =
  | "carregando"
  | "carregado"
  | "cadastrando"
  | "logando";
