import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";

import { AreaConhecimentoOutput } from "../../../../../domain/entities/areaConhecimento";
import { Cientista } from "../../../../../domain/entities/Cientista";
import { Result } from "../../../../../typings";
import { BadgeInfo } from "./BadgeInfo";
import { InputAreasConhecimento } from "../../../../generalComponents/InputAreasConhecimento";

export const InfoAreaDeConhecimento = ({
  areasConhecimento,
  classes,
  handlerEdit,
}: {
  classes?: string;
  areasConhecimento: AreaConhecimentoOutput[];
  handlerEdit?: (
    inputEdit: AreaConhecimentoOutput[]
  ) => Promise<Result<unknown>>;
}) => {
  const [areasConhecimentoToSave, setAreasConhecimentoToSave] = useState<
    AreaConhecimentoOutput[]
  >([]);
  const [showModalAdd, setShowModalAdd] = useState<boolean>(false);
  const [carregando, setCarregando] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  useEffect(() => {
    areasConhecimento.forEach((area) => {
      if (
        !areasConhecimentoToSave.some((areaToSave) => areaToSave.id == area.id)
      ) {
        areasConhecimentoToSave.push(area);
      }
    });
  }, [areasConhecimento, areasConhecimentoToSave]);

  useEffect(() => {
    const validateResult = Cientista.validateUpdate({
      user_id: "...",
      areas_de_conhecimento: areasConhecimentoToSave,
    });

    setError(validateResult.ok ? "" : validateResult.error.message);
  }, [areasConhecimentoToSave]);

  return (
    <div className={classes}>
      <div>
        <div>
          <h5 className="text-green-strong fw-bolder">Áreas de conhecimento</h5>
          {handlerEdit && (
            <button
              type="button"
              className="btn btn-primary rounded-circle m-2"
              onClick={() => setShowModalAdd(true)}
            >
              <FontAwesomeIcon icon={faPencil} />
            </button>
          )}
        </div>
        <div>
          {areasConhecimento.map((area, i) => (
            <BadgeInfo children={area.nome} key={i} />
          ))}
        </div>
      </div>

      <Modal show={showModalAdd} onHide={() => setShowModalAdd(false)} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Modal.Title className="mb-3">
            Especificar áreas de conhecimento
          </Modal.Title>

          <div className="mb-3">
            <InputAreasConhecimento
              label=""
              value={areasConhecimentoToSave ?? []}
              onChange={(areas_de_conhecimento) => {
                setAreasConhecimentoToSave([...areas_de_conhecimento]);
              }}
              validate={Cientista.validateAreasConhecimento}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            disabled={!!error || carregando}
            onClick={async () => {
              if (handlerEdit && areasConhecimentoToSave) {
                setCarregando(true);
                const handlerEditResult = await handlerEdit(
                  areasConhecimentoToSave
                );
                setCarregando(false);

                if (!handlerEditResult.ok) {
                  console.warn(handlerEditResult.error);
                  return;
                } else {
                  setAreasConhecimentoToSave([]);
                  setShowModalAdd(false);
                }
              } else setShowModalAdd(false);
            }}
          >
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
