import { createContext } from "react";

import { Result } from "../../typings";

export const ConexoesPendentesEnviadasCountContext = createContext<{
  conexoesPendentesEnviadasCountResult: TypeConexoesPendentesEnviadasCountContext;
}>({
  conexoesPendentesEnviadasCountResult: undefined,
});

export type TypeConexoesPendentesEnviadasCountContext =
  | Result<number>
  | undefined;
