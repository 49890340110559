import { useContext, useMemo } from "react";

import { ConviteProgramaConexao } from "../../../../domain/entities/ConviteProgramaConexao";
import { ImgFromPath } from "../../../generalComponents/ImgFromPath";
import { ProgramaConexao } from "../../../../domain/entities/ProgramaConexao";
import { TipoCadastro } from "../../../../domain/entities/TipoCadastro";
import { HandlersDatabaseContext } from "../../../../globalStore/HandlersProviders/HandlersDatabaseContext";

export function ListItemConviteProgramaDeConexao({
  convite: {
    id,
    programa_conexao: {
      logo_path,
      nome,
      stakeholder_type_group_a,
      stakeholder_type_group_b,
      message_to_a,
      message_to_b,
    },
    to_group,
  },
  className = "",
  onConviteAccepted,
  onConviteRejected,
}: {
  convite: ConviteProgramaConexao;
  className?: string;
  onConviteAccepted: () => void;
  onConviteRejected: () => void;
}) {
  const {
    handlerConviteProgramaConexaoAccept,
    handlerConviteProgramaConexaoReject,
  } = useContext(HandlersDatabaseContext);

  const stakeHolders = useMemo<TipoCadastro[]>(
    () =>
      ProgramaConexao.getStakeHolders({
        stakeholder_type_group_a,
        stakeholder_type_group_b,
      }),
    [stakeholder_type_group_a, stakeholder_type_group_b]
  );

  return (
    <div className={"card shadow-sm " + className}>
      <div className="card-body">
        <h5 className="pb-2 fw-bolder">
          Você foi convidado pra um programa de conexão!
        </h5>

        <div className="card-body d-flex gap-3 align-items-center">
          <div className="flex-shrink-1">
            <ImgFromPath
              path={logo_path}
              className="rounded-circle border-white object-fit-cover"
              alt={nome}
              style={{
                width: "90px",
                height: "90px",
                border: "2px solid #fff",
              }}
            />
          </div>

          <div className="flex-fill">
            <h5 className="fw-bolder">{nome}</h5>

            {stakeHolders.length > 0 && (
              <div
                className="d-block fw-bolder color-spinoff"
                style={{
                  fontSize: "14px",
                  textTransform: "capitalize",
                }}
              >
                {stakeHolders.map((stakeHolder, i) => (
                  <span key={i}>
                    {i > 0 && " | "}
                    {stakeHolder}
                  </span>
                ))}
              </div>
            )}
          </div>
        </div>

        {to_group.includes("a") && (
          <p className="rounded bg-secondary-subtle p-2 px-3">{message_to_a}</p>
        )}
        {to_group.includes("b") && (
          <p className="rounded bg-secondary-subtle p-2 px-3">{message_to_b}</p>
        )}

        <div className="card-footer text-muted border-0 bg-white text-end">
          <button
            type="button"
            className="btn bg-danger-subtle text-danger px-5 me-2 fw-bolder"
            onClick={async () => {
              await handlerConviteProgramaConexaoReject?.reject({ id });

              onConviteRejected();
            }}
          >
            Negar
          </button>
          <button
            type="button"
            className="btn btn-success px-5 fw-bolder"
            onClick={async () => {
              const acceptResult =
                await handlerConviteProgramaConexaoAccept?.accept({ id });

              if (acceptResult?.ok) onConviteAccepted();
              else console.warn("acceptResult", acceptResult?.error);
            }}
          >
            Aceitar
          </button>
        </div>
      </div>
    </div>
  );
}
