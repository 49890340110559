import { useNavigate } from "react-router-dom";

import { HandlerCacheStartup } from "../../../../../domain/usecases/interfaces/HandleCacheStartup";
import { HandlerCreateStartup } from "../../../../../domain/usecases/interfaces/handlerCreateStartup";
import { HandlerSavePitchPdfStartup } from "../../../../../domain/usecases/interfaces/handlerSavePitchPdfStartup";
import { HandlerSavePlanilhaStartup } from "../../../../../domain/usecases/interfaces/handlerSavePlanilhaStartup";
import { ContainerResponsive } from "../../../../generalComponents/ContainerResponsive";
import { ConteudoMaisSobreStartup } from "./ConteudoMaisSobreStartup";

export const PainelMaisSobreStartup = ({
  handlerCacheStartup,
  handlerCreateStartup,
  handlerSavePitchPdfStartup,
  handlerSavePlanilhaStartup,
}: {
  handlerCreateStartup: HandlerCreateStartup;
  handlerCacheStartup: HandlerCacheStartup;
  handlerSavePitchPdfStartup: HandlerSavePitchPdfStartup;
  handlerSavePlanilhaStartup: HandlerSavePlanilhaStartup;
}) => {
  const navigate = useNavigate();

  const paginacao = {
    start: 6,
    end: 7,
  };

  const backUrl = "/cadastro/startup/5";

  return (
    <ContainerResponsive
      backUrlMobile={backUrl}
      paginacao={paginacao}
      textoMarcaDagua="Startup"
      showLogo={true}
    >
      <ConteudoMaisSobreStartup
        onPrevious={() => {
          navigate(backUrl);
        }}
        onProximo={() => {
          navigate("/cadastro/startup/7");
        }}
        handlerCacheStartup={handlerCacheStartup}
        handlerCreateStartup={handlerCreateStartup}
        handlerSavePitchPdfStartup={handlerSavePitchPdfStartup}
        handlerSavePlanilhaStartup={handlerSavePlanilhaStartup}
      />
    </ContainerResponsive>
  );
};
