import { Result } from "../../typings";
import { TipoCadastro } from "./TipoCadastro";

export class ProgramaConexao {
  static readonly MIN_SIZE_NOME = 5;
  static readonly MIN_SIZE_NOME_ERROR = "Nome muito curto";
  static readonly MAX_SIZE_NOME = 150;
  static readonly MAX_SIZE_NOME_ERROR = "Nome muito longo";

  static readonly MIN_SIZE_ABOUT = 3;
  static readonly MIN_SIZE_ABOUT_ERROR = "Muito curto";
  static readonly MAX_SIZE_ABOUT = 1000;
  static readonly MAX_SIZE_ABOUT_ERROR = "Muito longo";

  readonly id!: string;

  private constructor(
    readonly nome: string,
    readonly logo_path: string,
    readonly start_date: Date,
    readonly end_date: Date,
    readonly about: string,
    readonly message_to_a: string,
    readonly message_to_b: string,
    readonly stakeholder_type_group_a: TipoCadastro[],
    readonly stakeholder_type_group_b: TipoCadastro[],
    readonly invitation_rule: InvitationRule,
    readonly gestores: string[]
  ) {}

  public static create(
    input: Omit<ProgramaConexao, "id">
  ): Result<ProgramaConexao, Partial<Record<keyof ProgramaConexao, Error>>> {
    const isInvalid = this.isInvalidProgramaConexao(input);

    if (isInvalid) {
      return {
        ok: false,
        error: isInvalid,
      };
    }

    const programa = new ProgramaConexao(
      input.nome,
      input.logo_path,
      input.start_date,
      input.end_date,
      input.about,
      input.message_to_a,
      input.message_to_b,
      input.stakeholder_type_group_a,
      input.stakeholder_type_group_b,
      input.invitation_rule,
      input.gestores
    );

    return {
      ok: true,
      value: programa,
    };
  }

  public static getStakeHolders({
    stakeholder_type_group_a,
    stakeholder_type_group_b,
  }: Pick<
    ProgramaConexao,
    "stakeholder_type_group_a" | "stakeholder_type_group_b"
  >): TipoCadastro[] {
    return Array.from(
      new Set([...stakeholder_type_group_a, ...stakeholder_type_group_b])
    );
  }

  public static isInvalidProgramaConexao(
    programaConexao: Partial<ProgramaConexao>
  ): Partial<Record<keyof ProgramaConexao, Error>> | undefined {
    const datesResult =
      programaConexao.start_date || programaConexao.end_date
        ? this.isInvalidDates(
            programaConexao.start_date,
            programaConexao.end_date
          )
        : undefined;

    const stakeholdersGroupsResult =
      programaConexao.stakeholder_type_group_a ||
      programaConexao.stakeholder_type_group_b
        ? this.isInvalidStakeHoldersGroups(
            programaConexao.stakeholder_type_group_a,
            programaConexao.stakeholder_type_group_b
          )
        : undefined;

    const errors: Partial<Record<keyof ProgramaConexao, Error>> = {
      logo_path:
        programaConexao.logo_path != undefined
          ? this.isInvalidId(programaConexao.logo_path)
          : undefined,
      nome:
        programaConexao.nome != undefined
          ? this.isInvalidNome(programaConexao.nome)
          : undefined,
      start_date: datesResult?.start_date,
      end_date: datesResult?.end_date,
      about:
        programaConexao.about != undefined
          ? this.isInvalidAbout(programaConexao.about)
          : undefined,
      message_to_a:
        programaConexao.message_to_a != undefined
          ? this.isInvalidMessage(programaConexao.message_to_a)
          : undefined,
      message_to_b:
        programaConexao.message_to_b != undefined
          ? this.isInvalidMessage(programaConexao.message_to_b)
          : undefined,
      stakeholder_type_group_a: stakeholdersGroupsResult?.groupA
        ? stakeholdersGroupsResult.groupA instanceof Error
          ? stakeholdersGroupsResult.groupA
          : new Error(
              stakeholdersGroupsResult.groupA
                .map((erro) => erro.message)
                .join(", ")
            )
        : undefined,
      stakeholder_type_group_b: stakeholdersGroupsResult?.groupB
        ? stakeholdersGroupsResult.groupB instanceof Error
          ? stakeholdersGroupsResult.groupB
          : new Error(
              stakeholdersGroupsResult.groupB
                .map((erro) => erro.message)
                .join(", ")
            )
        : undefined,
      gestores: programaConexao.gestores
        ? this.isInvalidGestores(programaConexao.gestores)
        : undefined,
    };

    const hasError: boolean = Object.values(errors).some((error) => !!error);

    if (hasError) return errors;
  }

  public static isInvalidId(id: string): Error | undefined {
    if (id.length < 1) {
      return new Error("id obrigatório");
    }
  }

  public static isInvalidNome(nome: string): Error | undefined {
    const lengthNome = nome.length;

    if (lengthNome < this.MIN_SIZE_NOME) {
      return new Error(this.MIN_SIZE_NOME_ERROR);
    }

    if (lengthNome > this.MAX_SIZE_NOME) {
      return new Error(this.MAX_SIZE_NOME_ERROR);
    }
  }

  public static isInvalidDates(
    start_date?: Date,
    end_date?: Date
  ): { start_date?: Error; end_date?: Error } | undefined {
    if (!end_date || !start_date) {
      const errors: { start_date?: Error; end_date?: Error } = {};

      if (!start_date) {
        errors.start_date = new Error("Data inválida");
      }

      if (!end_date) {
        errors.end_date = new Error("Data inválida");
      }

      return errors;
    }

    if (end_date <= start_date) {
      return {
        start_date: new Error("Deve ser menor que data final"),
        end_date: new Error("Deve ser maior que data inicial"),
      };
    }
  }

  public static isInvalidAbout(about: string): Error | undefined {
    const length = about.trim().length;

    if (length < this.MIN_SIZE_ABOUT) {
      return new Error(this.MIN_SIZE_ABOUT_ERROR);
    }

    if (length > this.MAX_SIZE_ABOUT) {
      return new Error(this.MAX_SIZE_ABOUT_ERROR);
    }
  }

  public static isInvalidMessage(about: string): Error | undefined {
    return this.isInvalidAbout(about);
  }

  public static isInvalidStakeHoldersGroup(
    groups: TipoCadastro[]
  ): Error | undefined {
    if (groups.length <= 0) {
      return new Error("Obrigatório selecionar stakeholder");
    }
  }

  public static isInvalidStakeHoldersGroups(
    groupA: TipoCadastro[] | undefined,
    groupB: TipoCadastro[] | undefined
  ): { groupA?: Error | Error[]; groupB?: Error | Error[] } | undefined {
    const errors: { groupA?: Error | Error[]; groupB?: Error | Error[] } = {};

    const errorRequiredGroupA = this.isInvalidStakeHoldersGroup(groupA ?? []);
    const errorRequiredGroupB = this.isInvalidStakeHoldersGroup(groupB ?? []);

    if (errorRequiredGroupA || errorRequiredGroupB) {
      if (errorRequiredGroupA) errors.groupA = errorRequiredGroupA;
      if (errorRequiredGroupB) errors.groupB = errorRequiredGroupB;

      return errors;
    }

    const groupAErrors: Error[] = [];
    const groupBErrors: Error[] = [];

    groupA?.forEach((stakeholderA, indexA) => {
      groupB?.forEach((stakeholderB, indexB) => {
        if (stakeholderA == stakeholderB) {
          groupAErrors[indexA] = new Error("Stakeholder já está no grupo B");
          groupBErrors[indexB] = new Error("Stakeholder já está no grupo A");
        }
      });
    });

    if (groupAErrors.length > 0 || groupBErrors.length > 0) {
      return {
        groupA: groupAErrors,
        groupB: groupBErrors,
      };
    }
  }

  public static isInvalidGestores(gestores: string[]): Error | undefined {
    if (gestores.length <= 0) {
      return new Error("Gestor obrigatório");
    }
  }
}

export type InvitationRule = "from_both" | "from_a" | "from_b";
