import { TipoCadastro } from "../domain/entities/TipoCadastro";

export default function showStakeholder(stakeholder: TipoCadastro): string {
  switch (stakeholder) {
    case "cientista":
      return "Cientista Empreendedor";
    case "empresa":
      return "Empresa";
    case "hub":
      return "Hub";
    case "investidor":
      return "Investidor Anjo";
    case "mentor":
      return "Mentor";
    case "nit":
      return "NIT";
    case "startup":
      return "Startup";
    case "vc":
      return "Venture Capital";
  }
}
