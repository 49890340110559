export const TextH4Info = ({
  children,
  classes,
}: {
  children: string;
  classes?: string;
}) => {

  return (
    <h4 className={`${classes}`} style={{ fontSize: "14px", marginBottom: "0", padding: "5px 0" }}>
      {children}
    </h4>
  )
}