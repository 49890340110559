import { OutputNit } from "../../../domain/entities/Nit";
import { HeaderPerfil } from "../components/perfil/HeaderPerfil";
import { InfoHubsDeInvovacao } from "../user/components/generalComponents/InfoHubsDeInovacao.tsx";
import { InfoPertenceInstituicao } from "../user/components/generalComponents/InfoPertenceIntituicao";
import { InfoSobre } from "../user/components/generalComponents/InfoSobre";

export default function PerfilInfoNit({
  sobre,
  nome,
  cidade,
  logo_path,
  instituicoes,
  hubs,
}: Pick<
  OutputNit,
  "sobre" | "nome" | "cidade" | "logo_path" | "instituicoes" | "hubs"
>) {
  return (
    <section className="container">
      <HeaderPerfil
        nome={nome}
        cidade={cidade}
        foto_path={logo_path}
        headline={"Nit"}
      />

      <section>
        <InfoSobre classes="mb-4" sobre={sobre} />
        <InfoPertenceInstituicao classes="mb-4" instituicoes={instituicoes} />
        <InfoHubsDeInvovacao classes="mb-4" hubs={hubs} />
      </section>
    </section>
  );
}
