import { Button, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";

import {
  HandlerUpdateUsuarioScyggz,
  InputHandlerUpdateUsuarioScyggz,
} from "../../../../domain/usecases/interfaces/handlerUpdateUsuarioScyggz";
import { UsuarioScyggzValidator } from "../../../../domain/entities/UsuarioScyggzValidator";
import { InputNomeUsuario } from "../../../generalComponents/InputNomeUsuario";
import { InputHeadlineUsuario } from "../../../generalComponents/InputHeadlineUsuario";
import { InputCidade } from "../../../generalComponents/InputCidade";
import { InputLattes } from "../../../generalComponents/InputLattes";
import { InputLinkedin } from "../../../generalComponents/InputLinkedin";

export function ModalEditUser({
  initialValue,
  show,
  onShowChange,
  handlerUpdateUser,
  onUpdate,
}: {
  initialValue: InputHandlerUpdateUsuarioScyggz & { auth_user_id: string };
  show: boolean;
  onShowChange: (show: boolean) => void;
  handlerUpdateUser: HandlerUpdateUsuarioScyggz;
  onUpdate?: () => void;
}) {
  const [userToEdit, setUserToEdit] = useState<InputHandlerUpdateUsuarioScyggz>(
    {}
  );
  const [error, setError] = useState<
    Error | Partial<Record<keyof InputHandlerUpdateUsuarioScyggz, Error>>
  >();
  const [carregando, setCarregando] = useState<boolean>(false);

  useEffect(() => {
    setUserToEdit({
      ...initialValue,
    });
  }, [initialValue]);

  useEffect(() => {
    const userValidateResult =
      UsuarioScyggzValidator.validateUpdate(userToEdit);
    if (userValidateResult.ok) {
      setError(undefined);
    } else {
      setError(userValidateResult.error);
    }
  }, [userToEdit]);

  return (
    <Modal
      show={show}
      onHide={() => onShowChange(false)}
      centered
      scrollable={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <FontAwesomeIcon icon={faPencil} className="me-2" />
          Editar informações do usuário
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputNomeUsuario
          label="Nome"
          value={userToEdit.nome ?? ""}
          onChange={(nome) => {
            setUserToEdit({ ...userToEdit, nome });
          }}
        />

        <InputHeadlineUsuario
          label="Headline"
          value={userToEdit.headline ?? ""}
          onChange={(headline) => {
            setUserToEdit({ ...userToEdit, headline });
          }}
        />

        <InputCidade
          label="Cidade"
          value={userToEdit.cidade}
          onChange={(cidade) => {
            setUserToEdit({ ...userToEdit, cidade });
          }}
          validate={UsuarioScyggzValidator.isInvalidCidade}
          className="mb-3"
        />

        <InputLattes
          label="Lattes"
          value={userToEdit.url_lattes}
          onChange={(url_lattes) => {
            setUserToEdit({ ...userToEdit, url_lattes });
          }}
        />

        <InputLinkedin
          label="Linkedin"
          value={userToEdit.url_linkedin}
          onChange={(url_linkedin) => {
            setUserToEdit({ ...userToEdit, url_linkedin });
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="success"
          disabled={!!error || carregando}
          onClick={async () => {
            setCarregando(true);

            const handlerUpdateResult =
              await handlerUpdateUser.updateUsuarioScyggz(
                initialValue,
                userToEdit
              );

            setCarregando(false);

            if (handlerUpdateResult.ok) {
              if (onUpdate) onUpdate();

              onShowChange(false);
            } else {
              console.warn(handlerUpdateResult.error);

              setError(handlerUpdateResult.error);
            }
          }}
        >
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
