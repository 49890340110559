/* eslint-disable @typescript-eslint/no-explicit-any */
import "react-phone-input-2/lib/style.css";
import { Field, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import * as Yup from "yup";

import { ButtonDarkGreen } from "../../../../generalComponents/buttonDarkGreen/ButtonDarkGreen";
import { HandlerCreateStartup } from "../../../../../domain/usecases/interfaces/handlerCreateStartup";
import { EstagioStartup } from "../../../../../domain/entities/EstagioStartup";
import { ModeloNegocioOutput } from "../../../../../domain/entities/modeloNegocio";
import { SegmentoAtuacaoOutput } from "../../../../../domain/entities/segmentoAtuacao";
import {
  FundingRound,
  FUNDING_ROUNDS,
} from "../../../../../domain/entities/FundingRound";
import { MecanismoDeSolucaoTecnologicaOutput } from "../../../../../domain/entities/mecanismoDeSolucaoTecnologica";
import { OutputValorFinanceiro } from "../../../../../domain/entities/ValorFinanceiro";
import { OutputHub } from "../../../../../domain/entities/Hub";
import {
  InputStartup,
  OutputStartup,
  StatusStartupHub,
  Startup,
} from "../../../../../domain/entities/Startup";
import { CurrentUserContext } from "../../../../../globalStore/CurrentUserContext";
import { ResizeContext } from "../../../../../hooks/useResize";
import {
  CacheStartup,
  HandlerCacheStartup,
} from "../../../../../domain/usecases/interfaces/HandleCacheStartup";
import { ButtonLightGreen } from "../../../../generalComponents/buttonLightGreen/ButtonLightGreen";
import { HandlerSavePitchPdfStartup } from "../../../../../domain/usecases/interfaces/handlerSavePitchPdfStartup";
import { HandlerSavePlanilhaStartup } from "../../../../../domain/usecases/interfaces/handlerSavePlanilhaStartup";
import { getFileFromUrl } from "../../../../../hooks/getFileFromUrl";
import { getUrlFromFile } from "../../../../../hooks/getUrlFromFile";
import { getFileFromPathStorage } from "../../../../../hooks/getFileFromPathStorage";
import { FileSelect } from "../../../../generalComponents/FileSelect";
import { InputSegmentosAtuacao } from "../../../../generalComponents/InputSegmentosAtuacao";
import { InputMecanismosSolucao } from "../../../../generalComponents/InputMecanismosSolucao";
import { InputModelosNegocio } from "../../../../generalComponents/InputModelosNegocio";
import { InputEstagioStartup } from "../../../../generalComponents/InputEstagioStartup";
import { InputNumber } from "../../../../generalComponents/InputNumber";
import { InputHub } from "../../../../generalComponents/InputHub";
import { InputStatusHub } from "../../../../generalComponents/InputStatusHub";
import { HandlersDatabaseContext } from "../../../../../globalStore/HandlersProviders/HandlersDatabaseContext";

export function ConteudoMaisSobreStartup({
  handlerCacheStartup,
  handlerCreateStartup,
  onPrevious,
  onProximo,
  handlerSavePitchPdfStartup,
  handlerSavePlanilhaStartup,
}: {
  handlerCacheStartup: HandlerCacheStartup;
  handlerCreateStartup: HandlerCreateStartup;
  onPrevious: () => void;
  onProximo: () => void;
  handlerSavePitchPdfStartup: HandlerSavePitchPdfStartup;
  handlerSavePlanilhaStartup: HandlerSavePlanilhaStartup;
}) {
  const { handlerConviteStartupEmployeeCreate } = useContext(
    HandlersDatabaseContext
  );

  const [pitchPlanilhaFile, setPitchPlanilhaFile] = useState<File>();
  const [pitchPdfFile, setPitchPdfFile] = useState<File>();

  const [pitchPlanilhaFileFromURL, setPitchPlanilhaFileFromURL] =
    useState<File>();
  const [pitchPdfFileFromURL, setPitchPdfFileFromURL] = useState<File>();

  const [parteDoNome, setParteDoNome] = useState("");

  const { currentUserScyggz } = useContext(CurrentUserContext);

  useEffect(() => {
    if (currentUserScyggz.user) {
      const parteDoNome: string =
        currentUserScyggz.user.nome.split(" ").at(0) ??
        currentUserScyggz.user.nome;

      setParteDoNome(parteDoNome);
    }
  }, [currentUserScyggz]);

  const [cacheStartup, setCacheStartup] = useState<CacheStartup>({});

  const { responsive } = useContext(ResizeContext);

  useEffect(() => {
    handlerCacheStartup.get().then(async (cacheStartup) => {
      if (cacheStartup.ok) {
        setCacheStartup(cacheStartup.value);

        if (cacheStartup.value.path_pitch_planilha) {
          const getFileResult = await getFileFromPathStorage(
            cacheStartup.value.path_pitch_planilha
          );
          if (getFileResult.ok) setPitchPlanilhaFile(getFileResult.value);
        }

        if (cacheStartup.value.path_pitch_pdf) {
          const getFileResult = await getFileFromPathStorage(
            cacheStartup.value.path_pitch_pdf
          );
          if (getFileResult.ok) setPitchPdfFile(getFileResult.value);
        }

        if (cacheStartup.value.pitchPlanilhaUrl) {
          const file = await getFileFromUrl(
            new URL(cacheStartup.value.pitchPlanilhaUrl.url),
            cacheStartup.value.pitchPlanilhaUrl.nome
          );

          if (file) {
            setPitchPlanilhaFileFromURL(file);
          }
        }

        if (cacheStartup.value.pitchPdfUrl) {
          const file = await getFileFromUrl(
            new URL(cacheStartup.value.pitchPdfUrl.url),
            cacheStartup.value.pitchPdfUrl.nome
          );

          if (file) {
            setPitchPdfFileFromURL(file);
          }
        }
      }
    });
  }, [handlerCacheStartup]);

  return (
    <Formik<FormikFields>
      initialValues={{
        pitch_planilha: pitchPlanilhaFile ?? pitchPlanilhaFileFromURL,
        pitch_pdf: pitchPdfFile ?? pitchPdfFileFromURL,
        estagio: cacheStartup.estagio,
        modelo_negocio: cacheStartup.modelo_negocio ?? [],
        segmento_atuacao: cacheStartup.segmento_atuacao ?? [],
        mercado_potencial: {
          moeda: cacheStartup.mercado_potencial?.moeda ?? "real",
          valor: cacheStartup.mercado_potencial?.valor ?? 0,
        },
        faturamento_medio_mensal: {
          moeda: cacheStartup.faturamento_medio_mensal?.moeda ?? "real",
          valor: cacheStartup.faturamento_medio_mensal?.valor ?? 0,
        },
        valor_captado: {
          moeda:
            cacheStartup.last_funding_round?.valor_captado?.moeda ?? "real",
          valor: cacheStartup.last_funding_round?.valor_captado?.valor ?? 0,
        },
        mecanismo_solucao:
          cacheStartup.interesse_cientista_mecanismo_solucao_tecnologica,
        qtde_funcionarios: cacheStartup.qtde_funcionarios ?? 0,
        funding_round: cacheStartup.last_funding_round?.tipo ?? undefined,
        url_video: cacheStartup.url_video ?? "",
        status_hub: cacheStartup.hub?.status,
        hub: cacheStartup.hub
          ? {
              cidade: {
                cidade: "",
                estado: "",
                pais: "",
                paisCode: "",
                tipo: "cidade",
              },
              classificacao: "outros",
              email: "",
              gestores: [],
              id: cacheStartup.hub?.hub.id && cacheStartup.hub?.hub.id,
              nome: cacheStartup.hub.hub.nome,
              sobre: "",
              telefone: "",
              created_in: new Date(),
              habilitado: true,
            }
          : undefined,
      }}
      validationSchema={validator}
      validateOnMount={true}
      validateOnChange={true}
      validateOnBlur={true}
      enableReinitialize={true}
      onSubmit={async (values) => {
        if (!currentUserScyggz.user?.auth_user_id) {
          console.warn("Necessário estar logado pra criar startup");
          return;
        }
        if (!values.estagio) {
          console.warn("Deve especificar estágio da startup");
          return;
        }

        const startupToCreate: InputStartup = {
          gestores: [currentUserScyggz.user.auth_user_id],

          ano_fundacao: cacheStartup.ano_fundacao ?? -1,
          cidade: cacheStartup.cidade ?? {
            cidade: "",
            estado: "",
            pais: "",
            paisCode: "",
          },
          email: cacheStartup.email ?? "",
          nome: cacheStartup.nome ?? "",
          sobre: cacheStartup.sobre ?? "",
          interesse_cientista_area_aplicacao_tecnologia:
            cacheStartup.interesse_cientista_area_aplicacao_tecnologia,
          interesse_cientista_area_conhecimento:
            cacheStartup.interesse_cientista_area_conhecimento,
          interesse_cientista_instituicao:
            cacheStartup.interesse_cientista_instituicao,
          interesse_cientista_mecanismo_solucao_tecnologica:
            cacheStartup.interesse_cientista_mecanismo_solucao_tecnologica,
          linkedin: cacheStartup.linkedin,
          path_logo: cacheStartup.path_logo,
          path_pitch_pdf: cacheStartup.path_pitch_pdf,
          path_pitch_planilha: cacheStartup.path_pitch_planilha,
          premiacoes: cacheStartup.premiacoes,
          telefone: cacheStartup.telefone,
          website: cacheStartup.website,

          estagio: values.estagio,
          modelo_negocio: values.modelo_negocio,
          segmento_atuacao: values.segmento_atuacao,
          mecanismo_solucao: values.mecanismo_solucao,
          qtde_funcionarios: values.qtde_funcionarios,
          mercado_potencial: values.mercado_potencial,
          faturamento_medio_mensal: values.faturamento_medio_mensal,
          last_funding_round:
            values.funding_round && values.valor_captado
              ? {
                  tipo: values.funding_round,
                  valor_captado: values.valor_captado,
                }
              : undefined,
          hub:
            values.hub && values.status_hub
              ? {
                  hub: {
                    id: values.hub.id,
                    nome: values.hub.nome,
                  },
                  status: values.status_hub,
                }
              : undefined,
          url_video: values.url_video,
          created_in: new Date(),
          habilitado: false,
          recusado: false,
        };

        const cacheToSave: CacheStartup = {
          ...startupToCreate,
          pitchPdfUrl: values.pitch_pdf
            ? {
                nome: values.pitch_pdf.name,
                url: await getUrlFromFile(values.pitch_pdf),
              }
            : undefined,
          pitchPlanilhaUrl: values.pitch_planilha
            ? {
                url: await getUrlFromFile(values.pitch_planilha),
                nome: values.pitch_planilha.name,
              }
            : undefined,
        };

        await handlerCacheStartup.save(cacheToSave);

        if (!values.estagio) {
          console.warn("Deve especificar estágio da startup");
          return;
        }

        const createStartupResult = await handlerCreateStartup.createStartup(
          startupToCreate
        );

        if (createStartupResult.ok) {
          await handlerCacheStartup.save({ id: createStartupResult.value.id });

          handlerConviteStartupEmployeeCreate?.create([
            {
              alvo: {
                cidade: currentUserScyggz.user.cidade,
                id: currentUserScyggz.user.auth_user_id,
                nome: currentUserScyggz.user.nome,
                avatar_path: currentUserScyggz.user.avatar_path?.path,
                headline: currentUserScyggz.user.headline,
              },
              funcao: "admin",
              id: "",
              startup: {
                id: createStartupResult.value.id,
                nome: createStartupResult.value.nome,
                path_logo: createStartupResult.value.path_logo,
              },
              status: "accepted",
            },
          ]);
        } else {
          console.warn("Erro na criação da startup", createStartupResult.error);
          return;
        }

        if (values.pitch_pdf) {
          const handlerSavePitchPdfStartupResult =
            await handlerSavePitchPdfStartup.save({
              file: values.pitch_pdf,
              id_startup: createStartupResult.value.id,
            });

          if (handlerSavePitchPdfStartupResult.ok) {
            handlerCacheStartup.save({
              path_pitch_pdf: handlerSavePitchPdfStartupResult.value.path,
            });
          } else {
            console.warn("Erro em salvar pitch PDF da startup");
          }
        }

        if (values.pitch_planilha) {
          const handlerSavePlanilhaStartupResult =
            await handlerSavePlanilhaStartup.save({
              file: values.pitch_planilha,
              id_startup: createStartupResult.value.id,
            });

          if (handlerSavePlanilhaStartupResult.ok) {
            handlerCacheStartup.save({
              path_pitch_planilha: handlerSavePlanilhaStartupResult.value.path,
            });
          } else {
            console.warn("Erro em salvar pitch planilha da startup");
          }
        }

        onProximo();
      }}
    >
      {({
        isValid,
        isSubmitting,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        handleChange,
        handleBlur,
        errors,
        touched,
        values,
      }) => (
        <form
          onSubmit={handleSubmit}
          data-testid="painel_cadastro_mais_startup"
          className={`${responsive.sm ? "text-center" : "px-5"}`}
        >
          <h3 className="card-title mb-4 text-center">
            <span className="fw-bold">{parteDoNome}</span>
            <span className="h5 fw-bold">
              , por favor fale mais sobre sua Startup.
            </span>
          </h3>

          <InputEstagioStartup
            label="Estágio da sua startup*"
            value={values.estagio}
            onChange={(estagio) => {
              setFieldValue("estagio", estagio);
            }}
            validate={Startup.validateEstagio}
            className="mb-3"
          />

          <div className="mb-4">
            <InputModelosNegocio
              label="Modelo de negócio*"
              value={values.modelo_negocio}
              onChange={(modelo_negocio) => {
                setTimeout(() => {
                  setFieldTouched("modelo_negocio", true);
                }, 0);
                setFieldValue("modelo_negocio", modelo_negocio);
              }}
              validate={Startup.validateModelosNegocio}
            />
          </div>

          <div className="mb-4">
            <InputSegmentosAtuacao
              label="Atua nos seguintes segmentos*"
              value={values.segmento_atuacao}
              onChange={(segmento_atuacao) => {
                setTimeout(() => {
                  setFieldTouched("segmento_atuacao", true);
                }, 0);
                setFieldValue("segmento_atuacao", segmento_atuacao);
              }}
              validate={Startup.validateSegmentoAtuacao}
            />
          </div>

          <div className="mb-4">
            <InputMecanismosSolucao
              label="Mecanismo de solução tecnológica"
              value={values.mecanismo_solucao ?? []}
              onChange={(mecanismo_solucao) => {
                setFieldValue("mecanismo_solucao", mecanismo_solucao);
              }}
              validate={Startup.validateMecanismoSolucao}
            />
          </div>

          <div className="row">
            <InputNumber
              label="Quantidade de funcionários*"
              onChange={(qtde_funcionarios) => {
                setFieldValue("qtde_funcionarios", qtde_funcionarios);
              }}
              onBlur={() => {
                setTimeout(() => {
                  setFieldTouched("qtde_funcionarios", true);
                }, 0);
              }}
              id="iptQtdeFuncionarios"
              min={{
                value: Startup.QTDE_FUNCIONARIOS_MINIMO,
                errorMessage: Startup.QTDE_FUNCIONARIOS_MINIMO_MESSAGE,
              }}
              title="qtde_funcionarios"
              value={values.qtde_funcionarios}
            />

            <div className="mb-3 col-12 col-md-4">
              <label
                htmlFor="iptMercadoPotencial"
                className={
                  "form-label" +
                  (errors.mercado_potencial && touched.mercado_potencial
                    ? " is-invalid"
                    : "")
                }
              >
                Mercado potencial
              </label>

              <div className={`${responsive.sm ? " d-flex" : "input-group"}`}>
                <select
                  className={"form-select"}
                  name="mercado_potencial.moeda"
                  onChange={handleChange}
                  style={
                    responsive.sm
                      ? {
                          border: "none",
                          borderBottom: "3px #666600 solid",
                          display: "flex",
                          background: " #fff",
                          borderBottomLeftRadius: "8px",
                          borderBottomRightRadius: "0px",
                          textAlign: "left",
                          width: "30%",
                        }
                      : { maxWidth: "90px" }
                  }
                  title="Moeda do mercado potencial"
                >
                  <option value={values.mercado_potencial?.moeda}>
                    {values.mercado_potencial?.moeda === "dollar"
                      ? "US$"
                      : "R$"}
                  </option>
                  {values.mercado_potencial?.moeda === "dollar" ? (
                    <option value="real">R$</option>
                  ) : (
                    <option value="dollar">US$</option>
                  )}
                </select>
                <input
                  type="number"
                  style={responsive.sm ? { borderBottomLeftRadius: "0px" } : {}}
                  className={
                    `${responsive.sm ? "imputMobile" : "form-control"}` +
                    (errors.mercado_potencial && touched.mercado_potencial
                      ? " is-invalid"
                      : "")
                  }
                  id="iptMercadoPotencial"
                  name="mercado_potencial.valor"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.mercado_potencial?.valor && 
                    values.mercado_potencial?.valor > 0 ? values.mercado_potencial?.valor : ""}
                />
              </div>

              <div className="invalid-feedback flex-fill">
                {/* {errors.mercado_potencial} */}
                Valor inválido
              </div>
            </div>

            <div className="mb-3 col-12 col-md-4">
              <label
                htmlFor="iptFaturamentoMedio"
                className={
                  "form-label" +
                  (errors.faturamento_medio_mensal &&
                  touched.faturamento_medio_mensal
                    ? " is-invalid"
                    : "")
                }
              >
                Faturamento médio mensal
              </label>

              <div className={`${responsive.sm ? " d-flex" : "input-group"}`}>
                <select
                  className="form-select"
                  name="faturamento_medio_mensal.moeda"
                  onChange={handleChange}
                  style={
                    responsive.sm
                      ? {
                          border: "none",
                          borderBottom: "3px #666600 solid",
                          display: "flex",
                          background: " #fff",
                          borderBottomLeftRadius: "8px",
                          borderBottomRightRadius: "0px",
                          textAlign: "left",
                          width: "30%",
                        }
                      : { maxWidth: "90px" }
                  }
                  title="Moeda do faturamento médio mensal"
                >
                  <option value={values.faturamento_medio_mensal?.moeda}>
                    {values.faturamento_medio_mensal?.moeda === "dollar"
                      ? "US$"
                      : "R$"}
                  </option>
                  {values.faturamento_medio_mensal?.moeda === "dollar" ? (
                    <option value="real">R$</option>
                  ) : (
                    <option value="dollar">US$</option>
                  )}
                </select>
                <input
                  type="number"
                  className={
                    `${responsive.sm ? "imputMobile" : "form-control"}` +
                    (errors.faturamento_medio_mensal &&
                    touched.faturamento_medio_mensal
                      ? " is-invalid"
                      : "")
                  }
                  style={responsive.sm ? { borderBottomLeftRadius: "0px" } : {}}
                  id="iptFaturamentoMedio"
                  name="faturamento_medio_mensal.valor"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.faturamento_medio_mensal?.valor && 
                    values.faturamento_medio_mensal?.valor > 0 ? values.faturamento_medio_mensal?.valor : ""}
                />
              </div>

              <div className="invalid-feedback flex-fill">
                {/* {errors.faturamento_medio_mensal} */}
                Valor inválido
              </div>
            </div>
          </div>

          <div className="mb-3 col-12">
            <label className="form-label" htmlFor="selectFundingRound">
              Última capitação (funding round)
            </label>
            <Field
              component="select"
              className={
                `${responsive.sm ? "imputMobile" : "form-select"}` +
                (errors.funding_round && touched.funding_round
                  ? " is-invalid"
                  : "")
              }
              name="funding_round"
              onChange={(e: any) => {
                handleChange(e);
                setTimeout(() => {
                  setFieldTouched("funding_round", true);
                  setFieldTouched("valor_captado", true);
                }, 0);
              }}
              id="selectFundingRound"
            >
              {!touched.funding_round && <option value=""></option>}
              <option value="Não captei recursos">Não captei recursos</option>
              {FUNDING_ROUNDS.map((f) => (
                <option value={f} key={f}>
                  {f}
                </option>
              ))}
            </Field>
            <div className="invalid-feedback flex-fill">
              {errors.funding_round}
            </div>
          </div>

          <div className="mb-3 col-12">
            <label
              htmlFor="iptValorCaptado"
              className={
                "form-label" +
                (errors.valor_captado && touched.valor_captado
                  ? " is-invalid"
                  : "")
              }
            >
              Valor captado até hoje
            </label>
            <div
              className={`${responsive.sm ? " d-flex" : "input-group"} mb-3`}
            >
              <select
                className="form-select"
                name="valor_captado.moeda"
                onChange={(v) => {
                  handleChange(v);
                  setTimeout(() => setFieldTouched("valor_captado", true), 0);
                }}
                style={
                  responsive.sm
                    ? {
                        border: "none",
                        borderBottom: "3px #666600 solid",
                        display: "flex",
                        background: " #fff",
                        borderBottomLeftRadius: "8px",
                        borderBottomRightRadius: "0px",
                        textAlign: "left",
                        width: "30%",
                      }
                    : { maxWidth: "90px" }
                }
                title="Moeda valor captado"
              >
                <option value={values.valor_captado?.moeda}>
                  {values.valor_captado?.moeda === "dollar" ? "US$" : "R$"}
                </option>
                {values.valor_captado?.moeda === "dollar" ? (
                  <option value="real">R$</option>
                ) : (
                  <option value="dollar">US$</option>
                )}
              </select>
              <input
                type="number"
                className={
                  `${responsive.sm ? "imputMobile" : "form-control"}` +
                  (errors.valor_captado && touched.valor_captado
                    ? " is-invalid"
                    : "")
                }
                style={responsive.sm ? { borderBottomLeftRadius: "0px" } : {}}
                id="iptValorCaptado"
                name="valor_captado.valor"
                onChange={(v) => {
                  handleChange(v);
                  setTimeout(() => setFieldTouched("valor_captado", true), 0);
                }}
                value={values.valor_captado?.valor && 
                  values.valor_captado?.valor > 0 ? values.valor_captado?.valor : ""}
                onBlur={handleBlur}
              />
            </div>
            <div className="invalid-feedback flex-fill">
              {errors.valor_captado}
            </div>
          </div>

          <div
            className={`${responsive.sm && "border rounded-4 py-4 px-1 mb-2"}`}
          >
            <InputHub
              label="Hub de inovação"
              onChange={(hub) => {
                setFieldValue("hub", hub);
              }}
              validate={Startup.validateHub}
              value={values.hub}
              className="mb-3 col-12"
            />

            <InputStatusHub
              label="Hub de Inovação (status)"
              onChange={(status_hub) => {
                setFieldValue("status_hub", status_hub);
                setTimeout(() => setFieldTouched("status_hub", true), 0);
              }}
              validate={(status_hub) => {
                if (values.hub && !status_hub) {
                  return new Error("obrigatório");
                }
              }}
              disabled={!values.hub?.id}
              value={values.status_hub}
              className="mb-3 col-12"
            />
          </div>

          <div
            className={`${responsive.sm && "border rounded-4 py-4 px-1 mb-2"}`}
          >
            <div className="mb-3 col-12">
              <label htmlFor="iptUrlVideo" className="form-label">
                Pitch - link Youtube ou Vímeo
              </label>
              <input
                type="url"
                className={
                  `${responsive.sm ? "imputMobile" : "form-control"}` +
                  (errors.url_video && touched.url_video ? " is-invalid" : "")
                }
                id="iptUrlVideo"
                name="url_video"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.url_video}
              />
              <div className="invalid-feedback flex-fill">
                {errors.url_video}
              </div>
            </div>

            <FileSelect
              id="iptPlanilha"
              label={"Planilha - arquivo (xlsx)"}
              className="mb-3 col-12"
              value={values.pitch_planilha}
              onChange={async (file) => {
                setFieldValue("pitch_planilha", file);
                setFieldTouched("pitch_planilha", true);

                const cache = {
                  pitchPlanilhaUrl: file
                    ? {
                        url: await getUrlFromFile(file),
                        nome: file.name,
                      }
                    : undefined,
                };

                handlerCacheStartup.save(cache);
                setCacheStartup({
                  ...cacheStartup,
                  ...cache,
                });
              }}
              mimeType={
                /application\/(vnd.ms-excel|msexcel|x-msexcel|x-ms-excel|x-excel|x-dos_ms_excel|xls|x-xls|vnd.openxmlformats-officedocument.spreadsheetml.sheet)/i
              }
              accept=".xls, .xlsx"
              onBlur={handleBlur}
              erro={errors.pitch_planilha}
            />

            <FileSelect
              id="iptPdf"
              label={"Pitch - arquivo (pdf)"}
              className="mb-3 col-12"
              value={values.pitch_pdf}
              onChange={async (file) => {
                setFieldValue("pitch_pdf", file);
                setFieldTouched("pitch_pdf", true);

                const cache = {
                  pitchPdfUrl: file
                    ? {
                        url: await getUrlFromFile(file),
                        nome: file.name,
                      }
                    : undefined,
                };

                handlerCacheStartup.save(cache);
                setCacheStartup({
                  ...cacheStartup,
                  ...cache,
                });
              }}
              mimeType={/application\/pdf/i}
              accept=".pdf"
              onBlur={handleBlur}
              erro={errors.pitch_pdf}
            />
          </div>

          <div className="text-end pt-4">
            {!responsive.sm && (
              <ButtonLightGreen
                className="me-3"
                onClick={async () => {
                  const cacheStartupResult = await handlerCacheStartup.save({
                    ...values,
                    hub:
                      values.status_hub && values.hub
                        ? {
                            status: values.status_hub,
                            hub: values.hub,
                          }
                        : undefined,
                    last_funding_round:
                      values.funding_round && values.valor_captado
                        ? {
                            tipo: values.funding_round,
                            valor_captado: values.valor_captado,
                          }
                        : undefined,
                    pitchPlanilhaUrl: values.pitch_planilha
                      ? {
                          url: await getUrlFromFile(values.pitch_planilha),
                          nome: values.pitch_planilha.name,
                        }
                      : undefined,
                    pitchPdfUrl: values.pitch_pdf
                      ? {
                          url: await getUrlFromFile(values.pitch_pdf),
                          nome: values.pitch_pdf.name,
                        }
                      : undefined,
                  });

                  if (cacheStartupResult.ok) onPrevious();
                  else
                    console.warn(
                      "cacheStartupResult",
                      cacheStartupResult.error
                    );
                }}
              >
                VOLTAR
              </ButtonLightGreen>
            )}
            <ButtonDarkGreen
              disabled={!isValid || isSubmitting}
              isSubmit={true}
              className="text-uppercase"
            >
              Avançar
            </ButtonDarkGreen>
          </div>
        </form>
      )}
    </Formik>
  );
}

export type FormikFields = {
  modelo_negocio: ModeloNegocioOutput[];
  segmento_atuacao: SegmentoAtuacaoOutput[];
  qtde_funcionarios: number;
} & Partial<{
  estagio: EstagioStartup;

  funding_round: FundingRound;
  mecanismo_solucao: MecanismoDeSolucaoTecnologicaOutput[];
  faturamento_medio_mensal: OutputValorFinanceiro;
  mercado_potencial: OutputValorFinanceiro;
  pitch_pdf: File;
  pitch_planilha: File;
  url_video: string;
  valor_captado: OutputValorFinanceiro;
  hub: OutputHub;
  status_hub: StatusStartupHub;
}>;

export type OutputPainelCadastroMaisSobreStartup = OutputStartup;

const validator = Yup.object().shape({
  modelo_negocio: Yup.array()
    .required("Modelo de negócio obrigatório.")
    .min(1, "Campo obrigatório"),
  segmento_atuacao: Yup.array()
    .required("Segmento de atuação obrigatório.")
    .min(1, "Campo obrigatório"),
  estagio: Yup.string().required("Campo obrigatório"),
  qtde_funcionarios: Yup.number()
    .required("Campo obrigatório")
    .min(1, "Deve ter no mínimo um funcionário"),

  mecanismo_solucao: Yup.array(),
  faturamento_medio_mensal: Yup.object().shape({
    moeda: Yup.string().required(),
    valor: Yup.number().required().min(0),
  }),
  mercado_potencial: Yup.object().shape({
    moeda: Yup.string().required(),
    valor: Yup.number().required().min(0),
  }),
  url_video: Yup.string().url("Deve ser um link"),
  pitch_pdf: Yup.mixed(),
  pitch_planilha: Yup.mixed(),
  funding_round: Yup.string().test(
    "funding_round",
    "Campo obrigatório pois houve captação",
    (funding_round, context) => {
      const valor_captado = context.parent.valor_captado;
      const isValorCaptadoSetado =
        valor_captado.moeda && valor_captado.valor > 0;

      const isFundingRoundSetado = FUNDING_ROUNDS.includes(
        funding_round as FundingRound
      );

      return (
        isValorCaptadoSetado == isFundingRoundSetado || isFundingRoundSetado
      );
    }
  ),
  valor_captado: Yup.object().test(
    "valor_captado",
    "Campo obrigatório pois houve funding round",
    (valor_captado, context) => {
      const funding_round = context.parent.funding_round;
      const isValorCaptadoSetado =
        valor_captado.moeda && valor_captado.valor > 0;

      const isFundingRoundSetado = FUNDING_ROUNDS.includes(
        funding_round as FundingRound
      );

      return (
        isValorCaptadoSetado == isFundingRoundSetado || !isFundingRoundSetado
      );
    }
  ),
  hub: Yup.object()
    .shape({
      id: Yup.string(),
      nome: Yup.string(),
    })
    .nullable(),
  status_hub: Yup.string().when("hub", {
    is: (outputHub: OutputHub | undefined | Partial<OutputHub>) => {
      const isHubSetado: boolean = (outputHub?.id?.length ?? 0) > 0;
      return isHubSetado;
    },
    then: (schema) => schema.required(),
  }),
});
