import {
  Firestore,
  collection,
  where,
  query,
  Timestamp,
  doc,
  onSnapshot,
} from "@firebase/firestore";
import { Observable } from "rxjs";

import { Result } from "../typings";
import { OutputStartup } from "../domain/entities/Startup";
import { HandlerGetStartupRealtime } from "../domain/usecases/interfaces/HandlerGetStartupRealtime";

export class HandlerGetStartupRealtimeFirebase
  implements HandlerGetStartupRealtime
{
  constructor(private firestore: Firestore) {}

  getAllEnabledStartups(): Observable<Result<OutputStartup[]>> {
    return new Observable((sub) => {
      const queryEnabledStartups = query(
        collection(this.firestore, "startup"),
        where("habilitado", "==", true)
      );

      onSnapshot(
        queryEnabledStartups,
        (querySnapshot) => {
          const startups: OutputStartup[] = querySnapshot.docs.map(
            (docSnapshot) => {
              const startup = docSnapshot.data() as OutputStartup;
              startup.id = docSnapshot.id;
              startup.created_in = (
                startup.created_in as unknown as Timestamp
              ).toDate();
              return startup;
            }
          );

          sub.next({
            ok: true,
            value: startups,
          });
        },
        () =>
          sub.next({
            ok: true,
            value: [],
          })
      );
    });
  }

  getStartup(id: string): Observable<Result<OutputStartup>> {
    return new Observable((sub) => {
      const docStartup = doc(this.firestore, "startup", id);

      onSnapshot(
        docStartup,
        (docSnapshot) => {
          const startup = docSnapshot.data() as OutputStartup;
          startup.id = docSnapshot.id;
          startup.created_in = (
            startup.created_in as unknown as Timestamp
          ).toDate();

          sub.next({
            ok: true,
            value: startup,
          });
        },
        (error) =>
          sub.next({
            ok: false,
            error,
          })
      );
    });
  }

  getStartupsGestor(id_gerente: string): Observable<Result<OutputStartup[]>> {
    return new Observable((sub) => {
      const queryStartupsGestor = query(
        collection(this.firestore, "startup"),
        where("gestores", "array-contains", id_gerente)
      );

      onSnapshot(
        queryStartupsGestor,
        (querySnapshot) => {
          const startups: OutputStartup[] = querySnapshot.docs.map(
            (docSnapshot) => {
              const startup = docSnapshot.data() as OutputStartup;
              startup.id = docSnapshot.id;
              startup.created_in = (
                startup.created_in as unknown as Timestamp
              ).toDate();
              return startup;
            }
          );

          sub.next({
            ok: true,
            value: startups,
          });
        },
        () =>
          sub.next({
            ok: true,
            value: [],
          })
      );
    });
  }
}
