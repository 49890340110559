import { ReactNode, useContext, useMemo } from "react";

import { FirebaseContext } from "../FirebaseContext";
import { HandlersDatabaseContext } from "./HandlersDatabaseContext";
import { HandlerGetModeloNegocio } from "../../domain/usecases/interfaces/handlerGetModeloNegocio";
import { HandlerGetModeloNegocioFirebase } from "../../gateway/handlerGetModeloNegocioFirebase";
import { HandlerGetSegmentoAtuacao } from "../../domain/usecases/interfaces/handlerGetSegmentoAtuacao";
import { HandlerGetSegmentoAtuacaoFirebase } from "../../gateway/handlerGetSegmentoAtuacaoFirebase";
import { HandlerGetMecanismoSolucao } from "../../domain/usecases/interfaces/handlerGetMecanismoSolucao";
import { HandlerGetMecanismoSolucaoFirebase } from "../../gateway/handlerGetMecanismoSolucaoFirebase";
import { HandlerGetAreaConhecimento } from "../../domain/usecases/interfaces/handlerGetAreaConhecimento";
import { HandlerGetAreaConhecimentoFirebase } from "../../gateway/HandlerGetAreaConhecimentoFirebase";
import { HandlerGetUnidade } from "../../domain/usecases/interfaces/handlerGetUnidade";
import { HandlerGetUnidadeFirebase } from "../../gateway/handlerGetUnidadeFirebase";
import { HandlerUpdateUsuarioScyggzFirebase } from "../../gateway/handlerUpdateUsuarioScyggzFirebase";
import { HandlerGetAreaAplicacaoTecnologia } from "../../domain/usecases/interfaces/handlerGetAreaAplicacaoTecnologia";
import { HandlerGetAreaAplicacaoTecnologiaFirebase } from "../../gateway/HandlerGetAreaAplicacaoTecnologiaFirebase";
import { HandlerCreateCientista } from "../../domain/usecases/interfaces/handlerCreateCientista";
import { HandlerCreateCientistaFirebase } from "../../gateway/HandlerCreateCientistaFirebase";
import { HandlerGetTipoGrupoPesquisa } from "../../domain/usecases/interfaces/handlerGetTipoGrupoPesquisa";
import { HandlerGetTipoGrupoPesquisaFirebase } from "../../gateway/handlerGetTipoGrupoPesquisaFirebase";
import { HandlerCreateMentor } from "../../domain/usecases/interfaces/handlerCreateMentor";
import { HandlerCreateMentorFirebase } from "../../gateway/HandlerCreateMentorFirebase";
import { HandlerGetAreaEspecializacao } from "../../domain/usecases/interfaces/handlerGetAreaEspecializacao";
import { HandlerGetAreaEspecializacaoFirebase } from "../../gateway/HandlerGetAreaEspecializacaoFirebase";
import { HandlerAuth } from "../../domain/usecases/interfaces/HandlerAuth";
import { HandlerAuthFirebase } from "../../gateway/HandlerAuthFirebase";
import { HandlerCreateAuthWithNameFirebase } from "../../gateway/HandlerCreateAuthWithNameFirebase";
import { HandlerGetAssociacaoAnjo } from "../../domain/usecases/interfaces/handlerGetAssociacaoAnjo";
import { HandlerGetAssociacaoAnjoFirebase } from "../../gateway/HandlerGetAssociacaoAnjoFirebase";
import { HandlerCreateInvestidor } from "../../domain/usecases/interfaces/HandlerCreateInvestidor";
import { HandlerCreateInvestidorFirebase } from "../../gateway/HandlerCreateInvestidorFirebase";
import { HandlerGetUsuarioFirebase } from "../../gateway/HandlerGetUsuarioFirebase";
import { HandlerManagerPermissionFirebase } from "../../gateway/HandlerManagerPermissionFirebase";
import { HandlerContatoEmailSendFirebase } from "../../gateway/firebase/HandlerContatoEmailSendFirebase";
import { HandlerConviteProgramaConexaoFirestore } from "../../gateway/HandlerConviteProgramaConexaoFirestore";
import { HandlerProgramaConexaoFirestore } from "../../gateway/HandlerProgramaConexaoFirestore";
import { HandlerPerfisAlgolia } from "../../gateway/HandlerPerfisAlgolia";
import { HandlerGetEmpresaFirebase } from "../../gateway/handlerGetEmpresaFirebase";
import { HandlerGetStartupFirebase } from "../../gateway/handlerGetStartupFirebase";
import { HandlerGetVentureCapitalFirebase } from "../../gateway/handlerGetVentureCapitalFirebase";
import { HandlerGetNitFirebase } from "../../gateway/handlerGetNitFirebase";
import { HandlerGetHubFirebase } from "../../gateway/handlerGetHubFirebase";
import { HandlerComentariosProgramaConexaoFirestore } from "../../gateway/HandlerComentariosProgramaConexaoFirestore";
import { HandlerConexaoFirebase } from "../../gateway/HandlerConexaoFirebase";
import { HandlerGetMentorFirebase } from "../../gateway/getPerfil/HandlerGetMentorFirebase";
import { HandlerGetCientistaFirebase } from "../../gateway/getPerfil/HandlerGetCientistaFirebase";
import { HandlerGetInvestidorAnjoFirebase } from "../../gateway/getPerfil/handlerGetInvestidorAnjoFirebase";
import { HandlerAceiteStorage } from "../../gateway/HandlerAceiteStorage";
import { HandlerCreateUsuarioScyggzFirebase } from "../../gateway/handlerCreateUsuarioScyggzFirebase";
import { HandlerSaveUrlLinkedinFirebase } from "../../gateway/handlerSaveUrlLinkedinFirebase";
import { HandlerSaveTelefoneFirebase } from "../../gateway/handlerSaveTelefoneFirebase";
import { HandlerUpdateStartupFirebase } from "../../gateway/HandlerUpdateStartupFirebase";
import { HandlerConviteStartupEmployeeFirestore } from "../../gateway/HandlerConviteStartupEmployeeFirestore";

export function HandlersFirebaseProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { firestore, auth } = useContext(FirebaseContext);

  const handlerGetModeloNegocio = useMemo<HandlerGetModeloNegocio>(
    () => new HandlerGetModeloNegocioFirebase(firestore),
    [firestore]
  );

  const handlerGetSegmentoAtuacao = useMemo<HandlerGetSegmentoAtuacao>(
    () => new HandlerGetSegmentoAtuacaoFirebase(firestore),
    [firestore]
  );

  const handlerGetMecanismoSolucao = useMemo<HandlerGetMecanismoSolucao>(
    () => new HandlerGetMecanismoSolucaoFirebase(firestore),
    [firestore]
  );

  const handlerGetAreaConhecimento = useMemo<HandlerGetAreaConhecimento>(
    () => new HandlerGetAreaConhecimentoFirebase(firestore),
    [firestore]
  );

  const handlerGetUnidade = useMemo<HandlerGetUnidade>(
    () => new HandlerGetUnidadeFirebase(firestore),
    [firestore]
  );

  const handlerUpdateUsuarioScyggz =
    useMemo<HandlerUpdateUsuarioScyggzFirebase>(
      () => new HandlerUpdateUsuarioScyggzFirebase(firestore),
      [firestore]
    );

  const handlerUpdateStartupFirebase = useMemo<HandlerUpdateStartupFirebase>(
    () => new HandlerUpdateStartupFirebase(firestore),
    [firestore]
  );

  const handlerGetAreaAplicacaoTecnologia =
    useMemo<HandlerGetAreaAplicacaoTecnologia>(
      () => new HandlerGetAreaAplicacaoTecnologiaFirebase(firestore),
      [firestore]
    );

  const handlerCreateCientista = useMemo<HandlerCreateCientista>(
    () => new HandlerCreateCientistaFirebase(firestore),
    [firestore]
  );

  const handlerCreateMentor = useMemo<HandlerCreateMentor>(
    () => new HandlerCreateMentorFirebase(firestore),
    [firestore]
  );

  const handlerCreateInvestidor = useMemo<HandlerCreateInvestidor>(
    () => new HandlerCreateInvestidorFirebase(firestore),
    [firestore]
  );

  const handlerGetTipoGrupoPesquisa = useMemo<HandlerGetTipoGrupoPesquisa>(
    () => new HandlerGetTipoGrupoPesquisaFirebase(firestore),
    [firestore]
  );

  const handlerGetAreaEspecializacao = useMemo<HandlerGetAreaEspecializacao>(
    () => new HandlerGetAreaEspecializacaoFirebase(firestore),
    [firestore]
  );

  const handlerGetAssociacaoAnjo = useMemo<HandlerGetAssociacaoAnjo>(
    () => new HandlerGetAssociacaoAnjoFirebase(firestore),
    [firestore]
  );

  const handlerGetUsuario = useMemo<HandlerGetUsuarioFirebase>(
    () => new HandlerGetUsuarioFirebase(firestore),
    [firestore]
  );

  const handlerAuth = useMemo<HandlerAuth>(
    () => new HandlerAuthFirebase(auth),
    [auth]
  );

  const handlerManagerPermissionFirebase =
    useMemo<HandlerManagerPermissionFirebase>(
      () => new HandlerManagerPermissionFirebase(firestore),
      [firestore]
    );

  const handlerContatoEmailSend = useMemo<HandlerContatoEmailSendFirebase>(
    () => new HandlerContatoEmailSendFirebase(firestore),
    [firestore]
  );

  const handlerConviteProgramaConexaoFirestore =
    useMemo<HandlerConviteProgramaConexaoFirestore>(
      () => new HandlerConviteProgramaConexaoFirestore(firestore),
      [firestore]
    );

  const handlerProgramaConexao = useMemo<HandlerProgramaConexaoFirestore>(
    () => new HandlerProgramaConexaoFirestore(firestore),
    [firestore]
  );

  const handlerConviteStartupEmployee =
    useMemo<HandlerConviteStartupEmployeeFirestore>(
      () => new HandlerConviteStartupEmployeeFirestore(firestore),
      [firestore]
    );

  const handlerPerfisGetEnabled = useMemo<HandlerPerfisAlgolia>(
    () => new HandlerPerfisAlgolia(),
    []
  );

  const handlerGetEmpresa = useMemo<HandlerGetEmpresaFirebase>(
    () => new HandlerGetEmpresaFirebase(firestore),
    [firestore]
  );

  const handlerGetStartup = useMemo<HandlerGetStartupFirebase>(
    () => new HandlerGetStartupFirebase(firestore),
    [firestore]
  );

  const handlerUpdateStartup = useMemo<HandlerUpdateStartupFirebase>(
    () => new HandlerUpdateStartupFirebase(firestore),
    [firestore]
  );

  const handlerGetVentureCapital = useMemo<HandlerGetVentureCapitalFirebase>(
    () => new HandlerGetVentureCapitalFirebase(firestore),
    [firestore]
  );

  const handlerGetNit = useMemo<HandlerGetNitFirebase>(
    () => new HandlerGetNitFirebase(firestore),
    [firestore]
  );

  const handlerGetHub = useMemo<HandlerGetHubFirebase>(
    () => new HandlerGetHubFirebase(firestore),
    [firestore]
  );

  const handlerComentarioProgramaConexao =
    useMemo<HandlerComentariosProgramaConexaoFirestore>(
      () => new HandlerComentariosProgramaConexaoFirestore(firestore),
      [firestore]
    );

  const handlerConexao = useMemo<HandlerConexaoFirebase>(
    () => new HandlerConexaoFirebase(firestore),
    [firestore]
  );

  const handlerGetMentor = useMemo<HandlerGetMentorFirebase>(
    () => new HandlerGetMentorFirebase(firestore),
    [firestore]
  );

  const handlerGetCientista = useMemo<HandlerGetCientistaFirebase>(
    () => new HandlerGetCientistaFirebase(firestore),
    [firestore]
  );

  const handlerGetInvestidorAnjo = useMemo<HandlerGetInvestidorAnjoFirebase>(
    () => new HandlerGetInvestidorAnjoFirebase(firestore),
    [firestore]
  );

  const handlerAceiteStorage = useMemo<HandlerAceiteStorage>(
    () => new HandlerAceiteStorage(),
    []
  );

  const handlerCreateAuthWithName = useMemo<HandlerCreateAuthWithNameFirebase>(
    () => new HandlerCreateAuthWithNameFirebase(auth),
    [auth]
  );

  const handlerUsuarioScyggz = useMemo<HandlerCreateUsuarioScyggzFirebase>(
    () => new HandlerCreateUsuarioScyggzFirebase(firestore),
    [firestore]
  );

  const handlerLinkedin = useMemo<HandlerSaveUrlLinkedinFirebase>(
    () => new HandlerSaveUrlLinkedinFirebase(firestore),
    [firestore]
  );

  const handlerTelefone = useMemo<HandlerSaveTelefoneFirebase>(
    () => new HandlerSaveTelefoneFirebase(firestore),
    [firestore]
  );

  return (
    <HandlersDatabaseContext.Provider
      value={{
        handlerCreateCientista,
        handlerCreateMentor,
        handlerCreateInvestidor,
        handlerGetModeloNegocio,
        handlerGetSegmentoAtuacao,
        handlerGetMecanismoSolucao,
        handlerGetAreaConhecimento,
        handlerGetUnidade,
        handlerUpdateUsuarioScyggz,
        handlerGetAreaAplicacaoTecnologia,
        handlerGetTipoGrupoPesquisa,
        handlerGetAreaEspecializacao,
        handlerAuth,
        handlerGetAssociacaoAnjo,
        handlerGetUsuario,
        handlerGetUsersWaitingAprovement: handlerGetUsuario,
        handlerGetPermissionsOfUser: handlerManagerPermissionFirebase,
        handlerSetUsersEnabled: handlerUpdateUsuarioScyggz,
        handlerSetStartupEnabled: handlerUpdateStartupFirebase,
        handlerContatoEmailSend,
        handlerConviteProgramaConexaoCreate:
          handlerConviteProgramaConexaoFirestore,
        handlerConviteProgramaConexaoGetByAlvo:
          handlerConviteProgramaConexaoFirestore,
        handlerConviteProgramaConexaoAccept:
          handlerConviteProgramaConexaoFirestore,
        handlerConviteProgramaConexaoReject:
          handlerConviteProgramaConexaoFirestore,
        handlerConviteProgramaConexaoGetAllAccepted:
          handlerConviteProgramaConexaoFirestore,
        handlerConviteProgramaConexaoGetAll:
          handlerConviteProgramaConexaoFirestore,
        handlerConviteProgramaConexaoDelete:
          handlerConviteProgramaConexaoFirestore,
        handlerProgramaConexaoCreate: handlerProgramaConexao,
        handlerProgramaConexaoGetAll: handlerProgramaConexao,
        handlerProgramaConexaoGetAllByGestor: handlerProgramaConexao,
        handlerProgramaConexaoUpdate: handlerProgramaConexao,
        handlerProgramaConexaoGetByIds: handlerProgramaConexao,
        handlerProgramaConexaoDelete: handlerProgramaConexao,
        handlerConviteStartupEmployeeCreate: handlerConviteStartupEmployee,
        handlerConviteStartupEmployeeGetByUserAlvo:
          handlerConviteStartupEmployee,
        handlerConviteStartupEmployeeAccept: handlerConviteStartupEmployee,
        handlerConviteStartupEmployeeReject: handlerConviteStartupEmployee,
        handlerConviteStartupEmployeeGetAllAccepted:
          handlerConviteStartupEmployee,
        handlerConviteStartupEmployeeGetAll: handlerConviteStartupEmployee,
        handlerConviteStartupEmployeeGetAllNotRejected:
          handlerConviteStartupEmployee,
        handlerConviteStartupEmployeeDelete: handlerConviteStartupEmployee,
        handlerPerfisGetEnabled,
        handlerGetEmpresa,
        handlerGetStartup,
        handlerGetStartupWaitingAprovement: handlerGetStartup,
        handlerUpdateStartup,
        handlerGetVentureCapital,
        handlerGetNit,
        handlerGetHub,
        handlerComentarioProgramaConexaoGetAllMural:
          handlerComentarioProgramaConexao,
        handlerComentarioProgramaConexaoGetResponses:
          handlerComentarioProgramaConexao,
        handlerComentarioProgramaConexaoCreateInMural:
          handlerComentarioProgramaConexao,
        handlerComentarioProgramaConexaoCreateResponse:
          handlerComentarioProgramaConexao,
        handlerComentarioProgramaConexaoDelete:
          handlerComentarioProgramaConexao,
        handlerComentarioProgramaConexaoUpdate:
          handlerComentarioProgramaConexao,
        handlerComentarioProgramaConexaoAddLike:
          handlerComentarioProgramaConexao,
        handlerComentarioProgramaConexaoRemoveLike:
          handlerComentarioProgramaConexao,
        handlerConexaoCreate: handlerConexao,
        handlerConexaoGetAllOfUserRealtime: handlerConexao,
        handlerConexaoGetAllOfProgramaConexaoRealtime: handlerConexao,
        handlerGetMentor,
        handlerGetCientista,
        handlerGetInvestidorAnjo,
        handlerAceiteSet: handlerAceiteStorage,
        handlerAceiteGet: handlerAceiteStorage,
        handlerAceiteClear: handlerAceiteStorage,
        handlerCreateAuthWithName,
        handlerCreateUsuarioScyggz: handlerUsuarioScyggz,
        handlerSaveUrlLinkedin: handlerLinkedin,
        handlerSaveTelefone: handlerTelefone,
        handlerConexaoAprovadasGet: handlerConexao,
        handlerConexaoAprovadasCount: handlerConexao,
        handlerConexaoPendentesEnviadasCount: handlerConexao,
        handlerConexaoPendentesEnviadasGet: handlerConexao,
        handlerConexaoPendentesRecebidasCount: handlerConexao,
        handlerConexaoPendentesRecebidasGet: handlerConexao,
        handlerConexaoEntreUsuariosGet: handlerConexao,
        handlerConexaoPendentesRecebidasGetRealtime: handlerConexao,
        handlerConexaoStatusCountRealtime: handlerConexao,
      }}
      children={children}
    />
  );
}
