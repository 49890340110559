import { Result } from "../../typings";

export class GrupoDePesquisa {
  public id = "";
  private constructor(
    public tipo: {
      id: string;
      nome: string;
      aprovado: boolean;
      autor?: string;
    },
    public nome: string,
    public aprovado: boolean,
    public autor?: string
  ) {}

  public static create({
    tipo,
    nome,
    aprovado,
  }: GrupoDePesquisaInput): Result<GrupoDePesquisa> {
    const isTipoInvalid = tipo.id.length < 1;
    if (isTipoInvalid) {
      return {
        ok: false,
        error: new Error("Especifique o tipo de grupo de pesquisa."),
      };
    }

    nome = nome.trim();
    if (nome.length < 3) {
      return {
        ok: false,
        error: new Error("Nome inválido"),
      };
    }

    return {
      ok: true,
      value: new GrupoDePesquisa(tipo, nome, aprovado),
    };
  }

  public static validate(
    grupo: GrupoDePesquisaInput
  ): Result<
    GrupoDePesquisaInput,
    { [P in keyof Partial<GrupoDePesquisaInput>]: Error }
  > {
    let {
      tipo: { id: idTipo, nome: nomeTipo },
      id: idGrupo,
      nome: nomeGrupo,
    } = grupo;

    let erroTipoId = "";
    idTipo = idTipo.trim();
    if (idTipo.length < 1) {
      erroTipoId = "ID de tipo obrigatório";
    }

    let erroTipoNome = "";
    nomeTipo = nomeTipo.trim();
    if (nomeTipo.length < 1) {
      erroTipoNome = "Nome tipo obrigatório";
    }

    let erroGrupoId = "";
    idGrupo = idGrupo.trim();
    if (idGrupo.length < 1) {
      erroGrupoId = "ID de grupo obrigatório";
    }

    let erroGrupoNome = "";
    nomeGrupo = nomeGrupo.trim();
    if (nomeGrupo.length < 1) {
      erroGrupoNome = "Nome grupo obrigatório";
    }

    if (erroTipoId || erroTipoNome || erroGrupoId || erroGrupoNome) {
      return {
        ok: false,
        error: {
          id: erroGrupoId ? new Error(erroGrupoId) : undefined,
          nome: erroGrupoNome ? new Error(erroGrupoNome) : undefined,
          tipo:
            erroTipoId || erroTipoNome
              ? new Error(erroTipoNome ?? erroTipoId)
              : undefined,
        },
      };
    } else {
      return {
        ok: true,
        value: grupo,
      };
    }
  }
}

export type SomenteGrupoDePesquisaInput = {
  id: string;
  nome: string;
  aprovado: boolean;
  autor?: string;
};

export type GrupoDePesquisaInput = SomenteGrupoDePesquisaInput & {
  tipo: {
    id: string;
    nome: string;
    aprovado: boolean;
    autor?: string;
  };
};

export type GrupoDePesquisaOutput = GrupoDePesquisaInput;
