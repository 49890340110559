import { ReactNode, useContext, useEffect, useState } from "react";

import { OutputNit } from "../../domain/entities/Nit";
import { HandlerGetNitFirebase } from "../../gateway/handlerGetNitFirebase";
import { FirebaseContext } from "../FirebaseContext";
import { PerfilNitContext, TypePerfilNitContext } from "./PerfilNitContext";

export function PerfilNitFirebaseProvider({
  nit,
  children,
}: {
  nit: Pick<OutputNit, "id">;
  children: ReactNode;
}) {
  const [perfilNitResult, setPerfilNitResultResult] =
    useState<TypePerfilNitContext>();

  const { firestore } = useContext(FirebaseContext);

  useEffect(() => {
    new HandlerGetNitFirebase(firestore).getNit(nit.id).then((result) => {
      setPerfilNitResultResult(
        result.ok
          ? {
              ok: true,
              value: result.value,
            }
          : result
      );
    });
  }, [nit, firestore]);

  return (
    <PerfilNitContext.Provider
      value={{
        perfilNitResult,
      }}
      children={children}
    />
  );
}
