import { UserAuthRealtime } from "../domain/usecases/autenticacaoService";
import { UserInfoRealtime } from "../domain/usecases/getUserInfoObserver";
import { UserPhoneNumberRealtime } from "../domain/usecases/getUserPhoneNumberObserver";

export function userStatusHandler({
  userAuthRealtime,
  userInfoRealtime,
  userPhoneNumberRealtime,
  currentUserStatus,
}: {
  userAuthRealtime: UserAuthRealtime;
  userInfoRealtime: UserInfoRealtime;
  userPhoneNumberRealtime: UserPhoneNumberRealtime;
  currentUserStatus: UserStatus;
}): UserStatus {
  const isAuthCarregando: boolean = userAuthRealtime.status === "carregando";
  const isAuthCarregado: boolean = userAuthRealtime.status === "carregado";
  const isAuthVisitante: boolean = userAuthRealtime.user_auth === null;
  if (isAuthCarregando) return "carregando_auth";

  if (isAuthCarregado) {
    if (isAuthVisitante) return "visitante";

    const isUserScyggzCarregando: boolean =
      userInfoRealtime.status === "carregando";
    const isUserScyggzNaoEncontrado: boolean =
      userInfoRealtime.status === "nao_encontrado";
    // const isUserScyggzEncontrado: boolean =
    //   userInfoRealtime.status === "encontrado";

    if (isUserScyggzCarregando) return "carregando_user_scyggz";
    if (isUserScyggzNaoEncontrado) return "usuario_com_info_pendente";

    const isUserPhoneNumberCarregando: boolean =
      userPhoneNumberRealtime.status === "carregando";
    // const isUserPhoneNumberEncontrado: boolean =
    //   userPhoneNumberRealtime.status === "encontrado";
    const isUserPhoneNumberNaoEncontrado: boolean =
      userPhoneNumberRealtime.status === "nao_encontrado";

    if (isUserPhoneNumberCarregando) return "carregando_user_phone_number";
    if (isUserPhoneNumberNaoEncontrado) return "usuario_com_telefone_pendente";

    if ((userInfoRealtime.user?.nome.length ?? 0) < 1)
      return "usuario_com_info_pendente";

    if (Object.keys(userInfoRealtime.user?.perfis ?? []).length == 0)
      return "usuario_sem_perfil";

    if (userAuthRealtime.user_auth?.emailVerified ?? false) return "usuario_ok";

    return "email_nao_verificado";
  }
  return currentUserStatus;
}

export type UserStatus =
  | "carregando_auth"
  | "carregando_user_scyggz"
  | "carregando_user_phone_number"
  | "visitante"
  | "usuario_com_telefone_pendente"
  | "usuario_com_info_pendente"
  | "email_nao_verificado"
  | "usuario_sem_perfil"
  | "usuario_ok";
