import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { faExclamationCircle, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, ProgressBar } from "react-bootstrap";

import { HandlersDatabaseContext } from "../../../../../globalStore/HandlersProviders/HandlersDatabaseContext";
import { ProgramaConexao } from "../../../../../domain/entities/ProgramaConexao";
import { CurrentUserContext } from "../../../../../globalStore/CurrentUserContext";
import { Result } from "../../../../../typings";
import { MyProgram } from "./MyProgram";

export function ListMyPrograms({ className = "" }: { className?: string }) {
  const { currentUserAuth } = useContext(CurrentUserContext);
  const {
    handlerProgramaConexaoGetAllByGestor,
    handlerConviteProgramaConexaoGetByAlvo,
    handlerProgramaConexaoDelete,
  } = useContext(HandlersDatabaseContext);

  const [programaToDelete, setProgramaToDelete] =
    useState<Pick<ProgramaConexao, "id" | "nome">>();
  const [typeProgramsSelected, setTypeProgramsSelected] = useState<
    "joined" | "created"
  >("created");
  const [programsCreatedResult, setProgramasCreatedResult] =
    useState<
      Result<
        Pick<
          ProgramaConexao,
          | "id"
          | "nome"
          | "logo_path"
          | "stakeholder_type_group_a"
          | "stakeholder_type_group_b"
          | "message_to_a"
          | "message_to_b"
        >[]
      >
    >();
  const [programsJoinedResult, setProgramasJoinedResult] =
    useState<
      Result<
        Pick<
          ProgramaConexao,
          | "id"
          | "nome"
          | "logo_path"
          | "stakeholder_type_group_a"
          | "stakeholder_type_group_b"
          | "message_to_a"
          | "message_to_b"
        >[]
      >
    >();

  const refreshProgramsCreated = useCallback(() => {
    if (currentUserAuth.user_auth?.auth_id) {
      handlerProgramaConexaoGetAllByGestor
        ?.getAllByGestor(currentUserAuth.user_auth.auth_id)
        .then((programsCreatedResult) =>
          setProgramasCreatedResult(programsCreatedResult)
        );
    }
  }, [
    currentUserAuth.user_auth?.auth_id,
    handlerProgramaConexaoGetAllByGestor,
  ]);
  const refreshProgramsJoined = useCallback(() => {
    if (currentUserAuth.user_auth?.auth_id) {
      handlerConviteProgramaConexaoGetByAlvo
        ?.getByUserAlvo(currentUserAuth.user_auth.auth_id)
        .then((programsJoinedResult) => {
          if (programsJoinedResult.ok) {
            setProgramasJoinedResult({
              ok: true,
              value: programsJoinedResult.value
                .filter((convite) => convite.status == "accepted")
                .map((convite) => convite.programa_conexao),
            });
          } else {
            setProgramasJoinedResult(programsJoinedResult);
            console.warn(
              "erro refreshProgramsJoined",
              programsJoinedResult.error
            );
          }
        });
    }
  }, [
    currentUserAuth.user_auth?.auth_id,
    handlerConviteProgramaConexaoGetByAlvo,
  ]);

  const programsToShow = useMemo<
    | Result<
        Pick<
          ProgramaConexao,
          | "id"
          | "nome"
          | "logo_path"
          | "stakeholder_type_group_a"
          | "stakeholder_type_group_b"
          | "message_to_a"
          | "message_to_b"
        >[]
      >
    | undefined
  >(() => {
    switch (typeProgramsSelected) {
      case "created":
        return programsCreatedResult;
      case "joined":
        return programsJoinedResult;
    }

    return {
      ok: true,
      value: [],
    };
  }, [programsCreatedResult, programsJoinedResult, typeProgramsSelected]);

  useEffect(() => {
    switch (typeProgramsSelected) {
      case "created":
        refreshProgramsCreated();
        break;
      case "joined":
        refreshProgramsJoined();
        break;
    }
  }, [typeProgramsSelected, refreshProgramsCreated, refreshProgramsJoined]);

  return (
    <>
      <div className={"card shadow-sm px-4 " + className}>
        <div className="card-body gap-3">
          <h5 className="pb-2 fw-bolder">
            <FontAwesomeIcon icon={faUser} className="me-2" />
            Meus programas de conexão
          </h5>

          <div className="btn-group mb-3" role="group">
            <button
              type="button"
              className={
                "btn " +
                (typeProgramsSelected == "created"
                  ? "background-spinoff text-white"
                  : " btn-light")
              }
              onClick={() => setTypeProgramsSelected("created")}
            >
              Criados
            </button>
            <button
              type="button"
              className={
                "btn " +
                (typeProgramsSelected == "joined"
                  ? "background-spinoff"
                  : " btn-light")
              }
              onClick={() => setTypeProgramsSelected("joined")}
            >
              Participo
            </button>
          </div>

          <>
            {programsToShow ? (
              <>
                {programsToShow.ok ? (
                  <>
                    {programsToShow.value.map((programa, index) => (
                      <MyProgram
                        key={programa.id}
                        programa={programa}
                        onDeleteClick={setProgramaToDelete}
                        className={index == 0 ? "" : "mt-3"}
                      />
                    ))}
                  </>
                ) : (
                  <>erro</>
                )}
              </>
            ) : (
              <ProgressBar
                animated
                variant="info"
                now={100}
                className="w-100"
              />
            )}
          </>
        </div>
      </div>

      <Modal
        show={!!programaToDelete}
        onHide={() => setProgramaToDelete(undefined)}
        centered
        scrollable={true}
      >
        <Modal.Body>
          <div className="d-flex gap-3">
            <FontAwesomeIcon
              icon={faExclamationCircle}
              className="text-danger m-1"
              size="4x"
            />
            <div>
              <h6 className="fw-bolder">
                Confirmar exclusão de programa de conexão?
              </h6>
              <p>Excluir programa de conexão "{programaToDelete?.nome}"</p>
            </div>
          </div>

          <div className="container overflow-hidden text-center">
            <div className="row gx-1">
              <div className="col">
                <button
                  className="btn btn-danger w-100"
                  onClick={async () => {
                    if (programaToDelete) {
                      const deleteResult =
                        await handlerProgramaConexaoDelete?.delete(
                          programaToDelete
                        );

                      if (deleteResult?.ok) {
                        setProgramaToDelete(undefined);
                        refreshProgramsCreated();
                      }
                    }
                  }}
                >
                  Excluir
                </button>
              </div>
              <div className="col">
                <button
                  className="btn btn-secondary w-100"
                  onClick={() => {
                    setProgramaToDelete(undefined);
                  }}
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
