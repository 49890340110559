import "react-phone-input-2/lib/style.css";
import { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { CurrentUserContext } from "../../../../../globalStore/CurrentUserContext";
import { ConteudoCadastroInteresseStartup } from "./ConteudoCadastroInteresseStartup";
import {
  CacheInvestidor,
  HandlerCacheInvestidor,
} from "../../../../../domain/usecases/interfaces/HandlerCacheInvestidor";
import { ContainerResponsive } from "../../../../generalComponents/ContainerResponsive";

export function PainelInvestidorCadastroInteresseStartup({
  handlerCacheInvestidor,
}: {
  handlerCacheInvestidor: HandlerCacheInvestidor;
}) {
  const navigate = useNavigate();

  const { currentUserScyggz, refreshCurrentUserScyggz } =
    useContext(CurrentUserContext);

  const [cacheInvestidor, setCacheInvestidor] = useState<CacheInvestidor>({});

  useEffect(() => {
    handlerCacheInvestidor.get().then((cacheInvestidorResult) => {
      if (cacheInvestidorResult.ok) {
        setCacheInvestidor(cacheInvestidorResult.value);
      }
    });
  }, [handlerCacheInvestidor]);

  const paginacao = {
    start: 6,
    end: 6,
  };

  const backUrl = "/cadastro/investidor/5";

  return (
    <section
      data-testid="painel-cadastro-tese-investidor"
      style={{ height: "100%" }}
    >
      <ContainerResponsive
        paginacao={paginacao}
        backUrlMobile={backUrl}
        showLogo={true}
      >
        <ConteudoCadastroInteresseStartup
          inputPainelInteresseStartup={{
            auth_user_id: currentUserScyggz.user?.auth_user_id ?? "",
            nome: currentUserScyggz.user?.nome ?? "",
            ...cacheInvestidor,
            areas_de_especializacao:
              cacheInvestidor.areas_de_especializacao ?? [],
            associacoes_anjo: cacheInvestidor.associacoes_anjo ?? [],
            interesse_startup_estagio:
              cacheInvestidor.interesse_startup_estagio ?? [],
            interesse_startup_mecanismo_solucao:
              cacheInvestidor.interesse_startup_mecanismo_solucao ?? [],
            interesse_startup_modelo_negocio:
              cacheInvestidor.interesse_startup_modelo_negocio ?? [],
            interesse_startup_segmento_atuacao:
              cacheInvestidor.interesse_startup_segmento_atuacao ?? [],
            tese_de_investimento:
              cacheInvestidor.tese_de_investimento?.conteudo ?? "",
          }}
          handlerCacheInvestidor={handlerCacheInvestidor}
          onBack={(values) => {
            handlerCacheInvestidor.save({
              interesse_startup_estagio: values.interesse_startup_estagio,
              interesse_startup_localizacoes:
                values.interesse_startup_localizacoes,
              interesse_startup_mecanismo_solucao:
                values.interesse_startup_mecanismo_solucao,
              interesse_startup_modelo_negocio:
                values.interesse_startup_modelo_negocio,
              interesse_startup_segmento_atuacao:
                values.interesse_startup_segmento_atuacao,
              tese_de_investimento: {
                conteudo: values.tese_de_investimento,
              },
            });
            navigate(backUrl);
          }}
          onFinalize={async (output) => {
            if (refreshCurrentUserScyggz) await refreshCurrentUserScyggz();
            handlerCacheInvestidor.clear();
            navigate("/user/" + output.auth_id + "/investidor");
          }}
        />
      </ContainerResponsive>
    </section>
  );
}
