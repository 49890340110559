/* eslint-disable @typescript-eslint/no-explicit-any */
import "react-phone-input-2/lib/style.css";
import { Field, Formik } from "formik";
import Select, { StylesConfig } from "react-select";
import makeAnimated from "react-select/animated";
import * as Yup from "yup";
import { useState, useEffect, useContext } from "react";

import { ButtonDarkGreen } from "../../../../generalComponents/buttonDarkGreen/ButtonDarkGreen";
import {
  EstagioStartup,
  ESTAGIO_STARTUP,
} from "../../../../../domain/entities/EstagioStartup";
import { MecanismoDeSolucaoTecnologicaOutput } from "../../../../../domain/entities/mecanismoDeSolucaoTecnologica";
import { ModeloNegocioOutput } from "../../../../../domain/entities/modeloNegocio";
import { SegmentoAtuacaoOutput } from "../../../../../domain/entities/segmentoAtuacao";
import { LocationFinderOutput } from "../../../../../domain/usecases/locationFinder";
import { HandlerGetModeloNegocio } from "../../../../../domain/usecases/interfaces/handlerGetModeloNegocio";
import { ResizeContext } from "../../../../../hooks/useResize";
import { ButtonLightGreen } from "../../../../generalComponents/buttonLightGreen/ButtonLightGreen";
import {
  CacheVentureCapital,
  HandlerCacheVentureCapital,
} from "../../../../../domain/usecases/interfaces/HandlerCacheVentureCapital";
import { CurrentUserContext } from "../../../../../globalStore/CurrentUserContext";
import { HandlerCreateVentureCapital } from "../../../../../domain/usecases/interfaces/handlerCreateVentureCapital";
import { InputVentureCapital } from "../../../../../domain/entities/VentureCapital";
import { InputLocais } from "../../../../generalComponents/InputLocais";
import { VentureCapitalValidator } from "../../../../../domain/entities/VentureCapitalValidator";
import { InputSegmentosAtuacao } from "../../../../generalComponents/InputSegmentosAtuacao";
import { InputMecanismosSolucao } from "../../../../generalComponents/InputMecanismosSolucao";

const animatedComponents = makeAnimated();

export function ConteudoTeseDeInvestimentoVentureCapital({
  handlerGetModeloNegocio,
  handlerCacheVentureCapital,
  handlerCreateVentureCapital,
  onBack,
  onNext,
}: {
  handlerGetModeloNegocio: HandlerGetModeloNegocio;
  handlerCacheVentureCapital: HandlerCacheVentureCapital;
  handlerCreateVentureCapital: HandlerCreateVentureCapital;
  onBack: () => void;
  onNext: () => void;
}) {
  const customStylesSelect: StylesConfig<ModeloNegocioOutput, true> = {
    control: () => ({
      border: "none",
      borderBottom: "3px #666600 solid",
      display: "flex",
      background: " #fff",
      borderBottomLeftRadius: "8px",
      textAlign: "left",
    }),
  };

  const [allModeloNegocio, setAllModeloNegocio] = useState<
    ModeloNegocioOutput[]
  >([]);
  const [isAllModeloNegocioLoading, setIsAllModeloNegocioLoading] =
    useState<boolean>(false);

  const { currentUserScyggz } = useContext(CurrentUserContext);

  const [parteDoNome, setParteDoNome] = useState("");

  const [cacheVentureCapital, setCacheVentureCapital] =
    useState<CacheVentureCapital>({});

  useEffect(() => {
    if (currentUserScyggz.user) {
      const parteDoNome: string =
        currentUserScyggz.user.nome.split(" ").at(0) ??
        currentUserScyggz.user.nome;

      setParteDoNome(parteDoNome);
    }
  }, [currentUserScyggz]);

  useEffect(() => {
    handlerCacheVentureCapital.get().then((cacheVentureCapitalResult) => {
      if (cacheVentureCapitalResult.ok) {
        setCacheVentureCapital(cacheVentureCapitalResult.value);
      }
    });
  }, [handlerCacheVentureCapital]);

  useEffect(() => {
    setIsAllModeloNegocioLoading(true);
    handlerGetModeloNegocio
      .getAllModeloNegocio()
      .then((handlerGetModeloNegocioResult) => {
        if (handlerGetModeloNegocioResult.ok)
          setAllModeloNegocio(handlerGetModeloNegocioResult.value);

        setIsAllModeloNegocioLoading(false);
      });
  }, [handlerGetModeloNegocio]);

  const { responsive } = useContext(ResizeContext);

  return (
    <Formik<FormikFields>
      initialValues={{
        tese_de_investimento: cacheVentureCapital.tese_de_investimento ?? "",
        interesse_startup_estagio:
          cacheVentureCapital.interesse_startup_estagio ?? [],
        interesse_startup_localizacoes:
          cacheVentureCapital.interesse_startup_localizacoes ?? [],
        interesse_startup_mecanismo_solucao:
          cacheVentureCapital.interesse_startup_mecanismo_solucao ?? [],
        interesse_startup_modelo_negocio:
          cacheVentureCapital.interesse_startup_modelo_negocio ?? [],
        interesse_startup_segmento_atuacao:
          cacheVentureCapital.interesse_startup_segmento_atuacao ?? [],
      }}
      enableReinitialize={true}
      validationSchema={validator}
      validateOnMount={true}
      validateOnChange={true}
      validateOnBlur={true}
      onSubmit={async (values) => {
        const saveToCache: CacheVentureCapital = {
          tese_de_investimento: values.tese_de_investimento,
          interesse_startup_estagio: values.interesse_startup_estagio,
          interesse_startup_localizacoes: values.interesse_startup_localizacoes,
          interesse_startup_modelo_negocio:
            values.interesse_startup_modelo_negocio,
          interesse_startup_segmento_atuacao:
            values.interesse_startup_segmento_atuacao,
          interesse_startup_mecanismo_solucao:
            values.interesse_startup_mecanismo_solucao,
        };

        await handlerCacheVentureCapital.save(saveToCache);
        if (!currentUserScyggz.user?.auth_user_id) {
          console.warn(
            "Precisa estar logado para criar o perfil Venture Capital"
          );
          return;
        }

        const ventureCapitalToCreate: InputVentureCapital = {
          areas_de_investimento:
            cacheVentureCapital.areas_de_investimento ?? [],
          email: cacheVentureCapital.email ?? "",
          gestores: [currentUserScyggz.user.auth_user_id],
          interesse_startup_estagio: values.interesse_startup_estagio,
          interesse_startup_mecanismo_solucao:
            values.interesse_startup_mecanismo_solucao,
          interesse_startup_modelo_negocio:
            values.interesse_startup_modelo_negocio,
          interesse_startup_segmento_atuacao:
            values.interesse_startup_segmento_atuacao,
          nome: cacheVentureCapital.nome ?? "",
          telefone: cacheVentureCapital.telefone ?? "",
          tese_de_investimento: { conteudo: values.tese_de_investimento },
          interesse_startup_localizacoes: values.interesse_startup_localizacoes,
          premiacoes: cacheVentureCapital.premiacoes,
          startups_investidas: cacheVentureCapital.startups_investidas,
          volume_recursos_sob_gestao:
            cacheVentureCapital.volume_recursos_sob_gestao,
          website: cacheVentureCapital.website,
          created_in: new Date(),
          habilitado: false,
        };

        const createVCResult =
          await handlerCreateVentureCapital.createVentureCapital(
            ventureCapitalToCreate
          );

        if (createVCResult.ok) {
          await handlerCacheVentureCapital.save({
            id: createVCResult.value.id,
          });
        } else {
          console.warn(
            "Erro na criação do Venture Capital",
            createVCResult.error
          );
          return;
        }

        onNext();
      }}
    >
      {({
        isValid,
        errors,
        touched,
        values,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        submitForm,
      }) => (
        <form className={`${!responsive.sm && "px-3"}`} onSubmit={handleSubmit}>
          <h4 className="card-title text-center mb-5 fw-bold">
            <span>{parteDoNome && `${parteDoNome},`}</span>
            <span className="h5 fw-bold">
              {} selecione abaixo o perfil de Startup que você procura.
            </span>
          </h4>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: !responsive.sm ? " 1fr" : "",
              gap: "20px",
            }}
          >
            <div>
              <div className="mb-3 col-md-12">
                <label
                  className={
                    "form-label " +
                    (errors.tese_de_investimento && touched.tese_de_investimento
                      ? " is-invalid"
                      : "")
                  }
                  htmlFor="iptTese"
                >
                  Tese de investimento*
                </label>
                <textarea
                  className="form-control"
                  rows={10}
                  onChange={({ target: { value: teseValue } }) => {
                    setFieldValue("tese_de_investimento", teseValue);
                    setFieldTouched("tese_de_investimento", true);
                  }}
                  defaultValue={values.tese_de_investimento}
                  id="iptTese"
                />
                <div className="invalid-feedback flex-fill">
                  {errors.tese_de_investimento}
                </div>
              </div>

              <div className="mb-3" role="group">
                <label className="form-label">
                  Qual(is) o(s) estágio(s) da startup de interesse?*
                </label>
                <div
                  style={
                    responsive.sm
                      ? {
                          marginLeft: "auto",
                          marginRight: "auto",
                          width: "fit-content",
                        }
                      : {}
                  }
                  className={
                    `${
                      responsive.sm ? "flex-column" : ""
                    } d-flex justify-content-between flex-wrap ` +
                    (errors.interesse_startup_estagio &&
                    touched.interesse_startup_estagio
                      ? " is-invalid"
                      : "")
                  }
                >
                  {ESTAGIO_STARTUP.map((estagio) => (
                    <div
                      key={estagio}
                      className={"form-check form-check-inline"}
                    >
                      <Field
                        className="form-check-input checkboxDarkGreen"
                        type="checkbox"
                        name="interesse_startup_estagio"
                        value={estagio}
                        id={"rdb" + estagio}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={"rdb" + estagio}
                      >
                        {estagio}
                      </label>
                    </div>
                  ))}
                </div>
                <div className="invalid-feedback flex-fill">
                  {errors.interesse_startup_estagio}
                </div>
              </div>

              <div className="mb-4">
                <InputLocais
                  label="Em que localidades as Startups devem estar"
                  value={values.interesse_startup_localizacoes}
                  onChange={(interesse_startup_localizacoes) => {
                    setFieldValue(
                      "interesse_startup_localizacoes",
                      interesse_startup_localizacoes
                    );
                  }}
                  validate={VentureCapitalValidator.isInvalidLocais}
                />
              </div>

              <div className="mb-4">
                <label className="form-label" htmlFor="selModelos">
                  Com o modelo de negócio*
                </label>
                <Select<ModeloNegocioOutput, true>
                  styles={responsive.sm ? customStylesSelect : undefined}
                  options={allModeloNegocio}
                  isLoading={isAllModeloNegocioLoading}
                  getOptionLabel={(o) => o.nome}
                  getOptionValue={(o) => o.id}
                  components={animatedComponents}
                  isMulti={true}
                  placeholder=""
                  name="interesse_startup_modelo_negocio"
                  onChange={(interesse_startup_modelo_negocio) => {
                    setTimeout(() => {
                      setFieldTouched("interesse_startup_modelo_negocio", true);
                    }, 0);
                    setFieldValue(
                      "interesse_startup_modelo_negocio",
                      interesse_startup_modelo_negocio
                    );
                  }}
                  value={values.interesse_startup_modelo_negocio}
                  onBlur={() => {
                    setFieldTouched("interesse_startup_modelo_negocio", true);
                  }}
                  className={
                    errors.interesse_startup_modelo_negocio &&
                    touched.interesse_startup_modelo_negocio
                      ? " is-invalid"
                      : ""
                  }
                  noOptionsMessage={() => "sem opção"}
                  inputId="selModelos"
                />
                <div className="invalid-feedback flex-fill">
                  {errors.interesse_startup_modelo_negocio}
                </div>
              </div>

              <div className="mb-4">
                <InputSegmentosAtuacao
                  label="Que atuam nos seguintes segmentos*"
                  value={values.interesse_startup_segmento_atuacao}
                  onChange={(interesse_startup_segmento_atuacao) => {
                    setTimeout(() => {
                      setFieldTouched(
                        "interesse_startup_segmento_atuacao",
                        true
                      );
                    }, 0);
                    setFieldValue(
                      "interesse_startup_segmento_atuacao",
                      interesse_startup_segmento_atuacao
                    );
                  }}
                  validate={
                    VentureCapitalValidator.validateInteresseStartupSegmentoAtuacao
                  }
                />
              </div>

              <div className="mb-5">
                <InputMecanismosSolucao
                  label="Que utilizam os seguintes mecanismos de solução tecnológica*"
                  value={values.interesse_startup_mecanismo_solucao ?? []}
                  onChange={(interesse_startup_mecanismo_solucao) => {
                    setFieldValue(
                      "interesse_startup_mecanismo_solucao",
                      interesse_startup_mecanismo_solucao
                    );
                  }}
                  validate={
                    VentureCapitalValidator.validateInteresseStartupMecanismoSolucao
                  }
                />
              </div>
            </div>
          </div>
          <div className=" fw-bold text-center d-flex flex-wrap justify-content-center gap-2">
            {!responsive.sm && (
              <p style={{ color: "#525200", maxWidth: "500px", margin: "0" }}>
                *Obs: Após a criação do perfil da Venture Capital, você poderá
                convidar demais membros da sua Organização para completar o
                perfil.
              </p>
            )}

            <div
              className={`${responsive.sm ? "mt-2 w-100" : "text-end mt-2"}`}
            >
              {!responsive.sm && (
                <ButtonLightGreen
                  className="me-3"
                  onClick={async () => {
                    const saveToCache: CacheVentureCapital = {
                      tese_de_investimento: values.tese_de_investimento,
                      interesse_startup_estagio:
                        values.interesse_startup_estagio,
                      interesse_startup_localizacoes:
                        values.interesse_startup_localizacoes,
                      interesse_startup_modelo_negocio:
                        values.interesse_startup_modelo_negocio,
                      interesse_startup_segmento_atuacao:
                        values.interesse_startup_segmento_atuacao,
                      interesse_startup_mecanismo_solucao:
                        values.interesse_startup_mecanismo_solucao,
                    };

                    await handlerCacheVentureCapital.save(saveToCache);
                    onBack();
                  }}
                >
                  Voltar
                </ButtonLightGreen>
              )}
              <ButtonDarkGreen
                disabled={!isValid}
                onClick={submitForm}
                className={`${responsive.sm && "w-100"}`}
              >
                Avançar
              </ButtonDarkGreen>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}

type FormikFields = {
  tese_de_investimento: string;
  interesse_startup_estagio: EstagioStartup[];
  interesse_startup_localizacoes: LocationFinderOutput[];
  interesse_startup_modelo_negocio: ModeloNegocioOutput[];
  interesse_startup_segmento_atuacao: SegmentoAtuacaoOutput[];
  interesse_startup_mecanismo_solucao: MecanismoDeSolucaoTecnologicaOutput[];
};

const validator = Yup.object().shape({
  tese_de_investimento: Yup.string()
    .required("Campo obrigatório, deve ter no mínimo 100 caracteres")
    .min(100, "Mínimo 100 caracteres")
    .max(500, "Máximo 500 caracteres"),
  interesse_startup_estagio: Yup.array()
    .required("Precisa especificar estágio startup de interesse")
    .min(1, "Precisa especificar estágio startup de interesse"),
  interesse_startup_localizacoes: Yup.array(),
  interesse_startup_modelo_negocio: Yup.array()
    .required("Precisa especificar modelo de negócio da startup de interesse")
    .min(1, "Precisa especificar modelo de negócio da startup de interesse"),
  interesse_startup_segmento_atuacao: Yup.array()
    .required("Precisa especificar segmento de atuação da startup de interesse")
    .min(1, "Precisa especificar segmento de atuação da startup de interesse"),
  interesse_startup_mecanismo_solucao: Yup.array()
    .required(
      "Precisa especificar mecanismo de solução da startup de interesse"
    )
    .min(1, "Precisa especificar mecanismo de solução da startup de interesse"),
});
