import {
  CacheEmpresa,
  HandlerCacheEmpresa,
} from "../domain/usecases/interfaces/HandlerCacheEmpresa";
import { Result, Writeable } from "../typings";

export class HandlerCacheEmpresaStorage implements HandlerCacheEmpresa {
  private readonly keyEmpresaPrefix = "EmpresaCadastroInScyggz";

  clear(): Promise<Result<null>> {
    if (!localStorage) {
      return Promise.resolve({
        ok: false,
        error: new Error(
          "Não fez cache do Empresa pois o navegador é incompativel"
        ),
      });
    }

    try {
      localStorage.removeItem(this.keyEmpresaPrefix);

      return Promise.resolve({
        ok: true,
        value: null,
      });
    } catch (error) {
      return Promise.resolve({
        ok: false,
        error: error as Error,
      });
    }
  }

  async save(empresa: CacheEmpresa): Promise<Result<null, Error>> {
    if (!localStorage) {
      return Promise.resolve({
        ok: false,
        error: new Error(
          "Não fez cache da Empresa pois o navegador é incompativel"
        ),
      });
    }

    try {
      const getResult = await this.get();

      if (!getResult.ok) {
        return getResult;
      }

      localStorage.setItem(
        this.keyEmpresaPrefix,
        JSON.stringify({ ...getResult.value, ...empresa })
      );

      return Promise.resolve({
        ok: true,
        value: null,
      });
    } catch (error) {
      return Promise.resolve({
        ok: false,
        error: error as Error,
      });
    }
  }

  get(): Promise<Result<CacheEmpresa, Error>> {
    const empresaStorage = localStorage.getItem(this.keyEmpresaPrefix);

    if (!empresaStorage) {
      return Promise.resolve({
        ok: true,
        value: {},
      });
    }

    const empresaAny = JSON.parse(empresaStorage);

    if (!empresaAny) {
      return Promise.resolve({
        ok: true,
        value: {},
      });
    }

    const empresa = this.convertAnyToEmpresa(empresaAny);

    return Promise.resolve({
      ok: true,
      value: empresa,
    });
  }

  private convertAnyToEmpresa(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    empresaAny: any
  ): Writeable<CacheEmpresa> {
    const empresa: Writeable<CacheEmpresa> = {};

    if (empresaAny.id) empresa.id = empresaAny.id;

    if (empresaAny.email) empresa.email = empresaAny.email;

    if (empresaAny.gestores) empresa.gestores = empresaAny.gestores;

    if (empresaAny.interesse_startup_estagio)
      empresa.interesse_startup_estagio = empresaAny.interesse_startup_estagio;
    if (empresaAny.interesse_startup_localizacoes)
      empresa.interesse_startup_localizacoes =
        empresaAny.interesse_startup_localizacoes;

    if (empresaAny.interesse_startup_mecanismo_solucao)
      empresa.interesse_startup_mecanismo_solucao =
        empresaAny.interesse_startup_mecanismo_solucao;

    if (empresaAny.interesse_startup_modelo_negocio)
      empresa.interesse_startup_modelo_negocio =
        empresaAny.interesse_startup_modelo_negocio;

    if (empresaAny.interesse_startup_segmento_atuacao)
      empresa.interesse_startup_segmento_atuacao =
        empresaAny.interesse_startup_segmento_atuacao;

    if (empresaAny.interesses_cientista_grupo_pesquisa)
      empresa.interesses_cientista_grupo_pesquisa =
        empresaAny.interesses_cientista_grupo_pesquisa;

    if (empresaAny.interesses_cientista_tecnologia_area_conhecimento)
      empresa.interesses_cientista_tecnologia_area_conhecimento =
        empresaAny.interesses_cientista_tecnologia_area_conhecimento;

    if (empresaAny.interesses_cientista_tecnologia_areas_aplicacao)
      empresa.interesses_cientista_tecnologia_areas_aplicacao =
        empresaAny.interesses_cientista_tecnologia_areas_aplicacao;

    if (empresaAny.interesses_cientista_tecnologia_mecanismos_solucao)
      empresa.interesses_cientista_tecnologia_mecanismos_solucao =
        empresaAny.interesses_cientista_tecnologia_mecanismos_solucao;

    if (empresaAny.interesses_inovacao)
      empresa.interesses_inovacao = empresaAny.interesses_inovacao;

    if (empresaAny.linkedin) empresa.linkedin = empresaAny.linkedin;

    if (empresaAny.nome) empresa.nome = empresaAny.nome;

    if (empresaAny.path_logo) empresa.path_logo = empresaAny.nomepath_logo;

    if (empresaAny.setores_atuacao)
      empresa.setores_atuacao = empresaAny.setores_atuacao;

    if (empresaAny.sobre) empresa.sobre = empresaAny.sobre;

    if (empresaAny.telefone) empresa.telefone = empresaAny.telefone;

    if (empresaAny.website) empresa.website = empresaAny.website;
    return empresa;
  }
}
