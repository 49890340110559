import { useNavigate } from "react-router-dom";

import { HandlerCacheStartup } from "../../../../../domain/usecases/interfaces/HandleCacheStartup";
import { ContainerResponsive } from "../../../../generalComponents/ContainerResponsive";
import { ConteudoSobreStartup } from "./ConteudoSobreStartup";

export const PainelCadastroSobreStartup = ({
  handlerCacheStartup,
}: {
  handlerCacheStartup: HandlerCacheStartup;
}) => {
  const navigate = useNavigate();

  const paginacaoElegibilidadeStartup = {
    start: 5,
    end: 7,
  };

  return (
    <section style={{ height: "100%" }}>
      <ContainerResponsive
        textoMarcaDagua={"Startup"}
        paginacao={paginacaoElegibilidadeStartup}
        backUrlMobile="/cadastro/startup/4"
        showLogo={true}
      >
        <ConteudoSobreStartup
          onNext={() => {
            navigate("/cadastro/startup/6");
          }}
          handlerCacheStartup={handlerCacheStartup}
          onBack={() => {
            navigate("/cadastro/startup/4");
          }}
        />
      </ContainerResponsive>
    </section>
  );
};
