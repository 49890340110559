import { Result, Writeable } from "../typings";
import {
  CacheHub,
  HandlerCacheHub,
} from "../domain/usecases/interfaces/HandlerCacheHub";

export class HandlerCacheHubStorage implements HandlerCacheHub {
  private readonly keyHubPrefix = "HubCadastroInScyggz";

  clear(): Promise<Result<null>> {
    if (!localStorage) {
      return Promise.resolve({
        ok: false,
        error: new Error(
          "Não fez cache do Hub pois o navegador é incompativel"
        ),
      });
    }

    try {
      localStorage.removeItem(this.keyHubPrefix);

      return Promise.resolve({
        ok: true,
        value: null,
      });
    } catch (error) {
      return Promise.resolve({
        ok: false,
        error: error as Error,
      });
    }
  }

  async save(hub: CacheHub): Promise<Result<null, Error>> {
    if (!localStorage) {
      return Promise.resolve({
        ok: false,
        error: new Error(
          "Não fez cache do Hub pois o navegador é incompativel"
        ),
      });
    }

    try {
      const getResult = await this.get();

      if (!getResult.ok) {
        return getResult;
      }

      localStorage.setItem(
        this.keyHubPrefix,
        JSON.stringify({ ...getResult.value, ...hub })
      );

      return Promise.resolve({
        ok: true,
        value: null,
      });
    } catch (error) {
      return Promise.resolve({
        ok: false,
        error: error as Error,
      });
    }
  }

  get(): Promise<Result<CacheHub, Error>> {
    const hubStorage = localStorage.getItem(this.keyHubPrefix);

    if (!hubStorage) {
      return Promise.resolve({
        ok: true,
        value: {},
      });
    }

    const hubAny = JSON.parse(hubStorage);

    if (!hubAny) {
      return Promise.resolve({
        ok: true,
        value: {},
      });
    }

    const hub = this.convertAnyToHub(hubAny);

    return Promise.resolve({
      ok: true,
      value: hub,
    });
  }

  private convertAnyToHub(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    hubAny: any
  ): Writeable<CacheHub> {
    const hub: Writeable<CacheHub> = {};

    if (hubAny.id) hub.id = hubAny.id;

    if (hubAny.sobre) hub.sobre = hubAny.sobre;

    if (hubAny.cidade) hub.cidade = hubAny.cidade;

    if (hubAny.classificacao) hub.classificacao = hubAny.classificacao;

    if (hubAny.nit) hub.nit = hubAny.nit;

    if (hubAny.nome) hub.nome = hubAny.nome;

    if (hubAny.premiacoes) hub.premiacoes = hubAny.premiacoes;

    if (hubAny.email) hub.email = hubAny.email;

    if (hubAny.linkedin) hub.linkedin = hubAny.linkedin;

    if (hubAny.telefone) hub.telefone = hubAny.telefone;

    if (hubAny.path_logo) hub.path_logo = hubAny.path_logo;

    if (hubAny.website) hub.website = hubAny.website;

    return hub;
  }
}
