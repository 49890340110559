import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { UsuarioScyggz } from "../../../../domain/entities/usuarioScyggz";
import { ButtonMeusPerfis } from "../../../generalComponents/buttonMeusPerfis/ButtonMeusPerfis";
import { TextH2Bold } from "../../../generalComponents/TextH2Bold";

export const MeusPerfis = ({
  perfis,
  auth_user_id,
}: Pick<UsuarioScyggz, "perfis" | "auth_user_id">) => {
  const [perfisAprovados, setPerfisAprovados] = useState<string[]>();
  const [perfisInvalidos, setPerfisInvalidos] = useState<string[]>();

  useEffect(() => {
    const perfisValidos: string[] = [];
    const perfisInvalidos: string[] = [];

    if (perfis) {
      for (const [key, value] of Object.entries(perfis)) {
        if (value !== false) {
          perfisValidos.push(key);
        } else {
          perfisInvalidos.push(key);
        }
      }
    }
    setPerfisAprovados(perfisValidos);
    setPerfisInvalidos(perfisInvalidos);
  }, [perfis]);

  return (
    <aside className="border rounded-4 card w-100">
      <div className="card-body px-4 py-3">
        <TextH2Bold classes="mb-5" children="Meus Perfis" />

        <div>
          {perfisAprovados?.map((perfil, i) => (
            <Link key={i} to={`/user/${auth_user_id}/${perfil}`}>
              <ButtonMeusPerfis classes="mb-3" children={perfil} />
            </Link>
          ))}
        </div>

        <div>
          {perfisInvalidos?.map((perfil, i) => (
            <Link key={i} to={`/user/${auth_user_id}/${perfil}`}>
              <ButtonMeusPerfis classes="mb-3" children={perfil} />
            </Link>
          ))}
        </div>
      </div>
    </aside>
  );
};
