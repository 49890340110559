import {
  Firestore,
  collection,
  where,
  query,
  Timestamp,
  doc,
  onSnapshot,
} from "@firebase/firestore";
import { Observable } from "rxjs";

import { Result } from "../typings";
import { OutputEmpresa } from "../domain/entities/Empresa";
import { HandlerGetEmpresaRealtime } from "../domain/usecases/interfaces/HandlerGetEmpresaRealtime";

export class HandlerGetEmpresaRealtimeFirebase
  implements HandlerGetEmpresaRealtime
{
  constructor(private firestore: Firestore) {}

  getAllEnabledEmpresas(): Observable<Result<OutputEmpresa[]>> {
    return new Observable((sub) => {
      const queryEnabledEmpresas = query(
        collection(this.firestore, "empresas"),
        where("habilitado", "==", true)
      );

      onSnapshot(
        queryEnabledEmpresas,
        (querySnapshot) => {
          const empresas: OutputEmpresa[] = querySnapshot.docs.map(
            (docSnapshot) => {
              const empresa = docSnapshot.data() as OutputEmpresa;
              empresa.id = docSnapshot.id;
              empresa.created_in = (
                empresa.created_in as unknown as Timestamp
              ).toDate();
              return empresa;
            }
          );

          sub.next({
            ok: true,
            value: empresas,
          });
        },
        () =>
          sub.next({
            ok: true,
            value: [],
          })
      );
    });
  }

  getEmpresa(id: string): Observable<Result<OutputEmpresa>> {
    return new Observable((sub) => {
      const docEmpresa = doc(this.firestore, "empresas", id);

      onSnapshot(
        docEmpresa,
        (docSnapshot) => {
          const empresa = docSnapshot.data() as OutputEmpresa;
          empresa.id = docSnapshot.id;
          empresa.created_in = (
            empresa.created_in as unknown as Timestamp
          ).toDate();

          sub.next({
            ok: true,
            value: empresa,
          });
        },
        (error) =>
          sub.next({
            ok: false,
            error,
          })
      );
    });
  }

  getEmpresasGestor(id_gerente: string): Observable<Result<OutputEmpresa[]>> {
    return new Observable((sub) => {
      const queryEmpresasGestor = query(
        collection(this.firestore, "empresas"),
        where("gestores", "array-contains", id_gerente)
      );

      onSnapshot(
        queryEmpresasGestor,
        (querySnapshot) => {
          const empresas: OutputEmpresa[] = querySnapshot.docs.map(
            (docSnapshot) => {
              const empresa = docSnapshot.data() as OutputEmpresa;
              empresa.id = docSnapshot.id;
              empresa.created_in = (
                empresa.created_in as unknown as Timestamp
              ).toDate();
              return empresa;
            }
          );

          sub.next({
            ok: true,
            value: empresas,
          });
        },
        () =>
          sub.next({
            ok: true,
            value: [],
          })
      );
    });
  }
}
