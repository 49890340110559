import { useNavigate } from "react-router-dom";

import { ConteudoSobreMentor } from "./ConteudoSobreMentor";
import { ContainerResponsive } from "../../../../generalComponents/ContainerResponsive";
import { HandlerCacheMentor } from "../../../../../domain/usecases/interfaces/HandlerCacheMentor";

export const PainelSobreMentor = ({
  handlerCacheMentor,
}: {
  handlerCacheMentor: HandlerCacheMentor;
}) => {
  const navigate = useNavigate();

  const paginacao = {
    start: 5,
    end: 6,
  };

  const backUrl = "/cadastro/mentor/4";
  const nextUrl = "/cadastro/mentor/6";

  return (
    <ContainerResponsive
      backUrlMobile={backUrl}
      paginacao={paginacao}
      textoMarcaDagua="Mentor"
      showLogo={true}
    >
      <ConteudoSobreMentor
        handlerCacheMentor={handlerCacheMentor}
        onBack={() => {
          navigate(backUrl);
        }}
        onNext={() => {
          navigate(nextUrl);
        }}
      />
    </ContainerResponsive>
  );
};
