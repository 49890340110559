import { ButtonDarkGreen } from "../../../../generalComponents/buttonDarkGreen/ButtonDarkGreen";
import * as Yup from "yup";
import { Formik } from "formik";
import Select, { StylesConfig } from "react-select";
import makeAnimated from "react-select/animated";
import { useContext, useEffect, useState } from "react";

import { ResizeContext } from "../../../../../hooks/useResize";
import { InputPremiacoes } from "../../../../generalComponents/InputPremiacoes/InputPremiacoes";
import {
  Premiacao,
  PremiacaoOutput,
} from "../../../../../domain/entities/premiacao";
import { ButtonLightGreen } from "../../../../generalComponents/buttonLightGreen/ButtonLightGreen";
import { CurrentUserContext } from "../../../../../globalStore/CurrentUserContext";
import {
  CacheVentureCapital,
  HandlerCacheVentureCapital,
} from "../../../../../domain/usecases/interfaces/HandlerCacheVentureCapital";
import { OutputValorFinanceiro } from "../../../../../domain/entities/ValorFinanceiro";
import { AreaInvestimentoOutput } from "../../../../../domain/entities/AreaInvestimento";
import { HandlerGetAreaInvestimento } from "../../../../../domain/usecases/interfaces/handlerGetAreaInvestimento";
import {
  InputTelefone,
  TypeInputTelefone,
} from "../../../../generalComponents/InputTelefone";

export const ConteudoSobreVentureCapital = ({
  handlerCacheVentureCapital,
  handlerGetAreaInvestimento,
  onBack,
  onNext,
}: {
  handlerCacheVentureCapital: HandlerCacheVentureCapital;
  handlerGetAreaInvestimento: HandlerGetAreaInvestimento;
  onNext: () => void;
  onBack: () => void;
}) => {
  const customStylesSelect: StylesConfig<AreaInvestimentoOutput, true> = {
    control: () => ({
      border: "none",
      borderBottom: "3px #666600 solid",
      display: "flex",
      background: " #fff",
      borderBottomLeftRadius: "8px",
      textAlign: "left",
    }),
  };

  const animatedComponents = makeAnimated();

  const { responsive } = useContext(ResizeContext);

  const { currentUserScyggz } = useContext(CurrentUserContext);

  const [parteDoNome, setParteDoNome] = useState("");

  const [cacheVentureCapital, setCacheVentureCapital] =
    useState<CacheVentureCapital>({});
  const [isEditingPremiacoes, setIsEditingPremiacoes] =
    useState<boolean>(false);

  useEffect(() => {
    handlerCacheVentureCapital.get().then((cacheVentureCapital) => {
      if (cacheVentureCapital.ok) {
        setCacheVentureCapital(cacheVentureCapital.value);
      }
    });
  }, [handlerCacheVentureCapital]);

  const [allAreaInvestimento, setAllAreaInvestimento] = useState<
    AreaInvestimentoOutput[]
  >([]);
  const [isAllAreaInvestimentoLoading, setIsAllAreaInvestimentoLoading] =
    useState<boolean>(false);

  useEffect(() => {
    setIsAllAreaInvestimentoLoading(true);
    handlerGetAreaInvestimento
      .getAllAreaInvestimento()
      .then((handlerGetAreaInvestimentoResult) => {
        if (handlerGetAreaInvestimentoResult.ok)
          setAllAreaInvestimento(handlerGetAreaInvestimentoResult.value);

        setIsAllAreaInvestimentoLoading(false);
      });
  }, [handlerGetAreaInvestimento]);

  useEffect(() => {
    if (currentUserScyggz.user) {
      const parteDoNome: string =
        currentUserScyggz.user.nome.split(" ").at(0) ??
        currentUserScyggz.user.nome;

      setParteDoNome(parteDoNome);
    }
  }, [currentUserScyggz]);

  return (
    <div style={!responsive.sm ? { padding: "0 70px" } : {}}>
      <Formik<FormikValues>
        initialValues={{
          email: cacheVentureCapital?.email ?? "",
          nome_VentureCapital: cacheVentureCapital?.nome ?? "",
          website: cacheVentureCapital?.website ?? "",
          telefone: {
            numero: cacheVentureCapital?.telefone ?? "",
            invalid: true,
          },
          premiacoes: cacheVentureCapital?.premiacoes ?? [],
          areas_de_investimento:
            cacheVentureCapital.areas_de_investimento ?? [],
          volume_recursos_sob_gestao: {
            moeda:
              cacheVentureCapital.volume_recursos_sob_gestao?.moeda ?? "real",
            valor: cacheVentureCapital.volume_recursos_sob_gestao?.valor ?? 0,
          },
        }}
        validationSchema={validator}
        validateOnMount={true}
        validateOnChange={true}
        validateOnBlur={true}
        enableReinitialize={true}
        onSubmit={async (values) => {
          handlerCacheVentureCapital.save({
            email: values.email,
            nome: values.nome_VentureCapital,
            telefone: values.telefone.numero,
            website: values.website,
            premiacoes: values.premiacoes,
            volume_recursos_sob_gestao: values.volume_recursos_sob_gestao,
            areas_de_investimento: values.areas_de_investimento,
          });
          onNext();
        }}
      >
        {({
          isValid,
          isSubmitting,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          handleChange,
          handleBlur,
          errors,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit} className="pb-2">
            <h4 className="card-title text-center mb-3 fw-bold">
              <span>{parteDoNome && `${parteDoNome}`}</span>
              <span className="h5 fw-bold">
                {}, por favor fale sobre o VentureCapital de Inovação
              </span>
            </h4>

            <div className="mb-4">
              <label htmlFor="iptNome" className="form-label">
                Nome da Venture Capital*
              </label>
              <input
                type="text"
                className={
                  `${responsive.sm ? "imputMobile" : "form-control"}` +
                  (errors.nome_VentureCapital && touched.nome_VentureCapital
                    ? " is-invalid"
                    : "")
                }
                id="iptNome"
                name="nome_VentureCapital"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.nome_VentureCapital}
              />
              <div className="invalid-feedback flex-fill">
                {errors.nome_VentureCapital}
              </div>
            </div>

            <div className="mb-4">
              <InputTelefone
                label="Telefone*"
                value={values.telefone}
                onChange={(tel) => {
                  setFieldValue("telefone", tel);
                }}
                validate={(numero) => {
                  const preenchido: boolean = (numero?.length ?? 0) > 0;

                  if (!preenchido) {
                    return new Error("obrigatório");
                  }
                }}
              />
            </div>

            <div className="mb-4">
              <label htmlFor="iptWebsite" className="form-label">
                Website
              </label>
              <input
                type="text"
                className={
                  `${responsive.sm ? "imputMobile" : "form-control"}` +
                  (errors.website && touched.website ? " is-invalid" : "")
                }
                id="iptWebsite"
                name="website"
                onChange={website => {
                  const websiteStr = website.target.value;
                  let websiteFinal = websiteStr;

                  if(websiteStr === "http:/" || websiteStr === "https:/") {
                    websiteFinal = "";
                  } else if (!websiteStr.startsWith("http://") && !websiteStr.startsWith("https://")) {
                    websiteFinal = "https://" + websiteStr;
                  }

                  setFieldValue("website", websiteFinal);
                }}
                onBlur={handleBlur}
                value={values.website}
              />
              <div className="invalid-feedback flex-fill">{errors.website}</div>
            </div>

            <div className="mb-4">
              <label htmlFor="iptEmail" className="form-label">
                E-mail*
              </label>
              <input
                type="text"
                className={
                  `${responsive.sm ? "imputMobile" : "form-control"}` +
                  (errors.email && touched.email ? " is-invalid" : "")
                }
                id="iptEmail"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
              <div className="invalid-feedback flex-fill">{errors.email}</div>
            </div>

            <div className="mb-2 col-12">
              <label className="form-label" htmlFor="selectAreaInvest">
                Principais áreas de investimento*
              </label>
              <Select<AreaInvestimentoOutput, true>
                getOptionLabel={(o) => o.nome}
                getOptionValue={(o) => o.id}
                isLoading={isAllAreaInvestimentoLoading}
                styles={responsive.sm ? customStylesSelect : undefined}
                options={allAreaInvestimento}
                isMulti={true}
                components={animatedComponents}
                value={values.areas_de_investimento}
                placeholder=""
                onBlur={() => setFieldTouched("areas_de_investimento", true)}
                onChange={(areas_de_investimento) => {
                  setFieldValue("areas_de_investimento", areas_de_investimento);

                  setTimeout(() => {
                    setFieldTouched("areas_de_investimento", true);
                  }, 0);
                }}
                className={
                  errors.areas_de_investimento && touched.areas_de_investimento
                    ? " is-invalid "
                    : ""
                }
                noOptionsMessage={() => "sem opção"}
                inputId="selectAreaInvest"
              />
              <div className="invalid-feedback flex-fill">Campo inválido</div>
            </div>

            <div className="mb-2 col-12">
              <label
                htmlFor="iptFaturamentoMedio"
                className={
                  "form-label m-0" +
                  (errors.volume_recursos_sob_gestao &&
                  touched.volume_recursos_sob_gestao
                    ? " is-invalid"
                    : "")
                }
              >
                Volume de recursos sob gestão
              </label>

              <div
                className={`${responsive.sm ? " d-flex" : "input-group"} mb-3`}
              >
                <select
                  className="form-select"
                  name="volume_recursos_sob_gestao.moeda"
                  onChange={handleChange}
                  style={
                    responsive.sm
                      ? {
                          border: "none",
                          borderBottom: "3px #666600 solid",
                          display: "flex",
                          background: " #fff",
                          borderBottomLeftRadius: "8px",
                          borderBottomRightRadius: "0px",
                          textAlign: "left",
                          width: "30%",
                        }
                      : { maxWidth: "90px" }
                  }
                  defaultValue={values.volume_recursos_sob_gestao?.moeda}
                  title="Moeda do volume de recursos sob gestão"
                >
                  <option value={values.volume_recursos_sob_gestao?.moeda}>
                    {values.volume_recursos_sob_gestao?.moeda === "dollar"
                      ? "US$"
                      : "R$"}
                  </option>
                  {values.volume_recursos_sob_gestao?.moeda === "dollar" ? (
                    <option value="real">R$</option>
                  ) : (
                    <option value="dollar">US$</option>
                  )}
                </select>
                <input
                  type="number"
                  className={
                    `${responsive.sm ? "imputMobile" : "form-control"}` +
                    (errors.volume_recursos_sob_gestao &&
                    touched.volume_recursos_sob_gestao
                      ? " is-invalid"
                      : "")
                  }
                  style={responsive.sm ? { borderBottomLeftRadius: "0px" } : {}}
                  id="iptFaturamentoMedio"
                  name="volume_recursos_sob_gestao.valor"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.volume_recursos_sob_gestao?.valor}
                />
              </div>

              <div className="invalid-feedback flex-fill">Valor inválido</div>
            </div>

            <InputPremiacoes
              value={values.premiacoes ?? []}
              onChange={(premiacoes) => {
                setFieldValue("premiacoes", premiacoes);
              }}
              label="Liste suas premiações/certificações"
              className="col-12 text-end"
              showTip={true}
              isEditing={setIsEditingPremiacoes}
            />
            <div className="mt-5 fw-bold text-center d-flex flex-wrap justify-content-center gap-2">
              {!responsive.sm && (
                <p style={{ color: "#525200", maxWidth: "500px", margin: "0" }}>
                  *Obs: Após a criação do perfil do VentureCapital, você poderá
                  convidar demais membros da sua Organização para completar o
                  perfil.
                </p>
              )}

              <div
                className=" text-end d-flex flex-nowrap"
                style={{
                  height: "fit-content",
                  width: responsive.sm ? "100%" : "",
                }}
              >
                {!responsive.sm && (
                  <ButtonLightGreen
                    className="me-3"
                    onClick={() => {
                      handlerCacheVentureCapital.save({
                        email: values.email,
                        nome: values.nome_VentureCapital,
                        telefone: values.telefone.numero,
                        website: values.website,
                        premiacoes: values.premiacoes,
                        volume_recursos_sob_gestao:
                          values.volume_recursos_sob_gestao,
                        areas_de_investimento: values.areas_de_investimento,
                      });
                      onBack();
                    }}
                  >
                    Voltar
                  </ButtonLightGreen>
                )}
                <ButtonDarkGreen
                  disabled={!isValid || isSubmitting || isEditingPremiacoes}
                  isSubmit={true}
                  className={
                    (!responsive.sm ? "ms-2" : "w-100") + " text-uppercase"
                  }
                >
                  Avançar
                </ButtonDarkGreen>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export type FormikValues = {
  email: string;
  nome_VentureCapital: string;
  telefone: TypeInputTelefone;
} & Partial<{
  website: string; // TODO: VALIDATE URL
  premiacoes: PremiacaoOutput[];
  volume_recursos_sob_gestao: OutputValorFinanceiro;
  areas_de_investimento: AreaInvestimentoOutput[];
}>;

const validator = Yup.object().shape({
  nome_VentureCapital: Yup.string()
    .required("Campo obrigatório")
    .min(1, "Campo obrigatório"),
  website: Yup.string().url("Deve ser um link").min(5, "Mínimo 5 caracteres"),
  telefone: Yup.object()
    .shape({
      numero: Yup.string().required(),
      invalid: Yup.boolean().isFalse(),
    })
    .required("Cidade é obrigatório"),
  email: Yup.string().required("Campo obrigatório").email("Campo inválido"),
  premiacoes: Yup.array().of(
    Yup.object().shape({
      nome: Yup.string()
        .required("O nome é obrigatório")
        .min(
          Premiacao.NOME_MINIMO_LENGTH,
          "Mínimo " + Premiacao.NOME_MINIMO_LENGTH + " caracteres"
        )
        .max(
          Premiacao.NOME_MAXIMO_LENGTH,
          "Máximo " + Premiacao.NOME_MAXIMO_LENGTH + " caracteres"
        ),
      descricao: Yup.string().max(
        Premiacao.DESCRICAO_MAXIMO_LENGTH,
        "Máximo de " + Premiacao.DESCRICAO_MAXIMO_LENGTH + " caracteres."
      ),
    })
  ),
  areas_de_investimento: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string()
          .required("Id área é obrigatório")
          .min(1, "Id área é obrigatório"),
        nome: Yup.string()
          .required("Nome é obrigatório")
          .min(1, "Nome é obrigatório"),
      })
    )
    .required("Área de investimento obrigatório.")
    .min(1, "Área de investimento obrigatório."),
  volume_recursos_sob_gestao: Yup.object().shape({
    moeda: Yup.string(),
    valor: Yup.number(),
  }),
});
