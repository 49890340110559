import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";

import { Premiacao } from "../../../domain/entities/premiacao";
import { ItemPremiacao } from "./ItemPremiacao";
import { InputEditPremiacao } from "./InputEditPremiacao";
import { InputAddPremiacao } from "./InputAddPremiacao";

export function InputPremiacoes({
  label,
  className,
  value = [],
  onChange,
  showTip = true,
  isEditing,
}: {
  label: string;
  className?: string;
  value: Premiacao[];
  onChange: (premiacoes: Premiacao[]) => void;
  showTip?: boolean;
  isEditing: (isEditing: boolean) => void;
}) {
  const [status, setStatus] = useState<
    | undefined
    | { type: "add" }
    | {
        type: "edit";
        premiacaoToEdit: {
          premiacao: Premiacao;
          index: number;
        };
      }
  >();

  useEffect(() => {
    if (status == undefined) isEditing(false);
  }, [isEditing, status]);

  return (
    <div className={className}>
      <label
        className="form-label fw-bolder m-0 d-block"
        htmlFor="selAreasConhecimento"
      >
        {label}
      </label>

      {status == undefined ? (
        <>
          {showTip && (
            <p>
              <small>
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  className="opacity-25 me-2"
                />
                Você poderá adicionar mais premiações após a criação da sua
                conta
              </small>
            </p>
          )}

          {value.map((premiacao, i) => (
            <ItemPremiacao
              key={i}
              premiacao={premiacao}
              onClickDelete={() => {
                value.splice(i, 1);
                onChange([...value]);
              }}
              onClickEdit={() => {
                setStatus({
                  type: "edit",
                  premiacaoToEdit: {
                    premiacao,
                    index: i,
                  },
                });
              }}
            />
          ))}

          <button
            type="button"
            className="btn btn-outline-success fw-bolder"
            title="adicionar premiação"
            onClick={() =>
              setStatus({
                type: "add",
              })
            }
          >
            <FontAwesomeIcon icon={faPlus} className="me-1" /> premiação
          </button>
        </>
      ) : (
        <>
          {status.type == "edit" ? (
            <InputEditPremiacao
              onCancel={() => {
                setStatus(undefined);
              }}
              premiacaoToEdit={status.premiacaoToEdit.premiacao}
              onSave={(premiacao) => {
                value.splice(status.premiacaoToEdit.index, 1, premiacao);
                onChange([...value]);
                setStatus(undefined);
              }}
              onChange={() => isEditing(true)}
            />
          ) : (
            <>
              {status.type == "add" && (
                <>
                  <InputAddPremiacao
                    onAdd={(premiacao) => {
                      onChange([...value, premiacao]);
                      setStatus(undefined);
                    }}
                    onCancel={() => {
                      setStatus(undefined);
                    }}
                    onChange={() => isEditing(true)}
                  />
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}
