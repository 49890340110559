import { useContext, useEffect, useState } from "react";

import { UsuarioInfoContext } from "../../../globalStore/UsuarioInfoContext";
import { InfoLinks } from "./components/generalComponents/InfoLinks";

export function FooterUsuarioFromContext() {
  const { usuarioInfoResult } = useContext(UsuarioInfoContext);

  const [links, setLinks] = useState<string[]>([]);

  useEffect(() => {
    if (usuarioInfoResult?.ok) {
      const links: string[] = [];

      if (usuarioInfoResult.value.url_linkedin)
        links.push(usuarioInfoResult.value.url_linkedin);

      if (usuarioInfoResult.value.url_lattes)
        links.push(usuarioInfoResult.value.url_lattes);

      setLinks(links);
    }
  }, [usuarioInfoResult]);

  return (
    <>
      <InfoLinks links={links} />
    </>
  );
}
