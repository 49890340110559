import { Firestore, doc, setDoc } from "@firebase/firestore";

import { Result } from "../typings";
import { Conexao } from "../domain/entities/Conexao";
import { HandlerUpdateAceiteConexao } from "../domain/usecases/interfaces/HandlerUpdateAceiteConexao";

export class HandlerUpdateAceiteConexaoFirebase
  implements HandlerUpdateAceiteConexao
{
  constructor(private firestore: Firestore) {}

  aceitarConexao(
    conexao: Pick<Conexao, "id">,
    aceitar: boolean
  ): Promise<Result<null, Error>> {
    return new Promise((resolve) => {
      const docConexao = doc(this.firestore, "conexoes", conexao.id);
      const docUpdate: Pick<Conexao, "status"> = {
        status: aceitar ? "aprovado" : "negado",
      };

      setDoc(docConexao, docUpdate, {
        merge: true,
      })
        .then(() => {
          resolve({
            ok: true,
            value: null,
          });
        })
        .catch((error) => {
          resolve({
            ok: false,
            error,
          });
        });
    });
  }
}
