import { Firestore, collection, getDocs } from "@firebase/firestore";

import { Result } from "../typings";
import { HandlerGetAreaInvestimento } from "../domain/usecases/interfaces/handlerGetAreaInvestimento";
import { AreaInvestimentoOutput } from "../domain/entities/AreaInvestimento";

export class HandlerGetAreaInvestimentoFirebase
  implements HandlerGetAreaInvestimento
{
  constructor(private firestore: Firestore) {}
  getAllAreaInvestimento(): Promise<Result<AreaInvestimentoOutput[], Error>> {
    return new Promise((resolve) => {
      getDocs(collection(this.firestore, "areas_de_investimento"))
        .then((querySnapshot) => {
          const areas: AreaInvestimentoOutput[] = querySnapshot.docs.map(
            (docSnapshot) => {
              const area = docSnapshot.data() as AreaInvestimentoOutput;
              area.id = docSnapshot.id;
              return area;
            }
          );

          resolve({
            ok: true,
            value: areas,
          });
        })
        .catch((error) =>
          resolve({
            ok: false,
            error,
          })
        );
    });
  }
}
