import { useContext, useEffect } from "react";

import { PessoasRelevantesContext } from "../../../../../globalStore/PessoasRelevantesProvider/PessoasRelevantesContext";
import { InfoCardUsuarios } from "./InfoCardUsuarios";

export function InfoCardUsuariosFromPessoasRelevantesContext() {
  const { pessoasRelevantesResult, refresh } = useContext(
    PessoasRelevantesContext
  );

  useEffect(() => {
    if (refresh) refresh();
  }, [refresh]);

  return (
    <div
      className="card p-4 border-0 rounded-4"
      style={{ height: "fit-content" }}
    >
      <InfoCardUsuarios
        title={"Sugestões SCIENCIN de MATCH"}
        usuarios={
          pessoasRelevantesResult?.ok ? pessoasRelevantesResult.value : []
        }
      />
    </div>
  );
}
