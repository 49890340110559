import { Button, Modal } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";

import { InputPremiacoes } from "../../../generalComponents/InputPremiacoes/InputPremiacoes";
import { HandlersContext } from "../../../../globalStore/HandlersProviders/HandlersContext";
import { UsuarioScyggz } from "../../../../domain/entities/usuarioScyggz";
import { InvestidorAnjo } from "../../../../domain/entities/InvestidorAnjo";
import { InputTeseInvestimento } from "../../../generalComponents/InputTeseInvestimento";
import { InputAreasEspecializacao } from "../../../generalComponents/InputAreasEspecializacao";
import { InputAssociacoesAnjo } from "../../../generalComponents/InputAssociacoesAnjo";
import { InputEstagiosStartup } from "../../../generalComponents/InputEstagiosStartup";
import { InputLocais } from "../../../generalComponents/InputLocais";
import { InputModelosNegocio } from "../../../generalComponents/InputModelosNegocio";
import { InputSegmentosAtuacao } from "../../../generalComponents/InputSegmentosAtuacao";
import { InputMecanismosSolucao } from "../../../generalComponents/InputMecanismosSolucao";
import { InvestidorAnjoValidator } from "../../../../domain/entities/InvestidorAnjoValidator";
import { TypeHandlerCreateInvestidor } from "../../../../domain/usecases/interfaces/HandlerCreateInvestidor";

export function ModalAddInvestidor({
  user,
  show,
  onShowChange,
  onCreate,
}: {
  user: Pick<UsuarioScyggz, "nome" | "auth_user_id">;
  show: boolean;
  onShowChange: (show: boolean) => void;
  onCreate: () => void;
}) {
  const { handlerCreateInvestidor } = useContext(HandlersContext);

  const [investidorToCreate, setInvestidorToCreate] =
    useState<TypeHandlerCreateInvestidor>({
      created_in: new Date(),
      nome: "",
      user_id: "",
      areas_de_especializacao: [],
      interesse_startup_estagio: [],
      interesse_startup_mecanismo_solucao: [],
      interesse_startup_modelo_negocio: [],
      interesse_startup_segmento_atuacao: [],
      tese_de_investimento: {
        conteudo: "",
      },
    });
  const [error, setError] = useState<
    Error | Partial<Record<keyof InvestidorAnjo, Error>>
  >();
  const [carregando, setCarregando] = useState<boolean>(false);

  const [isEditingPremiacoes, setIsEditingPremiacoes] =
    useState<boolean>(false);

  useEffect(() => {
    setInvestidorToCreate((investidorToCreate) => ({
      ...investidorToCreate,
      user_id: user.auth_user_id,
      nome: user.nome,
    }));
  }, [user]);

  useEffect(() => {
    const investidorValidateResult =
      InvestidorAnjo.validateCreate(investidorToCreate);
    if (investidorValidateResult.ok) {
      setError(undefined);
    } else {
      setError(investidorValidateResult.error);
    }
  }, [investidorToCreate]);

  return (
    <Modal
      show={show}
      onHide={() => onShowChange(false)}
      centered
      scrollable={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>Adicionar perfil investidor</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputTeseInvestimento
          label="Tese de Investimento"
          value={investidorToCreate?.tese_de_investimento?.conteudo ?? ""}
          onChange={(tese_de_investimento) => {
            setInvestidorToCreate({
              ...investidorToCreate,
              tese_de_investimento: {
                conteudo: tese_de_investimento,
              },
            });
          }}
          className="mb-2"
        />

        <InputAreasEspecializacao
          label="Áreas de especialização"
          value={investidorToCreate.areas_de_especializacao ?? []}
          onChange={(areas_de_especializacao) => {
            setInvestidorToCreate({
              ...investidorToCreate,
              areas_de_especializacao,
            });
          }}
          validate={InvestidorAnjoValidator.isInvalidAreasEspecializacao}
          className="mb-3"
        />

        <InputAssociacoesAnjo
          label="Associações Anjo"
          value={investidorToCreate.associacoes_anjo ?? []}
          onChange={(associacoes_anjo) => {
            setInvestidorToCreate({
              ...investidorToCreate,
              associacoes_anjo,
            });
          }}
          validate={() => undefined}
          className="mb-3"
        />

        <InputEstagiosStartup
          label="Estágio da startup de interesse*"
          value={investidorToCreate.interesse_startup_estagio ?? []}
          onChange={(interesse_startup_estagio) => {
            setInvestidorToCreate({
              ...investidorToCreate,
              interesse_startup_estagio,
            });
          }}
          validate={InvestidorAnjoValidator.isInvalidInteresseStartupEstagio}
          className="mb-3"
        />

        <InputLocais
          label="Em que localidades as Startups devem estar"
          value={investidorToCreate.interesse_startup_localizacoes ?? []}
          onChange={(interesse_startup_localizacoes) => {
            setInvestidorToCreate({
              ...investidorToCreate,
              interesse_startup_localizacoes,
            });
          }}
          validate={InvestidorAnjoValidator.isInvalidLocais}
          className="mb-3"
        />

        <InputModelosNegocio
          label="Modelos de negócio*"
          value={investidorToCreate.interesse_startup_modelo_negocio ?? []}
          onChange={(interesse_startup_modelo_negocio) => {
            setInvestidorToCreate({
              ...investidorToCreate,
              interesse_startup_modelo_negocio,
            });
          }}
          validate={
            InvestidorAnjoValidator.isInvalidInteresseStartupModeloNegocio
          }
          className="mb-3"
        />

        <InputSegmentosAtuacao
          label="Que atuam nos seguintes segmentos*"
          value={investidorToCreate.interesse_startup_segmento_atuacao ?? []}
          onChange={(interesse_startup_segmento_atuacao) => {
            setInvestidorToCreate({
              ...investidorToCreate,
              interesse_startup_segmento_atuacao,
            });
          }}
          validate={
            InvestidorAnjoValidator.isInvalidInteresseStartupSegmentoAtuacao
          }
          className="mb-3"
        />

        <InputMecanismosSolucao
          label="Que utilizam os seguintes mecanismos de solução tecnológica*"
          value={investidorToCreate.interesse_startup_mecanismo_solucao ?? []}
          onChange={(interesse_startup_mecanismo_solucao) => {
            setInvestidorToCreate({
              ...investidorToCreate,
              interesse_startup_mecanismo_solucao,
            });
          }}
          validate={
            InvestidorAnjoValidator.isInvalidInteresseStartupMecanismoSolucao
          }
          className="mb-3"
        />

        <InputPremiacoes
          label="Premiações"
          value={investidorToCreate.premiacoes_e_certificados ?? []}
          onChange={(premiacoes_e_certificados) => {
            setInvestidorToCreate({
              ...investidorToCreate,
              premiacoes_e_certificados,
            });
          }}
          showTip={false}
          isEditing={setIsEditingPremiacoes}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="success"
          disabled={!!error || carregando || isEditingPremiacoes}
          onClick={async () => {
            setCarregando(true);

            if (handlerCreateInvestidor) {
              const handlerResult =
                await handlerCreateInvestidor.createInvestidor(
                  investidorToCreate
                );

              setCarregando(false);

              if (!handlerResult.ok) {
                console.warn(handlerResult.error);

                setError(handlerResult.error);

                return;
              } else {
                onCreate();
              }
            }

            onShowChange(false);
          }}
        >
          Salvar investidor
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
