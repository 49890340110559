import { ReactNode, useContext, useEffect, useState } from "react";

import { UsuarioScyggz } from "../../domain/entities/usuarioScyggz";
import {
  ConexoesPendentesEnviadasCountContext,
  TypeConexoesPendentesEnviadasCountContext,
} from "./ConexoesPendentesEnviadasCountContext";
import { HandlersDatabaseContext } from "../HandlersProviders/HandlersDatabaseContext";

export function ConexoesPendentesEnviadasCountProvider({
  user,
  children,
}: {
  user: Pick<UsuarioScyggz, "auth_user_id">;
  children: ReactNode;
}) {
  const [
    conexoesPendentesEnviadasCountResult,
    setConexoesPendentesEnviadasCountResult,
  ] = useState<TypeConexoesPendentesEnviadasCountContext>();

  const { handlerConexaoPendentesEnviadasCount } = useContext(
    HandlersDatabaseContext
  );

  useEffect(() => {
    if (handlerConexaoPendentesEnviadasCount)
      handlerConexaoPendentesEnviadasCount
        .getConexoesPendentesEnviadasCount(user)
        .then((result) => {
          setConexoesPendentesEnviadasCountResult(result);
        });
  }, [user, handlerConexaoPendentesEnviadasCount]);

  return (
    <ConexoesPendentesEnviadasCountContext.Provider
      value={{
        conexoesPendentesEnviadasCountResult,
      }}
      children={children}
    />
  );
}
