import { Firestore, collection, getDocs } from "@firebase/firestore";

import { Result } from "../typings";
import { HandlerGetSetorAtuacao } from "../domain/usecases/interfaces/handlerGetSetorAtuacao";
import { SetorAtuacaoOutput } from "../domain/entities/SetorAtuacao";

export class HandlerGetSetorAtuacaoFirebase implements HandlerGetSetorAtuacao {
  constructor(private firestore: Firestore) {}

  getAllSetorAtuacao(): Promise<Result<SetorAtuacaoOutput[], Error>> {
    return new Promise((resolve) => {
      getDocs(collection(this.firestore, "setores_atuacao"))
        .then((querySnapshot) => {
          const setores: SetorAtuacaoOutput[] = querySnapshot.docs.map(
            (docSnapshot) => {
              const setor = docSnapshot.data() as SetorAtuacaoOutput;
              setor.id = docSnapshot.id;
              return setor;
            }
          );

          resolve({
            ok: true,
            value: setores,
          });
        })
        .catch((error) =>
          resolve({
            ok: false,
            error,
          })
        );
    });
  }
}
