import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { CurrentUserContext } from "../../../../../globalStore/CurrentUserContext";
import { ConteudoCadastroInfosPessoais } from "../../components/ConteudoCadastroInfosPessoais";
import { HandlerSaveUrlLinkedin } from "../../../../../domain/usecases/interfaces/handlerSaveUrlLinkedin";
import { HandlerSaveTelefone } from "../../../../../domain/usecases/interfaces/handlerSaveTelefone";
import { HandlerCreateUsuarioScyggz } from "../../../../../domain/usecases/interfaces/handlerCreateUsuarioScyggz";
import { OutputAuthEntity } from "../../../../../domain/entities/Auth";
import { getNomeFromStorage } from "../../../../../hooks/handlerNameFromStorage";
import { useIsMounted } from "../../../../../hooks/useIsMounted";
import { ContainerResponsive } from "../../../../generalComponents/ContainerResponsive";
import { HandlersDatabaseContext } from "../../../../../globalStore/HandlersProviders/HandlersDatabaseContext";

export function PainelCadastroUsuarioNit({
  handlerSaveUrlLinkedin,
  handlerSaveTelefone,
  handlerCreateUsuarioScyggz,
}: {
  handlerSaveUrlLinkedin: HandlerSaveUrlLinkedin;
  handlerSaveTelefone: HandlerSaveTelefone;
  handlerCreateUsuarioScyggz: HandlerCreateUsuarioScyggz;
}) {
  const navigate = useNavigate();
  const isMounted = useIsMounted();

  const { handlerAceiteGet } = useContext(HandlersDatabaseContext);
  const { currentUserScyggz, currentUserAuth } = useContext(CurrentUserContext);

  const [dataAceite, setDataAceite] = useState<Date>(new Date());
  const [currentAuth, setCurrentAuth] = useState<OutputAuthEntity>();

  useEffect(() => {
    if (handlerAceiteGet)
      handlerAceiteGet.get("nit").then((handlerAceiteResult) => {
        if (handlerAceiteResult.ok) {
          if (!handlerAceiteResult.value) {
            navigate("/cadastro/nit/1");
          } else {
            if (isMounted) setDataAceite(handlerAceiteResult.value);
          }
        }
      });
  }, [handlerAceiteGet, isMounted, navigate]);

  useEffect(() => {
    if (currentUserScyggz.status == "encontrado" && currentUserScyggz.user) {
      navigate("/cadastro/nit/4");
    }
  }, [currentUserScyggz, navigate]);

  useEffect(() => {
    if (currentUserAuth.status == "carregado" && currentUserAuth.user_auth) {
      setCurrentAuth(currentUserAuth.user_auth);
    }
  }, [currentUserAuth]);

  const paginacaoNit = {
    start: 3,
    end: 6,
  };

  return (
    <section data-testid="painel-cadastro-usuario-nit" className="h-100">
      <ContainerResponsive
        backUrlMobile="/cadastro/nit/1"
        paginacao={paginacaoNit}
        textoMarcaDagua="Nit"
        showLogo={true}
      >
        <ConteudoCadastroInfosPessoais
          inputPainelCadastroInfosPessoais={{
            auth_id: currentAuth?.auth_id ?? "",
            nome: currentAuth?.displayName ?? getNomeFromStorage() ?? "",
            aceite_termo_de_servico: dataAceite,
            aceite_politica_de_privacidade: dataAceite,
            phoneNumber: undefined,
            cidade: undefined,
            urlLinkedin: undefined,
            headline: undefined,
          }}
          labelHeadline={"Seu cargo/função na Nit"}
          handlerSaveUrlLinkedin={handlerSaveUrlLinkedin}
          handlerSaveTelefone={handlerSaveTelefone}
          handlerCreateUsuarioScyggz={handlerCreateUsuarioScyggz}
          onCadastro={function () {
            navigate("/cadastro/nit/4");
          }}
        />
      </ContainerResponsive>
    </section>
  );
}
