import { createContext } from "react";

import { InstituicaoDeCienciaETecnologiaOutput } from "../../domain/entities/instituicaoDeCienciaETecnologia";
import { Result } from "../../typings";

export const InstituicaoContext = createContext<{
  allInstituicaoResult:
    | Result<InstituicaoDeCienciaETecnologiaOutput[]>
    | undefined;
  refresh: (() => void) | undefined;
}>({
  allInstituicaoResult: undefined,
  refresh: undefined,
});
