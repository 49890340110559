import { useContext, useEffect, useState } from "react";

import { HandlersStorageContext } from "../../globalStore/HandlersProviders/HandlersStorageContext";
import { HandlerGetURLFromPathResult } from "../../domain/usecases/interfaces/HandlerGetURLFromPath";

export function ImgFromPath({
  path,
  className,
  style,
  alt,
  elementErro,
}: {
  path: string;
  className?: string;
  style?: React.CSSProperties;
  alt: string;
  elementErro?: JSX.Element;
}): JSX.Element | null {
  const { handlerGetURLFromPath } = useContext(HandlersStorageContext);

  const [urlResult, setUrlResult] = useState<HandlerGetURLFromPathResult>();

  useEffect(() => {
    handlerGetURLFromPath?.getURL(path).then((result) => {
      setUrlResult(result);
    });
  }, [handlerGetURLFromPath, path]);

  return (
    <>
      {urlResult ? (
        <>
          {urlResult.ok ? (
            <img
              src={urlResult.value}
              alt={alt}
              className={className}
              style={style}
            />
          ) : (
            elementErro
          )}
        </>
      ) : (
        <div className="placeholder-glow">
          <div
            className={"placeholder h-100 w-100 " + (className || "")}
            style={style}
          ></div>
        </div>
      )}
    </>
  );
}
