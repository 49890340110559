import { ref, getBlob } from "@firebase/storage";
import { FirebaseError } from "firebase/app";

import { FirebaseService } from "../infra/firebase/firebaseService";
import {
  StorageError,
  StorageErrorsFactory,
} from "../domain/erros/StorageErrors";
import { Result } from "../typings";

export function getFileFromPathStorage(path: string): Promise<Result<File>> {
  return new Promise((resolve) => {
    const firebaseService = FirebaseService.newFirebaseService();
    const storage = firebaseService.getStorage();

    const pathReference = ref(storage, "/" + path);

    getBlob(pathReference)
      .then((blob) => {
        resolve({
          ok: true,
          value: new File([blob], pathReference.name),
        });
      })
      .catch((error: FirebaseError) => {
        resolve({
          ok: false,
          error: StorageErrorsFactory.createErrorsValidation(
            <StorageError>error.code,
            path
          ),
        });
      });
  });
}
