import Select, { StylesConfig } from "react-select";
import { useContext, useEffect, useState } from "react";
import makeAnimated from "react-select/animated";

import { ResizeContext } from "../../hooks/useResize";
import {
  ModeloNegocio,
  ModeloNegocioOutput,
} from "../../domain/entities/modeloNegocio";
import { HandlersDatabaseContext } from "../../globalStore/HandlersProviders/HandlersDatabaseContext";

const animatedComponents = makeAnimated();

export function InputModelosNegocio({
  label,
  value,
  onChange,
  validate,
  className,
}: {
  label: string;
  value: ModeloNegocio[];
  onChange: (modelosNegocio: ModeloNegocio[]) => void;
  validate: (modelosNegocio: ModeloNegocio[]) => Error | undefined;
  className?: string;
}) {
  const { handlerGetModeloNegocio } = useContext(HandlersDatabaseContext);
  const { responsive } = useContext(ResizeContext);

  const [allModeloNegocio, setAllModeloNegocio] = useState<
    ModeloNegocioOutput[]
  >([]);
  const [isAllModeloNegocioLoading, setIsAllModeloNegocioLoading] =
    useState<boolean>(false);

  const [error, setError] = useState<string>();
  const [isChanged, setIsChanged] = useState<boolean>(false);

  useEffect(() => {
    const error = validate(value);
    if (error) {
      setError(error.message);
    } else {
      setError(undefined);
    }
  }, [validate, value]);

  useEffect(() => {
    if (handlerGetModeloNegocio) {
      setIsAllModeloNegocioLoading(true);
      handlerGetModeloNegocio
        .getAllModeloNegocio()
        .then((handlerGetModeloNegocioResult) => {
          if (handlerGetModeloNegocioResult.ok)
            setAllModeloNegocio(handlerGetModeloNegocioResult.value);

          setIsAllModeloNegocioLoading(false);
        });
    }
  }, [handlerGetModeloNegocio]);

  const customStylesSelect: StylesConfig<ModeloNegocio, true> = {
    control: () => ({
      border: "none",
      borderBottom: "3px #666600 solid",
      display: "flex",
      background: " #fff",
      borderBottomLeftRadius: "8px",
      textAlign: "left",
    }),
  };

  return (
    <div className={className}>
      <label className="form-label fw-bolder m-0" htmlFor="selModelosNegocio">
        {label}
      </label>
      <Select<ModeloNegocio, true>
        isLoading={isAllModeloNegocioLoading}
        options={allModeloNegocio}
        styles={responsive.sm ? customStylesSelect : undefined}
        getOptionLabel={(o) => o.nome}
        getOptionValue={(o) => o.id}
        components={animatedComponents}
        isMulti
        value={value}
        placeholder=""
        onChange={(modelos_negocio) => {
          onChange([...modelos_negocio]);
          setIsChanged(true);
        }}
        onBlur={() => setIsChanged(true)}
        className={error && isChanged ? "is-invalid " : ""}
        noOptionsMessage={() => "sem opção"}
        inputId="selModelosNegocio"
      />

      <div className="invalid-feedback flex-fill">{error}</div>
    </div>
  );
}
