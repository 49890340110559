import { createContext } from "react";

import { HandlerUpdateAceiteConexao } from "../../domain/usecases/interfaces/HandlerUpdateAceiteConexao";
import { HandlerConexaoCreate } from "../../domain/usecases/interfaces/HandlerConexaoCreate";

export const HandlerConexaoContext = createContext<{
  handlerUpdateAceiteConexao: HandlerUpdateAceiteConexao | undefined;
  handlerCreateConexao: HandlerConexaoCreate | undefined;
}>({
  handlerUpdateAceiteConexao: undefined,
  handlerCreateConexao: undefined,
});
