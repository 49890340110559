import { useNavigate } from "react-router";

import { ConteudoCadastro } from "../../components/ConteudoCadastro";
import { ContainerResponsive } from "../../../../generalComponents/ContainerResponsive";

export function PainelCadastroAuthHub() {
  const navigate = useNavigate();

  const paginacaoElegibilidadeHub = {
    start: 2,
    end: 6,
  };

  return (
    <section data-testid="painel-cadastro-auth-hub" className="h-100">
      <ContainerResponsive
        backUrlMobile="/cadastro/hub/1"
        paginacao={paginacaoElegibilidadeHub}
        showLogo={true}
      >
        <ConteudoCadastro
          onRedirect={() => {
            navigate("/cadastro/hub/3");
          }}
          onCadastro={() => {
            navigate("/cadastro/hub/3");
          }}
        />
      </ContainerResponsive>
    </section>
  );
}
