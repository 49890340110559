import { Firestore, collection, getDocs } from "@firebase/firestore";

import { Result } from "../typings";
import { HandlerGetAreaConhecimento } from "../domain/usecases/interfaces/handlerGetAreaConhecimento";
import { AreaConhecimentoOutput } from "../domain/entities/areaConhecimento";

export class HandlerGetAreaConhecimentoFirebase
  implements HandlerGetAreaConhecimento
{
  constructor(private firestore: Firestore) {}
  getAllAreaConhecimento(): Promise<Result<AreaConhecimentoOutput[], Error>> {
    return new Promise((resolve) => {
      getDocs(collection(this.firestore, "areas_de_conhecimento"))
        .then((querySnapshot) => {
          const areas: AreaConhecimentoOutput[] = querySnapshot.docs.map(
            (docSnapshot) => {
              const area = docSnapshot.data() as AreaConhecimentoOutput;
              area.id = docSnapshot.id;
              return area;
            }
          );

          resolve({
            ok: true,
            value: areas,
          });
        })
        .catch((error) =>
          resolve({
            ok: false,
            error,
          })
        );
    });
  }
}
