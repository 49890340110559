import { useContext } from "react";

import { ConexoesAprovadasProvider } from "../../../../globalStore/conexoesAprovadas/ConexoesAprovadasProvider";
import { CurrentUserContext } from "../../../../globalStore/CurrentUserContext";
import HTMLHead from "../../../generalComponents/HTMLHead";
import { ListConexoesAprovadas } from "../components/ListConexoesAprovadas";

export function ConexoesAprovadas() {
  const { currentUserScyggz } = useContext(CurrentUserContext);

  return currentUserScyggz.user ? (
    <>
      <HTMLHead
        title="Conexões Aprovadas"
        description="Minhas Conexões Aprovadas"
      />
      <ConexoesAprovadasProvider user={currentUserScyggz.user}>
        <ListConexoesAprovadas user={currentUserScyggz.user} />
      </ConexoesAprovadasProvider>
    </>
  ) : (
    <></>
  );
}
