import { faPencil, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { TecnologiaDoCientista } from "../../../domain/entities/tecnologiaDoCientista";

export function ItemTecnologia({
  tecnologia,
  onClickEdit,
  onClickDelete,
}: {
  tecnologia: TecnologiaDoCientista;
  onClickEdit: () => void;
  onClickDelete: () => void;
}) {
  return (
    <div className="d-flex align-items-center rounded bg-success text-white px-2 mb-2">
      <div className="flex-fill text-truncate">
        <p className="fw-bold m-0">{tecnologia.nome}</p>
        <p className="fw-lighter m-0 lh-sm text-truncate">
          <small> {tecnologia.resumo}</small>
        </p>
      </div>

      <button className="btn text-reset" onClick={onClickEdit}>
        <FontAwesomeIcon icon={faPencil} />
      </button>

      <button className="btn text-reset fs-4" onClick={onClickDelete}>
        <FontAwesomeIcon icon={faTimes} />
      </button>
    </div>
  );
}
