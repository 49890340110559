import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faUserPlus } from "@fortawesome/free-solid-svg-icons";

import { UserContentPage } from "../../../user/components/UserContentPage";
import { UseUserPerfilType } from "../../../../../hooks/useUserPerfil";
import { UsuarioScyggz } from "../../../../../domain/entities/usuarioScyggz";

export function ModalShowUser({
  show,
  onShowChange,
  baseUrl,
  userPerfil,
  current_user_auth_id_conexoes,
  isCurrentUser,
  onClickConnect,
}: {
  show: boolean;
  onShowChange: (show: boolean) => void;
  baseUrl: string;
  userPerfil?: UseUserPerfilType;
  current_user_auth_id_conexoes?: string[];
  isCurrentUser: boolean;
  onClickConnect?: (
    alvo: Pick<UsuarioScyggz, "auth_user_id" | "nome" | "avatar_path">
  ) => void;
}) {
  return (
    <Modal
      show={show}
      onHide={() => onShowChange(false)}
      centered
      size="lg"
      scrollable={true}
      className="bg-transparent"
      contentClassName="bg-transparent border-0 rounded-4"
    >
      <Modal.Header
        className="position-absolute border-0 top-0 end-0"
        style={{
          zIndex: 10,
        }}
      >
        {onClickConnect && userPerfil?.ok && (
          <button
            onClick={() =>
              onClickConnect({
                auth_user_id: userPerfil.value.user.auth_user_id,
                nome: userPerfil.value.user.nome,
                avatar_path: userPerfil.value.user.avatar_path,
              })
            }
            className="btn text-white d-flex justify-content-center align-items-center background-color-spinoff me-3"
          >
            <FontAwesomeIcon icon={faUserPlus} className="me-2" />
            Conectar
          </button>
        )}

        <button
          onClick={() => onShowChange(false)}
          className="btn btn-light text-reset fs-4 d-flex justify-content-center align-items-center"
          style={{
            width: 38,
            height: 38,
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </Modal.Header>
      <Modal.Body className="p-0 bg-transparent">
        <UserContentPage
          baseUrl={baseUrl}
          isCurrentUser={isCurrentUser}
          current_user_auth_id_conexoes={current_user_auth_id_conexoes}
          userPerfil={userPerfil}
          className="backgroundDefault"
        />
      </Modal.Body>
    </Modal>
  );
}
