import { Link } from "react-router-dom";

import Avatar from "../../../../assets/images/avatarLight.jpeg";
import { ImgFromPath } from "../../../generalComponents/ImgFromPath";

export const GerenciarOrganizacoesSection = ({
  title,
  baseLink,
  organizacoes,
}: {
  title: string;
  baseLink: string;
  organizacoes: { id: string; nome: string; path_logo?: string }[];
}) => {
  return (
    <>
      {organizacoes.length > 0 && (
        <div className="card mb-1">
          <div className="card-body p-1">
            <h6 className="card-subtitle mb-2 text-muted text-center">
              {title}
            </h6>

            {organizacoes.map((organizacao) => (
              <Link
                to={baseLink + "/" + organizacao.id}
                key={organizacao.id}
                style={{ color: "black" }}
              >
                <div className="row align-items-center mb-2">
                  <div className="col-3">
                    <div className="p-0 ratio ratio-1x1">
                      {organizacao.path_logo ? (
                        <ImgFromPath
                          path={organizacao.path_logo}
                          className="rounded-circle border-white object-fit-cover"
                          alt={organizacao.nome}
                        />
                      ) : (
                        <img
                          className="rounded-circle border-white object-fit-cover"
                          src={Avatar}
                        />
                      )}
                    </div>
                  </div>
                  <div className="card-text fw-bolder col-9">
                    {organizacao.nome}
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
