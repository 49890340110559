import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { TipoCadastro } from "../domain/entities/TipoCadastro";
import { HandlersDatabaseContext } from "../globalStore/HandlersProviders/HandlersDatabaseContext";

export function RouteGuardAceite({
  required,
  redirectPath,
  children,
}: {
  required: TipoCadastro;
  redirectPath: string;
  children: JSX.Element | JSX.Element[];
}) {
  const navigate = useNavigate();

  const { handlerAceiteGet } = useContext(HandlersDatabaseContext);

  const [toShow, setToShow] = useState<JSX.Element | JSX.Element[]>([]);

  useEffect(() => {
    if (handlerAceiteGet)
      handlerAceiteGet.get(required).then((getAceiteResult) => {
        if (getAceiteResult.ok) {
          if (getAceiteResult.value == null) {
            navigate(redirectPath);
          } else {
            setToShow(children);
          }
        }
      });
  }, [children, handlerAceiteGet, navigate, redirectPath, required]);

  return <>{toShow}</>;
}
