import { useParams } from "react-router-dom";

import { PerfilNitFirebaseProvider } from "../../../globalStore/perfilNit/PerfilNitFirebaseProvider";
import { PerfilInfoNitFromContext } from "./PerfilInfoNitFromContext";

export function PainelNit() {
  const { id_nit } = useParams();

  if (!id_nit) {
    return <>...</>;
  }

  return (
    <PerfilNitFirebaseProvider
      nit={{
        id: id_nit,
      }}
    >
      <PerfilInfoNitFromContext />
    </PerfilNitFirebaseProvider>
  );
}
