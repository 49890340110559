import { useEffect, useState } from "react";

import { Premiacao } from "../../../domain/entities/premiacao";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPlus } from "@fortawesome/free-solid-svg-icons";

export function InputAddPremiacao({
  onAdd,
  onCancel,
  onChange,
}: {
  onAdd: (premiacao: Premiacao) => void;
  onCancel: () => void;
  onChange: () => void;
}) {
  const [changed, setChanged] = useState<boolean>(false);
  const [premiacao, setPremiacao] = useState<Premiacao>({
    nome: "",
    descricao: "",
  });
  const [errors, setErrors] =
    useState<Partial<Record<keyof Premiacao, string>>>();

  useEffect(() => {
    const premiacaoResult = Premiacao.validate(premiacao);

    if (premiacaoResult.ok) {
      setErrors(undefined);
    } else {
      const error = premiacaoResult.error;

      setErrors({
        nome: error.nome?.message,
        descricao: error.descricao?.message,
      });
    }
  }, [premiacao]);

  useEffect(() => {
    if (changed) onChange();
  }, [changed, onChange]);

  return (
    <div className="shadow p-2 m-3 rounded bg-body-tertiary border">
      {/* <h6 className="text-success">
        <FontAwesomeIcon icon={faPlus} className="me-2" />
        Adicionando
      </h6> */}

      <label className="form-label" htmlFor="iptNomePremiacao">
        Nome*
      </label>

      <input
        title="Nome da premiação"
        className={
          "form-control" + (changed && errors?.nome ? " is-invalid" : "")
        }
        type="text"
        value={premiacao.nome}
        onChange={({ target: { value: nomeValue } }) => {
          premiacao.nome = nomeValue;
          setPremiacao({ ...premiacao });
          setChanged(true);
        }}
        id="iptNomePremiacao"
      />

      <div className="d-flex justify-content-between">
        <div className="text-danger flex-fill" style={{ fontSize: ".85rem" }}>
          {changed && <>{errors?.nome}</>}
        </div>

        <span className="text-black-50 fw-lighter">
          {premiacao.nome.trim().length}/{Premiacao.NOME_MAXIMO_LENGTH}
        </span>
      </div>

      <label className="form-label" htmlFor="iptDescricaoPremiacao">
        Descrição
      </label>

      <textarea
        className={
          "form-control" + (changed && errors?.descricao ? " is-invalid" : "")
        }
        title="Descrição da premiação"
        rows={5}
        value={premiacao.descricao}
        onChange={({ target: { value: descricaoValue } }) => {
          premiacao.descricao = descricaoValue;
          setPremiacao({ ...premiacao });
          setChanged(true);
        }}
        id="iptDescricaoPremiacao"
      />

      <div className="d-flex justify-content-between">
        <div className="text-danger flex-fill" style={{ fontSize: ".85rem" }}>
          {changed && <>{errors?.descricao}</>}
        </div>

        <span className="text-black-50 fw-lighter">
          {premiacao.descricao.trim().length}/
          {Premiacao.DESCRICAO_MAXIMO_LENGTH}
        </span>
      </div>

      <button
        type="button"
        title="salvar premiação"
        className={
          "btn fw-bolder me-2" +
          (!errors ? " btn-outline-success" : " btn-outline-secondary")
        }
        onClick={() => onAdd(premiacao)}
        disabled={!!errors}
      >
        Salvar
      </button>

      <button
        type="button"
        className="btn btn-outline-warning fw-bolder"
        onClick={onCancel}
      >
        Cancelar
      </button>
    </div>
  );
}
