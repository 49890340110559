import { useNavigate } from "react-router-dom";

import { ContainerResponsive } from "../../../../generalComponents/ContainerResponsive";
import { ConteudoCadastro } from "../../components/ConteudoCadastro";

export const PainelCadastroAuthStartup = () => {
  const navigate = useNavigate();

  const paginacaoElegibilidadeStartup = {
    start: 2,
    end: 7,
  };

  return (
    <section className="h-100" data-testid="painel-cadastro-auth-startup">
      <ContainerResponsive
        paginacao={paginacaoElegibilidadeStartup}
        backUrlMobile="/cadastro/startup/1"
        showLogo={true}
      >
        <ConteudoCadastro
          onRedirect={() => {
            navigate("/cadastro/startup/3");
          }}
          onCadastro={() => {
            navigate("/cadastro/startup/3");
          }}
        />
      </ContainerResponsive>
    </section>
  );
};
