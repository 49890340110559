import { useMemo } from "react";

export function InputRadios({
  label,
  subLabel,
  value = "",
  options,
  onChange,
  id,
  title,
  className = "",
}: {
  label: string;
  subLabel?: string;
  value?: string;
  options: { label: string; value: string }[];
  onChange: (value: string) => void;
  id: string;
  title: string;
  className?: string;
}) {
  const valueCheck = useMemo<string | undefined>(() => {
    let valueCheck = options.find((option) => option.value == value);

    if (!valueCheck) {
      valueCheck = options.at(0);
    }

    return valueCheck?.value;
  }, [options, value]);

  return (
    <div className={className} title={title}>
      <label className="form-label fw-bolder m-0" htmlFor={id}>
        {label}
      </label>
      {subLabel && (
        <label className="form-label m-0 text-muted d-block mb-2">
          {subLabel}
        </label>
      )}

      {options.map((option) => (
        <div className="form-check" key={option.value}>
          <input
            className="form-check-input"
            type="radio"
            name={label}
            checked={valueCheck == option.value}
            id={"rdb" + label + option.value}
            onChange={({ target: { value } }) => {
              if (value) onChange(option.value);
            }}
          />
          <label
            className="form-check-label"
            htmlFor={"rdb" + label + option.value}
          >
            {option.label}
          </label>
        </div>
      ))}
    </div>
  );
}
