import { ReactNode, useCallback, useContext, useEffect, useState } from "react";

import { Startup } from "../../domain/entities/Startup";
import {
  PerfilStartupContext,
  TypePerfilStartupContext,
} from "./PerfilStartupContext";
import { HandlersDatabaseContext } from "../HandlersProviders/HandlersDatabaseContext";

export function PerfilStartupProvider({
  startup,
  children,
}: {
  startup: Pick<Startup, "id">;
  children: ReactNode;
}) {
  const { handlerGetStartup } = useContext(HandlersDatabaseContext);

  const [perfilStartupResult, setPerfilStartupResultResult] =
    useState<TypePerfilStartupContext>();

  const refreshStartup = useCallback(() => {
    if (handlerGetStartup)
      handlerGetStartup.getStartup(startup.id).then((result) => {
        setPerfilStartupResultResult(result);
      });
  }, [handlerGetStartup, startup.id]);

  useEffect(() => {
    refreshStartup();
  }, [refreshStartup]);

  return (
    <PerfilStartupContext.Provider
      value={{
        perfilStartupResult,
        refreshStartup,
      }}
      children={children}
    />
  );
}
