import { ref, uploadBytes, FirebaseStorage } from "firebase/storage";
import { doc, setDoc, Firestore } from "firebase/firestore";

import { Result } from "../typings";
import { HandlerSaveLogoEmpresa } from "../domain/usecases/interfaces/handlerSaveLogoEmpresa";
import {
  InputLogoEmpresa,
  LogoEmpresa,
  OutputLogoEmpresa,
} from "../domain/entities/LogoEmpresa";
import { OutputEmpresa } from "../domain/entities/Empresa";

export class HandlerSaveLogoEmpresaFirebase implements HandlerSaveLogoEmpresa {
  constructor(
    private firebaseStorage: FirebaseStorage,
    private firestore: Firestore
  ) {}

  async save(
    inputLogoEmpresa: InputLogoEmpresa
  ): Promise<Result<OutputLogoEmpresa>> {
    const logoEmpresaResult = LogoEmpresa.create(inputLogoEmpresa);

    if (!logoEmpresaResult.ok) return logoEmpresaResult;

    const storageRef = ref(this.firebaseStorage, logoEmpresaResult.value.path);

    return new Promise((resolve) => {
      uploadBytes(storageRef, logoEmpresaResult.value.file)
        .then(async () => {
          const docEmpresa = doc(
            this.firestore,
            "empresas",
            logoEmpresaResult.value.id_empresa
          );

          const logo: Pick<OutputEmpresa, "path_logo"> = {
            path_logo: storageRef.fullPath,
          };

          await setDoc(docEmpresa, logo, { merge: true });

          resolve(logoEmpresaResult);
        })
        .catch((error) =>
          resolve({
            ok: false,
            error,
          })
        );
    });
  }
}
