import { useNavigate } from "react-router-dom";

import { HandlerCacheHub } from "../../../../../domain/usecases/interfaces/HandlerCacheHub";
import { HandlerCreateHub } from "../../../../../domain/usecases/interfaces/handlerCreateHub";
import { HandlerGetNit } from "../../../../../domain/usecases/interfaces/handlerGetNit";
import { ContainerResponsive } from "../../../../generalComponents/ContainerResponsive";
import { ConteudoSobreHub } from "./ConteudoSobreHub";

export const PainelCadastroSobreHub = ({
  handlerCacheHub,
  handlerGetNit,
  handlerCreateHub,
}: {
  handlerCacheHub: HandlerCacheHub;
  handlerGetNit: HandlerGetNit;
  handlerCreateHub: HandlerCreateHub;
}) => {
  const navigate = useNavigate();

  const paginacaoHub = {
    start: 5,
    end: 6,
  };

  return (
    <section style={{ height: "100%" }}>
      <ContainerResponsive
        backUrlMobile="/cadastro/hub/4"
        paginacao={paginacaoHub}
        textoMarcaDagua="Hub"
        showLogo={true}
      >
        <ConteudoSobreHub
          handlerCreateHub={handlerCreateHub}
          handlerGetNit={handlerGetNit}
          handlerCacheHub={handlerCacheHub}
          onNext={() => {
            navigate("/cadastro/hub/6");
          }}
          onBack={() => {
            navigate("/cadastro/hub/4");
          }}
        />
      </ContainerResponsive>
    </section>
  );
};
