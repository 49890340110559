import { ref, uploadBytes, FirebaseStorage } from "firebase/storage";
import { doc, setDoc, Firestore } from "firebase/firestore";

import { Result } from "../typings";
import { HandlerSaveLogoNit } from "../domain/usecases/interfaces/handlerSaveLogoNit";
import {
  InputLogoNit,
  LogoNit,
  OutputLogoNit,
} from "../domain/entities/LogoNit";
import { OutputNit } from "../domain/entities/Nit";

export class HandlerSaveLogoNitFirebase implements HandlerSaveLogoNit {
  constructor(
    private firebaseStorage: FirebaseStorage,
    private firestore: Firestore
  ) {}

  async save(inputLogoNit: InputLogoNit): Promise<Result<OutputLogoNit>> {
    const logoNitResult = await LogoNit.create(inputLogoNit);

    if (!logoNitResult.ok) return logoNitResult;

    const storageRef = ref(this.firebaseStorage, logoNitResult.value.path);

    return new Promise((resolve) => {
      uploadBytes(storageRef, logoNitResult.value.file)
        .then(async () => {
          const docNit = doc(
            this.firestore,
            "nits",
            logoNitResult.value.id_nit
          );

          const logo: Pick<OutputNit, "logo_path"> = {
            logo_path: storageRef.fullPath,
          };

          await setDoc(docNit, logo, { merge: true });

          resolve(logoNitResult);
        })
        .catch((error) =>
          resolve({
            ok: false,
            error,
          })
        );
    });
  }
}
