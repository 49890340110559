import { ReactNode, useMemo, useState } from "react";

import { OutputStartup } from "../../../domain/entities/Startup";
import { CardHeader } from "../user/CardHeader";
import CardHeaderContent from "../user/CardHeaderContent";
import { AbasLinks } from "../user/components/AbasLinks";
import { AbasWrapper } from "../user/components/AbasWrapper";
import { SubPageStartup } from "./SubPageStartup";
import { useLocation } from "react-router-dom";
import { SubPagePessoas } from "./SubPagePessoas";
import { HandlerSaveLogoStartup } from "../../../domain/usecases/interfaces/handlerSaveLogoStartup";

type PerfilInfoStartupProps = {
  startup: OutputStartup;
  currentUserIsGestor: boolean;
  handlerSaveLogoStartup?: HandlerSaveLogoStartup;
};

export default function PerfilInfoStartup({
  startup,
  currentUserIsGestor,
  handlerSaveLogoStartup,
}: PerfilInfoStartupProps) {
  const location = useLocation();

  const { id, nome, cidade, path_logo, habilitado } = useMemo(
    () => startup,
    [startup]
  );
  const activePath = useMemo<string>(
    () => location.pathname,
    [location.pathname]
  );
  const pathStartupMain = useMemo<string>(
    () => "/startup/" + startup.id,
    [startup.id]
  );
  const pathStartupPessoas = useMemo<string>(
    () => "/startup/" + startup.id + "/pessoas",
    [startup.id]
  );

  const [seeAsPublic, setSeeAsPublic] = useState(false);

  const activePathComponent = useMemo<ReactNode>(() => {
    switch (activePath) {
      case pathStartupPessoas:
        return (
          <SubPagePessoas
            startup={startup}
            currentUserIsGestor={currentUserIsGestor}
            seeAsPublic={seeAsPublic}
          />
        );
    }

    return (
      <SubPageStartup
        startup={startup}
        currentUserIsGestor={currentUserIsGestor}
        seeAsPublic={seeAsPublic}
      />
    );
  }, [
    activePath,
    currentUserIsGestor,
    pathStartupPessoas,
    startup,
    seeAsPublic,
  ]);

  return (
    <section className="container py-3 d-flex flex-column flex-sm-row gap-3">
      <div className="col-12 col-sm-9 d-flex flex-column gap-3">
        <CardHeader>
          <CardHeaderContent
            html_title={""}
            html_description={""}
            infoResult={{
              ok: true,
              value: {
                id,
                headline: "",
                isHabilitado: habilitado,
              },
            }}
            isGestor={currentUserIsGestor}
            handlerSaveLogoStartup={handlerSaveLogoStartup}
            seeAsPublic={seeAsPublic}
            onChangeSeeAsPublic={(aspublic) => setSeeAsPublic(aspublic)}
            path_logo={path_logo}
            nome={nome}
            cidade={cidade}
          />
        </CardHeader>

        <div>
          <div className="ps-4">
            <AbasWrapper>
              <AbasLinks
                links={[
                  {
                    label: "Startup",
                    path: pathStartupMain,
                  },
                  {
                    label: "Time",
                    path: pathStartupPessoas,
                  },
                ]}
                activePath={activePath}
              />
            </AbasWrapper>
          </div>

          <div className="position-relative bg-white shadow-sm rounded-4 p-4 bt-0">
            {activePathComponent}
          </div>
        </div>
      </div>
    </section>
  );
}
