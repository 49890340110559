import "react-phone-input-2/lib/style.css";
import { useContext, useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";

import { ResizeContext } from "../../hooks/useResize";

function isPhoneNumberValid(value: string | undefined, mask: string): boolean {
  if (!value) return false;

  const lengthPhoneNumber: number = value.length ?? 0;

  let qtdeExigidaNumeros = 0;
  for (let i = 0; i < mask.length; i++)
    if (mask[i] == ".") qtdeExigidaNumeros++;

  return lengthPhoneNumber == qtdeExigidaNumeros;
}

export function InputTelefone({
  label,
  value,
  onChange,
  validate,
  className = "",
}: {
  label: string;
  value?: TypeInputTelefone;
  onChange: (tel: TypeInputTelefone) => void;
  validate: (numero: string | undefined) => Error | undefined;
  className?: string;
}) {
  const { responsive } = useContext(ResizeContext);

  const [error, setError] = useState<string>();
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [mask, setMask] = useState<string>("");

  useEffect(() => {
    if (isPhoneNumberValid(value?.numero, mask)) {
      setError(undefined);
    } else {
      if (value?.numero) {
        setError("inválido");
      } else {
        const validateErro = validate(value?.numero);
        if (validateErro) setError(validateErro.message);
        else setError(undefined);
      }
    }
  }, [mask, onChange, validate, value]);

  return (
    <div className={className}>
      <label
        className={
          "form-label fw-bolder m-0" + (error && isChanged ? " is-invalid" : "")
        }
        htmlFor="phoneNumber"
      >
        {label}
      </label>

      <PhoneInput
        inputProps={{ id: "phoneNumber", title: "Telefone" }}
        placeholder={mask}
        preferredCountries={["br"]}
        buttonStyle={
          responsive.sm
            ? {
                border: "none",
                background: "none",
                marginLeft: "9px",
                marginBottom: "3px",
              }
            : {}
        }
        inputStyle={{
          border: responsive.sm ? "none" : "",
          borderBottom: responsive.sm ? "3px #666600 solid" : "",
          background: responsive.sm ? " #fff" : "",
          borderBottomLeftRadius: responsive.sm ? "8px" : "",
          boxShadow: responsive.sm ? "none" : "",
        }}
        country={"br"}
        inputClass="w-100"
        isValid={() => !error || !isChanged}
        onChange={(phoneNumber, countryData) => {
          if ("format" in countryData) {
            setMask(countryData.format);

            const tel: TypeInputTelefone = {
              numero: phoneNumber,
              invalid: !isPhoneNumberValid(phoneNumber, countryData.format),
            };

            onChange(tel);
          }
          setIsChanged(true);
        }}
        onBlur={() => {
          setIsChanged(true);
        }}
        value={value?.numero}
      />

      <div className="invalid-feedback flex-fill">{error}</div>
    </div>
  );
}

export type TypeInputTelefone = {
  numero: string;
  invalid: boolean;
};
