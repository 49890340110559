import { useContext } from "react";
import { ProgressBar } from "react-bootstrap";

import { PerfilEmpresaContext } from "../../../globalStore/perfilEmpresa/PerfilEmpresaContext";
import PerfilInfoEmpresa from "./PerfilInfoEmpresa";

export const PerfilInfoEmpresaFromContext = () => {
  const { perfilEmpresaResult } = useContext(PerfilEmpresaContext);

  if (!perfilEmpresaResult)
    return (
      <ProgressBar animated variant="info" now={100} className="w-100 m-1" />
    );

  return perfilEmpresaResult.ok ? (
    <PerfilInfoEmpresa
      sobre={perfilEmpresaResult.value.sobre}
      nome={perfilEmpresaResult.value.nome}
      path_logo={perfilEmpresaResult.value.path_logo}
      interesses_inovacao={perfilEmpresaResult.value.interesses_inovacao}
      setores_atuacao={perfilEmpresaResult.value.setores_atuacao}
    />
  ) : (
    <>não encontrado</>
  );
};
