import { useCallback, useContext, useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { Spinner } from "react-bootstrap";

import { ButtonDarkGreen } from "../../../generalComponents/buttonDarkGreen/ButtonDarkGreen";
import { Avatar } from "../../../../domain/entities/avatar";
import { ResizeContext } from "../../../../hooks/useResize";
import { UsuarioScyggz } from "../../../../domain/entities/usuarioScyggz";
import { ButtonLightGreen } from "../../../generalComponents/buttonLightGreen/ButtonLightGreen";
import { InputImage } from "../../../generalComponents/InputImage";
import { HandlersStorageContext } from "../../../../globalStore/HandlersProviders/HandlersStorageContext";
import { useIsMounted } from "../../../../hooks/useIsMounted";

export function ConteudoAvatar({
  user,
  onBack,
  onCadastro,
}: {
  user: Pick<UsuarioScyggz, "auth_user_id" | "nome" | "avatar_path">;
  onBack: () => void;
  onCadastro: () => void;
}) {
  const isMounted = useIsMounted();
  const { handlerSaveAvatar, handlerGetURLFromPath } = useContext(
    HandlersStorageContext
  );
  const { responsive } = useContext(ResizeContext);

  const [initialValue, setInitialValue] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [value, setValue] = useState<{ imageBase: Blob; cropImage: Blob }>();

  useEffect(() => {
    if (user.avatar_path?.path && handlerGetURLFromPath) {
      handlerGetURLFromPath
        .getURL(user.avatar_path?.path)
        .then((getURLResult) => {
          if (getURLResult.ok) {
            if (isMounted) setInitialValue(getURLResult.value);
          }
        });
    }
  }, [handlerGetURLFromPath, isMounted, user.avatar_path?.path]);

  const nome: string | undefined = user.nome?.split(" ").at(0) ?? user.nome;

  const onSubmit = useCallback(async () => {
    if (!handlerSaveAvatar) {
      console.warn("undefined handlerSaveAvatar");

      return;
    }
    const fotoToSave = value?.cropImage ?? value?.imageBase;

    setIsSubmitting(true);
    if (fotoToSave) {
      const avatarResult = Avatar.create({
        auth_id: user.auth_user_id,
        file: fotoToSave,
      });

      if (!avatarResult.ok) {
        console.warn("Erro com o avatar", avatarResult.error);
        return;
      }

      const handlerSaveAvatarResult = await handlerSaveAvatar.save({
        auth_id: user.auth_user_id,
        file: avatarResult.value.file,
      });

      if (!handlerSaveAvatarResult.ok) {
        console.warn("Erro ao salvar avatar", handlerSaveAvatarResult.error);
        return;
      }
    }
    setIsSubmitting(false);

    onCadastro();
  }, [
    handlerSaveAvatar,
    onCadastro,
    user.auth_user_id,
    value?.cropImage,
    value?.imageBase,
  ]);

  return (
    <form
      onSubmit={(event) => {
        onSubmit();
        event.preventDefault();
      }}
      className="d-flex align-items-center flex-column flex-fill justify-content-between"
    >
      <h4 className="card-title text-center mb-5 fw-bold">
        <span>{nome && `${nome},`}</span>
        <span className="h5 fw-bold">
          {} adicione uma foto para reconhecerem você!
        </span>
      </h4>

      <InputImage
        alt={user.nome}
        title="avatar"
        initialValue={initialValue}
        onChange={(imageValue) => {
          setValue(imageValue);
        }}
        value={value}
      />

      <div className=" pt-4 w-100 text-end">
        <ButtonLightGreen
          disabled={isSubmitting}
          className={responsive.sm ? "d-none" : ""}
          onClick={onBack}
        >
          VOLTAR
        </ButtonLightGreen>

        <ButtonDarkGreen
          disabled={isSubmitting}
          isSubmit={true}
          className={
            "ms-2 pe-5 text-uppercase" + (responsive.sm ? " w-100" : "")
          }
        >
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className={"me-2" + (isSubmitting ? "" : " invisible")}
          />
          Avançar
        </ButtonDarkGreen>
      </div>
    </form>
  );
}

export type FormikValues = {
  newAvatarFile?: Blob;
};

export type OutputPainelCadastroFotoUsuarioInvestidor = {
  auth_id: string;
  nome: string;
};
