import bgCard from "../../../../../assets/images/logoCardUser.jpeg";
import { OutputAvatarEntity } from "../../../../../domain/entities/avatar";
import { CidadeOutput } from "../../../../../domain/entities/cidade";
import { TipoPerfil } from "../../../../../domain/entities/usuarioScyggz";
import { ImgFromPath } from "../../../../generalComponents/ImgFromPath";
import Avatar from "../../../../../assets/images/avatarLight.jpeg";
import { ConviteStartupEmployeeFuncao } from "../../../../../domain/entities/ConviteStartupEmployee";

export const CardUser = ({
  src,
  nome,
  headLine,
  cidade,
  perfis,
  funcao = undefined,
  className = "",
}: {
  src?: OutputAvatarEntity;
  nome: string;
  headLine?: string;
  cidade?: CidadeOutput;
  perfis?: TipoPerfil[];
  funcao?: ConviteStartupEmployeeFuncao;
  className?: string;
}) => {
  return (
    <div
      style={{
        minHeight: "170px",
        border: "1px solid #525200",
        borderRadius: "20px",
      }}
      className={className}
    >
      <div
        style={{
          height: "45px",
          width: "100%",
          backgroundImage: `url(${bgCard})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          borderTopRightRadius: "19px",
          borderTopLeftRadius: "19px",
        }}
      />

      <div
        className="d-flex flex-column px-2"
        style={{ position: "relative", top: "-22px" }}
      >
        <div className="p-0 ratio ratio-1x1">
          {src?.path ? (
            <ImgFromPath
              path={src?.path}
              className="rounded-circle border-white object-fit-cover"
              alt={nome}
            />
          ) : (
            <img
              className="rounded-circle border-white object-fit-cover"
              src={Avatar}
            />
          )}
        </div>

        <h4 style={{ fontSize: "16px", fontWeight: "700" }}>{nome}</h4>

        {headLine && <span style={{ fontSize: "12px" }}>{headLine}</span>}

        <div
          style={{ fontSize: "10px", paddingBottom: "5px" }}
        >{`${cidade?.cidade}, ${cidade?.estado} - ${cidade?.pais}`}</div>

        <div style={{ fontSize: "0" }}>
          {perfis &&
            perfis.map((perfil, i, array) => (
              <span
                key={perfil}
                style={{
                  fontSize: "9px",
                  color: "#525200",
                  textTransform: "capitalize",
                }}
              >
                {perfil} {i !== array.length - 1 ? " | " : ""}
              </span>
            ))}
        </div>
      </div>

      {funcao === "admin" && (
        <div
          style={{
            borderBottomLeftRadius: "19px",
            borderBottomRightRadius: "19px",
          }}
          className="w-100 bg-success align-items-center"
        >
          <h1 style={{ fontSize: "18px" }} className="text-white m-0 p-1">
            Admin
          </h1>
        </div>
      )}
    </div>
  );
};
