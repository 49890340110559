import { ref, uploadBytes, FirebaseStorage } from "firebase/storage";
import { doc, setDoc, Firestore } from "firebase/firestore";

import { Result } from "../typings";

import {
  InputLogoHub,
  LogoHub,
  OutputLogoHub,
} from "../domain/entities/LogoHub";
import { OutputHub } from "../domain/entities/Hub";
import { HandlerSaveLogoHub } from "../domain/usecases/interfaces/HandlerSaveLogoHub";

export class HandlerSaveLogoHubFirebase implements HandlerSaveLogoHub {
  constructor(
    private firebaseStorage: FirebaseStorage,
    private firestore: Firestore
  ) {}

  async save(inputLogoHub: InputLogoHub): Promise<Result<OutputLogoHub>> {
    const logoHubResult = LogoHub.create(inputLogoHub);

    if (!logoHubResult.ok) return logoHubResult;

    const storageRef = ref(this.firebaseStorage, logoHubResult.value.path);

    return new Promise((resolve) => {
      uploadBytes(storageRef, logoHubResult.value.file)
        .then(async () => {
          const docHub = doc(
            this.firestore,
            "hubs",
            logoHubResult.value.id_Hub
          );

          const logo: Pick<OutputHub, "path_logo"> = {
            path_logo: storageRef.fullPath,
          };

          await setDoc(docHub, logo, { merge: true });

          resolve(logoHubResult);
        })
        .catch((error) =>
          resolve({
            ok: false,
            error,
          })
        );
    });
  }
}
