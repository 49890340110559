import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import {
  IconDefinition,
  faLink,
  faUser,
  faUserMinus,
} from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";

import { HandlersDatabaseContext } from "../../../../../globalStore/HandlersProviders/HandlersDatabaseContext";
import { ConviteProgramaConexao } from "../../../../../domain/entities/ConviteProgramaConexao";
import { ImgFromPathNew } from "../../../../generalComponents/ImgFromPathNew";
import showStakeholder from "../../../../../utils/showStakeholder";
import { CurrentUserContext } from "../../../../../globalStore/CurrentUserContext";
import { StatusConexao } from "../../../../../domain/entities/Conexao";
import {
  TIPOS_PERFIL,
  TipoPerfil,
} from "../../../../../domain/entities/usuarioScyggz";

export function MembroItemProgramaDeConexao({
  convite,
  statusConexaoWithCurrentUser,
  isGestor,
  isLastInList,
  refreshMembros,
  formatURL,
}: {
  convite: ConviteProgramaConexao;
  statusConexaoWithCurrentUser?: StatusConexao;
  isGestor: boolean;
  isLastInList: boolean;
  refreshMembros: () => void;
  formatURL: (input: { user_id: string; perfil: string }) => string;
}) {
  const { handlerConviteProgramaConexaoDelete, handlerConexaoCreate } =
    useContext(HandlersDatabaseContext);
  const { currentUserScyggz } = useContext(CurrentUserContext);

  return (
    <tr>
      <td className={"align-middle " + (isLastInList ? "border-0" : "")}>
        <div className="d-flex align-middle align-items-center">
          {convite.alvo.avatar_path && (
            <div
              style={{
                width: "50px",
                height: "50px",
              }}
              className="me-3 d-inline-block"
            >
              <ImgFromPathNew
                path={convite.alvo.avatar_path}
                alt={convite.alvo.nome}
                className="rounded-circle border border-2 border-white bg-white"
                elementErro={
                  <div className="rounded-circle bg-secondary-subtle text-white d-flex justify-content-center align-items-center opacity-75 w-100 h-100">
                    <FontAwesomeIcon icon={faUser} className="w-50 h-50" />
                  </div>
                }
              />
            </div>
          )}

          {isGestor && (
            <span className="m-0 text-uppercase">
              {convite.alvo.nome}

              {convite.status == "accepted" ? (
                <span className="badge rounded-pill text-bg-success ms-2">
                  aceito
                </span>
              ) : convite.status == "rejected" ? (
                <span className="badge rounded-pill text-bg-danger ms-2">
                  rejeitado
                </span>
              ) : (
                <></>
              )}
            </span>
          )}
        </div>
      </td>

      <td className={"align-middle " + (isLastInList ? "border-0" : "")}>
        <div
          className="fw-bolder color-spinoff"
          style={{
            textTransform: "capitalize",
          }}
        >
          {convite.to_group}
        </div>
      </td>

      <td className={"align-middle " + (isLastInList ? "border-0" : "")}>
        <div
          className="fw-bolder color-spinoff"
          style={{
            textTransform: "capitalize",
          }}
        >
          {convite.alvo.typesStakeholder.map((typeStakeholder, i) => (
            <span key={i}>
              {i > 0 && " | "}
              {showStakeholder(typeStakeholder)}
            </span>
          ))}
        </div>
      </td>

      <td className={"align-middle " + (isLastInList ? "border-0" : "")}>
        <div
          className="fw-bolder color-spinoff"
          style={{
            textTransform: "capitalize",
          }}
        >
          {convite.alvo.cidade.cidade && (
            <>
              {convite.alvo.cidade.cidade}, {convite.alvo.cidade.estado} -{" "}
              {convite.alvo.cidade.pais}
            </>
          )}
        </div>
      </td>

      <td
        className={
          "align-middle align-items-center " + (isLastInList ? "border-0" : "")
        }
      >
        {isGestor ? (
          <>
            <button
              type="button"
              className="btn btn-outline-danger"
              onClick={async () => {
                await handlerConviteProgramaConexaoDelete?.delete(convite);
                refreshMembros();
              }}
            >
              <FontAwesomeIcon icon={faUserMinus} className="me-2" />
              Remover
            </button>
          </>
        ) : (
          <>
            {statusConexaoWithCurrentUser == "iniciado" ? (
              <>
                <button
                  type="button"
                  disabled
                  className="btn border-2 color-spinoff background-light-green border-color-spinoff"
                >
                  <FontAwesomeIcon icon={faClock as IconDefinition} />
                </button>
              </>
            ) : (
              <>
                {statusConexaoWithCurrentUser == undefined ? (
                  <>
                    <button
                      title={"conectar com " + convite.alvo.nome}
                      className="btn text-white background-spinoff"
                      onClick={async () => {
                        await handlerConexaoCreate?.create({
                          id_programa: convite.programa_conexao.id,
                          autor: {
                            auth_user_id:
                              currentUserScyggz.user?.auth_user_id ?? "",
                            nome: currentUserScyggz.user?.nome ?? "",
                            avatar_path: currentUserScyggz.user?.avatar_path,
                          },
                          alvo: {
                            auth_user_id: convite.alvo.id,
                            nome: convite.alvo.nome,
                            avatar_path: convite.alvo.avatar_path
                              ? {
                                  path: convite.alvo.avatar_path,
                                }
                              : undefined,
                          },
                        });

                        refreshMembros();
                      }}
                    >
                      <FontAwesomeIcon icon={faLink} />
                    </button>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </>
        )}

        {convite.alvo.typesStakeholder.some((typeStakeholder) =>
          TIPOS_PERFIL.includes(typeStakeholder as TipoPerfil)
        ) && (
          <Link
            to={formatURL({
              user_id: convite.alvo.id,
              perfil: "",
            })}
            className="btn ms-3 border-2 color-spinoff background-light-green1 border-color-spinoff"
          >
            visualizar
          </Link>
        )}
      </td>
    </tr>
  );
}
