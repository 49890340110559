import { Result } from "../typings";
import { HandleCacheNewUser } from "../domain/usecases/interfaces/HandleCacheNewUser";

export class HandlerCacheNewUserStorage implements HandleCacheNewUser {
  private readonly keyNewUserPrefix = "newUser";

  clear(): Promise<Result<null>> {
    if (!localStorage) {
      return Promise.resolve({
        ok: false,
        error: new Error(
          "Não fez cache da Startup pois o navegador é incompativel"
        ),
      });
    }

    try {
      localStorage.removeItem(this.keyNewUserPrefix);

      return Promise.resolve({
        ok: true,
        value: null,
      });
    } catch (error) {
      return Promise.resolve({
        ok: false,
        error: error as Error,
      });
    }
  }

  async save(newUser: boolean): Promise<Result<null, Error>> {
    if (!localStorage) {
      return Promise.resolve({
        ok: false,
        error: new Error(
          "Não fez cache da startup pois o navegador é incompativel"
        ),
      });
    }

    try {
      const getResult = await this.get();

      if (!getResult.ok) {
        return getResult;
      }

      localStorage.setItem(this.keyNewUserPrefix, JSON.stringify({ newUser }));

      return Promise.resolve({
        ok: true,
        value: null,
      });
    } catch (error) {
      return Promise.resolve({
        ok: false,
        error: error as Error,
      });
    }
  }

  get(): Promise<Result<boolean, Error>> {
    const newUserStorage = localStorage.getItem(this.keyNewUserPrefix);

    if (!newUserStorage) {
      return Promise.resolve({
        ok: true,
        value: false,
      });
    }

    const newUserAny = JSON.parse(newUserStorage);

    if (!newUserAny) {
      return Promise.resolve({
        ok: true,
        value: false,
      });
    }

    console.log(newUserAny);

    const { newUser } = newUserAny;

    return Promise.resolve({
      ok: true,
      value: newUser,
    });
  }
}
