import { Result } from "../../typings";
import { UsuarioScyggzValidator } from "./UsuarioScyggzValidator";
import { OutputAvatarEntity } from "./avatar";
import { CidadeOutput } from "./cidade";

export class UsuarioScyggz {
  public auth_user_id: string;
  public nome: string;
  public cidade: CidadeOutput;
  public telefone: string;
  public aceite_termo_de_servico: Date;
  public aceite_politica_de_privacidade: Date;

  public avatar_path?: OutputAvatarEntity;
  public url_linkedin?: string;
  public headline?: string;
  public url_lattes?: string;
  public perfis?: { [id_perfil in TipoPerfil]?: boolean } | undefined;
  public auth_id_conexoes?: string[];

  private constructor({
    avatar_path,
    headline,
    url_linkedin,
    perfis,
    url_lattes,
    aceite_politica_de_privacidade,
    aceite_termo_de_servico,
    auth_user_id,
    cidade,
    nome,
    telefone,
  }: InputUsuarioScyggzEntity) {
    this.auth_user_id = auth_user_id;
    this.nome = nome;
    this.cidade = cidade;
    this.telefone = telefone;
    this.aceite_termo_de_servico = aceite_termo_de_servico;
    this.aceite_politica_de_privacidade = aceite_politica_de_privacidade;

    this.avatar_path = avatar_path;
    this.url_linkedin = url_linkedin;
    this.headline = headline;
    this.url_lattes = url_lattes;
    this.perfis = perfis;
  }

  public static createUsuario(
    input: InputUsuarioScyggzEntity
  ): Result<UsuarioScyggz, Partial<Record<keyof UsuarioScyggz, Error>>> {
    const validateCreateResult = UsuarioScyggzValidator.validateCreate(input);

    if (!validateCreateResult.ok) {
      return validateCreateResult;
    }

    return {
      ok: true,
      value: new UsuarioScyggz(input),
    };
  }
}

export type InputUsuarioScyggzEntity = {
  auth_user_id: string;
  nome: string;
  cidade: CidadeOutput;
  telefone: string;
  aceite_termo_de_servico: Date;
  aceite_politica_de_privacidade: Date;
} & Partial<{
  avatar_path: OutputAvatarEntity;
  url_linkedin: string;
  headline: string;
  perfis: { [id_perfil in TipoPerfil]?: boolean };
  url_lattes: string;
  auth_id_conexoes: string[];
}>;

export type OutputUsuarioScyggzEntity = InputUsuarioScyggzEntity;

export const TIPOS_PERFIL = ["cientista", "investidor", "mentor"] as const;

export type TipoPerfil = (typeof TIPOS_PERFIL)[number];
