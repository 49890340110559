import { createContext } from "react";

import { OutputVentureCapital } from "../../domain/entities/VentureCapital";
import { Result } from "../../typings";

export const PerfilVentureCapitalContext = createContext<{
  perfilVentureCapitalResult: TypePerfilVentureCapitalContext;
}>({
  perfilVentureCapitalResult: undefined,
});

export type TypePerfilVentureCapitalContext =
  | Result<OutputVentureCapital>
  | undefined;
