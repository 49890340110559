import {
  Firestore,
  collection,
  where,
  query,
  Timestamp,
  doc,
  onSnapshot,
} from "@firebase/firestore";
import { Observable } from "rxjs";

import { Result } from "../typings";
import { OutputNit } from "../domain/entities/Nit";
import { HandlerGetNitRealtime } from "../domain/usecases/interfaces/HandlerGetNitRealtime";

export class HandlerGetNitRealtimeFirebase implements HandlerGetNitRealtime {
  constructor(private firestore: Firestore) {}

  getAllEnabledNits(): Observable<Result<OutputNit[]>> {
    return new Observable((sub) => {
      const queryEnabledNits = query(
        collection(this.firestore, "nits"),
        where("habilitado", "==", true)
      );

      onSnapshot(
        queryEnabledNits,
        (querySnapshot) => {
          const nits: OutputNit[] = querySnapshot.docs.map((docSnapshot) => {
            const nit = docSnapshot.data() as OutputNit;
            nit.id = docSnapshot.id;
            nit.created_in = (nit.created_in as unknown as Timestamp).toDate();
            return nit;
          });

          sub.next({
            ok: true,
            value: nits,
          });
        },
        () =>
          sub.next({
            ok: true,
            value: [],
          })
      );
    });
  }

  getNit(id: string): Observable<Result<OutputNit>> {
    return new Observable((sub) => {
      const docNit = doc(this.firestore, "nits", id);

      onSnapshot(
        docNit,
        (docSnapshot) => {
          const nit = docSnapshot.data() as OutputNit;
          nit.id = docSnapshot.id;
          nit.created_in = (nit.created_in as unknown as Timestamp).toDate();

          sub.next({
            ok: true,
            value: nit,
          });
        },
        (error) =>
          sub.next({
            ok: false,
            error,
          })
      );
    });
  }

  getNitsGestor(id_gerente: string): Observable<Result<OutputNit[]>> {
    return new Observable((sub) => {
      const queryNitsGestor = query(
        collection(this.firestore, "nits"),
        where("gestores", "array-contains", id_gerente)
      );

      onSnapshot(
        queryNitsGestor,
        (querySnapshot) => {
          const nits: OutputNit[] = querySnapshot.docs.map((docSnapshot) => {
            const nit = docSnapshot.data() as OutputNit;
            nit.id = docSnapshot.id;
            nit.created_in = (nit.created_in as unknown as Timestamp).toDate();
            return nit;
          });

          sub.next({
            ok: true,
            value: nits,
          });
        },
        () =>
          sub.next({
            ok: true,
            value: [],
          })
      );
    });
  }
}
