import { Result } from "../../typings";

export class TeseDeInvestimento {
  static readonly MINIMO_LENGTH = 100;
  static readonly MAXIMO_LENGTH = 500;

  private constructor(public conteudo: string) {}

  static create(
    tese: TeseDeInvestimentoInput
  ): Result<TeseDeInvestimento, Error> {
    return TeseDeInvestimento.validate(tese);
  }

  public static validate({
    conteudo,
  }: TeseDeInvestimento): Result<TeseDeInvestimento> {
    conteudo = conteudo.trim();

    if (conteudo.length < TeseDeInvestimento.MINIMO_LENGTH) {
      return {
        ok: false,
        error: new Error(
          "Mínimo de " + TeseDeInvestimento.MINIMO_LENGTH + " caracteres"
        ),
      };
    }

    if (conteudo.length > TeseDeInvestimento.MAXIMO_LENGTH) {
      return {
        ok: false,
        error: new Error(
          "Máximo de " + TeseDeInvestimento.MAXIMO_LENGTH + " caracteres"
        ),
      };
    }

    return {
      ok: true,
      value: {
        conteudo,
      },
    };
  }
}

export type TeseDeInvestimentoInput = { conteudo: string };
export type TeseDeInvestimentoOutput = TeseDeInvestimentoInput;
