import { useContext } from "react";
import { ProgressBar } from "react-bootstrap";
import { ConviteStartupEmployee } from "../../../../../domain/entities/ConviteStartupEmployee";
import { CardUser } from "./CardUser";
import { HandlersDatabaseContext } from "../../../../../globalStore/HandlersProviders/HandlersDatabaseContext";
import { CurrentUserContext } from "../../../../../globalStore/CurrentUserContext";

type InfoCardConvitesStartupProps = {
  convites: ConviteStartupEmployee[];
  className?: string;
  refreshConvites: () => void;
  loading: boolean;
  seeAsPublic: boolean;
};

export const InfoCardConvitesStartup = ({
  convites,
  className = "",
  refreshConvites,
  loading,
  seeAsPublic,
}: InfoCardConvitesStartupProps) => {
  const {
    handlerConviteStartupEmployeeAccept,
    handlerConviteStartupEmployeeReject,
  } = useContext(HandlersDatabaseContext);
  const { currentUserScyggz } = useContext(CurrentUserContext);

  return (
    <section className={className}>
      {loading ? (
        <ProgressBar animated variant="info" now={100} className="w-100" />
      ) : (
        <div className="d-flex ms-4 gap-3 flex-wrap align-items-center">
          {convites && convites.length > 0 ? (
            convites.map((convite) => (
              <div key={convite.alvo.id} className="text-center">
                <a href={"/user/" + convite.alvo.id} className="text-reset">
                  <CardUser
                    src={
                      convite.alvo.avatar_path
                        ? {
                            path: convite.alvo.avatar_path,
                          }
                        : undefined
                    }
                    nome={convite.alvo.nome}
                    headLine={convite.alvo.headline}
                    cidade={convite.alvo.cidade}
                    funcao={convite.funcao}
                    className={
                      convite.status != "accepted" ? " opacity-25" : ""
                    }
                  />
                </a>

                {currentUserScyggz.user?.auth_user_id == convite.alvo.id &&
                  !convite.status &&
                  !seeAsPublic && (
                    <div className="d-flex gap-2 mt-1">
                      <button
                        type="button"
                        className="btn btn-danger btn-sm mt-1 btn-d"
                        onClick={async () => {
                          await handlerConviteStartupEmployeeReject?.reject(
                            convite
                          );
                          refreshConvites();
                        }}
                      >
                        Recusar
                      </button>
                      <button
                        type="button"
                        className="btn btn-success btn-sm mt-1"
                        onClick={async () => {
                          await handlerConviteStartupEmployeeAccept?.accept(
                            convite
                          );
                          refreshConvites();
                        }}
                      >
                        Confirmar
                      </button>
                    </div>
                  )}
              </div>
            ))
          ) : (
            <div className="w-100">
              <h1 className="text-center mb-0 ">Não há pessoas no time!</h1>
            </div>
          )}
        </div>
      )}
    </section>
  );
};
