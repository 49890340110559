import { createContext } from "react";

import { OutputEmpresa } from "../../domain/entities/Empresa";
import { Result } from "../../typings";

export const PerfilEmpresaContext = createContext<{
  perfilEmpresaResult: TypePerfilEmpresaContext;
}>({
  perfilEmpresaResult: undefined,
});

export type TypePerfilEmpresaContext = Result<OutputEmpresa> | undefined;
