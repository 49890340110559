import { Result } from "../../typings";
import { CidadeOutputWithTipo } from "./cidade";
import { ClassificacaoHub } from "./ClassificacaoHub";
import { PremiacaoOutput } from "./premiacao";

export class Hub {
  public id = "";

  private constructor(private input: InputHub) {}
  public static create(input: InputHub): Result<Hub> {
    const errors: Error[] = [];

    let { nome } = input;

    nome = nome.trim();
    const isNomeValid: boolean = nome.length > 0;
    if (!isNomeValid) {
      errors.push(new Error("Nome inválido"));
    }

    //TODO: VALIDATE: sobre, gestores, cidade, email

    if (errors.length > 0) {
      return {
        ok: false,
        error: errors[0],
      };
    }

    return {
      ok: true,
      value: new Hub(input),
    };
  }

  public get nome(): string {
    return this.input.nome;
  }

  public get cidade(): CidadeOutputWithTipo {
    return this.input.cidade;
  }

  public get path_logo(): string | undefined {
    return this.input.path_logo;
  }

  public get sobre(): string {
    return this.input.sobre;
  }

  public get nit(): { id: string; nome: string } | undefined {
    return this.input.nit;
  }
  public get classificacao(): ClassificacaoHub {
    return this.input.classificacao;
  }
  public get premiacoes(): PremiacaoOutput[] | undefined {
    return this.input.premiacoes;
  }
  public get email() {
    return this.input.email;
  }
  public get telefone() {
    return this.input.telefone;
  }
  public get website() {
    return this.input.website;
  }

  public get linkedin() {
    return this.input.linkedin;
  }
}

export type InputHub = {
  nome: string;
  sobre: string;
  gestores: string[];
  cidade: CidadeOutputWithTipo;
  telefone: string;
  email: string; // TODO: VALIDATE EMAIL
  classificacao: ClassificacaoHub;
  created_in: Date;
  habilitado: boolean;
} & Partial<{
  nit: {
    id: string;
    nome: string;
  };
  website: string; // TODO: VALIDATE URL
  premiacoes: PremiacaoOutput[];
  linkedin: string; // TODO: VALIDATE URL
  path_logo: string;
}>;

export type OutputHub = InputHub & {
  id: string;
};
