import { useContext, useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { faConnectdevelop } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import HTMLHead from "../../generalComponents/HTMLHead";
import { Result } from "../../../typings";
import { Conexao } from "../../../domain/entities/Conexao";
import { CurrentUserContext } from "../../../globalStore/CurrentUserContext";
import { ListConexoes } from "./components/ListConexoes";
import { HandlersDatabaseContext } from "../../../globalStore/HandlersProviders/HandlersDatabaseContext";

export function ConexoesPage() {
  const { currentUserAuth } = useContext(CurrentUserContext);
  const { handlerConexaoAprovadasGet } = useContext(HandlersDatabaseContext);

  const [conexoesAprovadasResult, setConexoesAprovadasResult] =
    useState<Result<Readonly<Conexao>[]>>();

  useEffect(() => {
    if (currentUserAuth.user_auth?.auth_id) {
      handlerConexaoAprovadasGet
        ?.getConexoesAprovadas({
          auth_user_id: currentUserAuth.user_auth.auth_id,
        })
        .then((conexoesAprovadasResult) => {
          setConexoesAprovadasResult(conexoesAprovadasResult);
        });
    }
  }, [currentUserAuth.user_auth?.auth_id, handlerConexaoAprovadasGet]);

  return (
    <>
      <HTMLHead title="Conexões" description="Minhas Conexões" />

      <div className="p-3">
        <div className="card shadow-sm px-4">
          <div className="card-body">
            <h5 className="pb-2 fw-bolder">
              <FontAwesomeIcon icon={faConnectdevelop} className="me-2" />
              Minhas conexões
            </h5>

            {conexoesAprovadasResult == undefined ? (
              <ProgressBar
                animated
                variant="info"
                now={100}
                className="w-100 m-1"
              />
            ) : (
              <>
                {conexoesAprovadasResult.ok && (
                  <ListConexoes
                    conexoes={conexoesAprovadasResult.value.map((conexao) => ({
                      id: conexao.id,
                      user:
                        currentUserAuth.user_auth?.auth_id ==
                        conexao.autor.auth_user_id
                          ? conexao.alvo
                          : conexao.autor,
                    }))}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
