import { useContext, useState } from "react";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { InputUpdateStartup } from "../../../domain/entities/Startup";
import { ModalEditStartup } from "./ModalEditStartup";
import { HandlersDatabaseContext } from "../../../globalStore/HandlersProviders/HandlersDatabaseContext";
import { PerfilStartupContext } from "../../../globalStore/perfilStartup/PerfilStartupContext";

export function EditInfoStartup({
  startup,
  enableEdit,
}: {
  startup: InputUpdateStartup;
  enableEdit: boolean;
}) {
  const { handlerUpdateStartup } = useContext(HandlersDatabaseContext);
  const { refreshStartup } = useContext(PerfilStartupContext);

  const [showModalEditStartup, setShowModalEditStartup] =
    useState<boolean>(false);

  return (
    <>
      {enableEdit && (
        <>
          <button
            type="button"
            className="btn bg-body-secondary text-secondary rounded-circle m-1"
            onClick={() => setShowModalEditStartup(true)}
          >
            <FontAwesomeIcon icon={faPencil} />
          </button>

          {showModalEditStartup && (
            <ModalEditStartup
              initialValue={startup}
              show={showModalEditStartup}
              onShowChange={(show) => {
                setShowModalEditStartup(show);
              }}
              handlerUpdateStartup={
                enableEdit ? handlerUpdateStartup : undefined
              }
              afterUpdate={refreshStartup}
            />
          )}
        </>
      )}
    </>
  );
}
