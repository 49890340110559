import { useContext, useEffect, useState } from "react";

import { ResizeContext } from "../../hooks/useResize";

export function InputNumber({
  label,
  value,
  onChange,
  onBlur,
  id,
  title,
  min,
  max,
  className = "",
}: {
  label: string;
  value?: number;
  onChange: (value: number) => void;
  onBlur: () => void;
  id: string;
  title: string;
  min?: { value: number; errorMessage: string };
  max?: { value: number; errorMessage: string };
  className?: string;
}) {
  const { responsive } = useContext(ResizeContext);

  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  useEffect(() => {
    if (min && (value ?? 0) < min.value) {
      setError(min.errorMessage);
    } else if (max && (value ?? 0) > max.value) {
      setError(max.errorMessage);
    } else {
      setError(undefined);
    }
  }, [max, min, value]);

  return (
    <div className={className}>
      <label htmlFor="iptQtdeFuncionarios" className="form-label fw-bolder m-0">
        {label}
      </label>

      <input
        type="number"
        min={min?.value}
        max={max?.value}
        className={
          `${responsive.sm ? "imputMobile" : "form-control"}` +
          (error && isChanged ? " is-invalid" : "")
        }
        id={id}
        name={id}
        title={title}
        onChange={({ target: { value } }) => {
          onChange(parseInt(value));
          setIsChanged(true);
        }}
        onBlur={onBlur}
        value={value && value > 0 ? value : ""}
      />
      <div className="invalid-feedback flex-fill">{error}</div>
    </div>
  );
}
