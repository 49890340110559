import { Navigate } from "react-router-dom";
import { useContext } from "react";

import HTMLHead from "../../generalComponents/HTMLHead";
import { ConvitesConexaoRecebidas } from "./components/ConvitesConexaoRecebidas";
import { HandlerUpdateAceiteConexao } from "../../../domain/usecases/interfaces/HandlerUpdateAceiteConexao";
import { MeusPerfisContext } from "./components/MeusPerfisContext";
import { GerenciarMinhaRede } from "./components/GerenciarMinhaRede";
import { CurrentUserContext } from "../../../globalStore/CurrentUserContext";
import { ConexoesAprovadasCountProvider } from "../../../globalStore/conexoesAprovadasCount/ConexoesAprovadasCountProvider";
import { ConexoesPendentesEnviadasCountProvider } from "../../../globalStore/conexoesPendentesEnviadasCount/ConexoesPendentesEnviadasCountProvider";
import { ConexoesPendentesRecebidasProvider } from "../../../globalStore/conexoesPendentesRecebidas/ConexoesPendentesRecebidasProvider";
import { GerenciarOrganizacoes } from "./components/GerenciarOrganizacoes";
import { UserOrganizacoesRealtimeFirebaseProvider } from "../../../globalStore/userOrganizacoes/UserOrganizacoesRealtimeFirebaseProvider";
import { PessoasRelevantesFirebaseProvider } from "../../../globalStore/PessoasRelevantesProvider/PessoasRelevantesFirebaseProvider";
import { InfoCardUsuariosFromPessoasRelevantesContext } from "../user/components/generalComponents/InfoCardUsuariosFromPessoasRelevantesContext";

export function Dashboard({
  handlerUpdateAceiteConexao,
}: {
  handlerUpdateAceiteConexao: HandlerUpdateAceiteConexao;
}) {
  const {
    currentUserScyggz: { status, user },
  } = useContext(CurrentUserContext);

  return (
    <>
      {status == "nao_encontrado" ? (
        <Navigate to={"/cadastro"} />
      ) : (
        <main className="h-100 overflow-auto p-3" data-testid="dashboard">
          <div className="container">
            <div className="row g-3">
              <HTMLHead title="Dashboard" description="Dashboard do site" />

              {user && (
                <div className="d-flex flex-column col-md-8 col-lg-9 gap-3">
                  <ConexoesPendentesRecebidasProvider
                    realtime={true}
                    user={{ auth_user_id: user.auth_user_id }}
                  >
                    <ConvitesConexaoRecebidas
                      handlerUpdateAceiteConexao={handlerUpdateAceiteConexao}
                    />
                  </ConexoesPendentesRecebidasProvider>

                  <PessoasRelevantesFirebaseProvider
                    showedUser={user}
                    currentUserScyggz={user}
                  >
                    <InfoCardUsuariosFromPessoasRelevantesContext />
                  </PessoasRelevantesFirebaseProvider>
                </div>
              )}

              <div className="col-md-4 col-lg-3 d-flex flex-column align-items-center gap-3">
                <MeusPerfisContext />
                {user && (
                  <>
                    <ConexoesAprovadasCountProvider user={user}>
                      <ConexoesPendentesEnviadasCountProvider user={user}>
                        <GerenciarMinhaRede />
                      </ConexoesPendentesEnviadasCountProvider>
                    </ConexoesAprovadasCountProvider>

                    <UserOrganizacoesRealtimeFirebaseProvider user={user}>
                      <GerenciarOrganizacoes />
                    </UserOrganizacoesRealtimeFirebaseProvider>
                  </>
                )}
              </div>
            </div>
          </div>
        </main>
      )}
    </>
  );
}
