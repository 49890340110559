import {
  faPersonWalkingArrowRight,
  faUserPlus,
  faUsersViewfinder,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { Link } from "react-router-dom";

import { ConexoesStatusCountContext } from "../../../../globalStore/conexoesStatusCount/ConexoesStatusCountContext";
import { TextH2Bold } from "../../../generalComponents/TextH2Bold";
import { TextH5Info } from "../../user/components/generalComponents/TextH5Info";

export const GerenciarMinhaRede = () => {
  const { conexoesStatusCountResult } = useContext(ConexoesStatusCountContext);

  return (
    <aside style={{ width: "100%" }} className="border rounded-4 p-4 card">
      <TextH2Bold classes="mb-5" children="Gerenciar minha rede" />

      <div>
        <Link to={"/conexoes"} className="text-reset">
          <div className="d-flex align-items-center gap-2 pb-4">
            <FontAwesomeIcon fontSize={20} icon={faUsersViewfinder} />

            <TextH5Info children="Minhas conexões" />

            <TextH5Info
              children={
                conexoesStatusCountResult?.ok
                  ? conexoesStatusCountResult.value.qtdeAprovadas
                  : 0
              }
              classes="flex-fill text-end"
            />
          </div>
        </Link>

        <Link to={"/conexoes/pendentes/recebidos"} className="text-reset">
          <div className="d-flex align-items-center gap-2 pb-4">
            <FontAwesomeIcon fontSize={20} icon={faUserPlus} />

            <TextH5Info children="Convites recebidos" />

            <TextH5Info
              classes="flex-fill text-end"
              children={
                conexoesStatusCountResult?.ok
                  ? conexoesStatusCountResult.value.qtdePendentesRecebidas
                  : 0
              }
            />
          </div>
        </Link>

        <Link to={"/conexoes/pendentes/enviados"} className="text-reset">
          <div className="d-flex align-items-center w-100 gap-2 pb-4">
            <FontAwesomeIcon fontSize={20} icon={faPersonWalkingArrowRight} />

            <TextH5Info children="Convites enviados" />

            <TextH5Info
              classes="flex-fill text-end"
              children={
                conexoesStatusCountResult?.ok
                  ? conexoesStatusCountResult.value.qtdePendentesEnviadas
                  : 0
              }
            />
          </div>
        </Link>
      </div>
    </aside>
  );
};
