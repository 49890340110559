import { useContext } from "react";
import { ProgressBar } from "react-bootstrap";

import { CurrentUserContext } from "../../../../globalStore/CurrentUserContext";
import { PerfilCientistaContext } from "../../../../globalStore/perfilCientista/PerfilCientistaContext";
import PerfilNotFinded from "../PerfilNotFinded";
import { PerfilInfoCientista } from "./PerfilInfoCientista";

export const PerfilInfoCientistaFromContext = () => {
  const { perfilCientistaResult, updateCientista, refreshCientista } =
    useContext(PerfilCientistaContext);
  const { currentUserAuth, seeAsPublic } = useContext(CurrentUserContext);

  return (
    <>
      {perfilCientistaResult == undefined ? (
        <ProgressBar animated variant="info" now={100} className="w-100" />
      ) : (
        <>
          {perfilCientistaResult.ok ? (
            <PerfilInfoCientista
              areas_de_conhecimento={
                perfilCientistaResult.value.areas_de_conhecimento
              }
              sobre={perfilCientistaResult.value.sobre}
              unidades={perfilCientistaResult.value.unidades}
              grupos_de_pesquisa={
                perfilCientistaResult.value.grupos_de_pesquisa
              }
              premiacoes={perfilCientistaResult.value.premiacoes}
              tecnologias={perfilCientistaResult.value.tecnologias}
              refreshCientista={refreshCientista}
              updateCientista={
                currentUserAuth.user_auth?.auth_id ==
                  perfilCientistaResult.value.user_id && !seeAsPublic
                  ? updateCientista
                  : undefined
              }
            />
          ) : (
            <PerfilNotFinded perfil="cientista" />
          )}
        </>
      )}
    </>
  );
};
