import { useEffect, useState } from "react";

import { InputDate } from "./InputDate";

export function InputDuration({
  label,
  value,
  onChange,
  validate,
  sizeDates = "small",
}: {
  label: string;
  value: InputDurationValue;
  onChange: (duration: InputDurationValue) => void;
  validate: (
    duration: InputDurationValue
  ) => { start_date?: Error; end_date?: Error } | undefined;
  sizeDates?: "small" | "large";
}) {
  const [error, setError] = useState<
    { start_date?: Error; end_date?: Error } | undefined
  >();

  useEffect(() => {
    const validateError = validate(value);

    if (validateError) {
      setError(validateError);
    } else {
      setError(undefined);
    }
  }, [validate, value]);

  return (
    <div className={"mb-3 has-validation"}>
      <label className="form-label fw-bolder m-0">{label}</label>

      <div className="row text-muted fw-light">
        <InputDate
          label="Início"
          value={value.start}
          id="iptInicio"
          onChange={(start) => {
            onChange({
              ...value,
              start,
            });
          }}
          validate={() => {
            return error?.start_date;
          }}
          className={sizeDates == "small" ? "col-sm-4 col-md-3" : "col-6"}
        />

        <InputDate
          label="Final"
          value={value.end}
          id="iptFinal"
          onChange={(end) => {
            onChange({
              ...value,
              end,
            });
          }}
          validate={() => {
            return error?.end_date;
          }}
          className={sizeDates == "small" ? "col-sm-4 col-md-3" : "col-6"}
        />
      </div>
    </div>
  );
}

export type InputDurationValue = {
  start?: Date;
  end?: Date;
};
