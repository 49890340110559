import "react-phone-input-2/lib/style.css";
import { Formik } from "formik";
import * as Yup from "yup";
import { Spinner } from "react-bootstrap";
import { useState, useEffect, useContext } from "react";

import { ButtonDarkGreen } from "../../../../generalComponents/buttonDarkGreen/ButtonDarkGreen";
import { EstagioStartup } from "../../../../../domain/entities/EstagioStartup";
import { MecanismoDeSolucaoTecnologicaOutput } from "../../../../../domain/entities/mecanismoDeSolucaoTecnologica";
import { ModeloNegocioOutput } from "../../../../../domain/entities/modeloNegocio";
import { SegmentoAtuacaoOutput } from "../../../../../domain/entities/segmentoAtuacao";
import { LocationFinderOutput } from "../../../../../domain/usecases/locationFinder";
import { ResizeContext } from "../../../../../hooks/useResize";
import { ButtonLightGreen } from "../../../../generalComponents/buttonLightGreen/ButtonLightGreen";
import {
  CacheMentor,
  HandlerCacheMentor,
} from "../../../../../domain/usecases/interfaces/HandlerCacheMentor";
import { CurrentUserContext } from "../../../../../globalStore/CurrentUserContext";
import { HandlerCreateMentor } from "../../../../../domain/usecases/interfaces/handlerCreateMentor";
import { InputModelosNegocio } from "../../../../generalComponents/InputModelosNegocio";
import { MentorValidator } from "../../../../../domain/entities/MentorValidator";
import { InputSegmentosAtuacao } from "../../../../generalComponents/InputSegmentosAtuacao";
import { InputMecanismosSolucao } from "../../../../generalComponents/InputMecanismosSolucao";
import { InputEstagiosStartup } from "../../../../generalComponents/InputEstagiosStartup";
import { InputLocais } from "../../../../generalComponents/InputLocais";

export function ConteudoCadastroInteresseMentoria({
  handlerCacheMentor,
  handlerCreateMentor,
  onBack,
  onFinalize,
}: {
  handlerCacheMentor: HandlerCacheMentor;
  handlerCreateMentor: HandlerCreateMentor;
  onBack: () => void;
  onFinalize: (mentor_id: string) => void;
}) {
  const { currentUserScyggz } = useContext(CurrentUserContext);

  const [parteDoNome, setParteDoNome] = useState("");

  const [cacheMentor, setCacheMentor] = useState<CacheMentor>({});

  useEffect(() => {
    if (currentUserScyggz.user) {
      const parteDoNome: string =
        currentUserScyggz.user.nome.split(" ").at(0) ??
        currentUserScyggz.user.nome;

      setParteDoNome(parteDoNome);
    }
  }, [currentUserScyggz]);

  useEffect(() => {
    handlerCacheMentor.get().then((cacheMentorResult) => {
      if (cacheMentorResult.ok) {
        setCacheMentor(cacheMentorResult.value);
      }
    });
  }, [handlerCacheMentor]);

  const { responsive } = useContext(ResizeContext);

  return (
    <Formik<FormikFields>
      initialValues={{
        interesse_startup_estagio: cacheMentor.interesse_startup_estagio ?? [],
        interesse_startup_localizacoes:
          cacheMentor.interesse_startup_localizacoes ?? [],
        interesse_startup_mecanismo_solucao:
          cacheMentor.interesse_startup_mecanismo_solucao ?? [],
        interesse_startup_modelo_negocio:
          cacheMentor.interesse_startup_modelo_negocio ?? [],
        interesse_startup_segmento_atuacao:
          cacheMentor.interesse_startup_segmento_atuacao ?? [],
      }}
      enableReinitialize={true}
      validationSchema={validator}
      validateOnMount={true}
      validateOnChange={true}
      validateOnBlur={true}
      onSubmit={async (values) => {
        const saveToCache: CacheMentor = {
          interesse_startup_estagio: values.interesse_startup_estagio,
          interesse_startup_localizacoes: values.interesse_startup_localizacoes,
          interesse_startup_modelo_negocio:
            values.interesse_startup_modelo_negocio,
          interesse_startup_segmento_atuacao:
            values.interesse_startup_segmento_atuacao,
          interesse_startup_mecanismo_solucao:
            values.interesse_startup_mecanismo_solucao,
        };

        await handlerCacheMentor.save(saveToCache);

        const createMentorResult = await handlerCreateMentor.createMentor({
          user_id: currentUserScyggz.user?.auth_user_id ?? "",
          interesse_startup_estagio: values.interesse_startup_estagio,
          interesse_startup_mecanismo_solucao:
            values.interesse_startup_mecanismo_solucao,
          interesse_startup_modelo_negocio:
            values.interesse_startup_modelo_negocio,
          interesse_startup_segmento_atuacao:
            values.interesse_startup_segmento_atuacao,
          sobre_o_mentor: cacheMentor.sobre_o_mentor ?? "",
          horas_disponibilidade_mensal:
            cacheMentor.horas_disponibilidade_mensal,
          interesse_startup_localizacoes: values.interesse_startup_localizacoes,
          premiacoes: cacheMentor.premiacoes,
          nome: currentUserScyggz.user?.nome ?? "",
          areas_de_especializacao: [],
          created_in: new Date(),
        });

        if (createMentorResult.ok) {
          onFinalize(createMentorResult.value.user_id);
        } else {
          console.warn(createMentorResult.error);
        }
      }}
    >
      {({
        isValid,
        values,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        submitForm,
        isSubmitting,
      }) => (
        <form className={`${!responsive.sm && "px-3"}`} onSubmit={handleSubmit}>
          <h4 className="card-title text-center mb-4 fw-bold">
            <span>{parteDoNome && `${parteDoNome},`}</span>
            <span className="h5 fw-bold">
              {} selecione abaixo o perfil de Startup que você procura.
            </span>
          </h4>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: !responsive.sm ? " 1fr" : "",
              gap: "20px",
            }}
          >
            <div>
              <div className="mb-3">
                <InputEstagiosStartup
                  label="Estágio da startup de interesse*"
                  value={values.interesse_startup_estagio}
                  onChange={(interesse_startup_estagio) => {
                    setFieldValue(
                      "interesse_startup_estagio",
                      interesse_startup_estagio
                    );
                  }}
                  validate={MentorValidator.validateInteresseStartupEstagio}
                />
              </div>

              <div className="mb-4">
                <InputLocais
                  label="Em que localidades as Startups devem estar"
                  value={values.interesse_startup_localizacoes}
                  onChange={(interesse_startup_localizacoes) => {
                    setFieldValue(
                      "interesse_startup_localizacoes",
                      interesse_startup_localizacoes
                    );
                  }}
                  validate={MentorValidator.isInvalidLocais}
                />
              </div>

              <div className="mb-4">
                <InputModelosNegocio
                  label="Com o modelo de negócio*"
                  value={values.interesse_startup_modelo_negocio}
                  onChange={(interesse_startup_modelo_negocio) => {
                    setTimeout(() => {
                      setFieldTouched("interesse_startup_modelo_negocio", true);
                    }, 0);
                    setFieldValue(
                      "interesse_startup_modelo_negocio",
                      interesse_startup_modelo_negocio
                    );
                  }}
                  validate={
                    MentorValidator.validateInteresseStartupModeloNegocio
                  }
                />
              </div>

              <div className="mb-4">
                <InputSegmentosAtuacao
                  label="Que atuam nos seguintes segmentos*"
                  value={values.interesse_startup_segmento_atuacao}
                  onChange={(interesse_startup_segmento_atuacao) => {
                    setTimeout(() => {
                      setFieldTouched(
                        "interesse_startup_segmento_atuacao",
                        true
                      );
                    }, 0);
                    setFieldValue(
                      "interesse_startup_segmento_atuacao",
                      interesse_startup_segmento_atuacao
                    );
                  }}
                  validate={
                    MentorValidator.validateInteresseStartupSegmentoAtuacao
                  }
                />
              </div>

              <div className="mb-4">
                <InputMecanismosSolucao
                  label="Que utilizam os seguintes mecanismos de solução tecnológica*"
                  value={values.interesse_startup_mecanismo_solucao}
                  onChange={(interesse_startup_mecanismo_solucao) => {
                    setTimeout(() => {
                      setFieldTouched(
                        "interesse_startup_mecanismo_solucao",
                        true
                      );
                    }, 0);
                    setFieldValue(
                      "interesse_startup_mecanismo_solucao",
                      interesse_startup_mecanismo_solucao
                    );
                  }}
                  validate={(mecanismosDeSolucao) => {
                    const result =
                      MentorValidator.validateInteresseStartupMecanismoSolucao(
                        mecanismosDeSolucao
                      );
                    if (!result.ok) return result.error;
                  }}
                />
              </div>
            </div>
          </div>

          <div className={`${responsive.sm ? "mt-5" : "text-end mt-2"}`}>
            {!responsive.sm && (
              <ButtonLightGreen
                className="me-3"
                onClick={async () => {
                  const saveToCache: CacheMentor = {
                    interesse_startup_estagio: values.interesse_startup_estagio,
                    interesse_startup_localizacoes:
                      values.interesse_startup_localizacoes,
                    interesse_startup_modelo_negocio:
                      values.interesse_startup_modelo_negocio,
                    interesse_startup_segmento_atuacao:
                      values.interesse_startup_segmento_atuacao,
                    interesse_startup_mecanismo_solucao:
                      values.interesse_startup_mecanismo_solucao,
                  };

                  await handlerCacheMentor.save(saveToCache);
                  onBack();
                }}
              >
                Voltar
              </ButtonLightGreen>
            )}
            <ButtonDarkGreen
              disabled={!isValid || isSubmitting}
              onClick={submitForm}
              className={`pe-5 ${responsive.sm && "w-100"}`}
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className={"me-2" + (isSubmitting ? "" : " invisible")}
              />
              Finalizar
            </ButtonDarkGreen>
          </div>
        </form>
      )}
    </Formik>
  );
}

type FormikFields = {
  interesse_startup_estagio: EstagioStartup[];
  interesse_startup_localizacoes: LocationFinderOutput[];
  interesse_startup_modelo_negocio: ModeloNegocioOutput[];
  interesse_startup_segmento_atuacao: SegmentoAtuacaoOutput[];
  interesse_startup_mecanismo_solucao: MecanismoDeSolucaoTecnologicaOutput[];
};

const validator = Yup.object().shape({
  interesse_startup_estagio: Yup.array()
    .required("Precisa especificar estágio startup de interesse")
    .min(1, "Precisa especificar estágio startup de interesse"),
  interesse_startup_localizacoes: Yup.array(),
  interesse_startup_modelo_negocio: Yup.array()
    .required("Precisa especificar modelo de negócio da startup de interesse")
    .min(1, "Precisa especificar modelo de negócio da startup de interesse"),
  interesse_startup_segmento_atuacao: Yup.array()
    .required("Precisa especificar segmento de atuação da startup de interesse")
    .min(1, "Precisa especificar segmento de atuação da startup de interesse"),
  interesse_startup_mecanismo_solucao: Yup.array()
    .required(
      "Precisa especificar mecanismo de solução da startup de interesse"
    )
    .min(1, "Precisa especificar mecanismo de solução da startup de interesse"),
});
