import { useEffect, useState } from "react";

export function InputTextarea({
  label,
  value,
  onChange,
  id,
  title,
  subLabel,
  min,
  max,
  rows = 7,
  className,
}: {
  label: string;
  subLabel?: string;
  value: string;
  onChange: (value: string) => void;
  id: string;
  title: string;
  min?: { value: number; errorMessage: string };
  max?: { value: number; errorMessage: string };
  rows?: number;
  className?: string;
}) {
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  useEffect(() => {
    if (min && value.length < min.value) {
      setError(min.errorMessage);
    } else if (max && value.length > max.value) {
      setError(max.errorMessage);
    } else {
      setError(undefined);
    }
  }, [max, min, value.length]);

  return (
    <div className={className}>
      {label && (
        <label className="form-label fw-bolder m-0" htmlFor={id}>
          {label}
        </label>
      )}
      {subLabel && (
        <label className="form-label m-0 d-block text-muted">{subLabel}</label>
      )}

      <textarea
        className={"form-control" + (error && isChanged ? " is-invalid" : "")}
        onChange={({ target: { value } }) => {
          onChange(value);
          setIsChanged(true);
        }}
        rows={rows}
        id={id}
        value={value}
        title={title}
      />

      <div className={"d-flex justify-content-between"}>
        <div className="text-danger flex-fill" style={{ fontSize: ".85rem" }}>
          {isChanged && <>{error}</>}
        </div>

        <span className={"text-black-50 fw-lighter"}>
          {value.trim().length}

          {max && <>/{max.value}</>}
        </span>
      </div>
    </div>
  );
}
