import { useEffect, useState } from "react";

import {
  ESTAGIO_STARTUP,
  EstagioStartup,
} from "../../domain/entities/EstagioStartup";

export function InputEstagiosStartup({
  label,
  value,
  onChange,
  validate,
  className,
}: {
  label: string;
  value: EstagioStartup[];
  onChange: (estagiosStartup: EstagioStartup[]) => void;
  validate: (estagiosStartup: EstagioStartup[]) => Error | undefined;
  className?: string;
}) {
  const [error, setError] = useState<string>();
  const [isChanged, setIsChanged] = useState<boolean>(false);

  useEffect(() => {
    const error = validate(value);
    if (error) {
      setError(error.message);
    } else {
      setError(undefined);
    }
  }, [validate, value]);

  return (
    <div className={className}>
      <label
        className="form-label fw-bolder m-0 d-block"
        htmlFor="selEstagiosStartup"
      >
        {label}
      </label>

      <div
        style={{
          borderColor: "#ccc",
          borderWidth: 1,
          borderStyle: "solid",
        }}
        className={
          "d-flex justify-content-between flex-wrap rounded-1 p-2" +
          (error && isChanged ? " is-invalid" : "")
        }
        role="group"
      >
        {ESTAGIO_STARTUP.map((estagio) => (
          <div key={estagio} className={"form-check form-check-inline"}>
            <input
              className="form-check-input checkboxDarkGreen"
              type="checkbox"
              name="interesse_startup_estagio"
              value={estagio}
              checked={value.includes(estagio)}
              onChange={({ target: { checked } }) => {
                if (checked) onChange([...value, estagio]);
                else {
                  onChange(
                    value.filter((valueEstagio) => valueEstagio != estagio)
                  );
                }
                setIsChanged(true);
              }}
              id={"chk" + estagio}
            />
            <label className="form-check-label" htmlFor={"chk" + estagio}>
              {estagio}
            </label>
          </div>
        ))}
      </div>
      <div className="invalid-feedback flex-fill">{error}</div>
    </div>
  );
}
