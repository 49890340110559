/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Yup from "yup";
import { Formik } from "formik";
import makeAnimated from "react-select/animated";
import Select, { StylesConfig } from "react-select";
import { SetStateAction, useContext, useEffect, useState } from "react";

import { ButtonDarkGreen } from "../../../../generalComponents/buttonDarkGreen/ButtonDarkGreen";
import { CidadeOutputWithTipo } from "../../../../../domain/entities/cidade";
import { ResizeContext } from "../../../../../hooks/useResize";
import { PremiacaoOutput } from "../../../../../domain/entities/premiacao";
import { ButtonLightGreen } from "../../../../generalComponents/buttonLightGreen/ButtonLightGreen";
import { CurrentUserContext } from "../../../../../globalStore/CurrentUserContext";
import {
  CacheNit,
  HandlerCacheNit,
} from "../../../../../domain/usecases/interfaces/HandlerCacheNit";
import { UnidadeInstituicaoDeCienciaETecnologiaOutput } from "../../../../../domain/entities/unidadeInstituicaoDeCienciaETecnologia";
import { HandlerGetUnidade } from "../../../../../domain/usecases/interfaces/handlerGetUnidade";
import { InstituicaoDeCienciaETecnologia } from "../../../../../domain/entities/instituicaoDeCienciaETecnologia";
import { InputNit } from "../../../../../domain/entities/Nit";
import { HandlerCreateNit } from "../../../../../domain/usecases/interfaces/handlerCreateNit";
import { InputSobre } from "../../../../generalComponents/InputSobre";
import { Sobre } from "../../../../../domain/entities/sobre";
import { InputCidade } from "../../../../generalComponents/InputCidade";
import { UsuarioScyggzValidator } from "../../../../../domain/entities/UsuarioScyggzValidator";
import {
  InputTelefone,
  TypeInputTelefone,
} from "../../../../generalComponents/InputTelefone";
import { InputLinkedin } from "../../../../generalComponents/InputLinkedin";
import { UrlLinkedin } from "../../../../../domain/entities/urlLinkedin";

export const ConteudoSobreNit = ({
  handlerCacheNit,
  handlerGetUnidade,
  handlerCreateNit,
  onBack,
  onNext,
}: {
  handlerCacheNit: HandlerCacheNit;
  labelHeadline?: string;
  handlerGetUnidade: HandlerGetUnidade;
  handlerCreateNit: HandlerCreateNit;
  onNext: () => void;
  onBack: () => void;
}) => {
  const customStylesSelectInstituicaoCiencia: StylesConfig<
    InstituicaoOptions,
    true
  > = {
    control: () => ({
      border: "none",
      borderBottom: "3px #666600 solid",
      display: "flex",
      background: " #fff",
      borderBottomLeftRadius: "8px",
      textAlign: "left",
    }),
    input: () => ({
      position: "absolute",
    }),
  };

  const animatedComponents = makeAnimated();

  const { responsive } = useContext(ResizeContext);

  const { currentUserScyggz } = useContext(CurrentUserContext);

  const [parteDoNome, setParteDoNome] = useState("");

  const [cacheNit, setCacheNit] = useState<CacheNit>({});

  const [allUnidades, setAllUnidades] = useState<
    UnidadeInstituicaoDeCienciaETecnologiaOutput[]
  >([]);

  const [isAllUnidadesLoading, setIsAllUnidadesLoading] = useState(true);

  const [instituicaoOptions, setInstituicaoOptions] = useState<
    InstituicaoOptions[]
  >([]);

  useEffect(() => {
    setIsAllUnidadesLoading(true);
    handlerGetUnidade.getAllUnidade().then((getAllUnidadeResult) => {
      if (getAllUnidadeResult.ok) {
        setAllUnidades(getAllUnidadeResult.value);
      }
      setIsAllUnidadesLoading(false);
    });
  }, [handlerGetUnidade]);

  useEffect(() => {
    const instituicoes: SetStateAction<InstituicaoOptions[]> = [];
    allUnidades.forEach((unidade) => instituicoes.push(unidade.instituicao));
    const setInstituicao = new Set();
    const instituicoesFiltradas = instituicoes.filter((instituicao) => {
      const instituicaoDuplicada = setInstituicao.has(instituicao.id);
      setInstituicao.add(instituicao.id);
      return !instituicaoDuplicada;
    });
    setInstituicaoOptions(instituicoesFiltradas);
  }, [allUnidades]);

  useEffect(() => {
    handlerCacheNit.get().then((cacheNit) => {
      if (cacheNit.ok) {
        setCacheNit(cacheNit.value);
      }
    });
  }, [handlerCacheNit]);

  useEffect(() => {
    if (currentUserScyggz.user) {
      const parteDoNome: string =
        currentUserScyggz.user.nome.split(" ").at(0) ??
        currentUserScyggz.user.nome;

      setParteDoNome(parteDoNome);
    }
  }, [currentUserScyggz]);

  return (
    <div style={!responsive.sm ? { padding: "0 70px" } : {}}>
      <Formik<FormikValues>
        initialValues={{
          instituicoes: cacheNit.instituicoes ?? [],
          email: cacheNit?.email ?? "",
          nome_Nit: cacheNit?.nome ?? "",
          sobre: cacheNit?.sobre ?? "",
          website: cacheNit?.website ?? "",
          linkedin: cacheNit?.linkedin ?? "",
          telefone: {
            numero: cacheNit?.telefone ?? "",
            invalid: true,
          },
          cidade: cacheNit.cidade
            ? {
                cidade: cacheNit.cidade.cidade,
                estado: cacheNit.cidade.estado,
                pais: cacheNit.cidade.pais,
                paisCode: cacheNit.cidade.paisCode,
                tipo: "cidade",
              }
            : undefined,
        }}
        validationSchema={validator}
        validateOnMount={true}
        validateOnChange={true}
        validateOnBlur={true}
        enableReinitialize={true}
        onSubmit={async (values) => {
          await handlerCacheNit.save({
            nome: values.nome_Nit,
            sobre: values.sobre,
            cidade: values.cidade,
            email: values.email,
            linkedin: values.linkedin,
            telefone: values.telefone.numero,
            website: values.website,
            instituicoes: values.instituicoes,
          });

          if (!values.cidade) {
            console.warn("Erro cidade");
            return;
          }

          const createNit: InputNit = {
            cidade: values.cidade,
            email: values.email,
            gestores: [currentUserScyggz.user?.auth_user_id ?? ""],
            nome: values.nome_Nit,
            sobre: values.sobre,
            telefone: values.telefone.numero ?? "",
            instituicoes: values.instituicoes,
            linkedin: values.linkedin,
            website: values.website,
            created_in: new Date(),
            habilitado: false,
          };

          const createNitResult = await handlerCreateNit.createNit(createNit);

          if (!createNitResult.ok) {
            console.warn("Erro na criação do NIT");
            return;
          } else {
            handlerCacheNit.save({ id: createNitResult.value.id });
          }

          onNext();
        }}
      >
        {({
          isValid,
          isSubmitting,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          handleChange,
          handleBlur,
          errors,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit} className="pb-2">
            <h4 className="card-title text-center mb-3 fw-bold">
              <span>{parteDoNome && `${parteDoNome},`}</span>
              <span className="h5 fw-bold">{} por favor fale sobre o Nit.</span>
            </h4>

            <InputSobre
              label="Sobre o NIT*"
              value={values.sobre}
              onChange={(sobre) => {
                setFieldValue("sobre", sobre);
              }}
            />

            <div className="mb-4">
              <label htmlFor="iptNome" className="form-label">
                Nome do NIT*
              </label>
              <input
                type="text"
                className={
                  `${responsive.sm ? "imputMobile" : "form-control"}` +
                  (errors.nome_Nit && touched.nome_Nit ? " is-invalid" : "")
                }
                id="iptNome"
                name="nome_Nit"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.nome_Nit}
              />
              <div className="invalid-feedback flex-fill">
                {errors.nome_Nit}
              </div>
            </div>

            <div className="mb-4">
              <InputCidade
                value={values.cidade}
                label="Em qual cidade?*"
                onChange={(cidade) => {
                  setFieldValue("cidade", cidade);
                }}
                validate={UsuarioScyggzValidator.isInvalidCidade}
              />
            </div>

            <div className="mb-4">
              <InputTelefone
                label={!responsive.sm ? "Telefone*" : ""}
                value={values.telefone}
                onChange={(tel) => {
                  setFieldValue("telefone", tel);
                }}
                validate={(numero) => {
                  const preenchido: boolean = (numero?.length ?? 0) > 0;

                  if (!preenchido) {
                    return new Error("obrigatório");
                  }
                }}
              />
            </div>

            <div className="mb-3 col-12">
              <label className="form-label" htmlFor="selectInstituicao">
                Instituições (ICTs)
              </label>
              <Select<InstituicaoOptions, true>
                styles={
                  responsive.sm
                    ? customStylesSelectInstituicaoCiencia
                    : undefined
                }
                isMulti={true}
                isLoading={isAllUnidadesLoading}
                options={instituicaoOptions}
                getOptionLabel={(o) => o.nome}
                getOptionValue={(o) => o.id}
                components={animatedComponents}
                placeholder=""
                name="instituicoes"
                onChange={(instituicoes) => {
                  setFieldValue("instituicoes", instituicoes);
                }}
                onBlur={() => {
                  setFieldTouched("instituicoes", true);
                }}
                className={
                  errors.instituicoes && touched.instituicoes
                    ? " is-invalid "
                    : ""
                }
                value={values.instituicoes}
                noOptionsMessage={() => "sem opção"}
                inputId="selectInstituicao"
              />
              <div className="invalid-feedback flex-fill">
                {errors.instituicoes}
                {/* Campo obrigatório */}
              </div>
            </div>

            <div className="mb-4">
              <label htmlFor="iptWebsite" className="form-label">
                Website
              </label>
              <input
                type="text"
                className={
                  `${responsive.sm ? "imputMobile" : "form-control"}` +
                  (errors.website && touched.website ? " is-invalid" : "")
                }
                id="iptWebsite"
                name="website"
                onChange={website => {
                  const websiteStr = website.target.value;
                  let websiteFinal = websiteStr;

                  if(websiteStr === "http:/" || websiteStr === "https:/") {
                    websiteFinal = "";
                  } else if (!websiteStr.startsWith("http://") && !websiteStr.startsWith("https://")) {
                    websiteFinal = "https://" + websiteStr;
                  }

                  setFieldValue("website", websiteFinal);
                }}
                onBlur={handleBlur}
                value={values.website}
              />
              <div className="invalid-feedback flex-fill">{errors.website}</div>
            </div>

            <div className="mb-4">
              <label htmlFor="iptEmail" className="form-label">
                E-mail*
              </label>
              <input
                type="text"
                className={
                  `${responsive.sm ? "imputMobile" : "form-control"}` +
                  (errors.email && touched.email ? " is-invalid" : "")
                }
                id="iptEmail"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
              <div className="invalid-feedback flex-fill">{errors.email}</div>
            </div>

            <div className="mb-5">
              <InputLinkedin
                label="Linkedin"
                value={values.linkedin}
                onChange={(linkedin) => {
                  setFieldValue("linkedin", linkedin);
                }}
              />
            </div>

            <div className="mt-5 fw-bold text-center d-flex flex-wrap justify-content-center gap-2">
              {!responsive.sm && (
                <p style={{ color: "#525200", maxWidth: "500px", margin: "0" }}>
                  *Obs: Após a criação do perfil da Nit, você poderá convidar
                  demais membros da sua Organização para completar o perfil.
                </p>
              )}

              <div
                className=" text-end d-flex flex-nowrap"
                style={{
                  height: "fit-content",
                  width: responsive.sm ? "100%" : "",
                }}
              >
                {!responsive.sm && (
                  <ButtonLightGreen
                    className="me-3"
                    onClick={() => {
                      handlerCacheNit.save({
                        ...values,
                        telefone: values.telefone.numero,
                      });
                      onBack();
                    }}
                  >
                    Voltar
                  </ButtonLightGreen>
                )}
                <ButtonDarkGreen
                  disabled={!isValid || isSubmitting}
                  isSubmit={true}
                  className={
                    (!responsive.sm ? "ms-2" : "w-100") + " text-uppercase"
                  }
                >
                  Avançar
                </ButtonDarkGreen>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export type InputPainelSobreNitMobile = {
  nome: string;
};

type InstituicaoOptions = {
  id: string;
  nome: string;
};

export type FormikValues = {
  sobre: string;
  email: string;
  nome_Nit: string;
  telefone: TypeInputTelefone;
} & Partial<{
  cidade: CidadeOutputWithTipo;
  website: string; // TODO: VALIDATE URL
  linkedin: string; // TODO: VALIDATE URLc
  instituicoes: Pick<InstituicaoDeCienciaETecnologia, "id" | "nome">[];
}>;

export type OutputPainelSobreNitMobile = {
  sobre: string;
  email: string;
  nome_Nit: string;
  ano_fundacao: number;
  telefone: TypeInputTelefone;
} & Partial<{
  cidade: CidadeOutputWithTipo;
  website: string; // TODO: VALIDATE URL
  linkedin: string; // TODO: VALIDATE URL
  premiacoes: PremiacaoOutput[];
}>;

const validator = Yup.object().shape({
  cidade: Yup.object()
    .shape({
      cidade: Yup.string().required(),
      estado: Yup.string().required(),
      pais: Yup.string().required(),
      paisCode: Yup.string().required(),
    })
    .required("Cidade é obrigatório"),
  sobre: Yup.string()
    .required(
      "Campo obrigatório, deve ter no mínimo " +
        Sobre.MINIMO_LENGTH +
        " caracteres"
    )
    .min(Sobre.MINIMO_LENGTH, "Mínimo " + Sobre.MINIMO_LENGTH + " caracteres.")
    .max(
      Sobre.MAXIMO_LENGTH,
      "Máximo de " + Sobre.MAXIMO_LENGTH + " caracteres."
    ),
  nome_Nit: Yup.string()
    .required("Campo obrigatório")
    .min(1, "Campo obrigatório"),
  website: Yup.string().url("Deve ser um link").min(5, "Mínimo 5 caracteres"),
  telefone: Yup.object()
    .shape({
      numero: Yup.string().required(),
      invalid: Yup.boolean().isFalse(),
    })
    .required("Cidade é obrigatório"),
  linkedin: Yup.string().test((urlLinkedin) => {
    return urlLinkedin ? UrlLinkedin.isValidUrlLinkedin(urlLinkedin) : true;
  }),
  email: Yup.string().required("Campo obrigatório").email("Campo inválido"),
});
