import arvore from "../../../../assets/images/icone_arvore.png";

import {
  BannerPessoasRelevantesItem,
  TypeBannerPessoasRelevantesItem,
} from "./BannerPessoasRelevantesItem";

export function BannerPessoasRelevantes({
  items,
}: {
  items: TypeBannerPessoasRelevantesItem[];
}) {
  return (
    <div className="card shadow-sm rounded-4">
      <div className="card-body">
        <h5 className="card-title fw-bolder pb-5 d-flex justify-content-center gap-1 align-items-center">
          <img width={"25rem"} src={arvore} alt="Logo árvore scyggz" />
          <span>Pessoas relevantes</span>
        </h5>

        {items.map((item, i) => (
          <BannerPessoasRelevantesItem
            item={item}
            key={item.url_link}
            className={i > 0 ? "mt-2" : ""}
          />
        ))}
      </div>
    </div>
  );
}
