import { useCallback, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";

import { InputUpdateStartup, Startup } from "../../../domain/entities/Startup";
import { InputPremiacoes } from "../../generalComponents/InputPremiacoes/InputPremiacoes";
import { HandlerUpdateStartup } from "../../../domain/usecases/interfaces/handlerUpdateStartup";
import { InputTextarea } from "../../generalComponents/InputTextarea";
import { InputEstagioStartup } from "../../generalComponents/InputEstagioStartup";
import { InputCidade } from "../../generalComponents/InputCidade";
import { InputModelosNegocio } from "../../generalComponents/InputModelosNegocio";
import { InputSegmentosAtuacao } from "../../generalComponents/InputSegmentosAtuacao";
import { InputMecanismosSolucao } from "../../generalComponents/InputMecanismosSolucao";
import { InputNumber } from "../../generalComponents/InputNumber";
import { InputHub } from "../../generalComponents/InputHub";
import { InputStatusHub } from "../../generalComponents/InputStatusHub";

export function ModalEditStartup({
  initialValue,
  show,
  onShowChange,
  handlerUpdateStartup,
  afterUpdate,
}: {
  initialValue: InputUpdateStartup;
  show: boolean;
  onShowChange: (show: boolean) => void;
  handlerUpdateStartup?: HandlerUpdateStartup;
  afterUpdate?: () => void;
}) {
  const [startupToEdit, setStartupToEdit] =
    useState<InputUpdateStartup>(initialValue);
  const [errors, setErrors] = useState<
    Error | Partial<Record<keyof InputUpdateStartup, Error>>
  >();
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isEditingPremiacoes, setIsEditingPremiacoes] =
    useState<boolean>(false);

  const onUpdate = useCallback(async () => {
    setIsUpdating(true);

    if (handlerUpdateStartup) {
      const result = await handlerUpdateStartup.updateStartup(startupToEdit);

      if (result.ok) {
        if (afterUpdate) afterUpdate();
        onShowChange(false);
      } else {
        setErrors(result.error);
      }
    }

    setIsUpdating(false);
  }, [afterUpdate, handlerUpdateStartup, onShowChange, startupToEdit]);

  useEffect(() => {
    setStartupToEdit(initialValue);
  }, [initialValue]);
  useEffect(() => {
    setErrors(Startup.validateUpdate(startupToEdit));
  }, [startupToEdit]);

  return (
    <Modal
      show={show}
      onHide={() => onShowChange(false)}
      centered
      size="lg"
      scrollable={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>Editar startup</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputTextarea
          label="Sobre*"
          value={startupToEdit.sobre ?? ""}
          onChange={(sobre) => {
            setStartupToEdit({
              ...startupToEdit,
              sobre,
            });
          }}
          id="iptSobre"
          title="sobre"
          min={{
            value: Startup.SOBRE_MINIMO_LENGTH,
            errorMessage: Startup.SOBRE_MINIMO_MESSAGE,
          }}
          max={{
            value: Startup.SOBRE_MAXIMO_LENGTH,
            errorMessage: Startup.SOBRE_MAXIMO_MESSAGE,
          }}
        />

        <InputEstagioStartup
          label="Estágio da startup*"
          value={startupToEdit.estagio}
          onChange={(estagio) => {
            setStartupToEdit({
              ...startupToEdit,
              estagio,
            });
          }}
          validate={Startup.validateEstagio}
          className="mb-3"
        />

        <InputCidade
          value={startupToEdit.cidade}
          label="Cidade*"
          onChange={(cidade) => {
            setStartupToEdit({
              ...startupToEdit,
              cidade,
            });
          }}
          validate={Startup.validateCidade}
          className="mb-3"
        />

        <InputModelosNegocio
          label="Modelo de negócio*"
          value={startupToEdit.modelo_negocio ?? []}
          onChange={(modelo_negocio) => {
            setStartupToEdit({
              ...startupToEdit,
              modelo_negocio,
            });
          }}
          validate={Startup.validateModelosNegocio}
          className="mb-3"
        />

        <InputSegmentosAtuacao
          label="Atua nos seguintes segmentos*"
          value={startupToEdit.segmento_atuacao ?? []}
          onChange={(segmento_atuacao) => {
            setStartupToEdit({
              ...startupToEdit,
              segmento_atuacao,
            });
          }}
          validate={Startup.validateSegmentoAtuacao}
          className="mb-3"
        />

        <InputMecanismosSolucao
          label="Mecanismo de solução tecnológica"
          value={startupToEdit.mecanismo_solucao ?? []}
          onChange={(mecanismo_solucao) => {
            setStartupToEdit({
              ...startupToEdit,
              mecanismo_solucao,
            });
          }}
          validate={Startup.validateMecanismoSolucao}
          className="mb-3"
        />

        <InputNumber
          label="Quantidade de funcionários*"
          onChange={(qtde_funcionarios) => {
            setStartupToEdit({
              ...startupToEdit,
              qtde_funcionarios,
            });
          }}
          id="iptQtdeFuncionarios"
          min={{
            value: Startup.QTDE_FUNCIONARIOS_MINIMO,
            errorMessage: Startup.QTDE_FUNCIONARIOS_MINIMO_MESSAGE,
          }}
          title="qtde_funcionarios"
          value={startupToEdit.qtde_funcionarios ?? 0}
          onBlur={() => {
            setStartupToEdit({
              ...startupToEdit,
            });
          }}
          className="mb-3"
        />

        <InputHub
          label="Hub de inovação"
          onChange={(hub) => {
            setStartupToEdit((startupToEdit) => {
              if (startupToEdit.hub) {
                if (hub) {
                  startupToEdit.hub.hub = {
                    id: hub.id,
                    nome: hub.nome,
                  };
                  return startupToEdit;
                }

                startupToEdit = { ...startupToEdit, hub: undefined };
                return startupToEdit;
              }

              if (hub) {
                startupToEdit = {
                  ...startupToEdit,
                  hub: {
                    hub,
                    status: "graduada", // TODO: FIX: APONTAR P VALOR
                  },
                };
                return startupToEdit;
              }

              return startupToEdit;
            });
          }}
          validate={Startup.validateHub}
          value={startupToEdit.hub?.hub}
          className="mb-3 col-12"
        />

        <InputStatusHub
          label="Hub de Inovação (status)"
          onChange={(status_hub) => {
            setStartupToEdit((startupToEdit) => {
              if (startupToEdit.hub) {
                startupToEdit.hub.status = status_hub ?? "graduada";

                return { ...startupToEdit };
              }

              return startupToEdit;
            });
          }}
          validate={(status_hub) => {
            if (startupToEdit.hub && !status_hub) {
              return new Error("obrigatório");
            }
          }}
          disabled={!startupToEdit.hub?.hub}
          value={startupToEdit.hub?.status}
          className="mb-3 col-12"
        />

        <InputPremiacoes
          label="Premiações"
          value={startupToEdit.premiacoes ?? []}
          onChange={(premiacoes) => {
            setStartupToEdit({
              ...startupToEdit,
              premiacoes,
            });
          }}
          showTip={false}
          isEditing={setIsEditingPremiacoes}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="success"
          disabled={!!errors || isUpdating || isEditingPremiacoes}
          onClick={onUpdate}
        >
          Salvar startup
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
