import { useCallback, useEffect, useState } from "react";
import Cropper, { Area, Point, MediaSize, Size } from "react-easy-crop";

import { cropImage } from "../../hooks/cropImage";
import FormRange from "react-bootstrap/esm/FormRange";

export function ImageCrop({
  alt,
  fileFotoDataURL,
  onChangeBlob,
}: {
  alt?: string;
  fileFotoDataURL: string;
  onChangeBlob: (blob: Blob) => void;
}) {
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [cropSize, setCropSize] = useState<Size>({
    height: 0,
    width: 0,
  });
  const [zoom, setZoom] = useState(1);
  const [imgSize, setImgSize] = useState<MediaSize>({
    height: 0,
    width: 0,
    naturalHeight: 0,
    naturalWidth: 0,
  });
  const [minZoom, setMinZoom] = useState(1);

  useEffect(() => {
    setCrop({
      x: 0,
      y: 0,
    });
    setZoom(1);
  }, [fileFotoDataURL]);

  useEffect(() => {
    const max = Math.max(imgSize.naturalHeight, imgSize.naturalWidth);
    const min = Math.min(imgSize.naturalHeight, imgSize.naturalWidth);

    if (max == 0) setMinZoom(0);
    else setMinZoom(min / max);
  }, [imgSize]);

  const onCropChange = useCallback(
    ({ x, y }: Point) => {
      const widthFinal: number = (imgSize?.width ?? 0) * zoom;
      const maxWidth: number = widthFinal / 2 - cropSize.width / 3;
      const minWidth: number = -1 * maxWidth;

      const heightFinal: number = (imgSize?.height ?? 0) * zoom;
      const maxHeight: number = heightFinal / 2 - cropSize.height / 3;
      const minHeight: number = -1 * maxHeight;

      if (x > maxWidth) {
        x = maxWidth;
      } else if (x < minWidth) {
        x = minWidth;
      }

      if (y > maxHeight) {
        y = maxHeight;
      } else if (y < minHeight) {
        y = minHeight;
      }

      setCrop({
        x,
        y,
      });
    },
    [cropSize, imgSize, zoom]
  );

  const onCropComplete = useCallback(
    async (_, croppedAreaPixels: Area) => {
      const blob = await cropImage(
        fileFotoDataURL,
        croppedAreaPixels.x,
        croppedAreaPixels.y,
        croppedAreaPixels.width,
        croppedAreaPixels.height
      );

      onChangeBlob(blob);
    },
    [fileFotoDataURL, onChangeBlob]
  );

  return (
    <>
      <Cropper
        mediaProps={{
          alt,
        }}
        minZoom={minZoom}
        objectFit="auto-cover"
        restrictPosition={false}
        image={fileFotoDataURL}
        crop={crop}
        zoom={zoom}
        aspect={1}
        onCropChange={onCropChange}
        onZoomChange={setZoom}
        onCropComplete={onCropComplete}
        showGrid={false}
        cropShape="round"
        setMediaSize={setImgSize}
        onCropSizeChange={setCropSize}
      />

      <FormRange
        min={minZoom}
        max={3}
        value={zoom}
        step={"any"}
        className="position-absolute top-100 pt-5"
        onChange={({ target: { value } }) => {
          setZoom(parseFloat(value));
        }}
      />
    </>
  );
}
