import { useContext, useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";

import {
  UsuarioScyggz,
  TIPOS_PERFIL,
  TipoPerfil,
} from "../../../../domain/entities/usuarioScyggz";
import { ModalAddCientista } from "../cientista/ModalAddCientista";
import { CurrentUserContext } from "../../../../globalStore/CurrentUserContext";
import { ModalAddMentor } from "../mentor/ModalAddMentor";
import { ModalAddInvestidor } from "../investidor/ModalAddInvestidor";

type AbasPerfisProps = {
  isCurrentUser: boolean;
  user: Pick<
    UsuarioScyggz,
    "auth_user_id" | "avatar_path" | "nome" | "headline" | "perfis" | "cidade"
  >;
  perfilAtivo?: TipoPerfil;
  formatURL: (input: { user_id: string; perfil: string }) => string;
  onClickOrganizacoes: (show: boolean) => void;
  showOrganizacoes: boolean;
  userHasOrganizacoes: boolean;
};

export function AbasPerfis({
  user,
  isCurrentUser,
  perfilAtivo = undefined,
  formatURL,
  onClickOrganizacoes,
  showOrganizacoes,
  userHasOrganizacoes,
}: AbasPerfisProps) {
  const { refreshCurrentUserScyggz, seeAsPublic } =
    useContext(CurrentUserContext);

  const todosPerfis = useMemo(
    () => Object.keys(user.perfis ?? {}),
    [user.perfis]
  );
  const perfisToAdd = useMemo(
    () =>
      TIPOS_PERFIL.filter(
        (perfil) => !todosPerfis.some((perfilExiste) => perfilExiste == perfil)
      ),
    [todosPerfis]
  );
  const perfisHabilitados = useMemo(
    () =>
      Object.entries(user.perfis ?? {})
        .filter(([, isHabilitado]) => isHabilitado)
        .map(([perfil]) => perfil),
    [user.perfis]
  );

  const [showAddPerfil, setShowAddPerfil] = useState<TipoPerfil>();

  const pathOrganizacoes = useMemo<string>(
    () => "/user/" + user.auth_user_id + "/organizacoes",
    [user.auth_user_id]
  );

  // const formatURLOrganizacao: (input: { id_user: string }) => string = () =>
  //   "/user/" + user.auth_user_id + "/organizacoes";

  return (
    <div className="d-flex gap-2 align-items-end">
      {(isCurrentUser ? todosPerfis : perfisHabilitados).map((perfil) => {
        return (
          <NavLink
            key={perfil}
            className={
              "fs-5 fw-bold px-2 px-sm-3 px-md-4 py-2 rounded-top " +
              (perfilAtivo == perfil && !showOrganizacoes
                ? "bg-white color-spinoff"
                : "bg-body-secondary text-secondary")
            }
            to={formatURL({
              user_id: user.auth_user_id,
              perfil,
            })}
            onClick={() => {
              onClickOrganizacoes(false);
            }}
          >
            {perfil}
          </NavLink>
        );
      })}

      {userHasOrganizacoes && (
        <NavLink
          key={pathOrganizacoes}
          className={
            "fs-5 fw-bold px-2 px-sm-3 px-md-4 py-2 rounded-top " +
            (showOrganizacoes
              ? "bg-white color-spinoff"
              : "bg-body-secondary text-secondary")
          }
          to={""}
          onClick={() => {
            onClickOrganizacoes(true);
          }}
        >
          Organizações
        </NavLink>
      )}

      {isCurrentUser && perfisToAdd.length > 0 && !seeAsPublic && (
        <>
          <Dropdown>
            <Dropdown.Toggle className="bg-body-secondary text-secondary rounded-circle border-0 m-1 removeCaret">
              <FontAwesomeIcon icon={faAdd} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {perfisToAdd.map((perfilToAdd) => (
                <Dropdown.Item
                  key={perfilToAdd}
                  onClick={() => setShowAddPerfil(perfilToAdd)}
                >
                  criar perfil {perfilToAdd}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>

          <ModalAddCientista
            user={user}
            show={showAddPerfil === "cientista"}
            onShowChange={(show) => {
              if (show) setShowAddPerfil("cientista");
              else setShowAddPerfil(undefined);
            }}
            onCreate={() => {
              setShowAddPerfil(undefined);
              if (refreshCurrentUserScyggz) refreshCurrentUserScyggz();
            }}
          />

          <ModalAddMentor
            user={user}
            show={showAddPerfil === "mentor"}
            onShowChange={(show) => {
              if (show) setShowAddPerfil("mentor");
              else setShowAddPerfil(undefined);
            }}
            onCreate={() => {
              setShowAddPerfil(undefined);
              if (refreshCurrentUserScyggz) refreshCurrentUserScyggz();
            }}
          />

          <ModalAddInvestidor
            user={user}
            show={showAddPerfil === "investidor"}
            onShowChange={(show) => {
              if (show) setShowAddPerfil("investidor");
              else setShowAddPerfil(undefined);
            }}
            onCreate={() => {
              setShowAddPerfil(undefined);
              if (refreshCurrentUserScyggz) refreshCurrentUserScyggz();
            }}
          />
        </>
      )}
    </div>
  );
}
