import { Firestore, getDoc, doc, Timestamp } from "firebase/firestore";

import { Result } from "../../typings";
import { HandlerGetMentor } from "../../domain/usecases/interfaces/getPerfil/handlerGetMentor";
import { MentorOutput } from "../../domain/entities/Mentor";
import { fieldToDate } from "../utils/fieldToDate";

export class HandlerGetMentorFirebase implements HandlerGetMentor {
  constructor(private firestore: Firestore) {}

  async get(auth_id: string): Promise<Result<MentorOutput>> {
    return new Promise((resolve) => {
      const docMentor = doc(
        this.firestore,
        "usuarios",
        auth_id,
        "perfis/mentor"
      );
      getDoc(docMentor)
        .then((docMentorSnapshot) => {
          if (docMentorSnapshot.exists()) {
            const mentor = this.convert(docMentorSnapshot.data(), auth_id);

            resolve({
              ok: true,
              value: mentor,
            });
            return;
          }

          resolve({
            ok: false,
            error: new Error("Usuário não encontrado"),
          });
        })
        .catch((error) =>
          resolve({
            ok: false,
            error,
          })
        );
    });
  }

  private convert(
    input: Partial<Omit<MentorOutput, "created_in">> &
      Partial<{ created_in: Timestamp }>,
    id: string
  ): MentorOutput {
    return {
      created_in: fieldToDate(input.created_in),
      user_id: id,
      areas_de_especializacao: input.areas_de_especializacao ?? [],
      nome: input.nome ?? "",
      habilitado: !!input.habilitado,
      sobre_o_mentor: input.sobre_o_mentor ?? "",
      interesse_startup_estagio: input.interesse_startup_estagio ?? [],
      interesse_startup_modelo_negocio:
        input.interesse_startup_modelo_negocio ?? [],
      interesse_startup_segmento_atuacao:
        input.interesse_startup_segmento_atuacao ?? [],
      interesse_startup_mecanismo_solucao:
        input.interesse_startup_mecanismo_solucao ?? [],
      interesse_startup_localizacoes:
        input.interesse_startup_localizacoes ?? [],
      horas_disponibilidade_mensal: input.horas_disponibilidade_mensal,
      premiacoes: input.premiacoes,
    };
  }
}
