import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { CurrentUserContext } from "../../../../../globalStore/CurrentUserContext";
import { getNomeFromStorage } from "../../../../../hooks/handlerNameFromStorage";
import { ContainerResponsive } from "../../../../generalComponents/ContainerResponsive";
import { ConteudoCadastroInfosPessoais } from "../../components/ConteudoCadastroInfosPessoais";
import { HandlersDatabaseContext } from "../../../../../globalStore/HandlersProviders/HandlersDatabaseContext";
import { HandleCacheNewUser } from "../../../../../domain/usecases/interfaces/HandleCacheNewUser";

type PainelCadastroUsuarioStartupProps = {
  handlerCacheNewUser?: HandleCacheNewUser;
};

export const PainelCadastroUsuarioStartup = ({
  handlerCacheNewUser,
}: PainelCadastroUsuarioStartupProps) => {
  const navigate = useNavigate();

  const { currentUserAuth, currentUserScyggz } = useContext(CurrentUserContext);
  const {
    handlerAceiteGet,
    handlerCreateUsuarioScyggz,
    handlerSaveUrlLinkedin,
    handlerUpdateUsuarioScyggz,
    handlerSaveTelefone,
  } = useContext(HandlersDatabaseContext);

  const [dataAceite, setDataAceite] = useState<Date>(new Date());
  const [userExiste, setUserExiste] = useState(false);
  const [salvandoNovoUsuario, setSalvandoNovoUsuario] = useState(false);

  useEffect(() => {
    handlerCacheNewUser?.get().then((newUser) => {
      if (newUser.ok) {
        if (currentUserScyggz?.user && !newUser.value && !salvandoNovoUsuario) {
          setUserExiste(true);
        }
      }
    });

    handlerAceiteGet?.get("startup").then((handlerAceiteResult) => {
      if (handlerAceiteResult.ok) {
        if (handlerAceiteResult.value) {
          setDataAceite(handlerAceiteResult.value);
        }
      }
    });
  }, [
    handlerAceiteGet,
    currentUserScyggz,
    handlerCacheNewUser,
    salvandoNovoUsuario,
  ]);

  const paginacaoElegibilidadeStartup = {
    start: 3,
    end: 7,
  };

  const goToNextPage = () => {
    if (userExiste) {
      navigate("/cadastro/startup/5");
    } else {
      navigate("/cadastro/startup/4");
    }
  };

  return (
    <section className="h-100" data-testid="painel-cadastro-usuario-startup">
      <ContainerResponsive
        textoMarcaDagua={"Startup"}
        paginacao={paginacaoElegibilidadeStartup}
        backUrlMobile="/cadastro/startup/1"
        showLogo={true}
      >
        <ConteudoCadastroInfosPessoais
          inputPainelCadastroInfosPessoais={{
            auth_id: currentUserAuth.user_auth?.auth_id ?? "",
            nome:
              currentUserAuth.user_auth?.displayName ??
              getNomeFromStorage() ??
              "",
            aceite_termo_de_servico: dataAceite,
            aceite_politica_de_privacidade: dataAceite,
            phoneNumber: currentUserScyggz.user?.telefone
              ? {
                  numero: currentUserScyggz.user.telefone,
                  invalid: false,
                }
              : undefined,
            cidade: currentUserScyggz.user?.cidade,
            urlLinkedin: currentUserScyggz.user?.url_linkedin,
            headline: currentUserScyggz.user?.headline,
          }}
          handlerSaveUrlLinkedin={handlerSaveUrlLinkedin}
          handlerSaveTelefone={handlerSaveTelefone}
          handlerCreateUsuarioScyggz={handlerCreateUsuarioScyggz}
          handlerUpdateUsuarioScyggz={handlerUpdateUsuarioScyggz}
          onCadastro={goToNextPage}
          labelHeadline="Seu cargo/função na Startup"
          userExiste={userExiste}
          handlerCacheNewUser={handlerCacheNewUser}
          handlerSalvandoNovoUsuario={setSalvandoNovoUsuario}
        />
      </ContainerResponsive>
    </section>
  );
};
