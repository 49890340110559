import { useContext } from "react";

import { ProgramaConexao } from "../../../../../domain/entities/ProgramaConexao";
import { AbasPrograma } from "./AbasPrograma";
import { useAbaProgramaToShow } from "../../../../../hooks/useAbaPrograma";
import { CurrentUserContext } from "../../../../../globalStore/CurrentUserContext";
import { MuralProgramaDeConexao } from "./MuralProgramaDeConexao";
import { MembrosProgramaDeConexao } from "./MembrosProgramaDeConexao";
import { EstatisticasProgramaDeConexao } from "./EstatisticasProgramaDeConexao";

export function BodyProgramaDeConexao({
  programa,
  isGestor,
}: {
  programa: Pick<
    ProgramaConexao,
    | "id"
    | "stakeholder_type_group_a"
    | "stakeholder_type_group_b"
    | "nome"
    | "logo_path"
    | "message_to_a"
    | "message_to_b"
  >;
  isGestor: boolean;
}) {
  const { abaToShow, abasPermitidas } = useAbaProgramaToShow(
    "aba_programa",
    isGestor
  );
  const { currentUserAuth, currentUserScyggz } = useContext(CurrentUserContext);

  return (
    <div className="col-12">
      <div className="ps-3">
        <AbasPrograma
          urls={{
            membros: "/programa_conexao/" + programa.id + "/membros",
            estatisticas: "/programa_conexao/" + programa.id + "/estatisticas",
            mural: "/programa_conexao/" + programa.id + "/mural",
          }}
          abaToShow={abaToShow}
          abasPermitidas={abasPermitidas}
        />
      </div>

      {abaToShow == "mural" ? (
        <MuralProgramaDeConexao
          programa={{
            id: programa.id,
          }}
          isGestor={isGestor}
          currentUserName={{
            user_id: currentUserAuth.user_auth?.auth_id ?? "",
            nome:
              currentUserScyggz.user?.nome ??
              currentUserAuth.user_auth?.displayName ??
              "",
            avatar_path: currentUserScyggz.user?.avatar_path?.path,
          }}
        />
      ) : (
        <>
          {abaToShow == "membros" ? (
            <MembrosProgramaDeConexao
              className="border-top-0"
              isGestor={isGestor}
              programa={programa}
            />
          ) : (
            <>
              {abaToShow == "estatisticas" && (
                <EstatisticasProgramaDeConexao
                  className="border-top-0"
                  id_programa={programa.id}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}
