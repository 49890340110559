import { Firestore, doc, setDoc } from "@firebase/firestore";
import { FirebaseError } from "firebase/app";

import { Result } from "../typings";
import {
  FirestoreErrorsFactory,
  FirestoreError,
} from "../domain/erros/FirestoreErrors";
import {
  HandlerUpdateCientista,
  InputHandlerUpdateCientista,
} from "../domain/usecases/interfaces/handlerUpdateCientista";
import { Cientista } from "../domain/entities/Cientista";

export class HandlerUpdateCientistaFirebase implements HandlerUpdateCientista {
  constructor(private firestore: Firestore) {}

  async updateCientista(
    inputCientista: InputHandlerUpdateCientista & { user_id: string }
  ): Promise<Result<InputHandlerUpdateCientista>> {
    const validateCientistaResult = Cientista.validateUpdate(inputCientista);

    if (!validateCientistaResult.ok) {
      return validateCientistaResult;
    }

    return new Promise((resolve) => {
      const docUser = doc(
        this.firestore,
        "usuarios",
        inputCientista.user_id,
        "perfis/cientista"
      );

      setDoc(docUser, inputCientista, {
        merge: true,
      })
        .then(() => {
          resolve({
            ok: true,
            value: inputCientista,
          });
        })
        .catch((error: FirebaseError) => {
          const instanciaErro = FirestoreErrorsFactory.createErrorsValidation(
            <FirestoreError>error.code
          );
          if (instanciaErro) {
            resolve({
              ok: false,
              error: instanciaErro,
            });
            return;
          }

          console.error("Erro na atualização: ", error.code, error.message);

          resolve({
            ok: false,
            error,
          });
        });
    });
  }
}
