/* eslint-disable @typescript-eslint/no-explicit-any */

import { deleteField } from "@firebase/firestore";

export function removeUndefined(input: any) {
  const valuesToUpdate: any = {};

  Object.entries(input).forEach(([key, value]) => {
    if (value == undefined) {
      valuesToUpdate[key] = deleteField();
    } else {
      valuesToUpdate[key] = value;
    }
  });

  return valuesToUpdate;
}
