import { Result } from "../../typings";
import { TypeHandlerCreateInvestidor } from "../usecases/interfaces/HandlerCreateInvestidor";
import { HandlerValidate } from "../usecases/interfaces/HandlerValidate";
import { InputHandlerUpdateInvestidor } from "../usecases/interfaces/handlerUpdateInvestidor";
import { EstagioStartup } from "./EstagioStartup";
import { InvestidorAnjo } from "./InvestidorAnjo";
import { AreaEspecializacao } from "./areaEspecializacaoInvestidorAnjo";
import { LocalizacaoOutput, isLocalizacaoValida } from "./localizacao";
import { MecanismoDeSolucaoTecnologica } from "./mecanismoDeSolucaoTecnologica";
import { ModeloNegocio } from "./modeloNegocio";
import { SegmentoAtuacao } from "./segmentoAtuacao";
import { TeseDeInvestimento } from "./teseDeInvestimento";

export class InvestidorAnjoValidator
  implements HandlerValidate<InputHandlerUpdateInvestidor>
{
  validateUpdate(
    attributes: Partial<InputHandlerUpdateInvestidor>
  ): Result<null, Partial<Record<keyof InvestidorAnjo, Error>>> {
    const errors: Partial<Record<keyof InvestidorAnjo, Error>> = {};

    if (attributes.user_id !== undefined) {
      const error = InvestidorAnjoValidator.isInvalidUserId(attributes.user_id);
      if (error) {
        errors.user_id = error;
      }
    }

    if (attributes.areas_de_especializacao !== undefined) {
      const error = InvestidorAnjoValidator.isInvalidAreasEspecializacao(
        attributes.areas_de_especializacao
      );

      if (error) {
        errors.areas_de_especializacao = error;
      }
    }

    if (attributes.tese_de_investimento !== undefined) {
      const teseInvestimentoResult = TeseDeInvestimento.create({
        conteudo: attributes.tese_de_investimento?.conteudo.trim() ?? "",
      });

      if (!teseInvestimentoResult.ok) {
        if (Array.isArray(teseInvestimentoResult.error)) {
          errors.tese_de_investimento = new Error(
            teseInvestimentoResult.error
              .map((error) => error.message)
              .join(", ")
          );
        } else {
          errors.tese_de_investimento = teseInvestimentoResult.error;
        }
      }
    }

    if (attributes.interesse_startup_estagio !== undefined) {
      const error = InvestidorAnjoValidator.isInvalidInteresseStartupEstagio(
        attributes.interesse_startup_estagio
      );

      if (error) {
        errors.interesse_startup_estagio = error;
      }
    }

    if (attributes.interesse_startup_modelo_negocio !== undefined) {
      const error =
        InvestidorAnjoValidator.isInvalidInteresseStartupModeloNegocio(
          attributes.interesse_startup_modelo_negocio
        );

      if (error) {
        errors.interesse_startup_modelo_negocio = error;
      }
    }

    if (attributes.interesse_startup_segmento_atuacao !== undefined) {
      const error =
        InvestidorAnjoValidator.isInvalidInteresseStartupSegmentoAtuacao(
          attributes.interesse_startup_segmento_atuacao
        );

      if (error) {
        errors.interesse_startup_segmento_atuacao = error;
      }
    }

    if (attributes.interesse_startup_mecanismo_solucao !== undefined) {
      const error =
        InvestidorAnjoValidator.isInvalidInteresseStartupMecanismoSolucao(
          attributes.interesse_startup_mecanismo_solucao
        );

      if (error) {
        errors.interesse_startup_mecanismo_solucao = error;
      }
    }

    if (Object.keys(errors).length > 0) {
      return {
        ok: false,
        error: errors,
      };
    }

    return {
      ok: true,
      value: null,
    };
  }

  validateCreate(
    attributes: TypeHandlerCreateInvestidor
  ): Result<null, Partial<Record<keyof InvestidorAnjo, Error>>> {
    const errors: Partial<Record<keyof InvestidorAnjo, Error>> = {};

    let error = InvestidorAnjoValidator.isInvalidUserId(attributes.user_id);
    if (error) {
      errors.user_id = error;
    }

    error = InvestidorAnjoValidator.isInvalidAreasEspecializacao(
      attributes.areas_de_especializacao
    );
    if (error) {
      errors.areas_de_especializacao = error;
    }

    const teseInvestimentoResult = TeseDeInvestimento.create({
      conteudo: attributes.tese_de_investimento?.conteudo.trim() ?? "",
    });
    if (!teseInvestimentoResult.ok) {
      if (Array.isArray(teseInvestimentoResult.error)) {
        errors.tese_de_investimento = new Error(
          teseInvestimentoResult.error.map((error) => error.message).join(", ")
        );
      } else {
        errors.tese_de_investimento = teseInvestimentoResult.error;
      }
    }

    error = InvestidorAnjoValidator.isInvalidInteresseStartupEstagio(
      attributes.interesse_startup_estagio
    );
    if (error) {
      errors.interesse_startup_estagio = error;
    }

    error = InvestidorAnjoValidator.isInvalidInteresseStartupModeloNegocio(
      attributes.interesse_startup_modelo_negocio
    );
    if (error) {
      errors.interesse_startup_modelo_negocio = error;
    }

    error = InvestidorAnjoValidator.isInvalidInteresseStartupSegmentoAtuacao(
      attributes.interesse_startup_segmento_atuacao
    );
    if (error) {
      errors.interesse_startup_segmento_atuacao = error;
    }

    error = InvestidorAnjoValidator.isInvalidInteresseStartupMecanismoSolucao(
      attributes.interesse_startup_mecanismo_solucao
    );
    if (error) {
      errors.interesse_startup_mecanismo_solucao = error;
    }

    if (Object.keys(errors).length > 0) {
      return {
        ok: false,
        error: errors,
      };
    }

    return {
      ok: true,
      value: null,
    };
  }

  static isInvalidUserId(user_id: string): Error | undefined {
    if (user_id.trim().length < 1) {
      return new Error("User id inválido");
    }
  }

  static isInvalidAreasEspecializacao(
    areasEspecializacao: AreaEspecializacao[]
  ): Error | undefined {
    if (areasEspecializacao.length < 1) {
      return new Error("Precisa especificar área de especialização");
    }
  }

  static isInvalidInteresseStartupEstagio(
    estagios: EstagioStartup[]
  ): Error | undefined {
    if (estagios.length < 1) {
      return new Error("Precisa especificar estágio da Startup de interesse");
    }
  }

  public static isInvalidInteresseStartupModeloNegocio(
    modelos: ModeloNegocio[]
  ): Error | undefined {
    if (modelos.length < 1) {
      return new Error(
        "Precisa especificar modelo de negócio da Startup de interesse"
      );
    }
  }

  static isInvalidInteresseStartupSegmentoAtuacao(
    segmentos: SegmentoAtuacao[]
  ): Error | undefined {
    if (segmentos.length < 1) {
      return new Error(
        "Precisa especificar segmento de atuação da Startup de interesse"
      );
    }
  }

  static isInvalidInteresseStartupMecanismoSolucao(
    mecanismos: MecanismoDeSolucaoTecnologica[]
  ): Error | undefined {
    if (mecanismos.length < 1) {
      return new Error(
        "Precisa especificar mecanismos de solução da Startup de interesse"
      );
    }
  }

  public static isInvalidLocais(
    locais: LocalizacaoOutput[] | undefined
  ): Error | undefined {
    const someInvalid: boolean = (locais ?? [])?.some((local) => {
      return !isLocalizacaoValida(local);
    });

    if (someInvalid) {
      return new Error("Inválido");
    }
  }
}
