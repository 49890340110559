import { Result } from "../../typings";

export class PitchPdfStartup {
  private constructor(public id_startup: string, public file: Blob) {}

  public static create({
    id_startup,
    file,
  }: InputPitchPdfStartup): Result<PitchPdfStartup> {
    const errors: Error[] = [];

    id_startup = id_startup.trim();
    const isAuthValid: boolean = id_startup.length > 0;
    if (!isAuthValid) {
      errors.push(new Error("Id startup inválido"));
    }

    if (errors.length > 0) {
      return {
        ok: false,
        error: errors[0],
      };
    }

    return {
      ok: true,
      value: new PitchPdfStartup(id_startup, file),
    };
  }

  public get path(): string {
    return "startup/" + this.id_startup + "/pitch_pdf";
  }
}

export type InputPitchPdfStartup = {
  id_startup: string;
  file: Blob;
};

export type OutputPitchPdfStartup = {
  path: string;
};
