import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { CurrentUserContext } from "../../../../../globalStore/CurrentUserContext";
import { ContainerResponsive } from "../../../../generalComponents/ContainerResponsive";
import { OnEnterPrevent } from "../../../../generalComponents/OnEnterPrevent";
import { ConteudoAvatar } from "../../components/ConteudoAvatar";

export const PainelCadastroFotoUsuarioCientista = () => {
  const navigate = useNavigate();
  const { currentUserScyggz } = useContext(CurrentUserContext);

  const paginacao = {
    start: 4,
    end: 5,
  };

  const backUrl = "/cadastro/cientista/3";

  return (
    <ContainerResponsive
      backUrlMobile={backUrl}
      paginacao={paginacao}
      textoMarcaDagua="Cientista"
      showLogo={true}
    >
      <OnEnterPrevent>
        {currentUserScyggz.user && (
          <ConteudoAvatar
            user={currentUserScyggz.user}
            onBack={() => {
              navigate(backUrl);
            }}
            onCadastro={() => {
              navigate("/cadastro/cientista/5");
            }}
          />
        )}
      </OnEnterPrevent>
    </ContainerResponsive>
  );
};
