import { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { dateFormat, locale, locales } from "../../utils/defaultDateFormat";

Object.entries(locales).forEach((locale) =>
  registerLocale(locale[0], locale[1])
);

export function InputDate({
  label,
  value,
  id,
  onChange,
  validate,
  className,
}: {
  label: string;
  value: Date | undefined;
  id: string;
  onChange: (date?: Date) => void;
  validate: (date?: Date) => Error | undefined;
  className?: string;
}) {
  const [error, setError] = useState<Error>();
  const [isChanged, setIsChanged] = useState<boolean>(false);

  useEffect(() => {
    const validateError = validate(value);

    if (validateError) {
      setError(validateError);
    } else {
      setError(undefined);
    }
  }, [validate, value]);

  return (
    <div className={className}>
      <label htmlFor={id} className="form-label d-block">
        {label}
      </label>

      <DatePicker
        selected={value}
        onChange={(value) => {
          onChange(value ?? undefined);

          setIsChanged(true);
        }}
        dateFormat={dateFormat}
        locale={locale}
        className="form-control"
        id={id}
      />

      {isChanged && error instanceof Error && (
        <div className="text-danger flex-fill">{error.message}</div>
      )}
    </div>
  );
}
