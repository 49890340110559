import { useContext } from "react";

import { CurrentUserContext } from "../../../../globalStore/CurrentUserContext";
import HTMLHead from "../../../generalComponents/HTMLHead";
import { ListConexoesPendentesRecebidas } from "../components/ListConexoesPendentesRecebidas";
import { ConexoesPendentesRecebidasProvider } from "../../../../globalStore/conexoesPendentesRecebidas/ConexoesPendentesRecebidasProvider";

export function ConexoesPendentesRecebidos() {
  const { currentUserScyggz } = useContext(CurrentUserContext);

  return currentUserScyggz.user ? (
    <>
      <HTMLHead
        title="Conexões pendentes recebidas"
        description="Minhas Conexões Pendentes Recebidas"
      />
      <ConexoesPendentesRecebidasProvider user={currentUserScyggz.user}>
        <ListConexoesPendentesRecebidas />
      </ConexoesPendentesRecebidasProvider>
    </>
  ) : (
    <></>
  );
}
