import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { TipoPerfil, UsuarioScyggz } from "../domain/entities/usuarioScyggz";
import { Result } from "../typings";
import { MentorOutput } from "../domain/entities/Mentor";
import { CientistaOutputEntity } from "../domain/entities/Cientista";
import { OutputInvestidorAnjo } from "../domain/entities/InvestidorAnjo";
import { HandlersDatabaseContext } from "../globalStore/HandlersProviders/HandlersDatabaseContext";

export function useUserPerfil({
  id_user,
  id_perfil,
  currentUser,
}: {
  id_user?: string;
  id_perfil?: string;
  currentUser: UsuarioScyggz | null;
}): UseUserPerfilType | undefined {
  const {
    handlerGetUsuario,
    handlerGetMentor,
    handlerGetCientista,
    handlerGetInvestidorAnjo,
  } = useContext(HandlersDatabaseContext);

  const [user, setUser] = useState<Result<UsuarioScyggz>>();
  const [perfil, setPerfil] = useState<PerfilResultType>();

  const userPerfil = useMemo<UseUserPerfilType | undefined>(() => {
    if (user?.ok) {
      return {
        ok: true,
        value: {
          user: user.value,
          perfil,
        },
      };
    }

    return user;
  }, [perfil, user]);

  const getMentor = useCallback(
    (user_id: string) =>
      handlerGetMentor?.get(user_id).then((getResult) => {
        if (getResult.ok) {
          setPerfil({
            ok: true,
            value: {
              ...getResult.value,
              tipo: "mentor",
            },
          });
        } else {
          setPerfil(getResult);
        }
      }),
    [handlerGetMentor]
  );
  const getInvestidor = useCallback(
    (user_id: string) =>
      handlerGetInvestidorAnjo?.get(user_id).then((getResult) => {
        if (getResult.ok) {
          setPerfil({
            ok: true,
            value: {
              ...getResult.value,
              tipo: "investidor",
            },
          });
        } else {
          setPerfil(getResult);
        }
      }),
    [handlerGetInvestidorAnjo]
  );
  const getCientista = useCallback(
    (user_id: string) =>
      handlerGetCientista?.get(user_id).then((getResult) => {
        if (getResult.ok) {
          setPerfil({
            ok: true,
            value: {
              ...getResult.value,
              tipo: "cientista",
            },
          });
        } else {
          setPerfil(getResult);
        }
      }),
    [handlerGetCientista]
  );

  useEffect(() => {
    if (!id_user) {
      if (currentUser) {
        setUser({
          ok: true,
          value: currentUser,
        });
      } else {
        setUser({
          ok: false,
          error: new Error("access denied"),
        });
      }
      return;
    }

    if (id_user == currentUser?.auth_user_id) {
      setUser({
        ok: true,
        value: currentUser,
      });

      return;
    }

    if (!handlerGetUsuario) {
      setUser({
        ok: false,
        error: new Error("handlerGetUsuario required"),
      });
      return;
    }

    setUser(undefined);

    handlerGetUsuario
      .getUsuario({
        auth_user_id: id_user,
      })
      .then((getUsuarioResult) => {
        if (getUsuarioResult.ok) {
          setUser({
            ok: true,
            value: getUsuarioResult.value,
          });
        } else {
          setUser(getUsuarioResult);
        }
      });
  }, [currentUser, handlerGetUsuario, id_user]);

  useEffect(() => {
    if (user?.ok) {
      let idPerfilToShow: TipoPerfil | undefined | "invalido" =
        id_perfil as TipoPerfil;

      idPerfilToShow = Object.keys(user.value.perfis ?? {}).find(
        (perfil) => perfil == idPerfilToShow
      ) as TipoPerfil | undefined;

      if (!idPerfilToShow) {
        const firstPerfil: [perfil: TipoPerfil, enabled: boolean] | undefined =
          Object.entries(user.value.perfis ?? {})
            .sort(([, enabledA], [, enabledB]) =>
              enabledA && enabledB ? 0 : enabledA ? 1 : -1
            )
            .at(0) as [perfil: TipoPerfil, enabled: boolean] | undefined;

        if (firstPerfil) {
          idPerfilToShow = firstPerfil[0];
        } else {
          setPerfil({
            ok: false,
            error: new Error("sem perfil"),
          });

          return;
        }
      }

      switch (idPerfilToShow) {
        case "mentor":
          getMentor(user.value.auth_user_id);
          break;
        case "investidor":
          getInvestidor(user.value.auth_user_id);
          break;
        case "cientista":
          getCientista(user.value.auth_user_id);
          break;
      }
    }
  }, [getCientista, getInvestidor, getMentor, id_perfil, user]);

  return userPerfil;
}

export type UseUserPerfilType = Result<{
  user: UsuarioScyggz;
  perfil?: PerfilResultType;
}>;

export type PerfilResultType = Result<
  //   {
  //     //   tipo: TipoPerfil;
  //     //   perfil: MentorOutput | CientistaOutputEntity | OutputInvestidorAnjo;
  //     }
  | (MentorOutput & { tipo: "mentor" })
  | (CientistaOutputEntity & { tipo: "cientista" })
  | (OutputInvestidorAnjo & { tipo: "investidor" })
>;

// const x:PerfilResultType = {
//     ok: true,
//     value: {
//         tipo: "mentor",

//     }
// }
