import { useEffect, useState } from "react";

import { InputStakeholdersGrupo } from "./InputStakeholdersGrupo";
import { TipoCadastro } from "../../domain/entities/TipoCadastro";

export function SelectStakeholdersGrupos({
  label,
  subLabel,
  value,
  onChange,
  validate,
  className = "",
}: {
  label: string;
  subLabel: string;
  value: SelectStakeholdersGrupos;
  onChange: (stakeholders: SelectStakeholdersGrupos) => void;
  validate: (stakeholders: SelectStakeholdersGrupos) =>
    | {
        groupA?: Error | Error[];
        groupB?: Error | Error[];
      }
    | undefined;
  className?: string;
}) {
  const [error, setError] = useState<{
    groupA?: Error | Error[];
    groupB?: Error | Error[];
  }>();

  useEffect(() => {
    const validateError = validate(value);

    if (validateError) {
      setError(validateError);
    } else {
      setError(undefined);
    }
  }, [validate, value]);

  return (
    <div className={"mb-3 has-validation " + className}>
      <label className="form-label fw-bolder m-0 d-block">{label}</label>
      <label className="form-label mb-3 d-block">{subLabel}</label>

      <div className="row">
        <InputStakeholdersGrupo
          label="Stakeholders Grupo A"
          value={value.stakeholder_type_group_a}
          id={"selectGrupoA"}
          onChange={(stakeholder_type_group_a) => {
            onChange({
              ...value,
              stakeholder_type_group_a,
            });
          }}
          validate={() => error?.groupA}
          className="col-6"
        />

        <InputStakeholdersGrupo
          label="Stakeholders Grupo B"
          value={value.stakeholder_type_group_b}
          id={"selectGrupoB"}
          onChange={(stakeholder_type_group_b) => {
            onChange({
              ...value,
              stakeholder_type_group_b,
            });
          }}
          validate={() => error?.groupB}
          className="col-6"
        />
      </div>
    </div>
  );
}

type SelectStakeholdersGrupos = {
  stakeholder_type_group_a: TipoCadastro[];
  stakeholder_type_group_b: TipoCadastro[];
};
