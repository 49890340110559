import { useContext } from "react";
import { useNavigate } from "react-router";

import { ContainerResponsive } from "../../../../generalComponents/ContainerResponsive";
import { PainelElegibilidade } from "../../components/PainelElegibilidade/PainelElegibilidade";
import { HandlersDatabaseContext } from "../../../../../globalStore/HandlersProviders/HandlersDatabaseContext";

export function PainelElegibilidadeCientista() {
  const navigate = useNavigate();

  const { handlerAceiteSet } = useContext(HandlersDatabaseContext);

  const paginacaoElegibilidade = {
    start: 1,
    end: 5,
  };
  const nextUrl = "/cadastro/cientista/2";

  return (
    <section className="h-100">
      <ContainerResponsive
        backUrlMobile="/"
        textoMarcaDagua={"Cientista"}
        paginacao={paginacaoElegibilidade}
        showLogo={true}
      >
        <PainelElegibilidade
          requisitos={[
            "Sou pessoa física e me dedico à pesquisas e desenvolvimento de tecnologias;",
            "Quero apresentar minha pesquisa para empresas;",
            "Quero apresentar minha pesquisa para investidores.",
          ]}
          titulo={"Cientista"}
          onNext={() => {
            if (handlerAceiteSet) handlerAceiteSet.set("cientista", new Date());
            navigate(nextUrl);
          }}
          onPrevious={() => {
            navigate("/");
          }}
        />
      </ContainerResponsive>
    </section>
  );
}
