import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";

export const useResize = () => {
  const [responsive, setResponsive] = useState<Responsive>(getResponsive());

  const updateDimensions = useCallback(() => {
    setResponsive(getResponsive());
  }, []);

  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, [updateDimensions]);

  function getResponsive(): Responsive {
    const largura = window.innerWidth;

    return { md: largura <= 768, sm: largura <= 576 };
  }

  return responsive;
};

export const ResizeContext = createContext<{ responsive: Responsive }>({
  responsive: {
    sm: false,
    md: false,
  },
});

export function ResizeContextProvider({ children }: { children: ReactNode }) {
  const responsive = useResize();

  return (
    <ResizeContext.Provider
      value={{
        responsive,
      }}
    >
      {children}
    </ResizeContext.Provider>
  );
}

type Responsive = {
  md: boolean;
  sm: boolean;
};
