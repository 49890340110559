import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";

import styles from "./Footer.module.css";

export const Footer = () => {
  return (
    <footer className={`${styles.footer}`}>
      <div className={styles.copyright + " p-3"}>
        <div className="container align-items-center d-flex justify-content-between flex-row-reverse flex-wrap-reverse ">
          <ul
            className={`${styles.ulFooter} ${styles.copyRight} d-flex pull-right m-0 list-inline mobile-block`}
          >
            <li className={"ms-2"}>
              <Link target="_blank" to="/termosdeuso">
                Termos de uso
              </Link>
            </li>
            <li className={"ms-2"}>&bull;</li>
            <li className={"ms-2"}>
              <Link target="_blank" to="/politicadeprivacidade">
                Política de Privacidade
              </Link>
            </li>
            <li className={"ms-2"}>&bull;</li>
            <li className={"ms-2"}>
              <Link to="/contato">
                Contato com <b>Sciencin</b>
              </Link>
            </li>
          </ul>

          <span className={`${styles.copyRight}`}>
            Copyright &copy; Sciencin | Feito com amor{" "}
            <FontAwesomeIcon icon={faHeart} style={{ color: "red" }} /> no
            Brasil 🇧🇷 e na Dinamarca 🇩🇰
          </span>
        </div>
      </div>
    </footer>
  );
};
