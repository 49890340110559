import { Result } from "../../typings";

export class LogoNit {
  private constructor(public id_nit: string, public file: Blob) {}

  public static create({ id_nit, file }: InputLogoNit): Result<LogoNit> {
    const errors: Error[] = [];

    id_nit = id_nit.trim();
    const isAuthValid: boolean = id_nit.length > 0;
    if (!isAuthValid) {
      errors.push(new Error("Id Nit inválido"));
    }

    // TODO: VALIDATE LOGO NIT

    if (errors.length > 0) {
      return {
        ok: false,
        error: errors[0],
      };
    }

    return {
      ok: true,
      value: new LogoNit(id_nit, file),
    };
  }

  public get path(): string {
    return "nits/" + this.id_nit + "/logo/" + new Date().getTime().toString();
  }
}

export type InputLogoNit = {
  id_nit: string;
  file: Blob;
};

export type OutputLogoNit = {
  path: string;
};
