import {
  Firestore,
  doc,
  updateDoc,
  getDocs,
  query,
  collection,
  where,
  deleteDoc,
  writeBatch,
} from "firebase/firestore";

import { Result, Writeable } from "../typings";
import { HandlerConviteProgramaConexaoAccept } from "../domain/usecases/interfaces/HandlerConviteProgramaConexaoAccept";
import { ConviteProgramaConexao } from "../domain/entities/ConviteProgramaConexao";
import { HandlerConviteProgramaConexaoReject } from "../domain/usecases/interfaces/HandlerConviteProgramaConexaoReject";
import { HandlerConviteProgramaConexaoGetByUserAlvo } from "../domain/usecases/interfaces/HandlerConviteProgramaConexaoGetByUserAlvo";
import { HandlerConviteProgramaConexaoCreate } from "../domain/usecases/interfaces/HandlerConviteProgramaConexaoCreate";
import { HandlerConviteProgramaConexaoGetAllAccepted } from "../domain/usecases/interfaces/HandlerConviteProgramaConexaoGetAllAccepted";
import { HandlerConviteProgramaConexaoGetAll } from "../domain/usecases/interfaces/HandlerConviteProgramaConexaoGetAll";
import { HandlerConviteProgramaConexaoDelete } from "../domain/usecases/interfaces/HandlerConviteProgramaConexaoDelete";

export class HandlerConviteProgramaConexaoFirestore
  implements
    HandlerConviteProgramaConexaoAccept,
    HandlerConviteProgramaConexaoReject,
    HandlerConviteProgramaConexaoGetByUserAlvo,
    HandlerConviteProgramaConexaoCreate,
    HandlerConviteProgramaConexaoGetAllAccepted,
    HandlerConviteProgramaConexaoGetAll,
    HandlerConviteProgramaConexaoDelete
{
  readonly collectionPath = "convites_programa_conexao";

  constructor(private firestore: Firestore) {}

  create(convites: [ConviteProgramaConexao]): Promise<Result<void, Error[]>> {
    if (convites.length < 1)
      return Promise.resolve({
        ok: true,
        value: undefined,
      });

    return new Promise((resolve) => {
      const validateResults = convites.map((convite) =>
        ConviteProgramaConexao.create(convite)
      );
      if (validateResults.some((validateResult) => !validateResult.ok)) {
        return validateResults;
      }

      const writeConvitesBatch = writeBatch(this.firestore);

      convites.forEach((convite) => {
        writeConvitesBatch.set(
          doc(
            this.firestore,
            this.collectionPath,
            ConviteProgramaConexao.idFormat(convite)
          ),
          convite
        );
      });

      writeConvitesBatch
        .commit()
        .then(() => {
          resolve({
            ok: true,
            value: undefined,
          });
        })
        .catch((error) => {
          console.warn("create", error);
          resolve({
            ok: false,
            error,
          });
        });
    });
  }

  getByUserAlvo(id_alvo: string): Promise<Result<ConviteProgramaConexao[]>> {
    return new Promise((resolve) => {
      getDocs(
        query(
          collection(this.firestore, this.collectionPath),
          where("alvo.id", "==", id_alvo),
          where("alvo.typesStakeholder", "array-contains-any", [
            "mentor",
            "cientista",
            "investidor",
          ])
        )
      )
        .then((querySnapshot) => {
          const convites: ConviteProgramaConexao[] = querySnapshot.docs.map(
            (docSnapshot) => {
              const convite =
                docSnapshot.data() as Writeable<ConviteProgramaConexao>;
              convite.id = docSnapshot.id;
              return convite;
            }
          );

          resolve({
            ok: true,
            value: convites,
          });
        })
        .catch((error) => {
          console.warn("getByUserAlvo", error);
          resolve({
            ok: false,
            error,
          });
        });
    });
  }

  getAll(id_programa: string): Promise<Result<ConviteProgramaConexao[]>> {
    return new Promise((resolve) => {
      getDocs(
        query(
          collection(this.firestore, this.collectionPath),
          where("programa_conexao.id", "==", id_programa)
        )
      )
        .then((querySnapshot) => {
          const convites: ConviteProgramaConexao[] = querySnapshot.docs.map(
            (docSnapshot) => {
              const convite =
                docSnapshot.data() as Writeable<ConviteProgramaConexao>;
              convite.id = docSnapshot.id;
              return convite;
            }
          );

          convites.sort((conviteA, conviteB) =>
            conviteA.alvo.nome.localeCompare(conviteB.alvo.nome)
          );

          resolve({
            ok: true,
            value: convites,
          });
        })
        .catch((error) => {
          console.warn("getAll", error);
          resolve({
            ok: false,
            error,
          });
        });
    });
  }

  getAllAccepted(
    id_programa: string
  ): Promise<Result<ConviteProgramaConexao[]>> {
    return new Promise((resolve) => {
      getDocs(
        query(
          collection(this.firestore, this.collectionPath),
          where("programa_conexao.id", "==", id_programa),
          where("status", "==", "accepted")
        )
      )
        .then((querySnapshot) => {
          const convites: ConviteProgramaConexao[] = querySnapshot.docs.map(
            (docSnapshot) => {
              const convite =
                docSnapshot.data() as Writeable<ConviteProgramaConexao>;
              convite.id = docSnapshot.id;
              return convite;
            }
          );

          convites.sort((conviteA, conviteB) =>
            conviteA.alvo.nome.localeCompare(conviteB.alvo.nome)
          );

          resolve({
            ok: true,
            value: convites,
          });
        })
        .catch((error) => {
          console.warn("getAllAccepted", error);
          resolve({
            ok: false,
            error,
          });
        });
    });
  }

  accept(convite: Pick<ConviteProgramaConexao, "id">): Promise<Result<void>> {
    return this.updateConvite(convite, true);
  }

  reject(convite: Pick<ConviteProgramaConexao, "id">): Promise<Result<void>> {
    return this.updateConvite(convite, false);
  }

  updateConvite(
    convite: Pick<ConviteProgramaConexao, "id">,
    aceitar: boolean
  ): Promise<Result<void>> {
    return new Promise((resolve) => {
      const docConvite = doc(this.firestore, this.collectionPath, convite.id);

      const docUpdate: Pick<ConviteProgramaConexao, "status"> = {
        status: aceitar ? "accepted" : "rejected",
      };

      updateDoc(docConvite, docUpdate)
        .then(() => {
          resolve({
            ok: true,
            value: undefined,
          });
        })
        .catch((error) => {
          resolve({
            ok: false,
            error,
          });
        });
    });
  }

  delete(convite: Pick<ConviteProgramaConexao, "id">): Promise<Result<void>> {
    return new Promise((resolve) => {
      const docConvite = doc(this.firestore, this.collectionPath, convite.id);

      deleteDoc(docConvite)
        .then(() => {
          resolve({
            ok: true,
            value: undefined,
          });
        })
        .catch((error) => {
          resolve({
            ok: false,
            error,
          });
        });
    });
  }
}
