export const ButtonMeusPerfis = ({
  children,
  classes,
}: {
  children: string;
  classes?: string;
}) => {
  return (
    <button
      className={classes}
      style={{
        borderRadius: "10px",
        backgroundColor: "#fff",
        color: "#999900",
        border: "2px solid #999900",
        fontWeight: "bold",
        padding: "2px 20px",
        fontSize: "22px",
        boxShadow: "0px 2px 2px 1px rgba(0, 0, 0, 0.2)",
        textTransform: "capitalize",
      }}
    >
      {children}
    </button>
  );
};
