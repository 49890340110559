import { useMemo } from "react";
import { Link } from "react-router-dom";

import { ImgFromPath } from "../../../../generalComponents/ImgFromPath";
import { TipoCadastro } from "../../../../../domain/entities/TipoCadastro";
import { ProgramaConexao } from "../../../../../domain/entities/ProgramaConexao";

export function MyProgram({
  programa: {
    id,
    logo_path,
    nome,
    stakeholder_type_group_a,
    stakeholder_type_group_b,
  },
  className = "",
  onDeleteClick,
}: {
  programa: Pick<
    ProgramaConexao,
    | "id"
    | "logo_path"
    | "nome"
    | "stakeholder_type_group_a"
    | "stakeholder_type_group_b"
  >;
  className?: string;
  onDeleteClick: (programa: Pick<ProgramaConexao, "id" | "nome">) => void;
}) {
  const stakeHolders = useMemo<TipoCadastro[]>(
    () =>
      ProgramaConexao.getStakeHolders({
        stakeholder_type_group_a,
        stakeholder_type_group_b,
      }),
    [stakeholder_type_group_a, stakeholder_type_group_b]
  );

  return (
    <>
      <div className={"d-flex gap-3 " + className}>
        <div className="flex-shrink-1">
          <ImgFromPath
            path={logo_path}
            className="rounded-circle border-white object-fit-cover"
            alt={nome}
            style={{
              width: "50px",
              height: "50px",
              border: "2px solid #fff",
            }}
          />
        </div>

        <div className="flex-fill">
          <h5 className="fw-bolder m-0">{nome}</h5>

          {stakeHolders.length > 0 && (
            <div
              className="d-block fw-bolder color-spinoff"
              style={{
                fontSize: "14px",
                textTransform: "capitalize",
              }}
            >
              {stakeHolders.map((stakeHolder, i) => (
                <span key={i}>
                  {i > 0 && " | "}
                  {stakeHolder}
                </span>
              ))}
            </div>
          )}
        </div>

        <div>
          <button
            type="button"
            className="btn bg-danger text-white fw-bolder me-1"
            onClick={() =>
              onDeleteClick({
                id,
                nome,
              })
            }
          >
            Excluir
          </button>

          <Link to={"/programa_conexao/" + id}>
            <button
              type="button"
              className="btn background-spinoff text-white fw-bolder"
            >
              Visualizar
            </button>
          </Link>
        </div>
      </div>
    </>
  );
}
