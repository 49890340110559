import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare, faSquareCheck } from "@fortawesome/free-regular-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import { TipoCadastro } from "../../../../../domain/entities/TipoCadastro";
import { HandlersDatabaseContext } from "../../../../../globalStore/HandlersProviders/HandlersDatabaseContext";
import { Result } from "../../../../../typings";
import {
  TIPOS_PERFIL,
  TipoPerfil,
} from "../../../../../domain/entities/usuarioScyggz";
import { BuscaPerfisPorTextoType } from "../../../../../domain/usecases/buscaPerfisPorTexto";
import { SelectStakeholder, TypeSelectStakeholder } from "./SelectStakeholder";

export function ListSelectStakeholders({
  typesStakeholders,
  selectedStakeholders,
  onChangeSelectedStakeholders,
}: {
  typesStakeholders: TipoCadastro[];
  selectedStakeholders: TypeSelectStakeholder[];
  onChangeSelectedStakeholders: (selecteds: TypeSelectStakeholder[]) => void;
}) {
  const {
    handlerPerfisGetEnabled,
    handlerGetEmpresa,
    handlerGetStartup,
    handlerGetVentureCapital,
    handlerGetNit,
    handlerGetHub,
  } = useContext(HandlersDatabaseContext);

  const [stakeholdersResultsPerType, setStakeholdersResultsPerType] =
    useState<TypeListStakeholders>({});
  const [perfisEnabledBuscaResult, setPerfisEnabledBuscaResult] =
    useState<BuscaPerfisPorTextoType>();

  // STAKEHOLDERS
  const stakeholdersResult = useMemo<{
    stakeholders: TypeSelectStakeholder[];
    loading: boolean;
  }>(() => {
    const stakeholders: TypeSelectStakeholder[] = [];
    let loading = false;

    typesStakeholders.forEach((typeStakeholder) => {
      const typeStakeholderResult = stakeholdersResultsPerType[typeStakeholder];

      if (typeStakeholderResult == undefined) {
        loading = true;
      } else {
        if (typeStakeholderResult?.ok) {
          stakeholders.push(...typeStakeholderResult.value);
        }
      }
    });

    stakeholders.sort((stakeholderA, stakeholderB) =>
      stakeholderA.nome.localeCompare(stakeholderB.nome)
    );

    return {
      stakeholders,
      loading,
    };
  }, [stakeholdersResultsPerType, typesStakeholders]);
  // SEPARA PERFIS USUARIOS DE ORGANIZAÇÕES
  const typePerfisEOrganizacoes = useMemo<{
    perfis: TipoPerfil[];
    organizacoes: TipoCadastro[];
  }>(() => {
    return {
      perfis: typesStakeholders.filter((typeStakeholders) =>
        TIPOS_PERFIL.includes(typeStakeholders as TipoPerfil)
      ) as TipoPerfil[],
      organizacoes: typesStakeholders.filter(
        (typeStakeholders) =>
          !TIPOS_PERFIL.includes(typeStakeholders as TipoPerfil)
      ),
    };
  }, [typesStakeholders]);

  const isAllSelected = useMemo<boolean>(() => {
    return stakeholdersResult.stakeholders.every((stakeholder) =>
      selectedStakeholders.some(
        (selectedStakeholder) => selectedStakeholder.id == stakeholder.id
      )
    );
  }, [selectedStakeholders, stakeholdersResult.stakeholders]);

  const selectAll = useCallback(() => {
    onChangeSelectedStakeholders([...stakeholdersResult.stakeholders]);
  }, [onChangeSelectedStakeholders, stakeholdersResult.stakeholders]);
  const unselectAll = useCallback(() => {
    onChangeSelectedStakeholders([]);
  }, [onChangeSelectedStakeholders]);

  // BUSCA PERFIS USUARIOS
  useEffect(() => {
    if (typePerfisEOrganizacoes.perfis.length > 0) {
      handlerPerfisGetEnabled
        ?.getEnabled(typePerfisEOrganizacoes.perfis)
        .then((perfisEnabledResult) => {
          setPerfisEnabledBuscaResult(perfisEnabledResult);
        });
    } else {
      setPerfisEnabledBuscaResult({
        ok: true,
        value: {
          currentPage: 0,
          totalPages: 0,
          perfis: [],
        },
      });
    }
  }, [handlerPerfisGetEnabled, typePerfisEOrganizacoes.perfis]);

  useEffect(() => {
    if (
      typePerfisEOrganizacoes.organizacoes.length > 0 &&
      typePerfisEOrganizacoes.organizacoes.includes("empresa")
    ) {
      handlerGetEmpresa?.getAllEnabledEmpresas().then((perfisEnabledResult) => {
        if (perfisEnabledResult.ok) {
          setStakeholdersResultsPerType((stakeholdersResultsPerType) => ({
            ...stakeholdersResultsPerType,
            empresa: {
              ok: true,
              value: perfisEnabledResult.value.map((empresa) => ({
                id: empresa.id,
                cidade: {
                  cidade: "",
                  estado: "",
                  pais: "",
                  paisCode: "",
                },
                nome: empresa.nome,
                avatar_path: empresa.path_logo,
                headline: empresa.sobre,
                typesStakeholder: ["empresa"],
              })),
            },
          }));
        } else {
          setStakeholdersResultsPerType((stakeholdersResultsPerType) => ({
            ...stakeholdersResultsPerType,
            empresa: perfisEnabledResult,
          }));
        }
      });
    } else {
      setStakeholdersResultsPerType((stakeholdersResultsPerType) => ({
        ...stakeholdersResultsPerType,
        empresa: {
          ok: true,
          value: [],
        },
      }));
    }
  }, [handlerGetEmpresa, typePerfisEOrganizacoes.organizacoes]);

  useEffect(() => {
    if (
      typePerfisEOrganizacoes.organizacoes.length > 0 &&
      typePerfisEOrganizacoes.organizacoes.includes("startup")
    ) {
      handlerGetStartup?.getAllEnabledStartups().then((perfisEnabledResult) => {
        if (perfisEnabledResult.ok) {
          setStakeholdersResultsPerType((stakeholdersResultsPerType) => ({
            ...stakeholdersResultsPerType,
            startup: {
              ok: true,
              value: perfisEnabledResult.value.map((startup) => ({
                id: startup.id,
                cidade: startup.cidade,
                nome: startup.nome,
                avatar_path: startup.path_logo,
                headline: startup.sobre,
                typesStakeholder: ["startup"],
              })),
            },
          }));
        } else {
          setStakeholdersResultsPerType((stakeholdersResultsPerType) => ({
            ...stakeholdersResultsPerType,
            startup: perfisEnabledResult,
          }));
        }
      });
    } else {
      setStakeholdersResultsPerType((stakeholdersResultsPerType) => ({
        ...stakeholdersResultsPerType,
        startup: {
          ok: true,
          value: [],
        },
      }));
    }
  }, [handlerGetStartup, typePerfisEOrganizacoes.organizacoes]);

  useEffect(() => {
    if (
      typePerfisEOrganizacoes.organizacoes.length > 0 &&
      typePerfisEOrganizacoes.organizacoes.includes("vc")
    ) {
      handlerGetVentureCapital
        ?.getAllEnabledVentureCapitals()
        .then((perfisEnabledResult) => {
          if (perfisEnabledResult.ok) {
            setStakeholdersResultsPerType((stakeholdersResultsPerType) => ({
              ...stakeholdersResultsPerType,
              vc: {
                ok: true,
                value: perfisEnabledResult.value.map((vc) => ({
                  id: vc.id,
                  cidade: {
                    cidade: "",
                    estado: "",
                    pais: "",
                    paisCode: "",
                  },
                  nome: vc.nome,
                  avatar_path: vc.path_logo,
                  headline: vc.tese_de_investimento.conteudo,
                  typesStakeholder: ["vc"],
                })),
              },
            }));
          } else {
            setStakeholdersResultsPerType((stakeholdersResultsPerType) => ({
              ...stakeholdersResultsPerType,
              vc: perfisEnabledResult,
            }));
          }
        });
    } else {
      setStakeholdersResultsPerType((stakeholdersResultsPerType) => ({
        ...stakeholdersResultsPerType,
        vc: {
          ok: true,
          value: [],
        },
      }));
    }
  }, [handlerGetVentureCapital, typePerfisEOrganizacoes.organizacoes]);

  useEffect(() => {
    if (
      typePerfisEOrganizacoes.organizacoes.length > 0 &&
      typePerfisEOrganizacoes.organizacoes.includes("nit")
    ) {
      handlerGetNit?.getAllEnabledNits().then((perfisEnabledResult) => {
        if (perfisEnabledResult.ok) {
          setStakeholdersResultsPerType((stakeholdersResultsPerType) => ({
            ...stakeholdersResultsPerType,
            nit: {
              ok: true,
              value: perfisEnabledResult.value.map((nit) => ({
                id: nit.id,
                cidade: nit.cidade,
                nome: nit.nome,
                avatar_path: nit.logo_path,
                headline: nit.sobre,
                typesStakeholder: ["nit"],
              })),
            },
          }));
        } else {
          setStakeholdersResultsPerType((stakeholdersResultsPerType) => ({
            ...stakeholdersResultsPerType,
            nit: perfisEnabledResult,
          }));
        }
      });
    } else {
      setStakeholdersResultsPerType((stakeholdersResultsPerType) => ({
        ...stakeholdersResultsPerType,
        nit: {
          ok: true,
          value: [],
        },
      }));
    }
  }, [handlerGetNit, typePerfisEOrganizacoes.organizacoes]);

  useEffect(() => {
    if (
      typePerfisEOrganizacoes.organizacoes.length > 0 &&
      typePerfisEOrganizacoes.organizacoes.includes("hub")
    ) {
      handlerGetHub?.getAllEnabledHubs().then((perfisEnabledResult) => {
        if (perfisEnabledResult.ok) {
          setStakeholdersResultsPerType((stakeholdersResultsPerType) => ({
            ...stakeholdersResultsPerType,
            hub: {
              ok: true,
              value: perfisEnabledResult.value.map((hub) => ({
                id: hub.id,
                cidade: hub.cidade,
                nome: hub.nome,
                avatar_path: hub.path_logo,
                headline: hub.sobre,
                typesStakeholder: ["hub"],
              })),
            },
          }));
        } else {
          setStakeholdersResultsPerType((stakeholdersResultsPerType) => ({
            ...stakeholdersResultsPerType,
            hub: perfisEnabledResult,
          }));
        }
      });
    } else {
      setStakeholdersResultsPerType((stakeholdersResultsPerType) => ({
        ...stakeholdersResultsPerType,
        hub: {
          ok: true,
          value: [],
        },
      }));
    }
  }, [handlerGetHub, typePerfisEOrganizacoes.organizacoes]);

  // AJUSTA PERFIS USUARIOS NOS RESULTS
  useEffect(() => {
    if (perfisEnabledBuscaResult?.ok) {
      const cientistas: TypeSelectStakeholder[] = [];
      const mentor: TypeSelectStakeholder[] = [];
      const investidor: TypeSelectStakeholder[] = [];

      perfisEnabledBuscaResult.value.perfis.forEach((perfil) => {
        switch (perfil.perfil) {
          case "cientista":
            cientistas.push({
              id: perfil.user_id,
              cidade: perfil.cidade,
              nome: perfil.nome,
              typesStakeholder: ["cientista"],
              avatar_path: perfil.avatar_path?.path,
              headline: perfil.headline,
            });
            break;
          case "mentor":
            mentor.push({
              id: perfil.user_id,
              cidade: perfil.cidade,
              nome: perfil.nome,
              typesStakeholder: ["mentor"],
              avatar_path: perfil.avatar_path?.path,
              headline: perfil.headline,
            });
            break;
          case "investidor":
            investidor.push({
              id: perfil.user_id,
              cidade: perfil.cidade,
              nome: perfil.nome,
              typesStakeholder: ["investidor"],
              avatar_path: perfil.avatar_path?.path,
              headline: perfil.headline,
            });
            break;
        }
      });

      setStakeholdersResultsPerType((stakeholdersResultsPerType) => {
        return {
          ...stakeholdersResultsPerType,
          cientista: {
            ok: true,
            value: cientistas,
          },
          mentor: {
            ok: true,
            value: mentor,
          },
          investidor: {
            ok: true,
            value: investidor,
          },
        };
      });
    } else {
      setStakeholdersResultsPerType((stakeholdersResultsPerType) => {
        return {
          ...stakeholdersResultsPerType,
          cientista: perfisEnabledBuscaResult,
          mentor: perfisEnabledBuscaResult,
          investidor: perfisEnabledBuscaResult,
        };
      });
    }
  }, [perfisEnabledBuscaResult]);

  return (
    <div className="table-responsive">
      <>
        {stakeholdersResult.loading && (
          <ProgressBar animated variant="info" now={100} className="w-100" />
        )}

        <table className="table table-borderless table-hover">
          <thead className="bg-light">
            <tr>
              <th
                scope="col"
                onClick={isAllSelected ? unselectAll : selectAll}
                className="cursorPointer"
              >
                Todos
                <FontAwesomeIcon
                  icon={(isAllSelected ? faSquareCheck : faSquare) as IconProp}
                  className="ms-3"
                />
              </th>
              <th scope="col">Nome</th>
              <th scope="col">Perfis</th>
            </tr>
          </thead>
          <tbody>
            {stakeholdersResult.stakeholders.map((stakeholder) => (
              <SelectStakeholder
                key={stakeholder.id}
                stakeholder={stakeholder}
                selected={selectedStakeholders.some(
                  (selectedStakeholder) =>
                    selectedStakeholder.id == stakeholder.id
                )}
                onSelect={(stakeholder) => {
                  onChangeSelectedStakeholders([
                    ...selectedStakeholders,
                    stakeholder,
                  ]);
                }}
                onUnselect={(stakeholder) => {
                  onChangeSelectedStakeholders(
                    selectedStakeholders.filter(
                      (selectedStakeholder) =>
                        selectedStakeholder.id != stakeholder.id
                    )
                  );
                }}
              />
            ))}
          </tbody>
        </table>
      </>
    </div>
  );
}

type TypeListStakeholders = Partial<
  Record<TipoCadastro, Result<TypeSelectStakeholder[]>>
>;
