import React from "react";
type Props = {
  title: string;
  description: string;
};

const HTMLHead = ({ title, description }: Props) => {
  React.useEffect(() => {
    document.title = "Sciencin | " + title;
    document
      // eslint-disable-next-line quotes
      .querySelector('meta[name="description"]')
      ?.setAttribute("content", description);
  }, [title, description]);

  return <></>;
};

export default HTMLHead;
