import { useEffect, useState } from "react";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const InfoLinks = ({ links }: { links: string[] }) => {
  const [linksCleans, setLinksCleans] = useState<string[]>([]);

  useEffect(() => {
    setLinksCleans(links.map((link) => link?.replace(/h?t?t?p?s?:?\/\//, "")));
  }, [links]);

  return (
    <>
      {links.length > 0 && (
        <section className="mb-4 mt-3">
          {linksCleans.length > 0 && (
            <>
              <h5 className="fw-lighter">Outros Links</h5>
              <div
                style={{ width: "fit-content" }}
                className="d-flex gap-4 py-2 flex-wrap"
              >
                {linksCleans.map((link, i) => (
                  <div key={i}>
                    <a
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                      href={`${links[i]}`}
                      target="_blank"
                      className="text-green-strong"
                    >
                      <FontAwesomeIcon icon={faLink} width={17} />
                      <span className="ms-2">{link}</span>
                    </a>
                  </div>
                ))}
              </div>
            </>
          )}
        </section>
      )}
    </>
  );
};
