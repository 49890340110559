import {
  Firestore,
  getDocs,
  query,
  collection,
  where,
  addDoc,
  Timestamp,
  orderBy,
  deleteDoc,
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";

import { Result, Writeable } from "../typings";
import { HandlerComentarioProgramaConexaoGetAllMural } from "../domain/usecases/interfaces/HandlerComentarioProgramaConexaoGetAllMural";
import { ProgramaConexaoComment } from "../domain/entities/ProgramaConexaoComment";
import { HandlerComentarioProgramaConexaoCreateInMural } from "../domain/usecases/interfaces/HandlerComentarioProgramaConexaoCreateInMural";
import { HandlerComentarioProgramaConexaoDelete } from "../domain/usecases/interfaces/HandlerComentarioProgramaConexaoDelete";
import { HandlerComentarioProgramaConexaoUpdate } from "../domain/usecases/interfaces/HandlerComentarioProgramaConexaoUpdate";
import { HandlerComentarioProgramaConexaoCreateResponse } from "../domain/usecases/interfaces/HandlerComentarioProgramaConexaoCreateResponse";
import { HandlerComentarioProgramaConexaoGetResponses } from "../domain/usecases/interfaces/HandlerComentarioProgramaConexaoGetResponses";
import { HandlerComentarioProgramaConexaoAddLike } from "../domain/usecases/interfaces/HandlerComentarioProgramaConexaoAddLike";
import { HandlerComentarioProgramaConexaoRemoveLike } from "../domain/usecases/interfaces/HandlerComentarioProgramaConexaoRemoveLike";

export class HandlerComentariosProgramaConexaoFirestore
  implements
    HandlerComentarioProgramaConexaoGetAllMural,
    HandlerComentarioProgramaConexaoCreateInMural,
    HandlerComentarioProgramaConexaoUpdate,
    HandlerComentarioProgramaConexaoDelete,
    HandlerComentarioProgramaConexaoCreateResponse,
    HandlerComentarioProgramaConexaoGetResponses,
    HandlerComentarioProgramaConexaoAddLike,
    HandlerComentarioProgramaConexaoRemoveLike
{
  readonly collectionProgramaPath = "programas_conexao";
  readonly collectionCommentsPath = "comments";

  constructor(private firestore: Firestore) {}

  createInMural(
    comment: Omit<ProgramaConexaoComment, "id" | "parent_comment">
  ): Promise<Result<Pick<ProgramaConexaoComment, "id">>> {
    return new Promise((resolve) => {
      const commentToSave: Omit<ProgramaConexaoComment, "id"> = {
        autor: comment.autor,
        created_at: comment.created_at,
        id_programa: comment.id_programa,
        message: comment.message,
        parent_comment: null,
      };

      const isInvalidComment =
        ProgramaConexaoComment.isInvalidComment(commentToSave);

      if (isInvalidComment) {
        return isInvalidComment;
      }

      addDoc(
        collection(
          this.firestore,
          this.collectionProgramaPath,
          commentToSave.id_programa,
          this.collectionCommentsPath
        ),
        commentToSave
      )
        .then((docRef) => {
          resolve({
            ok: true,
            value: {
              id: docRef.id,
            },
          });
        })
        .catch((error) => {
          console.warn("createInMural", error);

          resolve({
            ok: false,
            error,
          });
        });
    });
  }

  createResponse(
    comment: Omit<ProgramaConexaoComment, "id" | "parent_comment"> & {
      parent_comment: string;
    }
  ): Promise<Result<void>> {
    return new Promise((resolve) => {
      const commentToSave: Omit<ProgramaConexaoComment, "id"> = {
        autor: comment.autor,
        created_at: comment.created_at,
        id_programa: comment.id_programa,
        message: comment.message,
        parent_comment: comment.parent_comment,
      };

      const isInvalidComment =
        ProgramaConexaoComment.isInvalidComment(commentToSave);

      if (isInvalidComment) {
        return isInvalidComment;
      }

      addDoc(
        collection(
          this.firestore,
          this.collectionProgramaPath,
          commentToSave.id_programa,
          this.collectionCommentsPath
        ),
        commentToSave
      )
        .then(() => {
          resolve({
            ok: true,
            value: undefined,
          });
        })
        .catch((error) => {
          console.warn("createResponse", error);

          resolve({
            ok: false,
            error,
          });
        });
    });
  }

  getAllMural(id_programa: string): Promise<Result<ProgramaConexaoComment[]>> {
    return new Promise((resolve) => {
      getDocs(
        query(
          collection(
            this.firestore,
            this.collectionProgramaPath,
            id_programa,
            this.collectionCommentsPath
          ),
          where("parent_comment", "==", null),
          orderBy("created_at", "desc")
        )
      )
        .then((querySnapshot) => {
          const comments: ProgramaConexaoComment[] = querySnapshot.docs.map(
            (docSnapshot) => {
              const convite =
                docSnapshot.data() as Writeable<ProgramaConexaoComment>;
              convite.id = docSnapshot.id;
              convite.created_at = (
                convite.created_at as unknown as Timestamp
              ).toDate();
              return convite;
            }
          );

          resolve({
            ok: true,
            value: comments,
          });
        })
        .catch((error) => {
          console.warn("getAllMural", error);
          resolve({
            ok: false,
            error,
          });
        });
    });
  }

  getResponses({
    id: id_comment,
    id_programa,
  }: Pick<ProgramaConexaoComment, "id" | "id_programa">): Promise<
    Result<ProgramaConexaoComment[]>
  > {
    return new Promise((resolve) => {
      getDocs(
        query(
          collection(
            this.firestore,
            this.collectionProgramaPath,
            id_programa,
            this.collectionCommentsPath
          ),
          where("parent_comment", "==", id_comment),
          orderBy("created_at", "desc")
        )
      )
        .then((querySnapshot) => {
          const comments: ProgramaConexaoComment[] = querySnapshot.docs.map(
            (docSnapshot) => {
              const convite =
                docSnapshot.data() as Writeable<ProgramaConexaoComment>;
              convite.id = docSnapshot.id;
              convite.created_at = (
                convite.created_at as unknown as Timestamp
              ).toDate();
              return convite;
            }
          );

          resolve({
            ok: true,
            value: comments,
          });
        })
        .catch((error) => {
          console.warn("getResponses", error);
          resolve({
            ok: false,
            error,
          });
        });
    });
  }

  update(
    comment: Pick<ProgramaConexaoComment, "id" | "message" | "id_programa">
  ): Promise<Result<void>> {
    return new Promise((resolve) => {
      updateDoc(
        doc(
          this.firestore,
          this.collectionProgramaPath,
          comment.id_programa,
          this.collectionCommentsPath,
          comment.id
        ),
        "message",
        comment.message
      )
        .then(() =>
          resolve({
            ok: true,
            value: undefined,
          })
        )
        .catch((error) => {
          console.warn("update", error);
          resolve({
            ok: false,
            error,
          });
        });
    });
  }

  addLike(
    { id_programa, id }: Pick<ProgramaConexaoComment, "id" | "id_programa">,
    user_id: string
  ): Promise<Result<void>> {
    return new Promise((resolve) => {
      updateDoc(
        doc(
          this.firestore,
          this.collectionProgramaPath,
          id_programa,
          this.collectionCommentsPath,
          id
        ),
        "likes",
        arrayUnion(user_id)
      )
        .then(() =>
          resolve({
            ok: true,
            value: undefined,
          })
        )
        .catch((error) => {
          console.warn("addLike", error);
          resolve({
            ok: false,
            error,
          });
        });
    });
  }

  removeLike(
    { id_programa, id }: Pick<ProgramaConexaoComment, "id" | "id_programa">,
    user_id: string
  ): Promise<Result<void>> {
    return new Promise((resolve) => {
      updateDoc(
        doc(
          this.firestore,
          this.collectionProgramaPath,
          id_programa,
          this.collectionCommentsPath,
          id
        ),
        "likes",
        arrayRemove(user_id)
      )
        .then(() =>
          resolve({
            ok: true,
            value: undefined,
          })
        )
        .catch((error) => {
          console.warn("addLike", error);
          resolve({
            ok: false,
            error,
          });
        });
    });
  }

  delete(
    comment: Pick<ProgramaConexaoComment, "id" | "id_programa">
  ): Promise<Result<void>> {
    return new Promise((resolve) => {
      deleteDoc(
        doc(
          this.firestore,
          this.collectionProgramaPath,
          comment.id_programa,
          this.collectionCommentsPath,
          comment.id
        )
      )
        .then(() =>
          resolve({
            ok: true,
            value: undefined,
          })
        )
        .catch((error) => {
          console.warn("delete", error);
          resolve({
            ok: false,
            error,
          });
        });
    });
  }
}
