import algoliasearch from "algoliasearch";

import { TipoPerfil, UsuarioScyggz } from "../entities/usuarioScyggz";
import { MentorInput } from "../entities/Mentor";
import { CientistaInputEntity } from "../entities/Cientista";
import { InputInvestidorAnjo } from "../entities/InvestidorAnjo";
import { Result } from "../../typings";

export async function buscaPerfisPorTexto({
  texto,
  filter,
  totalPerPage = 24,
  page = 0,
}: {
  texto: string;
  totalPerPage?: number;
  page?: number;
  filter: {
    perfil?: TipoPerfil[];
    "areas_de_conhecimento.nome"?: string[];
    "areas_de_especializacao.nome"?: string[];
  };
}): Promise<BuscaPerfisPorTextoType> {
  const client = algoliasearch(
    "40O9V7GSHN",
    "4455a01c9297d5d24ed81e29bafb3ba6"
  );
  const index = client.initIndex("Perfis");

  const filterAnd: string = Object.entries(filter)
    .filter(([field]) => field != "perfil")
    .reduce<string[]>((prev, [field, value]) => {
      if (value) {
        if (Array.isArray(value)) {
          if (value.length > 0)
            value.forEach((v) => {
              prev.push(field + ":'" + v + "'");
            });
        } else {
          prev.push(field + ":'" + value + "'");
        }
      }

      return prev;
    }, [])
    .join(" AND ");

  const filterOr: string = Object.entries(filter)
    .filter(([field]) => field == "perfil")
    .reduce<string[]>((prev, [field, value]) => {
      if (value) {
        if (Array.isArray(value)) {
          if (value.length > 0)
            value.forEach((v) => {
              prev.push(field + ":'" + v + "'");
            });
        } else {
          prev.push(field + ":'" + value + "'");
        }
      }

      return prev;
    }, [])
    .join(" OR ");

  return new Promise((resolve) => {
    index
      .search<PerfilToSearch>(texto, {
        filters:
          "habilitado:true " +
          (filterAnd ? " AND (" + filterAnd + ")" : "") +
          (filterOr ? " AND (" + filterOr + ")" : ""),
        hitsPerPage: totalPerPage,
        page,
      })
      .then((searchResponse) => {
        if (searchResponse.nbPages > 0 && searchResponse.hits.length == 0) {
          return resolve(
            buscaPerfisPorTexto({
              texto,
              filter,
              page: searchResponse.nbPages - 1,
            })
          );
        }
        resolve({
          ok: true,
          value: {
            perfis: groupByUser(searchResponse.hits),
            currentPage: page,
            totalPages: searchResponse.nbPages,
          },
        });
      })
      .catch((error) => {
        resolve({
          ok: false,
          error,
        });
      });
  });
}

export type PerfilToSearch = Omit<
  MentorInput | CientistaInputEntity | InputInvestidorAnjo,
  "created_in"
> &
  Pick<
    UsuarioScyggz,
    "avatar_path" | "nome" | "cidade" | "headline" | "perfis"
  > & {
    path: string;
    user_id: string;
    perfil: TipoPerfil;
    created_in: number;
  };

export type BuscaPerfisPorTextoType = Result<{
  currentPage: number;
  totalPages: number;
  perfis: PerfilToSearch[];
}>;

function groupByUser(perfis: PerfilToSearch[]) {
  return Object.values(
    perfis.reduce<{ [id: string]: PerfilToSearch }>((all, perfil) => {
      if (!all[perfil.user_id]) {
        all[perfil.user_id] = perfil;
      }

      return all;
    }, {})
  );
}
