import { initializeApp, FirebaseApp, FirebaseOptions } from "firebase/app";
import { getAuth, Auth, connectAuthEmulator } from "firebase/auth";
import {
  Firestore,
  getFirestore,
  connectFirestoreEmulator,
  // enableMultiTabIndexedDbPersistence,
  initializeFirestore,
} from "firebase/firestore";
import {
  getStorage,
  FirebaseStorage,
  connectStorageEmulator,
} from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

const config: FirebaseOptions = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY ?? "firebaseApiKey",
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN ?? "scyggz.web.app",
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID ?? "scyggz",
  storageBucket:
    process.env.REACT_APP_FIREBASE_STORAGE_BUCKET ?? "scyggz.appspot.com",
  messagingSenderId:
    process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID ??
    "firestoreMessagingSenderId",
  appId: process.env.REACT_APP_FIREBASE_APP_ID ?? "scyggz",
  measurementId:
    process.env.REACT_APP_FIREBASE_MEASUREMENT_ID ?? "measurementId",
};

export class FirebaseService {
  static firebaseService: FirebaseService;

  firebaseApp: FirebaseApp;
  private authApp: Auth | undefined;
  private firestore: Firestore | undefined;
  private storage: FirebaseStorage | undefined;

  private constructor() {
    this.firebaseApp = initializeApp(config);

    if (process.env.NODE_ENV === "production") {
      getAnalytics(this.firebaseApp);
    }
  }

  static newFirebaseService() {
    if (!FirebaseService.firebaseService)
      FirebaseService.firebaseService = new FirebaseService();

    return FirebaseService.firebaseService;
  }

  getAuth = () => {
    if (!this.authApp) {
      this.authApp = getAuth(this.firebaseApp);
      this.connectWithAuthEmulator();
    }

    return this.authApp;
  };

  private connectWithAuthEmulator = () => {
    if (process.env.NODE_ENV !== "production") {
      connectAuthEmulator(this.getAuth(), "http://localhost:9099", {
        disableWarnings: true,
      });
    }
  };

  getFirestore(): Firestore {
    if (!this.firestore) {
      initializeFirestore(this.firebaseApp, {
        ignoreUndefinedProperties: true,
      });
      this.firestore = getFirestore(this.firebaseApp);
      this.connectWithFirestoreEmulator();
      // if (process.env.NODE_ENV !== "test")
      //   enableMultiTabIndexedDbPersistence(this.firestore);
    }

    return this.firestore;
  }

  getStorage = (): FirebaseStorage => {
    if (!this.storage) {
      this.storage = getStorage(this.firebaseApp);
      this.connectWithStorageEmulator();
    }

    return this.storage;
  };

  private connectWithFirestoreEmulator = () => {
    if (process.env.NODE_ENV !== "production") {
      connectFirestoreEmulator(this.getFirestore(), "localhost", 8080);
    }
  };

  private connectWithStorageEmulator = () => {
    if (process.env.NODE_ENV !== "production") {
      connectStorageEmulator(this.getStorage(), "localhost", 9199);
    }
  };
}
