import { Firestore, doc, setDoc } from "@firebase/firestore";
import { FirebaseError } from "firebase/app";

import { Result } from "../typings";
import {
  FirestoreErrorsFactory,
  FirestoreError,
} from "../domain/erros/FirestoreErrors";
import {
  HandlerUpdateInvestidor,
  InputHandlerUpdateInvestidor,
  formatInvestidorAnjoUpdate,
} from "../domain/usecases/interfaces/handlerUpdateInvestidor";
import { InvestidorAnjo } from "../domain/entities/InvestidorAnjo";
import { AssociacaoAnjoOutput } from "../domain/entities/associacaoAnjo";
import { HandlerCreateAssociacaoAnjoFirebase } from "./HandlerCreateAssociacaoAnjoFirebase";

export class HandlerUpdateInvestidorFirebase
  implements HandlerUpdateInvestidor
{
  constructor(private firestore: Firestore) {}

  async updateInvestidor(
    inputInvestidor: InputHandlerUpdateInvestidor
  ): Promise<Result<InputHandlerUpdateInvestidor>> {
    const validateInvestidorResult =
      InvestidorAnjo.validateUpdate(inputInvestidor);

    if (!validateInvestidorResult.ok) {
      return {
        ok: false,
        error: new Error("Erro validação"),
      };
    }

    const { investidor, newAssociacoes } =
      formatInvestidorAnjoUpdate(inputInvestidor);

    const handlerCreateAssociacaoAnjoFirebase =
      new HandlerCreateAssociacaoAnjoFirebase(this.firestore);

    const allPromisesCreateAssociacao: Promise<
      Result<AssociacaoAnjoOutput, Error>
    >[] = newAssociacoes.map((newAssociacaoAnjo) => {
      return handlerCreateAssociacaoAnjoFirebase.createAssociacaoAnjo({
        nome: newAssociacaoAnjo,
        gestores: [inputInvestidor.user_id],
      });
    });

    const allCreateAssociacaoResults = await Promise.all(
      allPromisesCreateAssociacao
    );

    allCreateAssociacaoResults.forEach((createdAssociacao) => {
      if (createdAssociacao.ok) {
        investidor.associacoes_anjo = investidor.associacoes_anjo ?? [];

        investidor.associacoes_anjo.push(createdAssociacao.value);
      }
    });

    return new Promise((resolve) => {
      const docUser = doc(
        this.firestore,
        "usuarios",
        inputInvestidor.user_id,
        "perfis/investidor"
      );

      setDoc(docUser, investidor, {
        merge: true,
      })
        .then(() => {
          resolve({
            ok: true,
            value: inputInvestidor,
          });
        })
        .catch((error: FirebaseError) => {
          const instanciaErro = FirestoreErrorsFactory.createErrorsValidation(
            <FirestoreError>error.code
          );
          if (instanciaErro) {
            resolve({
              ok: false,
              error: instanciaErro,
            });
            return;
          }

          console.error("Erro na atualização: ", error.code, error.message);

          resolve({
            ok: false,
            error,
          });
        });
    });
  }
}
