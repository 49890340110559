import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "./HeaderIcon.module.css";

export function HeaderIcon({
  label,
  icon,
}: {
  label: string;
  icon: IconDefinition | JSX.Element;
}) {
  return (
    <div className="d-inline-block">
      <div className="d-flex flex-lg-column align-items-center">
        {"icon" in icon ? (
          <FontAwesomeIcon icon={icon} className={styles.icon} />
        ) : (
          icon
        )}
        <span className={styles.label + " ps-1 ps-lg-0 pt-lg-2"}>{label}</span>
      </div>
    </div>
  );
}
