import { useContext, useEffect, useMemo, useState } from "react";

import { ImgFromPath } from "./ImgFromPath";
import { HandlersDatabaseContext } from "../../globalStore/HandlersProviders/HandlersDatabaseContext";
import { UsuarioScyggz } from "../../domain/entities/usuarioScyggz";

const QTDE_IMAGES = 3;
const SIZE_IMAGES = 25;
const HEIGHT_IMAGES_CONTAINER = SIZE_IMAGES;

export function MutualConnection({
  auth_id_conexoes_user,
  auth_id_conexoes_current_user,
  hideMutual = false,
}: {
  auth_id_conexoes_user: string[];
  auth_id_conexoes_current_user: string[];
  hideMutual?: boolean;
}) {
  const { handlerGetUsuario } = useContext(HandlersDatabaseContext);

  const [usuarios, setUsuarios] = useState<UsuarioScyggz[]>();

  const id_mutual_connections = useMemo<string[]>(() => {
    return auth_id_conexoes_user.filter((idConnectionUser) =>
      auth_id_conexoes_current_user.includes(idConnectionUser)
    );
  }, [auth_id_conexoes_user, auth_id_conexoes_current_user]);
  const ids_to_show_image = useMemo<string[]>(
    () => id_mutual_connections.slice(0, QTDE_IMAGES),
    [id_mutual_connections]
  );
  const paths_to_show = useMemo<string[]>(
    () =>
      usuarios
        ?.map((usuario) => usuario.avatar_path?.path ?? "")
        .filter((path) => !!path) ?? [],
    [usuarios]
  );

  useEffect(() => {
    if (handlerGetUsuario) {
      if (ids_to_show_image.length > 0)
        handlerGetUsuario
          .getUsuarios(ids_to_show_image)
          .then((usuariosResult) => {
            if (usuariosResult.ok) {
              setUsuarios(usuariosResult.value);
            }
          });
      else setUsuarios([]);
    }
  }, [handlerGetUsuario, ids_to_show_image]);

  return (
    <div
      className="d-flex align-items-center py-1"
      style={{
        lineHeight: SIZE_IMAGES + "px",
      }}
    >
      {!hideMutual && <ImagesOverlaps paths={paths_to_show} />}
      <span className={hideMutual || paths_to_show.length == 0 ? "" : "ms-2"}>
        {auth_id_conexoes_user.length}{" "}
        {auth_id_conexoes_user.length == 1 ? "conexão" : "conexões"}
        {!hideMutual && auth_id_conexoes_user.length > 0 && (
          <>
            {" "}
            • <b>{id_mutual_connections.length} em comum</b>
          </>
        )}
      </span>
    </div>
  );
}

function ImagesOverlaps({ paths }: { paths: string[] }) {
  return (
    <div
      className="position-relative d-inline-block"
      style={{
        width:
          paths.length > 0
            ? (SIZE_IMAGES / 2) * paths.length + SIZE_IMAGES / 2
            : 0,
        height: HEIGHT_IMAGES_CONTAINER,
      }}
    >
      {paths.map((path, i) => (
        <div
          key={path}
          className="ratio ratio-1x1 position-absolute"
          style={{
            left: i * (SIZE_IMAGES / 2),
            width: SIZE_IMAGES,
          }}
        >
          <ImgFromPath
            path={path}
            alt=""
            className="rounded-circle border border-white object-fit-cover"
          />
        </div>
      ))}
    </div>
  );
}
