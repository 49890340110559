import { Result } from "../../typings";

export class LogoStartup {
  private constructor(public id_startup: string, public file: Blob) {}

  public static create({
    id_startup,
    file,
  }: InputLogoStartup): Result<LogoStartup> {
    const errors: Error[] = [];

    id_startup = id_startup.trim();
    const isAuthValid: boolean = id_startup.length > 0;
    if (!isAuthValid) {
      errors.push(new Error("Id startup inválido"));
    }

    //TODO: VALIDATE LOGO STARTUP

    if (errors.length > 0) {
      return {
        ok: false,
        error: errors[0],
      };
    }

    return {
      ok: true,
      value: new LogoStartup(id_startup, file),
    };
  }

  public get path(): string {
    return (
      "startup/" + this.id_startup + "/logo/" + new Date().getTime().toString()
    );
  }
}

export type InputLogoStartup = {
  id_startup: string;
  file: Blob;
};

export type OutputLogoStartup = {
  path: string;
};
