import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Pagination, ProgressBar } from "react-bootstrap";

import {
  BuscaPerfisPorTextoType,
  buscaPerfisPorTexto,
} from "../../../domain/usecases/buscaPerfisPorTexto";
import {
  buscaStartupsPorTexto,
  BuscaStartupsPorTextoType,
} from "../../../domain/usecases/buscaStartupsPorTexto";
import {
  TIPOS_PERFIL,
  TipoPerfil,
} from "../../../domain/entities/usuarioScyggz";
import {
  PERFIL,
  ORGANIZACAO,
  TipoPerfilMM,
  TIPO_PERFIL_MM,
} from "../../../domain/entities/TipoPerfilMM";
import { BannerPessoasRelevantesItem } from "../components/BannerPessoasRelevantes/BannerPessoasRelevantesItem";
import { BannerStartupItem } from "../components/BannerStartupItem";
import { HeaderFilter } from "./components/HeaderFilter";
import {
  TIPOS_ORGANIZACAO,
  TipoOrganizacao,
} from "../../../domain/entities/organizacaoScyggz";

export function PageBusca() {
  const [searchParams, setSearchParams] = useSearchParams();

  const tipoVisualizacao = useMemo<TipoPerfilMM | undefined>(() => {
    const tipoVisualizacao = searchParams.get("visualizacao");
    if (
      tipoVisualizacao &&
      TIPO_PERFIL_MM.includes(tipoVisualizacao as TipoPerfilMM)
    ) {
      return tipoVisualizacao as TipoPerfilMM;
    }
  }, [searchParams]);

  const tipoPerfilToShow = useMemo<TipoPerfil | undefined>(() => {
    const perfil = searchParams.get("perfil");

    if (perfil && TIPOS_PERFIL.includes(perfil as TipoPerfil)) {
      return perfil as TipoPerfil;
    }
  }, [searchParams]);

  const areas_de_conhecimento = useMemo<string[] | undefined>(
    () => searchParams.get("areas_de_conhecimento")?.split(","),
    [searchParams]
  );

  const areas_de_especializacao = useMemo<string[] | undefined>(
    () => searchParams.get("areas_de_especializacao")?.split(","),
    [searchParams]
  );

  const page = useMemo<number>(
    () => parseInt(searchParams.get("page") ?? "0"),
    [searchParams]
  );

  const tipoOrganizacaoToShow = useMemo<TipoOrganizacao | undefined>(() => {
    const organizacao = searchParams.get("organizacao");

    if (
      organizacao &&
      TIPOS_ORGANIZACAO.includes(organizacao as TipoOrganizacao)
    ) {
      return organizacao as TipoOrganizacao;
    }
  }, [searchParams]);

  const [perfisResult, setPerfisResult] = useState<BuscaPerfisPorTextoType>();
  const [startupsResult, setStartupsResult] =
    useState<BuscaStartupsPorTextoType>();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setPerfisResult(undefined);
    setStartupsResult(undefined);
    setLoading(true);

    if (tipoVisualizacao === undefined) {
      setSearchParams((searchParams) => {
        searchParams.set("visualizacao", PERFIL);
        return searchParams;
      });
    }

    if (tipoVisualizacao === PERFIL) {
      buscaPerfisPorTexto({
        texto: "",
        filter: {
          perfil: tipoPerfilToShow ? [tipoPerfilToShow] : [],
          "areas_de_conhecimento.nome": areas_de_conhecimento,
          "areas_de_especializacao.nome": areas_de_especializacao,
        },
        page,
        totalPerPage: 1000,
      }).then((perfisResult) => {
        setLoading(false);
        setPerfisResult(perfisResult);
      });
    } else if (tipoVisualizacao === ORGANIZACAO) {
      buscaStartupsPorTexto({
        texto: "",
        page,
        totalPerPage: 1000,
      }).then((startupsResult) => {
        setLoading(false);
        setStartupsResult(startupsResult);
      });
    }
  }, [
    areas_de_conhecimento,
    page,
    tipoPerfilToShow,
    areas_de_especializacao,
    tipoOrganizacaoToShow,
    tipoVisualizacao,
    setSearchParams,
  ]);

  let listView;

  if (perfisResult) {
    if (perfisResult.ok) {
      listView = (
        <>
          {perfisResult.value.perfis.length > 0
            ? perfisResult.value.perfis.map((perfil) => (
                <div
                  key={perfil.path}
                  className="col-12 col-sm-6 col-md-4 col-xl-3 p-1"
                >
                  <BannerPessoasRelevantesItem
                    item={{
                      url_link: "/user/" + perfil.user_id + "/" + perfil.perfil,
                      image_path: perfil.avatar_path?.path,
                      nome: perfil.nome,
                      cidade: perfil.cidade,
                      perfis: Object.entries(perfil.perfis ?? {}).reduce<
                        TipoPerfil[]
                      >((prev, [perfil, enabled]) => {
                        if (enabled) {
                          prev.push(perfil as TipoPerfil);
                        }
                        return prev;
                      }, []),
                      headline: perfil.headline,
                      onConectar: undefined,
                    }}
                    className="card p-2"
                  />
                </div>
              ))
            : null}

          {perfisResult.value.totalPages > 1 && (
            <Pagination className="w-100 justify-content-center">
              {Array.from(Array(perfisResult.value.totalPages).keys()).map(
                (_, index) => (
                  <Pagination.Item
                    key={index}
                    active={index === perfisResult.value.currentPage}
                    onClick={() => {
                      searchParams.set("page", index.toString());

                      setSearchParams(searchParams);
                    }}
                  >
                    {index + 1}
                  </Pagination.Item>
                )
              )}
            </Pagination>
          )}
        </>
      );
    } else {
      listView = <>{perfisResult.error.message}</>;
    }
  } else if (startupsResult) {
    if (startupsResult.ok) {
      listView = (
        <>
          {startupsResult.value.startups.length > 0
            ? startupsResult.value.startups.map((startup) => (
                <div
                  key={startup.path_logo}
                  className="col-12 col-sm-6 col-md-4 col-xl-3 p-1"
                >
                  <BannerStartupItem
                    item={{
                      url_link: "/startup/" + startup.objectID,
                      image_path: startup.path_logo,
                      nome: startup.nome,
                      cidade: startup.cidade,
                      onConectar: undefined,
                    }}
                    className="card p-2"
                  />
                </div>
              ))
            : null}

          {startupsResult.value.totalPages > 1 && (
            <Pagination className="w-100 justify-content-center">
              {Array.from(Array(startupsResult.value.totalPages).keys()).map(
                (_, index) => (
                  <Pagination.Item
                    key={index}
                    active={index === startupsResult.value.currentPage}
                    onClick={() => {
                      searchParams.set("page", index.toString());

                      setSearchParams(searchParams);
                    }}
                  >
                    {index + 1}
                  </Pagination.Item>
                )
              )}
            </Pagination>
          )}
        </>
      );
    } else {
      listView = <>{startupsResult.error.message}</>;
    }
  }

  return (
    <div className="d-flex flex-wrap p-1">
      <HeaderFilter />

      {loading ? (
        <ProgressBar animated variant="info" now={100} className="w-100" />
      ) : (
        listView
      )}
    </div>
  );
}
