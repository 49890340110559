import { doc, onSnapshot } from "firebase/firestore";
import { BehaviorSubject, Observable } from "rxjs";

import { FirebaseService } from "../../infra/firebase/firebaseService";
import { OutputTelefone } from "../entities/telefone";

const firebaseService = FirebaseService.newFirebaseService();

export function getUserPhoneNumberObserver(
  id_user: string
): Observable<UserPhoneNumberRealtime> {
  const firestore = firebaseService.getFirestore();

  const info$ = new BehaviorSubject<UserPhoneNumberRealtime>({
    status: "carregando",
    userPhoneNumber: null,
  });

  onSnapshot(doc(firestore, "phone_number", id_user), (docUserSnapshot) => {
    if (docUserSnapshot.exists()) {
      const docUserSnapshotData = docUserSnapshot.data();

      info$.next({
        status: "encontrado",
        userPhoneNumber: {
          auth_id: docUserSnapshot.id,
          numero: docUserSnapshotData.numero ?? null,
        },
      });
    } else {
      info$.next({
        status: "nao_encontrado",
        userPhoneNumber: null,
      });
    }
  });

  return info$;
}

export type UserPhoneNumberRealtime = {
  status: StatusUserPhoneNumberRealtime;
  userPhoneNumber: OutputTelefone | null;
};

type StatusUserPhoneNumberRealtime =
  | "carregando"
  | "encontrado"
  | "nao_encontrado";