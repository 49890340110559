import { useMemo } from "react";
import { faRotateLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ListSelectStakeholders } from "./ListSelectStakeholders";
import { TipoCadastro } from "../../../../../domain/entities/TipoCadastro";
import { TypeSelectStakeholder } from "./SelectStakeholder";

export function Etapa3NewPrograma({
  typeStakeholdersGroupA,
  value: programaToSave,
  onChange: setProgramaToSave,
  onBack,
  onNext,
}: {
  typeStakeholdersGroupA: TipoCadastro[];
  value: ProgramaConexaoEtapa3;
  onChange: (value: ProgramaConexaoEtapa3) => void;
  onBack: () => void;
  onNext: () => void;
}) {
  const erroProgramaToSave = useMemo<boolean>(() => {
    return programaToSave.gruposA.length < 1;
  }, [programaToSave.gruposA.length]);

  return (
    <div className="card shadow-sm">
      <div className="card-header border-0 bg-white p-0">
        <button
          type="button"
          className="btn btn-light bg-white border-0 text-muted"
          onClick={onBack}
        >
          <FontAwesomeIcon icon={faRotateLeft} className="me-2" />
          Back
        </button>
      </div>

      <div className="card-body">
        <h5 className="pb-2 fw-bolder">Selecione stakeholders grupo A</h5>

        <ListSelectStakeholders
          typesStakeholders={typeStakeholdersGroupA}
          selectedStakeholders={programaToSave.gruposA}
          onChangeSelectedStakeholders={(gruposA) => {
            setProgramaToSave({
              ...programaToSave,
              gruposA,
            });
          }}
        />
      </div>

      <div className="card-footer border-0 pt-0 bg-white text-center">
        <button
          type="button"
          className="btn px-4 text-white background-spinoff"
          onClick={onNext}
          disabled={erroProgramaToSave}
        >
          Continue
        </button>
      </div>
    </div>
  );
}

export type ProgramaConexaoEtapa3 = {
  gruposA: TypeSelectStakeholder[];
};
