import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare, faSquareCheck } from "@fortawesome/free-regular-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import { ImgFromPath } from "../../../../generalComponents/ImgFromPath";
import showStakeholder from "../../../../../utils/showStakeholder";
import { CidadeOutput } from "../../../../../domain/entities/cidade";
import { TipoCadastro } from "../../../../../domain/entities/TipoCadastro";

export function SelectStakeholder({
  stakeholder,
  selected,
  onSelect,
  onUnselect,
}: {
  stakeholder: TypeSelectStakeholder;
  selected: boolean;
  onSelect: (stakeholder: TypeSelectStakeholder) => void;
  onUnselect: (stakeholder: TypeSelectStakeholder) => void;
}) {
  return (
    <tr
      onClick={() =>
        selected ? onUnselect(stakeholder) : onSelect(stakeholder)
      }
      className="cursorPointer"
    >
      <td className="text-center align-middle">
        <FontAwesomeIcon
          icon={(selected ? faSquareCheck : faSquare) as IconProp}
        />
      </td>

      <td className="align-middle">
        {stakeholder.avatar_path && (
          <ImgFromPath
            path={stakeholder.avatar_path}
            className="rounded-circle border-white object-fit-cover me-4"
            alt={stakeholder.nome}
            style={{
              width: "50px",
              height: "50px",
              border: "2px solid #fff",
            }}
          />
        )}
        <span className="m-0">{stakeholder.nome}</span>
      </td>

      <td className="align-middle">
        <div
          className="fw-bolder color-spinoff"
          style={{
            textTransform: "capitalize",
          }}
        >
          {stakeholder.typesStakeholder.map((typeStakeholder, i) => (
            <span key={i}>
              {i > 0 && " | "}
              {showStakeholder(typeStakeholder)}
            </span>
          ))}
        </div>
      </td>
    </tr>
  );
}

export type TypeSelectStakeholder = {
  id: string;
  nome: string;
  headline?: string;
  cidade: CidadeOutput;
  avatar_path?: string;
  typesStakeholder: TipoCadastro[];
};
