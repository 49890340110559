import { useState } from "react";

import { Conexao } from "../../../../domain/entities/Conexao";
import { ImgFromPath } from "../../../generalComponents/ImgFromPath";
import Avatar from "../../../../assets/images/avatarLight.jpeg";
import { HandlerUpdateAceiteConexao } from "../../../../domain/usecases/interfaces/HandlerUpdateAceiteConexao";
import { ButtonAceiteConexao } from "../../../generalComponents/buttonAceiteConexao/ButtonAceiteConexao";

export const CardConviteConexao = ({
  conexao,
  handlerUpdateAceiteConexao,
  onAceiteOk,
}: {
  conexao: Pick<Conexao, "id" | "autor">;
  handlerUpdateAceiteConexao: HandlerUpdateAceiteConexao;
  onAceiteOk?: () => void;
}) => {
  const [processandoAceite, setProcessandoAceite] = useState<boolean>(false);

  return (
    <a
      href={"/user/" + conexao.autor.auth_user_id}
      style={{
        display: "flex",
        padding: "0",
        border: "1px solid #000",
        boxShadow: "0px 2px 2px 1px rgba(0, 0, 0, 0.2)",
        height: "100%",
      }}
      className="rounded-2 p-2 position-relative text-reset"
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="gap-2"
      >
        <div className="p-0 ratio ratio-1x1">
          {conexao.autor.avatar_path ? (
            <ImgFromPath
              path={conexao.autor.avatar_path.path}
              className="rounded-circle border-white object-fit-cover"
              alt={conexao.autor.nome}
            />
          ) : (
            <img
              className="rounded-circle border-white object-fit-cover"
              src={Avatar}
            />
          )}
        </div>

        <div className="d-flex flex-column">
          <h4 style={{ fontSize: "14px", fontWeight: "700", margin: "0" }}>
            {conexao.autor.nome}
          </h4>
          <span style={{ fontSize: "12px" }}>{conexao.autor.headline}</span>
          <span style={{ fontSize: "10px" }}>
            {conexao.autor.cidade.cidade}, {conexao.autor.cidade.estado} -{" "}
            {conexao.autor.cidade.paisCode}
          </span>
          <div style={{ fontSize: "0" }}>
            <span
              style={{
                fontSize: "10px",
                color: "#525200",
                textTransform: "capitalize",
                fontWeight: "bold",
              }}
            >
              {Object.entries(conexao.autor.perfis ?? {})
                .filter(([, perfilIsHabilitado]) => !!perfilIsHabilitado)
                .map(([perfil]) => perfil)
                .join(" | ")}
            </span>
          </div>
        </div>

        <div>
          <h4 style={{ fontSize: "12px", fontWeight: "500" }}>
            Convidou você para conectar-se com ela.
          </h4>

          <ButtonAceiteConexao
            processandoAceite={processandoAceite}
            onAceite={() => {
              setProcessandoAceite(true);
              handlerUpdateAceiteConexao
                .aceitarConexao(conexao, true)
                .then((aceiteResult) => {
                  if (aceiteResult.ok && onAceiteOk) {
                    onAceiteOk();
                  }
                  setProcessandoAceite(false);
                });
            }}
          />

          <span
            style={{
              fontSize: "25px",
              border: "1px solid #000",
              borderRadius: "50%",
              position: "absolute",
              top: "-16px",
              lineHeight: "1",
              padding: "1px 9px 5px",
              backgroundColor: "#fff",
              right: "-17px",
              opacity: processandoAceite ? 0.5 : 1,
            }}
            onClick={() => {
              setProcessandoAceite(true);
              handlerUpdateAceiteConexao
                .aceitarConexao(conexao, false)
                .then((aceiteResult) => {
                  if (aceiteResult.ok && onAceiteOk) {
                    onAceiteOk();
                  }
                  setProcessandoAceite(false);
                });
            }}
          >
            x
          </span>
        </div>
      </div>
    </a>
  );
};
