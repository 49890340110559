import { useContext } from "react";
import { CurrentUserContext } from "../../../../globalStore/CurrentUserContext";
import { MeusPerfis } from "./MeusPerfis";

export const MeusPerfisContext = () => {
  const {
    currentUserScyggz: { user },
  } = useContext(CurrentUserContext);

  return (
    user && <MeusPerfis perfis={user.perfis} auth_user_id={user.auth_user_id} />
  );
};
