import { useState } from "react";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { AreasInfo } from "../components/generalComponents/AreasInfo";
import { InfoEstagioCicloDeVida } from "../components/generalComponents/InfoEstagioCicloDeVida";
import { InfoAssociocoesAnjo } from "../components/generalComponents/InfoAssociacoesAnjo";
import { InfoLocalidade } from "../components/generalComponents/InfoLocalidade";
import { InfoPremiacoes } from "../components/generalComponents/InfoPremiacoes";
import { ParagrafoInfo } from "../components/generalComponents/ParagrafoInfo";
import { TitleInfo } from "../components/generalComponents/TitleInfo";
import { InfoStatupsCards } from "../components/generalComponents/InfoStartupsCards";
import {
  InputHandlerUpdateInvestidorSemId,
  TypeReturnHandlerUpdateInvestidor,
} from "../../../../domain/usecases/interfaces/handlerUpdateInvestidor";
import { ModalEditInvestidor } from "./ModalEditInvestidor";
import { InvestidorAnjo } from "../../../../domain/entities/InvestidorAnjo";

export const PerfilInfoInvestidorAnjo = ({
  areas_de_especializacao,
  tese_de_investimento,
  interesse_startup_modelo_negocio,
  interesse_startup_estagio,
  interesse_startup_localizacoes,
  interesse_startup_segmento_atuacao,
  interesse_startup_mecanismo_solucao,
  premiacoes_e_certificados,
  associacoes_anjo,
  startups_investidas,
  updateInvestidor,
  refreshInvestidor,
}: Pick<
  InvestidorAnjo,
  | "areas_de_especializacao"
  | "tese_de_investimento"
  | "interesse_startup_modelo_negocio"
  | "interesse_startup_estagio"
  | "interesse_startup_localizacoes"
  | "interesse_startup_segmento_atuacao"
  | "interesse_startup_mecanismo_solucao"
  | "premiacoes_e_certificados"
  | "associacoes_anjo"
  | "startups_investidas"
> &
  Partial<{
    refreshInvestidor: () => void;
    updateInvestidor: (
      input: InputHandlerUpdateInvestidorSemId
    ) => TypeReturnHandlerUpdateInvestidor;
  }>) => {
  const [showModalEdit, setShowModalEdit] = useState<boolean>(false);

  return (
    <section className="mx-4 position-relative">
      {updateInvestidor && (
        <>
          <button
            type="button"
            className="btn bg-body-secondary position-absolute top-0 start-100 translate-middle rounded-circle text-secondary"
            onClick={() => setShowModalEdit(true)}
          >
            <FontAwesomeIcon icon={faPencil} />
          </button>

          <ModalEditInvestidor
            initialValue={{
              areas_de_especializacao,
              tese_de_investimento,
              interesse_startup_modelo_negocio,
              interesse_startup_estagio,
              interesse_startup_localizacoes,
              interesse_startup_segmento_atuacao,
              interesse_startup_mecanismo_solucao,
              premiacoes_e_certificados,
              associacoes_anjo,
            }}
            show={showModalEdit}
            onShowChange={(show) => {
              setShowModalEdit(show);
            }}
            handlerUpdateInvestidor={updateInvestidor}
            onUpdate={() => {
              if (refreshInvestidor) refreshInvestidor();
            }}
          />
        </>
      )}

      <TitleInfo classes="mb-2" children="Tese de Investimento" />
      <div className="border rounded-4 p-3 mb-4">
        <ParagrafoInfo
          classes="mb-5"
          border=""
          descricao={tese_de_investimento.conteudo}
        />
        <div className="row">
          <div className="col-md-6">
            <InfoEstagioCicloDeVida estagio={interesse_startup_estagio} />
            <InfoLocalidade localidades={interesse_startup_localizacoes} />
            <AreasInfo
              areas={interesse_startup_modelo_negocio}
              title="Modelo de negócio"
            />
          </div>
          <div className="col-md-6">
            <AreasInfo
              areas={interesse_startup_segmento_atuacao}
              title="Segmentos de atuação"
            />
            <AreasInfo
              areas={interesse_startup_mecanismo_solucao}
              title="Mecanismo de solução tecnológica"
            />
          </div>
        </div>
      </div>
      <InfoPremiacoes classes="mb-4" premiacoes={premiacoes_e_certificados} />
      <InfoAssociocoesAnjo classes="mb-4" associacoes={associacoes_anjo} />
      <InfoStatupsCards startups={startups_investidas} />
    </section>
  );
};
