import {
  Firestore,
  collection,
  getDocs,
  getDoc,
  doc,
  query,
  where,
  Timestamp,
} from "@firebase/firestore";

import { Result } from "../typings";
import { HandlerGetEmpresa } from "../domain/usecases/interfaces/handlerGetEmpresa";
import { OutputEmpresa } from "../domain/entities/Empresa";

export class HandlerGetEmpresaFirebase implements HandlerGetEmpresa {
  constructor(private firestore: Firestore) {}

  getAllEnabledEmpresas(): Promise<Result<OutputEmpresa[]>> {
    return new Promise((resolve) => {
      const queryEnabledEmpresas = query(
        collection(this.firestore, "empresas"),
        where("habilitado", "==", true)
      );

      getDocs(queryEnabledEmpresas)
        .then((querySnapshot) => {
          const empresas: OutputEmpresa[] = querySnapshot.docs.map(
            (docSnapshot) => {
              const empresa = docSnapshot.data() as OutputEmpresa;
              empresa.id = docSnapshot.id;
              empresa.created_in = (
                empresa.created_in as unknown as Timestamp
              ).toDate();
              return empresa;
            }
          );

          resolve({
            ok: true,
            value: empresas,
          });
        })
        .catch(() =>
          resolve({
            ok: true,
            value: [],
          })
        );
    });
  }

  getEmpresa(id: string): Promise<Result<OutputEmpresa>> {
    return new Promise((resolve) => {
      const docEmpresa = doc(this.firestore, "empresas", id);

      getDoc(docEmpresa)
        .then((docSnapshot) => {
          const empresa = docSnapshot.data() as OutputEmpresa;
          empresa.id = docSnapshot.id;
          empresa.created_in = (
            empresa.created_in as unknown as Timestamp
          ).toDate();

          resolve({
            ok: true,
            value: empresa,
          });
        })
        .catch((error) =>
          resolve({
            ok: false,
            error,
          })
        );
    });
  }

  getEmpresasGestor(
    id_gerente: string
  ): Promise<Result<OutputEmpresa[], Error>> {
    return new Promise((resolve) => {
      const queryEmpresas = query(
        collection(this.firestore, "empresas"),
        where("gestores", "array-contains", id_gerente)
      );

      getDocs(queryEmpresas)
        .then((querySnapshot) => {
          const empresas: OutputEmpresa[] = querySnapshot.docs.map(
            (docSnapshot) => {
              const empresa = docSnapshot.data() as OutputEmpresa;
              empresa.id = docSnapshot.id;
              empresa.created_in = (
                empresa.created_in as unknown as Timestamp
              ).toDate();
              return empresa;
            }
          );

          resolve({
            ok: true,
            value: empresas,
          });
        })
        .catch(() =>
          resolve({
            ok: true,
            value: [],
          })
        );
    });
  }
}
