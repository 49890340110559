import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faUsersViewfinder } from "@fortawesome/free-solid-svg-icons";

export function SelectProgramaDeConexao() {
  return (
    <div className="card shadow-sm px-4">
      <div className="card-body">
        <h5 className="pb-2 fw-bolder">Dashboard</h5>

        <Link to="/programa_conexao" className="text-reset d-block pb-2">
          <FontAwesomeIcon icon={faUsersViewfinder} className="me-2" />
          Todos os programas
        </Link>

        <Link to="/programa_conexao/my" className="text-reset d-block">
          <FontAwesomeIcon icon={faUser} className="me-2" />
          Meus programas
        </Link>
      </div>

      <div className="card-footer border-0 pt-0 bg-white">
        <Link
          to="/programa_conexao/new"
          className="w-12 btn d-block text-center text-white background-spinoff"
        >
          Criar novo
        </Link>
      </div>
    </div>
  );
}
