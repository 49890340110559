import { Result } from "../../typings";

export class LogoEmpresa {
  private constructor(public id_empresa: string, public file: Blob) {}

  public static create({
    id_empresa,
    file,
  }: InputLogoEmpresa): Result<LogoEmpresa> {
    const errors: Error[] = [];

    id_empresa = id_empresa.trim();
    const isAuthValid: boolean = id_empresa.length > 0;
    if (!isAuthValid) {
      errors.push(new Error("Id empresa inválido"));
    }

    // TODO: VALIDATE LOGO EMPRESA

    if (errors.length > 0) {
      return {
        ok: false,
        error: errors[0],
      };
    }

    return {
      ok: true,
      value: new LogoEmpresa(id_empresa, file),
    };
  }

  public get path(): string {
    return (
      "empresas/" + this.id_empresa + "/logo/" + new Date().getTime().toString()
    );
  }
}

export type InputLogoEmpresa = {
  id_empresa: string;
  file: Blob;
};

export type OutputLogoEmpresa = {
  path: string;
};
