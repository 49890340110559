import {
  Firestore,
  collection,
  where,
  query,
  Timestamp,
  doc,
  getDoc,
  DocumentData,
  getDocs,
  documentId,
  getCountFromServer,
} from "@firebase/firestore";

import { Result } from "../typings";
import { HandlerGetStartup } from "../domain/usecases/interfaces/handlerGetStartup";
import { OutputStartup } from "../domain/entities/Startup";
import { fieldToDate } from "./utils/fieldToDate";
import { HandlerGetStartupWaitingAprovement } from "../domain/usecases/interfaces/HandlerGetStartupWaitingAprovement";

export class HandlerGetStartupFirebase
  implements HandlerGetStartup, HandlerGetStartupWaitingAprovement
{
  constructor(private firestore: Firestore) {}

  getAllEnabledStartups(): Promise<Result<OutputStartup[]>> {
    return new Promise((resolve) => {
      const queryEnabledStartups = query(
        collection(this.firestore, "startup"),
        where("habilitado", "==", true)
      );

      getDocs(queryEnabledStartups)
        .then((querySnapshot) => {
          const startups: OutputStartup[] = querySnapshot.docs.map(
            (docSnapshot) => {
              const startup = docSnapshot.data() as OutputStartup;
              startup.id = docSnapshot.id;
              startup.created_in = (
                startup.created_in as unknown as Timestamp
              ).toDate();
              return startup;
            }
          );

          resolve({
            ok: true,
            value: startups,
          });
        })
        .catch(() =>
          resolve({
            ok: true,
            value: [],
          })
        );
    });
  }

  getStartup(id: string): Promise<Result<OutputStartup>> {
    return new Promise((resolve) => {
      const docStartup = doc(this.firestore, "startup", id);

      getDoc(docStartup)
        .then((docSnapshot) => {
          const startup = docSnapshot.data() as OutputStartup;
          startup.id = docSnapshot.id;
          startup.created_in = (
            startup.created_in as unknown as Timestamp
          ).toDate();

          resolve({
            ok: true,
            value: startup,
          });
        })
        .catch((error) =>
          resolve({
            ok: false,
            error,
          })
        );
    });
  }

  getStartupsGestor(id_gerente: string): Promise<Result<OutputStartup[]>> {
    return new Promise((resolve) => {
      const queryStartupsGestor = query(
        collection(this.firestore, "startup"),
        where("gestores", "array-contains", id_gerente)
      );

      getDocs(queryStartupsGestor)
        .then((querySnapshot) => {
          const startups: OutputStartup[] = querySnapshot.docs.map(
            (docSnapshot) => {
              const startup = docSnapshot.data() as OutputStartup;
              startup.id = docSnapshot.id;
              startup.created_in = (
                startup.created_in as unknown as Timestamp
              ).toDate();
              return startup;
            }
          );

          resolve({
            ok: true,
            value: startups,
          });
        })
        .catch(() =>
          resolve({
            ok: true,
            value: [],
          })
        );
    });
  }

  getStartupsByUserIdAccepted(
    id_user: string | undefined
  ): Promise<Result<OutputStartup[]>> {
    return new Promise((resolve) => {
      getDocs(
        query(
          collection(this.firestore, "convites_startup_employee"),
          where("alvo.id", "==", id_user),
          where("status", "==", "accepted")
        )
      )
        .then((querySnapshot) => {
          const startupsId: string[] = [];

          querySnapshot.docs.map((docSnapshot) =>
            startupsId.push(docSnapshot.get("startup.id"))
          );
          
          const batches = [];
          while (startupsId.length) {
            const batchIds = startupsId.splice(0, 10);
            batches.push(getDocs(
              query(
                collection(this.firestore, "startup"),
                where(documentId(), "in", batchIds)
              )
            ))
          }

          Promise.all(batches)
            .then((batchResults) => {
              const startups: OutputStartup[] = [];

              // Processar os resultados de cada lote
              batchResults.forEach((batchSnapshot) => {
                startups.push(
                  ...batchSnapshot.docs.map((docSnapshot) => {
                    const startup = docSnapshot.data() as OutputStartup;
                    startup.id = docSnapshot.id;
                    return startup;
                  })
                );
              });

              startups.sort((startupA, startupB) =>
                startupA.nome.localeCompare(startupB.nome)
              );
              console.log("startups", startups)
              resolve({
                ok: true,
                value: startups,
              });
            })
            .catch((error) => {
              resolve({
                ok: false,
                error,
              });
            });

        })
        .catch((error) => {
          resolve({
            ok: false,
            error,
          });
        });
    });
  }

  isUserHasStartup(id_user: string): Promise<Result<boolean>> {
    return new Promise((resolve) => {
      getCountFromServer(
        query(
          collection(this.firestore, "convites_startup_employee"),
          where("alvo.id", "==", id_user)
        )
      )
        .then((qtdConvitesStartup) => {
          resolve({
            ok: true,
            value: qtdConvitesStartup.data().count > 0,
          });
        })
        .catch((error) => {
          resolve({
            ok: false,
            error,
          });
        });
    });
  }

  getStartupWaitingAprovement(): Promise<Result<Readonly<OutputStartup>[]>> {
    return new Promise((resolve) => {
      const docStartup = query(
        collection(this.firestore, "startup"),
        where("habilitado", "==", false)
      );
      console.log("docStartup", docStartup);
      getDocs(docStartup)
        .then((querySnapshot) => {
          console.log("querySnapshot", querySnapshot);
          resolve({
            ok: true,
            value: querySnapshot.docs.map((doc) => {
              console.log("doc", doc);
              return docDataToStartup(doc.data(), doc.id);
            }),
          });
        })
        .catch((error) => {
          resolve({
            ok: false,
            error,
          });
        });
    });
  }
}
function docDataToStartup(
  doc: DocumentData,
  id: string
): Readonly<OutputStartup> {
  const startup: OutputStartup = {
    ...doc,
    id: id,
    nome: doc.nome,
    cidade: doc.cidade,
    ano_fundacao: doc.ano_fundacao,
    email: doc.email,
    estagio: doc.estagio,
    modelo_negocio: doc.modelo_negocio,
    segmento_atuacao: doc.segmento_atuacao,
    gestores: doc.gestores,
    qtde_funcionarios: doc.qtde_funcionarios,
    sobre: doc.sobre,
    created_in: fieldToDate(doc.created_in),
    habilitado: doc.habilitado,
    recusado: doc.recusado,
  };

  return startup;
}
